import React, { ChangeEvent } from 'react';
import { Col, Row } from '../../lib/utils';
import {
    Body1Bold,
    Body1Regular,
    Body2Bold,
    Body2Regular,
    Body3Bold,
    Body3Light,
    Body3Regular,
    CaptionLight,
    Head4,
    Subtitle1Bold,
    Subtitle1Regular,
} from '../../lib/font';
import { colors } from '../../lib/colors';
import { I_ALERT, I_PRIMARY_CHECK, I_RIGHT_PRIMARY_BTN } from '../../types/images';
import NormalButton from '../Button/NormalButton';
import dayjs from 'dayjs';
import ColorRoundButton from '../Button/ColorRoundButton';

type Props = {
    email: string;
    onChangeEmail: (e: ChangeEvent<HTMLInputElement>) => void;
    authObject: any;
    onClickTerm: () => void;
    onClickPolicy: () => void;
    isCheckPolicy: boolean;
    buttonType: string;
    smallCenterTrainerNumber: number;
    bigCenterTrainerNumber: number;
    currentPrice: string;
    onClickPlanChange: () => void;
};
const PlanChangePay = ({
    email,
    onChangeEmail,
    authObject,
    onClickTerm,
    onClickPolicy,
    isCheckPolicy,
    buttonType,
    smallCenterTrainerNumber,
    bigCenterTrainerNumber,
    currentPrice,
    onClickPlanChange,
}: Props) => {
    return (
        <Col>
            <Row style={{ marginTop: 64 }}>
                <Col
                    style={{
                        width: 685,
                        height: 706,
                        borderRadius: 16,
                        backgroundColor: colors.WHITE_50,
                        border: '1px solid #C3D4F4',
                        marginRight: 24,
                    }}
                >
                    <Body1Bold style={{ color: colors.LAVEL_4, marginLeft: 40, marginTop: 32 }}>
                        {'플랜 변경 정보'}
                    </Body1Bold>

                    <Col style={{ marginLeft: 56, marginTop: 32 }}>
                        <Row style={{ alignItems: 'center' }}>
                            <Body2Bold style={{ color: colors.LAVEL_4 }}>{'플랜 변경 :'}</Body2Bold>
                            <Body2Bold style={{ color: colors.LAVEL_4, marginLeft: 16 }}>
                                {authObject?.planLimitCount}명
                            </Body2Bold>
                            <img
                                src={I_RIGHT_PRIMARY_BTN}
                                style={{ width: 16, height: 16, marginLeft: 16, marginRight: 16 }}
                            />
                            <Body2Bold style={{ color: colors.LAVEL_4 }}>
                                {buttonType === 'small'
                                    ? `${smallCenterTrainerNumber}명`
                                    : `${bigCenterTrainerNumber}명`}
                            </Body2Bold>
                        </Row>

                        <Body3Regular style={{ color: colors.POINT_1, marginTop: 11 }}>
                            {'플랜 상향 변경 시, 즉시 적용되며  변경 된 플랜은 다음 결제 예정일부터 자동 청구 됩니다!'}
                        </Body3Regular>

                        <CaptionLight style={{ color: colors.LAVEL_1, whiteSpace: 'pre-wrap', marginTop: 11 }}>
                            {
                                '선택하신 플랜(인원 수)에 따라 강사를 등록할 수 있으며, 등록된 강사는 Rappo App 에서 Platinum Plan과\n동일한 혜택이 제공되는 Manager Plan을 이용하실 수 있습니다. '
                            }
                        </CaptionLight>

                        <Row style={{ alignItems: 'center', marginTop: 48 }}>
                            <div
                                style={{
                                    width: 4,
                                    height: 4,
                                    borderRadius: 2,
                                    backgroundColor: colors.LAVEL_3,
                                    marginRight: 5,
                                }}
                            />
                            <Body3Light style={{ color: colors.LAVEL_3 }}>
                                {'구독 취소는 [ 마이페이지 > 구독 플랜 > 구독 취소 ] 과정을 통해 가능합니다.'}
                            </Body3Light>
                        </Row>

                        <Row style={{ alignItems: 'center' }}>
                            <div
                                style={{
                                    width: 4,
                                    height: 4,
                                    borderRadius: 2,
                                    backgroundColor: colors.LAVEL_3,
                                    marginRight: 5,
                                }}
                            />
                            <Body3Light style={{ color: colors.LAVEL_3 }}>
                                {'정기 결제는 해당 월에 해당되는 날짜가 없는경우 (30일, 31일) 그 달 마지막날'}
                            </Body3Light>
                        </Row>

                        <Body3Light style={{ color: colors.LAVEL_3, marginLeft: 9 }}>{'결제 됩니다.'}</Body3Light>

                        <Row style={{ alignItems: 'center' }}>
                            <div
                                style={{
                                    width: 4,
                                    height: 4,
                                    borderRadius: 2,
                                    backgroundColor: colors.LAVEL_3,
                                    marginRight: 5,
                                }}
                            />
                            <Body3Light style={{ color: colors.LAVEL_3 }}>
                                {'결제가 실패할 경우 7일간의 유예기간이 주어지며 유예기간이 끝나면 연결된'}
                            </Body3Light>
                        </Row>

                        <Body3Light style={{ color: colors.LAVEL_3, marginLeft: 9 }}>
                            {'모든 라포 서비스를 이용하실 수 없습니다.'}
                        </Body3Light>

                        <Row style={{ alignItems: 'center', marginTop: 48 }}>
                            <Body3Regular style={{ color: colors.LAVEL_4 }}>이메일(선택)</Body3Regular>
                            <CaptionLight style={{ marginLeft: 8 }}>
                                {'입력하신 이메일로 결제정보가 전송됩니다! '}
                            </CaptionLight>
                        </Row>

                        <input
                            id="email"
                            name="email"
                            value={email}
                            onChange={onChangeEmail}
                            readOnly={authObject?.testAccount}
                            style={{
                                width: 360,
                                height: 36,
                                borderRadius: 36,
                                paddingLeft: 16,
                                fontSize: 16,
                                fontWeight: 400,
                                marginTop: 8,
                                border: '1px solid #A6BFEF',
                            }}
                        />

                        <Row style={{ alignItems: 'center', marginTop: 56 }}>
                            <button
                                type="button"
                                onClick={onClickPolicy}
                                style={{
                                    width: 24,
                                    height: 24,
                                    borderWidth: 1,
                                    borderColor: 'rgba(212, 219, 232, 1)',

                                    borderRadius: 2,

                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {isCheckPolicy && <img src={I_PRIMARY_CHECK} />}
                            </button>
                            <button style={{ border: 'none', marginLeft: 24 }} onClick={onClickTerm}>
                                <Body3Regular style={{ color: colors.LAVEL_4 }}>
                                    (필수) <Body3Bold>개인정보 수집 및 이용약관</Body3Bold>에 동의합니다.
                                </Body3Regular>
                                <Body1Regular style={{ color: colors.ERROR }}>*</Body1Regular>
                            </button>
                        </Row>
                    </Col>

                    <ColorRoundButton
                        text="플랜 변경 요청"
                        size="middle"
                        callBack={onClickPlanChange}
                        customStyle={{ width: 280, height: 48, marginLeft: 203, marginTop: 48 }}
                    />
                </Col>

                <Col
                    style={{
                        width: 483,
                        height: 706,
                        borderRadius: 16,
                        backgroundColor: colors.WHITE_50,
                        border: '1px solid #C3D4F4',
                    }}
                >
                    <Body1Bold style={{ color: colors.LAVEL_4, marginLeft: 40, marginTop: 32 }}>
                        {'결제 및 이용 정보'}
                    </Body1Bold>

                    <Col style={{ marginLeft: 56 }}>
                        <Body2Regular style={{ color: colors.LAVEL_4, marginTop: 32 }}>{'적용 할인 혜택'}</Body2Regular>
                        <Body3Regular style={{ color: colors.LAVEL_3 }}>
                            {buttonType === 'small'
                                ? 'Starter Manager Sale (약 65% 할인) - 1인 당 32,900원'
                                : 'Pro Manager Sale (약 60% 할인) - 1인 당 39,900원'}
                        </Body3Regular>

                        <Body2Regular style={{ color: colors.LAVEL_4, marginTop: 40 }}>
                            {'다음 결제 예정일'}
                        </Body2Regular>
                        <Body3Regular style={{ color: colors.LAVEL_3 }}>
                            {dayjs(authObject?.paymentDueDate).format('YYYY년 MM월 DD일')}
                        </Body3Regular>

                        <Body2Regular style={{ color: colors.LAVEL_4, marginTop: 40 }}>
                            {'다음 플랜 이용 기간'}
                        </Body2Regular>
                        <Body3Regular style={{ color: colors.LAVEL_3 }}>
                            {dayjs(authObject?.expirationDate).format('YYYY년 MM월 DD일 ~ ')}
                            {dayjs(authObject?.expirationDate).add(1, 'month').format('YYYY년 MM월 DD일')}
                        </Body3Regular>

                        <Row style={{ alignItems: 'center', marginTop: 40 }}>
                            <Body2Regular style={{ color: colors.LAVEL_4 }}>{'정기 결제'}</Body2Regular>
                            <CaptionLight style={{ color: colors.LAVEL_4, marginLeft: 8 }}>
                                {'(최초 결제일을 기준으로 적용)'}
                            </CaptionLight>
                        </Row>

                        <Body3Regular style={{ color: colors.LAVEL_3 }}>
                            매월 {dayjs(authObject?.expirationDate).format(' DD일')}
                        </Body3Regular>
                    </Col>

                    <Col
                        style={{
                            width: 403,
                            height: 180,
                            borderRadius: 8,
                            backgroundColor: colors.WHITE_300,
                            border: '1px solid #C3D4F4',
                            alignSelf: 'center',
                            paddingLeft: 24,
                            paddingTop: 20,
                        }}
                    >
                        <Body2Regular style={{ color: colors.LAVEL_4 }}>{'결제 금액'}</Body2Regular>
                        <Row style={{ alignItems: 'center' }}>
                            <Body3Regular style={{ color: colors.LAVEL_3 }}>
                                {authObject?.planLimitCount && authObject?.planLimitCount > 3
                                    ? (authObject?.planLimitCount * 39900).toLocaleString()
                                    : (authObject?.planLimitCount * 32900).toLocaleString()}
                                원
                            </Body3Regular>
                            <Body3Light style={{ color: colors.LAVEL_1 }}>{'/ VAT포함'}</Body3Light>
                            <img
                                src={I_RIGHT_PRIMARY_BTN}
                                style={{ width: 16, height: 16, marginLeft: 16, marginRight: 16 }}
                            />

                            <Body3Regular style={{ color: colors.LAVEL_3 }}>{currentPrice}원</Body3Regular>
                            <Body3Light style={{ color: colors.LAVEL_1 }}>{'/ 월 VAT포함'}</Body3Light>
                        </Row>

                        <Body2Regular style={{ color: colors.LAVEL_4, marginTop: 24 }}>{'결제 카드'}</Body2Regular>
                        <Body3Regular style={{ color: colors.LAVEL_3 }}>{'신한 9999-9999-****-999*'}</Body3Regular>
                    </Col>
                </Col>
            </Row>
        </Col>
    );
};

export default PlanChangePay;
