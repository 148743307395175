import React, { ChangeEvent } from 'react';
import { Col, Row } from '../../../lib/utils';
import { Body1Bold, Body3Regular, CaptionLight, Subtitle1Bold } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import dayjs from 'dayjs';
import { I_LEFT_ANGLE, I_RIGHT_ANGLE } from '../../../types/images';
import ThreeButton from '../../Button/ThreeButton';
import ResetButton from '../../Button/ResetButton';

type Props = {
    isMonth: boolean;
    isYear: boolean;
    isEntire: boolean;
    currentDate: any;
    searchText: string;
    onClickSubtractDate: () => void;
    onClickAddDate: () => void;
    settingDate: (type: string) => void;
    onClickResetButton: () => void;
    onChangeSearchText: (e: ChangeEvent<HTMLInputElement>) => void;
    thisMonthFilterList: any;
    thisYearFilterList: any;
    entireFilterList: any;
};
const ClassHistoryHeader = ({
    isMonth,
    isYear,
    isEntire,
    currentDate,
    searchText,
    onClickSubtractDate,
    onClickAddDate,
    settingDate,
    onClickResetButton,
    onChangeSearchText,
    thisMonthFilterList,
    thisYearFilterList,
    entireFilterList,
}: Props) => {
    return (
        <Col style={{ width: 1192 }}>
            <Subtitle1Bold style={{ color: colors.LAVEL_4 }}>{'수업기록'}</Subtitle1Bold>

            <Row style={{ alignItems: 'center', marginTop: 24, justifyContent: 'space-between' }}>
                <Row style={{ alignItems: 'center' }}>
                    <Row
                        style={{
                            width: 232,
                            height: 32,
                            justifyContent: isEntire ? 'center' : 'space-between',
                            alignItems: 'center',
                            marginRight: 24,
                        }}
                    >
                        {!isEntire && (
                            <button style={{ width: 24, height: 24 }} onClick={() => onClickSubtractDate()}>
                                <img src={I_LEFT_ANGLE} style={{ width: 24, height: 24 }} />
                            </button>
                        )}

                        <Body3Regular style={{ width: 160, textAlign: 'center', color: colors.LAVEL_2 }}>
                            {isMonth
                                ? dayjs(currentDate).format('YYYY년 MM월')
                                : isYear
                                ? dayjs(currentDate).format('YYYY년')
                                : '전체기간'}
                        </Body3Regular>
                        {!isEntire && (
                            <button style={{ width: 32, height: 32 }} onClick={() => onClickAddDate()}>
                                <img src={I_RIGHT_ANGLE} style={{ width: 24, height: 24 }} />
                            </button>
                        )}
                    </Row>
                    <ThreeButton
                        isFirst={isMonth}
                        isSecond={isYear}
                        isThird={isEntire}
                        firstText={'이번 달'}
                        secondText={'이번 연도'}
                        thirdText={'전체'}
                        firstCallBack={() => settingDate('month')}
                        secondCallBack={() => settingDate('year')}
                        thirdCallBack={() => settingDate('entire')}
                    />
                    {/* 초기화 버튼 */}
                    <ResetButton style={{ marginLeft: 24 }} callBack={onClickResetButton} />
                </Row>

                <input
                    value={searchText}
                    onChange={onChangeSearchText}
                    placeholder="내용을 입력해주세요!"
                    style={{
                        width: 310,
                        height: 36,
                        borderRadius: 36,
                        backgroundColor: colors.WHITE_50,
                        border: '1px solid #D4DBE8',
                        paddingLeft: 16,
                        fontSize: 16,
                        fontWeight: '400',
                    }}
                />
            </Row>

            <Row style={{ width: 1192, justifyContent: 'flex-end', marginTop: 12 }}>
                <CaptionLight style={{ color: colors.LAVEL_1 }}>
                    {`총 수업 기록:`}
                    {isMonth && thisMonthFilterList && thisMonthFilterList.length}
                    {isYear && thisYearFilterList && thisYearFilterList.length}
                    {isEntire && entireFilterList && entireFilterList.length}회
                </CaptionLight>
            </Row>
        </Col>
    );
};

export default ClassHistoryHeader;
