import React, { memo } from 'react';
import { Col, Row } from '../../lib/utils';
import { CaptionLight, Subtitle1Bold } from '../../lib/font';
import PeriodNavigator, { PeriodNavigatorType } from '../Date/PeriodNavigator';
import DateRangeSelector from '../Date/DateRangeSelector';
import ResetButton from '../Button/ResetButton';
import { colors } from '../../lib/colors';

type Props = {
    currentDate: string;
    onClickPrev?: (type: PeriodNavigatorType) => void;
    onClickNext?: (type: PeriodNavigatorType) => void;
    onClickType?: (type: PeriodNavigatorType) => void;
    type: PeriodNavigatorType;
    monthLength?: number;
    yearLength?: number;
    allLength?: number;
};

const MemberClassHistoryComponentsHeader = ({
    currentDate,
    onClickPrev,
    onClickNext,
    onClickType,
    type,
    monthLength,
    yearLength,
    allLength,
}: Props) => {
    const count = type === 'thisYear' ? yearLength : type === 'all' ? allLength : monthLength;
    const rightText = `총 수업 기록 : ${count}회`;

    return (
        <Col className="mb-[24px]">
            <Row className="w-[1192px] items-center mb-[24px]">
                <Subtitle1Bold style={{ color: colors.LAVEL_4 }}>수업 기록</Subtitle1Bold>
            </Row>
            <Row className="flex w-[1192px] h-[32px] justify-between items-center">
                <Row>
                    <PeriodNavigator
                        currentDate={currentDate}
                        fontType={'Body3Regular'}
                        type={type}
                        onClickPrev={onClickPrev}
                        onClickNext={onClickNext}
                        mr={24}
                    />
                    <DateRangeSelector onClickType={onClickType} mr={24} fontType="Body3Regular" />
                    <ResetButton />
                </Row>
                <CaptionLight className="text-LAVEL_1">{rightText}</CaptionLight>
            </Row>
        </Col>
    );
};

export default MemberClassHistoryComponentsHeader;
