import React from 'react';
import {
    Head1,
    Head2,
    Head3,
    Head4,
    Subtitle1Bold,
    Subtitle1Regular,
    Body1Bold,
    Body1Regular,
    Body1Light,
    Body2Bold,
    Body2Regular,
    Body2Light,
    Body3Bold,
    Body3Regular,
    Body3Light,
    CaptionBold,
    CaptionRegular,
    CaptionLight,
    OverlineBold,
    OverlineRegular,
    OverlineLight,
} from '../lib/font';
import { colors } from '../lib/colors';

export type FontsType =
    | 'Head1'
    | 'Head2'
    | 'Head3'
    | 'Head4'
    | 'Subtitle1Bold'
    | 'Subtitle1Regular'
    | 'Body1Bold'
    | 'Body1Regular'
    | 'Body1Light'
    | 'Body2Bold'
    | 'Body2Regular'
    | 'Body2Light'
    | 'Body3Bold'
    | 'Body3Regular'
    | 'Body3Light'
    | 'CaptionBold'
    | 'CaptionRegular'
    | 'CaptionLight'
    | 'OverlineBold'
    | 'OverlineRegular'
    | 'OverlineLight';
type Props = {
    fontType: FontsType;
    text: string;
    color?: keyof typeof colors;
    className?: string;
};

const TextSelector = ({ fontType, text, color, className }: Props) => {
    const fontComponents: Record<
        FontsType,
        ({
            children,
            className,
            numberOfLines,
            style,
        }: {
            children: React.ReactNode;
            className?: string;
            numberOfLines?: number | undefined;
            style?: React.CSSProperties;
        }) => JSX.Element
    > = {
        Head1: Head1,
        Head2: Head2,
        Head3: Head3,
        Head4: Head4,
        Subtitle1Bold: Subtitle1Bold,
        Subtitle1Regular: Subtitle1Regular,
        Body1Bold: Body1Bold,
        Body1Regular: Body1Regular,
        Body1Light: Body1Light,
        Body2Bold: Body2Bold,
        Body2Regular: Body2Regular,
        Body2Light: Body2Light,
        Body3Bold: Body3Bold,
        Body3Regular: Body3Regular,
        Body3Light: Body3Light,
        CaptionBold: CaptionBold,
        CaptionRegular: CaptionRegular,
        CaptionLight: CaptionLight,
        OverlineBold: OverlineBold,
        OverlineRegular: OverlineRegular,
        OverlineLight: OverlineLight,
    };
    const TextComponent = fontComponents[fontType];

    return (
        <TextComponent className={` ${className}`} style={{ color: color ? colors[color] : colors.black }}>
            {text}
        </TextComponent>
    );
};

export default TextSelector;
