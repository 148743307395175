import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, getItemWithExpiration } from '../../lib/utils';
import UpdateOneSchedule from '../../components/UpdateSchedule/UpdateOneSchedule/UpdateOneSchedule';
import UpdateEtcSchedule from '../../components/UpdateSchedule/UpdateEtcSchedule/UpdateEtcSchedule';
import { colors } from '../../lib/colors';
import { useQuery } from '@tanstack/react-query';
import { getCoachListAPI } from '../../api/schedule';
import _ from 'lodash';
import dayjs from 'dayjs';

type PageType = 'oneSchedule' | 'repeatSchedule' | 'etcSchedule' | 'offSchedule';

const UpdateSchedulePage = ({ createAlert, setIsLoading }: any) => {
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));
    const location = useLocation();

    const pageType: PageType = location.state.type;
    const scheduleObject: any = location.state?.scheduleObject;

    // 캘린더 페이지의 일간 주간 타입 정보
    const calendarType = location.state?.calendarType;

    // 일정 페이지로 갔다가 되돌아올 때 받아올 날짜 및 스크롤 정보
    const calendarSelectedDay = location.state?.calendarSelectedDay;
    const calendarScrollY = location.state?.calendarScrollY;

    const [coachOption, setCoachOption] = useState<any>([]);

    //  강사 리스트 조회 API : (GET)
    const getCoachList = useQuery(
        ['getCoachListAPI'],
        async () => await getCoachListAPI(authObject?.centerId, authorization),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const response = res?.data?.coachNameList;
                    const coachList = [];

                    if (response && response.length > 0) {
                        for (let i = 0; i < response.length; i++) {
                            coachList.push({ value: response[i].coachId, label: response[i].name });
                        }
                    }

                    setCoachOption(coachList);
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!!authObject?.testAccount,
            // refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    return (
        <Col style={{ width: '100%', alignItems: 'center', marginTop: 64, marginBottom: 218 }}>
            <Col
                style={{
                    width: 1192,
                    minHeight: 400,
                    borderRadius: 16,
                    backgroundColor: colors.WHITE_50,
                    border: '1px solid #C3D4F4',
                    marginTop: 64,
                }}
            >
                {pageType === 'oneSchedule' && (
                    <UpdateOneSchedule
                        coachOption={coachOption}
                        authorization={authorization}
                        authObject={authObject}
                        createAlert={createAlert}
                        scheduleObject={scheduleObject}
                        calendarType={calendarType}
                        calendarSelectedDay={dayjs(calendarSelectedDay).format('YYYY-MM-DD')}
                        calendarScrollY={calendarScrollY}
                    />
                )}
                {pageType === 'etcSchedule' && (
                    <UpdateEtcSchedule
                        coachOption={coachOption}
                        authorization={authorization}
                        authObject={authObject}
                        createAlert={createAlert}
                        scheduleObject={scheduleObject}
                        calendarType={calendarType}
                        calendarSelectedDay={dayjs(calendarSelectedDay).format('YYYY-MM-DD')}
                        calendarScrollY={calendarScrollY}
                    />
                )}
            </Col>
        </Col>
    );
};

export default UpdateSchedulePage;
