import { Col, Row } from '../../lib/utils';
import { colors } from '../../lib/colors';
import { Body1Bold, Body2Regular, Body3Light, Body3Regular } from '../../lib/font';
import { I_PLAN_LOGO } from '../../types/images';
import dayjs from 'dayjs';
import WhiteRoundButton from '../Button/WhiteRoundButton';
import ColorRoundButton from '../Button/ColorRoundButton';

const ExpiredInformation = ({ clearLocalStorage, navigate, authObject, onClickCardChangeButton }: any) => {
    const handleClick = (event: any) => {
        event.preventDefault(); // 기본 링크 이동 방지
        window.open('http://pf.kakao.com/_ICxhcb/chat', '_blank'); // 새 탭에서 링크 열기
    };
    return (
        <>
            <Row style={{ width: 1192, justifyContent: 'space-between' }}>
                <ColorRoundButton
                    text="실시간 1:1 문의하기"
                    size="tiny"
                    callBack={(e: any) => handleClick(e)}
                    customStyle={{ width: 132, height: 24 }}
                />
                <WhiteRoundButton
                    text={'로그아웃'}
                    size="tiny"
                    callBack={() => {
                        clearLocalStorage();
                        navigate('/signIn');
                    }}
                />
            </Row>

            <img src={I_PLAN_LOGO} style={{ width: 1192, height: 236, marginTop: 16 }} />

            <Col
                style={{
                    width: 1192,
                    height: 466,
                    borderRadius: 16,
                    border: '1px solid hsla(356, 100%, 62%, 1)',
                    backgroundColor: colors.WHITE_50,
                    marginTop: 24,
                    paddingLeft: 72,
                    paddingTop: 48,
                }}
            >
                <Body1Bold style={{ color: colors.LAVEL_4 }}>{'구독 만료 안내'}</Body1Bold>
                <Body3Light style={{ color: colors.LAVEL_3, marginTop: 16 }}>
                    {
                        '선택하신 플랜의 구독이 만료되어 서비스를 더이상 사용하실 수 없으니, 다시 이용을 원하신다면 플랜을 재구독해주세요'
                    }
                </Body3Light>
                <Row style={{ alignItems: 'center', marginTop: 16 }}>
                    <Body1Bold style={{ color: colors.POINT_2 }}>{authObject?.name} 관리자</Body1Bold>
                    <Body3Regular style={{ color: colors.POINT_2, marginLeft: 8, marginRight: 8 }}>님의</Body3Regular>
                    <Body1Bold style={{ color: colors.POINT_2 }}>{authObject?.centerName}</Body1Bold>
                    <Body3Regular style={{ color: colors.POINT_2, marginLeft: 8, marginRight: 8 }}>
                        센터에서는 현재
                    </Body3Regular>
                    <Body1Bold style={{ color: colors.POINT_2 }}>{authObject?.coachCount}명의 강사님</Body1Bold>
                    <Body3Regular style={{ color: colors.POINT_2, marginLeft: 8, marginRight: 8 }}>과</Body3Regular>
                    <Body1Bold style={{ color: colors.POINT_2, marginRight: 8 }}>
                        {authObject?.memberCount}명의 회원님
                    </Body1Bold>
                    <Body3Regular style={{ color: colors.POINT_2 }}>이 함께하고 계십니다!</Body3Regular>
                </Row>

                <Col
                    style={{
                        width: 1048,
                        height: 230,
                        borderRadius: 16,
                        backgroundColor: colors.WHITE_300,
                        border: '1px solid #A6BFEF',
                        marginTop: 24,
                        paddingLeft: 48,
                        paddingTop: 32,
                    }}
                >
                    <Row style={{ alignItems: 'center' }}>
                        <Col style={{ width: 400, height: 66, justifyContent: 'space-between' }}>
                            <Body2Regular style={{ color: colors.LAVEL_4 }}>마지막 결제일</Body2Regular>
                            <Body3Regular style={{ color: colors.LAVEL_2 }}>
                                {authObject?.usableStatus === 'needPlanToFree'
                                    ? '-'
                                    : dayjs(authObject?.finalPaymentDate).format('YYYY년 MM월 DD일(ddd)')}
                            </Body3Regular>
                        </Col>
                        <Col style={{ width: 260, height: 66, justifyContent: 'space-between', marginLeft: 80 }}>
                            <Body2Regular style={{ color: colors.LAVEL_4 }}>결제 금액</Body2Regular>
                            <Body3Regular style={{ color: colors.LAVEL_2 }}>
                                {authObject?.usableStatus === 'needPlanToFree'
                                    ? '0원 '
                                    : `${authObject?.planAmount && (authObject?.planAmount).toLocaleString()}원`}
                                /월 VAT포함 (다음 플랜)
                            </Body3Regular>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: 34 }}>
                        <Col style={{ width: 400, height: 66, justifyContent: 'space-between' }}>
                            <Body2Regular style={{ color: colors.LAVEL_4 }}>마지막 플랜 이용기간</Body2Regular>
                            <Body3Regular style={{ color: colors.LAVEL_2 }}>
                                {dayjs(authObject?.activationDate).format('YYYY년 MM월 DD일(ddd) ~ ')}{' '}
                                {dayjs(authObject?.expirationDate).format('YYYY년 MM월 DD일(ddd)')}
                            </Body3Regular>
                        </Col>

                        <Col style={{ width: 260, height: 66, justifyContent: 'space-between', marginLeft: 80 }}>
                            <Body2Regular style={{ color: colors.LAVEL_4 }}>결제 카드</Body2Regular>
                            <Body3Regular style={{ color: colors.LAVEL_2 }}>
                                {authObject?.usableStatus === 'needPlanToFree'
                                    ? '-'
                                    : `${authObject?.cardIssuingBank} ${authObject?.cardNumber}`}
                            </Body3Regular>
                        </Col>

                        <ColorRoundButton
                            isDisabled={authObject?.usableStatus === 'needPlanToFree'}
                            callBack={onClickCardChangeButton}
                            text={'카드 변경'}
                            size="small"
                            isGradient={true}
                            customStyle={{ width: 160, height: 36, marginLeft: 52, marginTop: 30 }}
                        />
                    </Row>
                </Col>
            </Col>
        </>
    );
};

export default ExpiredInformation;
