import React from 'react';
import { Col, Row } from '../../../lib/utils';
import { Body2Regular, Body3Regular, CaptionLight } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import InputSquareDropDown from '../../DropDown/InputSquareDropDown';
import dayjs from 'dayjs';
import WhiteRoundButton from '../../Button/WhiteRoundButton';
import ColorRoundButton from '../../Button/ColorRoundButton';

const CreateOneScheduleLeft = ({
    coachOption,
    coach,
    handleCoachSelect,
    selectedDay,
    setIsCalendar,
    timeList,
    startTime,
    handleStartTimeSelect,
    endTime,
    handleEndTimeSelect,
    onClickAddTime,
    onClickSubTime,
}: any) => {
    return (
        <Col style={{ width: 470 }}>
            <Col style={{ width: 382, height: 88, justifyContent: 'space-between' }}>
                <Body3Regular style={{ color: colors.LAVEL_4 }}> {'담당 강사'}</Body3Regular>

                {coachOption && (
                    <InputSquareDropDown
                        selectedGender={coach}
                        genderOptions={coachOption}
                        handleGenderSelect={handleCoachSelect}
                        customStyle={{ width: 382 }}
                        zIndex={100}
                    />
                )}
            </Col>

            <Col style={{ width: 382, height: 88, justifyContent: 'space-between', marginTop: 64 }}>
                <Body3Regular style={{ color: colors.LAVEL_4 }}> {'날짜'}</Body3Regular>

                <InputSquareDropDown
                    selectedGender={coach}
                    genderOptions={coachOption}
                    handleGenderSelect={handleCoachSelect}
                    customStyle={{ width: 382 }}
                    isJustClick={true}
                    justClickString={dayjs(selectedDay).format('YYYY년 M월 D일(ddd)')}
                    justClickCallBack={() => setIsCalendar(true)}
                    zIndex={90}
                />
            </Col>

            <Col style={{ width: 470, height: 162, marginTop: 64 }}>
                <Body3Regular style={{ color: colors.LAVEL_4 }}> {'시간'}</Body3Regular>

                <Row style={{ width: 470, alignItems: 'center', justifyContent: 'space-between', marginTop: 16 }}>
                    {timeList && (
                        <InputSquareDropDown
                            selectedGender={startTime}
                            genderOptions={timeList}
                            handleGenderSelect={handleStartTimeSelect}
                            isDefaultScroll={true}
                        />
                    )}

                    <Body2Regular style={{ color: colors.BLUE_500 }}>~</Body2Regular>
                    {timeList && (
                        <InputSquareDropDown
                            selectedGender={endTime}
                            genderOptions={timeList}
                            handleGenderSelect={handleEndTimeSelect}
                            isDefaultScroll={true}
                        />
                    )}
                </Row>
                <CaptionLight style={{ color: colors.LAVEL_1, marginLeft: 8, marginTop: 16 }}>
                    *일정에 소요되는 시간만큼 추가해주세요!
                </CaptionLight>

                <Row
                    style={{
                        width: 470,
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: 16,
                    }}
                >
                    <WhiteRoundButton
                        customStyle={{ width: 60, height: 24 }}
                        text="-30분"
                        callBack={() => {
                            onClickSubTime(30);
                        }}
                        size="tiny"
                    />
                    <WhiteRoundButton
                        customStyle={{ width: 60, height: 24 }}
                        text="-20분"
                        callBack={() => {
                            onClickSubTime(20);
                        }}
                        size="tiny"
                    />
                    <WhiteRoundButton
                        customStyle={{ width: 60, height: 24 }}
                        text="-10분"
                        callBack={() => {
                            onClickSubTime(10);
                        }}
                        size="tiny"
                    />

                    <ColorRoundButton
                        customStyle={{ width: 60, height: 24 }}
                        text="+10분"
                        callBack={() => {
                            onClickAddTime(10);
                        }}
                        size="tiny"
                    />
                    <ColorRoundButton
                        customStyle={{ width: 60, height: 24 }}
                        text="+20분"
                        callBack={() => {
                            onClickAddTime(20);
                        }}
                        size="tiny"
                    />
                    <ColorRoundButton
                        customStyle={{ width: 60, height: 24 }}
                        text="+30분"
                        callBack={() => {
                            onClickAddTime(30);
                        }}
                        size="tiny"
                    />
                </Row>
            </Col>
        </Col>
    );
};

export default CreateOneScheduleLeft;
