import { Dispatch, SetStateAction, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../lib/colors';
import { Col, Row } from '../../lib/utils';
import { HoverImage } from '../../lib/styled';
import { I_LEFT_BRACKET, I_POPUP_EXIT, I_RIGHT_BRACKET } from '../../types/images';
import Scrollbars from 'react-custom-scrollbars-2';
import _ from 'lodash';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
`;

const ModalInner = styled.div`
    background: white;
    width: 1000px;
    height: 480px;
    border-radius: 24px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    background: rgba(245, 249, 255, 1);
`;

type Props = {
    setModalVisible: Dispatch<SetStateAction<boolean>>;
    pictureList: any;
};

const ModalImagesViewer = ({ setModalVisible, pictureList }: Props) => {
    const scrollbarsRef = useRef<any>(null);
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    const onClickChangePicture = (index: number) => {
        setCurrentIndex(index);
    };

    const onClickNextImage = () => {
        if (currentIndex > pictureList.length - 2) {
            return;
        } else {
            setCurrentIndex((prev) => prev + 1);
            if (scrollbarsRef.current) {
                const currentScrollTop = scrollbarsRef.current.getScrollTop();
                const targetScrollTop = currentScrollTop + 60;
                smoothScroll(scrollbarsRef.current, currentScrollTop, targetScrollTop);
            }
        }
    };

    const onClickPrevImage = () => {
        if (currentIndex === 0) {
            return;
        } else {
            setCurrentIndex((prev) => prev - 1);
            if (scrollbarsRef.current) {
                const currentScrollTop = scrollbarsRef.current.getScrollTop();
                const targetScrollTop = currentScrollTop - 60;
                smoothScroll(scrollbarsRef.current, currentScrollTop, targetScrollTop);
            }
        }
    };

    const smoothScroll = (scrollbars: any, currentScrollTop: any, targetScrollTop: any) => {
        const step = () => {
            currentScrollTop += (targetScrollTop - currentScrollTop) / 8;
            if (Math.abs(targetScrollTop - currentScrollTop) < 1) {
                scrollbars.scrollTop(targetScrollTop);
            } else {
                scrollbars.scrollTop(currentScrollTop);
                requestAnimationFrame(step);
            }
        };
        requestAnimationFrame(step);
    };
    return (
        <ModalOverLay>
            <ModalInner>
                <Row
                    style={{
                        width: 834,
                        height: 416,
                        marginTop: 32,
                        marginLeft: 32,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Row
                        style={{
                            position: 'absolute',
                            width: 834,
                            height: 416,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Row
                            onClick={onClickPrevImage}
                            style={{ width: 68, height: 160, alignItems: 'center', justifyContent: 'center' }}
                        >
                            <HoverImage
                                src={I_LEFT_BRACKET}
                                style={{
                                    width: 18,
                                    height: 58,
                                }}
                            />
                        </Row>
                        <Row
                            onClick={onClickNextImage}
                            style={{ width: 68, height: 160, alignItems: 'center', justifyContent: 'center' }}
                        >
                            <HoverImage
                                src={I_RIGHT_BRACKET}
                                style={{
                                    width: 18,
                                    height: 58,
                                }}
                            />
                        </Row>
                    </Row>

                    <img
                        src={pictureList[currentIndex].content}
                        style={{
                            width: '100%',
                            height: '100%',

                            objectFit: 'scale-down',
                        }}
                    />
                </Row>

                <Col style={{ width: 92, height: 416, marginTop: 32, marginLeft: 20 }}>
                    <HoverImage
                        src={I_POPUP_EXIT}
                        style={{ width: 52, height: 52, marginLeft: 12 }}
                        onClick={() => setModalVisible(false)}
                    />

                    <Scrollbars
                        ref={scrollbarsRef}
                        autoHide
                        autoHideTimeout={800}
                        autoHideDuration={200}
                        style={{
                            width: 92,
                            height: 336,
                            marginTop: 24,
                        }}
                    >
                        <Col style={{ width: 76, paddingLeft: 4, backgroundColor: colors.WHITE_600 }}>
                            {pictureList &&
                                pictureList.map((item: any, index: number) => {
                                    return (
                                        <Row
                                            key={'pictureDetail' + index}
                                            style={{
                                                width: 68,
                                                height: 68,
                                                marginTop: index === 0 ? 4 : 0,
                                                marginBottom: 8,

                                                borderRadius: 2,
                                                overflow: 'hidden',
                                                border:
                                                    currentIndex === index ? '3px solid rgba(61, 115, 221, 1)' : '0',
                                            }}
                                            onClick={() => onClickChangePicture(index)}
                                        >
                                            <img src={item?.content} style={{ width: 68, height: 68 }} />
                                        </Row>
                                    );
                                })}
                        </Col>
                    </Scrollbars>
                </Col>
            </ModalInner>
        </ModalOverLay>
    );
};

export default ModalImagesViewer;
