import React from 'react';
import { Col, Row } from '../../../lib/utils';
import { Body3Regular, CaptionRegular } from '../../../lib/font';
import { colors } from '../../../lib/colors';

import OneOnOneSchedule from './OneOnOneSchedule';
import OneOnTwoSchedule from './OneOnTwoSchedule';

const CreateOneMoreScheduleRight = ({
    setIsOneOnOneClass,
    isOneOnOneClass,
    selectedMember,
    filteredMemberList,
    handleMemberSelect,
    searchText,
    onChangeText,
    coach,
    isDisabledInput,
    onClickDeleteSelectedMember,
    onClickClassPassPage,
    oneOnOneMemberShipIndex,
    setOneOnOneMemberShipIndex,
    firstMemberShipIndex,
    setFirstMemberShipIndex,
    secondMemberShipIndex,
    setSecondMemberShipIndex,
}: any) => {
    return (
        <Col style={{ width: 470, marginLeft: 138 }}>
            <Row style={{ alignItems: 'center' }}>
                <Body3Regular style={{ color: colors.LAVEL_4 }}>회원</Body3Regular>
                {coach.value === '' ? (
                    <CaptionRegular style={{ color: colors.LAVEL_2, marginLeft: 12 }}>
                        담당강사를 먼저 선택해주세요!
                    </CaptionRegular>
                ) : null}
            </Row>

            <Row
                style={{
                    width: 204,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginLeft: 8,
                    marginTop: 0,
                    marginBottom: 16,
                }}
            ></Row>

            {isOneOnOneClass ? (
                <OneOnOneSchedule
                    selectedMember={selectedMember}
                    onClickDeleteSelectedMember={onClickDeleteSelectedMember}
                    onClickClassPassPage={onClickClassPassPage}
                    filteredMemberList={filteredMemberList}
                    handleMemberSelect={handleMemberSelect}
                    searchText={searchText}
                    onChangeText={onChangeText}
                    isDisabledInput={isDisabledInput}
                    coach={coach}
                    oneOnOneMemberShipIndex={oneOnOneMemberShipIndex}
                    setOneOnOneMemberShipIndex={setOneOnOneMemberShipIndex}
                />
            ) : (
                <OneOnTwoSchedule
                    selectedMember={selectedMember}
                    onClickDeleteSelectedMember={onClickDeleteSelectedMember}
                    onClickClassPassPage={onClickClassPassPage}
                    filteredMemberList={filteredMemberList}
                    handleMemberSelect={handleMemberSelect}
                    searchText={searchText}
                    onChangeText={onChangeText}
                    isDisabledInput={isDisabledInput}
                    coach={coach}
                    firstMemberShipIndex={firstMemberShipIndex}
                    setFirstMemberShipIndex={setFirstMemberShipIndex}
                    secondMemberShipIndex={secondMemberShipIndex}
                    setSecondMemberShipIndex={setSecondMemberShipIndex}
                />
            )}
        </Col>
    );
};

export default CreateOneMoreScheduleRight;
