import React, { CSSProperties } from 'react';

import { Subtitle1Bold } from '../../lib/font';
import styled from 'styled-components';

const NormalButtonWrap = styled.button`
    width: 180px;
    height: 60px;
    border-radius: 48px;
    box-shadow: 1px 1px 8px 0px #2b529d2e;

    background-color: rgba(237, 244, 255, 1);
    color: ${(props) => (props.disabled ? 'rgba(165, 171, 181, 1)' : 'rgba(26, 48, 93, 1)')};
    &:not([disabled]):hover {
        background-color: #e9f1ff;
        border-width: 2;
        border-color: #7da1e8;
        color: #3869c9;
    }
    &:not([disabled]):active {
        transform: scale(0.98);
        background-color: #648fe4;
        color: #fdfeff;
        box-shadow: none;
    }
`;

type Props = {
    text: string;
    type?: any;
    style?: CSSProperties;
    onClick?: any;
    disabled?: boolean;
};

/**
 * 180 * 60 크기의 노말버튼
 */
const NormalButton = ({ text, type = 'button', style, onClick, disabled }: Props) => {
    return (
        <NormalButtonWrap type={type} disabled={disabled} style={style} onClick={onClick}>
            <Subtitle1Bold>{text}</Subtitle1Bold>
        </NormalButtonWrap>
    );
};

export default NormalButton;
