import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import { Col, getItemWithExpiration, Row } from '../../../lib/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Body3Regular, Subtitle1Bold } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import { I_POPUP_EXIT } from '../../../types/images';
import dayjs from 'dayjs';
import WhiteSquareButton from '../../Button/WhiteSquareButton';
import ColorSquareButton from '../../Button/ColorSquareButton';
import { deleteGeneralScheduleAPI, deleteOffScheduleAPI } from '../../../api/schedule';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
`;

const ModalInner = styled.div`
    background: #f5f9ff;
    width: 928px;
    height: 476px;
    border-radius: 8px;
    box-shadow: 8px 8px 32px 0px #0000007a;

    background-color: #fdfeff;
    display: flex;
    flex-direction: column;
`;

const HoverImage = styled.img`
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

type Props = {
    modalVisible: boolean;
    setModalVisible: Dispatch<SetStateAction<boolean>>;
    navigate: any;
    detailObject: any;
    setDetailObject: any;
    createAlert: any;
    authObject: any;
    calendarType: 'daily' | 'weekly';
    calendarSelectedDay: string;
    calendarScrollY: number;
};

const OffEtcDetailModal = ({
    modalVisible,
    setModalVisible,
    navigate,
    detailObject,
    setDetailObject,
    createAlert,
    authObject,
    calendarType,
    calendarSelectedDay,
    calendarScrollY,
}: Props) => {
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const queryClient = useQueryClient();

    console.log(detailObject);

    // 일반일정 삭제 API : (delete)
    const deleteGeneralSchedule = useMutation({
        mutationFn: async () => await deleteGeneralScheduleAPI(authObject?.centerId, detailObject?._id, authorization),
        onSuccess: (res) => {
            if (res.status === 204) {
                queryClient.invalidateQueries({ queryKey: ['getDailyScheduleAPI'] });
                queryClient.invalidateQueries({ queryKey: ['getWeeklyScheduleAPI'] });
                setModalVisible(false);
            } else {
                createAlert('오류', '오류가 발생하였습니다!\n다시 한번 등록해주세요!', true);
            }
        },
        onError: () => {
            createAlert('', '오류가 발생하였습니다!\n다시 한번 등록해주세요!', true);
        },
    });

    // OFF일정 삭제 API : (delete)
    const deleteOffSchedule = useMutation({
        mutationFn: async () => await deleteOffScheduleAPI(authObject?.centerId, detailObject?.blockId, authorization),
        onSuccess: (res) => {
            if (res.status === 204) {
                queryClient.invalidateQueries({ queryKey: ['getDailyScheduleAPI'] });
                queryClient.invalidateQueries({ queryKey: ['getWeeklyScheduleAPI'] });
                setModalVisible(false);
            } else {
                createAlert('오류', '오류가 발생하였습니다!\n다시 한번 등록해주세요!', true);
            }
        },
        onError: () => {
            createAlert('', '오류가 발생하였습니다!\n다시 한번 등록해주세요!', true);
        },
    });

    const onClickDeleteButton = () => {
        if (detailObject?.type === 'onetimeSchedule') {
            deleteGeneralSchedule.mutate();
        } else {
            deleteOffSchedule.mutate();
        }
    };

    const onClickUpdateButton = () => {
        if (detailObject?.type === 'onetimeSchedule') {
            navigate('/schedule/update', {
                state: {
                    type: 'etcSchedule',
                    scheduleObject: detailObject,
                    calendarType,
                    calendarSelectedDay,
                    calendarScrollY,
                },
            });
        }
    };

    return (
        <ModalOverLay>
            <ModalInner>
                <Row>
                    <Subtitle1Bold style={{ color: colors.LAVEL_4, marginLeft: 48, marginTop: 36 }}>
                        {detailObject?.type === 'onetimeSchedule' ? '일반 일정 자세히보기' : 'OFF 자세히보기'}
                    </Subtitle1Bold>
                    {detailObject?.type === 'onetimeSchedule' ? (
                        <Row
                            style={{
                                width: 112,
                                height: 32,
                                borderRadius: 4,
                                border: '1px solid #17B5CB',
                                marginTop: 38,
                                marginLeft: 16,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Body3Regular style={{ color: colors.CYAN }}>일반 일정</Body3Regular>
                        </Row>
                    ) : (
                        <Row
                            style={{
                                width: 112,
                                height: 32,
                                borderRadius: 4,
                                backgroundColor: colors.WHITE_700,
                                marginTop: 38,
                                marginLeft: 16,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Body3Regular style={{ color: colors.LAVEL_0 }}>OFF</Body3Regular>
                        </Row>
                    )}

                    <HoverImage
                        onClick={() => {
                            setModalVisible(false);
                            setDetailObject(null);
                        }}
                        src={I_POPUP_EXIT}
                        style={{ marginTop: 16, width: 32, height: 32, marginLeft: 494 }}
                    />
                </Row>

                <Row style={{ marginTop: 48, alignItems: 'center' }}>
                    <Col style={{ width: 310, height: 68, marginLeft: 72, justifyContent: 'space-between' }}>
                        <Body3Regular style={{ color: colors.LAVEL_4 }}>
                            {detailObject?.type === 'onetimeSchedule' ? '일정 제목' : '담당자'}
                        </Body3Regular>
                        <Row
                            style={{
                                width: 310,
                                height: 36,
                                borderRadius: 36,
                                border: '1px solid #ededed',
                                paddingLeft: 16,
                                backgroundColor: colors.WHITE_500,
                                alignItems: 'center',
                            }}
                        >
                            {detailObject?.type === 'onetimeSchedule' ? (
                                <Body3Regular style={{ color: colors.LAVEL_1 }}>{`${detailObject?.name}`}</Body3Regular>
                            ) : (
                                <Body3Regular
                                    style={{ color: colors.LAVEL_1 }}
                                >{`${detailObject?.coachName} 강사`}</Body3Regular>
                            )}
                        </Row>
                    </Col>

                    <Col style={{ width: 340, height: 68, marginLeft: 104, justifyContent: 'space-between' }}>
                        <Body3Regular style={{ color: colors.LAVEL_4 }}>날짜</Body3Regular>
                        <Row
                            style={{
                                width: 340,
                                height: 36,
                                borderRadius: 36,
                                border: '1px solid #ededed',
                                paddingLeft: 16,
                                backgroundColor: colors.WHITE_500,
                                alignItems: 'center',
                            }}
                        >
                            <Body3Regular style={{ color: colors.LAVEL_1 }}>{`${dayjs(detailObject?.startTime).format(
                                'YYYY년 MM월 DD일 (ddd)'
                            )}`}</Body3Regular>
                        </Row>
                    </Col>
                </Row>

                <Row style={{ marginTop: 48, alignItems: 'center' }}>
                    <Col style={{ width: 310, height: 68, marginLeft: 72, justifyContent: 'space-between' }}>
                        {detailObject?.type === 'onetimeSchedule' && (
                            <>
                                <Body3Regular style={{ color: colors.LAVEL_4 }}>담당자</Body3Regular>
                                <Row
                                    style={{
                                        width: 310,
                                        height: 36,
                                        borderRadius: 36,
                                        border: '1px solid #ededed',
                                        paddingLeft: 16,
                                        backgroundColor: colors.WHITE_500,
                                        alignItems: 'center',
                                    }}
                                >
                                    <Body3Regular
                                        style={{ color: colors.LAVEL_1 }}
                                    >{`${detailObject?.coachName}`}</Body3Regular>
                                </Row>
                            </>
                        )}
                    </Col>

                    <Col style={{ width: 340, height: 68, marginLeft: 104, justifyContent: 'space-between' }}>
                        <Body3Regular style={{ color: colors.LAVEL_4 }}>시간</Body3Regular>
                        <Row
                            style={{
                                width: 340,
                                height: 36,
                                borderRadius: 36,
                                border: '1px solid #ededed',
                                paddingLeft: 16,
                                backgroundColor: colors.WHITE_500,
                                alignItems: 'center',
                            }}
                        >
                            <Body3Regular style={{ color: colors.LAVEL_1 }}>{`${dayjs(detailObject?.startTime).format(
                                'A hh시 mm분 ~ '
                            )}  ${dayjs(detailObject?.endTime).format('A hh시 mm분')}`}</Body3Regular>
                        </Row>
                    </Col>
                </Row>

                {detailObject?.type === 'onetimeSchedule' && (
                    <>
                        <Row
                            style={{
                                width: 312,
                                marginLeft: 580,
                                marginTop: 64,
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <WhiteSquareButton text="일정 삭제" size="middle" callBack={onClickDeleteButton} />
                            <ColorSquareButton text="수정하기" size="middle" callBack={onClickUpdateButton} />
                        </Row>
                    </>
                )}
                {detailObject?.type === 'block' && (
                    <>
                        <Row
                            style={{
                                width: 312,
                                marginLeft: 744,
                                marginTop: 64,
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <WhiteSquareButton text="일정 삭제" size="middle" callBack={onClickDeleteButton} />
                        </Row>
                    </>
                )}
            </ModalInner>
        </ModalOverLay>
    );
};

export default OffEtcDetailModal;
