import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { getWeeklyScheduleAPI } from '../../../api/schedule';
import { Col, Row } from '../../../lib/utils';
import WeekList from './WeekList';
import WeekScheduleTable from './WeekScheduleTable';
import { colors } from '../../../lib/colors';

const WeeklySchedule = ({
    setIsLoading,
    grid,
    currentDate,
    authorization,
    authObject,
    selectedDay,
    setSelectedDay,
    onClickFloatingButton,
    onClickSchedule,
    onClickRequest,
    onClickEtcOffSchedule,
    onClickApproveScheduleRequest,
    onClickRejectScheduleRequest,
    scrollY,
    weeklyScheduleList,
    weeklyGridData,
    lengthArr,
}: any) => {
    return (
        <Col style={{ marginTop: 88 }}>
            {/* 주간 달력 상단 날짜 표시 */}
            <WeekList scrollY={scrollY} weeklyScheduleList={weeklyScheduleList} />
            {/* 주간 달력 표 */}
            <WeekScheduleTable
                weeklyScheduleList={weeklyScheduleList}
                weeklyGridData={weeklyGridData}
                lengthArr={lengthArr}
                scrollY={scrollY}
                onClickSchedule={onClickSchedule}
                onClickRequest={onClickRequest}
                onClickEtcOffSchedule={onClickEtcOffSchedule}
                onClickApproveScheduleRequest={onClickApproveScheduleRequest}
                onClickRejectScheduleRequest={onClickRejectScheduleRequest}
            />
        </Col>
    );
};

export default WeeklySchedule;
