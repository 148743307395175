import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import ModalClassHistoryHeader from './ModalClassHistoryHeader';
import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import { getSpecificMemberClassHistoryAPI } from '../../api/employee';
import ModalClassHistoryThisMonth from './ModalClassHistoryThisMonth';
import Scrollbars from 'react-custom-scrollbars-2';
import ModalClassHistoryThisYear from './ModalClassHistoryThisYear';
import ModalClassHistoryEntire from './ModalClassHistoryEntire';
import { useMutation } from '@tanstack/react-query';
import { storeUserFlow } from '../../api/adminuserflow';
import { thisMonthDummyData, thisYearDummyData, totalViewDummyData } from './DummyData';
import { Col } from '../../lib/utils';
import NormalButton from '../Button/NormalButton';
import BackButton from '../Button/BackButton';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
`;

const ModalInner = styled.div`
    background: white;
    width: 1400px;
    height: 780px;
    border-radius: 24px;
    box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.12);
`;

type Props = {
    modalVisible: boolean;
    setModalVisible: Dispatch<SetStateAction<boolean>>;
    authorization?: any;
    employeeId: string;
    searchMemberName: string;
    setSearchMemberName: Dispatch<SetStateAction<string>>;
    authObject: any;
    isClickedHistoryAtInfoModal: boolean;
    setIsClickedHistoryAtInfoModal: Dispatch<SetStateAction<boolean>>;
    onClickBackMemberInfo: () => void;
};

const ModalClassHistory = ({
    modalVisible,
    setModalVisible,
    authorization,
    employeeId,
    searchMemberName,
    setSearchMemberName,
    authObject,
    isClickedHistoryAtInfoModal,
    setIsClickedHistoryAtInfoModal,
    onClickBackMemberInfo,
}: Props) => {
    // 초기값 설정
    const [currentDate, setCurrentDate] = useState(dayjs());
    const [searchText, setSearchText] = useState<string>('');
    const [isMonth, setIsMonth] = useState<boolean>(true);
    const [isYear, setIsYear] = useState<boolean>(false);
    const [isEntire, setIsEntire] = useState<boolean>(false);

    /**
     * thisMonthList : api로 전달받은 이번달의 모든 스케줄기록
     * thisMonthFilterList : 이번달의 모든 스케줄기록에서 검색필터가 적용된 스케줄 리스트
     */
    const [thisMonthList, setThisMonthList] = useState<any>(null);
    const [thisMonthFilterList, setThisMonthFilterList] = useState<any>([]);

    const [thisYearList, setThisYearList] = useState<any>(null);
    const [thisYearFilterList, setThisYearFilterList] = useState<any>([]);

    const [entireList, setEntireList] = useState<any>(null);
    const [entireFilterList, setEntireFilterList] = useState<any>([]);

    const settingDate = (type: string) => {
        if (type === 'month') {
            setIsMonth(true);
            setIsYear(false);
            setIsEntire(false);
            setCurrentDate(dayjs());
        }

        if (type === 'year') {
            setIsMonth(false);
            setIsYear(true);
            setIsEntire(false);
            setCurrentDate(dayjs());
        }

        if (type === 'entire') {
            setIsMonth(false);
            setIsYear(false);
            setIsEntire(true);
            setCurrentDate(dayjs());
        }
    };

    const onClickAddDate = () => {
        if (isMonth) {
            const newDate = dayjs(currentDate).add(1, 'month');
            setCurrentDate(newDate);
        }

        if (isYear) {
            const newDate = dayjs(currentDate).add(1, 'year');
            setCurrentDate(newDate);
        }
    };

    const onClickSubtractDate = () => {
        if (isMonth) {
            const newDate = dayjs(currentDate).subtract(1, 'month');
            setCurrentDate(newDate);
        }

        if (isYear) {
            const newDate = dayjs(currentDate).subtract(1, 'year');
            setCurrentDate(newDate);
        }
    };

    const onClickExit = () => {
        setSearchMemberName('');
        setModalVisible(false);
        setIsClickedHistoryAtInfoModal(false);
    };

    //  담당회원 정보 리스트 조회 API : (GET)
    const getSpecificMemberClassHistory = useQuery(
        ['getSpecificMemberClassHistoryAPI', employeeId, currentDate],
        async () =>
            await getSpecificMemberClassHistoryAPI(authorization, employeeId, dayjs(currentDate).format('YYYY-MM-DD')),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const thisMonth: any = res.data.thisMonth;
                    const thisYear = res.data.thisYear;
                    const entire = res.data.totalView;

                    if (thisMonth) {
                        let thisMonthKey = Object.keys(thisMonth)[0];
                        let thisMonthList = thisMonth[thisMonthKey];
                        setThisMonthList(thisMonthList ? thisMonthList : []);
                    }

                    if (thisYear) {
                        let thisYearKey = Object.keys(thisYear)[0];
                        let thisYearList = thisYear[thisYearKey];
                        setThisYearList(thisYearList ? thisYearList : []);
                    }

                    if (entire) {
                        setEntireList(entire);
                    }
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!employeeId && !!!authObject?.testAccount,
            // refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    useEffect(() => {
        if (searchMemberName) {
            setSearchText(searchMemberName);
        }
    }, [searchMemberName]);

    useEffect(() => {
        if (thisMonthList) {
            let thisMonthLengthCheckArr: any = [];
            for (let i = 0; i < thisMonthList.length; i++) {
                let keys = Object.keys(thisMonthList[i]);
                let dayObject = thisMonthList[i][keys[0]];

                let findFilterList: any = [];
                if (searchText === '') {
                    findFilterList = dayObject;
                } else {
                    findFilterList = dayObject.filter((e: any) => e.alias.includes(searchText));
                }

                if (findFilterList) {
                    for (let j = 0; j < findFilterList.length; j++) {
                        thisMonthLengthCheckArr.push(findFilterList[j]);
                    }
                }
            }
            setThisMonthFilterList(thisMonthLengthCheckArr);
        }

        if (thisYearList) {
            let thisYearLengthCheckArr: any = [];
            for (let i = 0; i < thisYearList.length; i++) {
                let keys = Object.keys(thisYearList[i]);
                let monthArr = thisYearList[i][keys[0]];
                for (let j = 0; j < monthArr.length; j++) {
                    let dayKeys = Object.keys(monthArr[j]);
                    let dayObject = monthArr[j][dayKeys[0]];

                    let findFilterList = [];

                    if (searchText === '') {
                        findFilterList = dayObject;
                    } else {
                        findFilterList = dayObject.filter((e: any) => e.alias.includes(searchText));
                    }

                    if (findFilterList) {
                        for (let k = 0; k < findFilterList.length; k++) {
                            thisYearLengthCheckArr.push(findFilterList[k]);
                        }
                    }
                }
            }

            setThisYearFilterList(thisYearLengthCheckArr);
        }

        if (entireList) {
            let entireLengthCheckArr: any = [];
            for (let i = 0; i < entireList.length; i++) {
                let keys = Object.keys(entireList[i]);
                let yearArr = entireList[i][keys[0]];

                for (let j = 0; j < yearArr.length; j++) {
                    let monthKeys = Object.keys(yearArr[j]);
                    let monthArr = yearArr[j][monthKeys[0]];

                    for (let k = 0; k < monthArr.length; k++) {
                        let dayKeys = Object.keys(monthArr[k]);
                        let dayObject = monthArr[k][dayKeys[0]];

                        let findFilterList = [];

                        if (searchText === '') {
                            findFilterList = dayObject;
                        } else {
                            findFilterList = dayObject.filter((e: any) => e.alias.includes(searchText));
                        }

                        if (findFilterList) {
                            for (let l = 0; l < findFilterList.length; l++) {
                                entireLengthCheckArr.push(findFilterList[l]);
                            }
                        }
                    }
                }
            }
            setEntireFilterList(entireLengthCheckArr);
        }
    }, [searchText, thisMonthList, thisYearList, entireList]);

    // 접속 데이터 측정 API
    const pushUserFlow = useMutation({
        mutationFn: async () => await storeUserFlow('memberScheduleHistory', authorization),
    });

    useEffect(() => {
        try {
            if (pushUserFlow) {
                pushUserFlow.mutate();
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        if (totalViewDummyData && thisYearDummyData && thisMonthDummyData && authObject?.testAccount) {
            const thisMonth: any = thisMonthDummyData;
            const thisYear = thisYearDummyData;
            const entire = totalViewDummyData;

            if (thisMonth) {
                let thisMonthKey = Object.keys(thisMonth)[0];
                let thisMonthList = thisMonth[thisMonthKey];
                setThisMonthList(thisMonthList);
            }

            if (thisYear) {
                let thisYearKey = Object.keys(thisYear)[0];
                let thisYearList = thisYear[thisYearKey];
                setThisYearList(thisYearList);
            }

            if (entire) {
                setEntireList(entire);
            }
        }
    }, [totalViewDummyData, thisYearDummyData, thisMonthDummyData]);

    return (
        <ModalOverLay>
            <ModalInner>
                <ModalClassHistoryHeader
                    isMonth={isMonth}
                    isYear={isYear}
                    isEntire={isEntire}
                    currentDate={currentDate}
                    settingDate={settingDate}
                    onClickExit={onClickExit}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    thisMonthFilterList={thisMonthFilterList}
                    thisYearFilterList={thisYearFilterList}
                    entireFilterList={entireFilterList}
                    onClickAddDate={onClickAddDate}
                    onClickSubtractDate={onClickSubtractDate}
                />
                {isClickedHistoryAtInfoModal && (
                    <BackButton
                        style={{ position: 'absolute', zIndex: 5, marginLeft: 1184, marginTop: 474 }}
                        onClick={onClickBackMemberInfo}
                    />
                )}
                <Scrollbars
                    autoHide
                    autoHideTimeout={800}
                    autoHideDuration={200}
                    style={{
                        height: 570,
                    }}
                >
                    {isMonth && (
                        <ModalClassHistoryThisMonth
                            thisMonthList={thisMonthList}
                            thisMonthFilterList={thisMonthFilterList}
                            searchText={searchText}
                        />
                    )}
                    {isYear && (
                        <ModalClassHistoryThisYear
                            thisYearList={thisYearList}
                            thisYearFilterList={thisYearFilterList}
                            searchText={searchText}
                        />
                    )}
                    {isEntire && (
                        <ModalClassHistoryEntire
                            entireList={entireList}
                            entireFilterList={entireFilterList}
                            searchText={searchText}
                        />
                    )}
                </Scrollbars>
            </ModalInner>
        </ModalOverLay>
    );
};

export default ModalClassHistory;
