import React from 'react';
import { Row } from '../../lib/utils';
import { Body3Regular } from '../../lib/font';
import { colors } from '../../lib/colors';

const CommunicationListHeader = () => {
    return (
        <Row
            style={{
                width: 1192,
                height: 56,
                borderRadius: '15px 15px 0px 0px',
                border: '1px solid #C3D4F4',
                alignItems: 'center',
                backgroundColor: colors.WHITE_50,
                borderBottom: 'none',
            }}
        >
            <Row
                style={{
                    width: 48,
                    height: 32,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: 24,
                    marginRight: 24,
                    color: colors.LAVEL_3,
                }}
            >
                <Body3Regular>답변</Body3Regular>
            </Row>

            <div style={{ width: 1, height: 24, backgroundColor: colors.BLUE_100 }} />

            <Row
                style={{
                    width: 108,
                    height: 32,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: 24,
                    marginRight: 24,
                    color: colors.LAVEL_3,
                }}
            >
                <Body3Regular>문의유형</Body3Regular>
            </Row>

            <div style={{ width: 1, height: 24, backgroundColor: colors.BLUE_100 }} />

            <Row
                style={{
                    width: 112,
                    height: 32,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: 24,
                    marginRight: 24,
                    color: colors.LAVEL_3,
                }}
            >
                <Body3Regular>회원 이름</Body3Regular>
            </Row>

            <div style={{ width: 1, height: 24, backgroundColor: colors.BLUE_100 }} />

            <Row
                style={{
                    width: 112,
                    height: 32,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: 24,
                    marginRight: 24,
                    color: colors.LAVEL_3,
                }}
            >
                <Body3Regular>담당 강사</Body3Regular>
            </Row>

            <div style={{ width: 1, height: 24, backgroundColor: colors.BLUE_100 }} />

            <Row
                style={{
                    width: 404,
                    height: 32,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: 24,
                    marginRight: 24,
                    color: colors.LAVEL_3,
                }}
            >
                <Body3Regular>내용</Body3Regular>
            </Row>

            <div style={{ width: 1, height: 24, backgroundColor: colors.BLUE_100 }} />

            <Row
                style={{
                    width: 120,
                    height: 32,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: 24,

                    color: colors.LAVEL_3,
                }}
            >
                <Body3Regular>등록일</Body3Regular>
            </Row>
        </Row>
    );
};

export default CommunicationListHeader;
