import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { Row } from '../../../lib/utils';
import { I_BLUE_CLOCK } from '../../../types/images';
import { Body1Regular, Head3, Subtitle1Bold } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import dayjs, { Dayjs } from 'dayjs';
import Scrollbars from 'react-custom-scrollbars-2';
import styled from 'styled-components';

const HoverButton = styled.button`
    width: 168px;
    height: 54px;
    background-color: rgba(245, 249, 255, 0.36);
    box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.12);
    color: rgba(98, 101, 107, 1);
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 400px;
    cursor: pointer;
    &:hover {
        /* background-color: rgba(245, 249, 255, 0.36); */
        color: rgba(26, 48, 93, 1);
        font-weight: 700;
    }
`;

type Props = {
    startTime: Dayjs;
    setStartTime: any;
    endTime: Dayjs;
    setEndTime: any;
    timeList: any;
    isStartTime: boolean;
    isEndTime: boolean;
    setIsStartTime: Dispatch<SetStateAction<boolean>>;
    setIsEndTime: Dispatch<SetStateAction<boolean>>;
};
const ScheduleTime = ({
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    timeList,
    isStartTime,
    setIsStartTime,
    isEndTime,
    setIsEndTime,
}: Props) => {
    const refStartTime = useRef<any>(null);
    const refEndTime = useRef<any>(null);

    useEffect(() => {
        if (isStartTime) {
            const convertStartHour = parseInt(dayjs(startTime).format('HH')) * 6;
            const convertStartMin = Math.floor(parseInt(dayjs(startTime).format('mm')) / 10);
            const resultScrollRef = convertStartHour + convertStartMin;
            if (refStartTime?.current) {
                refStartTime.current.scrollTop(resultScrollRef * 58);
            }
        }

        if (isEndTime) {
            const convertEndHour = parseInt(dayjs(endTime).format('HH')) * 6;
            const convertEndMin = Math.floor(parseInt(dayjs(endTime).format('mm')) / 10);
            const resultScrollRef = convertEndHour + convertEndMin;
            if (refEndTime?.current) {
                refEndTime.current.scrollTop(resultScrollRef * 58);
            }
        }
    }, [isStartTime, isEndTime]);

    return (
        <Row style={{ width: 560, height: 60, marginLeft: 70, marginTop: 48, alignItems: 'center' }}>
            <img src={I_BLUE_CLOCK} style={{ width: 36, height: 36 }} />
            <Subtitle1Bold style={{ color: colors.WHITE_900, marginLeft: 16 }}>시간</Subtitle1Bold>
            <Row style={{ width: 414, justifyContent: 'space-between', alignItems: 'center', marginLeft: 60 }}>
                <button
                    style={{
                        width: 168,
                        height: 60,
                        backgroundColor: colors.WHITE_500,
                        borderRadius: 48,
                        boxShadow: '4px 4px 8px 1px #213E7B29 inset',
                        border: 'none',
                        zIndex: 2,
                    }}
                    onClick={() => setIsStartTime(true)}
                >
                    <Body1Regular style={{ color: colors.WHITE_900 }}>
                        {dayjs(startTime).format('A hh시 mm분')}
                    </Body1Regular>
                </button>
                <Head3 style={{ color: colors.WHITE_900 }}>~</Head3>
                <button
                    style={{
                        width: 168,
                        height: 60,
                        backgroundColor: colors.WHITE_500,
                        borderRadius: 48,
                        boxShadow: '4px 4px 8px 1px #213E7B29 inset',
                        border: 'none',
                        zIndex: 2,
                    }}
                    onClick={() => setIsEndTime(true)}
                >
                    <Body1Regular style={{ color: colors.WHITE_900 }}>
                        {dayjs(endTime).format('A hh시 mm분')}
                    </Body1Regular>
                </button>
            </Row>

            {isStartTime && (
                <Scrollbars
                    ref={refStartTime}
                    style={{
                        width: 168,
                        height: 361,
                        borderBottomLeftRadius: 12,
                        borderBottomRightRadius: 12,
                        borderRadius: 10,
                        position: 'absolute',
                        marginTop: 350,
                        marginLeft: 143,
                        backgroundColor: 'rgba(245, 249, 255, 1)',
                        boxShadow: '4px 4px 12px 0px rgba(0, 0, 0, 0.12)',
                        zIndex: 1,
                    }}
                >
                    <Row style={{ width: 168, height: 40 }} />
                    {timeList &&
                        timeList.map((item: any, index: number) => {
                            return (
                                <HoverButton
                                    onClick={() => {
                                        setStartTime(dayjs(item.value));
                                        setIsStartTime(false);
                                    }}
                                    key={'startTimeListItem' + index}
                                >
                                    {item.name}
                                </HoverButton>
                            );
                        })}
                </Scrollbars>
            )}

            {isEndTime && (
                <Scrollbars
                    ref={refEndTime}
                    style={{
                        width: 168,
                        height: 361,
                        borderBottomLeftRadius: 12,
                        borderBottomRightRadius: 12,
                        borderRadius: 10,
                        position: 'absolute',
                        marginTop: 350,
                        marginLeft: 389,
                        backgroundColor: 'rgba(245, 249, 255, 1)',
                        boxShadow: '4px 4px 12px 0px rgba(0, 0, 0, 0.12)',
                        zIndex: 1,
                    }}
                >
                    <Row style={{ width: 168, height: 40 }} />
                    {timeList &&
                        timeList.map((item: any, index: number) => {
                            return (
                                <HoverButton
                                    onClick={() => {
                                        setEndTime(dayjs(item.value));
                                        setIsEndTime(false);
                                    }}
                                    key={'endTimeListItem' + index}
                                >
                                    {item.name}
                                </HoverButton>
                            );
                        })}
                </Scrollbars>
            )}
        </Row>
    );
};

export default ScheduleTime;
