import React, { CSSProperties } from "react";
import styled from "styled-components";
import { Row } from "../../lib/utils";
import {
  Body1Regular,
  Body2Regular,
  Body3Regular,
  CaptionRegular,
} from "../../lib/font";
import { I_WHITE_PLUS } from "../../types/buttonImage";

const ButtonWrap = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.disabled ? "#A5ABB5" : "#3d73dd")};
  color: ${(props) => (props.disabled ? "#EDF4FF" : "#FDFEFF")};
  &:not([disabled]):hover {
    background-color: #3869c9;
  }
  &:not([disabled]):active {
    background-color: #2b529d;
  }
`;

const GradientButtonWrap = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #3d73dd 0%, #6327e2 100%);
  color: ${(props) => (props.disabled ? "#EDF4FF" : "#FDFEFF")};
  &:not([disabled]):hover {
    background: #3869c9;
  }
  &:not([disabled]):active {
    background: #2b529d;
  }
`;

type Props = {
  text: string;
  isVisibleLeftImg?: boolean;
  isVisibleRightImg?: boolean;
  isDisabled?: boolean;
  size: "big" | "middle" | "small" | "tiny";
  customStyle?: CSSProperties;
  callBack?: () => void;
  isGradient?: boolean;
};

const ColorSquareButton = ({
  text,
  isVisibleLeftImg = false,
  isVisibleRightImg = false,
  isDisabled = false,
  size,
  customStyle,
  callBack,
  isGradient = false,
}: Props) => {
  let BigStyle: CSSProperties = {
    width: 217,
    height: 64,
  };

  let MiddleStyle: CSSProperties = {
    width: 148,
    height: 48,
  };

  let SmallStyle: CSSProperties = {
    width: 113,
    height: 32,
  };

  let TinyStyle: CSSProperties = {
    width: 81,
    height: 24,
  };

  let sizeStyle =
    size === "big"
      ? BigStyle
      : size === "middle"
      ? MiddleStyle
      : size === "small"
      ? SmallStyle
      : TinyStyle;

  let combinedStyle = { ...sizeStyle, ...customStyle };

  return (
    <>
      {isGradient ? (
        <GradientButtonWrap
          className={"flex"}
          style={combinedStyle}
          disabled={isDisabled}
          onClick={callBack}
        >
          {(size === "big" || size === "middle") && isVisibleLeftImg && (
            <Row
              style={{
                width: 24,
                height: 24,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={I_WHITE_PLUS} style={{ width: 18, height: 18 }} />
            </Row>
          )}

          {size === "big" && (
            <Body1Regular style={{ marginLeft: 8, marginRight: 8 }}>
              {text}
            </Body1Regular>
          )}
          {size === "middle" && (
            <Body2Regular style={{ marginLeft: 8, marginRight: 8 }}>
              {text}
            </Body2Regular>
          )}
          {size === "small" && <Body3Regular>{text}</Body3Regular>}
          {size === "tiny" && <CaptionRegular>{text}</CaptionRegular>}

          {(size === "big" || size === "middle") && isVisibleRightImg && (
            <Row
              style={{
                width: 24,
                height: 24,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={I_WHITE_PLUS} style={{ width: 18, height: 18 }} />
            </Row>
          )}
        </GradientButtonWrap>
      ) : (
        <ButtonWrap
          style={combinedStyle}
          disabled={isDisabled}
          onClick={callBack}
        >
          {(size === "big" || size === "middle") && isVisibleLeftImg && (
            <Row
              style={{
                width: 24,
                height: 24,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={I_WHITE_PLUS} style={{ width: 18, height: 18 }} />
            </Row>
          )}

          {size === "big" && (
            <Body1Regular style={{ marginLeft: 8, marginRight: 8 }}>
              {text}
            </Body1Regular>
          )}
          {size === "middle" && (
            <Body2Regular style={{ marginLeft: 8, marginRight: 8 }}>
              {text}
            </Body2Regular>
          )}
          {size === "small" && <Body3Regular>{text}</Body3Regular>}
          {size === "tiny" && <CaptionRegular>{text}</CaptionRegular>}

          {(size === "big" || size === "middle") && isVisibleRightImg && (
            <Row
              style={{
                width: 24,
                height: 24,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={I_WHITE_PLUS} style={{ width: 18, height: 18 }} />
            </Row>
          )}
        </ButtonWrap>
      )}
    </>
  );
};

export default ColorSquareButton;
