import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Row } from '../../lib/utils';
import { CaptionRegular } from '../../lib/font';
import { I_PRIMARY_TRIANGLE } from '../../types/images';
import { colors } from '../../lib/colors';
import { CodeValueType } from '../../types/types';
import TextSelector, { FontsType } from '../TextSelector';

// Styled Components
const DropdownContainer = styled.div<{ mr?: number }>`
    position: relative; // 수정된 부분
    display: inline-block;
    cursor: pointer;
    margin-right: ${({ mr }) => (mr ? `${mr}px` : '0px')};
`;

const DropdownButton = styled.button`
    background-color: #fdfeff;
    position: relative;
    color: #4a4a4a;
    display: flex;
    align-items: center;

    border-radius: 24px;
    border: 1px solid #d4dbe8;
    cursor: pointer;
    z-index: 2;
`;

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideUp = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

const DropdownContent = styled.div<{ isOpen: boolean }>`
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    position: absolute; // 수정된 부분
    background-color: #f0f6ff;
    padding-left: 2px;
    box-shadow: 0px 1px 2px 0px #25252514;
    padding-bottom: 8px;
    padding-top: 24px;

    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    animation: ${({ isOpen }) => (isOpen ? slideDown : slideUp)} 0.3s ease forwards;
    z-index: 1; // 수정된 부분
`;

const DropdownItem = styled.div`
    text-decoration: none;
    display: flex;
    align-items: center;
    background-color: #fdfeff;
    margin-top: 4px;
    padding-left: 16px;
    z-index: 9999; // 수정된 부분
    cursor: pointer;
    &:hover {
        background-color: #f1f1f1;
    }
`;
interface I_Props<T extends string> {
    selectData?: CodeValueType<T>;
    datas: CodeValueType<T>[];
    onClickSort: (item: CodeValueType<T>) => void;
    mr?: number;
    width?: number;
    height?: number;
    fontType?: FontsType;
}

const NewRoundedDropDown = <T extends string>({
    selectData,
    datas,
    onClickSort,
    mr,
    width,
    height,
    fontType = 'CaptionRegular',
}: I_Props<T>) => {
    const [isOpenSort, setIsOpenSort] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpenSort && setIsOpenSort(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <DropdownContainer ref={dropdownRef} mr={mr}>
            <DropdownButton
                style={{
                    zIndex: 2,
                    width: width ? width : 180,
                    height: height ? height : 36,
                }}
                onClick={() => setIsOpenSort(!isOpenSort)}
            >
                <Row
                    style={{
                        width: width ? width - 32 : 132,
                        height: 48,
                        paddingLeft: 16,
                        alignItems: 'center',
                    }}
                >
                    <TextSelector text={selectData?.value ?? ''} fontType={fontType} />
                </Row>
                <Row
                    style={{
                        width: 32,
                        height: 32,
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderLeft: '1px solid #D4DBE8',
                        borderColor: colors.WHITE_600,
                    }}
                >
                    <img
                        style={{
                            width: 8,
                            height: 14,
                            transform: isOpenSort ? 'rotate(270deg)' : 'rotate(90deg)',
                        }}
                        src={I_PRIMARY_TRIANGLE}
                    />
                </Row>
            </DropdownButton>
            <DropdownContent
                style={{
                    width: width ? width : 180,
                    top: height ? height : 10,
                }}
                isOpen={isOpenSort}
            >
                {datas.map((data: any, index: number) => (
                    <DropdownItem
                        style={{
                            width: width ? width : 180,
                            height: height ? height : 36,
                        }}
                        key={data.value}
                        onClick={() => {
                            setIsOpenSort(false);
                            onClickSort(data);
                        }}
                    >
                        <CaptionRegular> {data.value}</CaptionRegular>
                    </DropdownItem>
                ))}
            </DropdownContent>
        </DropdownContainer>
    );
};

export default NewRoundedDropDown;
