import React, { useEffect, useState } from 'react';
import { Col, Row } from '../../../lib/utils';
import ProfileImage from '../../ProfileImage';
import { Body2Bold, Body3Bold, CaptionRegular, OverlineRegular } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import { I_CLOSE, I_ERROR, I_LEFT_ANGLE, I_RIGHT_ANGLE } from '../../../types/images';
import ColorRoundButton from '../../Button/ColorRoundButton';
import InputSearchDropdown from '../../DropDown/InputSearchDropdown';

const UpdateOneOnOneSchedule = ({
    selectedMember,
    onClickDeleteSelectedMember,
    onClickClassPassPage,
    filteredMemberList,
    handleMemberSelect,
    searchText,
    onChangeText,
    isDisabledInput,
    coach,

    oneOnOneMemberShipIndex,
    setOneOnOneMemberShipIndex,
}: any) => {
    const memberShipLength = selectedMember[0]?.memberships ? selectedMember[0]?.memberships.length : 0;

    return selectedMember && selectedMember[0]?.value ? (
        <Col>
            <Row
                style={{
                    width: 382,
                    height: 48,
                    borderRadius: 8,
                    border: '1px solid #648fe4',
                    cursor: 'pointer',
                    paddingLeft: 16,
                    paddingRight: 16,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Row style={{ alignItems: 'center' }}>
                    <ProfileImage
                        imageUrl={selectedMember[0]?.profileImageUrl}
                        type="Small"
                        profileName={selectedMember[0]?.profileName}
                        profileColorType={selectedMember[0]?.profileColorType}
                    />
                    <Body3Bold
                        style={{ marginLeft: 16, color: colors.LAVEL_4 }}
                    >{`${selectedMember[0]?.label} 회원`}</Body3Bold>
                </Row>
            </Row>

            {selectedMember[0]?.memberships ? (
                <Col>
                    <Row
                        style={{
                            width: 382,
                            height: 160,
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: 12,
                        }}
                    >
                        <Row
                            onClick={() => {
                                if (oneOnOneMemberShipIndex === 1) {
                                    return;
                                } else {
                                    setOneOnOneMemberShipIndex(oneOnOneMemberShipIndex - 1);
                                }
                            }}
                            style={{
                                width: 32,
                                height: 32,
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                            }}
                        >
                            <img src={I_LEFT_ANGLE} style={{ width: 24, height: 24 }} />
                        </Row>
                        <Col
                            style={{
                                width: 310,
                                height: 160,
                                borderRadius: 8,
                                backgroundColor: colors.WHITE_50,
                                boxShadow: '2px 2px 8px 0px #00000029',
                                overflow: 'hidden',
                            }}
                        >
                            <Row
                                style={{
                                    width: 310,
                                    height: 88,
                                    background: `${
                                        selectedMember[0]?.memberships[oneOnOneMemberShipIndex - 1]?.status === 'active'
                                            ? `linear-gradient(135deg, ${
                                                  selectedMember[0]?.memberships[oneOnOneMemberShipIndex - 1]
                                                      ?.BGColor[0]
                                              } 0%, ${
                                                  selectedMember[0]?.memberships[oneOnOneMemberShipIndex - 1]
                                                      ?.BGColor[1]
                                              } 100%)`
                                            : colors.GREY_200
                                    }`,
                                    paddingLeft: 24,

                                    alignItems: 'center',
                                }}
                            >
                                <Body2Bold style={{ color: colors.LAVEL_0 }}>{`${
                                    selectedMember[0]?.memberships[oneOnOneMemberShipIndex - 1]?.name
                                }`}</Body2Bold>
                            </Row>

                            <Row
                                style={{
                                    width: 310,
                                    height: 72,
                                    paddingTop: 16,
                                    paddingLeft: 24,
                                    paddingRight: 30,
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Col style={{ width: 64, height: 40, justifyContent: 'space-between' }}>
                                    <OverlineRegular style={{ color: colors.LAVEL_3 }}>{'등록 가능'}</OverlineRegular>
                                    <Body3Bold style={{ color: colors.LAVEL_4 }}>{`${
                                        selectedMember[0]?.memberships[oneOnOneMemberShipIndex - 1]
                                            ?.canMakeScheduleSession
                                    }회`}</Body3Bold>
                                </Col>
                                <Col style={{ width: 64, height: 40, justifyContent: 'space-between' }}>
                                    <OverlineRegular style={{ color: colors.LAVEL_3 }}>{'유효 기간'}</OverlineRegular>
                                    <Body3Bold style={{ color: colors.LAVEL_4 }}>{`${
                                        selectedMember[0]?.memberships[oneOnOneMemberShipIndex - 1]?.remainPeriod
                                    }일`}</Body3Bold>
                                </Col>
                                <Col style={{ width: 64, height: 40, justifyContent: 'space-between' }}>
                                    <OverlineRegular style={{ color: colors.LAVEL_3 }}>{'수업 시간'}</OverlineRegular>
                                    <Body3Bold style={{ color: colors.LAVEL_4 }}>{`${
                                        selectedMember[0]?.memberships[oneOnOneMemberShipIndex - 1]?.period
                                    }분`}</Body3Bold>
                                </Col>
                            </Row>
                        </Col>
                        <Row
                            onClick={() => {
                                if (oneOnOneMemberShipIndex === memberShipLength) {
                                    return;
                                } else {
                                    setOneOnOneMemberShipIndex(oneOnOneMemberShipIndex + 1);
                                }
                            }}
                            style={{
                                width: 32,
                                height: 32,
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                            }}
                        >
                            <img src={I_RIGHT_ANGLE} style={{ width: 24, height: 24 }} />
                        </Row>
                    </Row>

                    <CaptionRegular
                        style={{ color: colors.LAVEL_1, marginLeft: 180, marginTop: 12 }}
                    >{`${oneOnOneMemberShipIndex} / ${memberShipLength}`}</CaptionRegular>
                </Col>
            ) : (
                <Col
                    style={{
                        width: 383,
                        height: 140,
                        border: '1px solid #ff9ba1',
                        marginTop: 12,
                        borderRadius: 8,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Row style={{ alignItems: 'center', marginBottom: 18 }}>
                        <img src={I_ERROR} style={{ width: 18, height: 18 }} />
                        <Body3Bold style={{ color: colors.LAVEL_4, marginLeft: 8 }}>
                            등록된 수강권이 없는 회원이에요!
                        </Body3Bold>
                    </Row>
                    <ColorRoundButton text="수강권 추가하기" size="small" callBack={onClickClassPassPage} />
                </Col>
            )}
        </Col>
    ) : (
        <InputSearchDropdown
            dropDownOptions={filteredMemberList}
            handleSelectOption={handleMemberSelect}
            searchText={searchText}
            onChangeText={onChangeText}
            placeholder="회원 이름을 검색해주세요!"
            isDisabled={coach.value === '' || isDisabledInput}
        />
    );
};

export default UpdateOneOnOneSchedule;
