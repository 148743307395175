import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Col, Row, getItemWithExpiration } from '../../lib/utils';
import { useMutation } from '@tanstack/react-query';
import { createCommunicationReplyAPI, deleteCommunicationReplyAPI, editCommunicationReplyAPI } from '../../api/commBox';
import _ from 'lodash';
import ModalDetailCommunicationHeader from './ModalDetailCommunicationHeader';
import ModalDetailCommunicationBody from './ModalDetailCommunicationBody';
import ModalDetailCommunicationInputReply from './ModalDetailCommunicationInputReply';
import ModalDetailCommunicationBottom from './ModalDetailCommunicationBottom';
import { I_POPUP_EXIT } from '../../types/images';

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const HoverImage = styled.img`
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: flex-end;

    z-index: 2000;
`;

const ModalInner = styled.div`
    background: white;
    width: 708px;
    height: 928px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    display: flex;
    flex-direction: column;
    box-shadow: -4px -4px 24px 0px #0000002e;

    background-color: #fdfeff;
    animation: ${slideIn} 0.5s ease-out forwards;
`;

type Props = {
    setIsDetailModal: Dispatch<SetStateAction<boolean>>;
    communicationObject: any;
    setCommunicationObject: any;
    onClickRefetch: () => void;
    createTwoButtonAlert: any;
    createAlert: any;
    onClickPicture: (item: any) => void;
};

const ModalDetailCommunication = ({
    setIsDetailModal,
    communicationObject,
    setCommunicationObject,
    onClickRefetch,
    createTwoButtonAlert,
    createAlert,
    onClickPicture,
}: Props) => {
    const scrollRef: any = useRef(null);
    const textareaRef: any = useRef(null);
    const containerRef: any = useRef(null);
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));

    // 모달의 내용
    const [modalObject, setModalObject] = useState<any>(null);
    const [isInputMode, setIsInputMode] = useState<boolean>(false);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [text, setText] = useState('');
    const [editText, setEditText] = useState('');

    const handleEditChange = (event: any) => {
        setEditText(event.target.value);
        updateTextareaHeight();
        if (scrollRef) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    };

    const handleChange = (event: any) => {
        setText(event.target.value);
        updateTextareaHeight();

        if (scrollRef) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    };

    const updateTextareaHeight = () => {
        const textarea = textareaRef.current;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    const onClickReplyButton = () => {
        setIsInputMode(true);
    };

    const onClickEditReplyButton = () => {
        setIsEditMode(true);
    };

    const onClickCreateReply = () => {
        if (text.trim() === '') {
            createAlert('', '내용을 입력해 주세요');
        } else {
            createCommunicationReply.mutate();
        }
    };

    const onClickDeleteReply = () => {
        createTwoButtonAlert(
            '전달된 답변 삭제',
            `이미 전달된 답변입니다!\n답변을 삭제하시면 답변 대기상태로 돌아갑니다`,
            true,
            '취소',
            '삭제',
            () => {},
            () => {
                deleteCommunicationReply.mutate();
            }
        );
    };

    const onClickEditReply = () => {
        if (editText.trim() === '') {
            createAlert('', '내용을 입력해 주세요');
        } else {
            createTwoButtonAlert(
                '전달된 답변 수정',
                `이미 전달된 답변입니다!\n내용을 수정하시면 수정 완료되는 즉시 회원에게 다시 알림이 전달됩니다!`,
                true,
                '취소',
                '수정',
                () => {},
                () => {
                    editCommunicationReply.mutate();
                }
            );
        }
    };

    // 외부클릭시 드롭다운닫기
    const onClickOutSide = () => {
        setIsDetailModal(false);
    };

    // 부모의 스크롤을 막기
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    // 소통상자 답글 달기 API : (POST)
    const createCommunicationReply = useMutation({
        mutationFn: async () =>
            await createCommunicationReplyAPI(
                communicationObject?._id,
                communicationObject.memberId,
                communicationObject.adminId,
                text,
                authorization
            ),
        onSuccess: (res) => {
            if (res.status === 200) {
                const response = res.data.communicationBox;
                onClickRefetch();
                const cloneObject = _.cloneDeep(modalObject);
                cloneObject.status = 'DONE';
                cloneObject.contents = response?.contents;
                cloneObject.updatedTime = response?.updatedTime;

                setModalObject(cloneObject);

                setIsInputMode(false);
                createAlert(
                    '답글 보내기 완료',
                    '답변이 회원에게 성공적으로 전달되었습니다\n회원이 답변을 추가할 경우 답변대기상태로 돌아갑니다'
                );
            }

            if (res.status === 405) {
                onClickRefetch();
                setIsDetailModal(false);
                setCommunicationObject(null);
                createAlert('오류', '이미 답변이 완료되었습니다', true);
            }
        },
    });

    // 소통상자 답글 삭제 API : (DELETE)
    const deleteCommunicationReply = useMutation({
        mutationFn: async () => await deleteCommunicationReplyAPI(communicationObject?._id, authorization),
        onSuccess: (res) => {
            if (res.status === 200) {
                const response = res.data.communicationBox;
                onClickRefetch();
                const cloneObject = _.cloneDeep(modalObject);
                cloneObject.status = 'PENDING';
                cloneObject.contents = response?.contents;
                cloneObject.updatedTime = response?.updatedTime;
                setModalObject(cloneObject);
                setIsInputMode(false);
                setText('');
                createAlert('답글 삭제 완료', '답변이 성공적으로 삭제되었습니다');
            }
        },
    });

    // 소통상자 답글 수정 API : (PATCH)
    const editCommunicationReply = useMutation({
        mutationFn: async () => await editCommunicationReplyAPI(communicationObject?._id, editText, authorization),
        onSuccess: (res) => {
            if (res.status === 200) {
                const response = res.data.communicationBox;
                onClickRefetch();
                const cloneObject = _.cloneDeep(modalObject);
                cloneObject.status = 'DONE';
                cloneObject.contents = response?.contents;
                cloneObject.updatedTime = response?.updatedTime;
                setModalObject(cloneObject);
                setIsEditMode(false);
                setEditText(editText);
                createAlert('답글 수정 완료', '답변이 성공적으로 수정되었습니다');
            }
        },
    });

    useEffect(() => {
        if (communicationObject) {
            setModalObject(communicationObject);
        }
    }, []);

    useEffect(() => {
        if (modalObject) {
            modalObject.contents &&
                modalObject.contents.length !== 1 &&
                setEditText(modalObject.contents[modalObject.contents.length - 1].content);
        }
    }, [modalObject]);

    return (
        <ModalOverLay>
            <Row style={{ flex: 1 }} onClick={onClickOutSide}></Row>

            <ModalInner ref={containerRef}>
                {modalObject && (
                    <>
                        <Row
                            style={{
                                width: 708,
                                height: 56,
                                boxShadow: '0px 2px 6px 0px #00000014',
                            }}
                        >
                            <HoverImage
                                src={I_POPUP_EXIT}
                                style={{ width: 24, height: 24, marginLeft: 16, marginTop: 16 }}
                                onClick={() => {
                                    setCommunicationObject(null);
                                    setIsDetailModal(false);
                                }}
                            />
                        </Row>
                        <Col ref={scrollRef} style={{ maxHeight: 872, paddingBottom: 300, overflowY: 'auto' }}>
                            <ModalDetailCommunicationHeader
                                modalObject={modalObject}
                                setIsDetailModal={setIsDetailModal}
                                setCommunicationObject={setCommunicationObject}
                            />
                            <ModalDetailCommunicationBody
                                modalObject={modalObject}
                                isEditMode={isEditMode}
                                textareaRef={textareaRef}
                                editText={editText}
                                handleEditChange={handleEditChange}
                                onClickPicture={onClickPicture}
                            />

                            <ModalDetailCommunicationInputReply
                                isInputMode={isInputMode}
                                textareaRef={textareaRef}
                                text={text}
                                handleChange={handleChange}
                            />

                            <ModalDetailCommunicationBottom
                                modalObject={modalObject}
                                isInputMode={isInputMode}
                                isEditMode={isEditMode}
                                text={text}
                                editText={editText}
                                onClickCreateReply={onClickCreateReply}
                                onClickReplyButton={onClickReplyButton}
                                onClickEditReply={onClickEditReply}
                                onClickEditReplyButton={onClickEditReplyButton}
                                onClickDeleteReply={onClickDeleteReply}
                            />
                        </Col>
                    </>
                )}
            </ModalInner>
        </ModalOverLay>
    );
};

export default ModalDetailCommunication;
