import React from 'react';
import { Col, Row } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import { Body1Regular, Body3Regular } from '../../../lib/font';
import { I_EMPTY_CONDITION } from '../../../types/images';
import dayjs from 'dayjs';
import { CONDITION_TYPE } from '../../../lib/varialbe';

const FeedScheduleItem = ({ scheduleReport }: any) => {
    const scheduleInfo = scheduleReport?.scheduleInfo;
    const condition = scheduleReport?.condition;

    let conditionText = condition
        ? CONDITION_TYPE.find((item) => item.code === condition.todayCondition)?.text
        : '기록 안함';

    let conditionImg = condition
        ? CONDITION_TYPE.find((item) => item.code === condition.todayCondition)?.src
        : I_EMPTY_CONDITION;

    return (
        <Col
            style={{
                width: 722,
                height: 80,
                borderRadius: 8,
                border: '1px solid #C3D4F4',
                alignSelf: 'center',
                marginTop: 16,
                paddingLeft: 16,
                justifyContent: 'space-between',
                paddingTop: 12,
                paddingBottom: 12,
            }}
        >
            <Body3Regular style={{ color: colors.LAVEL_2 }}>
                {dayjs(scheduleInfo?.startTime).format('수업시간 : YY/MM/DD(ddd)/AHH:mm ~ ')}
                {dayjs(scheduleInfo?.endTime).format('YY/MM/DD(ddd)/AHH:mm')}
            </Body3Regular>
            <Row style={{ alignItems: 'center' }}>
                <Body3Regular style={{ color: colors.LAVEL_2 }}>{`회원권 : ${
                    scheduleInfo?.currentSession ? scheduleInfo?.currentSession : '?'
                }/${scheduleInfo?.totalSession ? scheduleInfo?.totalSession : '?'}회차`}</Body3Regular>
                <div
                    style={{
                        width: 2,
                        height: 2,
                        borderRadius: 1,
                        backgroundColor: colors.LAVEL_2,
                        marginLeft: 16,
                        marginRight: 16,
                    }}
                />
                <Body3Regular
                    style={{ color: colors.LAVEL_2, marginRight: 8 }}
                >{`컨디션 : ${conditionText}`}</Body3Regular>

                <img src={conditionImg} style={{ width: 18, height: 18 }} />

                <div
                    style={{
                        width: 2,
                        height: 2,
                        borderRadius: 1,
                        backgroundColor: colors.LAVEL_2,
                        marginLeft: 16,
                        marginRight: 16,
                    }}
                />

                <Body3Regular style={{ color: colors.LAVEL_2, marginRight: 8 }}>{'특이사항 : 없음'}</Body3Regular>
            </Row>
        </Col>
    );
};

export default FeedScheduleItem;
