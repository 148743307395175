import React, { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Row, getItemWithExpiration, setItemWithExpiration } from '../../lib/utils';
import { useMutation } from '@tanstack/react-query';
import { requestPaymentAPI, requestRegistrationCardAPI } from '../../api/charge';
import ModalSuccess from '../../components/SuccessSubscription/ModalSuccess';
import ModalFail from '../../components/SuccessSubscription/ModalFail';
import ModalAlert from '../../components/modal/ModalAlert';
import Loading from '../../components/Loading';
import NormalButton from '../../components/Button/NormalButton';
import {
    Body1Bold,
    Body1Regular,
    Body2Regular,
    Body3Light,
    Body3Regular,
    Head4,
    Subtitle1Bold,
    Subtitle1Regular,
} from '../../lib/font';
import { colors } from '../../lib/colors';
import dayjs from 'dayjs';
import { I_ALERT, I_PRIMARY_CHECK } from '../../types/images';

const SuccessPurchaseAtExpired = ({ createAlert, onClickTerm }: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams: any = new URLSearchParams(location.search);
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));
    const authKey: string = queryParams.get('authKey');
    const customerKey: string = queryParams.get('customerKey');

    // 결제할 강사 수
    const trainerNumber = getItemWithExpiration('trainerNumber');
    const price = trainerNumber < 4 ? trainerNumber * 32900 : trainerNumber * 39900;
    const [email, setEmail] = useState<string>(authObject?.email);
    const [isCheckPolicy, setIsCheckPolicy] = useState<boolean>(false);

    // 카드정보
    const [cardName, setCardName] = useState<string>('');
    const [cardNumber, setCardNumber] = useState<string>('');

    // 로딩관련
    const [loadingText, setLoadingText] = useState<string>('카드 정보 등록 진행중입니다 잠시만 기다려주세요.');
    const [isLoading, setIsLoading] = useState<boolean>(true);

    // 카드등록 실패모달
    const [isFailModal, setIsFailModal] = useState<boolean>(false);
    const [failModalContent, setFailModalContent] = useState<string>('');

    // 카드등록, 결제 성공모달
    const [isSuccessModal, setIsSuccessModal] = useState<boolean>(false);
    const [successModalContent, setIsSuccessModalContent] = useState<string>('');
    const [isCallback, setIsCallBack] = useState<boolean>(false);

    // 결제실패 모달
    const [isFailPayModal, setIsFailPayModal] = useState<boolean>(false);

    // 이메일 변경
    const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
        const currentEmail = e.target.value;
        setEmail(currentEmail.trim());
    };

    // 개인정보 수집 및 이용약관 동의 버튼
    const onClickPolicy = () => {
        setIsCheckPolicy(!isCheckPolicy);
    };

    // 결제 요청하기 버튼 클릭이벤트
    const onClickPayButton = () => {
        const emailRegExp = /^$|^[A-Za-z0-9_]+[A-Za-z0-9]*[@]{1}[A-Za-z0-9]+[A-Za-z0-9]*[.]{1}[A-Za-z]{1,3}$/;
        if (!isCheckPolicy) {
            createAlert('', '개인정보 수집 및 이용약관에 동의해주세요');
            return;
        }

        if (!(email.trim() === '')) {
            if (!emailRegExp.test(email)) {
                createAlert('', '이메일 형식이 잘못되었습니다');
                return;
            }
        }
        setIsLoading(true);
        requestPayment.mutate();
    };

    const navigateExpiredPage = () => {
        navigate('/expired', { replace: true });
    };

    // API(POST) : 빌링키 요청 api
    const requestRegistrationCard = useMutation({
        mutationFn: async () =>
            await requestRegistrationCardAPI(authorization, authKey, customerKey, authObject?.adminId),
        onSuccess: (res) => {
            if (res.status === 201) {
                setCardName(res?.data?.cardIssuingBank);
                setCardNumber(res?.data?.cardNumber);
                setIsLoading(false);
                setIsSuccessModal(true);
                setIsSuccessModalContent('카드 등록에 성공하셨습니다!');
            } else {
                setIsLoading(false);
                setIsFailModal(true);
                res?.response?.data?.message && setFailModalContent(res?.response?.data?.message);
            }
        },
        onError: (error) => {},
    });

    // API(POST) : 결제 요청 api
    const requestPayment = useMutation({
        mutationFn: async () =>
            await requestPaymentAPI(
                authorization,
                authObject?.adminId,
                trainerNumber,
                price,
                email,
                dayjs().format('YYYY-MM-DD'),
                dayjs().add(1, 'month').add(1, 'day').startOf('day').format('YYYY-MM-DD')
            ),
        onSuccess: (res) => {
            if (res.status === 200) {
                const subscriptionData = res.data.subscriptionData;

                const authObjectData = {
                    adminId: authObject?.adminId,
                    id: authObject?.id,
                    name: authObject?.name,
                    email: authObject?.email,
                    gender: authObject?.gender,
                    phoneNumber: authObject?.phoneNumber,
                    profileImageUrl: authObject?.profileImageUrl,
                    centerAddress: authObject?.centerAddress,
                    centerCategory: authObject?.centerCategory,
                    centerCode: authObject?.centerCode,
                    centerName: authObject?.centerName,
                    centerId: authObject?.centerId,
                    isNewCommunicationBox: authObject?.isNewCommunicationBox,

                    // 구독플랜관련
                    coachCount: authObject?.coachCount,
                    memberCount: authObject?.memberCount,
                    activationDate: subscriptionData?.activationDate,
                    expirationDate: subscriptionData?.expirationDate,
                    expirationDelayDate: subscriptionData?.expirationDelayDate,
                    finalPaymentDate: subscriptionData?.finalPaymentDate,
                    finalPaymentTryDate: subscriptionData?.finalPaymentTryDate,
                    cardIssuingBank: subscriptionData?.cardIssuingBank,
                    cardNumber: subscriptionData?.cardNumber,
                    planAmount: subscriptionData?.planAmount,
                    usableStatus: subscriptionData?.usableStatus,

                    paymentDueDate: authObject?.paymentDueDate,

                    /** 구독플랜 인원 정보 */
                    planLimitCount: authObject?.planLimitCount,
                    prevPlanLimitCount: authObject?.prevPlanLimitCount,
                    changedPlanLimitCount: authObject?.changedPlanLimitCount,

                    confirmUsableStatus: false,
                    testAccount: authObject?.testAccount,
                };

                setItemWithExpiration('authObject', authObjectData, 12);

                setIsLoading(false);
                setIsSuccessModal(true);
                setIsCallBack(true);
                setIsSuccessModalContent('결제가 완료되었습니다');
            } else {
                setIsLoading(false);
                setIsFailPayModal(true);
                // createAlert('', '결제 실패');
            }
        },
    });

    useEffect(() => {
        if (authKey && customerKey && authorization && authObject?.adminId) {
            requestRegistrationCard.mutate();
        } else {
            createAlert('', '요청에 실패하였습니다');
        }
    }, []);

    return (
        <div style={{ marginLeft: -138, marginTop: 100 }}>
            {/* <Header title="구독 플랜" /> */}
            <NormalButton onClick={navigateExpiredPage} style={{ width: 300 }} text={'구독 플랜 다시 선택'} />

            <Col>
                <Row style={{ alignItems: 'center', marginTop: 48 }}>
                    <Head4 style={{ color: colors.BLUE_700 }}>{'플랜 및 결제 정보'}</Head4>
                    <Body3Light style={{ color: colors.WHITE_800, marginLeft: 36 }}>
                        선택하신 플랜과 결제내역을 확인해주세요
                    </Body3Light>
                </Row>

                <Row style={{ marginTop: 36 }}>
                    <Col
                        style={{
                            width: 818,
                            height: 584,
                            borderRadius: 24,
                            backgroundColor: colors.WHITE_200,
                            boxShadow: '4px 4px 12px 0px #0000001F',
                            paddingLeft: 60,
                            marginRight: 17,
                        }}
                    >
                        <Row style={{ marginTop: 36 }}>
                            <Head4
                                style={{ color: colors.BLUE_900 }}
                            >{`선택 플랜: Rappo Manager (${trainerNumber}명)`}</Head4>
                        </Row>
                        <Body2Regular style={{ width: 681, marginTop: 16 }}>
                            {
                                '선택하신 플랜(인원 수)에 따라 강사를 등록할 수 있으며, 등록된 강사는 Rappo App 에서 Platinum Plan과 동일한 혜택\n이 제공되는 Manager Plan을 이용하실 수 있습니다. '
                            }
                        </Body2Regular>

                        <Subtitle1Regular style={{ color: colors.BLUE_900, marginLeft: 10, marginTop: 38 }}>
                            이메일(선택)
                        </Subtitle1Regular>
                        <input
                            id="email"
                            name="email"
                            value={email}
                            onChange={onChangeEmail}
                            readOnly={authObject?.testAccount}
                            style={{
                                width: 400,
                                height: 60,
                                backgroundColor: colors.WHITE_300,
                                borderRadius: 48,
                                boxShadow: '4px 4px 8px 1px #213E7B29 inset',
                                paddingLeft: 30,
                                fontSize: 16,
                                fontWeight: 300,

                                marginTop: 6,
                                // //lineHeight: 24,
                            }}
                        />
                        <Body3Light style={{ color: colors.WHITE_800, marginTop: 6 }}>
                            *입력하신 이메일로 결제 정보가 전송됩니다!
                        </Body3Light>

                        <Row style={{ alignItems: 'center', marginTop: 50 }}>
                            <div
                                style={{
                                    width: 3,
                                    height: 3,
                                    borderRadius: 1.5,
                                    backgroundColor: colors.WHITE_900,
                                    marginRight: 5,
                                }}
                            />
                            <Body2Regular style={{ color: colors.WHITE_900 }}>
                                {'구독 취소는 [ 마이페이지 > 구독 플랜 > 구독 취소 ] 과정을 통해 가능합니다'}
                            </Body2Regular>
                        </Row>
                        <Row style={{ alignItems: 'center' }}>
                            <div
                                style={{
                                    width: 3,
                                    height: 3,
                                    borderRadius: 1.5,
                                    backgroundColor: colors.WHITE_900,
                                    marginRight: 5,
                                }}
                            />
                            <Body2Regular style={{ color: colors.WHITE_900 }}>
                                {
                                    '정기 결제는 해당 월에 해당되는 날짜가 없는경우( 29일 ~ 31일 ) 그 달 마지막날 결제됩니다'
                                }
                            </Body2Regular>
                        </Row>
                        <Row style={{ alignItems: 'center' }}>
                            <div
                                style={{
                                    width: 3,
                                    height: 3,
                                    borderRadius: 1.5,
                                    backgroundColor: colors.WHITE_900,
                                    marginRight: 5,
                                }}
                            />
                            <Body2Regular style={{ color: colors.WHITE_900 }}>
                                {
                                    '결제가 실패할 경우 7일간의 유예기간이 주어지며 유예기간이 끝나면 연결된 모든 라포 서비스를 이용하실 수 없습니다'
                                }
                            </Body2Regular>
                        </Row>

                        <Row style={{ width: 238, marginTop: 52 }}>
                            <button onClick={onClickTerm}>
                                <Body1Bold style={{ color: colors.BLUE_900 }}>
                                    이용약관 및 개인정보 정책 보기 {'>'}
                                </Body1Bold>
                            </button>
                        </Row>

                        <Row style={{ alignItems: 'center', marginTop: 12 }}>
                            <Body1Regular style={{ color: colors.BLUE_900 }}>
                                개인정보 수집 및 이용약관 동의
                            </Body1Regular>
                            <Body1Regular style={{ color: colors.ERROR }}>*</Body1Regular>
                            <button
                                type="button"
                                onClick={onClickPolicy}
                                style={{
                                    width: 18,
                                    height: 18,
                                    borderWidth: 1,
                                    borderColor: 'rgba(212, 219, 232, 1)',
                                    background: colors.WHITE_400,
                                    borderRadius: 2,
                                    marginLeft: 24,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {isCheckPolicy && <img src={I_PRIMARY_CHECK} />}
                            </button>
                        </Row>
                    </Col>

                    <Col
                        style={{
                            width: 522,
                            height: 584,
                            borderRadius: 24,
                            backgroundColor: colors.WHITE_200,
                            boxShadow: '4px 4px 12px 0px #0000001F',
                        }}
                    >
                        <Col style={{ marginLeft: 62, marginTop: 80 }}>
                            <Body1Bold style={{ color: colors.BLUE_700 }}>{'적용 할인 혜택'}</Body1Bold>
                            <Body1Regular style={{ color: colors.BLUE_900 }}>
                                {parseInt(trainerNumber) > 3
                                    ? `Pro Manager Sale (약 60% 할인) - 1인 당  39,900원`
                                    : `Starter Manager Sale (약 65% 할인) - 1인 당  32,900원`}
                            </Body1Regular>
                            <Body1Bold style={{ color: colors.BLUE_700, marginTop: 36 }}>{'플랜 이용 기간'}</Body1Bold>
                            <Body1Regular style={{ color: colors.BLUE_900 }}>
                                {dayjs().format('YYYY년 MM월 DD일 ~')}
                                {dayjs().add(1, 'month').format('YYYY년 MM월 ')}
                                {dayjs().format('DD일')}
                            </Body1Regular>
                            <Row style={{ alignItems: 'center', marginTop: 36 }}>
                                <Body1Bold style={{ width: 80, color: colors.BLUE_700 }}>{'정기 결제'}</Body1Bold>
                                <Body2Regular style={{ color: colors.BLUE_700 }}>
                                    {'(최초 결제일을 기준으로 적용)'}
                                </Body2Regular>
                            </Row>

                            <Row style={{ alignItems: 'center' }}>
                                <Body2Regular style={{ width: 80, color: colors.BLUE_900 }}>
                                    {dayjs().format('매월 DD일')}
                                </Body2Regular>
                                <Body3Regular style={{ color: colors.BLUE_900 }}>
                                    {dayjs().add(1, 'month').format('YYYY년 MM월 ')}
                                    {dayjs().format('DD일')}
                                </Body3Regular>
                            </Row>
                        </Col>

                        <Col
                            style={{
                                width: 463,
                                height: 192,
                                borderRadius: 16,
                                backgroundColor: colors.WHITE_300,
                                boxShadow: '4px 4px 8px 1px #213E7B29 inset',
                                marginLeft: 36,
                                marginTop: 48,
                                paddingLeft: 26,
                                paddingTop: 32,
                            }}
                        >
                            <Body1Bold style={{ color: colors.BLUE_700 }}>{'결제 금액'}</Body1Bold>
                            <Body1Regular style={{ color: colors.BLUE_900 }}>
                                {price.toLocaleString()}원 / 월 VAT포함
                            </Body1Regular>
                            <Body1Bold style={{ color: colors.BLUE_700, marginTop: 24 }}>{'결제 카드'}</Body1Bold>
                            <Body1Regular style={{ color: colors.BLUE_900 }}>신한 9999-9999-****-999*</Body1Regular>
                        </Col>
                    </Col>
                </Row>

                <Row style={{ alignItems: 'center', marginTop: 24 }}>
                    <img src={I_ALERT} style={{ width: 32, height: 32, marginLeft: 58 }} />
                    <Subtitle1Bold style={{ width: 647, color: colors.BLUE_900, marginLeft: 16 }}>
                        플랜 상향 변경 시 즉시 적용되며 변경 된 플랜은 다음 결제 예정일부터 자동 청구 됩니다
                    </Subtitle1Bold>
                    <NormalButton
                        text={'플랜 변경'}
                        style={{ marginLeft: 303, width: 300 }}
                        onClick={onClickPayButton}
                    />
                </Row>
            </Col>
            {isLoading && <Loading text={loadingText} />}

            {isFailModal && (
                <ModalFail
                    modalVisible={isFailModal}
                    setModalVisible={setIsFailModal}
                    navigate={navigate}
                    content={failModalContent}
                />
            )}

            {isSuccessModal && (
                <ModalSuccess
                    modalVisible={isSuccessModal}
                    setModalVisible={setIsSuccessModal}
                    successModalContent={successModalContent}
                    navigate={navigate}
                    isCallback={isCallback}
                />
            )}
            {/* 결제 실패모달 */}
            {isFailPayModal && (
                <ModalAlert
                    modalVisible={isFailPayModal}
                    setModalVisible={setIsFailPayModal}
                    title={''}
                    boldContent={'결제 실패'}
                    content={'카드를 확인해주시고 다시 시도하여 주세요'}
                    cancelButtonText={'재시도'}
                    confirmButtonText={'다른 카드 입력'}
                    callBack={navigateExpiredPage}
                />
            )}
        </div>
    );
};

export default SuccessPurchaseAtExpired;
