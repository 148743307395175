import { CSSProperties, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { colors } from '../../lib/colors';
import { Row } from '../../lib/utils';
import { Body2Bold, Body3Bold, Body3Regular } from '../../lib/font';
import { I_LEFT_ANGLE, I_LEFT_PRIMARY_BTN, I_RIGHT_ANGLE, I_RIGHT_PRIMARY_BTN } from '../../types/images';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useRecoilState } from 'recoil';
import { sidebarStatusState } from '../../atoms/atom';

type CalendarProps = {
    selectedDay: any;
    setSelectedDay: any;
    isPrevMonth: any;
    isNextMonth: any;
    setIsCalendar: Dispatch<SetStateAction<boolean>>;
    style?: CSSProperties;
};

const HoverAbleCell: any = styled.td`
    background-color: ${({ clicked, istoday }: any) =>
        clicked === 'true' ? '#3D73DD' : istoday === 'true' ? '#E9F1FF' : '#FDFEFF'}; /* 클릭 여부에 따른 배경색 설정 */

    border-top-left-radius: ${({ clicked, isfirst }: any) =>
        clicked === 'true' ? (isfirst === 'true' ? '24px' : '0px') : '24px'};
    border-bottom-left-radius: ${({ clicked, isfirst }: any) =>
        clicked === 'true' ? (isfirst === 'true' ? '24px' : '0px') : '24px'};

    border-top-right-radius: ${({ clicked, islast }: any) =>
        clicked === 'true' ? (islast === 'true' ? '24px' : '0px') : '24px'};
    border-bottom-right-radius: ${({ clicked, islast }: any) =>
        clicked === 'true' ? (islast === 'true' ? '24px' : '0px') : '24px'};

    color: ${({ clicked }: any) => (clicked === 'true' ? '#FDFEFF' : '#1A305D')};
    padding: 10px;

    cursor: pointer;
    transition: background-color 0.3s ease; /* 부드러운 전환 효과 */

    &:hover {
        border-radius: 24;
        background-color: ${({ clicked }: any) => (clicked === 'true' ? '#3D73DD' : '#E9F1FF')};
    }
`;

const WeeklyCalendar = ({
    selectedDay,
    setSelectedDay,
    isPrevMonth,
    isNextMonth,
    setIsCalendar,
    style,
}: CalendarProps) => {
    const calendarRef = useRef<HTMLDivElement>(null);
    const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];
    const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
    const [sidebarStatus, setSidebarStatus] = useRecoilState(sidebarStatusState);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const isIncludeDay = (day: any) => {
        let startDate = dayjs(selectedDay).startOf('week');
        let endDate = dayjs(selectedDay).endOf('week');

        if (dayjs(startDate) <= dayjs(day) && dayjs(day) < dayjs(endDate)) {
            return true;
        } else {
            return false;
        }
    };

    const isFirstDayOfWeek = (day: any) => {
        let startDate = dayjs(selectedDay).startOf('week');
        if (dayjs(startDate).format('DD') === dayjs(day).format('DD')) {
            return true;
        } else {
            return false;
        }
    };

    const isLastDayOfWeek = (day: any) => {
        let endDate = dayjs(selectedDay).endOf('week');
        if (dayjs(endDate).format('DD') === dayjs(day).format('DD')) {
            return true;
        } else {
            return false;
        }
    };

    const onClickDay = (day: Date) => {
        setSelectedDay(day);
        setIsCalendar(false);
    };

    const prevCalendar = (event: React.MouseEvent) => {
        event.stopPropagation();
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, currentMonth.getDate()));
    };

    const nextCalendar = (event: React.MouseEvent) => {
        event.stopPropagation();
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, currentMonth.getDate()));
    };

    const buildCalendarDays = () => {
        const curMonthStartDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1).getDay();
        const curMonthEndDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
        const prevMonthEndDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 0);
        const nextMonthStartDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1);
        const days: Date[] = Array.from({ length: curMonthStartDate }, (_, i) => {
            return new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, prevMonthEndDate.getDate() - i);
        }).reverse();

        days.push(
            ...Array.from(
                { length: curMonthEndDate.getDate() },
                (_, i) => new Date(currentMonth.getFullYear(), currentMonth.getMonth(), i + 1)
            )
        );

        const remainingDays = 7 - (days.length % 7);
        if (remainingDays < 7) {
            days.push(
                ...Array.from(
                    { length: remainingDays },
                    (_, i) => new Date(nextMonthStartDate.getFullYear(), nextMonthStartDate.getMonth(), i + 1)
                )
            );
        }
        return days;
    };

    const buildCalendarTag = (calendarDays: Date[]) => {
        return calendarDays.map((day: Date, i: number) => {
            if (day.getMonth() < currentMonth.getMonth()) {
                return (
                    <HoverAbleCell
                        style={{ color: colors.WHITE_700 }}
                        clicked={isIncludeDay(day) ? 'true' : 'false'}
                        isfirst={isFirstDayOfWeek(day) ? 'true' : 'false'}
                        islast={isLastDayOfWeek(day) ? 'true' : 'false'}
                        istoday={'false'}
                        key={i}
                        className="prevMonthDay"
                    >
                        {isPrevMonth ? day.getDate() : ''}
                    </HoverAbleCell>
                );
            }
            if (day.getMonth() > currentMonth.getMonth()) {
                return (
                    <HoverAbleCell
                        style={{ color: colors.WHITE_700 }}
                        clicked={isIncludeDay(day) ? 'true' : 'false'}
                        isfirst={isFirstDayOfWeek(day) ? 'true' : 'false'}
                        islast={isLastDayOfWeek(day) ? 'true' : 'false'}
                        istoday={'false'}
                        key={i}
                        className="nextMonthDay"
                    >
                        {isNextMonth ? day.getDate() : ''}
                    </HoverAbleCell>
                );
            }

            if (day < today) {
                return (
                    <HoverAbleCell
                        key={i}
                        clicked={isIncludeDay(day) ? 'true' : 'false'}
                        isfirst={isFirstDayOfWeek(day) ? 'true' : 'false'}
                        islast={isLastDayOfWeek(day) ? 'true' : 'false'}
                        onClick={() => onClickDay(day)}
                        istoday={'false'}
                    >
                        {day.getDate()}
                    </HoverAbleCell>
                );
            }

            return (
                <HoverAbleCell
                    key={i}
                    clicked={isIncludeDay(day) ? 'true' : 'false'}
                    isfirst={isFirstDayOfWeek(day) ? 'true' : 'false'}
                    islast={isLastDayOfWeek(day) ? 'true' : 'false'}
                    istoday={dayjs().format('YYYY-MM-DD') === dayjs(day).format('YYYY-MM-DD') ? 'true' : 'false'}
                    onClick={() => onClickDay(day)}
                >
                    {day.getDate()}
                </HoverAbleCell>
            );
        });
    };

    const divideWeek = (calendarTags: JSX.Element[]) => {
        return calendarTags.reduce((acc: JSX.Element[][], day: JSX.Element, i: number) => {
            if (i % 7 === 0) acc.push([day]);
            else acc[acc.length - 1].push(day);
            return acc;
        }, []);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
            setIsCalendar && setIsCalendar(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (selectedDay) {
            setCurrentMonth(new Date(selectedDay.getFullYear(), selectedDay.getMonth(), selectedDay.getDate()));
        }
    }, [selectedDay]);

    const calendarDays = buildCalendarDays();
    const calendarTags = buildCalendarTag(calendarDays);
    const calendarRows = divideWeek(calendarTags);

    return (
        <div
            ref={calendarRef}
            style={
                style
                    ? style
                    : {
                          width: 420,
                          height: 370,
                          borderRadius: 24,
                          backgroundColor: colors.WHITE_50,
                          boxShadow: '1px 1px 8px 0px #2B529D2E',
                          position: 'absolute',
                          top: 323,
                          left: sidebarStatus === 'fold' ? 164 : 439,
                          zIndex: 9,
                      }
            }
        >
            <Row style={{ alignItems: 'center', marginTop: 28, marginLeft: 158 }}>
                <button onClick={prevCalendar} style={{ width: 24, height: 24 }}>
                    <img style={{ width: 24, height: 24 }} src={I_LEFT_ANGLE} />
                </button>
                <Body3Regular
                    style={{ width: 40, textAlign: 'center', color: colors.BLUE_700, marginLeft: 8, marginRight: 8 }}
                >
                    {currentMonth.getMonth() + 1}월
                </Body3Regular>

                <button onClick={nextCalendar} style={{ width: 24, height: 24 }}>
                    <img style={{ width: 24, height: 24 }} src={I_RIGHT_ANGLE} />
                </button>
                <button
                    style={{
                        width: 56,
                        height: 26,
                        borderRadius: 24,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: colors.WHITE_300,
                        color: colors.PRIMARY_BLUE,
                        boxShadow: '1px 1px 8px 0px #2B529D2E',
                        marginLeft: 56,
                    }}
                    onClick={() => {
                        setSelectedDay(today);
                        setIsCalendar(false);
                    }}
                >
                    <Body3Bold>오늘</Body3Bold>
                </button>
            </Row>
            <table style={{ width: 340, marginTop: 22, marginLeft: 34 }}>
                <thead>
                    <tr>
                        {daysOfWeek.map((day, i) => (
                            <th
                                style={{
                                    borderTop: '1px solid #d4dbe8',
                                    borderBottom: '1px solid #d4dbe8',
                                    borderColor: colors.WHITE_600,
                                    paddingTop: 4,
                                    paddingBottom: 4,
                                }}
                                key={i}
                            >
                                <Body2Bold style={{ color: colors.BLUE_700 }}>{day}</Body2Bold>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {calendarRows.map((row: JSX.Element[], i: number) => (
                        <tr
                            style={{
                                fontSize: 14,
                                fontWeight: 400,
                                height: 42,
                                color: colors.BLUE_900,
                                textAlign: 'center',
                            }}
                            key={i}
                        >
                            {row}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default WeeklyCalendar;
