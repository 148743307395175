import React, { useState } from 'react';
import { PeriodNavigatorType } from './PeriodNavigator';
import TextSelector, { FontsType } from '../TextSelector';

type Props = {
    width?: number;
    fontType?: FontsType;
    onClickType?: (type: PeriodNavigatorType) => void;
    mr?: number;
};

const DateRangeSelector = ({ width = 278, fontType = 'Body3Light', onClickType, mr = 0 }: Props) => {
    const options = [{ thisMonth: '이번 달' }, { thisYear: '이번 연도' }, { all: '전체' }];

    const [selectedOption, setSelectedOption] = useState<PeriodNavigatorType>('thisMonth');

    return (
        <div
            className="flex items-center justify-between bg-gray-200 rounded-[2px] border-[1px] border-solid border-BLUE_100"
            style={{ width: width, marginRight: mr }}
        >
            {options.map((option) => (
                <div
                    key={Object.keys(option)[0]}
                    className={`flex h-full w-full justify-center items-center cursor-pointer rounded-[2px] transition-colors duration-300 border-[1px] border-solid
            ${Object.keys(option)[0] === selectedOption ? 'bg-white text-BLUE_500' : 'bg-WHITE_400 text-BLUE_600'}
            ${Object.keys(option)[0] === selectedOption ? 'border-primary' : 'border-trans'}
            `}
                    onClick={() => {
                        setSelectedOption(Object.keys(option)[0] as PeriodNavigatorType);
                        onClickType && onClickType(Object.keys(option)[0] as PeriodNavigatorType);
                    }}
                >
                    <TextSelector text={Object.values(option)[0]} fontType={fontType} color={'BLUE_600'} />
                </div>
            ))}
        </div>
    );
};

export default DateRangeSelector;
