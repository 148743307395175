import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { screenHeightState, screenWidthState } from '../atoms/atom';

const useScreenWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerWidth);

    const [screenWidth, setScreenWidth] = useRecoilState(screenWidthState);
    const [screenHeight, setScreenHeight] = useRecoilState(screenHeightState);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (width) {
            if (width > 1920) {
                setScreenWidth(1920);
                setScreenHeight(1080);
            }
            if (width <= 1440 && width > 1024) {
                setScreenWidth(1440);
                setScreenHeight(1024);
            }

            if (width <= 1024 && width > 768) {
                setScreenWidth(1024);
                setScreenHeight(768);
            }

            if (width <= 768) {
                setScreenWidth(768);
                setScreenHeight(1024);
            }
        }
    }, [width]);

    return;
};

export default useScreenWidth;
