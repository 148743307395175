import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Col, Row } from '../../lib/utils';
import { Body3Light, Body3Regular } from '../../lib/font';
import { colors } from '../../lib/colors';
import styled from 'styled-components';
import { I_CLOSE, I_ERROR, I_LOCK_MASKING, I_UNLOCK_MASKING } from '../../types/images';

// StyledInput 컴포넌트 생성
const StyledInput = styled.input`
    width: 100%;
    height: 4.8rem;
    font-size: 1.8rem;
    padding-left: 1.6rem;
    border-bottom: 1px solid #a6bfef;
    color: #353535;
    ::placeholder {
        color: #acacac;
    }
`;

const StyledButton = styled.button`
    width: 2.4rem;
    height: 2.4rem;
    background: none;
    border: none;
    position: absolute;
    margin-left: 40rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0.8; /* 기본 투명도 설정 */

    /* 호버 시 투명도 변경 */
    &:hover {
        opacity: 1;
    }

    /* 클릭 시 투명도 변경 */
    &:active {
        opacity: 0.6;
    }
`;

type I_Props = {
    userId: string;
    setUserId: Dispatch<SetStateAction<string>>;
    userPassword: string;
    setUserPassword: Dispatch<SetStateAction<string>>;
    handleKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

const SignInIdPassWord = ({ userId, setUserId, userPassword, setUserPassword, handleKeyPress }: I_Props) => {
    const [idErrorMsg, setIdErrorMsg] = useState<string>('');
    const [passwordErrorMsg, setPasswordErrorMsg] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleChangeId = (event: ChangeEvent<HTMLInputElement>) => {
        setUserId(event.target.value.trim());
    };

    const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
        setUserPassword(event.target.value.trim());
    };

    const onClickCloseButton = () => {
        setUserId('');
    };

    const onClickUnlockMasking = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        if (userId.length !== 0) {
            if (/^[a-zA-Z0-9]{4,}$/.test(userId)) {
                setIdErrorMsg('');
                return;
            } else {
                setIdErrorMsg('영문과 숫자를 조합하여 4글자 이상으로 입력해주세요!');
            }
        } else {
            setIdErrorMsg('');
        }
    }, [userId]);

    useEffect(() => {
        if (userPassword.length !== 0) {
            if (/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/.test(userPassword)) {
                setPasswordErrorMsg('');
                return;
            } else {
                setPasswordErrorMsg('영문, 숫자, 특수문자를 포함한 8~ 20글자로 입력해주세요!');
            }
        } else {
            setPasswordErrorMsg('');
        }
    }, [userPassword]);

    return (
        <Col>
            <Col style={{ width: '44rem', marginTop: '6.4rem' }}>
                <Body3Regular>{'아이디'}</Body3Regular>

                <Row style={{ alignItems: 'center', marginTop: '0.8rem' }}>
                    <StyledInput
                        placeholder="아이디를 입력해주세요!"
                        value={userId}
                        minLength={4}
                        maxLength={25}
                        onChange={handleChangeId}
                    />
                    {idErrorMsg !== '' ? (
                        <StyledButton onClick={onClickCloseButton}>
                            <img style={{ width: 18, height: 18 }} src={I_ERROR} alt="Button Icon" />
                        </StyledButton>
                    ) : (
                        userId.trim().length > 0 && (
                            <StyledButton onClick={onClickCloseButton}>
                                <img src={I_CLOSE} alt="Button Icon" />
                            </StyledButton>
                        )
                    )}
                </Row>

                <Body3Light
                    style={{
                        paddingLeft: '1.6rem',
                        height: '2.4rem',
                        marginTop: '0.8rem',
                        color: idErrorMsg.length === 0 ? colors.WHITE_50 : colors.ERROR,
                    }}
                >
                    {idErrorMsg}
                </Body3Light>
            </Col>

            <Col style={{ width: '44rem', marginTop: '2.4rem' }}>
                <Body3Regular>{'비밀번호'}</Body3Regular>

                <Row style={{ alignItems: 'center', marginTop: '0.8rem' }}>
                    <StyledInput
                        placeholder="비밀번호를 입력해주세요!"
                        type={showPassword ? 'text' : 'password'}
                        value={userPassword}
                        minLength={4}
                        maxLength={25}
                        onChange={handleChangePassword}
                        onKeyDown={handleKeyPress}
                    />
                    {userPassword.length !== 0 && (
                        <StyledButton onClick={onClickUnlockMasking}>
                            <img src={showPassword ? I_LOCK_MASKING : I_UNLOCK_MASKING} alt="Button Icon" />
                        </StyledButton>
                    )}
                </Row>

                <Body3Light
                    style={{
                        paddingLeft: '1.6rem',
                        height: '2.4rem',
                        marginTop: '0.8rem',
                        color: passwordErrorMsg.length === 0 ? colors.WHITE_50 : colors.ERROR,
                    }}
                >
                    {passwordErrorMsg}
                </Body3Light>
            </Col>
        </Col>
    );
};

export default SignInIdPassWord;
