import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { I_POPUP_EXIT } from '../../../types/images';
import { Col, getItemWithExpiration, Row } from '../../../lib/utils';
import { Body3Light, Body3Regular, CaptionLight, Subtitle1Bold } from '../../../lib/font';
import { colors } from '../../../lib/colors';

import Scrollbars from 'react-custom-scrollbars-2';
import { updateMembershipTemplatesAPI } from '../../../api/memberShip';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import BgColorSelect from '../ModalRegisterPass/BgColorSelect';
import NewColorSquareButton from '../../../components/Button/NewColorSquareButton';
import MemoTextInput from '../../../components/TextInput/MemoTextInput';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
`;

const ModalInner = styled.div`
    background: white;
    width: 1080px;
    height: 700px;
    border-radius: 8px;
    box-shadow: 8px 8px 32px 0px #0000007a;
    display: flex;
    flex-direction: column;

    background: #fdfeff;
`;

const HoverImage = styled.img`
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

type Props = {
    setModalVisible: Dispatch<SetStateAction<boolean>>;
    createAlert: any;
    detailObject: any;
    onUpdateSuccess: (data: any) => void;
};
const ModalModifyPass = ({ setModalVisible, createAlert, detailObject, onUpdateSuccess }: Props) => {
    const queryClient = useQueryClient();
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));

    const [isStart, setIsStart] = useState(false);

    const [passName, setPassName] = useState<string>(detailObject?.name ? detailObject?.name : ''); // 수강권 이름
    const [passNumber, setPassNumber] = useState<any>(
        detailObject?.totalSession ? `${detailObject?.totalSession}` : ''
    ); // 수강 횟수

    const [passDate, setPassDate] = useState<any>(detailObject?.validDays ? `${detailObject?.validDays}` : ''); // 수강권 기간

    const [passTime, setPassTime] = useState<any>(detailObject?.period ? `${detailObject?.period}` : ''); // 수업 시간

    const [passPrice, setPassPrice] = useState<string>(
        detailObject?.pricePerSchedule ? detailObject?.pricePerSchedule.toLocaleString() : ''
    ); // 회당 가격

    const [totalPrice, setTotalPrice] = useState<string>(
        detailObject?.totalPrice ? detailObject?.totalPrice.toLocaleString() : ''
    ); // 판매 금액

    const [memo, setMemo] = useState<string>(detailObject?.memo ? detailObject?.memo : ''); // 메모
    const [bgColor, setBgColor] = useState<any>(detailObject?.BGColor ?? ['#40CE96', '#1B5AD4']);
    const passNumberInputRef = useRef<HTMLInputElement>(null);
    const passDateInputRef = useRef<HTMLInputElement>(null);
    const passTimeInputRef = useRef<HTMLInputElement>(null);
    const passPriceInputRef = useRef<HTMLInputElement>(null);
    const totalPriceInputRef = useRef<HTMLInputElement>(null);

    const handlePassName = (event: ChangeEvent<HTMLInputElement>) => {
        setPassName(event.target.value);
    };

    const handleChangePassNumber = (event: ChangeEvent<HTMLInputElement>) => {
        const numericValue = event.target.value.replace(/\D/g, ''); // 숫자 이외의 문자 제거
        setPassNumber(numericValue);

        // 숫자 변경 후 커서를 숫자 뒤로 이동
        setTimeout(() => {
            if (passNumberInputRef.current) {
                passNumberInputRef.current.setSelectionRange(numericValue.length, numericValue.length);
            }
        }, 0);
    };

    const handleChangePassDate = (event: ChangeEvent<HTMLInputElement>) => {
        const numericValue = event.target.value.replace(/\D/g, ''); // 숫자 이외의 문자 제거
        setPassDate(numericValue);

        // 숫자 변경 후 커서를 숫자 뒤로 이동
        setTimeout(() => {
            if (passDateInputRef.current) {
                passDateInputRef.current.setSelectionRange(numericValue.length, numericValue.length);
            }
        }, 0);
    };

    const handleChangePassTime = (event: ChangeEvent<HTMLInputElement>) => {
        const numericValue = event.target.value.replace(/\D/g, ''); // 숫자 이외의 문자 제거
        setPassTime(numericValue);

        // 숫자 변경 후 커서를 숫자 뒤로 이동
        setTimeout(() => {
            if (passTimeInputRef.current) {
                passTimeInputRef.current.setSelectionRange(numericValue.length, numericValue.length);
            }
        }, 0);
    };

    // 회당 가격
    const handleChangePassPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = event.target.value.replace(/[^\d]/g, ''); // 숫자만 남기기

        let formattedValue = '';

        // 뒤에서부터 3자리씩 콤마를 추가하기
        for (let i = rawValue.length - 1; i >= 0; i--) {
            const commaIndex = rawValue.length - 1 - i;
            if (commaIndex > 0 && commaIndex % 3 === 0) {
                formattedValue = ',' + formattedValue; // 콤마 추가
            }
            formattedValue = rawValue.charAt(i) + formattedValue;
        }

        setPassPrice(formattedValue ? `${formattedValue}` : '');

        // 숫자 변경 후 커서를 숫자 뒤로 이동
        setTimeout(() => {
            if (passPriceInputRef.current) {
                passPriceInputRef.current.setSelectionRange(formattedValue.length, formattedValue.length);
            }
        }, 0);
    };
    // 판매 가격
    const handleChangeTotalPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = event.target.value.replace(/[^\d]/g, ''); // 숫자만 남기기

        let formattedValue = '';

        // 뒤에서부터 3자리씩 콤마를 추가하기
        for (let i = rawValue.length - 1; i >= 0; i--) {
            const commaIndex = rawValue.length - 1 - i;
            if (commaIndex > 0 && commaIndex % 3 === 0) {
                formattedValue = ',' + formattedValue; // 콤마 추가
            }
            formattedValue = rawValue.charAt(i) + formattedValue;
        }

        setTotalPrice(formattedValue ? `${formattedValue}` : '');

        // 숫자 변경 후 커서를 숫자 뒤로 이동
        setTimeout(() => {
            if (totalPriceInputRef.current) {
                totalPriceInputRef.current.setSelectionRange(formattedValue.length, formattedValue.length);
            }
        }, 0);
    };

    const handleKeyDown = (event: any) => {
        // 숫자 키와 백스페이스, Delete 키만 허용
        if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Delete') {
            event.preventDefault();
        }
    };

    const priceToNumber = (price: string): number => {
        const numberString = price.replace(/[^\d]/g, '');
        return parseInt(numberString);
    };

    // API (POST) : 수강권 생성
    const updateMembershipTemplates = useMutation({
        mutationFn: async () =>
            await updateMembershipTemplatesAPI(
                authorization,
                detailObject._id ? detailObject._id : detailObject.id,
                passName,
                passTime,
                priceToNumber(passPrice),
                priceToNumber(totalPrice),
                passNumber,
                bgColor,
                memo,
                passDate,
                detailObject?.isAvailable
            ),
        onSuccess: (res) => {
            if (res.status === 200) {
                onUpdateSuccess(res.data);
                setModalVisible(false);
                queryClient.invalidateQueries({ queryKey: ['getMembershipTemplatesListAPI'] });
            } else {
                createAlert('수강권 생성 오류', '오류가 지속된다면 라포채널로 문의바랍니다', true);
            }
        },
    });

    // 수강권 생성 버튼 클릭이벤트
    const onClickCreateMemberShipTemplates = () => {
        if (bgColor === null) {
            createAlert('필수 입력', '바탕색을 선택하여 주세요', true);
            return;
        }

        if (passNumber === null || passNumber.trim().length < 1) {
            createAlert('필수 입력', '수강 횟수를 입력하여 주세요', true);
            return;
        }

        if (passDate === null || passDate.trim().length < 1) {
            createAlert('필수 입력', '수강권 기간을 입력하여 주세요', true);
            return;
        }

        if (passTime === null || passTime.trim().length < 1) {
            createAlert('필수 입력', '수업 시간을 입력하여 주세요', true);
            return;
        }

        if (passTime % 10 > 0 || passTime.trim().length < 1) {
            createAlert('올바르지 않은 입력', '수업 시간을 10분단위로 입력하여 주세요', true);
            return;
        }

        if (passPrice === null || passPrice.trim().length < 1) {
            createAlert('필수 입력', '회당 가격을 입력하여 주세요', true);
            return;
        }
        updateMembershipTemplates.mutate();
    };

    useEffect(() => {
        if (passNumber && passPrice && isStart) {
            let number = passNumber.replaceAll('회', '');
            let destroyComma = passPrice.replaceAll(',', '');
            let destroyString = destroyComma.replaceAll('원', '');

            let totalPrice = (parseInt(number) * parseInt(destroyString)).toString();

            let formattedValue = '';

            for (let i = totalPrice.length - 1; i >= 0; i--) {
                const commaIndex = totalPrice.length - 1 - i;
                if (commaIndex > 0 && commaIndex % 3 === 0) {
                    formattedValue = ',' + formattedValue; // 콤마 추가
                }
                formattedValue = totalPrice.charAt(i) + formattedValue;
            }

            setTotalPrice(`${formattedValue}`);
        }
    }, [passNumber, passPrice]);

    useEffect(() => {
        setIsStart(true);
    }, []);

    // 부모의 스크롤을 막기
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <ModalOverLay>
            <ModalInner>
                <Row
                    style={{
                        width: 1080,
                        height: 88,
                        justifyContent: 'space-between',
                        paddingLeft: 48,
                        paddingRight: 20,
                        boxShadow: '0px 2px 6px 0px #00000014',
                    }}
                >
                    <Subtitle1Bold style={{ color: colors.LAVEL_4, marginTop: 36 }}>{'수강권 수정하기'}</Subtitle1Bold>
                    <HoverImage
                        src={I_POPUP_EXIT}
                        style={{ width: 24, height: 24, marginTop: 20 }}
                        onClick={() => setModalVisible(false)}
                    />
                </Row>

                <Scrollbars style={{ width: 1080, height: 612 }}>
                    {/* 수강권 이름 */}
                    <Col>
                        <Body3Regular style={{ marginTop: 32, marginLeft: 80, color: colors.LAVEL_4 }}>
                            {'수강권 이름'}
                        </Body3Regular>

                        <input
                            placeholder="수강권 이름을 입력해주세요!"
                            value={passName}
                            onChange={handlePassName}
                            style={{
                                width: 440,
                                height: 48,
                                borderBottom: '1px solid #A6BFEF',
                                marginLeft: 80,
                                marginTop: 8,
                                paddingLeft: 16,
                                fontSize: 18,
                                fontWeight: 400,
                            }}
                        />
                    </Col>
                    {/* 수강권 색상 */}
                    <BgColorSelect bgColor={bgColor} setBgColor={setBgColor} />

                    {/* 수강 횟수 / 수강권 기간 */}
                    <Row style={{ marginTop: 56, marginLeft: 80 }}>
                        <Col style={{ width: 160, height: 80, justifyContent: 'space-between' }}>
                            <Body3Regular style={{ color: colors.LAVEL_4 }}>{'수강 횟수'}</Body3Regular>

                            <Row style={{ marginTop: 8 }}>
                                <input
                                    ref={passNumberInputRef}
                                    type="text"
                                    value={passNumber ? `${passNumber}회` : ''}
                                    maxLength={5}
                                    onChange={handleChangePassNumber}
                                    placeholder="10회"
                                    onKeyDown={handleKeyDown}
                                    style={{
                                        width: 160,
                                        height: 48,
                                        borderBottom: '1px solid #A6BFEF',
                                        paddingLeft: 16,
                                        fontSize: 18,
                                        fontWeight: 400,
                                    }}
                                />
                            </Row>
                        </Col>

                        <Col style={{ width: 160, height: 80, justifyContent: 'space-between', marginLeft: 80 }}>
                            <Body3Regular style={{ color: colors.LAVEL_4 }}>{'수강권 기간'}</Body3Regular>

                            <Row
                                style={{
                                    marginTop: 8,
                                }}
                            >
                                <input
                                    ref={passDateInputRef}
                                    type="text"
                                    value={passDate ? `${passDate}일` : ''}
                                    maxLength={5}
                                    onChange={handleChangePassDate}
                                    placeholder="100일"
                                    onKeyDown={handleKeyDown}
                                    style={{
                                        width: 160,
                                        height: 48,
                                        borderBottom: '1px solid #A6BFEF',
                                        paddingLeft: 16,
                                        fontSize: 18,
                                        fontWeight: 400,
                                    }}
                                />
                            </Row>
                        </Col>
                    </Row>

                    {/* 수업 시간 */}
                    <Col style={{ width: 340, marginLeft: 80, marginTop: 56 }}>
                        <Body3Regular style={{ color: colors.LAVEL_4 }}>{'수업 시간'}</Body3Regular>
                        <input
                            ref={passTimeInputRef}
                            type="text"
                            value={passTime ? `${passTime}분` : ''}
                            maxLength={5}
                            onChange={handleChangePassTime}
                            placeholder="50분"
                            onKeyDown={handleKeyDown}
                            style={{
                                width: 360,
                                height: 48,
                                borderBottom: '1px solid #A6BFEF',
                                paddingLeft: 16,
                                fontSize: 18,
                                fontWeight: 400,
                                marginTop: 8,
                            }}
                        />
                        <Body3Light style={{ color: colors.LAVEL_1, marginLeft: 16, marginTop: 8 }}>
                            {'10분 단위로 입력해주세요!'}
                        </Body3Light>
                    </Col>

                    {/* 회당 가격 */}
                    <Col style={{ width: 340, marginLeft: 80, marginTop: 56 }}>
                        <Body3Regular style={{ color: colors.LAVEL_4 }}>{'회당 가격(원)'}</Body3Regular>
                        <input
                            ref={passPriceInputRef}
                            type="text"
                            value={passPrice ? `${passPrice}원` : ''}
                            onChange={handleChangePassPrice}
                            placeholder="1회당 가격을 입력해주세요!"
                            onKeyDown={handleKeyDown}
                            maxLength={10}
                            style={{
                                width: 360,
                                height: 48,
                                borderBottom: '1px solid #A6BFEF',
                                paddingLeft: 16,
                                fontSize: 18,
                                fontWeight: 400,
                                marginTop: 8,
                            }}
                        />
                    </Col>

                    {/* 판매 금액 */}
                    <Col style={{ width: 340, marginLeft: 80, marginTop: 56 }}>
                        <Row style={{ alignItems: 'center' }}>
                            <Body3Regular style={{ color: colors.LAVEL_4 }}>{'판매 금액(원)'}</Body3Regular>
                            <CaptionLight style={{ color: colors.LAVEL_1, marginLeft: 8 }}>
                                {'회당 가격과 수강 횟수를 입력하면 자동입력됩니다! '}
                            </CaptionLight>
                        </Row>
                        <input
                            ref={totalPriceInputRef}
                            type="text"
                            value={totalPrice ? `${totalPrice}원` : ''}
                            onChange={handleChangeTotalPrice}
                            placeholder="판매 금액을 입력해주세요!"
                            style={{
                                width: 360,
                                height: 48,
                                borderBottom: '1px solid #A6BFEF',
                                paddingLeft: 16,
                                fontSize: 18,
                                fontWeight: 400,
                                marginTop: 8,
                            }}
                        />
                    </Col>
                    <Col style={{ marginTop: 56, marginLeft: 80, marginBottom: 37 }}>
                        <Body3Regular className="text-LAVEL_4 mb-[16px]">{'메모'}</Body3Regular>

                        <MemoTextInput
                            value={memo}
                            onChange={(e) => setMemo(e.target.value)}
                            placeholder="메모를 입력해주세요"
                            width={600}
                            height={136}
                        />
                    </Col>

                    <Row className="flex w-full justify-end items-center mb-[36px] ">
                        <NewColorSquareButton
                            text="수강권 수정하기"
                            onClick={onClickCreateMemberShipTemplates}
                            width={148}
                            height={48}
                            className="mr-[36px]"
                        />
                    </Row>
                </Scrollbars>
            </ModalInner>
        </ModalOverLay>
    );
};

export default ModalModifyPass;
