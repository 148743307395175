import React from 'react';
import { Body1Bold } from '../../lib/font';
import TextSelector, { FontsType } from '../TextSelector';
import { colors } from '../../lib/colors';

type Props = {
    text: string;
    fontType?: FontsType;
    bgColor?: keyof typeof colors;
    textColor?: keyof typeof colors;
    width?: number;
    height?: number;
    onClick?: () => void;
    className?: string;
    disabled?: boolean;
};

const NewColorRoundButton = ({
    text,
    fontType = 'Body3Regular',
    width,
    height,
    bgColor,
    textColor,
    onClick,
    className,
    disabled,
}: Props) => {
    return (
        <div
            onClick={disabled ? undefined : onClick}
            className={`flex flex-col justify-center items-center  bg-${bgColor} rounded-[48px] ${className} ${
                disabled ? 'cursor-not-allowed' : 'cursor-pointer'
            }`}
            style={{
                width: width ? `${width}px` : 'auto',
                height: height ? `${height}px` : 'auto',
            }}
        >
            <TextSelector fontType={fontType} text={text} color={textColor} />
        </div>
    );
};

export default NewColorRoundButton;
