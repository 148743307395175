import React from 'react';
import { Col, Row } from '../../../lib/utils';
import { Body1Bold, Body3Bold, Body3Light, Body3Regular, CaptionRegular } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import ProfileImage from '../../ProfileImage';
import dayjs from 'dayjs';
import { I_RIGHT_PRIMARY_BTN } from '../../../types/images';

const FeedProfileItem = ({ item, postType, role }: any) => {
    return (
        <Row style={{ width: 722, alignSelf: 'center', justifyContent: 'space-between', marginTop: 36 }}>
            <Row style={{ alignItems: 'center' }}>
                <ProfileImage
                    imageUrl={item?.profileImageUrl}
                    type="Middle"
                    profileName={item?.profileName}
                    profileColorType={item?.profileColorType}
                    right={8}
                />
                <Col>
                    <Row style={{ alignItems: 'center' }}>
                        <Body3Bold style={{ color: colors.LAVEL_3 }}>{`${item?.writerName}  ${
                            role === 'coach' ? '강사' : '회원'
                        }`}</Body3Bold>

                        {role === 'coach' && (
                            <Row style={{ alignItems: 'center' }}>
                                <img
                                    src={I_RIGHT_PRIMARY_BTN}
                                    style={{ width: 16, height: 16, marginLeft: 8, marginRight: 8 }}
                                />
                                {item?.postType === 'FIXED' ? (
                                    <Body3Bold style={{ color: colors.POINT_1 }}>공지글</Body3Bold>
                                ) : (
                                    <Body3Bold style={{ color: colors.POINT_1 }}>{`${
                                        postType === 'SCHEDULEREPORT'
                                            ? `${item?.receivers[0]?.receiverName} 회원 수업일지`
                                            : `${
                                                  item?.receivers && item?.receivers.length > 1
                                                      ? `${item?.receivers[0].receiverName} 회원 외 ${
                                                            item?.receivers.length - 1
                                                        }명`
                                                      : `${item?.receivers[0].receiverName} 회원`
                                              }`
                                    }`}</Body3Bold>
                                )}
                            </Row>
                        )}
                    </Row>
                    {role === 'coach' ? (
                        <CaptionRegular style={{ color: colors.LAVEL_3 }}>{`회원 총 ${
                            item?.totalMemberCount ? item?.totalMemberCount : 0
                        }명`}</CaptionRegular>
                    ) : (
                        <CaptionRegular
                            style={{ color: colors.LAVEL_3 }}
                        >{`${item?.receivers[0]?.receiverName} 강사 담당`}</CaptionRegular>
                    )}
                </Col>
            </Row>
            <CaptionRegular style={{ color: colors.LAVEL_1, marginTop: 30 }}>
                {dayjs(item?.createdTime).format('YY/MM/DD(ddd)/A HH:mm')}
            </CaptionRegular>
        </Row>
    );
};

export default FeedProfileItem;
