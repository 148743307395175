import dayjs from 'dayjs';
import OffBlock from './OffBlock';
import ScheduleBlock from './ScheduleBlock';
import EtcBlock from './EtcBlock';
import RequestBlock from './RequestBlock';
import GroupScheduleBox from './GroupScheduleBox';

const ScheduleTableInnerBox = ({
    onClickSchedule,
    onClickRequest,
    onClickEtcOffSchedule,
    onClickApproveScheduleRequest,
    onClickRejectScheduleRequest,
    dailyCurrentData,
    scrollY,
}: any) => {
    return (
        dailyCurrentData &&
        dailyCurrentData.map((parentItem: any, parentIndex: number) => {
            const Schedules = parentItem.schedules;
            // startTime을 기준으로 객체들을 묶어서 Map을 만듬.
            const groupedByStartTime = Schedules.reduce((acc: any, obj: any) => {
                const key = obj.startTime + '-' + obj.type + obj.endTime;

                if (!acc.has(key)) {
                    acc.set(key, []);
                }
                acc.get(key).push(obj);

                return acc;
            }, new Map());

            // 중복되지 않은 요소들과 함께 배열로 만듬.
            const groupedArray = Array.from(groupedByStartTime.values());

            // 일정의 각 종류별로 우선순위를 설정하기 위해 일정을 종류별로 나눔
            const scheduleArray: any = [];
            const requestArray: any = [];
            const onetimeScheduleArray: any = [];
            const blockArray: any = [];

            groupedArray.map((array: any) => {
                switch (array[0]?.type) {
                    case 'schedule':
                        scheduleArray.push(array);
                        break;
                    case 'request':
                        requestArray.push(array);
                        break;
                    case 'onetimeSchedule':
                        onetimeScheduleArray.push(array);
                        break;
                    case 'block':
                        blockArray.push(array);
                        break;
                }
            });

            // 수업일정, 신청, 개인일정, Off 순으로 표시되도록 배열 합침
            const resultArray = [].concat(blockArray, onetimeScheduleArray, requestArray, scheduleArray);

            return resultArray.map((childArr: any, childIndex: number) => {
                const childItem = childArr[0];

                const colPosition =
                    (scrollY ? 120 : 114) +
                    parseInt(dayjs(childItem.startTime).format('H')) * 120 +
                    parseInt(dayjs(childItem.startTime).format('m')) * 2;

                const rowPosition = 11.56 * (parentIndex + 1) - 3.45;
                const boxHeight = childItem.duration * 2;

                return childItem.type === 'schedule' ? (
                    childArr.length === 1 ? (
                        <ScheduleBlock
                            key={childIndex + 'ScheduleBox'}
                            parentItem={parentItem}
                            parentIndex={parentIndex}
                            childItem={childItem}
                            colPosition={colPosition}
                            rowPosition={rowPosition}
                            boxHeight={boxHeight}
                            onClickSchedule={onClickSchedule}
                        />
                    ) : (
                        <GroupScheduleBox
                            key={childIndex + 'groupScheduleBox'}
                            boxHeight={boxHeight}
                            rowPosition={rowPosition}
                            colPosition={colPosition}
                            parentItem={parentItem}
                            parentIndex={parentIndex}
                            childArr={childArr}
                            onClickSchedule={onClickSchedule}
                        />
                    )
                ) : childItem.type === 'block' ? (
                    <OffBlock
                        key={childIndex + 'offBox'}
                        colPosition={colPosition}
                        rowPosition={rowPosition}
                        boxHeight={boxHeight}
                        childItem={childItem}
                        onClickEtcOffSchedule={onClickEtcOffSchedule}
                    />
                ) : childItem.type === 'onetimeSchedule' ? (
                    <EtcBlock
                        key={childIndex + 'etcBox'}
                        childItem={childItem}
                        colPosition={colPosition}
                        rowPosition={rowPosition}
                        boxHeight={boxHeight}
                        parentItem={parentItem}
                        parentIndex={parentIndex}
                        onClickEtcOffSchedule={onClickEtcOffSchedule}
                    />
                ) : (
                    childItem.type === 'request' && (
                        <RequestBlock
                            key={childIndex + 'requestBox'}
                            childItem={childItem}
                            colPosition={colPosition}
                            rowPosition={rowPosition}
                            boxHeight={boxHeight}
                            parentItem={parentItem}
                            parentIndex={parentIndex}
                            onClickRequest={onClickRequest}
                            onClickApproveScheduleRequest={onClickApproveScheduleRequest}
                            onClickRejectScheduleRequest={onClickRejectScheduleRequest}
                        />
                    )
                );
            });
        })
    );
};

export default ScheduleTableInnerBox;
