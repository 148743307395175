import React from 'react';
import { Col, Row } from '../../../lib/utils';
import { Body3Regular, CaptionRegular } from '../../../lib/font';
import { colors } from '../../../lib/colors';

import OneOnOneSchedule from './OneOnOneSchedule';
import OneOnTwoSchedule from './OneOnTwoSchedule';

const CreateOneScheduleRight = ({
    setIsOneOnOneClass,
    isOneOnOneClass,
    selectedMember,
    filteredMemberList,
    handleMemberSelect,
    searchText,
    onChangeText,
    coach,
    isDisabledInput,
    onClickDeleteSelectedMember,
    onClickClassPassPage,
    oneOnOneMemberShipIndex,
    setOneOnOneMemberShipIndex,
    firstMemberShipIndex,
    setFirstMemberShipIndex,
    secondMemberShipIndex,
    setSecondMemberShipIndex,
}: any) => {
    return (
        <Col style={{ width: 470, marginLeft: 138 }}>
            <Row style={{ alignItems: 'center' }}>
                <Body3Regular style={{ color: colors.LAVEL_4 }}>회원 선택</Body3Regular>
                {coach.value === '' ? (
                    <CaptionRegular style={{ color: colors.LAVEL_2, marginLeft: 12 }}>
                        담당강사를 먼저 선택해주세요!
                    </CaptionRegular>
                ) : null}
            </Row>

            <Row
                style={{
                    width: 204,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginLeft: 8,
                    marginTop: 16,
                    marginBottom: 16,
                }}
            >
                <Row
                    onClick={() => setIsOneOnOneClass(true)}
                    style={{
                        width: 76,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <Row
                        style={{
                            width: 16,
                            height: 16,
                            borderRadius: '50%',
                            border: isOneOnOneClass ? '1px solid #3d73dd' : '1px solid #a6bfef',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Row
                            style={{
                                width: 10,
                                height: 10,
                                borderRadius: '50%',
                                backgroundColor: isOneOnOneClass ? colors.BLUE_500 : colors.WHITE_50,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        />
                    </Row>
                    <Body3Regular style={{ color: colors.LAVEL_4 }}>1:1수업</Body3Regular>
                </Row>

                <Row
                    onClick={() => setIsOneOnOneClass(false)}
                    style={{
                        width: 76,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <Row
                        style={{
                            width: 16,
                            height: 16,
                            borderRadius: '50%',
                            border: isOneOnOneClass ? '1px solid #a6bfef' : '1px solid #3d73dd',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Row
                            style={{
                                width: 10,
                                height: 10,
                                borderRadius: '50%',
                                backgroundColor: isOneOnOneClass ? colors.WHITE_50 : colors.BLUE_500,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        />
                    </Row>
                    <Body3Regular style={{ color: colors.LAVEL_4 }}>1:2수업</Body3Regular>
                </Row>
            </Row>

            {isOneOnOneClass ? (
                <OneOnOneSchedule
                    selectedMember={selectedMember}
                    onClickDeleteSelectedMember={onClickDeleteSelectedMember}
                    onClickClassPassPage={onClickClassPassPage}
                    filteredMemberList={filteredMemberList}
                    handleMemberSelect={handleMemberSelect}
                    searchText={searchText}
                    onChangeText={onChangeText}
                    isDisabledInput={isDisabledInput}
                    coach={coach}
                    oneOnOneMemberShipIndex={oneOnOneMemberShipIndex}
                    setOneOnOneMemberShipIndex={setOneOnOneMemberShipIndex}
                />
            ) : (
                <OneOnTwoSchedule
                    selectedMember={selectedMember}
                    onClickDeleteSelectedMember={onClickDeleteSelectedMember}
                    onClickClassPassPage={onClickClassPassPage}
                    filteredMemberList={filteredMemberList}
                    handleMemberSelect={handleMemberSelect}
                    searchText={searchText}
                    onChangeText={onChangeText}
                    isDisabledInput={isDisabledInput}
                    coach={coach}
                    firstMemberShipIndex={firstMemberShipIndex}
                    setFirstMemberShipIndex={setFirstMemberShipIndex}
                    secondMemberShipIndex={secondMemberShipIndex}
                    setSecondMemberShipIndex={setSecondMemberShipIndex}
                />
            )}
        </Col>
    );
};

export default CreateOneScheduleRight;
