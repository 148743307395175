import styled from 'styled-components';

export const Head1 = styled.span`
    font-size: 4rem;
    font-weight: 800;
`;

export const Head2 = styled.span`
    font-size: 3.6rem;
    font-weight: 700;
`;
export const Head3 = styled.span`
    font-size: 3.2rem;
    font-weight: 700;
`;
export const Head4 = styled.span`
    font-size: 2.8rem;
    font-weight: 700;
`;

export const Subtitle1Bold = styled.span`
    font-size: 2.4rem;
    font-weight: 700;
`;

export const Subtitle1Regular = styled.span`
    font-size: 2.4rem;
    font-weight: 400;
`;

export const Body1Bold = styled.span`
    font-size: 2rem;
    font-weight: 700;
`;
export const Body1Regular = styled.span`
    font-size: 2rem;
    font-weight: 400;
`;
export const Body1Light = styled.span`
    font-size: 2rem;
    font-weight: 300;
`;

export const Body2Bold = styled.span`
    font-size: 1.8rem;
    font-weight: 700;
`;
export const Body2Regular = styled.span`
    font-size: 1.8rem;
    font-weight: 400;
`;
export const Body2Light = styled.span`
    font-size: 1.8rem;
    font-weight: 300;
`;

export const Body3Bold = styled.span`
    font-size: 1.6rem;
    font-weight: 700;
`;

export const Body3Regular = styled.span`
    font-size: 1.6rem;
    font-weight: 400;
`;
export const Body3Light = styled.span`
    font-size: 1.6rem;
    font-weight: 300;
`;

export const CaptionBold = styled.span`
    font-size: 1.2rem;
    font-weight: 700;
`;

export const CaptionRegular = styled.span`
    font-size: 1.2rem;
    font-weight: 400;
`;
export const CaptionLight = styled.span`
    font-size: 1.2rem;
    font-weight: 300;
`;

export const OverlineBold = styled.span`
    font-size: 1rem;
    font-weight: 700;
`;

export const OverlineRegular = styled.span`
    font-size: 1rem;
    font-weight: 400;
`;
export const OverlineLight = styled.span`
    font-size: 1rem;
    font-weight: 300;
`;
