import React, { useEffect, useRef } from 'react';
import { Col, clearLocalStorage } from '../../lib/utils';
import styled from 'styled-components';
import { I_POPUP_EXIT } from '../../types/images';
import ProfileImage from '../ProfileImage';
import { Body3Bold, CaptionRegular } from '../../lib/font';
import { colors } from '../../lib/colors';
const HoverImage = styled.img`
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

const ProfileContent = styled.button`
    display: flex;
    flex-direction: column;
    border: none;
    width: 240px;
    height: 176px;
    align-items: center;
    padding-top: 24px;
    &:hover {
        background-color: #ecf1fc;
    }
`;

const GnbMyInfo = ({ authObject, setIsVisibleInfo, navigate, onClickTerm }: any) => {
    const modalRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
            setIsVisibleInfo(false);
        }
    };

    const handleClick = (event: any) => {
        event.preventDefault(); // 기본 링크 이동 방지
        window.open('http://pf.kakao.com/_ICxhcb/chat', '_blank'); // 새 탭에서 링크 열기
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <Col
            ref={modalRef}
            style={{
                width: 240,
                height: 346,
                position: 'absolute',
                borderRadius: 8,
                backgroundColor: '#F5F9FFE0',
                marginTop: 430,
                marginLeft: 81,
                transition: 'all 1s ease',
                boxShadow: '0px 6px 12px 0px #0000003D',
            }}
        >
            <HoverImage
                src={I_POPUP_EXIT}
                style={{ width: 24, height: 24, marginLeft: 4, marginTop: 4 }}
                onClick={() => setIsVisibleInfo(false)}
            />
            <ProfileContent
                onClick={() => {
                    navigate('/myPage');
                    setIsVisibleInfo(false);
                }}
            >
                <ProfileImage
                    imageUrl={authObject?.profileImageUrl}
                    type="VeryBig"
                    profileName={authObject?.profileName}
                    profileColorType={authObject?.profileColorType}
                    right={8}
                />

                <Body3Bold style={{ color: colors.LAVEL_3, marginTop: 12 }}>{`${authObject?.name} 님`}</Body3Bold>
                <CaptionRegular style={{ color: colors.LAVEL_3 }}>{`${authObject.centerName} 센터`}</CaptionRegular>
            </ProfileContent>

            <Col
                style={{
                    width: 140,
                    alignItems: 'flex-start',
                    height: 96,
                    justifyContent: 'space-between',
                    marginTop: 16,
                    marginLeft: 16,
                }}
            >
                <button
                    onClick={(e: any) => {
                        setIsVisibleInfo(false);

                        handleClick(e);
                    }}
                    style={{ border: 'none' }}
                >
                    <CaptionRegular style={{ color: colors.LAVEL_3 }}>{'실시간 1:1 문의하기'}</CaptionRegular>
                </button>
                <button style={{ border: 'none' }} onClick={onClickTerm}>
                    <CaptionRegular style={{ color: colors.LAVEL_3 }}>{'이용약관 / 개인정보 정책'}</CaptionRegular>
                </button>
                <button
                    onClick={() => {
                        clearLocalStorage();
                        navigate('/signIn');
                    }}
                    style={{ border: 'none' }}
                >
                    <CaptionRegular style={{ color: colors.LAVEL_3 }}>{'로그아웃'}</CaptionRegular>
                </button>
            </Col>
        </Col>
    );
};

export default GnbMyInfo;
