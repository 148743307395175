import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import ModalFeedConditionHeader from './ModalFeedConditionHeader';
import { Col, Row } from '../../../lib/utils';
import BasicInfo from './BasicInfo';
import BodyPainList from './BodyPainList';
import PmsInfo from './PmsInfo';
import Memo from './Memo';
import { colors } from '../../../lib/colors';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    overflow: hidden;
`;

const ModalInner = styled.div`
    background: white;
    width: 880px;
    height: 480px;
    border-radius: 8px;
    box-shadow: 8px 8px 24px 0px #00000052;

    display: flex;
    flex-direction: column;
    background: rgba(245, 249, 255, 1);
    overflow-y: auto; /* 세로 스크롤 가능 */
`;

type Props = {
    modalObject: any;
    setModalVisible: Dispatch<SetStateAction<boolean>>;
};

const ModalFeedCondition = ({ modalObject, setModalVisible }: Props) => {
    const scheduleReport = modalObject?.scheduleReport;
    const condition = scheduleReport?.condition;
    const PMSReports = condition?.PMSReports;

    const receivers = modalObject?.receivers[0];

    console.log(modalObject);
    return (
        <ModalOverLay>
            <ModalInner>
                <ModalFeedConditionHeader modalObject={modalObject} setModalVisible={setModalVisible} />
                <Col style={{ width: 880, marginTop: 62 + 30, paddingLeft: 60 }}>
                    {/* 수업시간, 회원권, 컨디션, 수면 시간, 체중 */}
                    <BasicInfo scheduleReport={scheduleReport} receivers={receivers} />

                    {/* 신체 통증 리스트 */}
                    <BodyPainList scheduleReport={scheduleReport} />

                    {/* 월경 유무 */}
                    {PMSReports && PMSReports?.PMSLevel && <PmsInfo scheduleReport={scheduleReport} />}

                    {/* 회원 메모 */}
                    {condition?.memo && <Memo condition={condition} />}

                    <Row style={{ width: 200, height: 100 }} />
                </Col>
            </ModalInner>
        </ModalOverLay>
    );
};

export default ModalFeedCondition;
