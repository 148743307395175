import { Row } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import { Body2Bold, Head4 } from '../../../lib/font';
import { I_POPUP_EXIT } from '../../../types/images';
import { HoverImage } from '../../../lib/styled';

const ModalFeedConditionHeader = ({ modalObject, setModalVisible }: any) => {
    return (
        <Row
            style={{
                width: 880,
                position: 'fixed',
                height: 62,
                zIndex: 5,
                borderTopRightRadius: 24,
                borderTopLeftRadius: 24,
                backgroundColor: colors.WHITE_200,
                boxShadow: '0px 2px 6px 0px #00000014',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingLeft: 39,
                paddingRight: 14,
            }}
        >
            <Body2Bold style={{ color: colors.LAVEL_1 }}>{`컨디션 정보`}</Body2Bold>
            <HoverImage src={I_POPUP_EXIT} style={{ width: 32, height: 32 }} onClick={() => setModalVisible(false)} />
        </Row>
    );
};

export default ModalFeedConditionHeader;
