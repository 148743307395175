import { colors } from '../../lib/colors';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
dayjs.locale('ko');

const GridShadow = ({ currentDate, selectedDay }: any) => {
    return dayjs(selectedDay).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') ? (
        <div>
            <hr
                style={{
                    width: '92.5%',
                    top: 265 + parseInt(dayjs().format('H')) * 120 + parseInt(dayjs().format('m')) * 2 - 0.6 + 5,
                    left: '7.5%',
                    border: '1.5px solid #2B529D',
                    position: 'absolute',
                }}
            />

            <div
                style={{
                    width: 8,
                    height: 8,
                    borderRadius: 4,
                    backgroundColor: colors.BLUE_700,
                    position: 'absolute',
                    top: 265 + parseInt(dayjs().format('H')) * 120 + parseInt(dayjs().format('m')) * 2 - 3 + 5,
                    left: '7.2%',
                }}
            />
        </div>
    ) : null;
};

export default GridShadow;
