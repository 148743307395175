import React from 'react';
import { Body1Bold, Head1, Subtitle1Bold } from '../../lib/font';
import { colors } from '../../lib/colors';
import { I_1440_LOGO, I_BACK } from '../../types/images';
import { StepType } from '../../scenes/SignUpPage';
import { Row } from '../../lib/utils';

interface I_Props {
    step: StepType;
    onClickLoginPage: () => void;
}
const SignUpHeader = ({ step, onClickLoginPage }: I_Props) => {
    return (
        <>
            <img
                src={I_1440_LOGO}
                style={{
                    width: '12rem',
                    height: '6.8rem',
                    marginTop: '18rem',
                }}
            />

            <Row
                style={{ width: '12rem', height: 4, borderRadius: 8, backgroundColor: colors.WHITE_600, marginTop: 32 }}
            >
                <Row
                    style={{
                        width: step === 'first' ? '6rem' : '12rem',
                        height: 4,
                        borderRadius: 8,
                        backgroundColor: colors.primary,
                    }}
                ></Row>
            </Row>
        </>
    );
};

export default SignUpHeader;
