import { Col, getItemWithExpiration } from '../../lib/utils';
import ScheduleHistoryGraph from '../../components/ScheduleHistory/ScheduleHistoryGraph/ScheduleHistoryGraph';
import ScheduleHistoryList from '../../components/ScheduleHistory/ScheduleHistoryList/ScheduleHistoryList';
import ScheduleHistoryMemberCancel from '../../components/ScheduleHistory/ScheduleHistoryMemberCancel/ScheduleHistoryMemberCancel';
import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { storeUserFlow } from '../../api/adminuserflow';

const ScheduleHistoryPage = ({ createAlert, setIsLoading }: any) => {
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));

    // 접속 데이터 측정 API
    const pushUserFlow = useMutation({
        mutationFn: async () => await storeUserFlow('scheduleHistory', authorization),
    });

    useEffect(() => {
        try {
            if (pushUserFlow) {
                pushUserFlow.mutate();
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    return (
        <Col style={{ width: '100%', alignItems: 'center', marginTop: 64 }}>
            {/* 당일 취소 및 변경 횟수 */}
            <ScheduleHistoryGraph authorization={authorization} authObject={authObject} />

            {/* 당일 취소 및 변경 리스트 */}
            <ScheduleHistoryList authorization={authorization} authObject={authObject} />

            {/* 회원의 수업 취소 */}
            <ScheduleHistoryMemberCancel authorization={authorization} authObject={authObject} />
        </Col>
    );
};

export default ScheduleHistoryPage;
