import { CSSProperties } from 'react';
import { Body2Bold } from '../../lib/font';
import styled from 'styled-components';

type Props = {
    text: string;
    type?: any;
    style?: CSSProperties;
    onClick?: any;
    disabled?: boolean;
};

const SmallButtonWrap = styled.button`
    width: 100px;
    height: 28px;
    border-radius: 24px;
    box-shadow: 1px 1px 8px 0px rgba(43, 82, 157, 0.18);

    background-color: rgba(248, 251, 255, 1);
    color: ${(props) => (props.disabled ? 'rgba(165, 171, 181, 1)' : 'rgba(26, 48, 93, 1)')};
    &:not([disabled]):hover {
        background-color: #e9f1ff;
        border-width: 2;
        border-color: #7da1e8;
        color: #3869c9;
    }
    &:not([disabled]):active {
        transform: scale(0.98);
        background-color: #648fe4;
        color: #fdfeff;
        box-shadow: none;
    }
`;

/**
 * 100px * 28px 크기의 작은버튼
 */
const SmallButton = ({ text, type = 'button', style, onClick, disabled }: Props) => {
    return (
        <SmallButtonWrap type={type} disabled={disabled} style={style} onClick={onClick}>
            <Body2Bold> {text}</Body2Bold>
        </SmallButtonWrap>
    );
};

export default SmallButton;
