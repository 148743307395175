import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

const baseDate = dayjs().startOf('day');

const dailyDummyData: any = [];
const weeklyDummyData: any = [];
const monthlyDummyData: any = [];

let [dailyTotalCount, weeklyTotalCount, monthlyTotalCount] = [0, 0, 0];
let [dailyMaxCountDummy, weeklyMaxCountDummy, monthlyMaxCountDummy] = [10, 10, 10];

const dailyXLabelTemplate = ['일', '월', '화', '수', '목', '금', '토'];
const weeklyXLabelTemplate = ['첫째 주', '둘째 주', '셋째 주', '넷째 주', '다섯째 주', '여섯째 주'];
const monthlyXLabelTemplate = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];

const baseWeekDate = baseDate.startOf('week');
for (let i = 0; i < 7; i++) {
    const startDate = baseWeekDate.add(i, 'day').toISOString();
    const endDate = dayjs(startDate).endOf('day').toISOString();
    const todayCancelCount = dayjs().unix() > dayjs(startDate).unix() ? i : null;
    const todayChangeCount = dayjs().unix() > dayjs(startDate).unix() ? 6 - i : null;
    const xLabel = dailyXLabelTemplate[i];
    const xSubLabel = dayjs(startDate).format('D일');
    const isToday = dayjs().isBetween(dayjs(startDate), dayjs(endDate), 'day', '[]');
    if (!!todayCancelCount) {
        dailyTotalCount += todayCancelCount;
    }
    if (todayCancelCount !== null && todayChangeCount !== null) {
        dailyMaxCountDummy = Math.max(dailyMaxCountDummy, todayCancelCount, todayChangeCount);
    }

    dailyDummyData.push({
        startDate,
        endDate,
        todayCancelCount,
        todayChangeCount,
        xLabel,
        xSubLabel,
        isToday,
    });
}

// 주간 주차 계산 부분
const baseMonthDate = baseDate.startOf('month').startOf('week');
const startWeekNumberDate = baseMonthDate;
const endWeekNumberDate = baseDate.endOf('month').startOf('week');
let weekDiff = endWeekNumberDate.diff(startWeekNumberDate, 'day', true);
let maxWeekNumber = Math.floor(weekDiff) / 7 + 1; // 0주차는 존재할 수 없으니 1을 더해줌

for (let i = 0; i < maxWeekNumber; i++) {
    const startDate = baseMonthDate.add(i, 'week').toISOString();
    const endDate = dayjs(startDate).endOf('week').toISOString();
    const todayCancelCount = dayjs().unix() > dayjs(startDate).unix() ? i * 2 : null;
    const todayChangeCount = dayjs().unix() > dayjs(startDate).unix() ? (maxWeekNumber - 1 - i) * 2 : null;
    const xLabel = weeklyXLabelTemplate[i];
    const startDateSubLable = dayjs(startDate).format('M월 D일');
    const endDateSubLable = dayjs(endDate).format('M월 D일');
    const xSubLabel = `${startDateSubLable}(${dailyXLabelTemplate[dayjs(startDate).day()]})~${endDateSubLable}(${
        dailyXLabelTemplate[dayjs(endDate).day()]
    })`;
    const isToday = dayjs().isBetween(dayjs(startDate), dayjs(endDate), 'day', '[]');

    if (!!todayCancelCount) {
        weeklyTotalCount += todayCancelCount;
    }
    if (todayCancelCount !== null && todayChangeCount !== null) {
        weeklyMaxCountDummy = Math.max(weeklyMaxCountDummy, todayCancelCount, todayChangeCount);
    }

    weeklyDummyData.push({
        startDate,
        endDate,
        todayCancelCount,
        todayChangeCount,
        xLabel,
        xSubLabel,
        isToday,
    });
}

const baseYearDate = baseDate.startOf('year');

for (let i = 0; i < 12; i++) {
    const startDate = baseYearDate.add(i, 'month').toISOString();
    const endDate = dayjs(startDate).endOf('month').toISOString();
    const todayCancelCount = dayjs().unix() > dayjs(startDate).unix() ? i * 4 : null;
    const todayChangeCount = dayjs().unix() > dayjs(startDate).unix() ? (11 - i) * 4 : null;
    const xLabel = monthlyXLabelTemplate[i];
    const xSubLabel = null;
    const isToday = dayjs().isBetween(dayjs(startDate), dayjs(endDate), 'day', '[]');

    if (!!todayCancelCount) {
        monthlyTotalCount += todayCancelCount;
    }
    if (todayCancelCount !== null && todayChangeCount !== null) {
        monthlyMaxCountDummy = Math.max(monthlyMaxCountDummy, todayCancelCount, todayChangeCount);
    }

    monthlyDummyData.push({
        startDate,
        endDate,
        todayCancelCount,
        todayChangeCount,
        xLabel,
        xSubLabel,
        isToday,
    });
}

export {
    dailyDummyData,
    weeklyDummyData,
    monthlyDummyData,
    dailyMaxCountDummy,
    weeklyMaxCountDummy,
    monthlyMaxCountDummy,
};
