import { Col } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import FeedSelectListHeader from './FeedSelectListHeader';
import FeedSelectListContent from './FeedSelectListContent';

type Props = {
    coachId: string;
    memberId?: string | null | undefined;
    centerUserList: any;
    onClickCoach: any;
    onClickMember: any;
    onClickAllButton: any;
};
const FeedSelectList = ({
    coachId,
    memberId,
    centerUserList,
    onClickCoach,
    onClickMember,
    onClickAllButton,
}: Props) => {
    return (
        <Col
            style={{
                width: 380,
                backgroundColor: colors.WHITE_50,
                paddingTop: 16,
                paddingBottom: 24,
                borderRadius: 24,
                border: '1px solid #C3D4F4',
                alignSelf: 'flex-start',
            }}
        >
            {/* 상단 전체선택 */}
            <FeedSelectListHeader coachId={coachId} onClickAllButton={onClickAllButton} />
            {centerUserList && (
                <FeedSelectListContent
                    coachId={coachId}
                    memberId={memberId}
                    centerUserList={centerUserList}
                    onClickCoach={onClickCoach}
                    onClickMember={onClickMember}
                />
            )}
        </Col>
    );
};

export default FeedSelectList;
