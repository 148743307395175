import React, { ChangeEvent, useEffect, useState } from 'react';
import { Body1Bold, Body3Regular, CaptionLight } from '../../lib/font';
import { colors } from '../../lib/colors';
import { Col, Row, setItemWithExpiration } from '../../lib/utils';
import { useMutation } from '@tanstack/react-query';
import { modifyCenterInformationAPI } from '../../api/mypage';
import RoundedDropDown from '../DropDown/RoundedDropDown';
import ColorRoundButton from '../Button/ColorRoundButton';

let categoryOptions: any = [
    { value: 'PT', label: 'PT' },
    { value: '헬스', label: '헬스' },
    { value: '필라테스', label: '필라테스' },
    { value: '요가', label: '요가' },
    { value: '기타 체육시설', label: '기타 체육시설' },
    { value: '그 외', label: '그 외' },
];

const MyPageRightBottom = ({
    createAlert,
    authorization,
    authObject,
    setAuthObject,
    isOpenCategory,
    setIsOpenCategory,
    navigate,
}: any) => {
    // 초기값 세팅 - 센터 이름, 업종, 센터주소
    const [centerName, setCenterName] = useState<string>(authObject?.centerName);
    const [selectedCategory, setSelectedCategory] = useState<any>({
        value: authObject?.centerCategory,
        label: authObject?.centerCategory,
    });
    const [centerAddress, setCenterAddress] = useState<string>(authObject?.centerAddress);

    // 변경감지 플래그
    const [isChangedCenterName, setIsChangedCenterName] = useState<boolean>(false);
    const [isChangeCategory, setIsChangeCategory] = useState<boolean>(false);
    const [isChangeAddress, setIsChangeAddress] = useState<boolean>(false);

    // 업종 드롭다운 함수
    const toggleCategoryDropdown = () => {
        setIsOpenCategory(!isOpenCategory);
    };

    // 업종 선택 함수
    const handleCategorySelect = (option: any) => {
        setSelectedCategory(option);
        setIsOpenCategory(false);
    };

    // 센터명 변경
    const onChangeCenterName = (e: ChangeEvent<HTMLInputElement>) => {
        const currentCenterName = e.target.value;
        setCenterName(currentCenterName);
    };

    // 센터주소 변경
    const onChangeCenterAddress = (e: ChangeEvent<HTMLInputElement>) => {
        const currentCenterAddress = e.target.value;
        setCenterAddress(currentCenterAddress);
    };

    const resetFlag = () => {
        setIsChangedCenterName(false);
        setIsChangeCategory(false);
        setIsChangeAddress(false);
    };

    // 마이페이지 센터 정보 수정(PATCH)
    const modifyRepresentativeInformation = useMutation({
        mutationFn: async () =>
            await modifyCenterInformationAPI(
                authObject?.centerId,
                centerName,
                selectedCategory.value,
                centerAddress,
                authorization
            ),
        onSuccess: (res) => {
            if (res.status === 200) {
                const center = res.data.center;
                const authObjectData = {
                    adminId: center?.adminId,
                    id: authObject?.id,
                    name: authObject?.name,
                    email: authObject?.email,
                    gender: authObject?.gender,
                    phoneNumber: authObject?.phoneNumber,
                    profileImageUrl: authObject?.profileImageUrl,

                    centerAddress: center.address,
                    centerCategory: center.category,
                    centerCode: center.code,
                    centerName: center.name,
                    centerId: center.id,
                    isNewCommunicationBox: authObject?.isNewCommunicationBox,
                    // 구독플랜관련
                    coachCount: authObject?.coachCount,
                    memberCount: authObject?.memberCount,
                    activationDate: authObject?.activationDate,
                    expirationDate: authObject?.expirationDate,
                    expirationDelayDate: authObject?.expirationDelayDate,
                    finalPaymentDate: authObject?.finalPaymentDate,
                    finalPaymentTryDate: authObject?.finalPaymentTryDate,
                    cardIssuingBank: authObject?.cardIssuingBank,
                    cardNumber: authObject?.cardNumber,
                    planAmount: authObject?.planAmount,
                    usableStatus: authObject?.usableStatus,

                    paymentDueDate: authObject?.paymentDueDate,

                    /** 구독플랜 인원 정보 */
                    planLimitCount: authObject?.planLimitCount, // 플랜 제한 이용 인원
                    prevPlanLimitCount: authObject?.prevPlanLimitCount, // 구독 인원 변경시 변경전 플랜카운트
                    changedPlanLimitCount: authObject?.changedPlanLimitCount, // 구독 인원 변경시 변경 플랜카운트

                    confirmUsableStatus: authObject?.confirmUsableStatus,
                    testAccount: authObject?.testAccount,
                };
                setItemWithExpiration('authorization', authorization, 12);
                setItemWithExpiration('authObject', authObjectData, 12);
                setAuthObject(authObjectData);
                resetFlag();

                createAlert('수정 완료', '센터 정보 수정을 완료하였습니다.', false);
            } else {
                createAlert('입력 오류', '센터 정보 수정중 오류가 발생하였습니다.', true);
            }
        },
    });

    // 저장하기 버튼 클릭이벤트
    const onClickSave = () => {
        modifyRepresentativeInformation.mutate();
    };

    useEffect(() => {
        if (centerName === authObject?.centerName) {
            setIsChangedCenterName(false);
        } else {
            setIsChangedCenterName(true);
        }

        if (selectedCategory.value === authObject?.centerCategory) {
            setIsChangeCategory(false);
        } else {
            setIsChangeCategory(true);
        }

        if (centerAddress === authObject?.centerAddress) {
            setIsChangeAddress(false);
        } else {
            setIsChangeAddress(true);
        }
    }, [centerName, selectedCategory, centerAddress]);

    return (
        <div style={{ marginTop: 24 }}>
            <Col
                style={{
                    width: 888,
                    height: 402,
                    borderRadius: 16,
                    backgroundColor: colors.WHITE_50,
                    border: '1px solid #C3D4F4',
                }}
            >
                <Row style={{ alignItems: 'center', marginLeft: 40, marginTop: 32 }}>
                    <Body1Bold style={{ color: colors.LAVEL_4 }}> {'센터 정보'}</Body1Bold>
                    <CaptionLight style={{ color: colors.LAVEL_1, marginLeft: 16 }}>
                        {'관리자님의 센터 관리를 위한 센터 정보를 작성해주세요 !'}
                    </CaptionLight>
                </Row>
                <Row style={{ display: 'flex', marginTop: 32, marginLeft: 48 }}>
                    <Col>
                        <label htmlFor="centerName" style={{ display: 'flex', marginBottom: 8 }}>
                            <Body3Regular style={{ color: colors.LAVEL_4 }}>센터 이름</Body3Regular>
                        </label>

                        <input
                            id="centerName"
                            name="centerName"
                            value={centerName}
                            onChange={onChangeCenterName}
                            readOnly={authObject?.testAccount}
                            style={{
                                width: 240,
                                height: 48,
                                backgroundColor: colors.WHITE_50,
                                borderRadius: 48,
                                paddingLeft: 16,
                                border: '1px solid #3D73DD',
                                fontSize: 18,
                                fontWeight: 400,
                                color: colors.LAVEL_3,
                            }}
                        />
                    </Col>
                    <Col style={{ marginLeft: 80 }}>
                        <label htmlFor="centerCategory" style={{ display: 'flex', marginBottom: 8 }}>
                            <Body3Regular style={{ color: colors.LAVEL_4 }}>업종</Body3Regular>
                        </label>

                        <RoundedDropDown
                            toggleSortDropDown={toggleCategoryDropdown}
                            sortType={selectedCategory}
                            isOpenSort={isOpenCategory}
                            sortOptions={categoryOptions}
                            handleSortSelect={handleCategorySelect}
                            size="middle"
                        />
                    </Col>
                </Row>

                <Col style={{ height: 118, marginTop: 18, marginLeft: 48 }}>
                    <label htmlFor="centerAddress" style={{ display: 'flex', marginBottom: 8 }}>
                        <Body3Regular style={{ color: colors.LAVEL_4 }}>센터주소</Body3Regular>
                    </label>
                    <input
                        id="centerAddress"
                        name="centerAddress"
                        value={centerAddress}
                        onChange={onChangeCenterAddress}
                        readOnly={authObject?.testAccount}
                        style={{
                            width: 626,
                            height: 48,
                            backgroundColor: colors.WHITE_50,
                            borderRadius: 48,
                            border: '1px solid #A6BFEF',
                            paddingLeft: 16,
                            fontSize: 18,
                            fontWeight: 400,

                            // //lineHeight: 22,
                        }}
                        placeholder="센터의 주소를 입력해주세요"
                    />
                </Col>

                <ColorRoundButton
                    customStyle={{ width: 113, height: 36, marginLeft: 742 }}
                    text="변경하기"
                    size="small"
                    callBack={onClickSave}
                    isDisabled={!(isChangedCenterName || isChangeCategory || isChangeAddress)}
                />
            </Col>
        </div>
    );
};

export default MyPageRightBottom;
