import React, { useEffect, useState } from 'react';
import { Col, Row } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import { Body3Regular } from '../../../lib/font';
import ProfileImage from '../../ProfileImage';

import {
    I_LEFT_ANGLE_BRACKET_DEFAULT,
    I_LEFT_ANGLE_BRACKET_HOVER,
    I_LEFT_ANGLE_BRACKET_CLICK,
    I_RIGHT_ANGLE_BRACKET_DEFAULT,
    I_RIGHT_ANGLE_BRACKET_HOVER,
    I_RIGHT_ANGLE_BRACKET_CLICK,
} from '../../../types/images';
import styled from 'styled-components';

const LeftLogoButtonWrap = styled.button`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-left: 59.25%;

    border: none;
    background: none;
    padding: 0;
    cursor: pointer;

    img {
        width: 82px;
        height: 48px;
        transition: all 0.2s ease-in-out;
    }

    &:hover img {
        content: url(${I_LEFT_ANGLE_BRACKET_HOVER});
    }

    &:active img {
        content: url(${I_LEFT_ANGLE_BRACKET_CLICK});
    }
`;

// const RightLogoButtonWrap = styled.button`
//     position: absolute;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     width: 32px;
//     height: 32px;
//     left: calc(50% + 20px);
//     transform: 'translateX(0)'

//     border: none;
//     background: none;
//     padding: 0;
//     cursor: pointer;

//     img {
//         width: 32px;
//         height: 32px;
//         transition: all 0.2s ease-in-out;
//     }

//     &:hover img {
//         content: url(${I_RIGHT_ANGLE_BRACKET_HOVER});
//     }

//     &:active img {
//         content: url(${I_RIGHT_ANGLE_BRACKET_CLICK});
//     }
// `;

const RightLogoButtonWrap = styled.button`
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 32px;
    height: 32px;
    right: 0; /* 오른쪽에 붙이기 위한 설정 */
    top: 50%;
    transform: translateY(-50%); /* 세로 중앙 정렬 */

    border: none;
    background: none;
    padding: 0;
    cursor: pointer;

    img {
        width: 32px;
        height: 32px;
        transition: all 0.2s ease-in-out;
    }

    &:hover img {
        content: url(${I_RIGHT_ANGLE_BRACKET_HOVER});
    }

    &:active img {
        content: url(${I_RIGHT_ANGLE_BRACKET_CLICK});
    }
`;

const CoachList = ({ dailyCurrentData, scrollY, currentPage, endPage, handleNextPage, handlePrevPage }: any) => {
    return (
        <Row
            style={{
                width: '100%',
                height: 90,
                marginTop: scrollY ? 0 : 24,
                backgroundColor: colors.WHITE_50,
                border: '1px solid #A6BFEF',

                position: scrollY ? 'fixed' : 'relative',
                top: scrollY ? 152 : 0,
                // transition: 'all 0.3s ease',
                left: 0,
                zIndex: 3,
            }}
        >
            <Row
                className={'first test!'}
                style={{
                    width: '7.5%',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                }}
            >
                {endPage > 1 && currentPage > 1 ? (
                    <LeftLogoButtonWrap onClick={handlePrevPage}>
                        <img style={{ width: 32, height: 32 }} src={I_LEFT_ANGLE_BRACKET_DEFAULT} />
                    </LeftLogoButtonWrap>
                ) : null}
            </Row>
            {dailyCurrentData &&
                dailyCurrentData.map((item: any, index: number) => {
                    return (
                        <Col
                            className={`test!~${index}`}
                            style={{
                                position: 'relative',
                                width: '11.5%',
                                display: 'flex',
                                flexDirection: 'column', // 세로 방향으로 정렬
                                alignItems: 'center', // 가로 방향에서 중앙 정렬
                                justifyContent: 'center', // 세로 방향에서 중앙 정렬
                            }}
                            key={index + 'dailySchedules'}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <ProfileImage
                                    imageUrl={item?.coachProfileImageUrl}
                                    type="Small"
                                    profileName={item?.coachProfileName}
                                    profileColorType={item?.coachProfileColorType}
                                />
                                <Body3Regular style={{ color: colors.LAVEL_3 }}>{item.coachName}</Body3Regular>
                            </div>

                            {endPage > 1 && currentPage === 1 && index === 7 ? (
                                <RightLogoButtonWrap onClick={handleNextPage}>
                                    <img style={{ width: 32, height: 32 }} src={I_RIGHT_ANGLE_BRACKET_DEFAULT} />
                                </RightLogoButtonWrap>
                            ) : null}
                        </Col>
                    );
                })}
        </Row>
    );
};

export default CoachList;
