import React, { useEffect, useRef, useState } from 'react';
import styled, { css, CSSProperties, keyframes } from 'styled-components';
import { Row } from '../../lib/utils';
import { Body3Regular } from '../../lib/font';
import { I_DROPDOWN_DEFAULT, I_DROPDOWN_HOVER, I_PRIMARY_TRIANGLE } from '../../types/images';
import { colors } from '../../lib/colors';

// Styled Components
const DropdownContainer = styled.div`
    position: relative;
    display: inline-block;
    width: 20.4rem;
`;

const shrinkBorder = keyframes`
  0% {
    left: 0;
    width: 100%;
  }
  50% {
    left: 25%;
    width: 50%;
  }
  100% {
    left: 50%;
    width: 0;
  }
`;

const expandBorder = keyframes`
  0% {
    width: 0;
    left: 50%;
  }
  50% {
    width: 50%;
    left: 25%;
  }
  100% {
    width: 100%;
    left: 0;
  }
`;

const DropdownButton = styled.div<{ isOpen: boolean; isemptyvalue: string }>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #fdfeff;
    color: #acacac;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    height: 48px;
    font-weight: 400;
    font-size: 1.125rem;
    cursor: pointer;
    z-index: 2;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: ${({ isOpen }) => (isOpen ? '100%' : '0')};
        height: 1px;
        background-color: ${({ isemptyvalue }) => (isemptyvalue === 'true' ? '#a6bfef' : '#3D73DD')};
        transition: width 0.3s ease;
        ${({ isOpen }) =>
            isOpen
                ? css`
                      animation: ${shrinkBorder} 0.3s forwards;
                  `
                : css`
                      animation: ${expandBorder} 0.3s forwards;
                  `}
    }

    &:hover {
        background-color: #f8fbff;
    }
`;

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideUp = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

const DropdownContent = styled.div<{ isOpen: boolean }>`
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    position: absolute;
    background-color: #f0f6ff;
    width: 100%;
    max-height: 284px;
    overflow: auto;
    padding-left: 2px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding-top: 24px;
    padding-bottom: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    top: 24px;
    animation: ${({ isOpen }) => (isOpen ? slideDown : slideUp)} 0.3s ease forwards;
    z-index: 1;
    cursor: pointer;
`;

const DropdownItem = styled.div`
    width: 98.9%;
    height: 48px;
    text-decoration: none;
    display: flex;
    align-items: center;
    background-color: #fdfeff;
    margin-top: 4px;
    padding-left: 14px;
    z-index: 1;
    &:hover {
        background-color: #f8fbff;
    }
`;

interface I_Props {
    selectedGender: any;
    genderOptions: any;
    handleGenderSelect: (gender: string, index?: number) => void;
    disabled?: boolean;
    customStyle?: CSSProperties;
    placeHolder?: string;
    isJustClick?: boolean;
    justClickString?: string;
    justClickCallBack?: () => void;
    repeatIndex?: number;
    zIndex?: number;
}

const RepeatInputSquareDropDown = ({
    selectedGender,
    genderOptions,
    handleGenderSelect,
    disabled = false,
    customStyle,
    placeHolder,
    isJustClick = false,
    justClickString,
    justClickCallBack,
    repeatIndex,
    zIndex,
}: I_Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isHovered, setIsHovered] = useState(false);

    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    const dropdownContentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isOpen) {
            // 모달이 열릴 때 body의 스크롤을 막음
            document.body.style.overflow = 'hidden';
        } else {
            // 모달이 닫힐 때 body의 스크롤을 복원
            document.body.style.overflow = 'auto';
        }

        const selectedItemIndex = genderOptions?.findIndex((time: any) => time.label === selectedGender.label);

        if (isOpen && dropdownContentRef.current) {
            const selectedItem = dropdownContentRef.current.children[selectedItemIndex] as HTMLElement;
            if (selectedItem) {
                const dropdownTop = dropdownContentRef.current.offsetTop;
                const itemTop = selectedItem.offsetTop;
                dropdownContentRef.current.scrollTop = itemTop - dropdownTop;
            }
        }

        // 컴포넌트가 언마운트될 때 스크롤을 복원
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    return (
        <DropdownContainer
            style={customStyle}
            ref={dropdownRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <DropdownButton
                isOpen={isOpen}
                isemptyvalue={(!!!selectedGender.value).toString()}
                style={{ zIndex: zIndex ? zIndex + 2 : 2 }}
                onClick={() => {
                    toggleDropdown();

                    isJustClick && justClickCallBack && justClickCallBack();
                }}
            >
                {isJustClick ? (
                    <Body3Regular
                        style={{
                            position: 'relative',
                            color: isJustClick
                                ? colors.LAVEL_3
                                : selectedGender.value
                                ? colors.LAVEL_3
                                : colors.GREY_200,
                        }}
                    >
                        {justClickString && justClickString}
                    </Body3Regular>
                ) : (
                    <Body3Regular
                        style={{
                            position: 'relative',
                            color: isJustClick
                                ? colors.LAVEL_3
                                : selectedGender.value
                                ? colors.LAVEL_3
                                : colors.GREY_200,
                        }}
                    >
                        {selectedGender.label}
                    </Body3Regular>
                )}

                <img
                    style={{ width: 24, height: 24, transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
                    src={isHovered ? I_DROPDOWN_HOVER : I_DROPDOWN_DEFAULT}
                />
            </DropdownButton>
            {!isJustClick && (
                <DropdownContent style={{ zIndex: zIndex ? zIndex + 1 : 10 }} isOpen={isOpen} ref={dropdownContentRef}>
                    {genderOptions.map((option: any, index: number) => (
                        <DropdownItem
                            key={option.value}
                            onClick={() => {
                                handleGenderSelect(option, repeatIndex);
                                toggleDropdown();
                            }}
                        >
                            <Body3Regular style={{ color: colors.LAVEL_4 }}>{option.label}</Body3Regular>
                        </DropdownItem>
                    ))}
                </DropdownContent>
            )}
        </DropdownContainer>
    );
};

export default RepeatInputSquareDropDown;
