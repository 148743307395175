import styled from 'styled-components';

export const HoverReason = styled.div`
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(0%);
    background-color: rgba(20, 20, 23, 0.56); /* 배경색과 투명도 설정 */
    color: #fff;
    padding: 16px;
    border-radius: 4px;
    white-space: pre-line;
    z-index: 10;
    max-width: 325px; /* 원하는 최대 너비 설정 */
    min-width: 325px; /* 원하는 최소 너비 설정 */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    visibility: visible;
    word-wrap: break-word; /* 단어가 길 경우 줄 바꿈 처리 */
    border-radius: 16px; /* 모서리를 둥글게 설정 */
`;
