import React, { useEffect, useState } from 'react';
import { Col, getItemWithExpiration } from '../../lib/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import NormalButton from '../../components/Button/NormalButton';
import { I_FILL_ALERT } from '../../types/images';
import { Body2Regular, Subtitle1Bold } from '../../lib/font';
import { colors } from '../../lib/colors';
import ColorSquareButton from '../../components/Button/ColorSquareButton';

const FailChangePayInformation = ({ createAlert }: any) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));

    useEffect(() => {
        if (authObject) {
            if (authObject?.usableStatus === 'trial') {
                navigate('/myPage', { replace: true });
            } else {
                return;
            }
        }
    }, [authObject]);

    return (
        <Col style={{ width: '100%', alignItems: 'center', marginTop: 200 }}>
            <img src={I_FILL_ALERT} style={{ width: 24, height: 24 }} />
            <Subtitle1Bold style={{ color: colors.LAVEL_4, marginTop: 8 }}>{'잘못된 정보입니다!'}</Subtitle1Bold>

            <Body2Regular
                style={{
                    color: colors.LAVEL_4,
                    marginTop: 32,
                    width: 400,
                    textAlign: 'center',
                    whiteSpace: 'pre-wrap',
                }}
            >
                {queryParams.get('message')}
            </Body2Regular>

            <ColorSquareButton
                text="결제페이지 돌아가기"
                size="middle"
                callBack={() => {
                    navigate('/myPage', { replace: true });
                }}
                isVisibleLeftImg={false}
                isVisibleRightImg={false}
                customStyle={{
                    width: 240,
                    height: 48,
                    marginTop: 32,
                }}
            />
        </Col>
    );
};

export default FailChangePayInformation;
