import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Row } from '../../lib/utils';
import { CaptionRegular } from '../../lib/font';
import { I_PRIMARY_TRIANGLE } from '../../types/images';
import { colors } from '../../lib/colors';

// Styled Components
const DropdownContainer = styled.div`
    position: relative;
    display: inline-block;
    cursor: pointer;
`;

const DropdownButton = styled.button`
    background-color: #fdfeff;
    position: relative;
    color: #4a4a4a;
    display: flex;
    align-items: center;

    border-radius: 24px;
    border: 1px solid #d4dbe8;
    cursor: pointer;
    z-index: 2;
`;

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideUp = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

const DropdownContent = styled.div<{ isOpen: boolean }>`
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    position: absolute;
    background-color: #f0f6ff;

    padding-left: 2px;
    box-shadow: 0px 1px 2px 0px #25252514;
    padding-top: 24px;
    padding-bottom: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    animation: ${({ isOpen }) => (isOpen ? slideDown : slideUp)} 0.3s ease forwards;
    z-index: 1;
`;

const DropdownItem = styled.a`
    text-decoration: none;
    display: flex;
    align-items: center;
    background-color: #fdfeff;
    margin-top: 4px;
    padding-left: 16px;
    z-index: 1;
    &:hover {
        background-color: #f1f1f1;
    }
`;
interface I_Props {
    toggleSortDropDown: () => void;
    sortType: any;
    isOpenSort: boolean;
    setIsOpenSort?: any;
    sortOptions: any;
    handleSortSelect: (gender: string) => void;
    type?: any;

    size: 'middle' | 'small' | 'small2';
    className?: string;
}

const RoundedDropDown = ({
    toggleSortDropDown,
    sortType,
    isOpenSort,
    setIsOpenSort,
    sortOptions,
    handleSortSelect,
    type = 'button',
    size,
    className,
}: I_Props) => {
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpenSort && setIsOpenSort(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <DropdownContainer ref={dropdownRef}>
            <DropdownButton
                style={{
                    zIndex: 2,
                    width: size === 'middle' ? 180 : size === 'small' ? 136 : 136,
                    height: size === 'middle' ? 48 : size === 'small' ? 32 : 36,
                }}
                onClick={toggleSortDropDown}
                className={className}
            >
                <Row style={{ width: 180, height: 48, paddingLeft: 16, alignItems: 'center' }}>
                    <CaptionRegular>{sortType.label}</CaptionRegular>
                </Row>
                <Row
                    style={{
                        width: 48,
                        height: 32,
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderLeft: '1px solid #D4DBE8',
                        borderColor: colors.WHITE_600,
                    }}
                >
                    <img
                        style={{ width: 8, height: 14, transform: isOpenSort ? 'rotate(270deg)' : 'rotate(90deg)' }}
                        src={I_PRIMARY_TRIANGLE}
                    />
                </Row>
            </DropdownButton>
            <DropdownContent
                style={{
                    width: size === 'middle' ? 180 : size === 'small' ? 136 : 136,
                    top: size === 'middle' ? 24 : size === 'small' ? 12 : 16,
                }}
                isOpen={isOpenSort}
            >
                {sortOptions.map((option: any, index: number) => (
                    <DropdownItem
                        style={{
                            width: size === 'middle' ? 176 : size === 'small' ? 132 : 132,
                            height: size === 'middle' ? 48 : size === 'small' ? 32 : 36,
                        }}
                        key={option.value}
                        onClick={() => handleSortSelect(option)}
                    >
                        <CaptionRegular> {option.label}</CaptionRegular>
                    </DropdownItem>
                ))}
            </DropdownContent>
        </DropdownContainer>
    );
};

export default RoundedDropDown;
