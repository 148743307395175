import { useEffect } from 'react';
import { Col, Row } from '../../../lib/utils';
import InputSearchDropdown from '../../DropDown/InputSearchDropdown';
import ProfileImage from '../../ProfileImage';
import { Body2Bold, Body3Bold, CaptionRegular, OverlineRegular } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import { I_CLOSE, I_ERROR, I_LEFT_ANGLE, I_RIGHT_ANGLE } from '../../../types/images';
import ColorRoundButton from '../../Button/ColorRoundButton';
import _ from 'lodash';

const OneOnTwoSchedule = ({
    selectedMember,
    onClickDeleteSelectedMember,
    onClickClassPassPage,
    filteredMemberList,
    handleMemberSelect,
    searchText,
    onChangeText,
    isDisabledInput,
    coach,
    firstMemberShipIndex,
    setFirstMemberShipIndex,
    secondMemberShipIndex,
    setSecondMemberShipIndex,
}: any) => {
    return (
        <Col>
            <InputSearchDropdown
                dropDownOptions={filteredMemberList}
                handleSelectOption={handleMemberSelect}
                searchText={searchText}
                onChangeText={onChangeText}
                placeholder="회원 이름을 검색해주세요!"
                isDisabled={coach.value === '' || isDisabledInput}
            />

            {selectedMember &&
                selectedMember.map((item: any, index: number) => {
                    const memberShip = item?.memberships;
                    const memberShipLength = memberShip ? memberShip.length : 0;

                    const currentMemberShip =
                        index === 0 ? memberShip[firstMemberShipIndex - 1] : memberShip[secondMemberShipIndex - 1];

                    return (
                        <Col style={{ marginTop: 12 }} key={index + 'oneOnTwoClass'}>
                            <Row>
                                <Row
                                    style={{
                                        width: 24,
                                        height: 24,
                                        borderRadius: '50%',
                                        backgroundColor: colors.BLUE_500,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: 14,
                                        marginRight: 8,
                                    }}
                                >
                                    <CaptionRegular style={{ color: colors.WHITE_50 }}>{index + 1}</CaptionRegular>
                                </Row>
                                <Row
                                    style={{
                                        width: 350,
                                        height: 48,
                                        borderRadius: 8,
                                        border: '1px solid #648fe4',
                                        cursor: 'pointer',
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Row style={{ alignItems: 'center' }}>
                                        <ProfileImage
                                            imageUrl={item?.profileImageUrl}
                                            type="Small"
                                            profileName={item?.profileName}
                                            profileColorType={item?.profileColorType}
                                        />
                                        <Body3Bold
                                            style={{ marginLeft: 16, color: colors.LAVEL_4 }}
                                        >{`${item?.label} 회원`}</Body3Bold>
                                    </Row>

                                    <img
                                        onClick={() => onClickDeleteSelectedMember(item?.value)}
                                        src={I_CLOSE}
                                        style={{ width: 24, height: 24 }}
                                    />
                                </Row>
                            </Row>

                            {memberShip && memberShip.length > 0 ? (
                                <Col>
                                    <Row
                                        style={{
                                            width: 382,
                                            height: 160,
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: 12,
                                        }}
                                    >
                                        <Row
                                            style={{
                                                width: 32,
                                                height: 32,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                if (index === 0) {
                                                    if (firstMemberShipIndex === 1) {
                                                        return;
                                                    } else {
                                                        setFirstMemberShipIndex(firstMemberShipIndex - 1);
                                                    }
                                                } else {
                                                    if (secondMemberShipIndex === 1) {
                                                        return;
                                                    } else {
                                                        setSecondMemberShipIndex(secondMemberShipIndex - 1);
                                                    }
                                                }
                                            }}
                                        >
                                            <img src={I_LEFT_ANGLE} style={{ width: 24, height: 24 }} />
                                        </Row>
                                        <Col
                                            style={{
                                                width: 310,
                                                height: 160,
                                                borderRadius: 8,
                                                backgroundColor: colors.WHITE_50,
                                                boxShadow: '2px 2px 8px 0px #00000029',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <Row
                                                style={{
                                                    width: 310,
                                                    height: 88,
                                                    background: `${
                                                        currentMemberShip?.status === 'active'
                                                            ? `linear-gradient(135deg, ${currentMemberShip?.BGColor[0]} 0%, ${currentMemberShip?.BGColor[1]} 100%)`
                                                            : colors.GREY_200
                                                    }`,
                                                    paddingLeft: 24,

                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Body2Bold
                                                    style={{ color: colors.LAVEL_0 }}
                                                >{`${currentMemberShip?.name}`}</Body2Bold>
                                            </Row>

                                            <Row
                                                style={{
                                                    width: 310,
                                                    height: 72,
                                                    paddingTop: 16,
                                                    paddingLeft: 24,
                                                    paddingRight: 30,
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <Col style={{ width: 64, height: 40, justifyContent: 'space-between' }}>
                                                    <OverlineRegular style={{ color: colors.LAVEL_3 }}>
                                                        {'등록 가능'}
                                                    </OverlineRegular>
                                                    <Body3Bold
                                                        style={{ color: colors.LAVEL_4 }}
                                                    >{`${currentMemberShip?.canMakeScheduleSession}회`}</Body3Bold>
                                                </Col>
                                                <Col style={{ width: 64, height: 40, justifyContent: 'space-between' }}>
                                                    <OverlineRegular style={{ color: colors.LAVEL_3 }}>
                                                        {'유효 기간'}
                                                    </OverlineRegular>
                                                    <Body3Bold
                                                        style={{ color: colors.LAVEL_4 }}
                                                    >{`${currentMemberShip?.remainPeriod}일`}</Body3Bold>
                                                </Col>
                                                <Col style={{ width: 64, height: 40, justifyContent: 'space-between' }}>
                                                    <OverlineRegular style={{ color: colors.LAVEL_3 }}>
                                                        {'수업 시간'}
                                                    </OverlineRegular>
                                                    <Body3Bold
                                                        style={{ color: colors.LAVEL_4 }}
                                                    >{`${currentMemberShip?.period}분`}</Body3Bold>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Row
                                            onClick={() => {
                                                if (index === 0) {
                                                    if (firstMemberShipIndex === memberShipLength) {
                                                        return;
                                                    } else {
                                                        setFirstMemberShipIndex(firstMemberShipIndex + 1);
                                                    }
                                                } else {
                                                    if (secondMemberShipIndex === memberShipLength) {
                                                        return;
                                                    } else {
                                                        setSecondMemberShipIndex(secondMemberShipIndex + 1);
                                                    }
                                                }
                                            }}
                                            style={{
                                                width: 32,
                                                height: 32,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <img src={I_RIGHT_ANGLE} style={{ width: 24, height: 24 }} />
                                        </Row>
                                    </Row>

                                    <CaptionRegular
                                        style={{ color: colors.LAVEL_1, marginLeft: 180, marginTop: 12 }}
                                    >{`${
                                        index === 0 ? firstMemberShipIndex : secondMemberShipIndex
                                    } / ${memberShipLength}`}</CaptionRegular>
                                </Col>
                            ) : (
                                <Col
                                    style={{
                                        width: 350,
                                        height: 140,
                                        border: '1px solid #ff9ba1',
                                        marginTop: 12,
                                        borderRadius: 8,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginLeft: 33,
                                    }}
                                >
                                    <Row style={{ alignItems: 'center', marginBottom: 18 }}>
                                        <img src={I_ERROR} style={{ width: 18, height: 18 }} />
                                        <Body3Bold style={{ color: colors.LAVEL_4, marginLeft: 8 }}>
                                            등록된 수강권이 없는 회원이에요!
                                        </Body3Bold>
                                    </Row>
                                    <ColorRoundButton
                                        text="수강권 추가하기"
                                        size="small"
                                        callBack={() => {
                                            onClickClassPassPage(selectedMember[index]);
                                        }}
                                    />
                                </Col>
                            )}
                        </Col>
                    );
                })}
        </Col>
    );
};

export default OneOnTwoSchedule;
