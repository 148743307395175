import { I_BOOKMARK } from '../../../types/images';

const FeedFixedIcon = ({ item }: any) => {
    return item?.postType === 'FIXED' ? (
        <img
            src={I_BOOKMARK}
            style={{
                width: 32,
                height: 40,
                marginLeft: 722,

                position: 'absolute',
            }}
        />
    ) : (
        <></>
    );
};

export default FeedFixedIcon;
