import { useEffect, useState } from 'react';
import { colors } from '../../../lib/colors';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { I_PRIMARY_PLUS } from '../../../types/images';
import { Body3Regular } from '../../../lib/font';

const HoverComponent = styled.div`
    width: 100%;
    height: 20px;
`;

const HoverModal = styled.div`
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid #ecf1fc;
    background: #fdfeff;
    z-index: 9;
`;

const Container = styled.div`
    position: relative;
    height: 120px;
    border-width: 1px;
    /* border-top-color: ${colors.BLUE_100}; */
    border-left-color: ${colors.WHITE_500};
    border-right-color: ${colors.WHITE_500};
    border-bottom-color: ${colors.BLUE_100};
`;

const EmptyScheduleBox = ({
    rowIndex,
    cellIndex,
    gridData,
    dailyCurrentData,
    sidebarStatus,
    onClickHover,
    selectedDay,
}: any) => {
    const [isFirstHovered, setIsFirstHovered] = useState<boolean>(false);
    const [isSecondHovered, setIsSecondHovered] = useState<boolean>(false);
    const [isThirdHovered, setIsThirdHovered] = useState<boolean>(false);
    const [isFourthHovered, setIsFourthHovered] = useState<boolean>(false);
    const [isFifthHovered, setIsFifthHovered] = useState<boolean>(false);
    const [isSixthHovered, setIsSixthHovered] = useState<boolean>(false);

    const [hour, setHour] = useState<any>('0');
    const [addHour, setAddHour] = useState<any>('0');

    useEffect(() => {
        let convertHour = rowIndex === 0 ? '00' : rowIndex < 10 ? '0' + rowIndex : rowIndex;
        let convertAddHour = rowIndex === 0 ? '1' : rowIndex + 1 < 10 ? '0' + (rowIndex + 1) : rowIndex + 1;
        setHour(convertHour);
        setAddHour(convertAddHour);
    }, []);

    return (
        <Container style={{ width: sidebarStatus === 'open' ? 152 : 188 }}>
            {/* 0분 */}
            <HoverComponent
                onMouseEnter={() => {
                    const hoverStartTime = dayjs(selectedDay).format(`YYYY-MM-DD ${hour}:00`);
                    const hoverEndTime = dayjs(selectedDay).format(`YYYY-MM-DD ${hour}:50`);

                    const schedules = dailyCurrentData[cellIndex - 1]?.schedules;
                    let arr: any = [];
                    if (schedules) {
                        for (let i = 0; i < schedules.length; i++) {
                            const rangeStart = dayjs(schedules[i].startTime).format('YYYY-MM-DD HH:mm');
                            const rangeEnd = dayjs(schedules[i].endTime).format('YYYY-MM-DD HH:mm');

                            if (
                                dayjs(hoverEndTime).isAfter(rangeStart) &&
                                (dayjs(hoverEndTime).isSame(rangeEnd) || dayjs(hoverEndTime).isBefore(rangeEnd))
                            ) {
                                arr.push(true);
                            } else {
                                if (dayjs(rangeEnd).isBefore(hoverEndTime) && dayjs(rangeEnd).isAfter(hoverStartTime)) {
                                    arr.push(true);
                                } else {
                                    arr.push(false);
                                }
                            }
                        }
                        let findIndex = arr.findIndex((item: any) => item === true);
                        if (findIndex === -1) {
                            setIsFirstHovered(true);
                        } else {
                            setIsFirstHovered(false);
                        }
                    }
                }}
                onMouseLeave={() => {
                    setIsFirstHovered(false);
                }}
            >
                {gridData[0][cellIndex].coachName && isFirstHovered && (
                    <HoverModal
                        style={{
                            width: sidebarStatus === 'open' ? 118 : 142,
                            marginLeft: sidebarStatus === 'open' ? 17 : 22,
                        }}
                        onClick={() => {
                            let dateToString = dayjs(selectedDay).format(`YYYY-MM-DD ${rowIndex}:00:00`);
                            onClickHover(
                                dayjs(dateToString),
                                dailyCurrentData[cellIndex - 1].coachName,
                                dailyCurrentData[cellIndex - 1].coachId
                            );
                        }}
                    >
                        <img style={{ width: 24, height: 24 }} src={I_PRIMARY_PLUS} />
                        <Body3Regular style={{ color: colors.BLUE_200 }}>{'일반일정 추가'}</Body3Regular>{' '}
                    </HoverModal>
                )}
            </HoverComponent>
            {/* 10분 */}
            <HoverComponent
                onMouseEnter={() => {
                    const hoverStartTime = dayjs(selectedDay).format(`YYYY-MM-DD ${hour}:10`);
                    const hoverEndTime = dayjs(selectedDay).format(`YYYY-MM-DD ${parseInt(hour) + 1}:00`);

                    const schedules = dailyCurrentData[cellIndex - 1]?.schedules;
                    let arr: any = [];
                    if (schedules) {
                        for (let i = 0; i < schedules.length; i++) {
                            const rangeStart = dayjs(schedules[i].startTime).format('YYYY-MM-DD HH:mm');
                            const rangeEnd = dayjs(schedules[i].endTime).format('YYYY-MM-DD HH:mm');

                            if (
                                dayjs(hoverEndTime).isAfter(rangeStart) &&
                                (dayjs(hoverEndTime).isSame(rangeEnd) || dayjs(hoverEndTime).isBefore(rangeEnd))
                            ) {
                                arr.push(true);
                            } else {
                                if (dayjs(rangeEnd).isBefore(hoverEndTime) && dayjs(rangeEnd).isAfter(hoverStartTime)) {
                                    arr.push(true);
                                } else {
                                    arr.push(false);
                                }
                            }
                        }
                        let findIndex = arr.findIndex((item: any) => item === true);
                        if (findIndex === -1) {
                            setIsSecondHovered(true);
                        } else {
                            setIsSecondHovered(false);
                        }
                    }
                }}
                onMouseLeave={() => {
                    setIsSecondHovered(false);
                }}
            >
                {gridData[0][cellIndex].coachName && isSecondHovered && (
                    <HoverModal
                        style={{
                            width: sidebarStatus === 'open' ? 118 : 142,
                            marginLeft: sidebarStatus === 'open' ? 17 : 22,
                            marginTop: 20,
                        }}
                        onClick={() => {
                            let dateToString = dayjs(selectedDay).format(`YYYY-MM-DD ${rowIndex}:10:00`);
                            onClickHover(
                                dayjs(dateToString),
                                dailyCurrentData[cellIndex - 1].coachName,
                                dailyCurrentData[cellIndex - 1].coachId
                            );
                        }}
                    >
                        <img style={{ width: 24, height: 24 }} src={I_PRIMARY_PLUS} />
                        <Body3Regular style={{ color: colors.BLUE_200 }}>{'일반일정 추가'}</Body3Regular>{' '}
                    </HoverModal>
                )}
            </HoverComponent>
            {/* 20분 */}
            <HoverComponent
                onMouseEnter={() => {
                    const hoverStartTime = dayjs(selectedDay).format(`YYYY-MM-DD ${hour}:20`);
                    const hoverEndTime = dayjs(selectedDay).format(`YYYY-MM-DD ${parseInt(hour) + 1}:10`);

                    const schedules = dailyCurrentData[cellIndex - 1]?.schedules;
                    let arr: any = [];
                    if (schedules) {
                        for (let i = 0; i < schedules.length; i++) {
                            const rangeStart = dayjs(schedules[i].startTime).format('YYYY-MM-DD HH:mm');
                            const rangeEnd = dayjs(schedules[i].endTime).format('YYYY-MM-DD HH:mm');

                            if (
                                dayjs(hoverEndTime).isAfter(rangeStart) &&
                                (dayjs(hoverEndTime).isSame(rangeEnd) || dayjs(hoverEndTime).isBefore(rangeEnd))
                            ) {
                                arr.push(true);
                            } else {
                                if (dayjs(rangeEnd).isBefore(hoverEndTime) && dayjs(rangeEnd).isAfter(hoverStartTime)) {
                                    arr.push(true);
                                } else {
                                    arr.push(false);
                                }
                            }
                        }
                        let findIndex = arr.findIndex((item: any) => item === true);
                        if (findIndex === -1) {
                            setIsThirdHovered(true);
                        } else {
                            setIsThirdHovered(false);
                        }
                    }
                }}
                onMouseLeave={() => {
                    setIsThirdHovered(false);
                }}
            >
                {gridData[0][cellIndex].coachName && isThirdHovered && (
                    <HoverModal
                        style={{
                            width: sidebarStatus === 'open' ? 118 : 142,
                            marginLeft: sidebarStatus === 'open' ? 17 : 22,
                            marginTop: 40,
                        }}
                        onClick={() => {
                            let dateToString = dayjs(selectedDay).format(`YYYY-MM-DD ${rowIndex}:20:00`);
                            onClickHover(
                                dayjs(dateToString),
                                dailyCurrentData[cellIndex - 1].coachName,
                                dailyCurrentData[cellIndex - 1].coachId
                            );
                        }}
                    >
                        <img style={{ width: 24, height: 24 }} src={I_PRIMARY_PLUS} />
                        <Body3Regular style={{ color: colors.BLUE_200 }}>{'일반일정 추가'}</Body3Regular>{' '}
                    </HoverModal>
                )}
            </HoverComponent>
            {/* 30분 */}
            <HoverComponent
                onMouseEnter={() => {
                    const hoverStartTime = dayjs(selectedDay).format(`YYYY-MM-DD ${hour}:30`);
                    const hoverEndTime = dayjs(selectedDay).format(`YYYY-MM-DD ${parseInt(hour) + 1}:20`);

                    const schedules = dailyCurrentData[cellIndex - 1]?.schedules;
                    let arr: any = [];
                    if (schedules) {
                        for (let i = 0; i < schedules.length; i++) {
                            const rangeStart = dayjs(schedules[i].startTime).format('YYYY-MM-DD HH:mm');
                            const rangeEnd = dayjs(schedules[i].endTime).format('YYYY-MM-DD HH:mm');

                            if (
                                dayjs(hoverEndTime).isAfter(rangeStart) &&
                                (dayjs(hoverEndTime).isSame(rangeEnd) || dayjs(hoverEndTime).isBefore(rangeEnd))
                            ) {
                                arr.push(true);
                            } else {
                                if (dayjs(rangeEnd).isBefore(hoverEndTime) && dayjs(rangeEnd).isAfter(hoverStartTime)) {
                                    arr.push(true);
                                } else {
                                    arr.push(false);
                                }
                            }
                        }
                        let findIndex = arr.findIndex((item: any) => item === true);
                        if (findIndex === -1) {
                            setIsFourthHovered(true);
                        } else {
                            setIsFourthHovered(false);
                        }
                    }
                }}
                onMouseLeave={() => {
                    setIsFourthHovered(false);
                }}
            >
                {gridData[0][cellIndex].coachName && isFourthHovered && (
                    <HoverModal
                        style={{
                            width: sidebarStatus === 'open' ? 118 : 142,
                            marginLeft: sidebarStatus === 'open' ? 17 : 22,
                            marginTop: 60,
                        }}
                        onClick={() => {
                            let dateToString = dayjs(selectedDay).format(`YYYY-MM-DD ${rowIndex}:30:00`);
                            onClickHover(
                                dayjs(dateToString),
                                dailyCurrentData[cellIndex - 1].coachName,
                                dailyCurrentData[cellIndex - 1].coachId
                            );
                        }}
                    >
                        <img style={{ width: 24, height: 24 }} src={I_PRIMARY_PLUS} />
                        <Body3Regular style={{ color: colors.BLUE_200 }}>{'일반일정 추가'}</Body3Regular>{' '}
                    </HoverModal>
                )}
            </HoverComponent>
            {/* 40분 */}
            <HoverComponent
                onMouseEnter={() => {
                    const hoverStartTime = dayjs(selectedDay).format(`YYYY-MM-DD ${hour}:40`);
                    const hoverEndTime = dayjs(selectedDay).format(`YYYY-MM-DD ${parseInt(hour) + 1}:30`);

                    const schedules = dailyCurrentData[cellIndex - 1]?.schedules;
                    let arr: any = [];
                    if (schedules) {
                        for (let i = 0; i < schedules.length; i++) {
                            const rangeStart = dayjs(schedules[i].startTime).format('YYYY-MM-DD HH:mm');
                            const rangeEnd = dayjs(schedules[i].endTime).format('YYYY-MM-DD HH:mm');

                            if (
                                dayjs(hoverEndTime).isAfter(rangeStart) &&
                                (dayjs(hoverEndTime).isSame(rangeEnd) || dayjs(hoverEndTime).isBefore(rangeEnd))
                            ) {
                                arr.push(true);
                            } else {
                                if (dayjs(rangeEnd).isBefore(hoverEndTime) && dayjs(rangeEnd).isAfter(hoverStartTime)) {
                                    arr.push(true);
                                } else {
                                    arr.push(false);
                                }
                            }
                        }
                        let findIndex = arr.findIndex((item: any) => item === true);
                        if (findIndex === -1) {
                            setIsFifthHovered(true);
                        } else {
                            setIsFifthHovered(false);
                        }
                    }
                }}
                onMouseLeave={() => {
                    setIsFifthHovered(false);
                }}
            >
                {gridData[0][cellIndex].coachName && isFifthHovered && (
                    <HoverModal
                        style={{
                            width: sidebarStatus === 'open' ? 118 : 142,
                            marginLeft: sidebarStatus === 'open' ? 17 : 22,
                            marginTop: 80,
                        }}
                        onClick={() => {
                            let dateToString = dayjs(selectedDay).format(`YYYY-MM-DD ${rowIndex}:40:00`);
                            onClickHover(
                                dayjs(dateToString),
                                dailyCurrentData[cellIndex - 1].coachName,
                                dailyCurrentData[cellIndex - 1].coachId
                            );
                        }}
                    >
                        <img style={{ width: 24, height: 24 }} src={I_PRIMARY_PLUS} />
                        <Body3Regular style={{ color: colors.BLUE_200 }}>{'일반일정 추가'}</Body3Regular>{' '}
                    </HoverModal>
                )}
            </HoverComponent>
            {/* 50분 */}
            <HoverComponent
                onMouseEnter={() => {
                    const hoverStartTime = dayjs(selectedDay).format(`YYYY-MM-DD ${hour}:50`);
                    const hoverEndTime = dayjs(selectedDay).format(`YYYY-MM-DD ${parseInt(hour) + 1}:40`); // 내가 마우스 올린 끝시간

                    const schedules = dailyCurrentData[cellIndex - 1]?.schedules;

                    let arr: any = [];
                    if (schedules) {
                        for (let i = 0; i < schedules.length; i++) {
                            const rangeStart = dayjs(schedules[i].startTime).format('YYYY-MM-DD HH:mm');
                            const rangeEnd = dayjs(schedules[i].endTime).format('YYYY-MM-DD HH:mm');

                            if (
                                dayjs(hoverEndTime).isAfter(rangeStart) &&
                                (dayjs(hoverEndTime).isSame(rangeEnd) || dayjs(hoverEndTime).isBefore(rangeEnd))
                            ) {
                                arr.push(true);
                            } else {
                                if (dayjs(rangeEnd).isBefore(hoverEndTime) && dayjs(rangeEnd).isAfter(hoverStartTime)) {
                                    arr.push(true);
                                } else {
                                    arr.push(false);
                                }
                            }
                        }
                        let findIndex = arr.findIndex((item: any) => item === true);
                        if (findIndex === -1) {
                            setIsSixthHovered(true);
                        } else {
                            setIsSixthHovered(false);
                        }
                    }
                }}
                onMouseLeave={() => {
                    setIsSixthHovered(false);
                }}
            >
                {gridData[0][cellIndex].coachName && isSixthHovered && (
                    <HoverModal
                        style={{
                            width: sidebarStatus === 'open' ? 118 : 142,
                            marginLeft: sidebarStatus === 'open' ? 17 : 22,
                            marginTop: 100,
                        }}
                        onClick={() => {
                            let dateToString = dayjs(selectedDay).format(`YYYY-MM-DD ${rowIndex}:50:00`);
                            onClickHover(
                                dayjs(dateToString),
                                dailyCurrentData[cellIndex - 1].coachName,
                                dailyCurrentData[cellIndex - 1].coachId
                            );
                        }}
                    >
                        <img style={{ width: 24, height: 24 }} src={I_PRIMARY_PLUS} />
                        <Body3Regular style={{ color: colors.BLUE_200 }}>{'일반일정 추가'}</Body3Regular>
                    </HoverModal>
                )}
            </HoverComponent>
        </Container>
    );
};

export default EmptyScheduleBox;
