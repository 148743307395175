import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { Col, Row } from '../../lib/utils';
import { I_POPUP_EXIT } from '../../types/images';
import WhiteSquareButton from '../Button/WhiteSquareButton';
import ColorSquareButton from '../Button/ColorSquareButton';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
`;

const ModalInner = styled.div`
    background: #f5f9ff;
    width: 1080px;
    height: 700px;
    border-radius: 8px;
    box-shadow: 8px 8px 16px 0px #0000005c;
    background-color: #fdfeff;
    display: flex;
    flex-direction: column;
`;

const HoverImage = styled.img`
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

type Props = {
    modalVisible: boolean;
    setModalVisible: Dispatch<SetStateAction<boolean>>;
};

const ModalTerm = ({ modalVisible, setModalVisible }: Props) => {
    return (
        <ModalOverLay>
            <ModalInner>
                <Row style={{ width: '100%', justifyContent: 'flex-end', marginTop: 16, paddingRight: 16 }}>
                    <HoverImage
                        src={I_POPUP_EXIT}
                        style={{ width: 32, height: 32 }}
                        onClick={() => setModalVisible(false)}
                    />
                </Row>

                <Col
                    style={{
                        width: 1080,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <iframe
                        title="MyIframe"
                        src={'https://s3.ap-northeast-2.amazonaws.com/terms.rappomanager.com/index.html'}
                        style={{
                            width: 975,
                            // height: 456 + 76,
                            height: 700 - 16 - 32,
                        }}
                    />
                </Col>

                {/* <Row style={{ width: '100%', justifyContent: 'flex-end', paddingRight: 36, paddingTop: 30 }}>
                    <Row style={{ width: 312, justifyContent: 'space-between' }}>
                        <WhiteSquareButton
                            text="BUTTON"
                            size="middle"
                            isVisibleLeftImg={false}
                            isVisibleRightImg={false}
                            callBack={() => setModalVisible(false)}
                        />
                        <ColorSquareButton
                            text="BUTTON"
                            size="middle"
                            isVisibleLeftImg={false}
                            isVisibleRightImg={false}
                            callBack={() => setModalVisible(false)}
                        />
                    </Row>
                </Row> */}
            </ModalInner>
        </ModalOverLay>
    );
};

export default ModalTerm;
