import React from 'react';
import { Col } from '../../../lib/utils';
import { I_EMPTY_V2 } from '../../../types/images';
import { Body3Light, Head4 } from '../../../lib/font';
import { colors } from '../../../lib/colors';

const ScheduleHistoryMemberCancleEmptyLIst = ({ type }: any) => {
    return (
        <Col
            style={{
                width: 360,
                height: 88,
                alignItems: 'center',
                justifyContent: 'space-between',
                alignSelf: 'center',
                marginTop: 72,
            }}
        >
            <img src={I_EMPTY_V2} style={{ width: 24, height: 24 }} />

            <Body3Light style={{ color: colors.LAVEL_2 }}>회원님의 취소 기록이 없어요!</Body3Light>
            <Body3Light style={{ color: colors.LAVEL_2 }}>
                날짜를 변경하시거나 이번 주 / 이번 달을 확인해주세요!
            </Body3Light>
        </Col>
    );
};

export default ScheduleHistoryMemberCancleEmptyLIst;
