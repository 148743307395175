import React, { useEffect, useState } from 'react';
import { Col, concatDateAndTimeDayjs, isSameDate, Row } from '../../../lib/utils';
import { Body3Regular, Subtitle1Bold } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import WhiteSquareButton from '../../Button/WhiteSquareButton';
import ColorSquareButton from '../../Button/ColorSquareButton';
import dayjs, { Dayjs } from 'dayjs';
import Calendar from '../../Calendar/Calendar';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
    modifyOneOnOneScheduleAPI,
    getMemberListForCenterAPI,
    modifyOneOnOneScheduleMembershipAPI,
} from '../../../api/schedule';
import UpdateOneScheduleLeft from './UpdateOneScheduleLeft';
import UpdateOneScheduleRight from './UpdateOneScheduleRight';
import _, { replace } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

type RefineDate = {
    refineStartTime: string;
    refineEndTime: string;
};

const UpdateOneSchedule = ({
    coachOption,
    authorization,
    authObject,
    createAlert,
    scheduleObject,
    calendarType,
    calendarSelectedDay,
    calendarScrollY,
}: any) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const loadedStartTime = scheduleObject ? dayjs(scheduleObject?.startTime) : dayjs();
    const roundedStartTime = loadedStartTime.minute(Math.floor(loadedStartTime.minute() / 10) * 10).second(0);
    const loadedEndTime = scheduleObject ? dayjs(scheduleObject?.endTime) : dayjs();
    const roundedEndTime = loadedEndTime.minute(Math.floor(loadedEndTime.minute() / 10) * 10).second(0);
    /**
     * coach : 담당강사
     * startTime : 시작 시간
     * endTime : 종료시간
     * isOneOnOneClass : true - 1:1수업 , false - 2:1수업
     * isDisabledInput : 1:1수업 또는 1:2 수업 클릭시 멤버가 다선택되면 input 비활성화
     */
    const [coach, setCoach] = useState({
        value: scheduleObject ? scheduleObject?.coachId : '',
        label: scheduleObject ? scheduleObject?.coachName : '강사를 선택해주세요!',
    });
    const [startTime, setStartTime] = useState({
        value: roundedStartTime,
        label: dayjs(roundedStartTime).format('A hh:mm'),
    });
    const [endTime, setEndTime] = useState({
        value: roundedEndTime,
        label: dayjs(roundedEndTime).format('A hh:mm'),
    });
    const [isOneOnOneClass, setIsOneOnOneClass] = useState<boolean>(true);
    const [isDisabledInput, setIsDisabledInput] = useState<boolean>(false);

    /**
     * searchText: 회원 검색
     * memberList: 강사의 연결된 모든 회원의 리스트
     * filteredMemberList : 검색결과가 반영된 회원 리스트
     * selectedMember: 선택된 회원
     */
    const [searchText, setSearchText] = useState<string>('');
    const [memberList, setMemberList] = useState<any>([]);
    const [filteredMemberList, setFilteredMemberList] = useState<any>([]);
    const [selectedMember, setSelectedMember] = useState<any>([]);

    const [loadedOneOnOneMemberShipIndex, setLoadedOneOnOneMemberShipIndex] = useState<number>(1);
    const [oneOnOneMemberShipIndex, setOneOnOneMemberShipIndex] = useState<number>(1);

    const [firstMemberShipIndex, setFirstMemberShipIndex] = useState<number>(1);
    const [secondMemberShipIndex, setSecondMemberShipIndex] = useState<number>(1);

    /** 캘린더 state */
    const [selectedDay, setSelectedDay] = useState<any>(
        scheduleObject ? new Date(scheduleObject?.startTime) : new Date()
    );
    const [isCalendar, setIsCalendar] = useState<boolean>(false);

    const [timeList, setTimeList] = useState<any>(null); // 10분단위 시간 배열

    // 회원 검색 이벤트
    const onChangeText = (e: any) => {
        setSearchText(e.target.value);
    };

    // 담당 강사 선택
    const handleCoachSelect = (option: any) => {
        setCoach(option);
    };

    // 시작 시간 선택
    const handleStartTimeSelect = (option: any) => {
        setStartTime(option);
    };

    // 종료 시간 선택
    const handleEndTimeSelect = (option: any) => {
        setEndTime(option);
    };

    // 멤버 선택
    const handleMemberSelect = (option: any) => {
        setSearchText('');
        let cloneSelectedMember = _.cloneDeep(selectedMember);
        cloneSelectedMember.push(option);
        setSelectedMember(cloneSelectedMember);
    };

    // 선택된 회원 지우는 함수
    const onClickDeleteSelectedMember = (value: string) => {
        const filterSelectedMemberList = selectedMember.filter((e: any) => e.value !== value);
        setSelectedMember(filterSelectedMemberList);

        setOneOnOneMemberShipIndex(1);
        setFirstMemberShipIndex(1);
        setSecondMemberShipIndex(1);
    };

    // 수강권 추가하기 버튼 클릭이벤트
    const onClickClassPassPage = () => {
        navigate('/classPass');
    };

    //  회원 리스트 조회 API : (GET)
    const getMemberListForCenter = useQuery(
        ['getMemberListForCenterAPI', coach?.value],
        async () => await getMemberListForCenterAPI(authObject?.centerId, coach?.value, authorization),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const response = res?.data?.memberDataList;
                    let memberListToRes = [];

                    if (response.length > 0) {
                        for (let i = 0; i < response.length; i++) {
                            memberListToRes.push({
                                value: response[i]?.memberId,
                                label: response[i]?.alias,
                                status: response[i]?.membershipStatus,

                                memberships: response[i]?.memberships ? response[i]?.memberships : [],
                                profileColorType: response[i]?.profileColorType,
                                profileImageUrl: response[i]?.profileImageUrl,
                                profileName: response[i]?.profileName,
                            });
                        }
                        setMemberList(memberListToRes);
                        setFilteredMemberList(memberListToRes);
                    } else {
                        setMemberList([]);
                    }
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!coach?.value,
            // refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    // 1:1수업 수정 API : (PACTH)
    const updateOneOnOneSchedule = useMutation({
        mutationFn: async (refineDate: RefineDate) =>
            await modifyOneOnOneScheduleAPI(
                authObject?.centerId,
                scheduleObject._id,
                coach?.value,
                refineDate?.refineStartTime,
                refineDate?.refineEndTime,
                authorization
            ),
        onSuccess: (res, refineDate) => {
            if (res.status === 200) {
                console.log('ewfewefw');

                queryClient.invalidateQueries({ queryKey: ['getDailyScheduleAPI'] });
                queryClient.invalidateQueries({ queryKey: ['getWeeklyScheduleAPI'] });
                navigate('/schedule', {
                    state: { calendarType, calendarTimeInfo: refineDate.refineStartTime },
                    replace: true,
                });
            } else if (res.status === 410 || res.status === 420 || res.status === 430) {
                createAlert(
                    '중복',
                    `선택된 담당 강사 ${coach?.label}님이\n해당시간에 중복된 스케줄이 존재합니다`,
                    true
                );
            } else {
                createAlert('오류', `서버 내부 오류\n 지속적인 오류발생시 고객센터로 문의바랍니다`, true);
            }
        },
    });

    // 1:1수업 수강권 수정 API : (PACTH)
    const updateOneOnOneScheduleMembership = useMutation({
        mutationFn: async (membershipId: string) =>
            await modifyOneOnOneScheduleMembershipAPI(
                authObject?.centerId,
                scheduleObject._id,
                coach?.value,
                membershipId,
                authorization
            ),
        onSuccess: (res, refineDate) => {
            if (res.status === 200) {
                queryClient.invalidateQueries({ queryKey: ['getDailyScheduleAPI'] });
                queryClient.invalidateQueries({ queryKey: ['getWeeklyScheduleAPI'] });
                navigate('/schedule', {
                    state: { calendarType, calendarTimeInfo: new Date() },
                    replace: true,
                });
            } else if (res.status === 410 || res.status === 420 || res.status === 430) {
                createAlert(
                    '중복',
                    `선택된 담당 강사 ${coach?.label}님이\n해당시간에 중복된 스케줄이 존재합니다`,
                    true
                );
            } else {
                createAlert('오류', `서버 내부 오류\n 지속적인 오류발생시 고객센터로 문의바랍니다`, true);
            }
        },
    });

    // 수업일정 수정 버튼 클릭이벤트
    const onClickUpdateSchedule = () => {
        if (coach?.value === '') {
            createAlert('필수 입력', '담당 강사를 선택해주세요', true);
            return;
        }

        if (selectedMember.length === 0) {
            createAlert('필수 입력', '회원을 선택해주세요', true);
            return;
        }

        let findNullMembership = selectedMember.findIndex((item: any) => item?.memberships.length === 0);

        if (findNullMembership !== -1) {
            createAlert(
                '필수 입력',
                '수강권이 없는 회원이 존재합니다\n반드시 수강권을 만들고 일정을 만들어주세요',
                true
            );
        }

        // 2:1 수업일경우
        if (!isOneOnOneClass) {
            if (selectedMember.length !== 2) {
                createAlert('필수 입력', '1:2 수업일 경우 회원을 2명 선택해주세요', true);
            }
        }

        const concatStartTime = dayjs(concatDateAndTimeDayjs(selectedDay, startTime.value));
        const concatEndTime = dayjs(concatDateAndTimeDayjs(selectedDay, endTime.value));
        const refineStartTime = dayjs(concatStartTime).toISOString();
        const refineEndTime =
            dayjs(concatEndTime).format('HH:mm') === '00:00'
                ? dayjs(concatEndTime).add(1, 'day').toISOString()
                : dayjs(concatEndTime).toISOString();
        if (isSameDate(dayjs(refineStartTime), dayjs(refineEndTime))) {
            createAlert('입력 오류', '시작 시간과 완료 시간이 같습니다!\n다시 한번 확인해주세요!', '확인', () => {});
            return;
        }

        if (dayjs(refineStartTime).isAfter(dayjs(refineEndTime))) {
            createAlert(
                '입력 오류',
                '시작 시간이 완료 시간보다 늦을 수  없습니다!\n다시 한번 확인해주세요!',
                '확인',
                () => {}
            );
            return;
        }

        const refineDate: RefineDate = {
            refineStartTime: refineStartTime,
            refineEndTime: refineEndTime,
        };

        const _startTime = dayjs(roundedStartTime).toISOString();
        const _endTime = dayjs(roundedEndTime).toISOString();
        if (_startTime != refineStartTime || _endTime != refineEndTime) {
            updateOneOnOneSchedule.mutate(refineDate);
        }
        if (oneOnOneMemberShipIndex != loadedOneOnOneMemberShipIndex) {
            const loadedMember = memberList.find((member: any) => member.value === scheduleObject?.memberId);
            const membershipId = loadedMember?.memberships[oneOnOneMemberShipIndex - 1].id;
            updateOneOnOneScheduleMembership.mutate(membershipId);
        }
    };
    // 시간추가
    const onClickAddTime = (addNumber: number) => {
        if (
            dayjs(startTime.value).format('YYYY-MM-DD') ===
            dayjs(endTime.value).add(addNumber, 'minute').format('YYYY-MM-DD')
        ) {
            setEndTime({
                value: dayjs(endTime.value).add(addNumber, 'minute'),
                label: dayjs(dayjs(endTime.value).add(addNumber, 'minute')).format('A hh:mm'),
            });
        } else {
            createAlert('입력 오류', '추가 가능한 시간을 초과하였습니다', '확인', () => {});
        }
    };

    // 시간빼기
    const onClickSubTime = (subNumber: number) => {
        if (dayjs(startTime.value).isBefore(dayjs(endTime.value).subtract(subNumber, 'minute'))) {
            setEndTime({
                value: dayjs(endTime.value).subtract(subNumber, 'minute'),
                label: dayjs(dayjs(endTime.value).subtract(subNumber, 'minute')).format('A hh:mm'),
            });
        } else {
            createAlert('입력 오류', '감소 가능한 시간을 초과하였습니다', '확인', () => {});
        }
    };

    // 시작 시간이 변할 때 또는 수강권이 변할 때(회원 선택, 회원의 수강권 변경) endTime 정재
    useEffect(() => {
        if (startTime) {
            // 기본 수업 시간을 50분으로 설정
            let duration = 50;

            // 1대1 수업 또는 1대2 수업에 따른 Index 설정
            const membershipIndex = oneOnOneMemberShipIndex - 1;

            if (
                !!selectedMember &&
                selectedMember[0]?.memberships.length > 0 &&
                membershipIndex < selectedMember[0]?.memberships.length
            ) {
                // 회원권에 있는 수업 시간으로 수업시간을 설정
                duration = selectedMember[0]?.memberships[oneOnOneMemberShipIndex - 1]?.period;
            }

            const endTime: Dayjs = dayjs(startTime.value).add(duration, 'minute');

            if (
                endTime.isAfter(dayjs(startTime.value).endOf('day')) ||
                isSameDate(endTime, dayjs(startTime.value).endOf('day'))
            ) {
                setEndTime({
                    value: dayjs().endOf('day').add(1, 'millisecond'),
                    label: dayjs().endOf('day').add(1, 'millisecond').format('A hh:mm'),
                });
            } else {
                setEndTime({ value: dayjs(endTime), label: dayjs(endTime).format('A hh:mm') });
            }
        }
    }, [startTime, selectedMember, oneOnOneMemberShipIndex, firstMemberShipIndex]);

    // 회원이름 검색 정제
    useEffect(() => {
        if (memberList) {
            if (searchText !== '') {
                let filterMemberList = memberList.filter((e: any) => {
                    return (
                        e.label.includes(searchText) &&
                        !selectedMember.some((selected: any) => selected.value === e.value)
                    );
                });

                setFilteredMemberList(filterMemberList);
            } else {
                let filterMemberList = memberList.filter((e: any) => {
                    return !selectedMember.some((selected: any) => selected.value === e.value);
                });

                setFilteredMemberList(filterMemberList);
            }
        }
    }, [searchText, selectedMember]);

    // 시간 10분단위로 생성
    useEffect(() => {
        // 시간생성
        const generateTimeSlots = () => {
            const timeSlots = [];
            const start = dayjs().startOf('day').hour(0).minute(0);
            for (let i = 0; i < 144; i++) {
                // 24 hours * 6 (10분 간격)
                const time = start.add(i * 10, 'minute');
                const formattedTime = time.format('A hh:mm'); // 오전/오후 표시
                timeSlots.push({ value: time, label: formattedTime });
            }
            return timeSlots;
        };

        const timeSlots = generateTimeSlots();
        setTimeList(timeSlots);
    }, []);

    //  1:1수업 2:1 수업일시 input disable 로직
    useEffect(() => {
        if (isOneOnOneClass) {
            selectedMember && selectedMember.length === 1 ? setIsDisabledInput(true) : setIsDisabledInput(false);
        }
    }, [selectedMember]);

    // 1:1수업 , 2:1 수업 버튼 클릭시 상태 초기화
    useEffect(() => {
        setSelectedMember([]);
        setSearchText('');
    }, [isOneOnOneClass]);

    // 회원 리스트에서 회원 선택
    useEffect(() => {
        if (memberList) {
            const loadedMember = memberList.find((member: any) => member.value === scheduleObject?.memberId);
            const membershipIndex =
                loadedMember?.memberships.findIndex(
                    (membership: any) => membership.id === scheduleObject.membershipId
                ) + 1;
            setSelectedMember([loadedMember]);
            setOneOnOneMemberShipIndex(membershipIndex);
            setLoadedOneOnOneMemberShipIndex(membershipIndex);
        }
    }, [memberList]);

    return (
        <Col style={{ paddingLeft: 101, paddingTop: 56, paddingRight: 101, paddingBottom: 64 }}>
            <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <Row style={{ alignItems: 'flex-end' }}>
                    <Subtitle1Bold style={{ color: colors.LAVEL_4 }}>{'수업일정 수정하기'}</Subtitle1Bold>
                    <Body3Regular style={{ color: colors.LAVEL_1, marginLeft: 16, marginBottom: 3 }}>
                        {'수업 시간과 수강권 정보를 수정할 수 있어요!'}
                    </Body3Regular>
                </Row>

                <Row style={{ alignItems: 'center', width: 252, justifyContent: 'space-between' }}>
                    <WhiteSquareButton
                        customStyle={{ width: 100, height: 36 }}
                        text="돌아가기"
                        size="small"
                        callBack={() => {
                            navigate('/schedule', {
                                state: { calendarType, calendarSelectedDay, calendarScrollY },
                                replace: true,
                            });
                        }}
                    />
                    <ColorSquareButton
                        isGradient={true}
                        customStyle={{ width: 136, height: 36 }}
                        text="수업일정 수정"
                        size="small"
                        callBack={onClickUpdateSchedule}
                    />
                </Row>
            </Row>

            <Row style={{ marginTop: 56 }}>
                <UpdateOneScheduleLeft
                    coachOption={coachOption}
                    coach={coach}
                    handleCoachSelect={handleCoachSelect}
                    selectedDay={selectedDay}
                    setIsCalendar={setIsCalendar}
                    timeList={timeList}
                    startTime={startTime}
                    handleStartTimeSelect={handleStartTimeSelect}
                    endTime={endTime}
                    handleEndTimeSelect={handleEndTimeSelect}
                    onClickAddTime={onClickAddTime}
                    onClickSubTime={onClickSubTime}
                />
                <UpdateOneScheduleRight
                    setIsOneOnOneClass={setIsOneOnOneClass}
                    isOneOnOneClass={isOneOnOneClass}
                    selectedMember={selectedMember}
                    filteredMemberList={filteredMemberList}
                    handleMemberSelect={handleMemberSelect}
                    searchText={searchText}
                    onChangeText={onChangeText}
                    coach={coach}
                    isDisabledInput={isDisabledInput}
                    onClickDeleteSelectedMember={onClickDeleteSelectedMember}
                    onClickClassPassPage={onClickClassPassPage}
                    oneOnOneMemberShipIndex={oneOnOneMemberShipIndex}
                    setOneOnOneMemberShipIndex={setOneOnOneMemberShipIndex}
                    firstMemberShipIndex={firstMemberShipIndex}
                    setFirstMemberShipIndex={setFirstMemberShipIndex}
                    secondMemberShipIndex={secondMemberShipIndex}
                    setSecondMemberShipIndex={setSecondMemberShipIndex}
                />
            </Row>

            {isCalendar && (
                <Calendar
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    setIsCalendar={setIsCalendar}
                    isPrevMonth={true}
                    isNextMonth={true}
                    style={{
                        width: 420,
                        height: 370,
                        borderRadius: 24,
                        backgroundColor: colors.WHITE_50,
                        boxShadow: '1px 1px 8px 0px #2B529D2E',
                        position: 'absolute',
                        zIndex: 1999,
                        marginTop: 340,
                    }}
                />
            )}
        </Col>
    );
};

export default UpdateOneSchedule;
