import React from 'react';
import { Col, Row } from '../../lib/utils';
import { Body1Bold, Body1Regular, Head4, Subtitle1Bold } from '../../lib/font';
import { colors } from '../../lib/colors';
import dayjs from 'dayjs';
import { I_GREY_CIRCLE } from '../../types/images';

const ModalClassHistoryThisMonth = ({ thisMonthList, searchText, thisMonthFilterList }: any) => {
    return (
        <Col style={{ paddingBottom: 20 }}>
            {thisMonthFilterList && thisMonthFilterList.length === 0 ? (
                <Col
                    style={{
                        width: 420,
                        height: 172,
                        alignItems: 'center',
                        marginTop: 180,
                        marginLeft: 490,
                    }}
                >
                    {/* <img src={I_GREY_CIRCLE} style={{ width: 88, height: 88 }} /> */}
                    <Head4 style={{ color: colors.WHITE_700, marginTop: 48 }}>
                        아직 수업 히스토리가 등록되지 않았습니다
                    </Head4>
                </Col>
            ) : (
                thisMonthList &&
                thisMonthList.map((item: any, index: number) => {
                    let keys = Object.keys(item);

                    let dayObject = item[keys[0]];
                    let dayTime = dayObject[0].startTime;

                    let findFilterList: any = [];

                    if (searchText === '') {
                        findFilterList = dayObject;
                    } else {
                        findFilterList = dayObject.filter((e: any) => e.alias.includes(searchText));
                    }

                    return findFilterList && findFilterList.length === 0 ? (
                        <div key={index}></div>
                    ) : (
                        <Col
                            key={index}
                            style={{ marginLeft: 60, marginBottom: thisMonthList.length - 1 === index ? 20 : 0 }}
                        >
                            <Row style={{ marginTop: 42, marginBottom: 24 }}>
                                <Body1Bold style={{ color: colors.WHITE_900 }}>
                                    {dayjs(dayTime).format('MM월 DD일')}
                                </Body1Bold>

                                <Body1Bold style={{ color: colors.WHITE_900, marginLeft: 72 }}>
                                    {dayjs(dayTime).format('ddd요일')}
                                </Body1Bold>

                                <Body1Bold style={{ color: colors.WHITE_900, marginLeft: 73 }}>
                                    {findFilterList.length}회
                                </Body1Bold>
                            </Row>
                            <Col
                                style={{
                                    width: 1280,
                                    height: findFilterList.length * 84,
                                    borderRadius: 24,
                                    backgroundColor: colors.WHITE_300,
                                    boxShadow: '4px 4px 8px 1px #213E7B29 inset',
                                }}
                            >
                                {findFilterList.map((childItem: any, childIndex: number) => {
                                    return (
                                        <Col key={childIndex}>
                                            <Row
                                                style={{
                                                    height: 82,
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Subtitle1Bold
                                                    style={{
                                                        marginLeft: 60,
                                                        width: 140,
                                                        height: 28,
                                                        color: colors.BLUE_900,
                                                    }}
                                                >
                                                    {childItem.alias} 회원님
                                                </Subtitle1Bold>
                                                <Body1Regular
                                                    style={{
                                                        marginLeft: 90,
                                                        width: 160,
                                                        height: 24,
                                                        color: colors.WHITE_900,
                                                    }}
                                                >
                                                    {dayjs(childItem.startTime).format('A HH:mm ~ ')}
                                                    {dayjs(childItem.endTime).format('HH:mm')}
                                                </Body1Regular>
                                                <Body1Regular
                                                    style={{
                                                        marginLeft: 90,
                                                        width: 100,
                                                        height: 24,
                                                        color: colors.WHITE_900,
                                                    }}
                                                >
                                                    회차 : {childItem.currentSession ? childItem.currentSession : '-'}/
                                                    {childItem.totalSession ? childItem.totalSession : '-'}
                                                </Body1Regular>
                                                <Body1Regular
                                                    style={{
                                                        marginLeft: 90,
                                                        width: 150,
                                                        height: 24,
                                                        color: colors.WHITE_900,
                                                    }}
                                                >
                                                    회원권 등록 :{' '}
                                                    {childItem.membershipCount ? childItem.membershipCount : '-'}
                                                    회차
                                                </Body1Regular>
                                            </Row>
                                            {findFilterList.length - 1 !== childIndex && (
                                                <hr
                                                    style={{
                                                        marginLeft: 60,
                                                        width: 1160,
                                                        height: 2,
                                                        backgroundColor: colors.WHITE_600,
                                                    }}
                                                />
                                            )}
                                        </Col>
                                    );
                                })}
                            </Col>
                        </Col>
                    );
                })
            )}
        </Col>
    );
};

export default ModalClassHistoryThisMonth;
