import React from 'react';
import { Col, Row } from '../../../lib/utils';
import { Body3Regular, CaptionLight } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import WhiteRoundButton from '../../Button/WhiteRoundButton';
import InputSquareDropDown from '../../DropDown/InputSquareDropDown';

const CreateEtcScheduleLeft = ({ coachOption, coach, handleCoachSelect, title, setTitle, handleChangeTitle }: any) => {
    return (
        <Col style={{ width: 382 }}>
            <Body3Regular style={{ color: colors.LAVEL_4 }}>{'일정 제목'}</Body3Regular>

            <input
                placeholder="일정 제목"
                value={title}
                onChange={handleChangeTitle}
                style={{
                    width: 382,
                    height: 48,
                    borderBottom: '1px solid #a6bfef',
                    marginTop: 16,
                    fontSize: 18,
                    paddingLeft: 16,
                }}
            />

            <CaptionLight style={{ color: colors.LAVEL_2, marginLeft: 8, marginTop: 16 }}>
                *라포가 추천하는 일반일정 키워드
            </CaptionLight>

            <Row style={{ width: 382, marginTop: 16, alignItems: 'center', justifyContent: 'space-between' }}>
                <WhiteRoundButton
                    customStyle={{ width: 56, height: 24 }}
                    text="OT"
                    size="tiny"
                    callBack={() => {
                        setTitle('OT');
                    }}
                />
                <WhiteRoundButton
                    customStyle={{ width: 56, height: 24 }}
                    text="상담"
                    size="tiny"
                    callBack={() => {
                        setTitle('상담');
                    }}
                />
                <WhiteRoundButton
                    customStyle={{ width: 72, height: 24 }}
                    text="개인 운동"
                    size="tiny"
                    callBack={() => {
                        setTitle('개인 운동');
                    }}
                />
                <WhiteRoundButton
                    customStyle={{ width: 56, height: 24 }}
                    text="청소"
                    size="tiny"
                    callBack={() => {
                        setTitle('청소');
                    }}
                />
                <WhiteRoundButton
                    customStyle={{ width: 56, height: 24 }}
                    text="식사"
                    size="tiny"
                    callBack={() => {
                        setTitle('식사');
                    }}
                />
            </Row>

            <Col style={{ width: 382, height: 88, marginTop: 64, justifyContent: 'space-between' }}>
                <Body3Regular style={{ color: colors.LAVEL_4 }}> {'담당 강사'}</Body3Regular>

                {coachOption && (
                    <InputSquareDropDown
                        selectedGender={coach}
                        genderOptions={coachOption}
                        handleGenderSelect={handleCoachSelect}
                        customStyle={{ width: 382 }}
                        zIndex={100}
                    />
                )}
            </Col>
        </Col>
    );
};

export default CreateEtcScheduleLeft;
