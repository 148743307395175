import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from '../../lib/utils';
import Header from '../../components/Header';
import { Bottom } from '../global';
import FeedHeader from '../../components/Feed/FeedHeader';
import FeedList from '../../components/Feed/FeedList/FeedList';
import FeedSelectList from '../../components/Feed/FeedSelectList/FeedSelectList';
import RangeCalendar from '../../components/Calendar/RangeCalendar';
import ModalFeedDetail from '../../components/Feed/ModalFeedDetail/ModalFeedDetail';
import { useQuery } from '@tanstack/react-query';
import { getAllCoachMemberListAPI, getAllFeedListAPI } from '../../api/feed';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import ModalFeedCondition from '../../components/Feed/ModalFeedCondition/ModalFeedCondition';
import ModalImagesViewer from '../../components/modal/ModalImagesViewer';
import ModalVideoPlayer from '../../components/modal/ModalVideoPlayer';
import { colors } from '../../lib/colors';

export interface SortType {
    value: string;
    label: string;
}
export type Comment = {
    commenterName: string;
    content: string;
    createdTime: string;
    profileColorType: string;
    profileImageUrl: string;
    profileName: string;
    reactors: any[]; // 반응자 정보에 대한 타입이 제공되지 않았으므로 any[]로 처리했습니다.
    role: string;
    updatedTime: string;
    writerId: string;
    _id: string;
};
export type Content = {
    content: string;
    contentType: string;
    _id: string;
};
export type Reaction = {
    createdTime: string;
    reaction: string;
    reactorId: string;
    reactorName: string;
    role: string;
    updatedTime: string;
    _id: string;
};
export type Receiver = {
    role: string;
    receiverId: string;
    receiverName: string;
};
export type Feed = {
    comments: Comment[]; // 댓글 정보에 대한 타입이 제공되지 않았으므로 any[]로 처리했습니다.
    contents: Content[]; // 콘텐츠 정보에 대한 타입이 제공되지 않았으므로 any[]로 처리했습니다.
    createdAt: string;
    createdTime: string;
    isCanceled: boolean;
    isDeleted: boolean;
    postType: string;
    profileColorType: string;
    profileImageUrl: string;
    profileName: string;
    reactions: Reaction[]; // 반응 정보에 대한 타입이 제공되지 않았으므로 any[]로 처리했습니다.
    receivers: Receiver[];
    role: string;
    updatedAt: string;
    updatedTime: string;
    writerId: string;
    writerName: string;
    scheduleReport?: any;
    initialPost?: any;
    _id: string;
};
export type FeedListType = Feed[];

let sortOptions: any = [
    { value: 'all', label: '전체보기' },
    { value: 'SCHEDULEREPORT', label: '수업 일지' },
    { value: 'coach', label: '강사 게시글' },
    { value: 'member', label: '회원 게시글' },
];

const FeedPage = ({ authObject, setAuthObject, authorization, setIsLoading }: any) => {
    const location = useLocation();
    const navigate = useNavigate();

    const containerRef: any = useRef(null);
    const limitCount = 10;
    const [lastPostId, setLastPostId] = useState<string>('');
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
    const [sortType, setSortType] = useState<SortType>({ value: 'all', label: '전체 보기' });
    const [coachId, setCoachId] = useState<string>(location?.state?.coachId ? location.state.coachId : '');

    const [target, setTarget] = useState<any>(null); // 관찰대상 target
    const [isLoaded, setIsLoaded] = useState(false); // Load 중인가를 판별하는 boolean
    const [stop, setStop] = useState(false); // 마지막 데이터까지 다 불러온 경우 더이상 요청을 보내지 않기 위해서

    const [feedList, setFeedList] = useState<FeedListType>([]); // 일지 피드 리스트
    const [centerUserList, setCenterUserList] = useState<any>([]); // 센터의 강사,회원 리스트
    const [modalObject, setModalObject] = useState<any>(null); // 상세 모달 스테이트
    const [pictureList, setPictureList] = useState<any>([]);
    const [videoList, setVideoList] = useState<any>([]);
    const [selectVideoId, setSelectVideoId] = useState<string>('');

    const [isCalendar, setIsCalendar] = useState<boolean>(false);
    const [isVisibleSort, setIsVisibleSort] = useState<boolean>(false);
    const [isDetailModal, setIsDetailModal] = useState<boolean>(false); // 전체보기, 혹은 글클릭시 상세모달
    const [isConditionModal, setIsConditionModal] = useState<boolean>(false); // 컨디션 모달
    const [isImageModal, setIsImageModal] = useState<boolean>(false); // 이미지 모달
    const [isVideoModal, setIsVideoModal] = useState<boolean>(false); // 비디오 플레이어 모딜

    const [prevFilterFlag, setPrevFilterFlag] = useState<boolean>(false);
    const [isChangeFilter, setIsChangeFilter] = useState<boolean>(false);

    const toggleDropDown = () => {
        setIsVisibleSort(!isVisibleSort);
    };
    const handleDropDownSelect = (option: any) => {
        setSortType(option);
        setIsVisibleSort(false);
    };
    // 기간선택 버튼
    const onClickCalendar = () => {
        setIsCalendar(!isCalendar);
    };
    // 전체선택 버튼
    const onClickAllButton = () => {
        setCoachId('');
    };
    // 피드 상세 모달 버튼 클릭이벤트
    const onClickDetail = (object: any) => {
        setIsDetailModal(true);
        setModalObject(object);
    };
    // 강사 선택
    const onClickCoach = (id: string) => {
        setCoachId(id);
    };
    // 회원 선택
    const onClickMember = (memberData: any) => {
        navigate('/feed/member', {
            state: {
                coachId: memberData.coachId,
                memberId: memberData.memberId,
            },
        });
    };
    // 달력의 바깥부분 클릭시 달력모달 사라지게
    const onClickOutSide = (event: any) => {
        if (containerRef.current && !event.target.classList.contains('exclude')) {
            if (isVisibleSort) {
                setIsVisibleSort(false);
            } else {
                return;
            }
        }
    };
    //  피드 리스트 조회 API : (GET)
    const getAllFeedList = useQuery(
        ['getAllFeedListAPI', isChangeFilter, lastPostId],
        async () =>
            await getAllFeedListAPI(
                authObject?.adminId,
                lastPostId,
                limitCount,
                startDate ? dayjs(startDate).startOf('day').toISOString() : '',
                endDate ? dayjs(endDate).endOf('day').toISOString() : '',
                sortType.value === 'SCHEDULEREPORT' ? sortType.value : 'all',
                coachId,
                sortType.value === 'coach' || sortType.value === 'member' ? sortType.value : 'all',
                authorization
            ),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    if (isChangeFilter === prevFilterFlag) {
                        if (feedList !== res.data.posts) {
                            setFeedList((prevData: any) =>
                                prevData ? [...prevData, ...res.data.posts] : res.data.posts
                            );
                        }
                    } else {
                        setFeedList(res.data.posts);
                    }
                    setPrevFilterFlag(isChangeFilter);
                    setIsLoaded(false);
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!!authObject?.testAccount,
            staleTime: 0,
        }
    );
    //  센터 유저 리스트 조회 API : (GET)
    const getAllCoachMemberList = useQuery(
        ['getAllCoachMemberListAPI', isChangeFilter],
        async () =>
            await getAllCoachMemberListAPI(
                authObject?.adminId,
                startDate ? dayjs(startDate).startOf('day').toISOString() : '',
                endDate ? dayjs(endDate).endOf('day').toISOString() : '',
                authorization
            ),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    setCenterUserList(res.data.centerUserList);
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!!authObject?.testAccount,
            staleTime: 0,
        }
    );
    // 새로고침
    const onClickRefresh = () => {
        setLastPostId('');
        setPrevFilterFlag(isChangeFilter);
        setIsChangeFilter(!isChangeFilter);
        setStop(false);
    };
    // 필터초기화
    const onClickClearFilter = () => {
        setPrevFilterFlag(isChangeFilter);
        setIsChangeFilter(!isChangeFilter);
        setSortType({ value: 'all', label: '전체 보기' });
        setStartDate(null);
        setEndDate(null);
        setCoachId('');
        setLastPostId('');
    };
    // 인피니티 스크롤 감지 함수
    const onIntersect = async ([entry]: any, observer: any) => {
        // entry 요소가 교차되거나 Load중이 아니면
        if (entry.isIntersecting && !isLoaded) {
            // 관찰은 일단 멈추고
            await observer.unobserve(entry.target);
            // 데이터 불러오기
            setIsLoaded(true);
            // 불러온 후 다시 관찰 실행
            await observer.observe(entry.target);
        }
    };
    // 상세 모달안 이미지 클릭 이벤트
    const onClickImageAtDetailModal = (arr: any) => {
        setPictureList(arr);
        setIsImageModal(true);
    };
    // 상세 모달안 비디오 클릭 이벤트
    const onClickVideoAtDetailModal = (arr: any, videoId: string) => {
        setSelectVideoId(videoId);
        setVideoList(arr);
        setIsVideoModal(true);
    };

    // isLoaded가 변할 때 실행
    useEffect(() => {
        if (isLoaded && !stop && feedList) {
            if (feedList && feedList.length > 0 && feedList.length % 10 === 0) {
                setLastPostId(feedList[feedList.length - 1]?._id);
                return;
            } else {
                // setStop(true);
                return;
            }
        }
    }, [isLoaded]);

    useEffect(() => {
        let observer: any;
        if (target && !stop) {
            // callback 함수로 onIntersect를 지정
            observer = new IntersectionObserver(onIntersect, {
                threshold: 0,
            });
            observer.observe(target);
        }
        return () => observer && observer.disconnect();
    }, [target, isLoaded]);

    useEffect(() => {
        if (endDate || sortType.value || coachId) {
            setLastPostId('');
            setPrevFilterFlag(isChangeFilter);
            setIsChangeFilter(!isChangeFilter);
            setStop(false);
        }
    }, [endDate, sortType.value, coachId]);

    // 로딩 플래그
    useEffect(() => {
        if (getAllFeedList && getAllCoachMemberList) {
            if (getAllFeedList.isLoading || getAllCoachMemberList.isLoading) {
                if (authObject?.testAccount) {
                    setIsLoading(false);
                } else {
                    setIsLoading(true);
                }
            } else {
                setIsLoading(false);
            }
        }
    }, [getAllFeedList, getAllCoachMemberList]);

    return (
        <Col
            style={{
                marginTop: 64,
                alignItems: 'center',
            }}
        >
            <Col style={{ width: 1192, marginTop: 48 }}>
                <Col>
                    <FeedHeader
                        sortOptions={sortOptions}
                        sortType={sortType}
                        isVisibleSort={isVisibleSort}
                        toggleDropDown={toggleDropDown}
                        handleDropDownSelect={handleDropDownSelect}
                        onClickCalendar={onClickCalendar}
                        onClickRefresh={onClickRefresh}
                        startDate={startDate}
                        endDate={endDate}
                        onClickClearFilter={onClickClearFilter}
                    />
                    <Row style={{ justifyContent: 'space-between', marginTop: 32 }}>
                        {/* 게시물 피드리스트 */}
                        <FeedList onClickDetail={onClickDetail} feedList={feedList} setTarget={setTarget} />
                        {/* 센터 유저리스트 */}
                        <FeedSelectList
                            coachId={coachId}
                            centerUserList={centerUserList}
                            onClickCoach={onClickCoach}
                            onClickMember={onClickMember}
                            onClickAllButton={onClickAllButton}
                        />
                    </Row>
                </Col>

                {isCalendar && (
                    <RangeCalendar
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        setIsCalendar={setIsCalendar}
                        isPrevMonth={true}
                        isNextMonth={true}
                    />
                )}

                {/* 피드 상세 모달 */}
                {isDetailModal && (
                    <ModalFeedDetail
                        modalObject={modalObject}
                        setModalVisible={setIsDetailModal}
                        setIsConditionModal={setIsConditionModal}
                        onClickImageAtDetailModal={onClickImageAtDetailModal}
                        onClickVideoAtDetailModal={onClickVideoAtDetailModal}
                    />
                )}
                {/* 컨디션 전체보기 모달 */}
                {isConditionModal && (
                    <ModalFeedCondition modalObject={modalObject} setModalVisible={setIsConditionModal} />
                )}

                {/* 이미지 뷰어 모달 */}
                {isImageModal && pictureList && (
                    <ModalImagesViewer setModalVisible={setIsImageModal} pictureList={pictureList} />
                )}

                {/* 비디오 플레이어 모달 */}
                {isVideoModal && videoList && selectVideoId && (
                    <ModalVideoPlayer
                        setModalVisible={setIsVideoModal}
                        videoList={videoList}
                        selectVideoId={selectVideoId}
                    />
                )}
            </Col>
        </Col>
    );
};

export default FeedPage;
