import React, { useEffect, useState } from 'react';
import { Col, concatDateAndTimeDayjs, isSameDate, Row } from '../../../lib/utils';
import { Subtitle1Bold } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import WhiteSquareButton from '../../Button/WhiteSquareButton';
import ColorSquareButton from '../../Button/ColorSquareButton';
import dayjs, { Dayjs } from 'dayjs';
import Calendar from '../../Calendar/Calendar';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createOneOnOneScheduleAPI, createOneOnTwoScheduleAPI, getMemberListForCenterAPI } from '../../../api/schedule';
import CreateOneScheduleLeft from './CreateOneScheduleLeft';
import CreateOneScheduleRight from './CreateOneScheduleRight';
import _, { replace } from 'lodash';
import { useNavigate } from 'react-router-dom';
import ModalMembershipCreate from '../../MemberProfile/ModalMembershipCreate';
import ModalRegisterPass from '../../../scenes/ClassPass/ModalRegisterPass/ModalRegisterPass';

type RefineDate = {
    refineStartTime: string;
    refineEndTime: string;
};

const CreateOneSchedule = ({
    coachOption,
    authorization,
    authObject,
    createAlert,
    selectedSchedule,
    calendarType,
    calendarSelectedDay,
    calendarScrollY,
}: any) => {
    const navigate = useNavigate();
    const currentTime = selectedSchedule ? dayjs(selectedSchedule?.scheduleStartTime) : dayjs();
    const roundedTime = currentTime.minute(Math.floor(currentTime.minute() / 10) * 10).second(0);
    const currentTimeAdd50 = dayjs(roundedTime).add(50, 'minute');
    const _memberId = selectedSchedule ? selectedSchedule?.memberId : '';
    const _membershipId = selectedSchedule ? selectedSchedule?.membershipId : '';

    /**
     * coach : 담당강사
     * startTime : 시작 시간
     * endTime : 종료시간
     * isOneOnOneClass : true - 1:1수업 , false - 2:1수업
     * isDisabledInput : 1:1수업 또는 1:2 수업 클릭시 멤버가 다선택되면 input 비활성화
     */
    const [coach, setCoach] = useState({
        value: selectedSchedule ? selectedSchedule?.coachId : '',
        label: selectedSchedule ? selectedSchedule?.coachName : '강사를 선택해주세요!',
    });
    const [startTime, setStartTime] = useState({ value: roundedTime, label: dayjs(roundedTime).format('A hh:mm') });
    const [endTime, setEndTime] = useState({
        value: currentTimeAdd50,
        label: dayjs(currentTimeAdd50).format('A hh:mm'),
    });
    const [isOneOnOneClass, setIsOneOnOneClass] = useState<boolean>(true);
    const [isDisabledInput, setIsDisabledInput] = useState<boolean>(false);

    /**
     * searchText: 회원 검색
     * memberList: 강사의 연결된 모든 회원의 리스트
     * filteredMemberList : 검색결과가 반영된 회원 리스트
     * selectedMember: 선택된 회원
     */
    const [searchText, setSearchText] = useState<string>('');
    const [memberList, setMemberList] = useState<any>([]);
    const [filteredMemberList, setFilteredMemberList] = useState<any>([]);
    const [selectedMember, setSelectedMember] = useState<any>([]);

    const [oneOnOneMemberShipIndex, setOneOnOneMemberShipIndex] = useState<number>(1);

    const [firstMemberShipIndex, setFirstMemberShipIndex] = useState<number>(1);
    const [secondMemberShipIndex, setSecondMemberShipIndex] = useState<number>(1);

    /** 캘린더 state */
    const [selectedDay, setSelectedDay] = useState<any>(
        selectedSchedule ? new Date(selectedSchedule?.scheduleStartTime) : new Date()
    );
    const [isCalendar, setIsCalendar] = useState<boolean>(false);

    const [timeList, setTimeList] = useState<any>(null); // 10분단위 시간 배열

    // 수강권 등록 모달 열기
    const [isModalMembershipCreateVisible, setIsModalMembershipCreateVisible] = useState<boolean>(false);

    // 회원 수강권 새로 생성 모달
    const [isRegisterPassModal, setIsRegisterPassModal] = useState<boolean>(false);

    // selectedMemberName : 선택된 회원의 이름
    // selectedMemberId : 선택된 회원의 userId
    const [selectedMemberName, setSelectedMemberName] = useState<string>('');
    const [selectedMemberId, setSelectedMemberId] = useState<string>('');

    // 회원 검색 이벤트
    const onChangeText = (e: any) => {
        setSearchText(e.target.value);
    };

    // 담당 강사 선택
    const handleCoachSelect = (option: any) => {
        setCoach(option);
    };

    // 시작 시간 선택
    const handleStartTimeSelect = (option: any) => {
        setStartTime(option);
    };

    // 종료 시간 선택
    const handleEndTimeSelect = (option: any) => {
        setEndTime(option);
    };

    // 멤버 선택
    const handleMemberSelect = (option: any) => {
        setSearchText('');
        let cloneSelectedMember = _.cloneDeep(selectedMember);
        cloneSelectedMember.push(option);
        setSelectedMember(cloneSelectedMember);
    };

    // 선택된 회원 지우는 함수
    const onClickDeleteSelectedMember = (value: string) => {
        const filterSelectedMemberList = selectedMember.filter((e: any) => e.value !== value);
        setSelectedMember(filterSelectedMemberList);

        setOneOnOneMemberShipIndex(1);
        setFirstMemberShipIndex(1);
        setSecondMemberShipIndex(1);
    };

    // 수강권 추가하기 버튼 클릭이벤트
    const onClickClassPassPage = (selectedMember: any) => {
        setSelectedMemberId(selectedMember.value);
        setSelectedMemberName(selectedMember.label);
        setIsModalMembershipCreateVisible(true);
    };

    //  회원 리스트 조회 API : (GET)
    const getMemberListForCenter = useQuery(
        ['getMemberListForCenterAPI', coach?.value],
        async () => await getMemberListForCenterAPI(authObject?.centerId, coach?.value, authorization),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const response = res?.data?.memberDataList;
                    let memberListToRes: any = [];

                    if (response.length > 0) {
                        for (let i = 0; i < response.length; i++) {
                            memberListToRes.push({
                                value: response[i]?.memberId,
                                label: response[i]?.alias,
                                status: response[i]?.membershipStatus,

                                memberships: response[i]?.memberships ? response[i]?.memberships : [],
                                profileColorType: response[i]?.profileColorType,
                                profileImageUrl: response[i]?.profileImageUrl,
                                profileName: response[i]?.profileName,
                            });
                        }
                        setMemberList(memberListToRes);
                        setFilteredMemberList(memberListToRes);

                        // 기존 선택된 회원의 수강권을 변경된 수강권들로 업데이트 하는 로직
                        const _selectMember = _.cloneDeep(selectedMember);

                        if (_selectMember.length === 1 || _selectMember.length === 2) {
                            for (const member of _selectMember) {
                                const memberId = member.value;
                                const resultMember = memberListToRes.find(
                                    (memberRes: any) => memberRes.value === memberId
                                );
                                if (resultMember) {
                                    member.memberships = resultMember.memberships;
                                }
                            }
                            setSelectedMember(_selectMember);
                        }
                        // selectedMember가 0명이거나 3명 이상인 경우
                        else {
                            setSelectedMember([]);
                        }
                    } else {
                        setMemberList([]);
                        setSelectedMember([]);
                    }
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!coach?.value,
            // refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    // 1:1수업 생성 API : (POST)
    const createOneOnOneSchedule = useMutation({
        mutationFn: async (refineDate: RefineDate) =>
            await createOneOnOneScheduleAPI(
                authObject?.centerId,
                coach?.value,
                selectedMember[0]?.value,
                refineDate?.refineStartTime,
                refineDate?.refineEndTime,
                selectedMember[0]?.memberships[oneOnOneMemberShipIndex - 1]?.id,
                authorization
            ),
        onSuccess: (res, refineDate) => {
            if (res.status === 201) {
                const startTime = refineDate.refineStartTime;
                navigate('/schedule', {
                    state: { calendarType, calendarTimeInfo: startTime },
                    replace: true,
                });
            } else if (res.status === 410 || res.status === 420 || res.status === 430) {
                createAlert(
                    '중복',
                    `선택된 담당 강사 ${coach?.label}님이\n해당시간에 중복된 스케줄이 존재합니다`,
                    true
                );
            } else {
                createAlert('오류', `서버 내부 오류\n 지속적인 오류발생시 고객센터로 문의바랍니다`, true);
            }
        },
    });

    // 1:2수업 생성 API : (POST)
    const createOneOnTwoSchedule = useMutation({
        mutationFn: async (refineDate: RefineDate) =>
            await createOneOnTwoScheduleAPI(
                authObject?.centerId,
                coach?.value,
                [
                    {
                        memberId: selectedMember[0].value,
                        startTime: refineDate?.refineStartTime,
                        endTime: refineDate?.refineEndTime,
                        membershipId: selectedMember[0]?.memberships[firstMemberShipIndex - 1]?.id,
                    },
                    {
                        memberId: selectedMember[1].value,
                        startTime: refineDate?.refineStartTime,
                        endTime: refineDate?.refineEndTime,
                        membershipId: selectedMember[1]?.memberships[secondMemberShipIndex - 1]?.id,
                    },
                ],
                authorization
            ),
        onSuccess: (res, refineDate) => {
            if (res.status === 201) {
                const startTime = refineDate.refineStartTime;
                navigate('/schedule', {
                    state: { calendarType, calendarTimeInfo: startTime },
                    replace: true,
                });
            } else if (res.status === 410 || res.status === 420 || res.status === 430) {
                createAlert(
                    '중복',
                    `선택된 담당 강사 ${coach?.label}님이\n해당시간에 중복된 스케줄이 존재합니다`,
                    true
                );
            } else {
                createAlert('오류', `서버 내부 오류\n지속적인 오류발생시 고객센터로 문의바랍니다`, true);
            }
        },
    });

    // 수업일정 등록 버튼 클릭이벤트
    const onClickCreateSchedule = () => {
        if (coach?.value === '') {
            createAlert('필수 입력', '담당 강사를 선택해주세요', true);
            return;
        }

        if (selectedMember.length === 0) {
            createAlert('필수 입력', '회원을 선택해주세요', true);
            return;
        }

        let findNullMembership = selectedMember.findIndex((item: any) => item?.memberships.length === 0);

        if (findNullMembership !== -1) {
            createAlert(
                '필수 입력',
                '수강권이 없는 회원이 존재합니다\n반드시 수강권을 만들고 일정을 만들어주세요',
                true
            );
        }

        // 2:1 수업일경우
        if (!isOneOnOneClass) {
            if (selectedMember.length !== 2) {
                createAlert('필수 입력', '1:2 수업일 경우 회원을 2명 선택해주세요', true);
            }
        }

        const concatStartTime = dayjs(concatDateAndTimeDayjs(selectedDay, startTime.value));
        const concatEndTime = dayjs(concatDateAndTimeDayjs(selectedDay, endTime.value));
        const refineStartTime = dayjs(concatStartTime).toISOString();
        const refineEndTime =
            dayjs(concatEndTime).format('HH:mm') === '00:00'
                ? dayjs(concatEndTime).add(1, 'day').toISOString()
                : dayjs(concatEndTime).toISOString();
        if (isSameDate(dayjs(refineStartTime), dayjs(refineEndTime))) {
            createAlert('입력 오류', '시작 시간과 완료 시간이 같습니다!\n다시 한번 확인해주세요!', '확인', () => {});
            return;
        }

        if (dayjs(refineStartTime).isAfter(dayjs(refineEndTime))) {
            createAlert(
                '입력 오류',
                '시작 시간이 완료 시간보다 늦을 수  없습니다!\n다시 한번 확인해주세요!',
                '확인',
                () => {}
            );
            return;
        }

        const refineDate: RefineDate = {
            refineStartTime: refineStartTime,
            refineEndTime: refineEndTime,
        };

        // 1:1수업일 경우 첫번째 회원의 수강권의 등록 가능 횟수가 0인경우 createTwoButtonAlert 호출
        if (
            isOneOnOneClass &&
            selectedMember[0]?.memberships[oneOnOneMemberShipIndex - 1]?.canMakeScheduleSession === 0
        ) {
            createAlert(
                '다 쓴 수강권이에요!',
                '‘등록 가능’ 회차 전부 수업이 등록되어있어요!\n그래도 해당 수강권으로 더 등록하시겠어요?',
                true,
                '등록하기',
                () => {
                    createOneOnOneSchedule.mutate(refineDate);
                },
                true,
                '취소',
                () => {}
            );
            return;
        }
        // 1:2수업일 경우 첫번째 회원의 수강권의 등록 가능 횟수가 0인경우 createTwoButtonAlert 호출
        else if (
            (!isOneOnOneClass &&
                selectedMember[0]?.memberships[firstMemberShipIndex - 1]?.canMakeScheduleSession === 0) ||
            selectedMember[1]?.memberships[secondMemberShipIndex - 1]?.canMakeScheduleSession === 0
        ) {
            createAlert(
                '다 쓴 수강권이에요!',
                '‘등록 가능’ 회차 전부 수업이 등록되어있어요!\n그래도 해당 수강권으로 더 등록하시겠어요?',
                true,
                '등록하기',
                () => {
                    createOneOnTwoSchedule.mutate(refineDate);
                },
                true,
                '취소',
                () => {}
            );
            return;
        } else {
            if (isOneOnOneClass) {
                createOneOnOneSchedule.mutate(refineDate);
            } else {
                createOneOnTwoSchedule.mutate(refineDate);
            }
        }
    };
    // 시간추가
    const onClickAddTime = (addNumber: number) => {
        if (
            dayjs(startTime.value).format('YYYY-MM-DD') ===
            dayjs(endTime.value).add(addNumber, 'minute').format('YYYY-MM-DD')
        ) {
            setEndTime({
                value: dayjs(endTime.value).add(addNumber, 'minute'),
                label: dayjs(dayjs(endTime.value).add(addNumber, 'minute')).format('A hh:mm'),
            });
        } else {
            createAlert('입력 오류', '추가 가능한 시간을 초과하였습니다', '확인', () => {});
        }
    };

    // 시간빼기
    const onClickSubTime = (subNumber: number) => {
        if (dayjs(startTime.value).isBefore(dayjs(endTime.value).subtract(subNumber, 'minute'))) {
            setEndTime({
                value: dayjs(endTime.value).subtract(subNumber, 'minute'),
                label: dayjs(dayjs(endTime.value).subtract(subNumber, 'minute')).format('A hh:mm'),
            });
        } else {
            createAlert('입력 오류', '감소 가능한 시간을 초과하였습니다', '확인', () => {});
        }
    };

    // 시작 시간이 변할 때 또는 수강권이 변할 때(회원 선택, 회원의 수강권 변경) endTime 정재
    useEffect(() => {
        if (startTime) {
            // 기본 수업 시간을 50분으로 설정
            let duration = 50;

            // 1대1 수업 또는 1대2 수업에 따른 Index 설정
            const membershipIndex = (isOneOnOneClass ? oneOnOneMemberShipIndex : firstMemberShipIndex) - 1;

            if (
                !!selectedMember &&
                selectedMember[0]?.memberships.length > 0 &&
                membershipIndex < selectedMember[0]?.memberships.length
            ) {
                // 회원권에 있는 수업 시간으로 수업시간을 설정
                duration = selectedMember[0]?.memberships[oneOnOneMemberShipIndex - 1]?.period;
            }

            const endTime: Dayjs = dayjs(startTime.value).add(duration, 'minute');

            if (
                endTime.isAfter(dayjs(startTime.value).endOf('day')) ||
                isSameDate(endTime, dayjs(startTime.value).endOf('day'))
            ) {
                setEndTime({
                    value: dayjs().endOf('day').add(1, 'millisecond'),
                    label: dayjs().endOf('day').add(1, 'millisecond').format('A hh:mm'),
                });
            } else {
                setEndTime({ value: dayjs(endTime), label: dayjs(endTime).format('A hh:mm') });
            }
        }
    }, [startTime, selectedMember, oneOnOneMemberShipIndex, firstMemberShipIndex]);

    // 회원이름 검색 정제
    useEffect(() => {
        if (memberList) {
            if (searchText !== '') {
                let filterMemberList = memberList.filter((e: any) => {
                    return (
                        e.label.includes(searchText) &&
                        !selectedMember.some((selected: any) => selected.value === e.value)
                    );
                });

                setFilteredMemberList(filterMemberList);
            } else {
                let filterMemberList = memberList.filter((e: any) => {
                    return !selectedMember.some((selected: any) => selected.value === e.value);
                });

                setFilteredMemberList(filterMemberList);
            }
        }
    }, [searchText, selectedMember]);

    // 시간 10분단위로 생성
    useEffect(() => {
        // 시간생성
        const generateTimeSlots = () => {
            const timeSlots = [];
            const start = dayjs().startOf('day').hour(0).minute(0);
            for (let i = 0; i < 144; i++) {
                // 24 hours * 6 (10분 간격)
                const time = start.add(i * 10, 'minute');
                const formattedTime = time.format('A hh:mm'); // 오전/오후 표시
                timeSlots.push({ value: time, label: formattedTime });
            }
            return timeSlots;
        };

        const timeSlots = generateTimeSlots();
        setTimeList(timeSlots);
    }, []);

    //  1:1수업 2:1 수업일시 input disable 로직
    useEffect(() => {
        if (isOneOnOneClass) {
            selectedMember && selectedMember.length === 1 ? setIsDisabledInput(true) : setIsDisabledInput(false);
        } else {
            selectedMember && selectedMember.length === 2 ? setIsDisabledInput(true) : setIsDisabledInput(false);
        }
    }, [selectedMember]);

    // 1:1수업 , 2:1 수업 버튼 클릭시 상태 초기화
    useEffect(() => {
        setSelectedMember([]);
        setSearchText('');
    }, [isOneOnOneClass]);

    // _memberId가 있을 경우 회원 리스트 조회
    useEffect(() => {
        if (_memberId && !_membershipId) {
            console.log('weffwe');

            filteredMemberList.length > 0 &&
                filteredMemberList.map((member: any) => {
                    if (member.value === _memberId) {
                        setSelectedMember([member]);
                    }
                });
        }
    }, [_memberId, filteredMemberList]);

    // _membershipId가 있을 경우 회원 리스트 조회
    useEffect(() => {
        if (_membershipId) {
            filteredMemberList.length > 0 &&
                filteredMemberList.map((member: any) => {
                    if (member.value === _memberId) {
                        setSelectedMember([member]);
                        //  memberships 에서 _membershipId 와 같은 membership 의 index 를 찾아서 oneOnOneMemberShipIndex 로 설정
                        member.memberships.map((membership: any, index: number) => {
                            if (membership.id === _membershipId) {
                                setOneOnOneMemberShipIndex(index + 1);
                            }
                        });
                    }
                });
        }
    }, [_membershipId, filteredMemberList]);

    //

    return (
        <Col style={{ paddingLeft: 101, paddingTop: 56, paddingRight: 101, paddingBottom: 64 }}>
            <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <Subtitle1Bold style={{ color: colors.LAVEL_4 }}>{'수업일정 등록하기'}</Subtitle1Bold>

                <Row style={{ alignItems: 'center', width: 252, justifyContent: 'space-between' }}>
                    <WhiteSquareButton
                        customStyle={{ width: 100, height: 36 }}
                        text="돌아가기"
                        size="small"
                        callBack={() => {
                            navigate('/schedule', {
                                state: { calendarType, calendarSelectedDay, calendarScrollY },
                                replace: true,
                            });
                        }}
                    />
                    <ColorSquareButton
                        isGradient={true}
                        customStyle={{ width: 136, height: 36 }}
                        text="수업일정 등록"
                        size="small"
                        callBack={onClickCreateSchedule}
                    />
                </Row>
            </Row>

            <Row style={{ marginTop: 56 }}>
                <CreateOneScheduleLeft
                    coachOption={coachOption}
                    coach={coach}
                    handleCoachSelect={handleCoachSelect}
                    selectedDay={selectedDay}
                    setIsCalendar={setIsCalendar}
                    timeList={timeList}
                    startTime={startTime}
                    handleStartTimeSelect={handleStartTimeSelect}
                    endTime={endTime}
                    handleEndTimeSelect={handleEndTimeSelect}
                    onClickAddTime={onClickAddTime}
                    onClickSubTime={onClickSubTime}
                />
                <CreateOneScheduleRight
                    setIsOneOnOneClass={setIsOneOnOneClass}
                    isOneOnOneClass={isOneOnOneClass}
                    selectedMember={selectedMember}
                    filteredMemberList={filteredMemberList}
                    handleMemberSelect={handleMemberSelect}
                    searchText={searchText}
                    onChangeText={onChangeText}
                    coach={coach}
                    isDisabledInput={isDisabledInput}
                    onClickDeleteSelectedMember={onClickDeleteSelectedMember}
                    onClickClassPassPage={onClickClassPassPage}
                    oneOnOneMemberShipIndex={oneOnOneMemberShipIndex}
                    setOneOnOneMemberShipIndex={setOneOnOneMemberShipIndex}
                    firstMemberShipIndex={firstMemberShipIndex}
                    setFirstMemberShipIndex={setFirstMemberShipIndex}
                    secondMemberShipIndex={secondMemberShipIndex}
                    setSecondMemberShipIndex={setSecondMemberShipIndex}
                />
            </Row>

            {isCalendar && (
                <Calendar
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    setIsCalendar={setIsCalendar}
                    isPrevMonth={true}
                    isNextMonth={true}
                    style={{
                        width: 420,
                        height: 370,
                        borderRadius: 24,
                        backgroundColor: colors.WHITE_50,
                        boxShadow: '1px 1px 8px 0px #2B529D2E',
                        position: 'absolute',
                        zIndex: 1999,
                        marginTop: 340,
                    }}
                />
            )}

            {isModalMembershipCreateVisible && (
                <ModalMembershipCreate
                    setIsVisible={setIsModalMembershipCreateVisible}
                    onClickCreateNewMembership={() => setIsRegisterPassModal(true)}
                    memberName={selectedMemberName}
                    coachName={coach?.label}
                    coachId={coach?.value}
                    memberId={selectedMemberId}
                    createAlert={createAlert}
                    type={'schedule'}
                />
            )}

            {isRegisterPassModal && (
                <ModalRegisterPass
                    modalVisible={isRegisterPassModal}
                    setModalVisible={setIsRegisterPassModal}
                    createAlert={createAlert}
                />
            )}
        </Col>
    );
};

export default CreateOneSchedule;
