import React from 'react';
import styled from 'styled-components';
import {
    I_ACCENT,
    I_PRIMARY_SPEECH_BUBBLE,
    I_RED_RIGHT_ARROW,
    I_RIGHT_PRIMARY_BTN,
    I_YELLOW_SPEECH_BUBBLE,
} from '../../types/images';
import { Col, Row } from '../../lib/utils';
import { colors } from '../../lib/colors';
import {
    Body1Bold,
    Body1Light,
    Body1Regular,
    Body2Regular,
    Body3Bold,
    Body3Light,
    Body3Regular,
    CaptionRegular,
    Head3,
    Head4,
    Subtitle1Bold,
    Subtitle1Regular,
} from '../../lib/font';
import PrimaryButton from '../Button/PrimaryButton';
import ColorRoundButton from '../Button/ColorRoundButton';

// 스타일드 컴포넌트 생성
const DottedLine = styled.div`
    border-top: 1px dotted #62656b; /* 점선 스타일 및 색상 설정 */
    width: 280px;
    height: 1px; /* 점선의 세로 길이 설정 */
    margin-left: 40px;
`;
const YellowSpeech = styled.div`
    background-image: url(${I_YELLOW_SPEECH_BUBBLE});
    background-size: cover; // 이미지를 커버하도록 설정
    width: 124px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;

    /* overflow: hidden; // border-radius와 함께 사용하여 둥근 모서리가 제대로 나오도록 합니다. */
    /* box-shadow: 0px 0px 12px 2px #2e323752; */
`;

const PrimarySpeech = styled.div`
    background-image: url(${I_PRIMARY_SPEECH_BUBBLE});
    background-size: cover; // 이미지를 커버하도록 설정
    width: 124px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;

    /* overflow: hidden; // border-radius와 함께 사용하여 둥근 모서리가 제대로 나오도록 합니다. */
    /* box-shadow: 0px 0px 12px 2px #2e323752; */
`;

const GradientText = styled.span`
    background: linear-gradient(135deg, #2fa0df 0%, #ee78c6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 3.2rem;
    font-weight: 700;
`;

const GradientText2 = styled.span`
    background: linear-gradient(135deg, #a74af0 0%, #db4863 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 3.2rem;
    font-weight: 700;
`;

const ExpiredPlanReSubscriptionBottom = ({
    buttonType,
    setButtonType,
    smallCenterTrainerNumber,
    bigCenterTrainerNumber,
    onChangeTrainerNumber,
    onClickPayButton,
    authObject,
    coachCount,
    isCoachCountAlert,
}: any) => {
    return (
        <Row
            style={{
                width: 1064,
                height: 452,
                border: '1px solid #a6bfef',
                backgroundColor: colors.WHITE_50,
                justifyContent: 'space-between',
                alignSelf: 'center',
                borderRadius: 36,
                marginTop: 24,
            }}
        >
            <Col>
                <Row style={{ width: 462, height: 48, alignItems: 'center', marginTop: 48, marginLeft: 56 }}>
                    {buttonType === 'small' ? <GradientText>Starter</GradientText> : <GradientText2>Pro</GradientText2>}

                    <Head3 style={{ color: colors.POINT_2, marginLeft: 8 }}>Manager Sale</Head3>

                    {buttonType === 'small' ? (
                        <Row
                            style={{
                                width: 118,
                                height: 48,
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: colors.WHITE_50,
                                borderRadius: 35,
                                background: 'linear-gradient(135deg, #2FA0DF 0%, #EE78C6 100%)',
                                marginLeft: 16,
                            }}
                        >
                            <Body3Bold>{'약 65% 할인'}</Body3Bold>
                        </Row>
                    ) : (
                        <Row
                            style={{
                                width: 118,
                                height: 48,
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: colors.WHITE_50,
                                borderRadius: 35,
                                background: 'linear-gradient(135deg, #A74AF0 0%, #DB4863 100%)',
                                marginLeft: 16,
                            }}
                        >
                            <Body3Bold>{'약 60% 할인'}</Body3Bold>
                        </Row>
                    )}
                </Row>

                <Col style={{ width: 523, height: 90, marginLeft: 63, marginTop: 32 }}>
                    <Row style={{ alignItems: 'center' }}>
                        <div style={{ width: 4, height: 4, borderRadius: 2, backgroundColor: colors.BLUE_900 }} />
                        {buttonType === 'small' ? (
                            <Body1Bold style={{ marginLeft: 7, color: colors.BLUE_900 }}>
                                3인 이하의 소규모 센터
                                <Body1Light>를 운영하는 스타터 관리자님들을</Body1Light>
                            </Body1Bold>
                        ) : (
                            <Body1Bold style={{ marginLeft: 7, color: colors.BLUE_900 }}>
                                4인 이상 대규모 센터
                                <Body1Light>를 운영하는 전문 매니저님들을 위한 </Body1Light>
                            </Body1Bold>
                        )}
                    </Row>

                    <Body1Light style={{ marginLeft: 11 }}>
                        {buttonType === 'small'
                            ? '위해 보다 더 할인된 가격으로 제공합니다.'
                            : '프로 모션 가격으로 제공합니다.'}
                    </Body1Light>

                    <Row style={{ alignItems: 'center' }}>
                        <div style={{ width: 4, height: 4, borderRadius: 2, backgroundColor: colors.BLUE_900 }} />

                        <Body1Light style={{ marginLeft: 7, color: colors.BLUE_900 }}>
                            {buttonType === 'small'
                                ? '센터의 성장을 위해 라포 매니저를 시작해보세요!'
                                : '라포를 이용하여 성장한 센터를 보다 편리하게 관리해보세요!'}
                        </Body1Light>
                    </Row>
                </Col>

                <Row
                    style={{
                        width: 482,
                        height: 140,
                        borderRadius: 24,
                        border: '1px solid #a6bfef',
                        marginLeft: 71,
                        marginTop: 38,
                        paddingTop: 24,
                        paddingLeft: 24,
                        paddingRight: 24,
                        justifyContent: 'space-between',
                    }}
                >
                    <Col style={{ width: 185, height: 92, color: colors.GREY_200 }}>
                        <Body3Regular>원가</Body3Regular>
                        <Head4>{'99,000 원'}</Head4>
                        <Body3Light>{'강사님 수 / 월 (부가세 포함)'}</Body3Light>

                        <img
                            src={I_ACCENT}
                            style={{ width: 124, position: 'absolute', marginLeft: 0, marginTop: 35 }}
                        />
                    </Col>
                    <Col
                        style={{
                            width: 84,
                            height: 92,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img src={I_RIGHT_PRIMARY_BTN} style={{ width: 16, height: 16 }} />
                    </Col>

                    <Col style={{ width: 185, height: 92, color: colors.LAVEL_4 }}>
                        <Body3Regular>원가</Body3Regular>
                        <Head4>{buttonType === 'small' ? '32,900 ' : '39,900 '} 원</Head4>
                        <Body3Light>{'강사님 수 / 월 (부가세 포함)'}</Body3Light>
                    </Col>
                </Row>
            </Col>

            {/* 결제박스 */}
            <Col
                style={{
                    width: 350,
                    height: 372,
                    backgroundColor: colors.WHITE_300,
                    borderRadius: 24,
                    marginTop: 40,
                    marginRight: 38,
                    alignItems: 'center',
                }}
            >
                <Row
                    style={{
                        width: 280,
                        justifyContent: 'space-between',

                        marginTop: 46,
                    }}
                >
                    <Body3Regular style={{ color: colors.LAVEL_3 }}>상품 금액 (월)</Body3Regular>
                    <Body3Regular style={{ color: colors.LAVEL_3 }}>99,000 원</Body3Regular>
                </Row>

                <DottedLine style={{ marginTop: 24 }} />
                <Row
                    style={{
                        width: 280,
                        justifyContent: 'space-between',

                        marginTop: 16,
                    }}
                >
                    <Body3Regular style={{ color: colors.LAVEL_3 }}>할인 금액 (월)</Body3Regular>
                    <Body3Regular style={{ color: colors.LAVEL_3 }}>
                        {buttonType === 'small' ? '32,900 ' : '39,900 '}원
                    </Body3Regular>
                </Row>

                <Row
                    style={{
                        width: 280,
                        justifyContent: 'end',

                        marginTop: 16,
                    }}
                >
                    <Body3Regular style={{ color: colors.LAVEL_3 }}>
                        X{' '}
                        {buttonType === 'small'
                            ? smallCenterTrainerNumber
                                ? smallCenterTrainerNumber
                                : 0
                            : bigCenterTrainerNumber
                            ? bigCenterTrainerNumber
                            : 0}
                        명
                    </Body3Regular>
                </Row>
                <DottedLine style={{ marginTop: 24 }} />

                <Row
                    style={{
                        width: 280,
                        justifyContent: 'space-between',

                        marginTop: 18,
                    }}
                >
                    <Body3Bold style={{ color: colors.LAVEL_4 }}>총 금액 (월)</Body3Bold>
                    <Body3Bold style={{ color: colors.LAVEL_4 }}>
                        {buttonType === 'small'
                            ? ((smallCenterTrainerNumber ? smallCenterTrainerNumber : 0) * 32900).toLocaleString()
                            : ((bigCenterTrainerNumber ? bigCenterTrainerNumber : 0) * 39900).toLocaleString()}{' '}
                        원
                    </Body3Bold>
                </Row>
                <Row
                    style={{
                        width: 280,
                        justifyContent: 'end',

                        marginTop: 8,
                    }}
                >
                    <CaptionRegular style={{ color: colors.LAVEL_3 }}>부가세 포함</CaptionRegular>
                </Row>

                <ColorRoundButton
                    text="구독 시작"
                    size="middle"
                    callBack={onClickPayButton}
                    isDisabled={authObject?.testAccount || isCoachCountAlert}
                    customStyle={{ width: 280, height: 48, marginTop: 32 }}
                />
            </Col>
        </Row>
    );
};

export default ExpiredPlanReSubscriptionBottom;
