import React, { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row, getItemWithExpiration, setItemWithExpiration } from '../../lib/utils';
import { Bottom } from '../global';
import { useNavigate } from 'react-router-dom';
import ExpiredInformation from '../../components/ExpiredPlan/ExpiredInformation';
import ExpiredPlanReSubscriptionHeader from '../../components/ExpiredPlan/ExpiredPlanReSubscriptionHeader';
import ExpiredPlanReSubscriptionBottom from '../../components/ExpiredPlan/ExpiredPlanReSubscriptionBottom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getSubscriptionPlanAPI } from '../../api/charge';
import { clearLocalStorage } from '../../lib/utils';
import PlanInformation from '../../components/SubscriptionPlan/PlanInformation';
import { colors } from '../../lib/colors';
import { Body3Bold, Body3Light, Head4 } from '../../lib/font';
import NormalButton from '../../components/Button/NormalButton';
import ModalWithDrawal from '../../components/modal/ModalWithDrawal';
import ModalAlert from '../../components/modal/ModalAlert';
import { deleteAccountAPI } from '../../api/mypage';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import secureLocalStorage from 'react-secure-storage';

const ExpiredPlanPage = ({ createAlert }: any) => {
    const navigate = useNavigate();
    const LocalSuccessUrl: any = process.env.REACT_APP_LOCAL_EXPIRED_SUCCESS_URL;
    const LocalFailUrl: any = process.env.REACT_APP_LOCAL_EXPIRED_FAIL_URL;
    const ProdSuccessUrl: any = process.env.REACT_APP_PROD_EXPIRED_SUCCESS_URL;
    const ProdFailUrl: any = process.env.REACT_APP_PROD_EXPIRED_FAIL_URL;

    // auth 변수
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));

    // local state
    const [buttonType, setButtonType] = useState<string>('small');
    const [coachCount, setCoachCount] = useState<number>(0);
    const [smallCenterTrainerNumber, setSmallCenterTrainerNumber] = useState<number>(1);
    const [bigCenterTrainerNumber, setBigCenterTrainerNumber] = useState<number>(4);
    const [isCoachCountAlert, setIsCoachCountAlert] = useState<boolean>(false);

    // alert 관련
    const [isWithDrawalModal, setIsWithDrawalModal] = useState<boolean>(false);
    const [isDeleteAlertModal, setIsDeleteAlertModal] = useState<boolean>(false);
    const [alertTitle, setAlertTitle] = useState<string>('');
    const [alertContent, setAlertContent] = useState<string>('');
    const [reasonText, setReasonText] = useState<string>('');

    // 토스 결제창 연동 함수
    const tossPayFunction = async () => {
        const trainerNumber = buttonType === 'small' ? smallCenterTrainerNumber : bigCenterTrainerNumber;
        setItemWithExpiration('trainerNumber', trainerNumber, 12);
        const clientKey: any =
            process.env.NODE_ENV === 'development'
                ? process.env.REACT_APP_TOSS_TEST_CLIENT_KEY
                : process.env.REACT_APP_TOSS_LIVE_CLIENT_KEY;

        const customerKeyUUID = authObject?.id;

        await loadTossPayments(clientKey).then((tossPayments) => {
            // ------ 카드 등록창 호출 ------
            tossPayments
                .requestBillingAuth('카드', {
                    customerKey: customerKeyUUID, // 고객 ID로 상점에서 만들어야 합니다. 빌링키와 매핑됩니다. 자세한 파라미터 설명은 결제 정보 파라미터 설명을 참고하세요.
                    successUrl: process.env.NODE_ENV === 'development' ? LocalSuccessUrl : ProdSuccessUrl,
                    failUrl: process.env.NODE_ENV === 'development' ? LocalFailUrl : ProdFailUrl,
                })
                .catch(function (error) {
                    console.log(error.code);
                    secureLocalStorage.removeItem('trainerNumber');
                    if (error.code === 'USER_CANCEL') {
                        // 결제 고객이 결제창을 닫았을 때 에러 처리
                    }
                });
        });
    };

    // 결제를 햇었던 이력이 있는경우 전에했던 요금제로 바로 가도록
    const tossPayFunctionCardChange = async () => {
        const trainerNumber = authObject?.planLimitCount;
        setItemWithExpiration('trainerNumber', trainerNumber, 12);
        const clientKey: any =
            process.env.NODE_ENV === 'development'
                ? process.env.REACT_APP_TOSS_TEST_CLIENT_KEY
                : process.env.REACT_APP_TOSS_LIVE_CLIENT_KEY;

        const customerKeyUUID = authObject?.id;

        await loadTossPayments(clientKey).then((tossPayments) => {
            // ------ 카드 등록창 호출 ------
            tossPayments
                .requestBillingAuth('카드', {
                    customerKey: customerKeyUUID, // 고객 ID로 상점에서 만들어야 합니다. 빌링키와 매핑됩니다. 자세한 파라미터 설명은 결제 정보 파라미터 설명을 참고하세요.
                    successUrl: process.env.NODE_ENV === 'development' ? LocalSuccessUrl : ProdSuccessUrl,
                    failUrl: process.env.NODE_ENV === 'development' ? LocalFailUrl : ProdFailUrl,
                })
                .catch(function (error) {
                    console.log(error.code);
                    secureLocalStorage.removeItem('trainerNumber');
                    if (error.code === 'USER_CANCEL') {
                        // 결제 고객이 결제창을 닫았을 때 에러 처리
                    }
                });
        });
    };

    const onChangeTrainerNumber = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = parseInt(e.target.value);
        if (buttonType === 'small') {
            if (inputValue >= 1 && inputValue <= 3) {
                setSmallCenterTrainerNumber(inputValue);
            }
        } else {
            setBigCenterTrainerNumber(inputValue);
        }
    };

    // 결제하기 버튼 클릭이벤트
    const onClickPayButton = () => {
        tossPayFunction();
    };

    // 구독만료안내 > 카드변경버튼 클릭이벤트
    const onClickCardChangeButton = () => {
        tossPayFunctionCardChange();
    };

    // 회원탈퇴 버튼 클릭이벤트
    const onClickWithDrawal = () => {
        setIsWithDrawalModal(true);
    };

    // 회원탈퇴 버튼이유 탈퇴하기 버튼 클릭이벤트
    const onClickWithDrawalRepeat = (title: string, content: string) => {
        setAlertTitle(title);
        setAlertContent(content);
        setIsDeleteAlertModal(true);
    };

    // 진짜 회원탈퇴
    const onClickDeleteAccount = () => {
        deleteAccount.mutate(reasonText);
    };

    //  구독플랜조회 API : (GET)
    const getSubscriptionPlan = useQuery(
        ['getSubscriptionPlanAPI'],
        async () => await getSubscriptionPlanAPI(authorization, authObject?.adminId),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) {
                        setCoachCount(response.coachCount);
                    }
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!!authObject?.testAccount,
            refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    // 회원 탈퇴 API : (PATCH)
    const deleteAccount = useMutation({
        mutationFn: async (reason: string) => await deleteAccountAPI(authObject?.adminId, reason, authorization),
        onSuccess: (res) => {
            if (res.status === 204) {
                setIsWithDrawalModal(false);
                setIsDeleteAlertModal(false);
                clearLocalStorage();
                navigate('/signIn');
                createAlert('탈퇴 완료', '정상적으로 탈퇴되었습니다.');
            } else {
                createAlert('', '탈퇴 진행중 오류가 발생하였습니다');
            }
        },
    });

    // 현재 강사수보다 적은 요금제를 선택햇을경우 alert뛰우기 위한
    useEffect(() => {
        if (buttonType === 'small') {
            if (smallCenterTrainerNumber < coachCount) {
                setIsCoachCountAlert(true);
            } else {
                setIsCoachCountAlert(false);
            }
        } else {
            if (bigCenterTrainerNumber < coachCount) {
                setIsCoachCountAlert(true);
            } else {
                setIsCoachCountAlert(false);
            }
        }
    }, [smallCenterTrainerNumber, bigCenterTrainerNumber, buttonType, coachCount]);

    useEffect(() => {
        if (bigCenterTrainerNumber > 20) {
            setBigCenterTrainerNumber(20);
            createAlert('', '20명을 초과하는 요금제는 고객센터로 문의바랍니다.');
        }
    }, [bigCenterTrainerNumber]);

    return (
        <>
            <Col style={{ width: 1192, marginTop: 80, alignSelf: 'center' }}>

                {/* 구독 만료 안내 */}
                <ExpiredInformation
                    clearLocalStorage={clearLocalStorage}
                    authObject={authObject}
                    navigate={navigate}
                    onClickCardChangeButton={onClickCardChangeButton}
                />

                <Col
                    style={{
                        width: 1192,
                        height: 776,
                        borderRadius: 16,
                        backgroundColor: colors.WHITE_50,

                        border: '1px solid #C3D4F4',
                        marginTop: 24,
                    }}
                >
                    <ExpiredPlanReSubscriptionHeader
                        buttonType={buttonType}
                        setButtonType={setButtonType}
                        smallCenterTrainerNumber={smallCenterTrainerNumber}
                        bigCenterTrainerNumber={bigCenterTrainerNumber}
                        isCoachCountAlert={isCoachCountAlert}
                        onChangeTrainerNumber={onChangeTrainerNumber}
                        authObject={authObject}
                    />
                    <ExpiredPlanReSubscriptionBottom
                        buttonType={buttonType}
                        setButtonType={setButtonType}
                        smallCenterTrainerNumber={smallCenterTrainerNumber}
                        bigCenterTrainerNumber={bigCenterTrainerNumber}
                        onChangeTrainerNumber={onChangeTrainerNumber}
                        onClickPayButton={onClickPayButton}
                        authObject={authObject}
                        coachCount={coachCount}
                        isCoachCountAlert={isCoachCountAlert}
                    />
                </Col>
                {/* 라포 플랜 인포 */}
                <PlanInformation />

                {/* <NormalButton
                    onClick={onClickWithDrawal}
                    text={'탈퇴하기'}
                    style={{ marginLeft: 1176, marginTop: 40 }}
                /> */}
            </Col>

            {/*  탈퇴하기 버튼 클릭시 모달 */}
            {isWithDrawalModal && (
                <ModalWithDrawal
                    modalVisible={isWithDrawalModal}
                    setModalVisible={setIsWithDrawalModal}
                    onClickWithDrawalRepeat={onClickWithDrawalRepeat}
                    reasonText={reasonText}
                    setReasonText={setReasonText}
                />
            )}

            {/* 회원탈퇴 사유 적은후 탈퇴하기 눌럿을 경우 모달 */}
            {isDeleteAlertModal && (
                <ModalAlert
                    modalVisible={isDeleteAlertModal}
                    setModalVisible={setIsDeleteAlertModal}
                    title={alertTitle}
                    content={alertContent}
                    callBack={onClickDeleteAccount}
                />
            )}
        </>
    );
};

export default ExpiredPlanPage;
