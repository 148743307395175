import React from 'react';
import styled from 'styled-components';
import { Body1Regular, Body3Regular } from '../../lib/font';

const ButtonWrap = styled.button`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 24px;
    padding-right: 24px;
    color: #3d73dd;
    &:not([disabled]):hover {
        color: #7da1e8;
    }
    &:not([disabled]):active {
        color: #2b529d;
    }
`;

type Props = {
    size: 'large' | 'middle';
    text: string;
    callBack?: () => void;
};

const TextBlueButton = ({ size, text, callBack }: Props) => {
    const LargeStyle = {
        // width: 146,
        height: 46,
    };

    const MiddleStyle = {
        // width: 64,
        height: 32,
    };
    return (
        <ButtonWrap onClick={callBack} style={size === 'large' ? LargeStyle : MiddleStyle}>
            {size === 'large' && <Body3Regular>{text}</Body3Regular>}
            {size === 'middle' && <Body3Regular>{text}</Body3Regular>}
        </ButtonWrap>
    );
};

export default TextBlueButton;
