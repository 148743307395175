import React, { useState } from 'react';
import { Col, getItemWithExpiration, Row } from '../../lib/utils';
import { Body2Bold, Body2Regular, Body3Bold, Body3Regular, CaptionRegular, Head4 } from '../../lib/font';
import NewBackButton from '../Button/NewBackButton';
import { MemberMembershipDataType } from '../../services/useMembershipQueryService';
import dayjs from 'dayjs';
import NewWhiteRoundButton from '../Button/NewWhiteRoundButton';
import MemoTextInput from '../TextInput/MemoTextInput';
import { colors } from '../../lib/colors';
import useMembershipService from '../../services/useMembershipService';
import { useQueryClient } from '@tanstack/react-query';
import NewColorRoundButton from '../Button/NewColorRoundButton';

type Props = {
    membership: MemberMembershipDataType;
    memberName?: string;
    coachName?: string;
    onClickUpdate: () => void;
    createAlert: any;
};

const MemberMembershipManageComponents = ({ membership, memberName, coachName, onClickUpdate, createAlert }: Props) => {
    // 활성화 상태
    const isActive = membership.status === 'active';
    // [ ] : 활성, 스케줄 0 : 수정, 삭제
    const isEmptySchedule = membership.schedules.length === 0;
    // [ ] : 활성, 스케줄 있음 : 수정, 만료

    // [ ] : 만료, 스케줄 있음, 남은회차 있음 : 수정, 재활성화 O
    const isRemainSession = membership.remainSession > 0;
    // [ ] : 만료, 남은회차 없음 : 수정 , 재활성화 X

    const queryClient = useQueryClient();

    // 남은 일수
    const remainDays = dayjs(membership.expirationDate).diff(dayjs(membership.activationDate), 'day');

    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));

    const { updateMemberShipMutation, deleteMemberShipMutation } = useMembershipService({
        onUpdatedMemberShipSuccess: () => {
            if (isActive) {
                createAlert(
                    '수강권 만료 완료',
                    '수강권이 만료되었습니다!\n수강권의 사용 회차가 남아있을 경우, 재활성화가 가능해요!',
                    true
                );
            } else {
                createAlert('수강권 재활성화 완료!', '수강권이 재활성화 되었어요!', false);
            }

            queryClient.invalidateQueries({
                queryKey: ['getMembershipForMemberQuery'],
            });
        },
        onDeleteMemberShipSuccess: () => {
            createAlert(
                '수강권 삭제 완료',
                '수강권이 삭제되었습니다!\n수강권이 삭제되었으니, 회원의 수강권을 다시 등록해주세요!',
                false
            );
            queryClient.invalidateQueries({
                queryKey: ['getAllMembershipForMemberQuery'],
            });

            // 뒤로가기
            window.history.back();
        },
    });
    return (
        <Col className="mb-[80px]">
            <Row className="flex w-full items-center justify-between mb-[24px]">
                <Head4>회원 수강권 관리</Head4>
                <NewBackButton />
            </Row>
            <Row className="flex bg-WHITE_50 flex-row border-[1px] border-solid border-BLUE_100 rounded-[16px] px-[56px] py-[56px] mb-[16px]">
                <Col className="mr-[48px]">
                    <Col
                        className="flex flex-col w-[264px] rounded-[8px] h-[120px] justify-center items-center mb-[12px]"
                        style={{
                            background: isActive
                                ? `linear-gradient(135deg, ${membership?.BGColor[0]} 0%, ${membership?.BGColor[1]} 100%)`
                                : colors.GREY_200,
                            alignItems: 'center',
                        }}
                    >
                        <Col className="flex w-full p-[32px] justify-start items-start">
                            <Body2Bold className="text-LAVEL_0">{`${membership?.name}`}</Body2Bold>
                        </Col>
                    </Col>
                    <Col className={`flex bg-WHITE_300 rounded-[8px] p-[20px] `}>
                        <LabelAndValue label="회원" value={memberName ?? ''} mb={32} />
                        <Row>
                            <LabelAndValue label="담당 강사" value={coachName ?? ''} mb={32} mr={60} />
                            <LabelAndValue
                                label="발급자 "
                                value={
                                    membership.issuer.issuerRole === 'admin' ? '관리자' : membership?.issuer?.name ?? ''
                                }
                            />
                        </Row>
                        <LabelAndValue
                            label="발급 일시"
                            value={dayjs(membership.createdAt).format('YYYY년 MM월 DD일')}
                        />
                    </Col>
                </Col>
                <Col className="flex w-[752px]">
                    <Row className="flex w-full items-center mb-[28px] justify-between">
                        <LabelAndValue
                            label="사용 기간"
                            value={`${dayjs(membership.activationDate).format('YYYY년 MM월 DD일')} ~ ${dayjs(
                                membership.expirationDate
                            ).format('YYYY년 MM월 DD일')} (${remainDays}일)`}
                        />
                    </Row>
                    <Row className="mb-[24px]">
                        <LabelAndValue label="수업 시간" value={membership.period + '분'} width={200} />
                        <LabelAndValue label="회당 가격" value={membership.pricePerSchedule + '원'} width={200} />
                        <LabelAndValue label="판매 금액" value={membership.totalPrice + '원'} />
                    </Row>
                    <Row className="mb-[24px]">
                        <LabelAndValue label="총 회차" value={membership.totalSession + '회'} width={200} />

                        <LabelAndValue label="시작 회차" value={membership.startSessionValue + '회'} width={200} />
                        <LabelAndValue label="현재 회차" value={membership.currentSession + '회'} />
                    </Row>
                    <Row className="mb-[24px]"></Row>
                    {/* 메모 */}
                    <Col className="mb-[40px] w-[786px] h-[106px] ">
                        <Body3Regular className="text-LAVEL_1">메모</Body3Regular>
                        <Body2Regular className="text-LAVEL_4 w-full break-all h-full line-clamp-3 text-ellipsis">
                            {membership.memo}
                        </Body2Regular>
                    </Col>
                </Col>
            </Row>
            <Row className="w-full justify-between">
                {isActive ? (
                    isEmptySchedule ? (
                        <NewWhiteRoundButton
                            color={'GREY_300'}
                            text={'수강권 삭제'}
                            width={120}
                            height={24}
                            fontType={'CaptionRegular'}
                            onClick={() =>
                                deleteMemberShipMutation.mutate({
                                    membershipId: membership._id,
                                    accessToken: authorization,
                                })
                            }
                        />
                    ) : (
                        <NewWhiteRoundButton
                            color={'GREY_300'}
                            text={'수강권 만료'}
                            width={88}
                            height={24}
                            fontType={'CaptionRegular'}
                            onClick={() =>
                                updateMemberShipMutation.mutate({
                                    membershipId: membership._id,
                                    status: 'expired',
                                    accessToken: authorization,
                                })
                            }
                        />
                    )
                ) : (
                    <Row className=" items-center ">
                        <NewWhiteRoundButton
                            color={'primary'}
                            text={'수강권 재활성화'}
                            width={120}
                            height={24}
                            className="mr-[16px]"
                            fontType={'CaptionRegular'}
                            disabled={!isRemainSession}
                            onClick={() => {
                                if (!isRemainSession) {
                                    createAlert(
                                        '회차 조절이 필요해요!',
                                        `만료된 수강권을 활성화 시키려면 ‘수강권 수정하기’를 눌러\n‘총 회차’를 늘려주세요!`,
                                        true
                                    );
                                } else {
                                    updateMemberShipMutation.mutate({
                                        membershipId: membership._id,
                                        status: 'active',
                                        accessToken: authorization,
                                    });
                                }
                            }}
                        />
                        {isRemainSession ? (
                            <CaptionRegular className="" style={{ color: colors.LAVEL_1 }}>
                                회차가 남아있는 수강권은 재활성화가 가능해요!
                            </CaptionRegular>
                        ) : (
                            <>
                                <CaptionRegular style={{ color: colors.LAVEL_1 }}>
                                    만료된 수강권을 활성화 시키시려면
                                </CaptionRegular>
                                <CaptionRegular className="" style={{ color: colors.LAVEL_1 }}>
                                    회차를 조절해주세요!
                                </CaptionRegular>
                            </>
                        )}
                    </Row>
                )}
                <NewColorRoundButton
                    text={'수강권 수정'}
                    bgColor="primary"
                    textColor="white"
                    width={126}
                    height={36}
                    onClick={onClickUpdate}
                />
            </Row>
        </Col>
    );
};

export default MemberMembershipManageComponents;

const LabelAndValue = ({
    label,
    value,
    mr,
    mb,
    width = undefined,
}: {
    label: string;
    value: string;
    mr?: number;
    mb?: number;
    width?: number;
}) => {
    return (
        <Col className="flex" style={{ marginRight: mr, marginBottom: mb, width: width }}>
            <Body3Regular className="mb-[4px] text-LAVEL_1">{`${label}`}</Body3Regular>
            <Body3Regular>{value}</Body3Regular>
        </Col>
    );
};
