import { useEffect, useState } from 'react';
import { Col, getItemWithExpiration, Row } from '../../lib/utils';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getTrainerListAPI } from '../../api/employee';
import { useMutation } from '@tanstack/react-query';
import { storeUserFlow } from '../../api/adminuserflow';
import TrainerManageHeader from '../../components/TrainerManage/TrainerManageHeader';
import { SortType } from '../CommunicationPage';
import TrainerList from '../../components/TrainerManage/TrainerList';
import NormalToggleButton from '../../components/ToggleButton/NormalToggleButton';
import { Body3Regular } from '../../lib/font';
import { colors } from '../../lib/colors';
import FloatingButton from '../ClassPass/FloatingButton';
import { CodeValueType } from '../../types/types';

const TrainerManagePage = ({ createAlert, onClickAddTrainer, setIsLoading }: any) => {
    const navigate = useNavigate();
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));

    // 초기값 설정
    const [coachSortType, setCoachSortType] = useState<
        CodeValueType<'registrationasc' | 'seniorityasc' | 'membercountdesc' | 'alphabetasc'>
    >({ code: 'registrationasc', value: '등록순' });
    const [coachList, setCoachList] = useState<any>([]);

    const [registerList, setRegisterList] = useState<any>([]);
    const [filterCoachList, setFilterCoachList] = useState<any>([]);

    const [isVisibleDeleteTrainer, setIsVisibleDeleteTrainer] = useState<boolean>(true);

    const onClickToggleButton = () => {
        setIsVisibleDeleteTrainer(!isVisibleDeleteTrainer);
    };

    //  트레이너 리스트 조회 API : (GET)
    const getTrainerList = useQuery(
        ['getTrainerListAPI', coachSortType.value],
        async () => await getTrainerListAPI(authObject.centerId, coachSortType.code, authorization),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const registeredTrainer = res.data.registeredTrainer ? res.data.registeredTrainer : [];
                    const deletedTrainer = res.data.deletedTrainer ? res.data.deletedTrainer : [];

                    const totalList = [...registeredTrainer, ...deletedTrainer];

                    setRegisterList(registeredTrainer);
                    setFilterCoachList(totalList);
                    setCoachList(totalList);
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!!authObject?.testAccount,
            // refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    const onClickDetailPage = (item: any) => {
        navigate('trainerInfo', {
            state: {
                employeeId: item.employeeId,
                isConnected: item.isConnected,
                isDeleted: item.isDeleted,
            },
        });
    };

    useEffect(() => {
        if (isVisibleDeleteTrainer) {
            setFilterCoachList(coachList);
        } else {
            setFilterCoachList(registerList);
        }
    }, [isVisibleDeleteTrainer]);

    // 접속 데이터 측정 API
    const pushUserFlow = useMutation({
        mutationFn: async () => await storeUserFlow('trainerManage', authorization),
    });

    useEffect(() => {
        try {
            if (pushUserFlow) {
                pushUserFlow.mutate();
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    // 로딩 플래그
    useEffect(() => {
        if (getTrainerList) {
            if (getTrainerList.isLoading) {
                if (authObject?.testAccount) {
                    setIsLoading(false);
                } else {
                    setIsLoading(true);
                }
            } else {
                setIsLoading(false);
            }
        }
    }, [getTrainerList]);

    return (
        <Col style={{ marginTop: 64, alignItems: 'center' }}>
            <Col style={{ width: 1192, marginTop: 48 }}>
                <TrainerManageHeader coachSortType={coachSortType} setCoachSortType={setCoachSortType} />
                <TrainerList filterCoachList={filterCoachList} onClickDetailPage={onClickDetailPage} />

                <Row style={{ alignItems: 'center', marginTop: 16, marginBottom: 200 }}>
                    <NormalToggleButton isActive={isVisibleDeleteTrainer} callBack={onClickToggleButton} />
                    <Body3Regular style={{ color: colors.LAVEL_3, marginLeft: 12 }}>
                        {'삭제된 강사 보여주기'}
                    </Body3Regular>
                </Row>
            </Col>
            <FloatingButton onClick={onClickAddTrainer} />
        </Col>
    );
};

export default TrainerManagePage;
