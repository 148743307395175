import { Dispatch, SetStateAction } from 'react';
import { Body2Regular, Subtitle1Bold } from '../../lib/font';
import { colors } from '../../lib/colors';

import styled from 'styled-components';
import { I_FILL_ALERT, I_GREEN_ALERT, I_POPUP_EXIT } from '../../types/images';

import WhiteSquareButton from '../Button/WhiteSquareButton';
import ColorSquareButton from '../Button/ColorSquareButton';
import { Row } from '../../lib/utils';
import { HoverImage } from '../../lib/styled';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
`;

const ModalInner = styled.div`
    background: white;
    width: 600px;
    height: 300px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
`;

type Props = {
    modalVisible: boolean;
    setModalVisible: Dispatch<SetStateAction<boolean>>;
    title?: string | null;
    boldContent?: string | null;
    content?: string | null;
    callBack?: any;
    cancelButtonText?: string | null;
    confirmButtonText?: string | null;
    cancelCallBack?: any;
    twoButtonAlertStateReset?: any;
    isError?: boolean;
    customComponent?: React.ReactNode;
    isExitButton?: boolean;
};

const ModalAlert = ({
    modalVisible,
    setModalVisible,
    title,
    boldContent,
    content,
    callBack,
    cancelButtonText,
    confirmButtonText,
    cancelCallBack,
    twoButtonAlertStateReset,
    isError = false,
    customComponent,
    isExitButton = false,
}: Props) => {
    return (
        <ModalOverLay>
            {customComponent ? (
                <div className="bg-WHITE_50 shadow-lg rounded-[8px] p-[36px]">{customComponent}</div>
            ) : (
                <ModalInner>
                    {isExitButton && (
                        <Row
                            style={{
                                position: 'absolute',
                                width: '600px',
                                justifyContent: 'flex-end',
                                marginTop: 16,
                                paddingRight: 16,
                            }}
                        >
                            <HoverImage
                                src={I_POPUP_EXIT}
                                style={{ width: 24, height: 24 }}
                                onClick={() => setModalVisible(false)}
                            />
                        </Row>
                    )}
                    <img
                        src={isError ? I_FILL_ALERT : I_GREEN_ALERT}
                        style={{
                            width: 24,
                            height: 24,
                            marginBottom: 8,
                            marginTop: 36,
                        }}
                    />
                    <Subtitle1Bold
                        style={{
                            textAlign: 'center',
                            color: colors.LAVEL_4,
                        }}
                    >
                        {title}
                    </Subtitle1Bold>
                    <Body2Regular
                        style={{
                            color: colors.LAVEL_4,
                            whiteSpace: 'pre-wrap',
                            textAlign: 'center',
                            marginTop: 32,
                        }}
                    >
                        {content}
                    </Body2Regular>
                    <div
                        style={{
                            display: 'flex',
                            width: 312,
                            justifyContent: 'space-between',
                            marginTop: 24,
                        }}
                    >
                        <WhiteSquareButton
                            text={cancelButtonText ? cancelButtonText : '취소'}
                            size="middle"
                            isVisibleLeftImg={false}
                            isVisibleRightImg={false}
                            callBack={() => {
                                cancelCallBack && cancelCallBack();
                                twoButtonAlertStateReset ? twoButtonAlertStateReset() : setModalVisible(false);
                            }}
                        />

                        <ColorSquareButton
                            text={confirmButtonText ? confirmButtonText : '확인'}
                            size="middle"
                            isVisibleLeftImg={false}
                            isVisibleRightImg={false}
                            callBack={() => {
                                callBack && callBack();
                                twoButtonAlertStateReset ? twoButtonAlertStateReset() : setModalVisible(false);
                            }}
                        />
                    </div>
                </ModalInner>
            )}
        </ModalOverLay>
    );
};

export default ModalAlert;
