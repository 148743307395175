import dayjs from 'dayjs';

// 메인페이지 > 수업 진행 상황 더미데이터
export let dashBoardClassProgressDummyData = {
    success: true,
    thisDay: [
        {
            coachId: '6465c1a6090e920012f138eb',
            coachName: '강사1',
            coachProfile: '',
            totalScheduleCount: 10,
            completeScheduleCount: 5,
            interval: 5,
        },
        {
            coachId: '64646db825a9060012a76ad5',
            coachName: '강사2',
            coachProfile: '',
            totalScheduleCount: 6,
            completeScheduleCount: 3,
            interval: 3,
        },
        {
            coachId: '64f0555a5fc3a00012669553',
            coachName: '강사3',
            coachProfile: '',
            totalScheduleCount: 2,
            completeScheduleCount: 2,
            interval: 0,
        },
        {
            coachId: '658147f00f42a1001977b5b8',
            coachName: '강사4',
            coachProfile: '',
            totalScheduleCount: 7,
            completeScheduleCount: 2,
            interval: 5,
        },
        {
            coachId: '65814845ca42a00012a2d5d9',
            coachName: '강사5',
            coachProfile: '',
            totalScheduleCount: 5,
            completeScheduleCount: 1,
            interval: 4,
        },
        {
            coachId: '658148a90f42a1001977b739',
            coachName: '강사6',
            coachProfile: '',
            totalScheduleCount: 5,
            completeScheduleCount: 4,
            interval: 1,
        },
        {
            coachId: '658148cb0f42a1001977b78a',
            coachName: '강사7',
            coachProfile: '',
            totalScheduleCount: 7,
            completeScheduleCount: 6,
            interval: 1,
        },
        {
            coachId: '658149460f42a1001977b891',
            coachName: '강사8',
            coachProfile: '',
            totalScheduleCount: 10,
            completeScheduleCount: 6,
            interval: 4,
        },
        {
            coachId: '64f03dca5fc3a000126675a3',
            coachName: '강사9',
            coachProfile: '',
            totalScheduleCount: 10,
            completeScheduleCount: 8,
            interval: 2,
        },
        {
            coachId: '65814bb20f42a1001977b9e0',
            coachName: '강사10',
            coachProfile: '',
            totalScheduleCount: 9,
            completeScheduleCount: 3,
            interval: 6,
        },
        {
            coachId: '658149460f42a1001977b891',
            coachName: '강사11',
            coachProfile: '',
            totalScheduleCount: 10,
            completeScheduleCount: 6,
            interval: 4,
        },
        {
            coachId: '64f03dca5fc3a000126675a3',
            coachName: '강사12',
            coachProfile: '',
            totalScheduleCount: 10,
            completeScheduleCount: 8,
            interval: 2,
        },
        {
            coachId: '65814bb20f42a1001977b9e0',
            coachName: '강사13',
            coachProfile: '',
            totalScheduleCount: 9,
            completeScheduleCount: 3,
            interval: 6,
        },
    ],
    thisWeek: [
        {
            coachId: '6465c1a6090e920012f138eb',
            coachName: '강사1',
            coachProfile: '',
            totalScheduleCount: 20,
            completeScheduleCount: 15,
            interval: 5,
        },
        {
            coachId: '64646db825a9060012a76ad5',
            coachName: '강사2',
            coachProfile: '',
            totalScheduleCount: 16,
            completeScheduleCount: 13,
            interval: 3,
        },
        {
            coachId: '64f0555a5fc3a00012669553',
            coachName: '강사3',
            coachProfile: '',
            totalScheduleCount: 12,
            completeScheduleCount: 12,
            interval: 0,
        },
        {
            coachId: '658147f00f42a1001977b5b8',
            coachName: '강사4',
            coachProfile: '',
            totalScheduleCount: 17,
            completeScheduleCount: 12,
            interval: 5,
        },
        {
            coachId: '65814845ca42a00012a2d5d9',
            coachName: '강사5',
            coachProfile: '',
            totalScheduleCount: 15,
            completeScheduleCount: 11,
            interval: 4,
        },
        {
            coachId: '658148a90f42a1001977b739',
            coachName: '강사6',
            coachProfile: '',
            totalScheduleCount: 18,
            completeScheduleCount: 14,
            interval: 4,
        },
        {
            coachId: '658148cb0f42a1001977b78a',
            coachName: '강사7',
            coachProfile: '',
            totalScheduleCount: 21,
            completeScheduleCount: 16,
            interval: 5,
        },
        {
            coachId: '658149460f42a1001977b891',
            coachName: '강사8',
            coachProfile: '',
            totalScheduleCount: 30,
            completeScheduleCount: 16,
            interval: 14,
        },
        {
            coachId: '64f03dca5fc3a000126675a3',
            coachName: '강사9',
            coachProfile: '',
            totalScheduleCount: 40,
            completeScheduleCount: 18,
            interval: 22,
        },
        {
            coachId: '65814bb20f42a1001977b9e0',
            coachName: '강사10',
            coachProfile: '',
            totalScheduleCount: 19,
            completeScheduleCount: 13,
            interval: 6,
        },
        {
            coachId: '658149460f42a1001977b891',
            coachName: '강사11',
            coachProfile: '',
            totalScheduleCount: 22,
            completeScheduleCount: 6,
            interval: 16,
        },
        {
            coachId: '64f03dca5fc3a000126675a3',
            coachName: '강사12',
            coachProfile: '',
            totalScheduleCount: 36,
            completeScheduleCount: 18,
            interval: 18,
        },
        {
            coachId: '65814bb20f42a1001977b9e0',
            coachName: '강사13',
            coachProfile: '',
            totalScheduleCount: 19,
            completeScheduleCount: 13,
            interval: 16,
        },
    ],
    thisMonth: [
        {
            coachId: '6465c1a6090e920012f138eb',
            coachName: '강사1',
            coachProfile: '',
            totalScheduleCount: 70,
            completeScheduleCount: 70,
            interval: 5,
        },
        {
            coachId: '64646db825a9060012a76ad5',
            coachName: '강사2',
            coachProfile: '',
            totalScheduleCount: 86,
            completeScheduleCount: 86,
            interval: 3,
        },
        {
            coachId: '64f0555a5fc3a00012669553',
            coachName: '강사3',
            coachProfile: '',
            totalScheduleCount: 12,
            completeScheduleCount: 34,
            interval: 0,
        },
        {
            coachId: '658147f00f42a1001977b5b8',
            coachName: '강사4',
            coachProfile: '',
            totalScheduleCount: 17,
            completeScheduleCount: 48,
            interval: 5,
        },
        {
            coachId: '65814845ca42a00012a2d5d9',
            coachName: '강사5',
            coachProfile: '',
            totalScheduleCount: 15,
            completeScheduleCount: 72,
            interval: 4,
        },
        {
            coachId: '658148a90f42a1001977b739',
            coachName: '강사6',
            coachProfile: '',
            totalScheduleCount: 18,
            completeScheduleCount: 90,
            interval: 4,
        },
        {
            coachId: '658148cb0f42a1001977b78a',
            coachName: '강사7',
            coachProfile: '',
            totalScheduleCount: 21,
            completeScheduleCount: 120,
            interval: 5,
        },
        {
            coachId: '658149460f42a1001977b891',
            coachName: '강사8',
            coachProfile: '',
            totalScheduleCount: 30,
            completeScheduleCount: 16,
            interval: 14,
        },
        {
            coachId: '64f03dca5fc3a000126675a3',
            coachName: '강사9',
            coachProfile: '',
            totalScheduleCount: 40,
            completeScheduleCount: 99,
            interval: 22,
        },
        {
            coachId: '65814bb20f42a1001977b9e0',
            coachName: '강사10',
            coachProfile: '',
            totalScheduleCount: 19,
            completeScheduleCount: 27,
            interval: 6,
        },
        {
            coachId: '658149460f42a1001977b891',
            coachName: '강사11',
            coachProfile: '',
            totalScheduleCount: 22,
            completeScheduleCount: 106,
            interval: 16,
        },
        {
            coachId: '64f03dca5fc3a000126675a3',
            coachName: '강사12',
            coachProfile: '',
            totalScheduleCount: 36,
            completeScheduleCount: 118,
            interval: 18,
        },
        {
            coachId: '65814bb20f42a1001977b9e0',
            coachName: '강사13',
            coachProfile: '',
            totalScheduleCount: 19,
            completeScheduleCount: 73,
            interval: 16,
        },
    ],
    thisDayTotalCount: 100,
    thisWeekTotalCount: 285,
    thisMonthTotalCount: 959,
    thisDayMaxCount: 10,
    thisWeekMaxCount: 40,
    thisMonthMaxCount: 100,
};

// 메인페이지 > 회원권 등록 더미데이터
export let dashBoardMemberShipProgressDummyData = {
    success: true,
    thisDay: [
        {
            coachId: '6465c1a6090e920012f138eb',
            coachName: '강사1',
            coachProfile: '',
            newMemberCount: 3,
            reRegisteredMemberCount: 1,
        },
        {
            coachId: '64646db825a9060012a76ad5',
            coachName: '강사2',
            coachProfile: '',
            newMemberCount: 2,
            reRegisteredMemberCount: 0,
        },
        {
            coachId: '64f0555a5fc3a00012669553',
            coachName: '강사3',
            coachProfile: '',
            newMemberCount: 5,
            reRegisteredMemberCount: 2,
        },
        {
            coachId: '658147f00f42a1001977b5b8',
            coachName: '강사4',
            coachProfile: '',
            newMemberCount: 2,
            reRegisteredMemberCount: 2,
        },
        {
            coachId: '65814845ca42a00012a2d5d9',
            coachName: '강사5',
            coachProfile: '',
            newMemberCount: 0,
            reRegisteredMemberCount: 0,
        },
        {
            coachId: '658148a90f42a1001977b739',
            coachName: '강사6',
            coachProfile: '',
            newMemberCount: 0,
            reRegisteredMemberCount: 0,
        },
        {
            coachId: '658148cb0f42a1001977b78a',
            coachName: '강사7',
            coachProfile: '',
            newMemberCount: 1,
            reRegisteredMemberCount: 1,
        },
        {
            coachId: '658149460f42a1001977b891',
            coachName: '강사8',
            coachProfile: '',
            newMemberCount: 7,
            reRegisteredMemberCount: 2,
        },
        {
            coachId: '64f03dca5fc3a000126675a3',
            coachName: '강사9',
            newMemberCount: 3,
            reRegisteredMemberCount: 3,
        },
        {
            coachId: '65814bb20f42a1001977b9e0',
            coachName: '강사10',
            coachProfile: '',
            newMemberCount: 2,
            reRegisteredMemberCount: 0,
        },
        {
            coachId: '65814c0aca42a00012a2da50',
            coachName: '강사11',
            coachProfile: '',
            newMemberCount: 0,
            reRegisteredMemberCount: 4,
        },
        {
            coachId: '65814c69ca42a00012a2dabc',
            coachName: '강사12',
            coachProfile: '',
            newMemberCount: 0,
            reRegisteredMemberCount: 8,
        },
        {
            coachId: '65814cf9ca42a00012a2dc46',
            coachName: '강사13',
            coachProfile: '',
            newMemberCount: 10,
            reRegisteredMemberCount: 10,
        },
    ],
    thisWeek: [
        {
            coachId: '6465c1a6090e920012f138eb',
            coachName: '강사1',
            coachProfile: '',
            newMemberCount: 5,
            reRegisteredMemberCount: 2,
        },
        {
            coachId: '64646db825a9060012a76ad5',
            coachName: '강사2',
            coachProfile: '',
            newMemberCount: 10,
            reRegisteredMemberCount: 6,
        },
        {
            coachId: '64f0555a5fc3a00012669553',
            coachName: '강사3',
            coachProfile: '',
            newMemberCount: 4,
            reRegisteredMemberCount: 3,
        },
        {
            coachId: '658147f00f42a1001977b5b8',
            coachName: '강사4',
            coachProfile: '',
            newMemberCount: 7,
            reRegisteredMemberCount: 8,
        },
        {
            coachId: '65814845ca42a00012a2d5d9',
            coachName: '강사5',
            coachProfile: '',
            newMemberCount: 8,
            reRegisteredMemberCount: 2,
        },
        {
            coachId: '658148a90f42a1001977b739',
            coachName: '강사6',
            coachProfile: '',
            newMemberCount: 2,
            reRegisteredMemberCount: 8,
        },
        {
            coachId: '658148cb0f42a1001977b78a',
            coachName: '강사7',
            coachProfile: '',
            newMemberCount: 3,
            reRegisteredMemberCount: 10,
        },
        {
            coachId: '658149460f42a1001977b891',
            coachName: '강사8',
            coachProfile: '',
            newMemberCount: 13,
            reRegisteredMemberCount: 0,
        },
        {
            coachId: '64f03dca5fc3a000126675a3',
            coachName: '강사9',
            coachProfile: '',
            newMemberCount: 8,
            reRegisteredMemberCount: 0,
        },
        {
            coachId: '65814bb20f42a1001977b9e0',
            coachName: '강사10',
            coachProfile: '',
            newMemberCount: 2,
            reRegisteredMemberCount: 17,
        },
        {
            coachId: '65814c0aca42a00012a2da50',
            coachName: '강사11',
            coachProfile: '',
            newMemberCount: 5,
            reRegisteredMemberCount: 4,
        },
        {
            coachId: '65814c69ca42a00012a2dabc',
            coachName: '강사12',
            coachProfile: '',
            newMemberCount: 6,
            reRegisteredMemberCount: 9,
        },
        {
            coachId: '65814cf9ca42a00012a2dc46',
            coachName: '강사13',
            coachProfile: '',
            newMemberCount: 2,
            reRegisteredMemberCount: 8,
        },
    ],
    thisMonth: [
        {
            coachId: '6465c1a6090e920012f138eb',
            coachName: '강사1',
            coachProfile: '',
            newMemberCount: 16,
            reRegisteredMemberCount: 13,
        },
        {
            coachId: '64646db825a9060012a76ad5',
            coachName: '강사2',
            coachProfile: '',
            newMemberCount: 25,
            reRegisteredMemberCount: 30,
        },
        {
            coachId: '64f0555a5fc3a00012669553',
            coachName: '강사3',
            coachProfile: '',
            newMemberCount: 19,
            reRegisteredMemberCount: 3,
        },
        {
            coachId: '658147f00f42a1001977b5b8',
            coachName: '강사4',
            coachProfile: '',
            newMemberCount: 25,
            reRegisteredMemberCount: 25,
        },
        {
            coachId: '65814845ca42a00012a2d5d9',
            coachName: '강사5',
            coachProfile: '',
            newMemberCount: 32,
            reRegisteredMemberCount: 8,
        },
        {
            coachId: '658148a90f42a1001977b739',
            coachName: '강사6',
            coachProfile: '',
            newMemberCount: 23,
            reRegisteredMemberCount: 11,
        },
        {
            coachId: '658148cb0f42a1001977b78a',
            coachName: '강사7',
            coachProfile: '',
            newMemberCount: 29,
            reRegisteredMemberCount: 1,
        },
        {
            coachId: '658149460f42a1001977b891',
            coachName: '강사8',
            coachProfile: '',
            newMemberCount: 3,
            reRegisteredMemberCount: 19,
        },
        {
            coachId: '64f03dca5fc3a000126675a3',
            coachName: '강사9',
            coachProfile: '',
            newMemberCount: 7,
            reRegisteredMemberCount: 13,
        },
        {
            coachId: '65814bb20f42a1001977b9e0',
            coachName: '강사10',
            coachProfile: '',
            newMemberCount: 3,
            reRegisteredMemberCount: 4,
        },
        {
            coachId: '65814c0aca42a00012a2da50',
            coachName: '강사11',
            coachProfile: '',
            newMemberCount: 7,
            reRegisteredMemberCount: 8,
        },
        {
            coachId: '65814c69ca42a00012a2dabc',
            coachName: '강사12',
            coachProfile: '',
            newMemberCount: 11,
            reRegisteredMemberCount: 13,
        },
        {
            coachId: '65814cf9ca42a00012a2dc46',
            coachName: '강사13',
            coachProfile: '',
            newMemberCount: 4,
            reRegisteredMemberCount: 5,
        },
    ],
    thisDayMaxCount: 10,
    thisWeekMaxCount: 18,
    thisMonthMaxCount: 40,
};

// 스케줄관리 > 변경/취소 > 당일 취소 및 변경 리스트
export let scheduleHistoryCancelAndChangeDummyData = {
    success: true,
    thisDay: [
        {
            _id: '6583c353dd7b690019191837',
            isDeleted: false,
            scheduleId: '6583c34bdd7b69001919180a',
            adjustmentType: 'coachTodayCancel',
            memberId: '64ef20624f1ef10019211313',
            coachId: '64f0555a5fc3a00012669553',
            memberName: '회원1',
            coachName: '강사1',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 4,
            totalSession: 30,
            startTime: '2023-12-21T06:00:00.000Z',
            endTime: '2023-12-21T06:30:00.000Z',
            adjustTime: '2023-12-21T04:47:15.887Z',
            reason: '개인적인 사유',
            cancelCount: 2,
            createdAt: '2023-12-21T04:47:15.917Z',
            updatedAt: '2023-12-21T04:47:15.917Z',
            __v: 0,
        },
        {
            _id: '6583c34fdd7b690019191821',
            isDeleted: false,
            scheduleId: '6583c346dd7b6900191917f8',
            adjustmentType: 'coachTodayChange',
            memberId: '64ef20624f1ef10019211313',
            coachId: '64f0555a5fc3a00012669553',
            memberName: '회원2',
            coachName: '강사2',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 4,
            totalSession: 30,
            startTime: '2023-12-21T14:00:00.000Z',
            endTime: '2023-12-21T14:50:00.000Z',
            changedStartTime: '2023-12-27T01:00:00.000Z',
            changedEndTime: '2023-12-27T01:50:00.000Z',
            adjustTime: '2023-12-21T13:33:33.964Z',

            reason: '개인적인 사유',
            cancelCount: 2,
            createdAt: '2023-12-21T04:47:11.493Z',
            updatedAt: '2023-12-21T04:47:11.493Z',
            __v: 0,
        },
        {
            _id: '6583c32add7b6900191917e2',
            isDeleted: false,
            scheduleId: '657fe15b864d4d00125ebfb5',
            adjustmentType: 'coachTodayCancel',
            memberId: '64ef20624f1ef10019211313',
            coachId: '64f0555a5fc3a00012669553',
            memberName: '회원3',
            coachName: '강사3',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 4,
            totalSession: 30,
            startTime: '2023-12-21T05:00:00.000Z',
            endTime: '2023-12-21T05:30:00.000Z',
            adjustTime: '2023-12-21T04:46:34.278Z',
            reason: '개인적인 사유',
            cancelCount: 1,
            createdAt: '2023-12-21T04:46:34.309Z',
            updatedAt: '2023-12-21T04:46:34.309Z',
            __v: 0,
        },
    ],
    thisWeek: [
        {
            _id: '6583c353dd7b690019191837',
            isDeleted: false,
            scheduleId: '6583c34bdd7b69001919180a',
            adjustmentType: 'coachTodayCancel',
            memberId: '64ef20624f1ef10019211313',
            coachId: '64f0555a5fc3a00012669553',
            memberName: '회원1',
            coachName: '강사1',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 4,
            totalSession: 30,
            startTime: '2023-12-21T06:00:00.000Z',
            endTime: '2023-12-21T06:30:00.000Z',
            adjustTime: '2023-12-21T04:47:15.887Z',
            reason: '개인적인 사유',
            cancelCount: 2,
            createdAt: '2023-12-21T04:47:15.917Z',
            updatedAt: '2023-12-21T04:47:15.917Z',
            __v: 0,
        },
        {
            _id: '6583c34fdd7b690019191821',
            isDeleted: false,
            scheduleId: '6583c346dd7b6900191917f8',
            adjustmentType: 'coachTodayChange',
            memberId: '64ef20624f1ef10019211313',
            coachId: '64f0555a5fc3a00012669553',
            memberName: '회원2',
            coachName: '강사2',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 4,
            totalSession: 30,
            startTime: '2023-12-21T14:00:00.000Z',
            endTime: '2023-12-21T14:50:00.000Z',
            changedStartTime: '2023-12-27T01:00:00.000Z',
            changedEndTime: '2023-12-27T01:50:00.000Z',
            adjustTime: '2023-12-21T13:33:33.964Z',
            reason: '개인적인 사유',
            cancelCount: 2,
            createdAt: '2023-12-21T04:47:11.493Z',
            updatedAt: '2023-12-21T04:47:11.493Z',
            __v: 0,
        },
        {
            _id: '6583c32add7b6900191917e2',
            isDeleted: false,
            scheduleId: '657fe15b864d4d00125ebfb5',
            adjustmentType: 'coachTodayCancel',
            memberId: '64ef20624f1ef10019211313',
            coachId: '64f0555a5fc3a00012669553',
            memberName: '회원3',
            coachName: '강사3',
            memberProfileImageUrl: 'http://k.kakaocdn.net/dn/dpk9l1/btqmGhA2lKL/Oz0wDuJn1YV2DIn92f6DVK/img_640x640.jpg',
            membershipCount: 1,
            currentSession: 4,
            totalSession: 30,
            startTime: '2023-12-21T05:00:00.000Z',
            endTime: '2023-12-21T05:30:00.000Z',
            adjustTime: '2023-12-21T04:46:34.278Z',
            reason: '개인적인 사유',
            cancelCount: 1,
            createdAt: '2023-12-21T04:46:34.309Z',
            updatedAt: '2023-12-21T04:46:34.309Z',
            __v: 0,
        },
    ],
    thisMonth: [
        {
            _id: '6583c353dd7b690019191837',
            isDeleted: false,
            scheduleId: '6583c34bdd7b69001919180a',
            adjustmentType: 'coachTodayCancel',
            memberId: '64ef20624f1ef10019211313',
            coachId: '64f0555a5fc3a00012669553',
            memberName: '회원1',
            coachName: '강사1',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 4,
            totalSession: 30,
            startTime: '2023-12-21T06:00:00.000Z',
            endTime: '2023-12-21T06:30:00.000Z',
            adjustTime: '2023-12-21T04:47:15.887Z',
            reason: '개인적인 사유',
            cancelCount: 2,
            createdAt: '2023-12-21T04:47:15.917Z',
            updatedAt: '2023-12-21T04:47:15.917Z',
            __v: 0,
        },
        {
            _id: '6583c34fdd7b690019191821',
            isDeleted: false,
            scheduleId: '6583c346dd7b6900191917f8',
            adjustmentType: 'coachTodayCancel',
            memberId: '64ef20624f1ef10019211313',
            coachId: '64f0555a5fc3a00012669553',
            memberName: '회원2',
            coachName: '강사2',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 4,
            totalSession: 30,
            startTime: '2023-12-21T05:00:00.000Z',
            endTime: '2023-12-21T05:30:00.000Z',
            adjustTime: '2023-12-21T04:47:11.479Z',
            reason: '개인적인 사유',
            cancelCount: 2,
            createdAt: '2023-12-21T04:47:11.493Z',
            updatedAt: '2023-12-21T04:47:11.493Z',
            __v: 0,
        },
        {
            _id: '6583c32add7b6900191917e2',
            isDeleted: false,
            scheduleId: '657fe15b864d4d00125ebfb5',
            adjustmentType: 'coachTodayCancel',
            memberId: '64ef20624f1ef10019211313',
            coachId: '64f0555a5fc3a00012669553',
            memberName: '회원3',
            coachName: '강사3',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 4,
            totalSession: 30,
            startTime: '2023-12-21T05:00:00.000Z',
            endTime: '2023-12-21T05:30:00.000Z',
            adjustTime: '2023-12-21T04:46:34.278Z',
            reason: '개인적인 사유',
            cancelCount: 1,
            createdAt: '2023-12-21T04:46:34.309Z',
            updatedAt: '2023-12-21T04:46:34.309Z',
            __v: 0,
        },
        {
            _id: '6579ec58c42d260012d3216c',
            isDeleted: false,
            scheduleId: '657988de7b163c0012f154b6',
            adjustmentType: 'coachTodayCancel',
            memberId: '6577b1bbe537480019d73b70',
            coachId: '6465c1a6090e920012f138eb',
            memberName: '회원4',
            coachName: '강사4',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 3,
            totalSession: 30,
            startTime: '2023-12-14T00:00:00.000Z',
            endTime: '2023-12-14T00:50:00.000Z',
            adjustTime: '2023-12-13T17:39:36.910Z',
            reason: '개인적인 사유',
            cancelCount: 2,
            createdAt: '2023-12-13T17:39:36.931Z',
            updatedAt: '2023-12-13T17:39:36.931Z',
            __v: 0,
        },
        {
            _id: '6579b2ad72f00b0019abb76b',
            isDeleted: false,
            scheduleId: '6579b25672f00b0019abb71e',
            adjustmentType: 'coachTodayChange',
            memberId: '646b231a0cced2001964bbb6',
            coachId: '64646db825a9060012a76ad5',
            memberName: '회원5',
            coachName: '강사5',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 8,
            totalSession: 30,
            startTime: '2023-12-13T14:00:00.000Z',
            endTime: '2023-12-13T14:50:00.000Z',
            changedStartTime: '2023-12-15T01:00:00.000Z',
            changedEndTime: '2023-12-15T01:50:00.000Z',
            adjustTime: '2023-12-13T13:33:33.964Z',
            reason: '개인적인 사유',
            cancelCount: 2,
            createdAt: '2023-12-13T13:33:33.993Z',
            updatedAt: '2023-12-13T13:33:33.993Z',
            __v: 0,
        },
        {
            _id: '65799ca5267e5a0019baf63e',
            isDeleted: false,
            scheduleId: '65799c9d267e5a0019baf629',
            adjustmentType: 'coachTodayCancel',
            memberId: '6577b1bbe537480019d73b70',
            coachId: '6465c1a6090e920012f138eb',
            memberName: '회원6',
            coachName: '강사6',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 3,
            totalSession: 30,
            startTime: '2023-12-13T11:00:00.000Z',
            endTime: '2023-12-13T11:50:00.000Z',
            adjustTime: '2023-12-13T11:59:33.589Z',
            reason: '개인적인 사유',
            cancelCount: 2,
            createdAt: '2023-12-13T11:59:33.630Z',
            updatedAt: '2023-12-13T11:59:33.630Z',
            __v: 0,
        },
        {
            _id: '65798aaa7b163c0012f157de',
            isDeleted: false,
            scheduleId: '657850b286c4b50012ee8043',
            adjustmentType: 'coachTodayChange',
            memberId: '646b231a0cced2001964bbb6',
            coachId: '64646db825a9060012a76ad5',
            memberName: '회원7',
            coachName: '강사7',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 1,
            totalSession: 30,
            startTime: '2023-12-13T09:00:00.000Z',
            endTime: '2023-12-13T10:30:00.000Z',
            changedStartTime: '2023-12-15T09:00:00.000Z',
            changedEndTime: '2023-12-15T09:50:00.000Z',
            adjustTime: '2023-12-13T10:42:50.446Z',
            reason: '개인적인 사유',
            cancelCount: 1,
            createdAt: '2023-12-13T10:42:50.477Z',
            updatedAt: '2023-12-13T10:42:50.477Z',
            __v: 0,
        },
        {
            _id: '657988ba7b163c0012f15312',
            isDeleted: false,
            scheduleId: '657988b07b163c0012f152c4',
            adjustmentType: 'coachTodayChange',
            memberId: '64f0404f2164f3001915bdf6',
            coachId: '64f0555a5fc3a00012669553',
            memberName: '회원8',
            coachName: '강사8',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 2,
            totalSession: 20,
            startTime: '2023-12-13T06:00:00.000Z',
            endTime: '2023-12-13T07:30:00.000Z',
            changedStartTime: '2023-12-14T06:00:00.000Z',
            changedEndTime: '2023-12-14T07:30:00.000Z',
            adjustTime: '2023-12-13T10:34:34.591Z',
            reason: '개인적인 사유',
            cancelCount: 1,
            createdAt: '2023-12-13T10:34:34.617Z',
            updatedAt: '2023-12-13T10:34:34.617Z',
            __v: 0,
        },
        {
            _id: '657988aa7b163c0012f15292',
            isDeleted: false,
            scheduleId: '657915f4d48bdb001295a050',
            adjustmentType: 'coachTodayCancel',
            memberId: '64f0404f2164f3001915bdf6',
            coachId: '64f0555a5fc3a00012669553',
            memberName: '회원9',
            coachName: '강사9',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 2,
            totalSession: 20,
            startTime: '2023-12-13T05:00:00.000Z',
            endTime: '2023-12-13T05:50:00.000Z',
            adjustTime: '2023-12-13T10:34:18.762Z',
            reason: '개인적인 사유',
            cancelCount: 2,
            createdAt: '2023-12-13T10:34:18.789Z',
            updatedAt: '2023-12-13T10:34:18.789Z',
            __v: 0,
        },
        {
            _id: '6579880b7b163c0012f14f7f',
            isDeleted: false,
            scheduleId: '657821751fbfed001986f2a3',
            adjustmentType: 'coachTodayCancel',
            memberId: '656ea6c23dfbad0019109c0e',
            coachId: '6465c1a6090e920012f138eb',
            memberName: '회원10',
            coachName: '강사10',
            memberProfileImageUrl: '',
            membershipCount: 0,
            currentSession: 0,
            totalSession: 0,
            startTime: '2023-12-13T09:00:00.000Z',
            endTime: '2023-12-13T09:50:00.000Z',
            adjustTime: '2023-12-13T10:31:39.136Z',
            reason: '개인적인 사유',
            cancelCount: 2,
            createdAt: '2023-12-13T10:31:39.166Z',
            updatedAt: '2023-12-13T10:31:39.166Z',
            __v: 0,
        },
        {
            _id: '657938d7d48bdb001295b202',
            isDeleted: false,
            scheduleId: '6579379ad48bdb001295b0c7',
            adjustmentType: 'coachTodayCancel',
            memberId: '6577b1bbe537480019d73b70',
            coachId: '6465c1a6090e920012f138eb',
            memberName: '회원11',
            coachName: '강사11',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 3,
            totalSession: 30,
            startTime: '2023-12-13T02:00:00.000Z',
            endTime: '2023-12-13T02:50:00.000Z',
            adjustTime: '2023-12-13T04:53:43.198Z',
            reason: '개인적인 사유',
            cancelCount: 1,
            createdAt: '2023-12-13T04:53:43.229Z',
            updatedAt: '2023-12-13T04:53:43.229Z',
            __v: 0,
        },
        {
            _id: '657863ce7697200019aba4c1',
            isDeleted: false,
            scheduleId: '65785d267697200019ab9efd',
            adjustmentType: 'coachTodayChange',
            memberId: '6577b1bbe537480019d73b70',
            coachId: '6465c1a6090e920012f138eb',
            memberName: '회원12',
            coachName: '강사12',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 1,
            totalSession: 30,
            startTime: '2023-12-12T11:00:00.000Z',
            endTime: '2023-12-12T11:50:00.000Z',
            changedStartTime: '2023-12-12T12:00:00.000Z',
            changedEndTime: '2023-12-12T12:50:00.000Z',
            adjustTime: '2023-12-12T13:44:46.423Z',
            reason: '개인적인 사유',
            cancelCount: 1,
            createdAt: '2023-12-12T13:44:46.470Z',
            updatedAt: '2023-12-12T13:44:46.470Z',
            __v: 0,
        },
        {
            _id: '6578358754b44300121a10bf',
            isDeleted: false,
            scheduleId: '657830fd54b44300121a0f54',
            adjustmentType: 'coachTodayChange',
            memberId: '656ea6c23dfbad0019109c0e',
            coachId: '6465c1a6090e920012f138eb',
            memberName: '회원13',
            coachName: '강사2',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 1,
            totalSession: 30,
            startTime: '2023-12-12T09:00:00.000Z',
            endTime: '2023-12-12T09:50:00.000Z',
            changedStartTime: '2023-12-15T09:00:00.000Z',
            changedEndTime: '2023-12-15T09:50:00.000Z',
            adjustTime: '2023-12-12T10:27:19.057Z',
            reason: '개인적인 사유',
            cancelCount: 2,
            createdAt: '2023-12-12T10:27:19.105Z',
            updatedAt: '2023-12-12T10:27:19.105Z',
            __v: 0,
        },
        {
            _id: '657821751fbfed001986f2a8',
            isDeleted: false,
            scheduleId: '6578192279adb50012deb035',
            adjustmentType: 'coachTodayChange',
            memberId: '656ea6c23dfbad0019109c0e',
            coachId: '6465c1a6090e920012f138eb',
            memberName: '회원13',
            coachName: '강사3',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 1,
            totalSession: 30,
            startTime: '2023-12-12T09:00:00.000Z',
            endTime: '2023-12-12T09:50:00.000Z',
            changedStartTime: '2023-12-13T09:00:00.000Z',
            changedEndTime: '2023-12-13T09:50:00.000Z',
            adjustTime: '2023-12-12T09:01:41.180Z',
            reason: '개인적인 사유',
            cancelCount: 1,
            createdAt: '2023-12-12T09:01:41.220Z',
            updatedAt: '2023-12-12T09:01:41.220Z',
            __v: 0,
        },
        {
            _id: '657821571fbfed001986f20c',
            isDeleted: false,
            scheduleId: '6577c643bdb2e0001203631d',
            adjustmentType: 'coachTodayChange',
            memberId: '646b0d58755c9f00126dba42',
            coachId: '6465c1a6090e920012f138eb',
            memberName: '회원3',
            coachName: '강사3',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 4,
            totalSession: 30,
            startTime: '2023-12-12T06:00:00.000Z',
            endTime: '2023-12-12T06:50:00.000Z',
            changedStartTime: '2023-12-28T06:00:00.000Z',
            changedEndTime: '2023-12-28T06:50:00.000Z',
            adjustTime: '2023-12-12T09:01:11.703Z',
            reason: '개인적인 사유',
            cancelCount: 1,
            createdAt: '2023-12-12T09:01:11.756Z',
            updatedAt: '2023-12-12T09:01:11.756Z',
            __v: 0,
        },
        {
            _id: '65781a0e1fbfed001986f00a',
            isDeleted: false,
            scheduleId: '657819951fbfed001986efba',
            adjustmentType: 'coachTodayCancel',
            memberId: '64f0404f2164f3001915bdf6',
            coachId: '64f0555a5fc3a00012669553',
            memberName: '회원7',
            coachName: '강사7',
            memberProfileImageUrl: '',
            membershipCount: 0,
            currentSession: 0,
            totalSession: 0,
            startTime: '2023-12-12T08:00:00.000Z',
            endTime: '2023-12-12T08:50:00.000Z',
            adjustTime: '2023-12-12T08:30:06.062Z',
            reason: '개인적인 사유',
            cancelCount: 2,
            createdAt: '2023-12-12T08:30:06.101Z',
            updatedAt: '2023-12-12T08:30:06.101Z',
            __v: 0,
        },
        {
            _id: '65781a081fbfed001986eff6',
            isDeleted: false,
            scheduleId: '6578198e1fbfed001986efa8',
            adjustmentType: 'coachTodayCancel',
            memberId: '64f0404f2164f3001915bdf6',
            coachId: '64f0555a5fc3a00012669553',
            memberName: '회원8',
            coachName: '강사8',
            memberProfileImageUrl: '',
            membershipCount: 0,
            currentSession: 0,
            totalSession: 0,
            startTime: '2023-12-12T07:00:00.000Z',
            endTime: '2023-12-12T07:50:00.000Z',
            adjustTime: '2023-12-12T08:30:00.780Z',
            reason: '개인적인 사유',
            cancelCount: 1,
            createdAt: '2023-12-12T08:30:00.795Z',
            updatedAt: '2023-12-12T08:30:00.795Z',
            __v: 0,
        },
        {
            _id: '657818ee1fbfed001986ef18',
            isDeleted: false,
            scheduleId: '657818e71fbfed001986ef03',
            adjustmentType: 'coachTodayCancel',
            memberId: '656ea6c23dfbad0019109c0e',
            coachId: '6465c1a6090e920012f138eb',
            memberName: '회원4',
            coachName: '강사4',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 1,
            totalSession: 30,
            startTime: '2023-12-12T09:00:00.000Z',
            endTime: '2023-12-12T09:50:00.000Z',
            adjustTime: '2023-12-12T08:25:18.040Z',
            reason: '개인적인 사유',
            cancelCount: 1,
            createdAt: '2023-12-12T08:25:18.058Z',
            updatedAt: '2023-12-12T08:25:18.058Z',
            __v: 0,
        },
        {
            _id: '657818cc1fbfed001986eed7',
            isDeleted: false,
            scheduleId: '6577c6b6bdb2e00012036392',
            adjustmentType: 'coachTodayCancel',
            memberId: '646b0d58755c9f00126dba42',
            coachId: '6465c1a6090e920012f138eb',
            memberName: '회원6',
            coachName: '강사6',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 5,
            totalSession: 30,
            startTime: '2023-12-12T07:00:00.000Z',
            endTime: '2023-12-12T07:50:00.000Z',
            adjustTime: '2023-12-12T08:24:44.555Z',
            reason: '개인적인 사유',
            cancelCount: 1,
            createdAt: '2023-12-12T08:24:44.583Z',
            updatedAt: '2023-12-12T08:24:44.583Z',
            __v: 0,
        },
    ],
};

// 스케줄관리 > 변경/취소 > 회원의 수업 취소
export let scheduleHistoryMemberCancelDummyData = {
    success: true,
    thisDay: [
        {
            _id: '6583c353dd7b690019191837',
            isDeleted: false,
            scheduleId: '6583c34bdd7b69001919180a',
            adjustmentType: 'coachTodayCancel',
            memberId: '64ef20624f1ef10019211313',
            coachId: '64f0555a5fc3a00012669553',
            memberName: '회원1',
            coachName: '강사1',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 4,
            totalSession: 30,
            startTime: '2023-12-21T06:00:00.000Z',
            endTime: '2023-12-21T06:30:00.000Z',
            adjustTime: '2023-12-21T04:47:15.887Z',
            reason: '개인적인 사유',
            cancelCount: 2,
            createdAt: '2023-12-21T04:47:15.917Z',
            updatedAt: '2023-12-21T04:47:15.917Z',
            __v: 0,
        },
        {
            _id: '6583c34fdd7b690019191821',
            isDeleted: false,
            scheduleId: '6583c346dd7b6900191917f8',
            adjustmentType: 'coachTodayCancel',
            memberId: '64ef20624f1ef10019211313',
            coachId: '64f0555a5fc3a00012669553',
            memberName: '회원2',
            coachName: '강사2',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 4,
            totalSession: 30,
            startTime: '2023-12-21T14:00:00.000Z',
            endTime: '2023-12-21T14:50:00.000Z',
            adjustTime: '2023-12-21T13:33:33.964Z',
            reason: '개인적인 사유',
            cancelCount: 2,
            createdAt: '2023-12-21T04:47:11.493Z',
            updatedAt: '2023-12-21T04:47:11.493Z',
            __v: 0,
        },
        {
            _id: '6583c32add7b6900191917e2',
            isDeleted: false,
            scheduleId: '657fe15b864d4d00125ebfb5',
            adjustmentType: 'coachTodayCancel',
            memberId: '64ef20624f1ef10019211313',
            coachId: '64f0555a5fc3a00012669553',
            memberName: '회원3',
            coachName: '강사3',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 4,
            totalSession: 30,
            startTime: '2023-12-21T05:00:00.000Z',
            endTime: '2023-12-21T05:30:00.000Z',
            adjustTime: '2023-12-21T04:46:34.278Z',
            reason: '개인적인 사유',
            cancelCount: 1,
            createdAt: '2023-12-21T04:46:34.309Z',
            updatedAt: '2023-12-21T04:46:34.309Z',
            __v: 0,
        },
    ],
    thisWeek: [
        {
            _id: '6583c353dd7b690019191837',
            isDeleted: false,
            scheduleId: '6583c34bdd7b69001919180a',
            adjustmentType: 'coachTodayCancel',
            memberId: '64ef20624f1ef10019211313',
            coachId: '64f0555a5fc3a00012669553',
            memberName: '회원1',
            coachName: '강사1',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 4,
            totalSession: 30,
            startTime: '2023-12-21T06:00:00.000Z',
            endTime: '2023-12-21T06:30:00.000Z',
            adjustTime: '2023-12-21T04:47:15.887Z',
            reason: '개인적인 사유',
            cancelCount: 2,
            createdAt: '2023-12-21T04:47:15.917Z',
            updatedAt: '2023-12-21T04:47:15.917Z',
            __v: 0,
        },
        {
            _id: '6583c34fdd7b690019191821',
            isDeleted: false,
            scheduleId: '6583c346dd7b6900191917f8',
            adjustmentType: 'coachTodayCancel',
            memberId: '64ef20624f1ef10019211313',
            coachId: '64f0555a5fc3a00012669553',
            memberName: '회원2',
            coachName: '강사2',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 4,
            totalSession: 30,
            startTime: '2023-12-21T14:00:00.000Z',
            endTime: '2023-12-21T14:50:00.000Z',
            adjustTime: '2023-12-21T13:33:33.964Z',
            reason: '개인적인 사유',
            cancelCount: 2,
            createdAt: '2023-12-21T04:47:11.493Z',
            updatedAt: '2023-12-21T04:47:11.493Z',
            __v: 0,
        },
        {
            _id: '6583c32add7b6900191917e2',
            isDeleted: false,
            scheduleId: '657fe15b864d4d00125ebfb5',
            adjustmentType: 'coachTodayCancel',
            memberId: '64ef20624f1ef10019211313',
            coachId: '64f0555a5fc3a00012669553',
            memberName: '회원3',
            coachName: '강사3',
            memberProfileImageUrl: 'http://k.kakaocdn.net/dn/dpk9l1/btqmGhA2lKL/Oz0wDuJn1YV2DIn92f6DVK/img_640x640.jpg',
            membershipCount: 1,
            currentSession: 4,
            totalSession: 30,
            startTime: '2023-12-21T05:00:00.000Z',
            endTime: '2023-12-21T05:30:00.000Z',
            adjustTime: '2023-12-21T04:46:34.278Z',
            reason: '개인적인 사유',
            cancelCount: 1,
            createdAt: '2023-12-21T04:46:34.309Z',
            updatedAt: '2023-12-21T04:46:34.309Z',
            __v: 0,
        },
    ],
    thisMonth: [
        {
            _id: '6583c353dd7b690019191837',
            isDeleted: false,
            scheduleId: '6583c34bdd7b69001919180a',
            adjustmentType: 'coachTodayCancel',
            memberId: '64ef20624f1ef10019211313',
            coachId: '64f0555a5fc3a00012669553',
            memberName: '회원1',
            coachName: '강사1',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 4,
            totalSession: 30,
            startTime: '2023-12-21T06:00:00.000Z',
            endTime: '2023-12-21T06:30:00.000Z',
            adjustTime: '2023-12-21T04:47:15.887Z',
            reason: '개인적인 사유',
            cancelCount: 2,
            createdAt: '2023-12-21T04:47:15.917Z',
            updatedAt: '2023-12-21T04:47:15.917Z',
            __v: 0,
        },
        {
            _id: '6583c34fdd7b690019191821',
            isDeleted: false,
            scheduleId: '6583c346dd7b6900191917f8',
            adjustmentType: 'coachTodayCancel',
            memberId: '64ef20624f1ef10019211313',
            coachId: '64f0555a5fc3a00012669553',
            memberName: '회원2',
            coachName: '강사2',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 4,
            totalSession: 30,
            startTime: '2023-12-21T05:00:00.000Z',
            endTime: '2023-12-21T05:30:00.000Z',
            adjustTime: '2023-12-21T04:47:11.479Z',
            reason: '개인적인 사유',
            cancelCount: 2,
            createdAt: '2023-12-21T04:47:11.493Z',
            updatedAt: '2023-12-21T04:47:11.493Z',
            __v: 0,
        },
        {
            _id: '6583c32add7b6900191917e2',
            isDeleted: false,
            scheduleId: '657fe15b864d4d00125ebfb5',
            adjustmentType: 'coachTodayCancel',
            memberId: '64ef20624f1ef10019211313',
            coachId: '64f0555a5fc3a00012669553',
            memberName: '회원3',
            coachName: '강사3',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 4,
            totalSession: 30,
            startTime: '2023-12-21T05:00:00.000Z',
            endTime: '2023-12-21T05:30:00.000Z',
            adjustTime: '2023-12-21T04:46:34.278Z',
            reason: '개인적인 사유',
            cancelCount: 1,
            createdAt: '2023-12-21T04:46:34.309Z',
            updatedAt: '2023-12-21T04:46:34.309Z',
            __v: 0,
        },
        {
            _id: '6579ec58c42d260012d3216c',
            isDeleted: false,
            scheduleId: '657988de7b163c0012f154b6',
            adjustmentType: 'coachTodayCancel',
            memberId: '6577b1bbe537480019d73b70',
            coachId: '6465c1a6090e920012f138eb',
            memberName: '회원4',
            coachName: '강사4',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 3,
            totalSession: 30,
            startTime: '2023-12-14T00:00:00.000Z',
            endTime: '2023-12-14T00:50:00.000Z',
            adjustTime: '2023-12-13T17:39:36.910Z',
            reason: '개인적인 사유',
            cancelCount: 2,
            createdAt: '2023-12-13T17:39:36.931Z',
            updatedAt: '2023-12-13T17:39:36.931Z',
            __v: 0,
        },
        {
            _id: '6579b2ad72f00b0019abb76b',
            isDeleted: false,
            scheduleId: '6579b25672f00b0019abb71e',
            adjustmentType: 'coachTodayCancel',
            memberId: '646b231a0cced2001964bbb6',
            coachId: '64646db825a9060012a76ad5',
            memberName: '회원5',
            coachName: '강사5',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 8,
            totalSession: 30,
            startTime: '2023-12-13T14:00:00.000Z',
            endTime: '2023-12-13T14:50:00.000Z',

            adjustTime: '2023-12-13T13:33:33.964Z',
            reason: '개인적인 사유',
            cancelCount: 2,
            createdAt: '2023-12-13T13:33:33.993Z',
            updatedAt: '2023-12-13T13:33:33.993Z',
            __v: 0,
        },
        {
            _id: '65799ca5267e5a0019baf63e',
            isDeleted: false,
            scheduleId: '65799c9d267e5a0019baf629',
            adjustmentType: 'coachTodayCancel',
            memberId: '6577b1bbe537480019d73b70',
            coachId: '6465c1a6090e920012f138eb',
            memberName: '회원6',
            coachName: '강사6',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 3,
            totalSession: 30,
            startTime: '2023-12-13T11:00:00.000Z',
            endTime: '2023-12-13T11:50:00.000Z',
            adjustTime: '2023-12-13T11:59:33.589Z',
            reason: '개인적인 사유',
            cancelCount: 2,
            createdAt: '2023-12-13T11:59:33.630Z',
            updatedAt: '2023-12-13T11:59:33.630Z',
            __v: 0,
        },
        {
            _id: '65798aaa7b163c0012f157de',
            isDeleted: false,
            scheduleId: '657850b286c4b50012ee8043',
            adjustmentType: 'coachTodayCancel',
            memberId: '646b231a0cced2001964bbb6',
            coachId: '64646db825a9060012a76ad5',
            memberName: '회원7',
            coachName: '강사7',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 1,
            totalSession: 30,
            startTime: '2023-12-13T09:00:00.000Z',
            endTime: '2023-12-13T10:30:00.000Z',

            adjustTime: '2023-12-13T10:42:50.446Z',
            reason: '개인적인 사유',
            cancelCount: 1,
            createdAt: '2023-12-13T10:42:50.477Z',
            updatedAt: '2023-12-13T10:42:50.477Z',
            __v: 0,
        },
        {
            _id: '657988ba7b163c0012f15312',
            isDeleted: false,
            scheduleId: '657988b07b163c0012f152c4',
            adjustmentType: 'coachTodayCancel',
            memberId: '64f0404f2164f3001915bdf6',
            coachId: '64f0555a5fc3a00012669553',
            memberName: '회원8',
            coachName: '강사8',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 2,
            totalSession: 20,
            startTime: '2023-12-13T06:00:00.000Z',
            endTime: '2023-12-13T07:30:00.000Z',
            adjustTime: '2023-12-13T10:34:34.591Z',
            reason: '개인적인 사유',
            cancelCount: 1,
            createdAt: '2023-12-13T10:34:34.617Z',
            updatedAt: '2023-12-13T10:34:34.617Z',
            __v: 0,
        },
        {
            _id: '657988aa7b163c0012f15292',
            isDeleted: false,
            scheduleId: '657915f4d48bdb001295a050',
            adjustmentType: 'coachTodayCancel',
            memberId: '64f0404f2164f3001915bdf6',
            coachId: '64f0555a5fc3a00012669553',
            memberName: '회원9',
            coachName: '강사9',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 2,
            totalSession: 20,
            startTime: '2023-12-13T05:00:00.000Z',
            endTime: '2023-12-13T05:50:00.000Z',
            adjustTime: '2023-12-13T10:34:18.762Z',
            reason: '개인적인 사유',
            cancelCount: 2,
            createdAt: '2023-12-13T10:34:18.789Z',
            updatedAt: '2023-12-13T10:34:18.789Z',
            __v: 0,
        },
        {
            _id: '6579880b7b163c0012f14f7f',
            isDeleted: false,
            scheduleId: '657821751fbfed001986f2a3',
            adjustmentType: 'coachTodayCancel',
            memberId: '656ea6c23dfbad0019109c0e',
            coachId: '6465c1a6090e920012f138eb',
            memberName: '회원10',
            coachName: '강사10',
            memberProfileImageUrl: '',
            membershipCount: 0,
            currentSession: 0,
            totalSession: 0,
            startTime: '2023-12-13T09:00:00.000Z',
            endTime: '2023-12-13T09:50:00.000Z',
            adjustTime: '2023-12-13T10:31:39.136Z',
            reason: '개인적인 사유',
            cancelCount: 2,
            createdAt: '2023-12-13T10:31:39.166Z',
            updatedAt: '2023-12-13T10:31:39.166Z',
            __v: 0,
        },
        {
            _id: '657938d7d48bdb001295b202',
            isDeleted: false,
            scheduleId: '6579379ad48bdb001295b0c7',
            adjustmentType: 'coachTodayCancel',
            memberId: '6577b1bbe537480019d73b70',
            coachId: '6465c1a6090e920012f138eb',
            memberName: '회원11',
            coachName: '강사11',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 3,
            totalSession: 30,
            startTime: '2023-12-13T02:00:00.000Z',
            endTime: '2023-12-13T02:50:00.000Z',
            adjustTime: '2023-12-13T04:53:43.198Z',
            reason: '개인적인 사유',
            cancelCount: 1,
            createdAt: '2023-12-13T04:53:43.229Z',
            updatedAt: '2023-12-13T04:53:43.229Z',
            __v: 0,
        },
        {
            _id: '657863ce7697200019aba4c1',
            isDeleted: false,
            scheduleId: '65785d267697200019ab9efd',
            adjustmentType: 'coachTodayCancel',
            memberId: '6577b1bbe537480019d73b70',
            coachId: '6465c1a6090e920012f138eb',
            memberName: '회원12',
            coachName: '강사12',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 1,
            totalSession: 30,
            startTime: '2023-12-12T11:00:00.000Z',
            endTime: '2023-12-12T11:50:00.000Z',
            adjustTime: '2023-12-12T13:44:46.423Z',
            reason: '개인적인 사유',
            cancelCount: 1,
            createdAt: '2023-12-12T13:44:46.470Z',
            updatedAt: '2023-12-12T13:44:46.470Z',
            __v: 0,
        },
        {
            _id: '6578358754b44300121a10bf',
            isDeleted: false,
            scheduleId: '657830fd54b44300121a0f54',
            adjustmentType: 'coachTodayCancel',
            memberId: '656ea6c23dfbad0019109c0e',
            coachId: '6465c1a6090e920012f138eb',
            memberName: '회원13',
            coachName: '강사2',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 1,
            totalSession: 30,
            startTime: '2023-12-12T09:00:00.000Z',
            endTime: '2023-12-12T09:50:00.000Z',
            adjustTime: '2023-12-12T10:27:19.057Z',
            reason: '개인적인 사유',
            cancelCount: 2,
            createdAt: '2023-12-12T10:27:19.105Z',
            updatedAt: '2023-12-12T10:27:19.105Z',
            __v: 0,
        },
        {
            _id: '657821751fbfed001986f2a8',
            isDeleted: false,
            scheduleId: '6578192279adb50012deb035',
            adjustmentType: 'coachTodayCancel',
            memberId: '656ea6c23dfbad0019109c0e',
            coachId: '6465c1a6090e920012f138eb',
            memberName: '회원13',
            coachName: '강사3',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 1,
            totalSession: 30,
            startTime: '2023-12-12T09:00:00.000Z',
            endTime: '2023-12-12T09:50:00.000Z',
            adjustTime: '2023-12-12T09:01:41.180Z',
            reason: '개인적인 사유',
            cancelCount: 1,
            createdAt: '2023-12-12T09:01:41.220Z',
            updatedAt: '2023-12-12T09:01:41.220Z',
            __v: 0,
        },
        {
            _id: '657821571fbfed001986f20c',
            isDeleted: false,
            scheduleId: '6577c643bdb2e0001203631d',
            adjustmentType: 'coachTodayCancel',
            memberId: '646b0d58755c9f00126dba42',
            coachId: '6465c1a6090e920012f138eb',
            memberName: '회원3',
            coachName: '강사3',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 4,
            totalSession: 30,
            startTime: '2023-12-12T06:00:00.000Z',
            endTime: '2023-12-12T06:50:00.000Z',
            adjustTime: '2023-12-12T09:01:11.703Z',
            reason: '개인적인 사유',
            cancelCount: 1,
            createdAt: '2023-12-12T09:01:11.756Z',
            updatedAt: '2023-12-12T09:01:11.756Z',
            __v: 0,
        },
        {
            _id: '65781a0e1fbfed001986f00a',
            isDeleted: false,
            scheduleId: '657819951fbfed001986efba',
            adjustmentType: 'coachTodayCancel',
            memberId: '64f0404f2164f3001915bdf6',
            coachId: '64f0555a5fc3a00012669553',
            memberName: '회원7',
            coachName: '강사7',
            memberProfileImageUrl: '',
            membershipCount: 0,
            currentSession: 0,
            totalSession: 0,
            startTime: '2023-12-12T08:00:00.000Z',
            endTime: '2023-12-12T08:50:00.000Z',
            adjustTime: '2023-12-12T08:30:06.062Z',
            reason: '개인적인 사유',
            cancelCount: 2,
            createdAt: '2023-12-12T08:30:06.101Z',
            updatedAt: '2023-12-12T08:30:06.101Z',
            __v: 0,
        },
        {
            _id: '65781a081fbfed001986eff6',
            isDeleted: false,
            scheduleId: '6578198e1fbfed001986efa8',
            adjustmentType: 'coachTodayCancel',
            memberId: '64f0404f2164f3001915bdf6',
            coachId: '64f0555a5fc3a00012669553',
            memberName: '회원8',
            coachName: '강사8',
            memberProfileImageUrl: '',
            membershipCount: 0,
            currentSession: 0,
            totalSession: 0,
            startTime: '2023-12-12T07:00:00.000Z',
            endTime: '2023-12-12T07:50:00.000Z',
            adjustTime: '2023-12-12T08:30:00.780Z',
            reason: '개인적인 사유',
            cancelCount: 1,
            createdAt: '2023-12-12T08:30:00.795Z',
            updatedAt: '2023-12-12T08:30:00.795Z',
            __v: 0,
        },
        {
            _id: '657818ee1fbfed001986ef18',
            isDeleted: false,
            scheduleId: '657818e71fbfed001986ef03',
            adjustmentType: 'coachTodayCancel',
            memberId: '656ea6c23dfbad0019109c0e',
            coachId: '6465c1a6090e920012f138eb',
            memberName: '회원4',
            coachName: '강사4',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 1,
            totalSession: 30,
            startTime: '2023-12-12T09:00:00.000Z',
            endTime: '2023-12-12T09:50:00.000Z',
            adjustTime: '2023-12-12T08:25:18.040Z',
            reason: '개인적인 사유',
            cancelCount: 1,
            createdAt: '2023-12-12T08:25:18.058Z',
            updatedAt: '2023-12-12T08:25:18.058Z',
            __v: 0,
        },
        {
            _id: '657818cc1fbfed001986eed7',
            isDeleted: false,
            scheduleId: '6577c6b6bdb2e00012036392',
            adjustmentType: 'coachTodayCancel',
            memberId: '646b0d58755c9f00126dba42',
            coachId: '6465c1a6090e920012f138eb',
            memberName: '회원6',
            coachName: '강사6',
            memberProfileImageUrl: '',
            membershipCount: 1,
            currentSession: 5,
            totalSession: 30,
            startTime: '2023-12-12T07:00:00.000Z',
            endTime: '2023-12-12T07:50:00.000Z',
            adjustTime: '2023-12-12T08:24:44.555Z',
            reason: '개인적인 사유',
            cancelCount: 1,
            createdAt: '2023-12-12T08:24:44.583Z',
            updatedAt: '2023-12-12T08:24:44.583Z',
            __v: 0,
        },
    ],
};

// 트레이너관리 > 트레이너 리스트
export let trainerListDummyData = {
    success: true,
    registeredTrainer: [
        {
            employeeId: '657ffe0dd660af00198114b7',
            coachId: '657ffd5534d28700121d1ad1',
            coachName: '강사1',
            coachProfileImageUrl: 'http://k.kakaocdn.net/dn/1G9kp/btsAot8liOn/8CWudi3uy07rvFNUkk3ER0/img_640x640.jpg',
            coachInviteCode: 'U789',
            coachTotalMemberCount: 1,
            coachActiveMemberCount: 0,
            coachEmail: 'kolaqw51@gmail.com',
            coachPhoneNumber: '',
            isConnected: true,
            isDeleted: false,
            coachStartDate: null,
        },
        {
            employeeId: '657ffea5d660af0019811702',
            coachId: '657ffe84d660af0019811612',
            coachName: '강사2',
            coachProfileImageUrl: 'http://k.kakaocdn.net/dn/1G9kp/btsAot8liOn/8CWudi3uy07rvFNUkk3ER0/img_640x640.jpg',
            coachInviteCode: 'I727',
            coachTotalMemberCount: 0,
            coachActiveMemberCount: 0,
            coachEmail: 'kolaqw52@gmail.com',
            coachPhoneNumber: '',
            isConnected: true,
            isDeleted: false,
            coachStartDate: null,
        },
        {
            employeeId: '657fff24a7b4260012c99087',
            coachId: '657fff05a7b4260012c99035',
            coachName: '강사3',
            coachProfileImageUrl: 'http://k.kakaocdn.net/dn/1G9kp/btsAot8liOn/8CWudi3uy07rvFNUkk3ER0/img_640x640.jpg',
            coachInviteCode: 'S129',
            coachTotalMemberCount: 0,
            coachActiveMemberCount: 0,
            coachEmail: 'kolaqw53@gmail.com',
            coachPhoneNumber: '',
            isConnected: true,
            isDeleted: false,
            coachStartDate: null,
        },
        {
            employeeId: '657ffff7d660af00198119ba',
            coachId: '657fffbad660af00198118f6',
            coachName: '강사4',
            coachProfileImageUrl: 'http://k.kakaocdn.net/dn/1G9kp/btsAot8liOn/8CWudi3uy07rvFNUkk3ER0/img_640x640.jpg',
            coachInviteCode: 'V183',
            coachTotalMemberCount: 0,
            coachActiveMemberCount: 0,
            coachEmail: 'kolaqw54@gmail.com',
            coachPhoneNumber: '',
            isConnected: true,
            isDeleted: false,
            coachStartDate: null,
        },
        {
            employeeId: '6580048034d28700121d2852',
            coachId: '6580034b089a4b00190f2dcc',
            coachName: '강사5',
            coachProfileImageUrl: 'http://k.kakaocdn.net/dn/1G9kp/btsAot8liOn/8CWudi3uy07rvFNUkk3ER0/img_640x640.jpg',
            coachInviteCode: 'R318',
            coachTotalMemberCount: 0,
            coachActiveMemberCount: 0,
            coachEmail: 'kolaqw55@gmail.com',
            coachPhoneNumber: '',
            isConnected: true,
            isDeleted: false,
            coachStartDate: null,
        },
    ],
    deletedTrainer: [],
};

// 트레이너관리 > 트레이너 정보 > 트레이너 프로필
export let trainerProfileDummyData = {
    success: true,
    employeeProfile: {
        coachId: '657ffd5534d28700121d1ad1',
        employeeId: '657ffe0dd660af00198114b7',
        name: '강사1',
        inviteCode: 'U789',
        phoneNumber: '',
        profileImageUrl: 'http://k.kakaocdn.net/dn/1G9kp/btsAot8liOn/8CWudi3uy07rvFNUkk3ER0/img_640x640.jpg',
        birthday: '',
        gender: '',
        email: 'kolaqw51@gmail.com',
        startDate: '',
        jobPosition: '',
        totalMemberCount: 1,
        activeMemberCount: 0,
        isShareOnetimeSchedule : true
    },
};

// 트레이너관리 > 트레이너 정보 > 담당회원 정보 리스트
export let memberInformationDummyData = {
    success: true,
    completeScheduleDesc: [
        {
            memberName: 'Qwe',
            age: 29,
            gender: 'female',
            startWeight: 75,
            currentWeight: 75,
            height: 170,
            phoneNumber: '',
            isActive: true,
            currentSession: 9,
            totalSession: 30,
            membershipCount: 1,
            activationDate: '2023-12-14T15:00:00.000Z',
            expirationDate: '2024-04-12T15:00:00.000Z',
            lastScheduleTime: '2023-12-26T02:50:00.000Z',
        },
        {
            memberName: '이일일',
            age: 34,
            gender: 'male',
            startWeight: 75,
            currentWeight: 75,
            height: 170,
            phoneNumber: '',
            isActive: true,
            currentSession: 1,
            totalSession: 31,
            membershipCount: 1,
            activationDate: '2023-12-17T15:00:00.000Z',
            expirationDate: '2024-03-17T14:59:59.000Z',
            lastScheduleTime: '2023-12-21T00:00:00.000Z',
        },
        {
            memberName: 'vv',
            age: 34,
            gender: 'male',
            startWeight: 75,
            currentWeight: 75.2,
            height: 173.3,
            phoneNumber: '010-7477-8390',
            isActive: true,
            currentSession: null,
            totalSession: null,
            membershipCount: null,
            activationDate: null,
            expirationDate: null,
            memo: null,
            lastScheduleTime: '2023-12-06T06:50:00.000Z',
        },
        {
            memberName: 'hl하림d',
            age: 34,
            gender: 'male',
            startWeight: 75,
            currentWeight: 75,
            height: 170,
            phoneNumber: '',
            isActive: true,
            currentSession: null,
            totalSession: null,
            membershipCount: null,
            activationDate: null,
            expirationDate: null,
            memo: null,
            lastScheduleTime: '2023-12-06T01:50:00.000Z',
        },
        {
            memberName: 'qa21',
            age: 34,
            gender: 'male',
            startWeight: 75,
            currentWeight: 75,
            height: 170,
            phoneNumber: '',
            isActive: true,
            currentSession: null,
            totalSession: null,
            membershipCount: null,
            activationDate: null,
            expirationDate: null,
            memo: null,
            lastScheduleTime: '1999-12-31T15:00:00.000Z',
        },
        {
            memberName: 'qw235',
            age: 34,
            gender: 'female',
            startWeight: 75,
            currentWeight: 75,
            height: 170,
            phoneNumber: '',
            isActive: true,
            currentSession: null,
            totalSession: null,
            membershipCount: null,
            activationDate: null,
            expirationDate: null,
            memo: null,
            lastScheduleTime: '1999-12-31T15:00:00.000Z',
        },
    ],
    alphabetAsc: [
        {
            memberName: '이일일',
            age: 34,
            gender: 'male',
            startWeight: 75,
            currentWeight: 75,
            height: 170,
            phoneNumber: '',
            isActive: true,
            currentSession: 1,
            totalSession: 31,
            membershipCount: 1,
            activationDate: '2023-12-17T15:00:00.000Z',
            expirationDate: '2024-03-17T14:59:59.000Z',
            lastScheduleTime: '2023-12-21T00:00:00.000Z',
        },
        {
            memberName: 'hl하림d',
            age: 34,
            gender: 'male',
            startWeight: 75,
            currentWeight: 75,
            height: 170,
            phoneNumber: '',
            isActive: true,
            currentSession: null,
            totalSession: null,
            membershipCount: null,
            activationDate: null,
            expirationDate: null,
            memo: null,
            lastScheduleTime: '2023-12-06T01:50:00.000Z',
        },
        {
            memberName: 'qa21',
            age: 34,
            gender: 'male',
            startWeight: 75,
            currentWeight: 75,
            height: 170,
            phoneNumber: '',
            isActive: true,
            currentSession: null,
            totalSession: null,
            membershipCount: null,
            activationDate: null,
            expirationDate: null,
            memo: null,
            lastScheduleTime: '1999-12-31T15:00:00.000Z',
        },
        {
            memberName: 'qw235',
            age: 34,
            gender: 'female',
            startWeight: 75,
            currentWeight: 75,
            height: 170,
            phoneNumber: '',
            isActive: true,
            currentSession: null,
            totalSession: null,
            membershipCount: null,
            activationDate: null,
            expirationDate: null,
            memo: null,
            lastScheduleTime: '1999-12-31T15:00:00.000Z',
        },
        {
            memberName: 'Qwe',
            age: 29,
            gender: 'female',
            startWeight: 75,
            currentWeight: 75,
            height: 170,
            phoneNumber: '',
            isActive: true,
            currentSession: 9,
            totalSession: 30,
            membershipCount: 1,
            activationDate: '2023-12-14T15:00:00.000Z',
            expirationDate: '2024-04-12T15:00:00.000Z',
            lastScheduleTime: '2023-12-26T02:50:00.000Z',
        },
        {
            memberName: 'vv',
            age: 34,
            gender: 'male',
            startWeight: 75,
            currentWeight: 75.2,
            height: 173.3,
            phoneNumber: '010-7477-8390',
            isActive: true,
            currentSession: null,
            totalSession: null,
            membershipCount: null,
            activationDate: null,
            expirationDate: null,
            memo: null,
            lastScheduleTime: '2023-12-06T06:50:00.000Z',
        },
    ],
    totalMemberCount: 6,
    activeMemberCount: 2,
};
