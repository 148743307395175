import React from 'react';
import styled, { keyframes } from 'styled-components';
import { sidebarStatusState } from '../../../atoms/atom';
import { useRecoilState } from 'recoil';
import dayjs from 'dayjs';
import { Col, Row } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import { Body1Regular, Body2Regular, Subtitle1Bold } from '../../../lib/font';

// 모달 컴포넌트 스타일드
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// 모달 스타일드 컴포넌트
const HoverGroupScheduleContainer = styled.div`
    position: absolute;
    width: 340px;
    height: 196px;
    border-radius: 8px;
    background-color: rgba(253, 254, 255, 1);
    border: none;
    box-shadow: 0px 0px 8px 2px rgba(24, 62, 134, 0.28);
    animation: ${fadeIn} 1s ease; /* 애니메이션 적용 */
    z-index: 9999;
`;

const HoverGroupSchedule = ({ childArr, parentIndex, parentItem }: any) => {
    const [sidebarStatus, setSidebarStatus] = useRecoilState(sidebarStatusState);

    const isBeforeCurrentTime = dayjs().isBefore(childArr[0]?.startTime);
    const isGoingSchedule =
        dayjs().format('YYYY-MM-DD HH') === dayjs(childArr[0]?.startTime).format('YYYY-MM-DD HH') ? true : false;
    const marginLeftStyle = sidebarStatus === 'open' ? (parentIndex > 4 ? -345 : 120) : parentIndex > 4 ? -345 : 155;

    return (
        <HoverGroupScheduleContainer
            style={{
                marginTop: parseInt(dayjs(childArr[0].startTime).format('H')) > 20 ? -34 : 0,
                marginLeft: parentIndex > 4 ? -marginLeftStyle : marginLeftStyle,
            }}
        >
            <Row
                style={{
                    width: 340,
                    height: 52,
                    borderTopRightRadius: 8,
                    borderTopLeftRadius: 8,
                    backgroundColor: colors.primary,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingLeft: 24,
                    paddingRight: 24,
                }}
            >
                <Subtitle1Bold style={{ color: colors.WHITE_50 }}>
                    {dayjs(childArr[0]?.startTime).format('MM월 DD일(ddd)')}
                </Subtitle1Bold>

                <Row
                    style={{
                        borderRadius: 34,
                        border: isGoingSchedule
                            ? '1px solid rgba(255, 217, 119, 1)'
                            : '1px solid rgba(253, 254, 255, 1)',
                        paddingLeft: 10,
                        paddingRight: 10,
                    }}
                >
                    <Body2Regular style={{ color: isGoingSchedule ? colors.secondary : colors.WHITE_50 }}>
                        {`${isGoingSchedule ? '수업 중' : !isBeforeCurrentTime ? '수업 완료' : '수업 예정'}`}
                    </Body2Regular>
                </Row>
            </Row>
            <Col
                style={{
                    height: 144,
                    paddingLeft: 24,
                    paddingRight: 24,
                    paddingTop: 8,
                    paddingBottom: 8,
                    justifyContent: 'space-between',
                }}
            >
                <Row style={{ justifyContent: 'space-between' }}>
                    <Subtitle1Bold style={{ color: colors.BLUE_900 }}>{`${parentItem?.coachName} 강사`}</Subtitle1Bold>
                    <Subtitle1Bold style={{ color: colors.BLUE_900 }}>{`${childArr[0]?.alias} 회원`}</Subtitle1Bold>
                </Row>
                <Row style={{ justifyContent: 'flex-end' }}>
                    <Body1Regular style={{ color: colors.WHITE_900 }}>
                        {`${childArr[0].currentSession ? childArr[0].currentSession : '?'}/${
                            childArr[0].totalSession ? childArr[0].totalSession : '?'
                        }회`}
                    </Body1Regular>
                </Row>
                <Row style={{ justifyContent: 'flex-end' }}>
                    <Subtitle1Bold style={{ color: colors.BLUE_900 }}>{`${childArr[1].alias} 회원`}</Subtitle1Bold>
                </Row>

                <Row style={{ justifyContent: 'space-between' }}>
                    <Body1Regular style={{ color: colors.WHITE_900 }}>{`${dayjs(childArr[0]?.startTime).format(
                        'A h:mm ~ '
                    )} ${dayjs(childArr[0]?.endTime).format('h:mm')}`}</Body1Regular>
                    <Body1Regular style={{ color: colors.WHITE_900 }}>
                        {`${childArr[1].currentSession ? childArr[1].currentSession : '?'}/${
                            childArr[1].totalSession ? childArr[1].totalSession : '?'
                        }회`}
                    </Body1Regular>
                </Row>
            </Col>
        </HoverGroupScheduleContainer>
    );
};

export default HoverGroupSchedule;
