import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Row } from '../../lib/utils';
import {
    Body1Bold,
    Body3Light,
    Body3Regular,
    CaptionLight,
    CaptionRegular,
    Head4,
    Subtitle1Bold,
    Subtitle1Regular,
} from '../../lib/font';
import { colors } from '../../lib/colors';
import { I_POPUP_EXIT } from '../../types/images';
import NormalButton from '../Button/NormalButton';
import { modifyPasswordAPI, modifyPasswordPrevCheckAPI } from '../../api/mypage';
import { useMutation } from '@tanstack/react-query';
import WhiteSquareButton from '../Button/WhiteSquareButton';
import ColorSquareButton from '../Button/ColorSquareButton';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
`;

const ModalInner = styled.div`
    background: white;
    width: 880px;
    height: 460px;
    border-radius: 8px;
    box-shadow: 8px 8px 24px 0px #00000052;
`;

const HoverImage = styled.img`
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

type Props = {
    modalVisible: boolean;
    setModalVisible: Dispatch<SetStateAction<boolean>>;
    createAlert: any;
    authorization: any;
    authObject: any;
};

const ModalPassWordChange = ({ modalVisible, setModalVisible, createAlert, authorization, authObject }: Props) => {
    const [step, setStep] = useState<string>('first');
    // 초기값 설정
    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>('');

    const [buttonDisable, setButtonDisable] = useState<boolean>(false);

    const onClickExit = () => {
        setModalVisible(false);
    };

    const onChangeCurrentPassword = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentPassword(e.target.value.trim());
    };

    const onChangeNewPassword = (e: ChangeEvent<HTMLInputElement>) => {
        setNewPassword(e.target.value.trim());
    };

    const onChangeNewPasswordConfirm = (e: ChangeEvent<HTMLInputElement>) => {
        setNewPasswordConfirm(e.target.value.trim());
    };

    const onClickNextButton = () => {
        if (step === 'first') {
            modifyPasswordPrevCheck.mutate();
        }

        if (step === 'second') {
            if (newPassword === newPasswordConfirm) {
                modifyPassword.mutate();
            } else {
                createAlert('입력 오류', '비밀번호가 일치하지 않습니다.', true);
            }
        }
    };

    // 비밀번호 확인 (POST)
    const modifyPasswordPrevCheck = useMutation({
        mutationFn: async () => await modifyPasswordPrevCheckAPI(authObject.adminId, currentPassword, authorization),
        onSuccess: (res) => {
            console.log(res);
            if (res.status === 200) {
                setStep('second');
            }
            if (res.status === 400) {
                createAlert('입력 오류', '비밀번호 형식이 불일치합니다', true);
            }
            if (res.status === 418) {
                createAlert('입력 오류', '비밀번호가 일치하지 않습니다.', true);
            }
        },
    });

    // 비밀번호 변경 (POST)
    const modifyPassword = useMutation({
        mutationFn: async () => await modifyPasswordAPI(authObject.adminId, newPassword, authorization),
        onSuccess: (res) => {
            console.log(res);
            if (res.status === 200) {
                setModalVisible(false);
                createAlert('변경 완료', '새로운 비밀번호로 변경되었습니다');
            }
            if (res.status === 400) {
                createAlert('입력 오류', '비밀번호 형식이 불일치합니다', true);
            }
            if (res.status === 418) {
                createAlert('입력 오류', '비밀번호가 일치하지 않습니다.', true);
            }
        },
    });

    useEffect(() => {
        if (currentPassword === '') {
            setButtonDisable(true);
        } else {
            setButtonDisable(false);
        }
    }, [currentPassword]);

    return (
        <ModalOverLay>
            <ModalInner>
                <Row style={{ marginBottom: 16 }}>
                    <Subtitle1Bold style={{ color: colors.LAVEL_4, marginLeft: 48, marginTop: 36 }}>
                        비밀번호 변경
                    </Subtitle1Bold>

                    <HoverImage
                        src={I_POPUP_EXIT}
                        style={{ width: 32, height: 32, marginTop: 16, marginLeft: 646 }}
                        onClick={onClickExit}
                    />
                </Row>
                <Body3Regular style={{ color: colors.LAVEL_3, marginLeft: 48 }}>
                    {'새로운 비밀번호 변경을 위해 현재 비밀번호를 입력해주세요!'}
                </Body3Regular>

                <Row style={{ marginTop: 16, marginLeft: 56 }}>
                    <div
                        style={{
                            width: 24,
                            height: 24,
                            borderRadius: 18,
                            backgroundColor: step === 'first' ? colors.PRIMARY_BLUE : colors.BLUE_100,
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <CaptionRegular style={{ color: colors.WHITE_50 }}>1</CaptionRegular>
                    </div>
                    <hr
                        style={{
                            width: 32,
                            height: 1,
                            borderColor: colors.BLUE_100,
                            alignSelf: 'center',
                        }}
                    />
                    <div
                        style={{
                            width: 24,
                            height: 24,
                            borderRadius: 18,
                            backgroundColor: step === 'second' ? colors.PRIMARY_BLUE : colors.BLUE_100,
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <CaptionRegular style={{ color: colors.WHITE_50 }}>2</CaptionRegular>
                    </div>
                </Row>

                {step === 'first' && (
                    <Col style={{ marginTop: 38, marginLeft: 60 }}>
                        <label htmlFor="password" style={{ display: 'flex' }}>
                            <Body3Regular style={{ color: colors.LAVEL_4 }}>현재 비밀번호</Body3Regular>
                        </label>

                        <input
                            type="password"
                            maxLength={20}
                            value={currentPassword}
                            onChange={onChangeCurrentPassword}
                            placeholder="현재 비밀번호를 입력해주세요!"
                            style={{
                                width: 340,
                                height: 48,
                                backgroundColor: colors.WHITE_50,
                                borderBottom: '1px solid #A6BFEF',
                                fontSize: 18,
                                fontWeight: 400,
                                marginTop: 8,
                                paddingLeft: 16,
                            }}
                        />
                        <CaptionLight style={{ color: colors.LAVEL_1, marginLeft: 16 }}>
                            {'영문, 숫자, 특수문자를 포함한 8~20글자로 작성해주세요!'}
                        </CaptionLight>
                    </Col>
                )}

                {step === 'second' && (
                    <Row style={{ marginTop: 40, marginLeft: 56 }}>
                        <Col style={{ marginRight: 80 }}>
                            <label htmlFor="password" style={{ display: 'flex' }}>
                                <Body3Regular style={{ color: colors.LAVEL_4 }}>새 비밀번호</Body3Regular>
                            </label>

                            <input
                                type="password"
                                maxLength={20}
                                value={newPassword}
                                placeholder="새 비밀번호를 입력해주세요!"
                                onChange={onChangeNewPassword}
                                style={{
                                    width: 340,
                                    height: 48,
                                    backgroundColor: colors.WHITE_50,
                                    borderBottom: '1px solid #A6BFEF',
                                    fontSize: 18,
                                    fontWeight: 400,
                                    marginTop: 8,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                }}
                            />
                        </Col>
                        <Col>
                            <label htmlFor="password" style={{ display: 'flex' }}>
                                <Body3Regular style={{ color: colors.LAVEL_4 }}>새 비밀번호 확인</Body3Regular>
                            </label>

                            <input
                                type="password"
                                maxLength={20}
                                value={newPasswordConfirm}
                                placeholder="새 비밀번호를 입력해주세요!"
                                onChange={onChangeNewPasswordConfirm}
                                style={{
                                    width: 340,
                                    height: 48,
                                    backgroundColor: colors.WHITE_50,
                                    borderBottom: '1px solid #A6BFEF',
                                    fontSize: 18,
                                    fontWeight: 400,
                                    marginTop: 8,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                }}
                            />
                        </Col>
                    </Row>
                )}

                <Row style={{ width: 312, marginTop: 87, marginLeft: 536, justifyContent: 'space-between' }}>
                    <WhiteSquareButton
                        text="취소"
                        size="middle"
                        isVisibleLeftImg={false}
                        isVisibleRightImg={false}
                        callBack={() => setModalVisible(false)}
                    />

                    <ColorSquareButton
                        text="다음"
                        size="middle"
                        isVisibleLeftImg={false}
                        isVisibleRightImg={false}
                        isDisabled={buttonDisable}
                        callBack={onClickNextButton}
                    />
                </Row>
            </ModalInner>
        </ModalOverLay>
    );
};

export default ModalPassWordChange;
