import React from 'react';
import { Row } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import dayjs from 'dayjs';

const WeeklyTimeCell = ({ lengthArr, rowIndex, cell }: any) => {
    return (
        <Row
            style={{
                width: '7.5%',
                height: lengthArr
                    ? Math.max(...lengthArr[rowIndex]) === 0
                        ? 98
                        : (Math.max(...lengthArr[rowIndex]) - 1) * 98 + 98
                    : 98,
                justifyContent: 'center',
                alignItems: 'center',
                background: colors.WHITE_50,
            }}
        >
            <Row
                style={{
                    width: '74%',
                    height: 32,
                    border: cell.content === dayjs().format('A hh시') ? '1px  solid #1A305D' : '',
                    borderRadius: 24,
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 12,
                    fontWeight: cell.content === dayjs().format('A hh시') ? 700 : 400,
                    color: cell.content === dayjs().format('A hh시') ? colors.LAVEL_4 : colors.LAVEL_2,
                }}
            >
                <span>
                    {cell.content === '오후 12시'
                        ? '정오 12시'
                        : cell.content === '오전 12시'
                        ? '자정 12시'
                        : cell.content}
                </span>
            </Row>
        </Row>
    );
};

export default WeeklyTimeCell;
