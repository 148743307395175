import React, { Dispatch, SetStateAction, useState } from 'react';
import Modal from '../../modal/Modal';
import { Col, Row } from '../../../lib/utils';
import { Body1Regular, Body3Regular, Subtitle1Bold } from '../../../lib/font';
import BottomBorderTextInput from '../../TextInput/BottomBorderTextInput';
import NewWhiteSquareButton from '../../Button/NewWhiteSquareButton';
import NewColorSquareButton from '../../Button/NewColorSquareButton';

type Props = {
    onClickUpdateScheduleButton: (value: number) => void;
    currentSession: number;
    totalSession: number;
    minSession: number;
    setIsVisible: (isVisible: boolean) => void;
};

const ModalUpdateScheduleSession = ({
    onClickUpdateScheduleButton,
    currentSession,
    totalSession,
    minSession,
    setIsVisible,
}: Props) => {
    const [value, setValue] = useState(currentSession);

    return (
        <Modal whiteViewWidth={596} whiteViewHeight={336}>
            <Col className="flex justify-center items-center px-[40px] py-[36px]">
                <Subtitle1Bold className="mb-[16px]">수강권 회차 수정</Subtitle1Bold>
                <Body3Regular>몇 회차 스케줄인가요?</Body3Regular>
                <Body3Regular className="mb-[16px]">{minSession}회 이상으로 작성해주세요!</Body3Regular>
                <Row className="justify-center items-center mb-[40px]">
                    <BottomBorderTextInput
                        value={value === 0 ? '' : value.toString()}
                        onChange={(e: any) => {
                            // onChange(e.target.value);
                            let inputValue = e.target.value.replace(/\D/g, '');
                            let valueNumber = inputValue === '' ? 0 : parseInt(inputValue);
                            if (valueNumber > totalSession) {
                                valueNumber = totalSession;
                            }
                            setValue(valueNumber);
                        }}
                        width={120}
                        height={48}
                        mr={16}
                    />
                    <Body1Regular>/ {totalSession}회</Body1Regular>
                </Row>

                <Row>
                    <NewWhiteSquareButton
                        text={'취소'}
                        onClick={() => setIsVisible(false)}
                        width={148}
                        height={48}
                        mr={16}
                    />
                    <NewColorSquareButton
                        text={'수정 완료'}
                        onClick={() => {
                            onClickUpdateScheduleButton(value);
                            setIsVisible(false);
                        }}
                        width={148}
                        height={48}
                    />
                </Row>
            </Col>
        </Modal>
    );
};

export default ModalUpdateScheduleSession;
