import { ChangeEvent, useState } from 'react';
import { Col, Row, setItemWithExpiration } from '../../lib/utils';
import {
    Body1Regular,
    Body2Regular,
    Body3Light,
    Body3Regular,
    CaptionLight,
    Head4,
    Subtitle1Regular,
} from '../../lib/font';
import { colors } from '../../lib/colors';
import NormalButton from '../Button/NormalButton';
import QuitButton from '../Button/QuitButton';
import { I_EMPTY_PROFILE, I_HOVER_IMG } from '../../types/images';
import styled from 'styled-components';
import { useMutation } from '@tanstack/react-query';
import { modifyProfileImageAPI } from '../../api/mypage';
import SmallPrimaryButton from '../Button/SmallPrimaryButton';
import UsableState from '../UsableState';
import WhiteSquareButton from '../Button/WhiteSquareButton';
import ColorSquareButton from '../Button/ColorSquareButton';
import TextChangeButton from '../TextButton/TextChangeButton';

const Container = styled.div`
    position: relative;
    display: inline-block;
    margin-top: 32px;
`;

const Image: any = styled.img`
    width: 120px;
    height: 120px;
    border-radius: 60px;
    transition: filter 0.3s ease;

    ${(props: any) =>
        props.hovered &&
        `
        filter: grayscale(100%);
  `}
`;

const ImageOverlay: any = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    width: 120px;
    height: 120px;
    border-radius: 60px;
    cursor: pointer;
    background-color: #62656b;
    opacity: ${(props: any) => (props.hovered === 'true' ? 0.7 : 0)};
    transition: opacity 0.3s ease;
`;

const HoverImage: any = styled.img`
    position: absolute;
    width: 15px;
    height: 15px;
    transform: translate(-50%, -50%);
    cursor: pointer;
    top: 40%;
    left: 50%;
    visibility: ${(props: any) => (props.hovered === 'true' ? 'visible' : 'hidden')};
    opacity: ${(props: any) => (props.hovered === 'true' ? 1 : 0)};
    transition: visibility 0.3s, opacity 0.3s ease;
`;

const HoverText: any = styled.div`
    position: absolute;

    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    color: #fff; /* 텍스트 색상을 조절하세요 */
    visibility: ${(props: any) => (props.hovered === 'true' ? 'visible' : 'hidden')};
    opacity: ${(props: any) => (props.hovered === 'true' ? 1 : 0)};
    transition: visibility 0.3s, opacity 0.3s ease;
`;

type Props = {
    authorization: any;
    authObject: any;
    numberTrainers: number;
    numberStudents: number;
    numberActive: number;
    onClickPasswordChange: () => void;
    onClickWithDrawal: () => void;
    onClickConfirmUsingPlan: () => void;
    setAuthObject: any;
};

const MyPageLeft = ({
    authorization,
    authObject,
    numberTrainers,
    numberStudents,
    numberActive,
    onClickPasswordChange,
    onClickWithDrawal,
    onClickConfirmUsingPlan,
    setAuthObject,
}: Props) => {
    const [hovered, setHovered] = useState<string>('false');

    // 프로필 사진 바꾸기 (POST)
    const modifyProfileImage = useMutation({
        mutationFn: async (base64String: any) =>
            await modifyProfileImageAPI(authObject?.adminId, base64String, authorization),
        onSuccess: (res) => {
            if (res.status === 200) {
                const user = res.data.user;
                const authObjectData = {
                    adminId: user?.adminId,
                    id: user?.id,
                    name: user?.name,
                    email: user?.email,
                    gender: user?.gender,
                    phoneNumber: user?.phoneNumber,
                    profileImageUrl: user?.profileImageUrl,

                    centerAddress: authObject?.centerAddress,
                    centerCategory: authObject?.centerCategory,
                    centerCode: authObject?.centerCode,
                    centerName: authObject?.centerName,
                    centerId: authObject?.centerId,
                    isNewCommunicationBox: authObject?.isNewCommunicationBox,
                    // 구독플랜관련
                    coachCount: authObject?.coachCount,
                    memberCount: authObject?.memberCount,
                    activationDate: authObject?.activationDate,
                    expirationDate: authObject?.expirationDate,
                    expirationDelayDate: authObject?.expirationDelayDate,
                    finalPaymentDate: authObject?.finalPaymentDate,
                    finalPaymentTryDate: authObject?.finalPaymentTryDate,
                    cardIssuingBank: authObject?.cardIssuingBank,
                    cardNumber: authObject?.cardNumber,
                    planAmount: authObject?.planAmount,
                    usableStatus: authObject?.usableStatus,

                    paymentDueDate: authObject?.paymentDueDate,

                    /** 구독플랜 인원 정보 */
                    planLimitCount: authObject?.planLimitCount, // 플랜 제한 이용 인원
                    prevPlanLimitCount: authObject?.prevPlanLimitCount, // 구독 인원 변경시 변경전 플랜카운트
                    changedPlanLimitCount: authObject?.changedPlanLimitCount, // 구독 인원 변경시 변경 플랜카운트

                    confirmUsableStatus: authObject?.confirmUsableStatus,
                    testAccount: authObject?.testAccount,
                };
                setItemWithExpiration('authorization', authorization, 12);
                setItemWithExpiration('authObject', authObjectData, 12);

                setAuthObject(authObjectData);
            }
        },
    });

    const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const base64String = await convertFileToBase64(file);
            modifyProfileImage.mutate(base64String);
        }
    };

    const convertFileToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                if (reader.result) {
                    resolve(reader.result.toString());
                } else {
                    reject(new Error('Failed to convert the file to base64.'));
                }
            };

            reader.onerror = () => {
                reject(new Error('Failed to read the file.'));
            };

            reader.readAsDataURL(file);
        });
    };

    return (
        <Col
            style={{
                width: 280,
                height: 828,
                backgroundColor: colors.WHITE_50,
                borderRadius: 16,
                border: '1px solid #C3D4F4',
                alignItems: 'center',
            }}
        >
            <Row style={{ width: 216, marginTop: 24 }}>{authObject && <UsableState authObject={authObject} />}</Row>

            {authObject?.profileImageUrl === '' ? (
                <Container onMouseEnter={() => setHovered('true')} onMouseLeave={() => setHovered('false')}>
                    <Image src={I_EMPTY_PROFILE} alt="Profile" hovered={hovered} />
                    <ImageOverlay
                        multiple={false}
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        hovered={hovered}
                        disabled={authObject?.testAccount}
                    />
                    <HoverImage src={I_HOVER_IMG} hovered={hovered} />
                    <HoverText hovered={hovered}>프로필 사진 바꾸기</HoverText>
                </Container>
            ) : (
                <Container onMouseEnter={() => setHovered('true')} onMouseLeave={() => setHovered('false')}>
                    <Image src={authObject?.profileImageUrl} alt="Profile" hovered={hovered} />
                    <ImageOverlay
                        multiple={false}
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        hovered={hovered}
                    />
                    <HoverImage src={I_HOVER_IMG} hovered={hovered} />
                    <HoverText hovered={hovered}>{'프로필 사진\n 바꾸기'}</HoverText>
                </Container>
            )}

            <Col style={{ width: 216, height: 70, marginTop: 32, justifyContent: 'space-between' }}>
                <Body2Regular style={{ color: colors.LAVEL_3 }}>아이디</Body2Regular>
                <Row
                    style={{
                        width: 216,
                        height: 36,
                        borderRadius: 36,
                        backgroundColor: colors.WHITE_500,

                        alignItems: 'center',
                        paddingLeft: 16,
                    }}
                >
                    <Body3Regular style={{ color: colors.LAVEL_1 }}>{authObject?.adminId}</Body3Regular>
                </Row>
            </Col>

            <Col style={{ width: 216, height: 90, marginTop: 32, justifyContent: 'space-between' }}>
                <Body2Regular style={{ color: colors.LAVEL_3 }}>센터코드</Body2Regular>

                <Row
                    style={{
                        width: 216,
                        height: 36,
                        borderRadius: 36,
                        backgroundColor: colors.WHITE_500,
                        alignItems: 'center',
                        paddingLeft: 16,
                    }}
                >
                    <Body3Regular style={{ color: colors.LAVEL_1 }}>{authObject?.centerCode}</Body3Regular>
                </Row>
                <CaptionLight style={{ color: colors.LAVEL_1 }}>{'*변경 불가능한 정보입니다'}</CaptionLight>
            </Col>

            <Col style={{ width: 216, height: 88, justifyContent: 'space-between', marginTop: 32 }}>
                <Body3Regular style={{ color: colors.LAVEL_3 }}>소속 강사 : {numberTrainers}명</Body3Regular>
                <Body3Regular style={{ color: colors.LAVEL_3 }}>전체 회원 : {numberStudents}명</Body3Regular>
                <Body3Regular style={{ color: colors.LAVEL_3 }}>활성 회원 : {numberActive}명</Body3Regular>
            </Col>

            <Col style={{ width: 216, marginTop: 32 }}>
                <WhiteSquareButton
                    customStyle={{ width: 139, height: 36 }}
                    size="small"
                    text={'비밀번호 바꾸기'}
                    callBack={onClickPasswordChange}
                />

                <ColorSquareButton
                    customStyle={{ width: 139, height: 36, marginTop: 12, marginBottom: 98 }}
                    size="small"
                    callBack={onClickConfirmUsingPlan}
                    text={'구독 플랜'}
                    isGradient={true}
                />

                <TextChangeButton
                    customStyle={{ width: 59, height: 24 }}
                    text={'탈퇴하기'}
                    size="middle"
                    callBack={onClickWithDrawal}
                />
            </Col>
        </Col>
    );
};

export default MyPageLeft;
