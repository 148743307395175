import React, { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading';
import { Col, Row, getItemWithExpiration, setItemWithExpiration } from '../../lib/utils';
import { useMutation } from '@tanstack/react-query';
import { requestPaymentAPI, requestRegistrationCardAPI } from '../../api/charge';
import ModalFail from '../../components/SuccessSubscription/ModalFail';
import ModalSuccess from '../../components/SuccessSubscription/ModalSuccess';
import Header from '../../components/Header';
import {
    Body1Bold,
    Body1Regular,
    Body2Bold,
    Body2Regular,
    Body3Bold,
    Body3Light,
    Body3Regular,
    CaptionLight,
    Head4,
    Subtitle1Regular,
} from '../../lib/font';
import { colors } from '../../lib/colors';
import { I_PRIMARY_CHECK } from '../../types/images';
import NormalButton from '../../components/Button/NormalButton';
import ModalAlert from '../../components/modal/ModalAlert';
import dayjs from 'dayjs';
import ColorRoundButton from '../../components/Button/ColorRoundButton';

const SuccessSubscriptionPage = ({ createAlert, onClickTerm }: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams: any = new URLSearchParams(location.search);
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));
    const authKey: string = queryParams.get('authKey');
    const customerKey: string = queryParams.get('customerKey');

    const [cardName, setCardName] = useState<string>('');
    const [cardNumber, setCardNumber] = useState<string>('');

    // 결제할 강사 수
    const trainerNumber = getItemWithExpiration('trainerNumber');
    const price = trainerNumber < 4 ? trainerNumber * 32900 : trainerNumber * 39900;
    const [email, setEmail] = useState<string>(authObject?.email);
    const [isCheckPolicy, setIsCheckPolicy] = useState<boolean>(false);

    // 로딩관련
    const [loadingText, setLoadingText] = useState<string>('카드 정보 등록 진행중입니다 잠시만 기다려주세요.');
    const [isLoading, setIsLoading] = useState<boolean>(true);

    // 카드등록 실패모달
    const [isFailModal, setIsFailModal] = useState<boolean>(false);

    const [failModalTitle, setFailModalTitle] = useState<string>('');
    const [failModalContent, setFailModalContent] = useState<string>('');

    // 카드등록, 결제 성공모달
    const [isSuccessModal, setIsSuccessModal] = useState<boolean>(false);
    const [successModalTitle, setSuccessModalTitle] = useState<string>('');
    const [successModalContent, setIsSuccessModalContent] = useState<string>('');
    const [isCallback, setIsCallBack] = useState<boolean>(false);

    // 결제실패 모달
    const [isFailPayModal, setIsFailPayModal] = useState<boolean>(false);

    // 이메일 변경
    const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
        const currentEmail = e.target.value;
        setEmail(currentEmail.trim());
    };

    // 개인정보 수집 및 이용약관 동의 버튼
    const onClickPolicy = () => {
        setIsCheckPolicy(!isCheckPolicy);
    };

    // 결제 요청하기 버튼 클릭이벤트
    const onClickPayButton = () => {
        const emailRegExp = /^$|^[A-Za-z0-9_]+[A-Za-z0-9]*[@]{1}[A-Za-z0-9]+[A-Za-z0-9]*[.]{1}[A-Za-z]{1,3}$/;
        if (!isCheckPolicy) {
            createAlert('필수', '개인정보 수집 및 이용약관에 동의해주세요', true);
            return;
        }

        if (!(email.trim() === '')) {
            if (!emailRegExp.test(email)) {
                createAlert('입력 오류', '이메일 형식이 잘못되었습니다', true);
                return;
            }
        }
        setIsLoading(true);
        requestPayment.mutate();
    };

    // API(POST) : 빌링키 요청 api
    const requestRegistrationCard = useMutation({
        mutationFn: async () =>
            await requestRegistrationCardAPI(authorization, authKey, customerKey, authObject?.adminId),
        onSuccess: (res) => {
            console.log('res:', res);
            if (res.status === 201) {
                setCardName(res?.data?.cardIssuingBank);
                setCardNumber(res?.data?.cardNumber);
                setIsLoading(false);
                setIsSuccessModal(true);
                setSuccessModalTitle('카드 등록 성공');
                setIsSuccessModalContent('구독 플랜 및 결제정보를 확인해주세요!');
            } else {
                setIsLoading(false);
                setIsFailModal(true);

                res?.response?.data?.message && setFailModalContent(res?.response?.data?.message);
            }
        },
        onError: (error) => {},
    });

    // API(POST) : 결제 요청 api
    const requestPayment = useMutation({
        mutationFn: async () =>
            await requestPaymentAPI(
                authorization,
                authObject?.adminId,
                trainerNumber,
                price,
                email,
                dayjs().format('YYYY-MM-DD'),
                dayjs().add(1, 'month').add(1, 'day').format('YYYY-MM-DD')
            ),
        onSuccess: (res) => {
            if (res.status === 200) {
                const subscriptionData = res.data.subscriptionData;

                const authObjectData = {
                    adminId: authObject?.adminId,
                    id: authObject?.id,
                    name: authObject?.name,
                    email: authObject?.email,
                    gender: authObject?.gender,
                    phoneNumber: authObject?.phoneNumber,
                    profileImageUrl: authObject?.profileImageUrl,
                    centerAddress: authObject?.centerAddress,
                    centerCategory: authObject?.centerCategory,
                    centerCode: authObject?.centerCode,
                    centerName: authObject?.centerName,
                    centerId: authObject?.centerId,
                    isNewCommunicationBox: authObject?.isNewCommunicationBox,

                    // 구독플랜관련
                    coachCount: authObject?.coachCount,
                    memberCount: authObject?.memberCount,
                    activationDate: subscriptionData?.activationDate,
                    expirationDate: subscriptionData?.expirationDate,
                    expirationDelayDate: subscriptionData?.expirationDelayDate,
                    finalPaymentDate: subscriptionData?.finalPaymentDate,
                    finalPaymentTryDate: subscriptionData?.finalPaymentTryDate,
                    cardIssuingBank: subscriptionData?.cardIssuingBank,
                    cardNumber: subscriptionData?.cardNumber,
                    planAmount: subscriptionData?.planAmount,
                    usableStatus: subscriptionData?.usableStatus,

                    paymentDueDate: authObject?.paymentDueDate,

                    /** 구독플랜 인원 정보 */
                    planLimitCount: authObject?.planLimitCount,
                    prevPlanLimitCount: authObject?.prevPlanLimitCount,
                    changedPlanLimitCount: authObject?.changedPlanLimitCount,

                    confirmUsableStatus: false,
                    testAccount: authObject?.testAccount,
                };

                setItemWithExpiration('authObject', authObjectData, 12);

                setIsLoading(false);
                setIsSuccessModal(true);
                setIsCallBack(true);
                setIsSuccessModalContent('결제가 완료되었습니다');
            } else {
                setIsLoading(false);
                setIsFailPayModal(true);
                createAlert('오류', '결제 실패', true);
            }
        },
    });

    const navigateSubscriptionPlan = () => {
        navigate('/myPage/SubscriptionPlanPage', { replace: true });
    };

    useEffect(() => {
        if (authKey && customerKey && authorization && authObject?.adminId) {
            requestRegistrationCard.mutate();
        } else {
            navigateSubscriptionPlan();
        }
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (event: any) => {
            navigate('/dash', { replace: true });
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            // 컴포넌트가 언마운트되면 이벤트 리스너를 제거합니다.
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        if (authObject) {
            if (authObject?.usableStatus === 'trial' || authObject?.usableStatus === 'trialPaymentPending') {
                return;
            } else {
                navigate('/myPage', { replace: true });
            }
        }
    }, [authObject]);

    return (
        <div>
            <Col style={{ marginTop: 64, alignItems: 'center', marginBottom: 200 }}>
                <Row style={{ marginTop: 36 }}>
                    <Col
                        style={{
                            width: 685,
                            height: 706,
                            borderRadius: 16,
                            backgroundColor: colors.WHITE_50,
                            border: '1px solid #C3D4F4',
                        }}
                    >
                        <Body1Bold style={{ color: colors.LAVEL_4, marginLeft: 40, marginTop: 32 }}>
                            {'플랜 선택 정보'}
                        </Body1Bold>

                        <Body2Bold
                            style={{ color: colors.LAVEL_4, marginLeft: 56, marginTop: 32 }}
                        >{`선택 플랜 Rappo Manager (${trainerNumber}명)`}</Body2Bold>

                        <CaptionLight style={{ width: 520, marginLeft: 56, marginTop: 11 }}>
                            {
                                '선택하신 플랜(인원 수)에 따라 강사를 등록할 수 있으며, 등록된 강사는 Rappo App 에서 Platinum Plan과 동일한 혜택\n이 제공되는 Manager Plan을 이용하실 수 있습니다. '
                            }
                        </CaptionLight>

                        <Col style={{ marginLeft: 56, marginTop: 32 }}>
                            <Row style={{ alignItems: 'center' }}>
                                <div
                                    style={{
                                        width: 3,
                                        height: 3,
                                        borderRadius: 1.5,
                                        backgroundColor: colors.LAVEL_3,
                                        marginRight: 5,
                                    }}
                                />
                                <Body3Light style={{ color: colors.LAVEL_3 }}>
                                    {'구독 취소는 [ 마이페이지 > 구독 플랜 > 구독 취소 ] 과정을 통해 가능합니다'}
                                </Body3Light>
                            </Row>
                            <Row style={{ alignItems: 'center' }}>
                                <div
                                    style={{
                                        width: 3,
                                        height: 3,
                                        borderRadius: 1.5,
                                        backgroundColor: colors.LAVEL_3,
                                        marginRight: 5,
                                    }}
                                />
                                <Body3Light style={{ color: colors.LAVEL_3 }}>
                                    {
                                        '정기 결제는 해당 월에 해당되는 날짜가 없는경우( 29일 ~ 31일 ) 그 달 마지막날 결제됩니다'
                                    }
                                </Body3Light>
                            </Row>
                            <Row style={{ alignItems: 'center' }}>
                                <div
                                    style={{
                                        width: 3,
                                        height: 3,
                                        borderRadius: 1.5,
                                        backgroundColor: colors.LAVEL_3,
                                        marginRight: 5,
                                    }}
                                />
                                <Body3Light style={{ color: colors.LAVEL_3 }}>
                                    {'결제가 실패할 경우 7일간의 유예기간이 주어지며 유예기간이 끝나면 연결된 '}
                                </Body3Light>
                            </Row>

                            <Body3Light style={{ color: colors.LAVEL_3, marginLeft: 9 }}>
                                {'모든 라포 서비스를 이용하실 수 없습니다'}
                            </Body3Light>
                        </Col>

                        <Body3Regular style={{ color: colors.LAVEL_4, marginLeft: 56, marginTop: 72 }}>
                            이메일 입력(선택){' '}
                            <CaptionLight style={{ color: colors.LAVEL_1, marginLeft: 8 }}>
                                {'입력하신 이메일로 결제정보가 전송됩니다! '}
                            </CaptionLight>
                        </Body3Regular>
                        <input
                            id="email"
                            name="email"
                            value={email}
                            onChange={onChangeEmail}
                            readOnly={authObject?.testAccount}
                            style={{
                                width: 360,
                                height: 36,
                                borderRadius: 36,
                                paddingLeft: 16,
                                fontSize: 16,
                                fontWeight: 400,
                                marginTop: 8,
                                border: '1px solid #A6BFEF',
                                marginLeft: 56,
                            }}
                            placeholder="이메일을 입력해주세요"
                        />

                        <Row style={{ alignItems: 'center', marginTop: 71 }}>
                            <button
                                type="button"
                                onClick={onClickPolicy}
                                style={{
                                    width: 18,
                                    height: 18,
                                    borderWidth: 1,
                                    borderColor: 'rgba(212, 219, 232, 1)',
                                    borderRadius: 2,
                                    marginLeft: 56,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {isCheckPolicy && <img src={I_PRIMARY_CHECK} />}
                            </button>
                            <button style={{ marginLeft: 24, border: 'none' }} onClick={onClickTerm}>
                                <Body3Regular>
                                    (필수) <Body3Bold>{' 개인정보 수집 및 이용 약관'}</Body3Bold>에 동의합니다.
                                </Body3Regular>
                            </button>
                            <Body3Regular style={{ color: colors.ERROR }}>*</Body3Regular>
                        </Row>

                        <ColorRoundButton
                            text="플랜 결제 요청"
                            size="middle"
                            customStyle={{ width: 280, height: 48, marginTop: 60, marginLeft: 203 }}
                            callBack={onClickPayButton}
                        />
                    </Col>

                    <Col
                        style={{
                            width: 483,
                            height: 706,
                            borderRadius: 16,
                            backgroundColor: colors.WHITE_50,
                            border: '1px solid #C3D4F4',
                            marginLeft: 24,
                        }}
                    >
                        <Body1Bold style={{ marginLeft: 40, marginTop: 32, color: colors.LAVEL_4 }}>
                            {'결제 및 이용 정보'}
                        </Body1Bold>
                        <Col style={{ marginLeft: 56, marginTop: 32 }}>
                            <Body2Regular style={{ color: colors.LAVEL_4 }}>{'적용 할인 혜택'}</Body2Regular>
                            <Body3Regular style={{ color: colors.LAVEL_3 }}>
                                {parseInt(trainerNumber) > 3
                                    ? `Pro Manager Sale (약 60% 할인) - 1인 당  39,900원`
                                    : `Starter Manager Sale (약 65% 할인) - 1인 당  32,900원`}
                            </Body3Regular>

                            <Body2Regular style={{ color: colors.LAVEL_4, marginTop: 40 }}>
                                {'다음 결제 예정일'}
                            </Body2Regular>
                            <Body3Regular style={{ color: colors.BLUE_900 }}>
                                {dayjs().add(1, 'month').format('YYYY년 MM월 DD일')}
                            </Body3Regular>

                            <Body2Regular style={{ color: colors.LAVEL_4, marginTop: 40 }}>
                                {'다음 플랜 이용 기간'}
                            </Body2Regular>

                            {/* 최초결제 시에는 현재부터, 결제 실패로 인한 유예 시에는 최종 결제일부터 날짜를 산정하는 로직 필요 */}
                            <Body3Regular style={{ color: colors.LAVEL_3 }}>
                                {dayjs().format('YYYY년 MM월 DD일 ~')}
                                {dayjs().add(1, 'month').format('YYYY년 MM월 DD일')}
                            </Body3Regular>

                            <Row style={{ alignItems: 'center', marginTop: 40 }}>
                                <Body2Regular style={{ width: 80, color: colors.LAVEL_4 }}>{'정기 결제'}</Body2Regular>
                                <Body3Regular style={{ color: colors.LAVEL_3 }}>
                                    {'(최초 결제일을 기준으로 적용)'}
                                </Body3Regular>
                            </Row>

                            <Row style={{ alignItems: 'center' }}>
                                <Body2Regular style={{ width: 80, color: colors.BLUE_900 }}>
                                    {dayjs().format('매월 DD일')}
                                </Body2Regular>
                            </Row>
                        </Col>

                        <Col
                            style={{
                                width: 403,
                                height: 180,
                                borderRadius: 8,
                                backgroundColor: colors.WHITE_300,
                                marginLeft: 40,
                                marginTop: 40,
                                border: '1px solid #C3D4F4',
                                paddingLeft: 20,
                                paddingTop: 20,
                            }}
                        >
                            <Body2Bold style={{ color: colors.LAVEL_4 }}>{'결제 금액'}</Body2Bold>
                            <Body3Regular style={{ color: colors.LAVEL_3 }}>
                                {price.toLocaleString()}원 <Body3Light> / 월 VAT포함</Body3Light>
                            </Body3Regular>

                            <Body2Bold style={{ color: colors.LAVEL_4, marginTop: 24 }}>{'결제 카드'}</Body2Bold>
                            <Body3Regular style={{ color: colors.LAVEL_3 }}>
                                {cardName} {cardNumber}
                            </Body3Regular>
                        </Col>
                    </Col>
                </Row>
            </Col>
            {isLoading && <Loading text={loadingText} />}

            {isFailModal && (
                <ModalFail
                    modalVisible={isFailModal}
                    setModalVisible={setIsFailModal}
                    navigate={navigate}
                    content={failModalContent}
                />
            )}

            {isSuccessModal && (
                <ModalSuccess
                    modalVisible={isSuccessModal}
                    setModalVisible={setIsSuccessModal}
                    successModalTitle={successModalTitle}
                    successModalContent={successModalContent}
                    navigate={navigate}
                    isCallback={isCallback}
                />
            )}
            {/* 결제 실패모달 */}
            {isFailPayModal && (
                <ModalAlert
                    modalVisible={isFailPayModal}
                    setModalVisible={setIsFailPayModal}
                    title={''}
                    boldContent={'결제 실패'}
                    content={'카드를 확인해주시고 다시 시도하여 주세요'}
                    cancelButtonText={'재시도'}
                    confirmButtonText={'다른 카드 입력'}
                    callBack={navigateSubscriptionPlan}
                />
            )}
        </div>
    );
};

export default SuccessSubscriptionPage;
