import React from 'react';
import { Col } from '../../../lib/utils';
import { Body3Regular } from '../../../lib/font';
import { StyledSignUpInput } from '../SignUpBody2';

const InputEmail = ({ register }: any) => {
    return (
        <Col style={{ marginTop: 32 }}>
            <label htmlFor="email" style={{ display: 'flex', marginBottom: 8 }}>
                <Body3Regular>이메일</Body3Regular>
            </label>

            <StyledSignUpInput
                type="email"
                {...register('email', {
                    pattern: {
                        value: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
                        message: '이메일 형식에 맞지 않습니다.',
                    },
                })}
                placeholder="example@rappo.com"
            />
        </Col>
    );
};

export default InputEmail;
