import React, { useEffect, useState } from 'react';
import { Col, Row, getItemWithExpiration, setItemWithExpiration } from '../../lib/utils';
import Loading from '../../components/Loading';
import {
    Body1Bold,
    Body1Regular,
    Body2Regular,
    Body3Bold,
    Body3Light,
    Body3Regular,
    CaptionLight,
    Head2,
    Head4,
    Subtitle1Bold,
} from '../../lib/font';
import { colors } from '../../lib/colors';
import NormalButton from '../../components/Button/NormalButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { requestRegistrationCardAPI } from '../../api/charge';
import { useMutation } from '@tanstack/react-query';
import ModalFail from '../../components/SuccessSubscription/ModalFail';
import ModalSuccess from '../../components/SuccessSubscription/ModalSuccess';
import dayjs from 'dayjs';
import WhiteSquareButton from '../../components/Button/WhiteSquareButton';
import { I_BOTTOM_PRIMARY_TRIANGLE } from '../../types/images';

const SuccessChangePayInformation = ({ createAlert }: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams: any = new URLSearchParams(location.search);
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));
    const authKey: string = queryParams.get('authKey');
    const customerKey: string = queryParams.get('customerKey');

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [loadingText, setLoadingText] = useState<string>('카드 정보 등록 진행중...');

    const [prevCardName, setPrevCardName] = useState<string>('');
    const [prevCardNumber, setPrevCardNumber] = useState<string>('');

    const [newCardName, setNewCardName] = useState<string>('');
    const [newCardNumber, setNewCardNumber] = useState<string>('');

    // 카드등록, 결제 성공모달
    const [isSuccessModal, setIsSuccessModal] = useState<boolean>(false);
    const [successModalContent, setIsSuccessModalContent] = useState<string>('');

    // 카드등록 실패모달
    const [isFailModal, setIsFailModal] = useState<boolean>(false);
    const [failModalContent, setFailModalContent] = useState<string>('');

    const [isVisibleContent, setIsVisibleContent] = useState<boolean>(false);

    // API(POST) : 빌링키 요청 api
    const requestRegistrationCard = useMutation({
        mutationFn: async () =>
            await requestRegistrationCardAPI(authorization, authKey, customerKey, authObject?.adminId),
        onSuccess: (res) => {
            if (res.status === 201) {
                const response = res.data;

                console.log('response :', response);
                setNewCardName(response?.cardIssuingBank);
                setNewCardNumber(response?.cardNumber);
                setPrevCardName(response?.prevCardIssuingBank);
                setPrevCardNumber(response?.prevCardNubmer);

                const authObjectData = {
                    adminId: authObject?.adminId,
                    id: authObject?.id,
                    name: authObject?.name,
                    email: authObject?.email,
                    gender: authObject?.gender,
                    phoneNumber: authObject?.phoneNumber,
                    profileImageUrl: authObject?.profileImageUrl,

                    centerAddress: authObject?.centerAddress,
                    centerCategory: authObject?.centerCategory,
                    centerCode: authObject?.centerCode,
                    centerName: authObject?.centerName,
                    centerId: authObject?.centerId,
                    isNewCommunicationBox: authObject?.isNewCommunicationBox,

                    // 구독플랜관련
                    coachCount: authObject?.coachCount,
                    memberCount: authObject?.memberCount,
                    activationDate: authObject?.activationDate,
                    expirationDate: authObject?.expirationDate,
                    expirationDelayDate: authObject?.expirationDelayDate,
                    finalPaymentDate: authObject?.finalPaymentDate,
                    finalPaymentTryDate: authObject?.finalPaymentTryDate,

                    cardIssuingBank: response?.cardIssuingBank,
                    cardNumber: response?.cardNumber,
                    planAmount: authObject?.planAmount,
                    usableStatus: authObject?.usableStatus,

                    paymentDueDate: authObject?.paymentDueDate,

                    /** 구독플랜 인원 정보 */
                    planLimitCount: authObject?.planLimitCount,
                    prevPlanLimitCount: authObject?.prevPlanLimitCount,
                    changedPlanLimitCount: authObject?.changedPlanLimitCount,

                    confirmUsableStatus: true,
                    testAccount: authObject?.testAccount,
                };

                setItemWithExpiration('authObject', authObjectData, 12);

                // 상태관련 플래그
                setIsLoading(false);
                setIsSuccessModal(true);
                setIsVisibleContent(true);
                setIsSuccessModalContent('카드 등록에 성공하셨습니다!');
            } else {
                setIsLoading(false);
                setIsFailModal(true);
                setIsVisibleContent(false);
                res?.response?.data?.message && setFailModalContent(res?.response?.data?.message);
            }
        },
        onError: (error) => {},
    });

    useEffect(() => {
        if (authKey && customerKey && authorization && authObject?.adminId) {
            requestRegistrationCard.mutate();
        } else {
            navigate('/myPage', { replace: true });
        }
    }, []);

    useEffect(() => {
        if (authObject) {
            if (authObject?.usableStatus === 'trial') {
                navigate('/myPage', { replace: true });
            } else {
                return;
            }
        }
    }, [authObject]);

    return (
        <div>
            <Col style={{ width: '100%', alignItems: 'center', marginTop: 128, marginBottom: 300 }}>
                {isVisibleContent && (
                    <Col
                        style={{
                            width: 1192,
                            height: 598,
                            borderRadius: 16,
                            paddingLeft: 101,
                            paddingTop: 56,
                            backgroundColor: colors.WHITE_50,
                            border: '1px solid #C3D4F4',
                        }}
                    >
                        <Row style={{ alignItems: 'center' }}>
                            <Body1Bold style={{ color: colors.LAVEL_4 }}>{'결제 정보 등록 변경'}</Body1Bold>
                            <WhiteSquareButton
                                text="마이페이지로 돌아가기"
                                size="small"
                                callBack={() => {
                                    navigate('/myPage', { replace: true });
                                }}
                                customStyle={{ width: 180, height: 36, marginLeft: 649 }}
                            />
                        </Row>
                        <Head4 style={{ color: colors.BLUE_500, marginTop: 32 }}>
                            성공적으로 결제 정보 변경 신청이 완료되었습니다!
                        </Head4>

                        <Row style={{ marginTop: 38 }}>
                            <Col>
                                <Body1Bold style={{ color: colors.LAVEL_4, marginBottom: 32 }}>
                                    선택 플랜 : Rappo Manager ({authObject?.planLimitCount}명)
                                </Body1Bold>
                                <Col
                                    style={{
                                        width: 382,
                                        height: 274,
                                        borderRadius: 8,
                                        padding: 24,

                                        backgroundColor: colors.WHITE_300,
                                        border: '1px solid #C3D4F4',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Col>
                                        <Body2Regular style={{ color: colors.LAVEL_2 }}>이전 결제 카드</Body2Regular>
                                        <Body3Regular style={{ color: colors.LAVEL_1, marginTop: 8 }}>
                                            {authObject?.cardIssuingBank} {authObject?.cardNumber}
                                        </Body3Regular>
                                    </Col>

                                    <Row style={{ width: 56, justifyContent: 'center' }}>
                                        <img src={I_BOTTOM_PRIMARY_TRIANGLE} style={{ width: 16, height: 16 }} />
                                    </Row>

                                    <Col>
                                        <Body2Regular style={{ color: colors.LAVEL_2 }}>신규 결제 카드</Body2Regular>
                                        <Body3Regular style={{ color: colors.LAVEL_1, marginTop: 8 }}>
                                            {newCardName} {newCardNumber}
                                        </Body3Regular>
                                    </Col>
                                </Col>
                            </Col>

                            <Col style={{ width: 400, height: 336, marginLeft: 125 }}>
                                <Body2Regular style={{ color: colors.LAVEL_4 }}>적용 할인 혜택</Body2Regular>
                                <Body3Regular style={{ color: colors.LAVEL_3, marginTop: 8 }}>
                                    {authObject?.planLimitCount > 3
                                        ? 'Pro Manager Sale (약 60% 할인) - 1인 당 39,900원'
                                        : 'Starter Manager Sale (약 65% 할인) - 1인 당 32,900원'}
                                </Body3Regular>

                                <Body2Regular style={{ color: colors.LAVEL_4, marginTop: 32 }}>
                                    다음 결제 예정일
                                </Body2Regular>
                                <Body3Regular style={{ color: colors.LAVEL_3, marginTop: 8 }}>
                                    {dayjs(authObject?.paymentDueDate).format('YYYY년 MM월 DD일 (ddd)')}
                                </Body3Regular>

                                <Body2Regular style={{ color: colors.LAVEL_4, marginTop: 32 }}>
                                    다음 플랜 이용 기간
                                </Body2Regular>

                                <Body3Regular style={{ color: colors.LAVEL_3, marginTop: 8 }}>
                                    {dayjs(authObject?.paymentDueDate)
                                        .subtract(1, 'month')
                                        .format('YYYY년 MM월 DD일 ~ ')}
                                    {dayjs(authObject?.paymentDueDate).format('YYYY년 MM월 DD일')}
                                </Body3Regular>

                                <Row style={{ alignItems: 'center', marginTop: 32 }}>
                                    <Body2Regular style={{ color: colors.LAVEL_4 }}>정기 결제</Body2Regular>
                                    <CaptionLight style={{ color: colors.LAVEL_4, marginLeft: 8 }}>
                                        (최초 결제일을 기준으로 적용)
                                    </CaptionLight>
                                </Row>

                                <Row style={{ alignItems: 'center' }}>
                                    <Body3Regular style={{ color: colors.LAVEL_3, marginTop: 8 }}>
                                        매월 {dayjs(authObject?.paymentDueDate).format('DD')}일
                                    </Body3Regular>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                )}
            </Col>

            {isLoading && <Loading text={loadingText} />}

            {isFailModal && (
                <ModalFail
                    modalVisible={isFailModal}
                    setModalVisible={setIsFailModal}
                    navigate={navigate}
                    content={failModalContent}
                />
            )}

            {isSuccessModal && (
                <ModalSuccess
                    modalVisible={isSuccessModal}
                    setModalVisible={setIsSuccessModal}
                    successModalContent={successModalContent}
                    navigate={navigate}
                />
            )}
        </div>
    );
};

export default SuccessChangePayInformation;
