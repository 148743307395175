import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, getItemWithExpiration, Row } from '../../../lib/utils';
import { Body2Bold, Body3Regular, CaptionBold, Subtitle1Bold } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import { I_CLOSE, I_POPUP_EXIT } from '../../../types/images';
import dayjs from 'dayjs';
import NormalToggleButton from '../../ToggleButton/NormalToggleButton';
import WhiteSquareButton from '../../Button/WhiteSquareButton';
import ColorSquareButton from '../../Button/ColorSquareButton';
import {
    createGeneralScheduleAPI,
    deleteScheduleAPI,
    modifyScheduleAttendance,
    approveScheduleRequestAPI,
    rejectScheduleRequestAPI,
} from '../../../api/schedule';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
`;

const ModalRequest = styled.div`
    background: #f5f9ff;
    width: 928px;
    height: 564px;
    border-radius: 8px;
    box-shadow: 8px 8px 32px 0px #0000007a;

    background-color: #fdfeff;
    display: flex;
    flex-direction: column;
`;

const ModalChange = styled.div`
    background: #f5f9ff;
    width: 928px;
    height: 630px;
    border-radius: 8px;
    box-shadow: 8px 8px 32px 0px #0000007a;

    background-color: #fdfeff;
    display: flex;
    flex-direction: column;
`;

const HoverImage = styled.img`
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

type Props = {
    modalVisible: boolean;
    setModalVisible: Dispatch<SetStateAction<boolean>>;
    navigate: any;
    detailObject: any;
    setDetailObject: any;
    onClickRejectScheduleRequest: any;
    onClickApproveScheduleRequest: any;
    createAlert: any;
    authObject: any;
    calendarType: 'daily' | 'weekly';
    calendarSelectedDay: string;
    calendarScrollY: number;
};

const RequestDetailModal = ({
    modalVisible,
    setModalVisible,
    navigate,
    detailObject,
    setDetailObject,
    onClickRejectScheduleRequest,
    onClickApproveScheduleRequest,
    createAlert,
    authObject,
    calendarType,
    calendarSelectedDay,
    calendarScrollY,
}: Props) => {
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const queryClient = useQueryClient();
    const isGoingSchedule = dayjs().isAfter(detailObject?.startTime) && dayjs().isBefore(detailObject?.endTime);
    const [attendState, setAttendState] = useState<string>('ATTENDANCE');
    const [scheduleObject, setScheduleObject] = useState(detailObject);

    useEffect(() => {
        if (modalVisible) {
            // 모달이 열릴 때 body의 스크롤을 막음
            document.body.style.overflow = 'hidden';
        } else {
            // 모달이 닫힐 때 body의 스크롤을 복원
            document.body.style.overflow = 'auto';
        }

        // 컴포넌트가 언마운트될 때 스크롤을 복원
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [modalVisible]);
    console.log(scheduleObject);

    return (
        <ModalOverLay>
            {scheduleObject.requestType === 'register' ? (
                <ModalRequest>
                    <Row>
                        <Subtitle1Bold style={{ color: colors.LAVEL_4, marginLeft: 48, marginTop: 36 }}>
                            {'수업 신청 대기 자세히보기'}
                        </Subtitle1Bold>

                        <Row
                            style={{
                                width: 112,
                                height: 32,
                                borderRadius: 4,
                                border: '1px solid #FF8777',
                                marginTop: 38,
                                marginLeft: 16,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Body3Regular style={{ color: '#FF8777' }}>수업 신청 대기</Body3Regular>
                        </Row>

                        <HoverImage
                            onClick={() => {
                                setModalVisible(false);
                                setDetailObject(null);
                            }}
                            src={I_POPUP_EXIT}
                            style={{ marginTop: 16, width: 32, height: 32, marginLeft: 448 }}
                        />
                    </Row>

                    <Row style={{ marginTop: 48, alignItems: 'center' }}>
                        <Col style={{ width: 310, height: 68, marginLeft: 72, justifyContent: 'space-between' }}>
                            <Body3Regular style={{ color: colors.LAVEL_4 }}>담당자</Body3Regular>
                            <Row
                                style={{
                                    width: 310,
                                    height: 36,
                                    borderRadius: 36,
                                    border: '1px solid #ededed',
                                    paddingLeft: 16,
                                    backgroundColor: colors.WHITE_500,
                                    alignItems: 'center',
                                }}
                            >
                                <Body3Regular
                                    style={{ color: colors.LAVEL_1 }}
                                >{`${scheduleObject?.coachName} 강사`}</Body3Regular>
                            </Row>
                        </Col>

                        <Col style={{ width: 340, height: 68, marginLeft: 104, justifyContent: 'space-between' }}>
                            <Body3Regular style={{ color: colors.LAVEL_4 }}>날짜</Body3Regular>
                            <Row
                                style={{
                                    width: 340,
                                    height: 36,
                                    borderRadius: 36,
                                    border: '1px solid #ededed',
                                    paddingLeft: 16,
                                    backgroundColor: colors.WHITE_500,
                                    alignItems: 'center',
                                }}
                            >
                                <Body3Regular style={{ color: colors.LAVEL_1 }}>{`${dayjs(
                                    scheduleObject?.startTime
                                ).format('YYYY년 MM월 DD일 (ddd)')}`}</Body3Regular>
                            </Row>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: 48, alignItems: 'center' }}>
                        <Col style={{ width: 310, height: 68, marginLeft: 72, justifyContent: 'space-between' }}>
                            <Body3Regular style={{ color: colors.LAVEL_4 }}>회원</Body3Regular>
                            <Row
                                style={{
                                    width: 310,
                                    height: 36,
                                    borderRadius: 36,
                                    border: '1px solid #ededed',
                                    paddingLeft: 16,
                                    backgroundColor: colors.WHITE_500,
                                    alignItems: 'center',
                                }}
                            >
                                <Body3Regular
                                    style={{ color: colors.LAVEL_1 }}
                                >{`${scheduleObject?.alias} 회원`}</Body3Regular>
                            </Row>
                        </Col>

                        <Col style={{ width: 340, height: 68, marginLeft: 104, justifyContent: 'space-between' }}>
                            <Body3Regular style={{ color: colors.LAVEL_4 }}>시간</Body3Regular>
                            <Row
                                style={{
                                    width: 340,
                                    height: 36,
                                    borderRadius: 36,
                                    border: '1px solid #ededed',
                                    paddingLeft: 16,
                                    backgroundColor: colors.WHITE_500,
                                    alignItems: 'center',
                                }}
                            >
                                <Body3Regular style={{ color: colors.LAVEL_1 }}>{`${dayjs(
                                    scheduleObject?.startTime
                                ).format('A hh시 mm분 ~ ')}  ${dayjs(scheduleObject?.endTime).format(
                                    'A hh시 mm분'
                                )}`}</Body3Regular>
                            </Row>
                        </Col>
                    </Row>

                    <Row style={{ marginLeft: 72 }}>
                        <Row
                            style={{
                                width: 310,
                                height: 92,
                                borderRadius: 16,
                                marginTop: 16,
                                background: `${
                                    scheduleObject?.membership?.status === 'active'
                                        ? `linear-gradient(135deg, ${scheduleObject?.membership?.BGColor[0]} 0%, ${scheduleObject?.membership?.BGColor[1]} 100%)`
                                        : colors.GREY_200
                                }`,
                                paddingLeft: 24,
                                paddingRight: 24,
                                paddingTop: 18,
                                paddingBottom: 18,
                            }}
                        >
                            <Body2Bold style={{ color: colors.LAVEL_0 }}>{scheduleObject?.membership?.name}</Body2Bold>
                        </Row>
                    </Row>

                    <Row
                        style={{
                            width: 312,
                            marginLeft: 580,
                            marginTop: 50,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <WhiteSquareButton
                            text="거절하기"
                            size="middle"
                            callBack={() => {
                                onClickRejectScheduleRequest(scheduleObject);
                            }}
                        />
                        <ColorSquareButton
                            text="등록하기"
                            size="middle"
                            callBack={() => {
                                onClickApproveScheduleRequest(scheduleObject);
                            }}
                        />
                    </Row>
                </ModalRequest>
            ) : (
                <ModalChange>
                    <Row>
                        <Subtitle1Bold style={{ color: colors.LAVEL_4, marginLeft: 48, marginTop: 36 }}>
                            {'수업 변경 대기 자세히보기'}
                        </Subtitle1Bold>

                        <Row
                            style={{
                                width: 112,
                                height: 32,
                                borderRadius: 4,
                                border: '1px solid #FF8777',
                                marginTop: 38,
                                marginLeft: 16,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Body3Regular style={{ color: '#FF8777' }}>수업 변경 대기</Body3Regular>
                        </Row>

                        <HoverImage
                            onClick={() => {
                                setModalVisible(false);
                                setDetailObject(null);
                            }}
                            src={I_POPUP_EXIT}
                            style={{ marginTop: 16, width: 32, height: 32, marginLeft: 448 }}
                        />
                    </Row>

                    <Row>
                        <Col style={{ marginTop: 48 }}>
                            <Col style={{ width: 310, height: 68, marginLeft: 72, justifyContent: 'space-between' }}>
                                <Body3Regular style={{ color: colors.LAVEL_4 }}>담당자</Body3Regular>
                                <Row
                                    style={{
                                        width: 310,
                                        height: 36,
                                        borderRadius: 36,
                                        border: '1px solid #ededed',
                                        paddingLeft: 16,
                                        backgroundColor: colors.WHITE_500,
                                        alignItems: 'center',
                                    }}
                                >
                                    <Body3Regular
                                        style={{ color: colors.LAVEL_1 }}
                                    >{`${scheduleObject?.coachName} 강사`}</Body3Regular>
                                </Row>
                            </Col>
                            <Col
                                style={{
                                    width: 310,
                                    height: 68,
                                    marginTop: 32,
                                    marginLeft: 72,
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Body3Regular style={{ color: colors.LAVEL_4 }}>회원</Body3Regular>
                                <Row
                                    style={{
                                        width: 310,
                                        height: 36,
                                        borderRadius: 36,
                                        border: '1px solid #ededed',
                                        paddingLeft: 16,
                                        backgroundColor: colors.WHITE_500,
                                        alignItems: 'center',
                                    }}
                                >
                                    <Body3Regular
                                        style={{ color: colors.LAVEL_1 }}
                                    >{`${scheduleObject?.alias} 회원`}</Body3Regular>
                                </Row>
                            </Col>
                            <Col style={{ marginLeft: 72 }}>
                                <Row
                                    style={{
                                        width: 310,
                                        height: 92,
                                        borderRadius: 16,
                                        marginTop: 16,
                                        background: `${
                                            scheduleObject?.membership?.status === 'active'
                                                ? `linear-gradient(135deg, ${scheduleObject?.membership?.BGColor[0]} 0%, ${scheduleObject?.membership?.BGColor[1]} 100%)`
                                                : colors.GREY_200
                                        }`,
                                        paddingLeft: 24,
                                        paddingRight: 24,
                                        paddingTop: 18,
                                        paddingBottom: 18,
                                    }}
                                >
                                    <Body2Bold style={{ color: colors.LAVEL_0 }}>
                                        {scheduleObject?.membership?.name}
                                    </Body2Bold>
                                </Row>
                            </Col>
                        </Col>

                        <Col
                            style={{
                                marginTop: 48,
                                marginLeft: 104,
                            }}
                        >
                            <Col
                                style={{
                                    width: 380,
                                    height: 184,
                                    borderRadius: 16,
                                    border: '1px solid var(--GrayScale-gray-100, #C7C7C7)',
                                    backgroundColor: 'var(--PrimaryColor-White-White-100, #F8FBFF)',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    position: 'relative',
                                }}
                            >
                                <Row
                                    style={{
                                        justifyContent: 'flex-start',
                                    }}
                                >
                                    <Row style={{ width: 340 }}>
                                        <Body3Regular style={{ color: colors.LAVEL_4 }}>날짜</Body3Regular>
                                    </Row>
                                </Row>
                                <Row
                                    style={{
                                        width: 101,
                                        height: 26,
                                        borderRadius: 24,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: colors.GREY_100,
                                        position: 'absolute',
                                        left: 269,
                                        top: 10,
                                    }}
                                >
                                    <CaptionBold style={{ color: colors.LAVEL_0 }}>변경 전 (기존)</CaptionBold>
                                </Row>

                                <Row
                                    style={{
                                        width: 340,
                                        height: 36,
                                        borderRadius: 36,
                                        border: '1px solid #ededed',
                                        paddingLeft: 16,
                                        backgroundColor: colors.WHITE_500,
                                        alignItems: 'center',
                                        marginTop: 8,
                                    }}
                                >
                                    <Body3Regular style={{ color: colors.LAVEL_1 }}>{`${dayjs(
                                        scheduleObject?.startTime
                                    ).format('YYYY년 MM월 DD일 (ddd)')}`}</Body3Regular>
                                </Row>

                                <Col
                                    style={{
                                        width: 340,
                                        height: 68,
                                        marginTop: 16,
                                        justifyContent: 'flex',
                                    }}
                                >
                                    <Body3Regular style={{ color: colors.LAVEL_4 }}>시간</Body3Regular>
                                    <Row
                                        style={{
                                            width: 340,
                                            height: 36,
                                            borderRadius: 36,
                                            border: '1px solid #ededed',
                                            paddingLeft: 16,
                                            backgroundColor: colors.WHITE_500,
                                            alignItems: 'center',
                                            marginTop: 8,
                                        }}
                                    >
                                        <Body3Regular style={{ color: colors.LAVEL_1 }}>{`${dayjs(
                                            scheduleObject?.startTime
                                        ).format('A hh시 mm분 ~ ')}  ${dayjs(scheduleObject?.endTime).format(
                                            'A hh시 mm분'
                                        )}`}</Body3Regular>
                                    </Row>
                                </Col>
                            </Col>
                            <Col
                                style={{
                                    marginTop: 16,
                                    width: 380,
                                    height: 184,
                                    borderRadius: 16,
                                    border: '1px solid var(--AccentColor-Orange, #FF8777)',
                                    backgroundColor: 'var(--PrimaryColor-White-White-100, #F8FBFF)',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    position: 'relative',
                                }}
                            >
                                <Row
                                    style={{
                                        justifyContent: 'flex-start',
                                    }}
                                >
                                    <Row style={{ width: 340 }}>
                                        <Body3Regular style={{ color: colors.LAVEL_4 }}>날짜</Body3Regular>
                                    </Row>
                                </Row>
                                <Row
                                    style={{
                                        width: 101,
                                        height: 26,
                                        borderRadius: 24,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: colors.ORANGE,
                                        position: 'absolute',
                                        left: 269,
                                        top: 10,
                                    }}
                                >
                                    <CaptionBold style={{ color: colors.LAVEL_0 }}>변경 후 (요청)</CaptionBold>
                                </Row>

                                <Row
                                    style={{
                                        width: 340,
                                        height: 36,
                                        borderRadius: 36,
                                        border: '1px solid #ededed',
                                        paddingLeft: 16,
                                        backgroundColor: colors.WHITE_500,
                                        alignItems: 'center',
                                        marginTop: 8,
                                    }}
                                >
                                    <Body3Regular style={{ color: colors.LAVEL_1 }}>{`${dayjs(
                                        scheduleObject?.startTime
                                    ).format('YYYY년 MM월 DD일 (ddd)')}`}</Body3Regular>
                                </Row>

                                <Col
                                    style={{
                                        width: 340,
                                        height: 68,
                                        marginTop: 16,
                                        justifyContent: 'flex',
                                    }}
                                >
                                    <Body3Regular style={{ color: colors.LAVEL_4 }}>시간</Body3Regular>
                                    <Row
                                        style={{
                                            width: 340,
                                            height: 36,
                                            borderRadius: 36,
                                            border: '1px solid #ededed',
                                            paddingLeft: 16,
                                            backgroundColor: colors.WHITE_500,
                                            alignItems: 'center',
                                            marginTop: 8,
                                        }}
                                    >
                                        <Body3Regular style={{ color: colors.LAVEL_1 }}>{`${dayjs(
                                            scheduleObject?.startTime
                                        ).format('A hh시 mm분 ~ ')}  ${dayjs(scheduleObject?.endTime).format(
                                            'A hh시 mm분'
                                        )}`}</Body3Regular>
                                    </Row>
                                </Col>
                            </Col>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginTop: 52,
                            marginLeft: 580,
                            alignItems: 'center',
                        }}
                    >
                        <WhiteSquareButton
                            text="거절하기"
                            size="middle"
                            callBack={() => {
                                onClickRejectScheduleRequest(scheduleObject);
                            }}
                        />
                        <ColorSquareButton
                            customStyle={{ marginLeft: 16 }}
                            text="등록하기"
                            size="middle"
                            callBack={() => {
                                onClickApproveScheduleRequest(scheduleObject);
                            }}
                        />
                    </Row>
                </ModalChange>
            )}
        </ModalOverLay>
    );
};

export default RequestDetailModal;
