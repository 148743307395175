import axios, { AxiosResponse } from 'axios';

export /**
 * @method post
 * @brief 관리자 회원가입
 */
const createAccountAPI = async (
    adminId: string,
    password: string,
    name: string,
    phoneNumber: string,
    gender: string,
    email: string,
    centerName: string,
    centerCategory: string,
    centerAddress: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/v1/admins/signup`,
            headers: {},
            data: {
                adminId,
                password,
                name,
                phoneNumber,
                gender,
                email,
                centerName,
                centerCategory,
                centerAddress,
            },
        });

        if (response.status === 201) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 409:
                alert('중복된 ID 입니다');
                return { status: error.response.status };
            case 500:
                return { status: error.response.status };
        }
    }
};

export /**
 * @method post
 * @brief 관리자 로그인
 */
const requestSignInAPI = async (adminId: string, password: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/v1/admins/signin`,
            headers: {},
            data: {
                adminId,
                password,
            },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 418:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method post
 * @brief 테스트 계정 로그인
 */
const requestTestAccountSignInAPI = async (): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/v1/admins/demo-signin`,
            headers: {},
            data: {},
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 418:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method get
 * @brief 관리자 id 중복검사
 */
const getCheckDuplicateIdAPI = async (adminId: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/admins/check-duplicate-id/${adminId}`,
            headers: {},
            data: {},
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 409:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method post
 * @brief 인증번호 발급
 */
const requestIssueSmsCodeAPI = async (phoneNumber: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/v1/admins/issue-smscode`,
            headers: {},
            data: {
                phoneNumber,
            },
        });

        if (response.status === 201) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 409:
                return { status: error.response.status };
            case 418:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method post
 * @brief 인증번호 검증
 */
const requestVerifySmsCodeAPI = async (phoneNumber: string, smsCode: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/v1/admins/verify-smscode`,
            headers: {},
            data: {
                phoneNumber,
                smsCode,
            },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        console.log(error);
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 409:
                return { status: error.response.status };
            case 418:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};
