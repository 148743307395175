import { Col } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import FeedProfileItem from './FeedProfileItem';
import FeedTextContent from './FeedTextContent';
import FeedItemFooter from './FeedItemFooter';
import FeedImageItem from './FeedImageItem';
import FeedScheduleItem from './FeedScheduleItem';
import _ from 'lodash';
import { FeedListType } from '../../../scenes/Feed/FeedPage';
import FeedFixedIcon from './FeedFixedIcon';
import FeedEmpty from './FeedEmpty';
import FeedReply from './FeedReply';
import FeedAttachedFile from './FeedAttachedFile';

type Props = {
    onClickDetail: (object: any) => void;
    feedList: FeedListType;
    setTarget: any;
};

// 140 (공백포함)
// 110 (공백미포함)
const FeedList = ({ onClickDetail, feedList, setTarget }: Props) => {
    return (
        <Col>
            {feedList && feedList.length > 0 ? (
                feedList.map((item: any, index: number) => {
                    const imageObject = item?.contents?.filter((item: any) => item.contentType === 'IMG');
                    const videoObject = item?.contents?.filter((item: any) => item.contentType === 'VIDEO');
                    const imageVideoList = _.cloneDeep(imageObject);
                    videoObject && imageVideoList.push(...videoObject);
                    const textObject = item?.contents?.filter((item: any) => item.contentType === 'TEXT');
                    const fileObject = item?.contents?.filter((item: any) => item.contentType === 'FILE');

                    const initialPost = item?.initialPost; // 피드 글에 답글 회원->강사
                    const comments = item?.comments; // 댓글
                    const reactions = item?.reactions; // 좋아요
                    const role = item?.role; // 강사 or 멤버
                    const postType = item?.postType; //
                    const scheduleReport = item?.scheduleReport; // 스케줄기록

                    return (
                        <Col
                            key={index + 'memberFeedList'}
                            style={{
                                width: 786,
                                borderRadius: 24,
                                backgroundColor: colors.WHITE_50,
                                border: '1px solid #C3D4F4',
                                paddingBottom: 26,
                                marginBottom: 36,
                            }}
                        >
                            {/* 공지글 북마크아이콘 */}
                            <FeedFixedIcon item={item} />

                            {/* 상단 강사, 회원 이름 날짜 */}
                            <FeedProfileItem item={item} postType={postType} role={role} />

                            {/* 회원의 스케줄 (수업일지인 경우) */}
                            {item?.postType === 'SCHEDULEREPORT' && (
                                <FeedScheduleItem item={item} scheduleReport={scheduleReport} />
                            )}

                            {/* 본문 텍스트 글 */}
                            {textObject && <FeedTextContent textObject={textObject} />}

                            {/* 이미지, 비디오 */}
                            {imageVideoList.length > 0 && postType !== 'REPLYBASIC' && (
                                <FeedImageItem
                                    imageVideoList={imageVideoList}
                                    fileObject={fileObject}
                                    index={index}
                                    onClickDetail={() => onClickDetail(item)}
                                />
                            )}

                            {/* 첨부파일 */}
                            {fileObject?.length > 0 && imageVideoList?.length === 0 && (
                                <FeedAttachedFile fileObject={fileObject} />
                            )}

                            {/* 답글 */}
                            {postType === 'REPLYBASIC' && initialPost && (
                                <FeedReply item={item} initialPost={initialPost} />
                            )}

                            {/* 하단 좋아요, 댓글, 전체보기 버튼 */}
                            <FeedItemFooter
                                reactions={reactions}
                                comments={comments}
                                onClickDetail={() => onClickDetail(item)}
                            />
                        </Col>
                    );
                })
            ) : (
                <FeedEmpty />
            )}
            <div ref={setTarget}></div>
        </Col>
    );
};

export default FeedList;
