import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, concatDateAndTimeDayjs, isSameDate, Row } from '../../../lib/utils';
import { Body3Regular, Subtitle1Bold } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import WhiteSquareButton from '../../Button/WhiteSquareButton';
import ColorSquareButton from '../../Button/ColorSquareButton';
import CreateEtcScheduleLeft from './CreateEtcScheduleLeft';
import CreateEtcScheduleRight from './CreateEtcScheduleRight';
import Calendar from '../../Calendar/Calendar';
import { useMutation } from '@tanstack/react-query';
import { createGeneralScheduleAPI } from '../../../api/schedule';

type RefineDate = {
    refineStartTime: string;
    refineEndTime: string;
};

const CreateEtcSchedule = ({
    coachOption,
    authorization,
    authObject,
    createAlert,
    selectedSchedule,
    calendarType,
    calendarSelectedDay,
    calendarScrollY,
}: any) => {
    const navigate = useNavigate();
    const currentTime = selectedSchedule ? dayjs(selectedSchedule?.scheduleStartTime) : dayjs();
    const roundedTime = currentTime.minute(Math.floor(currentTime.minute() / 10) * 10).second(0);
    const currentTimeAdd50 = dayjs(roundedTime).add(50, 'minute');

    /**
     * title : 일정 제목
     * coach : 담당강사
     * startTime : 시작 시간
     * endTime : 종료시간
     * selectedDay : 날짜
     */
    const [title, setTitle] = useState<string>('');
    const [coach, setCoach] = useState({
        value: selectedSchedule ? selectedSchedule?.coachId : '',
        label: selectedSchedule ? selectedSchedule?.coachName : '강사를 선택해주세요!',
    });

    const [startTime, setStartTime] = useState({ value: roundedTime, label: dayjs(roundedTime).format('A hh:mm') });
    const [endTime, setEndTime] = useState({
        value: currentTimeAdd50,
        label: dayjs(currentTimeAdd50).format('A hh:mm'),
    });
    const [timeList, setTimeList] = useState<any>(null); // 10분단위 시간 배열

    /** 캘린더 state */
    const [selectedDay, setSelectedDay] = useState<any>(
        selectedSchedule ? new Date(selectedSchedule?.scheduleStartTime) : new Date()
    );
    const [isCalendar, setIsCalendar] = useState<boolean>(false);

    const handleChangeTitle = (e: any) => {
        setTitle(e.target.value);
    };

    // 담당 강사 선택
    const handleCoachSelect = (option: any) => {
        setCoach(option);
    };
    // 시작 시간 선택
    const handleStartTimeSelect = (option: any) => {
        setStartTime(option);
    };

    // 종료 시간 선택
    const handleEndTimeSelect = (option: any) => {
        setEndTime(option);
    };

    // 시간추가
    const onClickAddTime = (addNumber: number) => {
        if (
            dayjs(startTime.value).format('YYYY-MM-DD') ===
            dayjs(endTime.value).add(addNumber, 'minute').format('YYYY-MM-DD')
        ) {
            setEndTime({
                value: dayjs(endTime.value).add(addNumber, 'minute'),
                label: dayjs(dayjs(endTime.value).add(addNumber, 'minute')).format('A hh:mm'),
            });
        } else {
            createAlert('입력 오류', '추가 가능한 시간을 초과하였습니다', '확인', () => {});
        }
    };

    // 시간빼기
    const onClickSubTime = (subNumber: number) => {
        if (dayjs(startTime.value).isBefore(dayjs(endTime.value).subtract(subNumber, 'minute'))) {
            setEndTime({
                value: dayjs(endTime.value).subtract(subNumber, 'minute'),
                label: dayjs(dayjs(endTime.value).subtract(subNumber, 'minute')).format('A hh:mm'),
            });
        } else {
            createAlert('입력 오류', '감소 가능한 시간을 초과하였습니다', '확인', () => {});
        }
    };

    // 일반 일정 생성 API : (POST)
    const createGeneralSchedule = useMutation({
        mutationFn: async (refineDate: RefineDate) =>
            await createGeneralScheduleAPI(
                authObject?.centerId,
                coach.value,
                title,
                refineDate?.refineStartTime,
                refineDate?.refineEndTime,
                authorization
            ),
        onSuccess: (res, refineDate) => {
            if (res.status === 201) {
                const startTime = refineDate.refineStartTime;
                navigate('/schedule', {
                    state: { calendarType, calendarTimeInfo: startTime },
                    replace: true,
                });
            } else if (res.status === 410 || res.status === 420 || res.status === 430) {
                createAlert(
                    '중복',
                    `선택된 담당 강사 ${coach?.label}님이\n해당시간에 중복된 스케줄이 존재합니다`,
                    true
                );
            } else {
                createAlert('오류', `서버 내부 오류\n 지속적인 오류발생시 고객센터로 문의바랍니다`, true);
            }
        },
    });

    // 일반일정 등록 버튼 클릭이벤트
    const onClickCreateSchedule = () => {
        if (coach?.value === '') {
            createAlert('필수 입력', '담당 강사를 선택해주세요', true);
            return;
        }

        if (title.trim() === '') {
            createAlert('필수 입력', '일정 제목을 입력해주세요', true);
            return;
        }

        const concatStartTime = dayjs(concatDateAndTimeDayjs(selectedDay, startTime.value));
        const concatEndTime = dayjs(concatDateAndTimeDayjs(selectedDay, endTime.value));

        const refineStartTime = dayjs(concatStartTime).toISOString();
        const refineEndTime =
            dayjs(concatEndTime).format('HH:mm') === '00:00'
                ? dayjs(concatEndTime).add(1, 'day').toISOString()
                : dayjs(concatEndTime).toISOString();
        if (isSameDate(dayjs(refineStartTime), dayjs(refineEndTime))) {
            createAlert('입력 오류', '시작 시간과 완료 시간이 같습니다!\n다시 한번 확인해주세요!', '확인', () => {});
            return;
        }

        if (dayjs(refineStartTime).isAfter(dayjs(refineEndTime))) {
            createAlert(
                '입력 오류',
                '시작 시간이 완료 시간보다 늦을 수  없습니다!\n다시 한번 확인해주세요!',
                '확인',
                () => {}
            );
            return;
        }

        const refineDate: RefineDate = {
            refineStartTime: refineStartTime,
            refineEndTime: refineEndTime,
        };

        createGeneralSchedule.mutate(refineDate);
    };

    // 시작 시간이 변할때 endTime정제
    useEffect(() => {
        if (startTime) {
            const add50Min: Dayjs = dayjs(startTime.value).add(50, 'minute');

            if (
                add50Min.isAfter(dayjs(startTime.value).endOf('day')) ||
                isSameDate(add50Min, dayjs(startTime.value).endOf('day'))
            ) {
                setEndTime({
                    value: dayjs().endOf('day').add(1, 'millisecond'),
                    label: dayjs().endOf('day').add(1, 'millisecond').format('A hh:mm'),
                });
            } else {
                setEndTime({ value: dayjs(add50Min), label: dayjs(add50Min).format('A hh:mm') });
            }
        }
    }, [startTime]);

    // 시간 10분단위로 생성
    useEffect(() => {
        // 시간생성
        const generateTimeSlots = () => {
            const timeSlots = [];
            const start = dayjs().startOf('day').hour(0).minute(0);
            for (let i = 0; i < 144; i++) {
                // 24 hours * 6 (10분 간격)
                const time = start.add(i * 10, 'minute');
                const formattedTime = time.format('A hh:mm'); // 오전/오후 표시
                timeSlots.push({ value: time, label: formattedTime });
            }
            return timeSlots;
        };

        const timeSlots = generateTimeSlots();
        setTimeList(timeSlots);
    }, []);

    return (
        <Col style={{ paddingLeft: 101, paddingTop: 56, paddingRight: 101, paddingBottom: 64 }}>
            <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <Row style={{ alignItems: 'flex-end' }}>
                    <Subtitle1Bold style={{ color: colors.LAVEL_4 }}>{'일반일정 등록하기'}</Subtitle1Bold>
                    <Body3Regular style={{ color: colors.LAVEL_1, marginBottom: 3, marginLeft: 16 }}>
                        {'이 시간에는 회원님이 수업신청을 할 수 없어요!'}
                    </Body3Regular>
                </Row>

                <Row style={{ alignItems: 'center', width: 252, justifyContent: 'space-between' }}>
                    <WhiteSquareButton
                        customStyle={{ width: 100, height: 36 }}
                        text="돌아가기"
                        size="small"
                        callBack={() => {
                            navigate('/schedule', {
                                state: { calendarType, calendarSelectedDay, calendarScrollY },
                                replace: true,
                            });
                        }}
                    />
                    <ColorSquareButton
                        isGradient={true}
                        customStyle={{ width: 136, height: 36 }}
                        text="일반일정 등록"
                        size="small"
                        callBack={onClickCreateSchedule}
                    />
                </Row>
            </Row>

            <Row style={{ marginTop: 56 }}>
                <CreateEtcScheduleLeft
                    coachOption={coachOption}
                    coach={coach}
                    handleCoachSelect={handleCoachSelect}
                    title={title}
                    setTitle={setTitle}
                    handleChangeTitle={handleChangeTitle}
                />
                <CreateEtcScheduleRight
                    coachOption={coachOption}
                    coach={coach}
                    handleCoachSelect={handleCoachSelect}
                    selectedDay={selectedDay}
                    setIsCalendar={setIsCalendar}
                    handleEndTimeSelect={handleEndTimeSelect}
                    handleStartTimeSelect={handleStartTimeSelect}
                    startTime={startTime}
                    endTime={endTime}
                    timeList={timeList}
                    onClickAddTime={onClickAddTime}
                    onClickSubTime={onClickSubTime}
                />
            </Row>

            {isCalendar && (
                <Calendar
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    setIsCalendar={setIsCalendar}
                    isPrevMonth={true}
                    isNextMonth={true}
                    style={{
                        width: 420,
                        height: 370,
                        borderRadius: 24,
                        backgroundColor: colors.WHITE_50,
                        boxShadow: '1px 1px 8px 0px #2B529D2E',
                        position: 'absolute',
                        zIndex: 1999,
                        marginTop: 190,
                        marginLeft: 500,
                    }}
                />
            )}
        </Col>
    );
};

export default CreateEtcSchedule;
