import { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createAccountAPI, getCheckDuplicateIdAPI, requestIssueSmsCodeAPI, requestVerifySmsCodeAPI } from '../api/auth';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import SignUpHeader from '../components/SignUp/SignUpHeader';
import SignUpBody1 from '../components/SignUp/SignUpBody1';
import SignUpBody2 from '../components/SignUp/SignUpBody2';
import { Col } from '../lib/utils';

interface FormValue {
    adminId: string;
    password: string;
    passwordConfirm: string;
    gender: string;
    name: string;
    email: string;
    phoneNumber: string;
    authNumber: string;
    centerName: string;
    centerCategory: string;
    centerAddress: string;
}

export type StepType = 'first' | 'second' | 'third' | 'finish';

const SignUpPage = ({ createAlert, onClickTerm }: any) => {
    const navigate = useNavigate();

    // useForm을 이용한 form 관리
    const {
        register,
        control,
        handleSubmit: onSubmit,
        watch,
        getValues,
        formState: { errors },
    } = useForm<FormValue>({
        mode: 'onChange',
        defaultValues: {
            adminId: '',
            password: '',
            passwordConfirm: '',
            gender: '',
            name: '',
            email: '',
            centerName: '',
            centerCategory: '',
            centerAddress: '',
        },
    });

    let genderOptions: any = [
        { value: 'male', label: '남성' },
        { value: 'female', label: '여성' },
    ];

    let categoryOptions: any = [
        { value: 'PT', label: 'PT' },
        { value: '헬스', label: '헬스' },
        { value: '필라테스', label: '필라테스' },
        { value: '요가', label: '요가' },
        { value: '기타 체육시설', label: '기타 체육시설' },
        { value: '그 외', label: '그 외' },
    ];

    /**
     * phoneNumber : 휴대전화번호
     * authNumber : 인증번호
     * step : 회원가입 스텝 < first | second | third>
     * selectedGender : 성별
     * selectedOption : 업종
     */
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [authNumber, setAuthNumber] = useState<string>('');
    const [step, setStep] = useState<StepType>('first');
    const [selectedGender, setSelectedGender] = useState({ value: '', label: '-' });
    const [selectedCategory, setSelectedCategory] = useState({ value: '', label: '-' });

    /**
     * isCheckDuplicated : 중복검사 확인 플래그
     * isAuthNumber : 전화번호 인증 확인 플래그
     * isCheckAge : 14세이상 확인
     * isCheckPrivacy : 개인정보 수집 및 이용약관 동의
     */
    const [isCheckDuplicated, setIsCheckDuplicated] = useState<boolean>(false);
    const [isCheckAuthNumber, setIsCheckAuthNumber] = useState<boolean>(false);
    const [isCheckAge, setIsCheckAge] = useState<boolean>(false);
    const [isCheckPolicy, setIsCheckPolicy] = useState<boolean>(false);

    // 타이머관련 변수
    const initialMinutes = 3;
    const initialSeconds = 0;
    const [minutes, setMinutes] = useState(initialMinutes);
    const [seconds, setSeconds] = useState(initialSeconds);
    const [isRunning, setIsRunning] = useState(false);

    // 타이머 시작
    const startTimer = () => {
        setMinutes(initialMinutes);
        setSeconds(initialSeconds);
        setIsRunning(true);
    };

    // 타이머 초기화
    const resetTimer = () => {
        setIsRunning(false);
        setMinutes(initialMinutes);
        setSeconds(initialSeconds);
    };

    // 시간을 "03:00" 형식으로 포맷팅
    const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

    // 아이디 중복검사 API (GET)
    const checkDuplicatedId = () => {
        getCheckDuplicateIdAPI(watch('adminId')).then((res) => {
            if (res.status === 200) {
                setIsCheckDuplicated(true);
                createAlert('중복 검사 완료', '중복 검사를 완료하였습니다', false);
            }

            if (res.status === 400) {
                createAlert('형식 오류', '아이디는 영어 대문자/소문자/숫자의 조합으로만 가능합니다', true);
            }

            if (res.status === 409) {
                createAlert('중복된 아이디 입니다!', '다른 아이디로 변경해주세요!', true);
            }
        });
    };

    // 관리자 계정 생성 API (POST)
    const createAccount = useMutation({
        mutationFn: async (data: FormValue) =>
            await createAccountAPI(
                data.adminId,
                data.password,
                data.name,
                phoneNumber,
                selectedGender.value,
                data.email,
                data.centerName,
                selectedCategory.value,
                data.centerAddress
            ),
        onSuccess: (res) => {
            if (res.status === 201) {
                createAlert('회원가입 완료', '회원가입이 완료되었습니다\n라포에 오신 것을 환영합니다!');
                navigate('/signIn');
            }
        },
    });

    // 인증번호 발급 (POST)
    const requestIssueSmsCode = useMutation({
        mutationFn: async () => await requestIssueSmsCodeAPI(phoneNumber),
        onSuccess: (res) => {
            if (res.status === 201) {
                createAlert('인증번호 전송 완료', '인증번호를 전송하였습니다.');
                startTimer();
            }
            if (res.status === 400) {
                createAlert('오류', '올바른 휴대폰번호를 입력해주세요!', true);
                resetTimer();
            }
            if (res.status === 409) {
                createAlert(
                    '오류',
                    '동일한 휴대전화 번호로 다수의 계정을 생성할 수 없습니다.\n 아이디 찾기는 카카오톡 라포 채널에 문의해주세요!',
                    true
                );
                resetTimer();
            }
            if (res.status === 418) {
                createAlert('오류', '인증번호 전송 오류', true);
                resetTimer();
            }
        },
    });

    // 인증번호 검증 (POST)
    const requestVerifySmsCode = useMutation({
        mutationFn: async () => await requestVerifySmsCodeAPI(phoneNumber, authNumber),
        onSuccess: (res) => {
            if (res.status === 200) {
                setIsCheckAuthNumber(true);
                resetTimer();
                createAlert('인증 완료', '인증번호 입력이 완료되었어요!');
            }
            if (res.status === 400) {
                createAlert('오류', '올바른 인증번호를 입력해주세요', true);
            }

            if (res.status === 404) {
                createAlert('오류', '먼저 인증번호를 발급해주세요', true);
            }
            if (res.status === 409) {
                createAlert('오류', '유효하지 않은 인증번호 입니다', true);
            }
            if (res.status === 418) {
                createAlert('오류', '만료된 인증번호 입니다', true);
                resetTimer();
            }
        },
    });

    // 완료 버튼 클릭이벤트
    const handleSubmit = (data: FormValue) => {
        if (watch('name') === '') {
            createAlert('오류', '이름을 입력해주세요', true);
            return;
        }

        if (errors.name) {
            createAlert('오류', '이름을 입력해주세요', true);
            return;
        }

        if (!isCheckAuthNumber) {
            createAlert('오류', '휴대폰 인증을 진행해 주세요', true);
            return;
        }

        if (!isCheckAge) {
            createAlert('필수', '필수 체크 에 동의하여 주세요', true);
            return;
        }
        if (!isCheckPolicy) {
            createAlert('필수', '개인정보 수집 및 이용약관에 동의해주세요', true);
            return;
        }
        createAccount.mutate(data);
    };

    const checkKeyDown = (e: any) => {
        if (e.key === 'Enter') e.preventDefault();
    };

    // 다음 버튼 클릭이벤트
    const onClickNextStep = () => {
        if (step === 'first') {
            if (!isCheckDuplicated) {
                createAlert('중복 확인', '아이디 중복 확인을 진행해 주세요', true);
                return;
            }

            if (watch('password') === '') {
                createAlert('비밀번호', '비밀번호를 입력해주세요', true);
                return;
            }
            if (watch('passwordConfirm') === '') {
                createAlert('비밀번호', '비밀번호를 입력해주세요', true);
                return;
            }

            if (errors.password) {
                createAlert('비밀번호', '영문, 숫자, 특수문자 포함 8 ~ 20자로 입력해주세요', true);
                return;
            }

            if (errors.passwordConfirm) {
                createAlert('비밀번호', '비밀번호가 일치하지 않습니다', true);
                return;
            }
            setStep('second');
        }
    };
    // 로그인 페이지 이동
    const onClickLoginPage = () => {
        navigate('/signIn');
    };

    // 14세 이상 확인버튼
    const onClickCheckAge = () => {
        setIsCheckAge(!isCheckAge);
    };

    // 개인정보 수집 및 이용약관 동의 버튼
    const onClickPolicy = () => {
        setIsCheckPolicy(!isCheckPolicy);
    };

    // 성별 선택 함수
    const handleGenderSelect = (option: any) => {
        setSelectedGender(option);
    };

    // 업종 선택 함수
    const handleCategorySelect = (option: any) => {
        setSelectedCategory(option);
    };

    // 인증번호 발급
    const handleSmsCodeRequest = () => {
        requestIssueSmsCode.mutate();
    };

    // 인증번호 검증
    const handleAuthSmsCode = () => {
        requestVerifySmsCode.mutate();
    };

    // 아이디 중복검사 이후 아이디를 변경할 경우 중복검사 다시실행 위해
    useEffect(() => {
        if (watch('adminId')) {
            if (isCheckDuplicated === true) {
                setIsCheckDuplicated(false);
            }
        }
    }, [watch('adminId')]);

    // 휴대폰 인증 이후 번호를 변경할 경우 인증을 다시위해서
    useEffect(() => {
        if (phoneNumber) {
            if (isCheckAuthNumber === true) {
                setIsCheckAuthNumber(false);
            }
        }
    }, [phoneNumber]);

    // 휴대폰인증 타이머
    useEffect(() => {
        let timer: any;

        if (isRunning) {
            timer = setInterval(() => {
                if (minutes === 0 && seconds === 0) {
                    clearInterval(timer);
                    setIsRunning(false);
                } else {
                    if (seconds === 0) {
                        setMinutes(minutes - 1);
                        setSeconds(59);
                    } else {
                        setSeconds(seconds - 1);
                    }
                }
            }, 1000);
        } else {
            clearInterval(timer);
        }

        return () => clearInterval(timer);
    }, [isRunning, minutes, seconds]);

    // 휴대전화 번호 '-'추가 정제로직
    useEffect(() => {
        if (phoneNumber.length === 10) {
            setPhoneNumber(phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
        }
        if (phoneNumber.length === 13) {
            setPhoneNumber(phoneNumber.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
        }
    }, [phoneNumber]);

    return (
        <Col
            style={{
                width: '100%',
                height: '100%',
                alignSelf: 'center',
                alignItems: 'center',
            }}
        >
            <SignUpHeader step={step} onClickLoginPage={onClickLoginPage} />

            <form onSubmit={onSubmit(handleSubmit)} onKeyDown={(e) => checkKeyDown(e)}>
                {step === 'first' && (
                    <SignUpBody1
                        register={register}
                        getValues={getValues}
                        errors={errors}
                        watch={watch}
                        isCheckDuplicated={isCheckDuplicated}
                        checkDuplicatedId={checkDuplicatedId}
                        onClickNextStep={onClickNextStep}
                        onClickLoginPage={onClickLoginPage}
                    />
                )}

                {step === 'second' && (
                    <SignUpBody2
                        register={register}
                        errors={errors}
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        authNumber={authNumber}
                        setAuthNumber={setAuthNumber}
                        handleSmsCodeRequest={handleSmsCodeRequest}
                        handleAuthSmsCode={handleAuthSmsCode}
                        isRunning={isRunning}
                        formattedTime={formattedTime}
                        isCheckAuthNumber={isCheckAuthNumber}
                        selectedGender={selectedGender}
                        genderOptions={genderOptions}
                        handleGenderSelect={handleGenderSelect}
                        onClickLoginPage={onClickLoginPage}
                        selectedCategory={selectedCategory}
                        categoryOptions={categoryOptions}
                        handleCategorySelect={handleCategorySelect}
                        onClickCheckAge={onClickCheckAge}
                        onClickPolicy={onClickPolicy}
                        isCheckAge={isCheckAge}
                        isCheckPolicy={isCheckPolicy}
                        onClickTerm={onClickTerm}
                    />
                )}
            </form>
        </Col>
    );
};

export default SignUpPage;
