import React, { CSSProperties, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Body3Regular, CaptionRegular } from '../../lib/font';
import { I_RESET } from '../../types/images';
import { colors } from '../../lib/colors';

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
`;

const ResetButtonContainer = styled.button`
    padding-left: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

interface RotatingImageProps {
    isRotating: boolean;
}

const RotatingImage = styled.img<RotatingImageProps>`
    width: 16px;
    height: 16px;
    ${({ isRotating }) =>
        isRotating &&
        css`
            animation: ${rotate} 1s linear;
        `}
`;

type Props = {
    callBack?: () => void;
    style?: CSSProperties;
    text?: string;
};
const ResetButton = ({ callBack, style, text }: Props) => {
    const [isRotating, setIsRotating] = useState(false);

    const handleClick = () => {
        setIsRotating(true);
        if (callBack) {
            callBack();
        }
        setTimeout(() => {
            setIsRotating(false);
        }, 1000);
    };

    return (
        <ResetButtonContainer style={style} onClick={handleClick}>
            <CaptionRegular style={{ color: colors.BLUE_400 }}>{text ? text : '초기화'}</CaptionRegular>

            <RotatingImage src={I_RESET} isRotating={isRotating} />
        </ResetButtonContainer>
    );
};

export default ResetButton;
