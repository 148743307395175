import React from 'react';
import { Body3Regular } from '../../lib/font';
import { colors } from '../../lib/colors';
import { I_GREY_TRASH } from '../../types/images';

const TrashButton = ({
    text,
    onClick,
    width,
    height,
}: {
    text: string;
    onClick: () => void;
    width?: number;
    height?: number;
}) => {
    return (
        <button
            className="border-[1px] border-solid border-GREY_300 flex flex-row justify-center pl-[17.5px] pr-[11px] py-[4px] rounded-full items-center bg-white"
            onClick={onClick}
            style={{ width: width, height: height }}
        >
            <Body3Regular style={{ color: colors.GREY_300, marginRight: 8 }}>{text}</Body3Regular>
            <img src={I_GREY_TRASH} style={{ width: 16, height: 16 }} />
        </button>
    );
};

export default TrashButton;
