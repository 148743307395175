import React from 'react';
import { Col, Row } from '../../lib/utils';
import ColorRoundButton from '../Button/ColorRoundButton';
import { CaptionLight } from '../../lib/font';
import { colors } from '../../lib/colors';
import TextBlueButton from '../TextButton/TextBlueButton';
import TextGrayButton from '../TextButton/TextGrayButton';
import NewColorRoundButton from '../Button/NewColorRoundButton';
import SignInColorRoundButton from '../Button/SignInColorRoundButton';

type I_Props = {
    navigate: any;
    onClickLogin: () => void;
    userId: string;
    userPassword: string;
};
const SignInBottom = ({ navigate, onClickLogin, userId, userPassword }: I_Props) => {
    return (
        <Col style={{ alignItems: 'center' }}>
            <SignInColorRoundButton
                isDisabled={userId.length === 0 || userPassword.length === 0}
                callBack={onClickLogin}
                customStyle={{
                    width: '44rem',
                    height: '4.8rem',
                    marginTop: '3.2rem',
                }}
                text={'로그인'}
                size={'middle'}
            />

            <Row
                style={{
                    width: '44rem',
                    marginTop: '2.4rem',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                }}
            >
                <CaptionLight style={{ color: colors.LAVEL_1, paddingBottom: 4, marginRight: '1.6rem' }}>
                    {'아직 계정이 없으신가요?'}
                </CaptionLight>
                <TextBlueButton
                    callBack={() => {
                        navigate('/signUp');
                    }}
                    size="middle"
                    text={'회원가입'}
                />
            </Row>

            <Row
                style={{
                    width: '39.2rem',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '8.6rem',
                    marginBottom: '21.4rem',
                }}
            >
                <TextGrayButton
                    customStyle={{
                        width: '18rem',
                        height: '3.2rem',
                    }}
                    text="라포 홈페이지 바로가기"
                    size="middle"
                    callBack={() => {
                        window.open('https://rappomanager.info/', '_blank');
                    }}
                />

                <Row style={{ width: 1, height: 12, backgroundColor: colors.GREY_200 }} />

                <TextGrayButton
                    customStyle={{
                        width: '18rem',
                        height: '3.2rem',
                    }}
                    text="1:1 카카오 채널 상담"
                    size="middle"
                    callBack={() => {
                        window.open('http://pf.kakao.com/_ICxhcb', '_blank');
                    }}
                />
            </Row>
        </Col>
    );
};

export default SignInBottom;
