import React from 'react';
import { Col, Row } from '../../../lib/utils';
import ProfileImage from '../../ProfileImage';
import { colors } from '../../../lib/colors';
import {
    Body2Regular,
    Body3Regular,
    CaptionBold,
    CaptionLight,
    CaptionRegular,
    OverlineRegular,
} from '../../../lib/font';
import styled from 'styled-components';

const StyledButton = styled.button<{ selected: boolean }>`
    width: 378px;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d4dbe8;
    padding-left: 24px;
    padding-right: 36px;

    background-color: ${({ selected }) => (selected ? '#E9F1FF' : '#F5F9FF')};

    &:hover {
        background-color: #ecf1fc;
    }
`;

const MemberList = ({ memberItem, memberId, onClickMember }: any) => {
    return (
        <StyledButton onClick={onClickMember} selected={memberId === memberItem.memberId}>
            <Row style={{ alignItems: 'center' }}>
                <ProfileImage
                    imageUrl={memberItem?.profileImageUrl}
                    type="VerySmall"
                    profileName={memberItem?.profileName}
                    profileColorType={memberItem?.profileColorType}
                    right={8}
                />
                <Body3Regular style={{ color: colors.LAVEL_3, marginRight: 12 }}>{memberItem?.alias}</Body3Regular>

                <CaptionLight style={{ color: colors.LAVEL_3 }}>
                    일지 : {memberItem?.memberScheduleReportCount}개 / 마지막 작성일 :{' '}
                    {memberItem?.memberScheduleReportLastDateString}
                </CaptionLight>
            </Row>

            {memberItem?.status !== 'active' && (
                <Row
                    style={{
                        width: 65,
                        height: 24,
                        borderRadius: 30,
                        border: '1px solid #868686',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <OverlineRegular style={{ color: colors.GREY_300 }}>{'비활성 회원'}</OverlineRegular>
                </Row>
            )}
        </StyledButton>
    );
};

export default MemberList;
