import { Col } from '../../../lib/utils';
import CoachButton from './CoachButton';
import MemberList from './MemberList';

type Props = {
    coachId: string;
    memberId?: string | null | undefined;
    centerUserList: any;
    onClickCoach: any;
    onClickMember: any;
};

const FeedSelectListContent = ({ coachId, memberId, centerUserList, onClickCoach, onClickMember }: Props) => {
    return (
        <Col style={{ marginTop: 16, alignSelf: 'center' }}>
            {centerUserList.map((coachItem: any, coachIndex: number) => {
                const memberList = coachItem?.members;
                return (
                    <div key={coachIndex + 'centerUserList'}>
                        <CoachButton
                            coachId={coachId}
                            coachItem={coachItem}
                            onClickCoach={onClickCoach}
                            memberList={memberList}
                        />
                        {coachId === coachItem.coachId && (
                            <Col
                                style={{
                                    width: 374,
                                    borderBottomRightRadius: 8,
                                    borderBottomLeftRadius: 8,
                                    alignSelf: 'center',
                                }}
                            >
                                {memberList &&
                                    memberList.map((memberItem: any, memberIndex: number) => {
                                        return (
                                            <div key={memberIndex + 'centerMemberList'}>
                                                <MemberList
                                                    onClickMember={() => {
                                                        let memberObj = {
                                                            coachId: coachId,
                                                            memberId: memberItem.memberId,
                                                        };
                                                        onClickMember(memberObj);
                                                    }}
                                                    memberItem={memberItem}
                                                    memberId={memberId}
                                                />
                                            </div>
                                        );
                                    })}
                            </Col>
                        )}
                    </div>
                );
            })}
        </Col>
    );
};

export default FeedSelectListContent;
