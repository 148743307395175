import React, { useEffect } from 'react';
import { getItemWithExpiration, setItemWithExpiration } from '../lib/utils';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const useUsableStatus = ({ oneButtonCallBack, twoButtonCallBack, authObject, setAuthObject, alertStateReset }: any) => {
    const navigate = useNavigate();

    const alertCheck = () => {
        const authObjectData = {
            adminId: authObject?.adminId,
            id: authObject?.id,
            name: authObject?.name,
            email: authObject?.email,
            gender: authObject?.gender,
            phoneNumber: authObject?.phoneNumber,
            profileImageUrl: authObject?.profileImageUrl,
            /** 센터 */
            centerAddress: authObject?.centerAddress,
            centerCategory: authObject?.centerCategory,
            centerCode: authObject?.centerCode,
            centerName: authObject?.centerName,
            centerId: authObject?.centerId,
            isNewCommunicationBox: authObject?.isNewCommunicationBox,

            /** 구독플랜관련 */
            coachCount: authObject?.coachCount,
            memberCount: authObject?.memberCount,
            activationDate: authObject?.activationDate,
            expirationDate: authObject?.expirationDate,
            expirationDelayDate: authObject?.expirationDelayDate,
            finalPaymentDate: authObject?.finalPaymentDate,
            finalPaymentTryDate: authObject?.finalPaymentTryDate,

            cardIssuingBank: authObject?.cardIssuingBank, // 결제카드 은행
            cardNumber: authObject?.cardNumber, // 결제카드 번호
            planAmount: authObject?.planAmount, // 결제 금액

            usableStatus: authObject?.usableStatus,

            paymentDueDate: authObject?.paymentDueDate,

            /** 구독플랜 인원 정보 */
            planLimitCount: authObject?.planLimitCount, // 플랜 제한 이용 인원
            prevPlanLimitCount: authObject?.prevPlanLimitCount, // 구독 인원 변경시 변경전 플랜카운트
            changedPlanLimitCount: authObject?.changedPlanLimitCount, // 구독 인원 변경시 변경 플랜카운트

            confirmUsableStatus: true, // 경고창 관련 boolean
            testAccount: authObject?.testAccount,
        };
        setAuthObject(authObjectData);
        setItemWithExpiration('authObject', authObjectData, 12);
    };

    const navigateExpiredPage = () => {
        alertCheck();
        navigate('/expired', { replace: true });
        alertStateReset();
    };

    const navigateMyPage = () => {
        alertCheck();
        navigate('/myPage', {
            state: {
                isSubscriptionModal: true,
            },
            replace: true,
        });
    };

    useEffect(() => {
        if (authObject) {
            if (!(authObject.usableStatus === 'needAction')) {
                // // 테스트용
                // if (authObject.usableStatus === 'trial') {
                //     oneButtonCallBack(
                //         '구독 종료',
                //         '주상화 매니저님! 7일 무료 이용 기간이 만료되었습니다!\n구독 후 이용 부탁드립니다',
                //         '구독 하기',
                //         () => {
                //             navigateExpiredPage();
                //         }
                //     );
                // }

                // 무료 요금제 결제 유예중
                if (authObject.usableStatus === 'trialPaymentPending' && authObject?.confirmUsableStatus === false) {
                    twoButtonCallBack(
                        '구독 결제 갱신 요청',
                        `${authObject.name} 매니저님! ${dayjs(authObject.expirationDate).format(
                            'MM월 DD일'
                        )}부로 무료체험 이용기간이 만료되었습니다\n${dayjs(authObject.expirationDelayDate).format(
                            'MM월 DD일'
                        )} 내에 구독을 진행해주시길 바랍니다`,
                        '다음에',
                        '구독 하기',
                        () => {
                            alertCheck();
                        },
                        () => {
                            navigateMyPage();
                        }
                    );
                }
                //  유료 요금제 결제 유예중
                if (authObject.usableStatus === 'planAborted' && authObject?.confirmUsableStatus === false) {
                    twoButtonCallBack(
                        '구독 결제 갱신 요청',
                        `${authObject.name} 매니저님! ${dayjs(authObject.finalPaymentTryDate).format(
                            'MM월 DD일'
                        )} 구독 결제가 갱신되지 않았습니다\n${dayjs(authObject.expirationDelayDate).format(
                            'MM월 DD일'
                        )} 내에 구독을 진행해주시길 바랍니다`,
                        '다음에',
                        '구독 하기',
                        () => {
                            alertCheck();
                        },
                        () => {
                            navigateMyPage();
                        }
                    );
                }
                // 무료 요금제 만료 - needPlanToFree
                if (authObject.usableStatus === 'needPlanToFree' && authObject?.confirmUsableStatus === false) {
                    oneButtonCallBack(
                        '구독 종료',
                        `${authObject.name} 매니저님! 무료 이용 기간이 만료되었습니다!\n구독 후 이용 부탁드립니다`,
                        '구독 하기',
                        () => {
                            // navigateExpiredPage();
                            alertCheck();
                        }
                    );
                }
                // // 유료 요금제 만료 - needPlanToCharge
                if (authObject.usableStatus === 'needPlanToCharge' && authObject?.confirmUsableStatus === false) {
                    oneButtonCallBack(
                        '구독 종료',
                        `${authObject.name} 매니저님! 구독 이용 기간이 만료되었습니다!\n재구독 후 다시 이용하실 수 있습니다`,
                        '구독 하기',
                        () => {
                            // navigateExpiredPage();
                            alertCheck();
                        }
                    );
                }
            }
        }
    }, [authObject]);
};

export default useUsableStatus;
