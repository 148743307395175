import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Col, getItemWithExpiration, Row } from '../../../../lib/utils';
import { useQueryClient } from '@tanstack/react-query';
import useMembershipService from '../../../../services/useMembershipService';
import { MembershipTemplateDataType } from '../../../../services/useMembershipTemplateQueryService';
import dayjs from 'dayjs';
import Modal from '../../../../components/modal/Modal';
import CloseButton from '../../../../components/Button/CloseButton';
import { Body3Bold, Body3Light, Body3Regular, CaptionLight, CaptionRegular, Subtitle1Bold } from '../../../../lib/font';
import NewColorRoundButton from '../../../../components/Button/NewColorRoundButton';
import RadiusTextInput from '../../../../components/TextInput/RadiusTextInput';
import MembershipTemplate from '../../ClassPassList/MembershipTemplate';

import SvgIcon from '../../../../components/SvgIcon';
import NewWhiteSquareButton from '../../../../components/Button/NewWhiteSquareButton';
import NewColorSquareButton from '../../../../components/Button/NewColorSquareButton';
import useMembershipTemplateMutationService from '../../../../services/useMembershipTemplateMutationService';

type Props = {
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    // 새로운 수강권 만들기 버튼 클릭 시
    onClickCreateNewMembership: () => void;
    // 이전 템플릿
    beforeMembershipTemplate: MembershipTemplateDataType;
    // 수강권 ids
    membershipIds: string[];

    createAlert?: any;
    onPatchRelocateMembershipSuccess?: () => void;
};

const ModalMembershipChange = ({
    setIsVisible,
    onClickCreateNewMembership,
    beforeMembershipTemplate,
    membershipIds,
    createAlert,
    onPatchRelocateMembershipSuccess,
}: Props) => {
    const queryClient = useQueryClient();
    const { patchRelocateMembershipMutation } = useMembershipTemplateMutationService({
        onPatchRelocateMembershipSuccess() {
            onPatchRelocateMembershipSuccess && onPatchRelocateMembershipSuccess();
        },
    });

    const { allMembershipTemplatesListData } = useMembershipService({
        fetchAllMembershipTemplatesList: true,
        onCreateMemberShipSuccess() {
            setIsVisible(false);
            createAlert('수강권 등록 완료', '새로운 수강권이 성공적으로 등록되어 회원님께 발급되었습니다!', false);
            queryClient.invalidateQueries({
                queryKey: ['getAllMembershipForMemberQuery'],
            });
            queryClient.invalidateQueries({ queryKey: ['getMembershipTemplatesListAPI'] });
        },
    });

    const [filterText, setFilterText] = useState<string>('');

    // filterText 가 있을 때만 필터링
    const filteredData = allMembershipTemplatesListData.filter(
        (item: any) => item.name.includes(filterText) && item._id !== beforeMembershipTemplate._id
    );

    // 1,2 단계
    const [step, setStep] = useState<number>(1);
    // 수강권 템플릿 Id
    const [selectMemberShipTemplate, setSelectMemberShipTemplate] = useState<MembershipTemplateDataType | undefined>(
        undefined
    );

    // 수강권 선택
    const onClickMembershipTemplate = (item: MembershipTemplateDataType) => {
        setStep(2);

        setSelectMemberShipTemplate(item);
    };

    return (
        <Modal whiteViewHeight={700} whiteViewWidth={1080} setIsVisible={setIsVisible}>
            <CloseButton size={24} className=" absolute right-[25px] top-[25px]" onClick={() => setIsVisible(false)} />
            <Col className="flex py-[36px] px-[48px]">
                <Row className="flex mb-[24px] items-center">
                    <Subtitle1Bold className="mr-[16px]">수강권 이전하기</Subtitle1Bold>
                    <CaptionLight className=" text-LAVEL_2">
                        {`${filteredData.length}개의 수강권이 다시 선택하신 수강권으로 일괄 이동됩니다!`}
                    </CaptionLight>
                </Row>
                {step === 1 ? (
                    <>
                        <Row className="flex justify-between mb-[40px]">
                            <Row className="flex flex-row  items-center">
                                <Col className=" justify-center items-center bg-primary w-[24px] h-[24px] rounded-full">
                                    <CaptionRegular className="text-white">1</CaptionRegular>
                                </Col>
                                <Col className="w-[36px] h-[1px] bg-BLUE_200" />

                                <Col className=" justify-center items-center bg-BLUE_200 w-[24px] h-[24px] rounded-full">
                                    <CaptionRegular className="text-white">2</CaptionRegular>
                                </Col>
                            </Row>
                            <Row>
                                <NewColorRoundButton
                                    bgColor="primary"
                                    text="새로운 수강권 만들기"
                                    onClick={onClickCreateNewMembership}
                                    textColor="white"
                                    fontType="Body3Regular"
                                    width={188}
                                    height={38}
                                    className="mr-[16px]"
                                />
                                <RadiusTextInput
                                    textInputHeight={36}
                                    value={filterText}
                                    placeholder="수강권 검색하기"
                                    width={310}
                                    onChange={(value) => setFilterText(value)}
                                    isRightIcon={false}
                                    isLeftIcon
                                    isClearIcon={false}
                                />
                            </Row>
                        </Row>
                        <div className="grid grid-cols-3 gap-6 w-full px-[56px]">
                            {filteredData.map((item: { _id: any }, index: any) => (
                                <MembershipTemplate
                                    key={item._id}
                                    index={index}
                                    item={item}
                                    onClickDetailPage={onClickMembershipTemplate}
                                />
                            ))}
                        </div>
                    </>
                ) : (
                    <>
                        <Row className="flex flex-row  items-center mb-[32px]">
                            <Col className=" justify-center items-center bg-BLUE_200 w-[24px] h-[24px] rounded-full ">
                                <CaptionRegular className="text-white">1</CaptionRegular>
                            </Col>
                            <Col className="w-[36px] h-[1px] bg-BLUE_200" />

                            <Col className=" justify-center items-center bg-primary w-[24px] h-[24px] rounded-full">
                                <CaptionRegular className="text-white">2</CaptionRegular>
                            </Col>
                        </Row>
                        <Row className="flex items-center mb-[56px]">
                            <Col className="flex w-[264px]  mr-[48px]">
                                <Col className="flex bg-GREY_200 justify-center items-center w-full h-[32px] rounded-[16px] mb-[8px] ">
                                    <Body3Bold className="text-LAVEL_0">변경 전</Body3Bold>
                                </Col>
                                <MembershipTemplate
                                    key={beforeMembershipTemplate._id}
                                    item={beforeMembershipTemplate}
                                    onClickDetailPage={() => {}}
                                />
                            </Col>
                            <SvgIcon
                                name="SvgGradientRightStrokeArrow"
                                size={28}
                                gradientId="paint0_linear_4038_68781"
                                stopColors={selectMemberShipTemplate?.BGColor} // Array of gradient colors
                                className="mr-[48px]"
                            />
                            <Col className="flex w-[264px]  mr-[48px]">
                                <Col className="flex bg-ORANGE justify-center items-center w-full h-[32px] rounded-[16px] mb-[8px] ">
                                    <Body3Bold className="text-LAVEL_0">변경 후</Body3Bold>
                                </Col>
                                <MembershipTemplate
                                    key={selectMemberShipTemplate?._id}
                                    item={selectMemberShipTemplate}
                                    onClickDetailPage={() => {}}
                                />
                            </Col>
                        </Row>
                        <Body3Regular className="text-LAVEL_2 mb-[8px]">주의사항</Body3Regular>
                        <Row className="flex items-center">
                            <Col className=" rounded-full bg-black w-[5px] h-[5px] mr-[8px]" />
                            <Body3Light className="text-LAVEL_3">
                                수강권이 이동하더라도 각각의 원래의 수강권 정보는 그대로 유지됩니다!
                            </Body3Light>
                        </Row>
                        <Row className="flex items-center">
                            <Col className=" rounded-full bg-black w-[5px] h-[5px] mr-[8px]" />
                            <Body3Light className="text-LAVEL_3">
                                단, 변경 전 회당 가격이 설정되어 있지 않은 경우(0원), 변경 후 수강권에 설정된 회당
                                가격으로 자동 교체됩니다!
                            </Body3Light>
                        </Row>
                        <Row className=" absolute right-[36px] bottom-[36px]">
                            <NewWhiteSquareButton
                                text="이전"
                                onClick={() => setStep(1)}
                                width={148}
                                height={48}
                                mr={16}
                            />
                            <NewColorSquareButton
                                text="이동 완료"
                                bgColor="primary"
                                textColor="white"
                                onClick={() => {
                                    if (!selectMemberShipTemplate) {
                                        createAlert('수강권 선택', '수강권을 선택해주세요!', true);
                                        return;
                                    }
                                    patchRelocateMembershipMutation.mutate({
                                        newMembershipTemplateId: selectMemberShipTemplate?._id,
                                        membershipIds: membershipIds,
                                    });
                                }}
                                width={148}
                                height={48}
                            />
                        </Row>
                    </>
                )}
            </Col>
        </Modal>
    );
};

export default ModalMembershipChange;
