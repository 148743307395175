import { useMutation } from '@tanstack/react-query';
import {
    patchCenterUpdateMember,
    PatchCenterUpdateMemberParamsType,
    patchChangeCoachForMember,
    patchDisconnectMember,
    PatchDisconnectMemberParamsType,
    patchDisconnectMembers,
    PatchDisconnectMembersParamsType,
} from '../api/center';
import { MemberProfile } from './useMemberQueryService';
import { updateTrainerPermissionAPI, UpdateTrainerPermissionParamsType } from '../api/employee';
import { getItemWithExpiration } from '../lib/utils';
import { useState } from 'react';

type Props = {
    fetchCoachList?: boolean;
    onPatchCenterUpdateMemberSuccess?: (data: MemberProfile) => void;
    onPatchCenterUpdateMemberError?: (error: any) => void;
    onPatchDisconnectMembersSuccess?: () => void;
    onPatchDisconnectMembersError?: (error: any) => void;
    onPatchDisconnectMemberSuccess?: () => void;
    onPatchDisconnectMemberError?: (error: any) => void;
    onPatchChangeCoachForMemberSuccess?: (data: any) => void;
    onPatchChangeCoachForMemberError?: (error: any) => void;
    onPatchUpdateTrainerPermissionSuccess?: () => void;
    onPatchUpdateTrainerPermissionError?: (error: any) => void;
};

const useCenterMutationService = ({
    onPatchCenterUpdateMemberSuccess,
    onPatchCenterUpdateMemberError,
    onPatchDisconnectMemberSuccess,
    onPatchDisconnectMemberError,
    onPatchDisconnectMembersSuccess,
    onPatchDisconnectMembersError,
    onPatchChangeCoachForMemberSuccess,
    onPatchChangeCoachForMemberError,
    onPatchUpdateTrainerPermissionSuccess,
    onPatchUpdateTrainerPermissionError,
}: Props) => {
    const [authorization] = useState(getItemWithExpiration('authorization'));

    const patchCenterUpdateMemberMutation = useMutation({
        mutationFn: async (params: PatchCenterUpdateMemberParamsType) => await patchCenterUpdateMember(params),
        onSuccess: (res) => {
            if (res.status === 200) {
                onPatchCenterUpdateMemberSuccess && onPatchCenterUpdateMemberSuccess(res.data.member);
            } else if (res.status === 400 || res.status === 401 || res.status === 403 || res.status === 404) {
                onPatchCenterUpdateMemberError && onPatchCenterUpdateMemberError(res.status);
            }
        },
    });
    const patchDisconnectMembersMutation = useMutation({
        mutationFn: async (params: PatchDisconnectMembersParamsType) => await patchDisconnectMembers(params),
        onSuccess: (res) => {
            if (res.status === 200) {
                onPatchDisconnectMembersSuccess && onPatchDisconnectMembersSuccess();
            } else if (res.status === 400 || res.status === 401 || res.status === 403 || res.status === 404) {
                onPatchDisconnectMembersError && onPatchDisconnectMembersError(res.status);
            }
        },
    });
    const patchDisconnectMemberMutation = useMutation({
        mutationFn: async (params: PatchDisconnectMemberParamsType) => await patchDisconnectMember(params),
        onSuccess: (res) => {
            if (res.status === 200) {
                onPatchDisconnectMemberSuccess && onPatchDisconnectMemberSuccess();
            } else if (res.status === 400 || res.status === 401 || res.status === 403 || res.status === 404) {
                onPatchDisconnectMemberError && onPatchDisconnectMemberError(res.status);
            }
        },
    });

    // 담당 강사 변경
    const patchChangeCoachForMemberMutation = useMutation({
        mutationFn: async (params: PatchDisconnectMemberParamsType & { newCoachId: string }) =>
            await patchChangeCoachForMember({ ...params, accessToken: authorization }),
        onSuccess: (res) => {
            if (res.status === 200) {
                onPatchChangeCoachForMemberSuccess && onPatchChangeCoachForMemberSuccess(res.data);
            } else if (res.status === 400 || res.status === 401 || res.status === 403 || res.status === 404) {
                onPatchChangeCoachForMemberError && onPatchChangeCoachForMemberError(res.status);
            }
        },
    });

    // 수강권 권한 변경 : (PATCH)
    const patchUpdateTrainerPermission = useMutation({
        mutationFn: async (params: UpdateTrainerPermissionParamsType) =>
            await updateTrainerPermissionAPI({ ...params, accessToken: authorization }),
        onSuccess: (res) => {
            if (res.status === 200) {
                onPatchUpdateTrainerPermissionSuccess && onPatchUpdateTrainerPermissionSuccess();
            } else {
                onPatchUpdateTrainerPermissionError && onPatchUpdateTrainerPermissionError(res.status);
            }
        },
    });

    return {
        patchCenterUpdateMemberMutation,
        patchDisconnectMembersMutation,
        patchDisconnectMemberMutation,
        patchChangeCoachForMemberMutation,
        patchUpdateTrainerPermission,
    };
};

export default useCenterMutationService;
