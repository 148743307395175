import React, { useState } from 'react';
import { Col } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import { Body2Bold } from '../../../lib/font';

const OffBlock = ({ colPosition, rowPosition, boxHeight, childItem, onClickEtcOffSchedule }: any) => {
    return (
        <Col
            onClick={() => {
                onClickEtcOffSchedule(childItem);
            }}
            style={{
                zIndex: 0,
                width: '10.4%',
                height: boxHeight,
                position: 'absolute',
                marginTop: colPosition,
                marginLeft: `${rowPosition}%`,
                borderRadius: 2,
                backgroundColor: colors.WHITE_600,
                justifyContent: 'center',
                alignItems: 'center',
                border: 'none',
                color: colors.WHITE_50,
                cursor: 'pointer',
            }}
        >
            <Body2Bold>{'OFF'}</Body2Bold>
        </Col>
    );
};

export default OffBlock;
