import React from 'react';
import { Row } from '../../lib/utils';
import { Body2Bold, Subtitle1Bold, Subtitle1Regular } from '../../lib/font';
import { colors } from '../../lib/colors';

const DemoVersionQuitButton = ({ navigate }: any) => {
    return (
        <Row
            style={{
                position: 'absolute',
                top: 49,
                left: 740,
                width: 680,
                height: 83,
                borderRadius: 93,
                backgroundColor: 'rgba(0, 0, 0, 0.53)',
                alignItems: 'center',
                paddingLeft: 42,
                zIndex: 999,
            }}
        >
            <Subtitle1Regular style={{ width: 500, color: colors.WHITE_50 }}>
                <Subtitle1Bold>데모버전</Subtitle1Bold>에서는 사용예시로써 수정등 일부
                <Subtitle1Bold>기능을 제한</Subtitle1Bold>합니다
            </Subtitle1Regular>
            <button style={{ width: 90, height: 23, marginLeft: 24 }} onClick={() => navigate('/signIn')}>
                <Body2Bold style={{ color: colors.Secondiary }}>데모버전닫기</Body2Bold>
            </button>
        </Row>
    );
};

export default DemoVersionQuitButton;
