import React from 'react';
import { colors } from '../../../lib/colors';
import { I_DOWNLOAD } from '../../../types/images';
import { Body1Bold, Body2Regular } from '../../../lib/font';
import { Row } from '../../../lib/utils';

const FeedAttachedFile = ({ fileObject }: any) => {
    return (
        <Row
            style={{
                width: 722,
                height: 112,
                borderRadius: 8,
                border: '1px solid #D4DBE8',

                flexDirection: 'column',
                alignItems: 'center',
                alignSelf: 'center',
                paddingTop: 17,
            }}
        >
            <img src={I_DOWNLOAD} style={{ width: 24, height: 24 }} />
            <Body1Bold style={{ color: colors.primary }}>{'첨부파일 :'}</Body1Bold>
            <Body2Regular style={{ color: colors.primary }}>{fileObject[0]?.fileName}</Body2Regular>
        </Row>
    );
};

export default FeedAttachedFile;
