import React from 'react';
import { Col } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import { I_EMPTY_V2 } from '../../../types/images';
import { Body3Light } from '../../../lib/font';

const EmptyClass = () => {
    return (
        <Col
            style={{
                width: 1192,
                height: 168,
                alignItems: 'center',
                justifyContent: 'center',
                // marginTop: 180,
                backgroundColor: colors.WHITE_50,
                borderRadius: 16,
                border: '1px solid #C3D4F4',
                // marginLeft: 490,
            }}
        >
            <Col style={{ width: 260, height: 56, alignItems: 'center', justifyContent: 'space-between' }}>
                <img src={I_EMPTY_V2} style={{ width: 24, height: 24 }} />
                <Body3Light style={{ color: colors.LAVEL_2 }}>아직 수업이 등록되지 않았습니다</Body3Light>
            </Col>
        </Col>
    );
};

export default EmptyClass;
