import { Col, Row } from '../../../lib/utils';
import { Body3Bold, CaptionRegular } from '../../../lib/font';
import { I_POPUP_EXIT } from '../../../types/images';
import { colors } from '../../../lib/colors';
import ProfileImage from '../../ProfileImage';
import dayjs from 'dayjs';
import { HoverImage } from '../../../lib/styled';

const ModalFeedDetailHeader = ({ modalObject, setModalVisible }: any) => {
    return (
        <Col
            style={{
                width: 1080,
                position: 'fixed',
                zIndex: 5,
                height: 106,
                borderTopRightRadius: 24,
                borderTopLeftRadius: 24,
                paddingBottom: 16,
                backgroundColor: colors.WHITE_200,
                boxShadow: '0px 2px 6px 0px #00000014',
            }}
        >
            <HoverImage
                src={I_POPUP_EXIT}
                style={{ width: 32, height: 32, marginLeft: 1032, marginTop: 16 }}
                onClick={() => setModalVisible(false)}
            />

            <Row
                style={{
                    width: '100%',
                    paddingLeft: 48,
                    paddingRight: 64,
                    alignItems: 'center',
                }}
            >
                <Row
                    style={{
                        width: '100%',
                        alignItems: 'flex-end',

                        justifyContent: 'space-between',
                    }}
                >
                    <Row style={{ alignItems: 'center' }}>
                        <ProfileImage
                            imageUrl={modalObject?.profileImageUrl}
                            type="Middle"
                            profileName={modalObject?.profileName}
                            profileColorType={modalObject?.profileColorType}
                            right={16}
                        />
                        <Col style={{ justifyContent: 'space-between' }}>
                            <Body3Bold style={{ color: colors.BLUE_700 }}>{`${modalObject?.writerName} ${
                                modalObject?.role === 'coach' ? '강사' : '회원'
                            }`}</Body3Bold>
                            <CaptionRegular style={{ color: colors.WHITE_900 }}>{`${
                                modalObject?.role === 'coach'
                                    ? `회원 총 ${modalObject?.totalMemberCount}명`
                                    : `${modalObject?.receivers[0]?.receiverName} 강사 담당`
                            }`}</CaptionRegular>
                        </Col>
                    </Row>

                    <CaptionRegular style={{ color: colors.WHITE_900, marginBottom: 3 }}>
                        {dayjs(modalObject?.createdTime).format('YY/MM/DD(ddd)/A HH:mm')}
                    </CaptionRegular>
                </Row>
            </Row>
        </Col>
    );
};

export default ModalFeedDetailHeader;
