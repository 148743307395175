import React from 'react';
import styled from 'styled-components';
import { I_SPINNER } from '../types/images';
import { Head4 } from '../lib/font';
import { colors } from '../lib/colors';

const Background = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    background: #0000006b;

    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const LoadingText = styled.div`
    font: 1rem 'Noto Sans KR';
    text-align: center;
`;

type Props = {
    text?: string;
};

const Loading = ({ text }: Props) => {
    return (
        <Background>
            <img src={I_SPINNER} alt="로딩중" width="10%" />
            <Head4 style={{ color: colors.WHITE_50, textShadow: '0px 0px 12px 0px #0D0C44A8' }}>{text}</Head4>
        </Background>
    );
};

export default Loading;
