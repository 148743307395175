import { Col, Row } from '../../../lib/utils';
import { I_LEFT_ANGLE, I_PRIMARY_LEFT_TRIANGLE, I_PRIMARY_RIGHT_TRIANGLE, I_RIGHT_ANGLE } from '../../../types/images';
import {
    Body1Bold,
    Body1Regular,
    Body2Bold,
    Body2Light,
    Body3Bold,
    Body3Light,
    Body3Regular,
    Head4,
} from '../../../lib/font';
import { colors } from '../../../lib/colors';
import dayjs from 'dayjs';
import ResetButton from '../../Button/ResetButton';
import ThreeButton from '../../Button/ThreeButton';

type Props = {
    isToday: boolean;
    isWeek: boolean;
    isMonth: boolean;
    onClickSubtractDate: () => void;
    onClickAddDate: () => void;
    settingDate: (type: string) => void;
    currentDate: any;
    weekNumber: number;
    setCurrentDate: any;
};

const MemberMealListGraphHeader = ({
    isToday,
    isWeek,
    isMonth,
    onClickSubtractDate,
    onClickAddDate,
    settingDate,
    currentDate,
    weekNumber,
    setCurrentDate,
}: Props) => {
    return (
        <Col>
            <Body1Bold style={{ marginLeft: 70, marginTop: 48 }}>식단 관리 - 회원 수</Body1Bold>
            <Row
                style={{
                    marginTop: 24,
                    alignItems: 'center',
                    marginLeft: 70,
                    justifyContent: 'space-between',
                    paddingRight: 70,
                }}
            >
                <Row style={{ alignItems: 'center' }}>
                    <Row
                        style={{
                            width: 232,
                            height: 32,
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginRight: 24,
                        }}
                    >
                        <button style={{ width: 24, height: 24 }} onClick={() => onClickSubtractDate()}>
                            <img src={I_LEFT_ANGLE} style={{ width: 24, height: 24 }} />
                        </button>
                        <Body3Regular style={{ width: 160, textAlign: 'center', color: colors.LAVEL_2 }}>
                            {isToday && currentDate.format('MM월') + weekNumber + '주차'}
                            {isWeek && currentDate.format('YYYY년 MM월')}
                            {isMonth && currentDate.format('YYYY년')}
                        </Body3Regular>
                        <button style={{ width: 32, height: 32 }} onClick={() => onClickAddDate()}>
                            <img src={I_RIGHT_ANGLE} style={{ width: 24, height: 24 }} />
                        </button>
                    </Row>

                    <ThreeButton
                        isFirst={isToday}
                        isSecond={isWeek}
                        isThird={isMonth}
                        firstText={'일간'}
                        secondText={'주간'}
                        thirdText={'월간'}
                        firstCallBack={() => settingDate('today')}
                        secondCallBack={() => settingDate('thisWeek')}
                        thirdCallBack={() => settingDate('thisMonth')}
                    />
                    {/* 초기화 버튼 */}
                    <ResetButton style={{ marginLeft: 24 }} callBack={() => setCurrentDate(dayjs())} />
                </Row>

                <Row style={{ width: 289, justifyContent: 'space-between' }}>
                    <Row
                        style={{
                            width: 182,
                            height: 24,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            style={{
                                width: 16,
                                height: 16,
                                borderRadius: 2,
                                backgroundColor: colors.PINK,
                            }}
                        />
                        <Body3Regular style={{ color: colors.LAVEL_3 }}>{`식단 기록하지 않는 회원`}</Body3Regular>
                    </Row>

                    <Row
                        style={{
                            width: 83,
                            height: 24,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginLeft: 24,
                        }}
                    >
                        <div
                            style={{
                                width: 16,
                                height: 16,
                                borderRadius: 2,
                                backgroundColor: colors.BLUE_400,
                            }}
                        />
                        <Body3Regular style={{ color: colors.LAVEL_3 }}>{`식단기록`}</Body3Regular>
                    </Row>
                </Row>
            </Row>
            {/* <Row style={{ width: 1356, marginTop: 36, alignItems: 'center', justifyContent: 'space-between' }}>
                <Row>
                    <Row style={{ width: 164, height: 24, justifyContent: 'space-between' }}>
                        <button style={{ width: 24, height: 24 }} onClick={() => onClickSubtractDate()}>
                            <img src={I_PRIMARY_LEFT_TRIANGLE} style={{ width: 24, height: 24 }} />
                        </button>
                        <Body1Bold style={{ width: 100, textAlign: 'center', color: colors.BLUE_900 }}>
                            {isToday
                                ? currentDate.format('MM월 ') + weekNumber + '주차'
                                : isWeek
                                ? currentDate.format('YYYY년 MM월')
                                : currentDate.format('YYYY년')}
                        </Body1Bold>
                        <button style={{ width: 24, height: 24 }} onClick={() => onClickAddDate()}>
                            <img src={I_PRIMARY_RIGHT_TRIANGLE} style={{ width: 24, height: 24 }} />
                        </button>
                    </Row>

                    <Row
                        style={{
                            width: 236,
                            marginLeft: 52,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <button
                            style={{
                                width: 56,
                                height: 24,
                                color: isToday ? colors.BLUE_700 : colors.WHITE_700,
                            }}
                            onClick={() => settingDate('today')}
                        >
                            {isToday ? <Body1Bold>일간</Body1Bold> : <Body1Regular>일간</Body1Regular>}
                        </button>

                        <button
                            style={{
                                width: 56,
                                height: 24,
                                color: isWeek ? colors.BLUE_700 : colors.WHITE_700,
                            }}
                            onClick={() => settingDate('thisWeek')}
                        >
                            {isWeek ? <Body1Bold>주간</Body1Bold> : <Body1Regular>주간</Body1Regular>}
                        </button>

                        <button
                            style={{
                                width: 56,
                                height: 24,
                                color: isMonth ? colors.BLUE_700 : colors.WHITE_700,
                            }}
                            onClick={() => settingDate('thisMonth')}
                        >
                            {isMonth ? <Body1Bold>월간</Body1Bold> : <Body1Regular>월간</Body1Regular>}
                        </button>
                    </Row>

                    <button
                        style={{
                            width: 160,
                            height: 36,
                            backgroundColor: colors.WHITE_300,
                            borderRadius: 24,
                            marginLeft: 56,
                            boxShadow: '1px 1px 8px 0px #2B529D2E',
                        }}
                        onClick={() => setCurrentDate(dayjs())}
                    >
                        <Body2Bold style={{ color: colors.PRIMARY_BLUE }}>오늘기준으로 다시보기</Body2Bold>
                    </button>
                </Row>

                <Row style={{}}>
                    <Row
                        style={{
                            height: 22,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            style={{
                                width: 24,
                                height: 16,
                                borderRadius: 4,
                                backgroundColor: colors.BLUE_200,
                                marginRight: 8,
                            }}
                        />
                        <Body2Light style={{ color: colors.WHITE_900 }}>식단 기록하지 않는 회원</Body2Light>
                    </Row>

                    <Row
                        style={{
                            height: 22,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginLeft: 29,
                        }}
                    >
                        <div
                            style={{
                                width: 24,
                                height: 16,
                                borderRadius: 4,
                                backgroundColor: colors.PRIMARY_BLUE,
                                marginRight: 8,
                            }}
                        />
                        <Body2Light style={{ color: colors.WHITE_900 }}>식단 기록중인 회원</Body2Light>
                    </Row>
                </Row>
            </Row> */}
        </Col>
    );
};

export default MemberMealListGraphHeader;
