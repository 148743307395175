import React from 'react';
import { Row } from '../../../lib/utils';
import { Body1Regular, Body3Regular } from '../../../lib/font';
import { I_COMMENT_ICON, I_LIKE_ICON } from '../../../types/images';
import { colors } from '../../../lib/colors';
import SmallButton from '../../Button/SmallButton';
import WhiteRoundButton from '../../Button/WhiteRoundButton';
type Props = {
    onClickDetail: any;
    comments: any;
    reactions: any;
};
const FeedItemFooter = ({ onClickDetail, comments, reactions }: Props) => {
    return (
        <Row
            style={{
                width: 722,
                alignSelf: 'center',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: 18,
            }}
        >
            <Row style={{ height: 36, alignItems: 'center' }}>
                <Row style={{ alignItems: 'center' }}>
                    <img src={I_LIKE_ICON} style={{ width: 30, height: 30 }} />
                    <Body3Regular style={{ color: colors.LAVEL_3, marginLeft: 4 }}>{`좋아요(${
                        reactions ? reactions.length : 0
                    })`}</Body3Regular>
                </Row>
                <Row style={{ alignItems: 'center', marginLeft: 32 }}>
                    <img src={I_COMMENT_ICON} style={{ width: 30, height: 30 }} />
                    <Body3Regular style={{ color: colors.LAVEL_3, marginLeft: 4 }}>{`댓글(${
                        comments ? comments.length : 0
                    })`}</Body3Regular>
                </Row>
            </Row>

            <WhiteRoundButton
                callBack={onClickDetail}
                size="small"
                customStyle={{ width: 107, height: 36 }}
                text={'전체보기'}
            />
        </Row>
    );
};

export default FeedItemFooter;
