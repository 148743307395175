import React from 'react';
import * as Icons from '../types/svg';
type SvgProps = React.SVGProps<SVGSVGElement>;

type IconProps = SvgProps & {
    name: keyof typeof Icons;
    size?: number;
    gradientId?: string;
    stopColors?: string[]; // New prop for gradient stop colors
};

const SvgIcon = ({
    name,
    fill = 'black',
    width: _width,
    height: _height,
    size,
    stroke,
    className,
    gradientId,
    stopColors,
    ...props
}: IconProps) => {
    const Comp = Icons[name];
    const width = _width ?? size;
    const height = _height ?? size;
    const sizeProps = {
        ...(width !== undefined ? { width } : {}),
        ...(height !== undefined ? { height } : {}),
    };

    return stopColors ? (
        <svg
            {...props}
            width={width}
            height={height}
            className={className}
            fill={fill}
            stroke={stroke}
            xmlns="http://www.w3.org/2000/svg"
        >
            <defs>
                <linearGradient id={gradientId} x1="30" y1="14" x2="2" y2="14" gradientUnits="userSpaceOnUse">
                    {stopColors?.map((color, index) => (
                        <stop key={index} offset={`${index / (stopColors.length - 1)}`} stopColor={color} />
                    ))}
                </linearGradient>
            </defs>
            <Comp fill={gradientId ? `url(#${gradientId})` : fill} {...sizeProps} />
        </svg>
    ) : (
        <Comp
            {...props}
            // 1.2.3. `.svgrrc` 의 설정 덕분에 `fill` prop 을 이렇게 사용할 수 있다.
            fill={fill}
            className={className}
            stroke={stroke}
            {...sizeProps}
        />
    );
};

export default SvgIcon;
