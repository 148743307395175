import React from 'react';
import { Row } from '../../lib/utils';
import { I_FLOATING } from '../../types/images';

type FloatingButtonProps = {
    onClick: () => void;
};

const FloatingButton = ({ onClick }: FloatingButtonProps) => {
    return (
        <Row
            onClick={onClick}
            style={{
                width: 64,
                height: 64,
                position: 'fixed',
                right: 64,
                bottom: 64,
                cursor: 'pointer',
                zIndex: 999999,
            }}
        >
            <img src={I_FLOATING} style={{ width: 64, height: 64 }} />
        </Row>
    );
};

export default FloatingButton;
