import React, { CSSProperties, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Body1Regular, Body2Regular, Body3Regular, CaptionRegular } from '../../lib/font';
import { Row } from '../../lib/utils';
import { I_DEFAULT_SOLID_PLUS } from '../../types/buttonImage';

const ButtonWrap = styled.button`
    border-radius: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 12px;
    padding-right: 12px;
    background-color: ${(props) => (props.disabled ? '#EDF4FF' : '#fdfeff')};
    border: ${(props) => (props.disabled ? '1px solid #A5ABB5' : '1px solid #3d73dd')};
    color: ${(props) => (props.disabled ? '#A5ABB5' : '#3D73DD')};

    &:not([disabled]):hover {
        background-color: #f5f9ff;
        border: 1px solid #3869c9;
        color: #3869c9;
    }
    &:not([disabled]):active {
        background-color: #ecf1fc;
        border: 1px solid #2b529d;
        color: #2b529d;
    }
`;

type Props = {
    text: string;
    isVisibleLeftImg?: boolean;
    isVisibleRightImg?: boolean;
    isDisabled?: boolean;
    size?: 'big' | 'middle' | 'small' | 'tiny';
    callBack?: any;
    type?: 'button' | 'submit';
    customStyle?: CSSProperties;
};

const WhiteRoundButton = ({
    text,
    isVisibleLeftImg = false,
    isVisibleRightImg = false,
    isDisabled = false,
    size,
    callBack,
    type,
    customStyle,
}: Props) => {
    const BigStyle: CSSProperties = {
        width: 217,
        height: 64,
    };

    const MiddleStyle: CSSProperties = {
        width: 185,
        height: 48,
    };

    const SmallStyle: CSSProperties = {
        width: 140,
        height: 36,
    };

    const TinyStyle: CSSProperties = {
        width: 77,
        height: 24,
    };

    const buttonStyle: CSSProperties = {
        borderRadius: '48px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: isDisabled ? '#EDF4FF' : '#FDFEFF',

        color: isDisabled ? '#A5ABB5' : '#3D73DD',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        ...(customStyle
            ? customStyle
            : size === 'big'
            ? BigStyle
            : size === 'middle'
            ? MiddleStyle
            : size === 'small'
            ? SmallStyle
            : TinyStyle),
    };

    // 호버시
    const handleMouseOver = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!isDisabled) {
            e.currentTarget.style.backgroundColor = '#F5F9FF';
            e.currentTarget.style.color = '#3D73DD';
        }
    };

    // 디폴트 컬러
    const handleMouseOut = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!isDisabled) {
            e.currentTarget.style.backgroundColor = '#FDFEFF';
            e.currentTarget.style.color = '#3869C9';
        }
    };

    // 액티브
    const handleMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!isDisabled) {
            e.currentTarget.style.backgroundColor = '#ECF1FC';
            e.currentTarget.style.color = '#2B529D';
        }
    };

    // 디폴트 컬러
    const handleMouseUp = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!isDisabled) {
            e.currentTarget.style.backgroundColor = '#FDFEFF';
            e.currentTarget.style.color = '#3D73DD';
        }
    };

    return (
        <ButtonWrap
            onClick={callBack}
            style={buttonStyle}
            disabled={isDisabled}
            type={type}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
        >
            {(size === 'big' || size === 'middle') && isVisibleLeftImg && (
                <Row
                    style={{
                        width: 24,
                        height: 24,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <img src={I_DEFAULT_SOLID_PLUS} style={{ width: 18, height: 18, marginRight: 8 }} />
                </Row>
            )}

            {size === 'big' && <Body1Regular>{text}</Body1Regular>}
            {size === 'middle' && <Body2Regular>{text}</Body2Regular>}
            {size === 'small' && <Body3Regular>{text}</Body3Regular>}
            {size === 'tiny' && <CaptionRegular>{text}</CaptionRegular>}

            {(size === 'big' || size === 'middle') && isVisibleRightImg && (
                <Row
                    style={{
                        width: 24,
                        height: 24,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {<img src={I_DEFAULT_SOLID_PLUS} style={{ width: 18, height: 18, marginLeft: 8 }} />}
                </Row>
            )}
        </ButtonWrap>
    );
};

export default WhiteRoundButton;
