import React, { Dispatch, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import { Col, Row } from '../../lib/utils';
import { Body1Regular, Body2Bold, Body2Regular, Body3Regular, CaptionRegular, Subtitle1Bold } from '../../lib/font';
import { I_POPUP_EXIT, I_RIGHT_PRIMARY_BTN } from '../../types/images';
import { colors } from '../../lib/colors';

import { loadTossPayments } from '@tosspayments/payment-sdk';
import dayjs from 'dayjs';

import ColorRoundButton from '../Button/ColorRoundButton';
import WhiteRoundButton from '../Button/WhiteRoundButton';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalInner = styled.div`
    background: white;
    width: 1080px;
    height: 700px;
    border-radius: 8px;
    box-shadow: 8px 8px 32px 0px #0000007a;
`;

const HoverImage = styled.img`
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

type Props = {
    setModalVisible: Dispatch<SetStateAction<boolean>>;
    authObject: any;
    onClickRepeatCancelSubscription: () => void;
    onClickCancelWithDrawal: () => void;
    navigate: any;
};

const ModalUsingPlan = ({
    setModalVisible,
    authObject,
    navigate,
    onClickRepeatCancelSubscription,
    onClickCancelWithDrawal,
}: Props) => {
    const LocalSuccessUrl: any = process.env.REACT_APP_LOCAL_CHANGE_PAY_INFO_SUCCESS_URL;
    const LocalFailUrl: any = process.env.REACT_APP_LOCAL_CHANGE_PAY_INFO_FAIL_URL;
    const ProdSuccessUrl: any = process.env.REACT_APP_PROD_CHANGE_PAY_INFO_SUCCESS_URL;
    const ProdFailUrl: any = process.env.REACT_APP_PROD_CHANGE_PAY_INFO_FAIL_URL;

    // 토스 결제창 연동 함수
    const tossPayFunction = async () => {
        const clientKey: any =
            process.env.NODE_ENV === 'development'
                ? process.env.REACT_APP_TOSS_TEST_CLIENT_KEY
                : process.env.REACT_APP_TOSS_LIVE_CLIENT_KEY;

        const customerKeyUUID = authObject?.id;

        await loadTossPayments(clientKey).then((tossPayments) => {
            // ------ 카드 등록창 호출 ------
            tossPayments
                .requestBillingAuth('카드', {
                    customerKey: customerKeyUUID, // 고객 ID로 상점에서 만들어야 합니다. 빌링키와 매핑됩니다. 자세한 파라미터 설명은 결제 정보 파라미터 설명을 참고하세요.
                    successUrl: process.env.NODE_ENV === 'development' ? LocalSuccessUrl : ProdSuccessUrl,
                    failUrl: process.env.NODE_ENV === 'development' ? LocalFailUrl : ProdFailUrl,
                })
                .catch(function (error) {
                    console.log(error.code);

                    if (error.code === 'USER_CANCEL') {
                        // 결제 고객이 결제창을 닫았을 때 에러 처리
                    }
                });
        });
    };

    // 결제 수단 변경 버튼 클릭이벤트
    const onClickChangePayInformation = () => {
        tossPayFunction();
    };

    return (
        <ModalOverLay>
            <ModalInner>
                <Row style={{}}>
                    <Subtitle1Bold style={{ color: colors.LAVEL_4, marginLeft: 48, marginTop: 36 }}>
                        구독 플랜
                    </Subtitle1Bold>

                    <HoverImage
                        src={I_POPUP_EXIT}
                        style={{ width: 32, height: 32, marginLeft: 890, marginTop: 16 }}
                        onClick={() => setModalVisible(false)}
                    />
                </Row>

                <Col style={{ marginLeft: 56, marginTop: 16 }}>
                    <Row style={{ alignItems: 'center' }}>
                        <Body2Bold style={{ color: colors.LAVEL_4 }}>사용 플랜 :</Body2Bold>
                        <Body2Bold style={{ color: colors.LAVEL_4, marginLeft: 16 }}>
                            {authObject?.usableStatus === 'trial' || authObject?.usableStatus === 'trialPaymentPending'
                                ? '무료 플랜'
                                : authObject?.usableStatus === 'planWillChanged'
                                ? `Rappo Manager Plan (${authObject?.prevPlanLimitCount}인)`
                                : `Rappo Manager Plan (${authObject?.planLimitCount}인)`}
                        </Body2Bold>
                        {authObject?.usableStatus === 'trial' ||
                        authObject?.usableStatus === 'trialPaymentPending' ||
                        authObject?.usableStatus === 'plan' ? (
                            <></>
                        ) : (
                            <img
                                src={I_RIGHT_PRIMARY_BTN}
                                style={{ width: 16, height: 16, marginLeft: 16, marginRight: 16 }}
                            />
                        )}

                        {authObject?.usableStatus === 'planWillChanged' && (
                            <Body2Bold style={{ width: 136, color: colors.LAVEL_4 }}>
                                ({authObject?.changedPlanLimitCount}인)
                            </Body2Bold>
                        )}
                        {authObject?.usableStatus === 'planWillCanceled' && (
                            <Body2Bold style={{ width: 136, color: colors.ERROR }}>구독 취소</Body2Bold>
                        )}
                        {authObject?.usableStatus === 'planAborted' && (
                            <Body2Bold style={{ width: 136, color: colors.ERROR }}>결제 실패</Body2Bold>
                        )}
                        {authObject?.usableStatus === 'trialPaymentPending' && (
                            <Body2Bold style={{ width: 136, color: colors.ERROR, marginLeft: 10 }}>
                                - 체험 만료
                            </Body2Bold>
                        )}
                    </Row>

                    <Row style={{ marginTop: 8 }}>
                        <Body3Regular style={{ color: colors.LAVEL_2 }}>현재 등록된 강사 :</Body3Regular>
                        <Body3Regular style={{ color: colors.LAVEL_2, marginLeft: 16 }}>
                            {authObject?.coachCount}명
                        </Body3Regular>
                    </Row>

                    <Row style={{ marginTop: 24 }}>
                        {/* 적용 할인 해택 , 결제일 , 이용기간 */}
                        <Col
                            style={{
                                width: 620,
                                height: 358,
                                borderRadius: 16,
                                backgroundColor: colors.WHITE_50,
                                border: '1px solid #C3D4F4',
                                paddingLeft: 32,
                                paddingTop: 32,
                            }}
                        >
                            <Body2Regular style={{ color: colors.LAVEL_4 }}>적용 할인 혜택</Body2Regular>
                            <Body3Regular style={{ color: colors.LAVEL_2, marginTop: 4 }}>
                                {authObject?.usableStatus === 'trial'
                                    ? '무료 플랜 이용중입니다'
                                    : authObject?.usableStatus === 'trialPaymentPending'
                                    ? '무료체험플랜 이용이 만료되었습니다 구독플랜을 변경해주세요'
                                    : authObject?.planLimitCount > 3
                                    ? 'Pro Manager Sale (약 60% 할인) - 1인 당  39,900 원'
                                    : 'Starter Manager Sale (약 65% 할인) - 1인 당  32,900 원'}
                            </Body3Regular>

                            <Row style={{ alignItems: 'center', marginTop: 40 }}>
                                <Col style={{ marginRight: 32 }}>
                                    <Body2Regular style={{ color: colors.LAVEL_4 }}>마지막 결제일</Body2Regular>

                                    <Row
                                        style={{
                                            width: 200,
                                            height: 36,
                                            borderRadius: 36,
                                            backgroundColor: colors.WHITE_500,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginTop: 12,
                                        }}
                                    >
                                        <Body3Regular style={{ color: colors.LAVEL_1, marginTop: 4 }}>
                                            {authObject?.usableStatus === 'trial' ||
                                            authObject?.usableStatus === 'trialPaymentPending'
                                                ? '-'
                                                : dayjs(authObject?.finalPaymentDate).format('YYYY년 MM월 DD일(ddd)')}
                                        </Body3Regular>
                                    </Row>
                                </Col>
                                <Col>
                                    <Body2Regular style={{ color: colors.LAVEL_4 }}>다음 결제일</Body2Regular>
                                    <Row
                                        style={{
                                            width: 200,
                                            height: 36,
                                            borderRadius: 36,
                                            backgroundColor: colors.WHITE_500,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginTop: 12,
                                        }}
                                    >
                                        <Body3Regular style={{ color: colors.LAVEL_1, marginTop: 4 }}>
                                            {authObject?.usableStatus === 'trial' ||
                                            authObject?.usableStatus === 'trialPaymentPending'
                                                ? '-'
                                                : dayjs(authObject?.expirationDate).format('YYYY년 MM월 DD일(ddd)')}
                                        </Body3Regular>
                                    </Row>
                                </Col>
                            </Row>

                            <Col style={{ marginTop: 40 }}>
                                <Body2Regular style={{ color: colors.LAVEL_4 }}>플랜 이용기간</Body2Regular>
                                <Row
                                    style={{
                                        width: 432,
                                        height: 36,
                                        borderRadius: 36,
                                        backgroundColor: colors.WHITE_500,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: 12,
                                    }}
                                >
                                    <Body3Regular style={{ color: colors.BLUE_900, marginTop: 4 }}>
                                        {dayjs(authObject?.activationDate).format('YYYY년 MM월 DD일(ddd) ~ ') +
                                            dayjs(authObject?.expirationDate).format('YYYY년 MM월 DD일(ddd)')}
                                    </Body3Regular>
                                </Row>
                            </Col>
                        </Col>

                        {/* 결제금액 / 결제카드 */}
                        <Col
                            style={{
                                width: 324,
                                height: 358,
                                borderRadius: 16,
                                backgroundColor: colors.WHITE_300,
                                border: '1px solid #C3D4F4',
                                marginLeft: 24,
                                paddingLeft: 32,
                                paddingTop: 32,
                            }}
                        >
                            <Col style={{ width: 260, height: 216 }}>
                                <Body2Regular style={{ color: colors.LAVEL_4 }}>결제 금액</Body2Regular>

                                {authObject?.usableStatus === 'planWillChanged' && (
                                    <Col style={{ marginTop: 16 }}>
                                        <Body2Regular style={{ color: colors.LAVEL_2 }}>
                                            {authObject?.prevPlanLimitCount && authObject?.prevPlanLimitCount > 3
                                                ? (authObject?.prevPlanLimitCount * 39900).toLocaleString()
                                                : (authObject?.prevPlanLimitCount * 32900).toLocaleString()}
                                            원 <CaptionRegular>/ 월 VAT포함 (이전 플랜)</CaptionRegular>
                                        </Body2Regular>
                                    </Col>
                                )}

                                {authObject?.usableStatus === 'planWillChanged' ? (
                                    <Col style={{ marginTop: 16 }}>
                                        <Body2Regular style={{ color: colors.LAVEL_2 }}>
                                            {authObject?.changedPlanLimitCount > 3
                                                ? (authObject?.changedPlanLimitCount * 39900).toLocaleString()
                                                : (authObject?.changedPlanLimitCount * 32900).toLocaleString()}
                                            원 <CaptionRegular>/ 월 VAT포함 (다음 플랜)</CaptionRegular>
                                        </Body2Regular>
                                    </Col>
                                ) : (
                                    <Col style={{ marginTop: 16 }}>
                                        <Body2Regular style={{ color: colors.LAVEL_2 }}>
                                            {authObject?.planLimitCount
                                                ? authObject?.planLimitCount > 3
                                                    ? (authObject?.planLimitCount * 39900).toLocaleString() +
                                                      '원 / 월 VAT포함'
                                                    : (authObject?.planLimitCount * 32900).toLocaleString() +
                                                      '원 / 월 VAT포함'
                                                : '0원 / 월 VAT포함 (다음 플랜)'}
                                        </Body2Regular>
                                    </Col>
                                )}

                                {/* 구독취소 상태 일때 분기처리 */}
                                {authObject?.usableStatus === 'planWillCanceled' && (
                                    <Col style={{ marginTop: 16 }}>
                                        <Body2Regular style={{ color: colors.ERROR }}>
                                            구독 취소 (다음 플랜)
                                        </Body2Regular>
                                    </Col>
                                )}

                                <Col style={{ marginTop: 42 }}>
                                    <Body2Regular style={{ color: colors.LAVEL_4 }}>결제 카드</Body2Regular>
                                    <Body3Regular style={{ color: colors.LAVEL_2, marginTop: 16 }}>
                                        {authObject?.usableStatus === 'trial' ||
                                        authObject?.usableStatus === 'trialPaymentPending'
                                            ? '카드를 등록해주세요'
                                            : authObject?.cardIssuingBank + ' ' + authObject?.cardNumber}
                                    </Body3Regular>

                                    {/* 결제취소 상태 일때 분기처리 */}
                                    {authObject?.usableStatus === 'planAborted' && (
                                        <Body3Regular style={{ color: colors.ERROR, marginTop: 16 }}>
                                            카드 정보를 다시 확인해주세요
                                        </Body3Regular>
                                    )}
                                </Col>
                            </Col>

                            <ColorRoundButton
                                text="결제 수단 변경"
                                size="small"
                                callBack={onClickChangePayInformation}
                                customStyle={{ width: 180, height: 36, marginLeft: 40, marginTop: 42 }}
                                isDisabled={
                                    authObject?.testAccount ||
                                    authObject?.usableStatus === 'planWillCanceled' ||
                                    authObject?.usableStatus === 'trial' ||
                                    authObject?.usableStatus === 'trialPaymentPending'
                                }
                            />
                        </Col>
                    </Row>
                    <Row style={{ alignItems: 'center', marginTop: 16 }}>
                        <div
                            style={{
                                width: 4,
                                height: 4,
                                backgroundColor: colors.WHITE_900,
                                borderRadius: 2,
                                marginRight: 5,
                            }}
                        />
                        <CaptionRegular style={{ color: colors.LAVEL_2 }}>
                            우측의 결제 수단으로 월 정기결제로 이루어지며 결제일에 맞춰 플랜 이용기간이 갱신됩니다.
                        </CaptionRegular>
                    </Row>
                    <Row style={{ alignItems: 'center' }}>
                        <div
                            style={{
                                width: 4,
                                height: 4,
                                backgroundColor: colors.WHITE_900,
                                borderRadius: 2,
                                marginRight: 5,
                            }}
                        />
                        <CaptionRegular style={{ color: colors.LAVEL_2 }}>
                            결제가 실패할 경우 7일간의 유예기간이 주어지며 유예기간이 끝나면 연결된 모든 라포 서비스를
                            이용하실 수 없습니다.
                        </CaptionRegular>
                    </Row>
                </Col>

                {/* 하단버튼 */}
                <Row style={{ marginTop: 38, marginLeft: 46 }}>
                    {authObject?.usableStatus === 'planWillCanceled' ? (
                        <WhiteRoundButton
                            text="구독 취소 철회"
                            size="middle"
                            callBack={onClickCancelWithDrawal}
                            isDisabled={authObject?.testAccount}
                            isVisibleLeftImg={false}
                            isVisibleRightImg={false}
                            customStyle={{ width: 148, height: 48 }}
                        />
                    ) : (
                        <WhiteRoundButton
                            text="구독 취소"
                            size="middle"
                            callBack={onClickRepeatCancelSubscription}
                            isDisabled={
                                authObject?.testAccount ||
                                authObject?.usableStatus === 'trial' ||
                                authObject?.usableStatus === 'trialPaymentPending'
                            }
                            isVisibleLeftImg={false}
                            isVisibleRightImg={false}
                            customStyle={{ width: 148, height: 48 }}
                        />
                    )}

                    <ColorRoundButton
                        text="구독 플랜 변경하기"
                        size="middle"
                        callBack={() => {
                            setModalVisible(false);
                            authObject?.usableStatus === 'trial' || authObject?.usableStatus === 'trialPaymentPending'
                                ? navigate('SubscriptionPlanPage')
                                : navigate('SubscriptionPlanChangePage');
                        }}
                        customStyle={{ width: 200, height: 48, marginLeft: 632 }}
                        isDisabled={authObject?.testAccount || authObject?.usableStatus === 'planWillCanceled'}
                        isGradient={true}
                    />
                </Row>
            </ModalInner>
        </ModalOverLay>
    );
};

export default ModalUsingPlan;
