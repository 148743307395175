import React, { useState } from 'react';
import { Col, Row } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import { Body3Bold, Body3Light, CaptionBold, CaptionLight, CaptionRegular } from '../../../lib/font';
import dayjs from 'dayjs';
import ColorRoundButton from '../../Button/ColorRoundButton';
import WhiteRoundButton from '../../Button/WhiteRoundButton';
import { I_DOWN_TRIANGLE } from '../../../types/images';
import styled, { keyframes, css } from 'styled-components';

// 애니메이션 정의
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

interface StyledColProps {
    requestType?: string;
    backgroundColor: string;
    isVisible: boolean;
    parentIndex: number; // 추가된 prop
}

// 스타일드 컴포넌트 정의
const HoverItem = styled.div<StyledColProps>`
    display: flex;
    flex-direction: column;
    width: 300px;
    height: ${(props) => (props.requestType === 'change' ? '384px' : '224px')};
    background-color: ${(props) => props.backgroundColor};
    position: absolute;
    border-radius: 16px;
    box-shadow: 4px 4px 8px 0px #b24b3d52;
    padding-left: 16px;
    padding-right: 24px;
    padding-top: 14px;
    z-index: 3;
    transition: all 1s ease;
    animation: ${(props) => (props.isVisible ? fadeIn : 'none')} 1s ease forwards;

    /* parentIndex에 따라 다른 방향으로 투명한 영역 추가 */
    &::after {
        content: '';
        width: 50px; /* 투명한 영역 */
        height: 100%;
        position: absolute;
        top: 0;
        ${(props) =>
            props.parentIndex > 5
                ? css`
                      right: -50px;
                  `
                : css`
                      left: -50px;
                  `}
    }
`;

const RequestBlock = ({
    onClickRequest,
    onClickApproveScheduleRequest,
    onClickRejectScheduleRequest,
    parentItem,
    parentIndex,
    childItem,
    colPosition,
    rowPosition,
    boxHeight,
}: any) => {
    const [showModal, setShowModal] = useState(false);

    const hoverStyle =
        parentIndex > 5
            ? {
                  right: '110%',
              }
            : {
                  left: '110%',
              };

    const handleMouseEnter = () => {
        setShowModal(true);
    };

    const handleMouseLeave = () => {
        // 마우스가 빈 공간에 있을 때 onMouseLeave 호출 방지
        setShowModal(false);
    };

    const handleRejectButtonClick = (event: React.MouseEvent) => {
        event.stopPropagation(); // 이벤트 버블링 방지
        onClickRejectScheduleRequest(childItem);
    };

    const handleApproveButtonClick = (event: React.MouseEvent) => {
        event.stopPropagation(); // 이벤트 버블링 방지
        onClickApproveScheduleRequest(childItem);
    };

    return (
        <Col
            onClick={() => {
                onClickRequest(childItem);
            }}
            style={{
                width: '10.4%',
                height: boxHeight,
                position: 'absolute',
                marginTop: colPosition,
                marginLeft: `${rowPosition}%`,
                borderRadius: 4,
                justifyContent: 'center',
                alignItems: 'start',
                paddingLeft: '1.11%',
                border: '2px solid #FF8777',
                backgroundColor: '#FFFCFB',
                boxShadow: showModal ? '0px 0px 12px 0px #FF877782' : '',
                cursor: 'pointer',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Col>
                <Body3Bold style={{ color: colors.LAVEL_3 }}>{childItem?.alias} 회원</Body3Bold>

                <CaptionLight style={{ color: colors.LAVEL_2 }}>{`${childItem?.duration}분`}</CaptionLight>
            </Col>

            {showModal && (
                <HoverItem
                    style={hoverStyle}
                    requestType={childItem?.requestType}
                    backgroundColor={colors.WHITE_50}
                    isVisible={showModal}
                    parentIndex={parentIndex}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <Row
                        style={{
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Row style={{ width: 200 }}>
                            <Col
                                style={{
                                    width: 8,
                                    height: 22,
                                    borderRadius: 8,
                                    backgroundColor: colors.ORANGE,
                                    marginRight: 11,
                                }}
                            />
                            <Body3Bold style={{ color: colors.LAVEL_3 }}>{`${parentItem?.coachName} 강사`}</Body3Bold>
                        </Row>
                        <Row
                            style={{
                                width: 64,
                                height: 23,
                                borderRadius: 34,
                                border: '1px solid #FF8777',
                                color: colors.ORANGE,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <CaptionBold>{childItem?.requestType === 'change' ? '변경 요청' : '수업 신청'}</CaptionBold>
                        </Row>
                    </Row>

                    {childItem?.requestType === 'register' && (
                        <>
                            <Body3Light style={{ color: colors.LAVEL_3, marginTop: 16 }}>
                                {dayjs(childItem?.startTime).format('M월 D일(ddd)')}
                            </Body3Light>
                            <Body3Bold style={{ color: colors.LAVEL_3, marginTop: 8 }}>
                                {dayjs(childItem?.startTime).format('A h:mm ~ ')}
                                {dayjs(childItem?.endTime).format('A h:mm ')}
                                {`(${childItem?.duration}분)`}
                            </Body3Bold>
                            <Row style={{ alignItems: 'center', marginTop: 8 }}>
                                <Body3Bold style={{ color: colors.LAVEL_3 }}>{childItem?.alias} 회원</Body3Bold>
                                {/* <Body3Light style={{ color: colors.LAVEL_2, marginLeft: 16 }}>
                                    {`${childItem?.currentSession ? childItem?.currentSession : '?'}/${
                                        childItem?.totalSession ? childItem?.totalSession : '?'
                                    }회`}
                                </Body3Light> */}
                            </Row>
                        </>
                    )}

                    {childItem?.requestType === 'change' && (
                        <>
                            <Col style={{ alignItems: 'center' }}>
                                <Col
                                    style={{
                                        width: 268,
                                        borderRadius: 8,
                                        height: 88,
                                        border: '1px solid #D4DBE8',
                                        marginTop: 16,
                                        padding: 16,
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Row style={{ alignItems: 'center' }}>
                                        <Row
                                            style={{
                                                width: 38,
                                                height: 22,
                                                borderRadius: 24,
                                                backgroundColor: colors.GREY_200,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: colors.WHITE_50,
                                            }}
                                        >
                                            <CaptionRegular>{'기존'}</CaptionRegular>
                                        </Row>
                                        <Body3Light style={{ color: colors.LAVEL_3, marginLeft: 8 }}>
                                            {dayjs(childItem?.beforeStartTime).format('M월 D일(ddd)')}
                                        </Body3Light>
                                    </Row>
                                    <Body3Bold style={{ color: colors.LAVEL_3 }}>
                                        {dayjs(childItem?.beforeStartTime).format('A h:mm ~ ')}{' '}
                                        {dayjs(childItem?.beforeEndTime).format('A h:mm')}
                                    </Body3Bold>
                                </Col>

                                <img
                                    src={I_DOWN_TRIANGLE}
                                    style={{
                                        width: 16,
                                        height: 16,
                                        marginTop: 8,
                                        marginBottom: 8,
                                    }}
                                />

                                <Col
                                    style={{
                                        width: 268,
                                        borderRadius: 8,
                                        height: 88,
                                        border: '1px solid #D4DBE8',
                                        padding: 16,
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Row style={{ alignItems: 'center' }}>
                                        <Row
                                            style={{
                                                width: 38,
                                                height: 22,
                                                borderRadius: 24,
                                                backgroundColor: colors.BLUE_500,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: colors.WHITE_50,
                                            }}
                                        >
                                            <CaptionRegular>{'변경'}</CaptionRegular>
                                        </Row>
                                        <Body3Light style={{ color: colors.LAVEL_3, marginLeft: 8 }}>
                                            {dayjs(childItem?.changedStartTime).format('M월 D일(ddd)')}
                                        </Body3Light>
                                    </Row>
                                    <Body3Bold style={{ color: colors.LAVEL_3 }}>
                                        {dayjs(childItem?.changedStartTime).format('A h:mm ~ ')}{' '}
                                        {dayjs(childItem?.changedEndTime).format('A h:mm')}
                                    </Body3Bold>
                                </Col>
                            </Col>

                            <Body3Bold style={{ color: colors.LAVEL_3, marginTop: 16 }}>
                                {childItem?.alias} 회원
                            </Body3Bold>
                        </>
                    )}

                    <CaptionRegular style={{ color: colors.LAVEL_2, marginTop: 8 }}>
                        수강권 : {childItem?.membership ? childItem?.membership?.name : '-'}
                    </CaptionRegular>

                    <Row
                        style={{
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginTop: 16,
                        }}
                    >
                        <WhiteRoundButton
                            text={'거절하기'}
                            size="tiny"
                            customStyle={{ width: 120, height: 24 }}
                            callBack={handleRejectButtonClick}
                        />
                        <ColorRoundButton
                            text={'수락하기'}
                            size="tiny"
                            customStyle={{ width: 120, height: 24 }}
                            callBack={handleApproveButtonClick}
                        />
                    </Row>
                </HoverItem>
            )}
        </Col>
    );
};

export default RequestBlock;
