import React from 'react';
import { Row } from '../../../lib/utils';
import { Body3Regular } from '../../../lib/font';
import { colors } from '../../../lib/colors';

const TopTableOfMemberCancelHistory = () => {
    return (
        <Row
            style={{
                alignItems: 'center',
                width: 1100,
                height: 56,
                borderTop: '1px solid #c3d4f4',
                borderBottom: '1px solid #c3d4f4',
                paddingLeft: 12,
            }}
        >
            <Row style={{ width: 108, height: 32, alignItems: 'center', justifyContent: 'center' }}>
                <Body3Regular style={{ color: colors.LAVEL_3 }}>강사 이름</Body3Regular>
            </Row>
            <div style={{ width: 1, height: 24, backgroundColor: colors.BLUE_100, marginLeft: 20, marginRight: 20 }} />

            <Row style={{ width: 108, height: 32, alignItems: 'center', justifyContent: 'center' }}>
                <Body3Regular style={{ color: colors.LAVEL_3 }}>회원 이름</Body3Regular>
            </Row>
            <div style={{ width: 1, height: 24, backgroundColor: colors.BLUE_100, marginLeft: 20, marginRight: 20 }} />

            <Row style={{ width: 172, height: 32, alignItems: 'center', justifyContent: 'center' }}>
                <Body3Regular style={{ color: colors.LAVEL_3 }}>기존 수업 시간</Body3Regular>
            </Row>
            <div style={{ width: 1, height: 24, backgroundColor: colors.BLUE_100, marginLeft: 20, marginRight: 20 }} />

            <Row style={{ width: 100, height: 32, alignItems: 'center', justifyContent: 'center' }}>
                <Body3Regular style={{ color: colors.LAVEL_3 }}>취소 시간</Body3Regular>
            </Row>
            <div style={{ width: 1, height: 24, backgroundColor: colors.BLUE_100, marginLeft: 20, marginRight: 20 }} />

            <Row style={{ width: 340, height: 32, alignItems: 'center', justifyContent: 'center' }}>
                <Body3Regular style={{ color: colors.LAVEL_3 }}> 사유</Body3Regular>
            </Row>
            <div style={{ width: 1, height: 24, backgroundColor: colors.BLUE_100, marginLeft: 20, marginRight: 20 }} />
            <Row style={{ width: 75, height: 32, alignItems: 'center', justifyContent: 'center' }}>
                <Body3Regular style={{ color: colors.LAVEL_3 }}>취소 횟수</Body3Regular>
            </Row>
        </Row>
    );
};

export default TopTableOfMemberCancelHistory;
