import { Dispatch, SetStateAction } from 'react';
import { Body2Regular, Subtitle1Bold } from '../../lib/font';
import { colors } from '../../lib/colors';

import styled from 'styled-components';
import { I_GREEN_FILL_ALERT } from '../../types/images';

import ColorSquareButton from '../Button/ColorSquareButton';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2002;
`;

const ModalInner = styled.div`
    background: white;
    width: 540px;
    height: 278px;
    border-radius: 8px;
    box-shadow: 8px 8px 24px 0px #00000052;

    display: flex;
    flex-direction: column;
    align-items: center;
`;

type Props = {
    modalVisible: boolean;
    setModalVisible: Dispatch<SetStateAction<boolean>>;
    successModalTitle?: string;
    successModalContent: string;
    navigate: any;
    isCallback?: boolean;
};

const ModalSuccess = ({
    modalVisible,
    setModalVisible,
    successModalTitle,
    successModalContent,
    navigate,
    isCallback,
}: Props) => {
    return (
        <ModalOverLay>
            <ModalInner>
                <img
                    src={I_GREEN_FILL_ALERT}
                    style={{
                        width: 24,
                        height: 24,
                        marginBottom: 8,
                        marginTop: 36,
                    }}
                />

                <Subtitle1Bold
                    style={{
                        color: colors.LAVEL_4,
                        whiteSpace: 'pre-wrap',
                        textAlign: 'center',
                    }}
                >
                    {successModalTitle}
                </Subtitle1Bold>

                <Body2Regular
                    style={{
                        color: colors.LAVEL_4,
                        whiteSpace: 'pre-wrap',
                        textAlign: 'center',
                        marginTop: 32,
                        marginBottom: 32,
                    }}
                >
                    {successModalContent}
                </Body2Regular>

                <ColorSquareButton
                    text="다음"
                    callBack={() => {
                        setModalVisible(false);
                        isCallback && navigate('/myPage');
                    }}
                    size="middle"
                    isVisibleLeftImg={false}
                    isVisibleRightImg={false}
                />
            </ModalInner>
        </ModalOverLay>
    );
};

export default ModalSuccess;
