import { useEffect, useState } from 'react';
import { colors } from '../../lib/colors';
import { Row } from '../../lib/utils';
import { Body2Bold, Body3Bold } from '../../lib/font';
import { I_LEFT_PRIMARY_BTN, I_RIGHT_PRIMARY_BTN } from '../../types/images';
import styled from 'styled-components';
import dayjs from 'dayjs';

const HoverAbleTodayBeforeCell: any = styled.td`
    background-color: ${({ isbetween, isstart, isend, isstartclicked, issamestartend }: any) =>
        issamestartend === 'true'
            ? '#FDFEFF'
            : isstartclicked === 'true'
            ? '#3D73DD'
            : isbetween === 'true' || isstart === 'true' || isend === 'true'
            ? '#E9F1FF'
            : '#FDFEFF'};

    border-top-left-radius: ${({ isbetween, isend }: any) =>
        isend === 'true' ? '0px' : isbetween === 'true' ? '0px' : '25px'};
    border-bottom-left-radius: ${({ isbetween, isend }: any) =>
        isend === 'true' ? '0px' : isbetween === 'true' ? '0px' : '25px'};
    border-top-right-radius: ${({ isbetween, isstart }: any) =>
        isstart === 'true' ? '0px' : isbetween === 'true' ? '0px' : '25px'};
    border-bottom-right-radius: ${({ isbetween, isstart }: any) =>
        isstart === 'true' ? '0px' : isbetween === 'true' ? '0px' : '25px'};

    color: ${({ isbetween, isstartclicked }: any) =>
        isstartclicked === 'true' ? '#FDFEFF' : isbetween === 'true' ? '#1A305D' : '#1A305D'};
    padding: 10px;
    cursor: pointer;
    // transition: background-color 0.3s ease;

    &:hover {
        background-color: #e9f1ff;
    }
`;

const HoverAbleTodayAfterCell: any = styled.td`
    background-color: ${({ isbetween, isstart, isend, isstartclicked, issamestartend }: any) =>
        issamestartend === 'true'
            ? '#FDFEFF'
            : isstartclicked === 'true'
            ? '#3D73DD'
            : isbetween === 'true' || isstart === 'true' || isend === 'true'
            ? '#E9F1FF'
            : '#FDFEFF'}; /* 클릭 여부에 따른 배경색 설정 */

    border-top-left-radius: ${({ isbetween, isend }: any) =>
        isend === 'true' ? '0px' : isbetween === 'true' ? '0px' : '25px'};
    border-bottom-left-radius: ${({ isbetween, isend }: any) =>
        isend === 'true' ? '0px' : isbetween === 'true' ? '0px' : '25px'};
    border-top-right-radius: ${({ isbetween, isstart }: any) =>
        isstart === 'true' ? '0px' : isbetween === 'true' ? '0px' : '25px'};
    border-bottom-right-radius: ${({ isbetween, isstart }: any) =>
        isstart === 'true' ? '0px' : isbetween === 'true' ? '0px' : '25px'};

    color: ${({ isbetween, isstartclicked }: any) =>
        isstartclicked === 'true' ? '#FDFEFF' : isbetween === 'true' ? '#1A305D' : '#1A305D'};
    padding: 10px;
    cursor: pointer;
    // transition: background-color 0.3s ease; /* 부드러운 전환 효과 */

    &:hover {
        background-color: #e9f1ff;
    }
`;

const RangeCalendar = ({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    isPrevMonth,
    isNextMonth,
    setIsCalendar,
}: any) => {
    const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];
    const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const [clickType, setClickType] = useState<string>('start');

    const isSameDay = (toDay: any, compareDay: any) => {
        if (toDay && compareDay) {
            if (
                toDay.getFullYear() === compareDay?.getFullYear() &&
                toDay.getMonth() === compareDay?.getMonth() &&
                toDay.getDate() === compareDay?.getDate()
            ) {
                return true;
            }
        }

        return false;
    };

    const isBetweenDay = (compareDay: any) => {
        if (dayjs(compareDay).isBetween(startDate, endDate)) {
            return true;
        }
        return false;
    };

    const isStartDay = (compareDay: any) => {
        if (clickType === 'start') {
            if (startDate) {
                if (
                    startDate.getFullYear() === compareDay?.getFullYear() &&
                    startDate.getMonth() === compareDay?.getMonth() &&
                    startDate.getDate() === compareDay?.getDate()
                ) {
                    return true;
                } else {
                    return false;
                }
            }
        }
        return false;
    };

    const isEndDay = (compareDay: any) => {
        if (clickType === 'start') {
            if (endDate) {
                if (
                    endDate.getFullYear() === compareDay?.getFullYear() &&
                    endDate.getMonth() === compareDay?.getMonth() &&
                    endDate.getDate() === compareDay?.getDate()
                ) {
                    return true;
                } else {
                    return false;
                }
            }

            return false;
        }
        return false;
    };

    const onClickDay = (day: Date) => {
        if (clickType === 'start') {
            setStartDate(day);
            setEndDate(null);
            setClickType('end');
            return;
        }

        if (clickType === 'end') {
            if (dayjs(day).isBefore(startDate)) {
                setStartDate(day);
                setEndDate(startDate);
                setClickType('start');
                setIsCalendar(false);
                return;
            } else {
                setEndDate(day);
                setClickType('start');
                setIsCalendar(false);
                return;
            }
        }
    };

    const prevCalendar = (event: React.MouseEvent) => {
        event.stopPropagation();
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, currentMonth.getDate()));
    };

    const nextCalendar = (event: React.MouseEvent) => {
        event.stopPropagation();
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, currentMonth.getDate()));
    };

    const buildCalendarDays = () => {
        const curMonthStartDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1).getDay();
        const curMonthEndDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
        const prevMonthEndDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 0);
        const nextMonthStartDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1);
        const days: Date[] = Array.from({ length: curMonthStartDate }, (_, i) => {
            return new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, prevMonthEndDate.getDate() - i);
        }).reverse();

        days.push(
            ...Array.from(
                { length: curMonthEndDate.getDate() },
                (_, i) => new Date(currentMonth.getFullYear(), currentMonth.getMonth(), i + 1)
            )
        );

        const remainingDays = 7 - (days.length % 7);
        if (remainingDays < 7) {
            days.push(
                ...Array.from(
                    { length: remainingDays },
                    (_, i) => new Date(nextMonthStartDate.getFullYear(), nextMonthStartDate.getMonth(), i + 1)
                )
            );
        }
        return days;
    };

    const buildCalendarTag = (calendarDays: Date[]) => {
        return calendarDays.map((day: Date, i: number) => {
            if (day.getMonth() < currentMonth.getMonth()) {
                return (
                    <td style={{ color: colors.WHITE_700 }} key={i} className="prevMonthDay">
                        {isPrevMonth ? day.getDate() : ''}
                    </td>
                );
            }
            if (day.getMonth() > currentMonth.getMonth()) {
                return (
                    <td style={{ color: colors.WHITE_700 }} key={i} className="nextMonthDay">
                        {isNextMonth ? day.getDate() : ''}
                    </td>
                );
            }
            if (day < today) {
                return (
                    <HoverAbleTodayBeforeCell
                        key={i}
                        isbetween={isBetweenDay(day) ? 'true' : 'false'}
                        isstart={isStartDay(day) ? 'true' : 'false'}
                        isstartclicked={isSameDay(day, startDate) && endDate === null ? 'true' : 'false'}
                        isend={isEndDay(day) ? 'true' : 'false'}
                        issamestartend={isSameDay(startDate, endDate) ? 'true' : 'false'}
                        onClick={() => onClickDay(day)}
                    >
                        {isSameDay(day, endDate) && (
                            <div
                                style={{
                                    position: 'absolute',
                                    width: 36,
                                    height: 36,
                                    borderRadius: 25,
                                    backgroundColor: colors.primary,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: colors.WHITE_50,
                                    marginTop: -7,
                                }}
                            >
                                <span>{day.getDate()}</span>
                            </div>
                        )}
                        {day.getDate()}
                    </HoverAbleTodayBeforeCell>
                );
            }

            return (
                <HoverAbleTodayAfterCell
                    key={i}
                    isbetween={isBetweenDay(day) ? 'true' : 'false'}
                    isstart={isStartDay(day) ? 'true' : 'false'}
                    isstartclicked={isSameDay(day, startDate) && endDate === null ? 'true' : 'false'}
                    isend={isEndDay(day) ? 'true' : 'false'}
                    issamestartend={isSameDay(startDate, endDate) ? 'true' : 'false'}
                    onClick={() => onClickDay(day)}
                >
                    {isSameDay(day, endDate) && (
                        <div
                            style={{
                                position: 'absolute',
                                width: 35,
                                height: 35,
                                borderRadius: 25,
                                backgroundColor: colors.primary,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: colors.WHITE_50,
                                marginTop: -7,
                            }}
                        >
                            <span>{day.getDate()}</span>
                        </div>
                    )}
                    {day.getDate()}
                </HoverAbleTodayAfterCell>
            );
        });
    };

    const divideWeek = (calendarTags: JSX.Element[]) => {
        return calendarTags.reduce((acc: JSX.Element[][], day: JSX.Element, i: number) => {
            if (i % 7 === 0) acc.push([day]);
            else acc[acc.length - 1].push(day);
            return acc;
        }, []);
    };

    useEffect(() => {
        if (startDate) {
            setCurrentMonth(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
        }
    }, [startDate]);

    const calendarDays = buildCalendarDays();
    const calendarTags = buildCalendarTag(calendarDays);
    const calendarRows = divideWeek(calendarTags);

    return (
        <div
            className="exclude"
            style={{
                width: 420,
                height: 370,
                borderRadius: 24,
                backgroundColor: colors.WHITE_50,
                boxShadow: '1px 1px 8px 0px #2B529D2E',
                position: 'absolute',
                top: 218,
                left: 280,
                zIndex: 2,
            }}
        >
            <Row style={{ alignItems: 'center', marginTop: 28, marginLeft: 158 }}>
                <button onClick={prevCalendar} style={{ width: 24, height: 24 }}>
                    <img style={{ width: 24, height: 24 }} src={I_LEFT_PRIMARY_BTN} />
                </button>
                <Body2Bold
                    style={{ width: 40, textAlign: 'center', color: colors.BLUE_700, marginLeft: 8, marginRight: 8 }}
                >
                    {currentMonth.getMonth() + 1}월
                </Body2Bold>

                <button onClick={nextCalendar} style={{ width: 24, height: 24 }}>
                    <img style={{ width: 24, height: 24 }} src={I_RIGHT_PRIMARY_BTN} />
                </button>
            </Row>

            <table style={{ width: 340, marginTop: 22, marginLeft: 34 }}>
                <thead>
                    <tr>
                        {daysOfWeek.map((day, i) => (
                            <th
                                style={{
                                    borderTopWidth: 1,
                                    borderBottomWidth: 1,
                                    borderColor: colors.WHITE_600,
                                    paddingTop: 4,
                                    paddingBottom: 4,
                                }}
                                key={i}
                            >
                                <Body2Bold style={{ color: colors.BLUE_700 }}>{day}</Body2Bold>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {calendarRows.map((row: JSX.Element[], i: number) => (
                        <tr
                            style={{
                                fontSize: 14,
                                fontWeight: 400,
                                height: 42,
                                color: colors.BLUE_900,
                                textAlign: 'center',
                            }}
                            key={i}
                        >
                            {row}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default RangeCalendar;
