import React from 'react';
import { Col, Row } from '../../../lib/utils';
import { I_GREY_CIRCLE } from '../../../types/images';
import { Body3Light, Head4 } from '../../../lib/font';
import { colors } from '../../../lib/colors';

const MemberMealListEmptyGraph = () => {
    const lengthArr = Array.from({ length: 9 }, (_, index) => 8 - index);
    return (
        <Col style={{ marginTop: 50 }}>
            <Col style={{ position: 'absolute', marginLeft: 638.5, marginTop: 115 }}>
                {/* <img src={I_GREY_CIRCLE} style={{ width: 88, height: 88 }} /> */}
                {/* <Head4 style={{ color: colors.WHITE_700, marginLeft: 5, marginTop: 8 }}>EMPTY</Head4> */}
            </Col>
            {lengthArr.map((item, index) => {
                return (
                    <Row
                        key={index}
                        style={{
                            marginTop: index === 0 ? 15 : 0,
                            marginBottom: 13,
                            marginLeft: 62,
                            alignItems: 'center',
                        }}
                    >
                        <Body3Light style={{ color: colors.BLUE_700 }}>{item}</Body3Light>
                        <hr style={{ width: 1180, height: 1.5, marginLeft: 19, backgroundColor: colors.WHITE_600 }} />
                    </Row>
                );
            })}
            <Head4 style={{ marginTop: 61, color: colors.WHITE_900, textAlign: 'center' }}>
                센터의 트레이너를 등록해주세요
            </Head4>
        </Col>
    );
};

export default MemberMealListEmptyGraph;
