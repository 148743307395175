import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Row } from '../../lib/utils';
import { colors } from '../../lib/colors';
import ReactPlayer from 'react-player';
import { HoverImage } from '../../lib/styled';
import { I_LEFT_BRACKET, I_POPUP_EXIT, I_RIGHT_BRACKET } from '../../types/images';
import Scrollbars from 'react-custom-scrollbars-2';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
`;

const ModalInner = styled.div`
    background: white;
    width: 1000px;
    height: 480px;
    border-radius: 24px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    background: rgba(245, 249, 255, 1);
`;

type Props = {
    setModalVisible: Dispatch<SetStateAction<boolean>>;
    videoList: any[];
    selectVideoId: string;
};

const ModalVideoPlayer = ({ setModalVisible, videoList, selectVideoId }: Props) => {
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    useEffect(() => {
        let findIndex = videoList.findIndex((item: any) => item._id === selectVideoId);
        findIndex && setCurrentIndex(findIndex);
    }, []);

    const onClickChangePicture = (index: number) => {
        setCurrentIndex(index);
    };

    const onClickNextImage = () => {
        if (currentIndex > videoList.length - 2) {
            return;
        } else {
            setCurrentIndex((prev) => prev + 1);
        }
    };

    const onClickPrevImage = () => {
        if (currentIndex === 0) {
            return;
        } else {
            setCurrentIndex((prev) => prev - 1);
        }
    };

    return (
        <ModalOverLay>
            <ModalInner>
                <Row
                    style={{
                        width: 834,
                        height: 416,
                        marginTop: 32,
                        marginLeft: 32,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Row
                        style={{
                            position: 'absolute',
                            width: 834,
                            height: 416,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Row
                            onClick={onClickPrevImage}
                            style={{
                                width: 68,
                                height: 160,
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 99,
                            }}
                        >
                            <HoverImage
                                src={I_LEFT_BRACKET}
                                style={{
                                    width: 18,
                                    height: 58,
                                }}
                            />
                        </Row>
                        <Row
                            onClick={onClickNextImage}
                            style={{
                                width: 68,
                                height: 160,
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 99,
                            }}
                        >
                            <HoverImage
                                src={I_RIGHT_BRACKET}
                                style={{
                                    width: 18,
                                    height: 58,
                                }}
                            />
                        </Row>
                    </Row>
                    {/* https://d1oioiexdn2j0u.cloudfront.net/post/video/coach/630a2da031838b0019af609f/2024-05/21-171132505-0.mp4 */}
                    {videoList[currentIndex]?.content && (
                        <ReactPlayer
                            url={videoList[currentIndex].content}
                            muted
                            controls
                            width={'100%'}
                            height={'100%'}
                        />
                    )}
                </Row>

                <Col style={{ width: 92, height: 416, marginTop: 32, marginLeft: 20 }}>
                    <HoverImage
                        src={I_POPUP_EXIT}
                        style={{ width: 52, height: 52, marginLeft: 12 }}
                        onClick={() => setModalVisible(false)}
                    />
                    <Scrollbars
                        autoHide
                        autoHideTimeout={800}
                        autoHideDuration={200}
                        style={{
                            width: 92,
                            height: 336,
                            marginTop: 24,
                        }}
                    >
                        <Col style={{ width: 76, paddingLeft: 4, backgroundColor: colors.WHITE_600 }}>
                            {videoList &&
                                videoList.map((item: any, index: number) => {
                                    return (
                                        <Row
                                            key={'videoDetail' + index}
                                            style={{
                                                width: 68,
                                                height: 68,
                                                marginTop: index === 0 ? 4 : 0,
                                                marginBottom: 8,

                                                borderRadius: 2,
                                                overflow: 'hidden',
                                                border:
                                                    currentIndex === index ? '3px solid rgba(61, 115, 221, 1)' : '0',
                                            }}
                                            onClick={() => onClickChangePicture(index)}
                                        >
                                            <img src={item?.thumbnail} style={{ width: 68, height: 68 }} />
                                        </Row>
                                    );
                                })}
                        </Col>
                    </Scrollbars>
                </Col>
            </ModalInner>
        </ModalOverLay>
    );
};

export default ModalVideoPlayer;
