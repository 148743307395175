import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { getItemWithExpiration } from '../lib/utils';
import { deleteMembershipTemplate, patchRelocateMembership, PatchRelocateMembershipApiType } from '../api/memberShip';

type Props = {
    onPatchRelocateMembershipSuccess?: () => void;
    onDeleteMembershipTemplateSuccess?: () => void;
};

const useMembershipTemplateMutationService = ({
    onPatchRelocateMembershipSuccess,
    onDeleteMembershipTemplateSuccess,
}: Props) => {
    const [authObject] = useState(getItemWithExpiration('authObject'));
    const [authorization] = useState(getItemWithExpiration('authorization'));

    // 회원권 이전하기
    const patchRelocateMembershipMutation = useMutation(
        ['patchRelocateMembershipMutation'],
        async (params: Omit<PatchRelocateMembershipApiType, 'accessToken'>) =>
            patchRelocateMembership({ ...params, accessToken: authorization }),
        {
            onError: (error) => {
                console.log(error);
            },
            onSuccess: async ({ status }) => {
                if (status === 200) {
                    onPatchRelocateMembershipSuccess && onPatchRelocateMembershipSuccess();
                }
            },
        }
    );

    // 회원권 템플릿 삭제
    const deleteMembershipTemplateMutation = useMutation(
        ['deleteMembershipTemplateMutation'],
        async (params: { membershipTemplateId: string }) =>
            deleteMembershipTemplate({ ...params, accessToken: authorization }),
        {
            onError: (error) => {
                console.log(error);
            },
            onSuccess: async ({ status }) => {
                if (status === 204) {
                    onDeleteMembershipTemplateSuccess && onDeleteMembershipTemplateSuccess();
                }
            },
        }
    );

    return {
        patchRelocateMembershipMutation,
        deleteMembershipTemplateMutation,
    };
};

export default useMembershipTemplateMutationService;
