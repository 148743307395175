import React from 'react';
import { Body1Regular, Body3Regular } from '../../lib/font';
import { colors } from '../../lib/colors';
import { Col, Row } from '../../lib/utils';
import NormalButton from '../Button/NormalButton';
import WhiteSquareButton from '../Button/WhiteSquareButton';
import ColorSquareButton from '../Button/ColorSquareButton';

const ModalAddTrainerBody2 = ({ onClickNextStep, onClickPrevStep, digits, handleChange, inputRefs }: any) => {
    return (
        <>
            <Col style={{ marginLeft: 48, marginTop: 32 }}>
                <Body3Regular style={{ color: colors.LAVEL_3 }}>[트레이너 코드]를 입력해주세요!</Body3Regular>
                <Body3Regular style={{ color: colors.LAVEL_3 }}>
                    [트레이너 코드]는 '라포 모바일 - 트레이너 계정'의 'MY 코드번호'를 통해 공유받을 수 있습니다!
                </Body3Regular>
            </Col>
            <Row>
                <Row style={{ width: 400, height: 64, marginTop: 40, marginLeft: 48 }}>
                    {digits.map((digit: any, index: any) => (
                        <input
                            key={index}
                            ref={inputRefs[index]}
                            type="text"
                            value={digit}
                            onChange={(e) => handleChange(index, e.target.value)}
                            maxLength={1}
                            autoFocus={index === 0}
                            style={{
                                width: 64,
                                height: 64,

                                borderRadius: 4,
                                border: '1px solid #3D73DD',
                                marginRight: 24,
                                fontSize: 32,
                                fontWeight: 700,
                                //lineHeight: 64,
                                textAlign: 'center',
                            }}
                        />
                    ))}
                </Row>
            </Row>

            <Row
                style={{
                    width: 312,
                    height: 48,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: 52,
                    marginLeft: 536,
                }}
            >
                <WhiteSquareButton text="이전" size="middle" callBack={onClickPrevStep} />
                <ColorSquareButton text="다음" size="middle" callBack={onClickNextStep} />
            </Row>
        </>
    );
};

export default ModalAddTrainerBody2;
