// apiService.js

import { Issuer } from '../api/center';
import { getDetailMembershipAPI } from '../api/memberShip';
import { MemberMembershipDataType } from './useMembershipQueryService';

// 수강권 템플릿 하나 조회
export const fetchGetOneMembershipTemplate = (
    params: {
        membershipTemplateId: string;
    },
    authorization: string
) => ({
    queryKey: ['getDetailMembershipAPIQuery', params.membershipTemplateId],
    queryFn: () => getDetailMembershipAPI(params.membershipTemplateId, authorization),
    onSuccess: (data: any) => {
        // 데이터 정제 로직
        return data?.data?.membershipTemplate ?? {};
    },
});

export type MembershipTemplateDataType = {
    _id: string;
    period: number;
    isAvailable: boolean;
    BGColor: string[];
    isDeleted: boolean;
    isCanceled: boolean;
    name: string;
    pricePerSchedule: number;
    totalPrice: number;
    totalSession: number;
    issuer: Issuer;
    memo: string;
    validDays: number;
    createdAt: string;
    updatedAt: string;
    __v: number;
    recentUsedTime: string;
    memberships?: MemberMembershipDataType[];
};
