import { I_1440_LOGO } from '../types/images';
import { useNavigate } from 'react-router-dom';
import { Col, Row, setItemWithExpiration } from '../lib/utils';
import SignInIdPassWord from '../components/SignIn/SignInIdPassWord';
import { useState } from 'react';
import SignInBottom from '../components/SignIn/SignInBottom';
import { useMutation } from '@tanstack/react-query';
import { requestSignInAPI } from '../api/auth';

const SignInPage = ({ createAlert }: any) => {
    const navigate = useNavigate();
    const [userId, setUserId] = useState<string>('');
    const [userPassword, setUserPassword] = useState<string>('');

    const requestSignIn = useMutation({
        mutationFn: async () => await requestSignInAPI(userId, userPassword),
        onSuccess: (res) => {
            if (res.status === 200) {
                const accessToken: string = res.data?.tokens?.access?.token;
                const accessTokenExpires: string = res.data?.tokens?.access?.expires;
                const refreshToken: string = res.data?.tokens?.refresh?.token;
                const refreshTokenExpires: string = res.data?.tokens?.refresh?.expires;

                const user: any = res.data?.user;
                const center: any = res.data?.center;
                const subscriptionData: any = res.data?.subscriptionData;

                const authObject = {
                    adminId: user?.adminId,
                    id: user?.id,
                    name: user?.name,
                    email: user?.email,
                    gender: user?.gender,
                    phoneNumber: user?.phoneNumber,
                    profileImageUrl: user?.profileImageUrl,
                    /** 센터 */
                    centerAddress: center?.address,
                    centerCategory: center?.category,
                    centerCode: center?.code,
                    centerName: center?.name,
                    centerId: center?.id,
                    isNewCommunicationBox: res.data?.isNewCommunicationBox,

                    /** 구독플랜관련 */
                    coachCount: subscriptionData?.coachCount, // 소속 트레이너수
                    memberCount: subscriptionData?.memberCount, // 활성 수강생수
                    activationDate: subscriptionData?.activationDate, // 플랜 활성일
                    expirationDate: subscriptionData?.expirationDate, // 플랜 만료일
                    expirationDelayDate: subscriptionData?.expirationDelayDate, // 플랜 만료후 유예기간
                    finalPaymentDate: subscriptionData?.finalPaymentDate, // 마지막 결제일
                    finalPaymentTryDate: subscriptionData?.finalPaymentTryDate, // 마지막 결제 시도일

                    cardIssuingBank: subscriptionData?.cardIssuingBank, // 결제카드 은행
                    cardNumber: subscriptionData?.cardNumber, // 결제카드 번호
                    planAmount: subscriptionData?.planAmount, // 결제 금액
                    usableStatus: subscriptionData?.usableStatus, // 구독상태
                    paymentDueDate: null,

                    /** 구독플랜 인원 정보 */
                    planLimitCount: subscriptionData?.planLimitCount, // 플랜 제한 이용 인원
                    prevPlanLimitCount: subscriptionData?.prevPlanLimitCount, // 구독 인원 변경시 변경전 플랜카운트
                    changedPlanLimitCount: subscriptionData?.changedPlanLimitCount, // 구독 인원 변경시 변경 플랜카운트

                    confirmUsableStatus: false, // 구독상태의 따른 팝업 클릭시 12시간이내에 다시안뜨게 하는 변수
                    testAccount: false, // 데모버전 계정인지아닌지
                };

                setItemWithExpiration('authorization', accessToken, 12);
                setItemWithExpiration('authObject', authObject, 12);
                navigate('/dash');
            }
            if (res.status === 400) {
                createAlert('', '아이디 비밀번호를 입력해주세요', true);
            }
            if (res.status === 404) {
                createAlert('존재하지 않은 아이디 입니다!', '입력하신 아이디를 다시 확인해주세요!', true);
            }
            if (res.status === 418) {
                createAlert(
                    '비밀번호가 일치하지 않습니다!',
                    '입력하신 비밀번호를 다시 확인해주세요!\n비밀번호를 잊으셨다면, 1:1 카톡채널 상담에 문의해주세요!',
                    true
                );
            }
        },
    });

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            if (userPassword.length !== 0) {
                requestSignIn.mutate();
            }
        }
    };

    return (
        <Col
            style={{
                width: '100%',
                height: '100%',
                alignSelf: 'center',
                alignItems: 'center',
            }}
        >
            <img
                src={I_1440_LOGO}
                style={{
                    width: '12rem',
                    height: '6.8rem',
                    marginTop: '18rem',
                }}
            />

            <SignInIdPassWord
                userId={userId}
                setUserId={setUserId}
                userPassword={userPassword}
                setUserPassword={setUserPassword}
                handleKeyPress={handleKeyPress}
            />
            <SignInBottom
                navigate={navigate}
                onClickLogin={() => {
                    requestSignIn.mutate();
                }}
                userId={userId}
                userPassword={userPassword}
            />
        </Col>
    );
};
export default SignInPage;
