import React, { useEffect, useState } from 'react';
import { Col, Row } from '../../lib/utils';
import Header from '../../components/Header';
import { Bottom } from '../global';
import { useLocation, useNavigate } from 'react-router-dom';
import { SortType } from './FeedPage';
import FeedMemberHeader from '../../components/FeedMember/FeedMemberHeader';
import RangeCalendar from '../../components/Calendar/RangeCalendar';
import { useQuery } from '@tanstack/react-query';
import { getAllCoachMemberListAPI, getSpecificMemberFeedListAPI } from '../../api/feed';
import dayjs from 'dayjs';
import FeedList from '../../components/Feed/FeedList/FeedList';
import FeedSelectList from '../../components/Feed/FeedSelectList/FeedSelectList';
import ModalFeedDetail from '../../components/Feed/ModalFeedDetail/ModalFeedDetail';
import { colors } from '../../lib/colors';
import ModalVideoPlayer from '../../components/modal/ModalVideoPlayer';

let sortOptions: any = [
    { value: 'all', label: '전체보기' },
    { value: 'SCHEDULEREPORT', label: '수업 일지' },
    { value: 'coach', label: '강사 게시글' },
    { value: 'member', label: '회원 게시글' },
];

const FeedMemberPage = ({ authObject, setAuthObject, authorization }: any) => {
    const location = useLocation();
    const navigate = useNavigate();

    const limitCount = 10;
    const [lastPostId, setLastPostId] = useState<string>('');
    const [memberId, setMemberId] = useState<string>(location?.state?.memberId ? location.state.memberId : '');
    const [coachId, setCoachId] = useState<string>(location?.state?.coachId ? location?.state?.coachId : '');
    const [sortType, setSortType] = useState<SortType>({
        value: 'all',
        label: '전체 보기',
    });
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);

    const [feedList, setFeedList] = useState<any>([]); // 일지 피드 리스트
    const [centerUserList, setCenterUserList] = useState<any>([]); // 센터의 강사,회원 리스트
    const [modalObject, setModalObject] = useState<any>(null); // 상세 모달 스테이트

    const [target, setTarget] = useState<any>(null); // 관찰대상 target
    const [isLoaded, setIsLoaded] = useState(false); // Load 중인가를 판별하는 boolean
    const [stop, setStop] = useState(false); // 마지막 데이터까지 다 불러온 경우 더이상 요청을 보내지 않기 위해서

    const [isCalendar, setIsCalendar] = useState<boolean>(false);
    const [isVisibleSort, setIsVisibleSort] = useState<boolean>(false);
    const [isDetailModal, setIsDetailModal] = useState<boolean>(false);

    const [prevFilterFlag, setPrevFilterFlag] = useState<boolean>(false);
    const [isChangeFilter, setIsChangeFilter] = useState<boolean>(false);

    const [selectVideoId, setSelectVideoId] = useState<string>('');
    const [videoList, setVideoList] = useState<any>([]);
    const [isVideoModal, setIsVideoModal] = useState<boolean>(false); // 비디오 플레이어 모딜

    const toggleDropDown = () => {
        setIsVisibleSort(!isVisibleSort);
    };
    const handleDropDownSelect = (option: any) => {
        setSortType(option);
        setIsVisibleSort(false);
    };
    const onClickCalendar = () => {
        setIsCalendar(!isCalendar);
    };

    const onClickRefresh = () => {
        setLastPostId('');
        setPrevFilterFlag(isChangeFilter);
        setIsChangeFilter(!isChangeFilter);
        setStop(false);
    };

    // 전체선택 버튼 클릭이벤트
    const onClickAllButton = () => {
        navigate('/feed');
    };
    // 전체보기 버튼
    const onClickDetail = (object: any) => {
        setIsDetailModal(true);
        setModalObject(object);
    };
    // 필터초기화
    const onClickClearFilter = () => {
        // setPrevFilterFlag(isChangeFilter);
        // setIsChangeFilter(!isChangeFilter);
        // setSortType({ value: 'all', label: '전체 보기' });
        // setStartDate(null);
        // setEndDate(null);
        // setCoachId('');
        // setLastPostId('');
        navigate('/feed');
    };
    // 강사 선택
    const onClickCoach = (coachId: string) => {
        navigate('/feed', {
            state: {
                coachId,
            },
        });
    };
    // 회원 선택
    const onClickMember = (memberData: any) => {
        setMemberId(memberData.memberId);
    };
    //  피드 리스트 조회 API : (GET)
    const getSpecificMemberFeedList = useQuery(
        ['getSpecificMemberFeedListAPI', isChangeFilter, lastPostId],
        async () =>
            await getSpecificMemberFeedListAPI(
                memberId,
                lastPostId,
                limitCount,
                startDate ? dayjs(startDate).startOf('day').format('YYYY-MM-DD HH:mm') : '',
                endDate ? dayjs(endDate).endOf('day').format('YYYY-MM-DD HH:mm') : '',
                sortType.value === 'SCHEDULEREPORT' ? sortType.value : 'all',
                coachId,
                sortType.value === 'coach' || sortType.value === 'member' ? sortType.value : 'all',
                authorization
            ),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    if (isChangeFilter === prevFilterFlag) {
                        if (feedList !== res.data.posts) {
                            setFeedList((prevData: any) =>
                                prevData ? [...prevData, ...res.data.posts] : res.data.posts
                            );
                        }
                    } else {
                        setFeedList(res.data.posts);
                    }
                    setPrevFilterFlag(isChangeFilter);
                    setIsLoaded(false);
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!!authObject?.testAccount && !!memberId && !!coachId,
            staleTime: 0,
        }
    );
    //  센터 유저 리스트 조회 API : (GET)
    const getAllCoachMemberList = useQuery(
        ['getAllCoachMemberListAPI'],
        async () =>
            await getAllCoachMemberListAPI(
                authObject?.adminId,
                startDate ? dayjs(startDate).startOf('day').toISOString() : '',
                endDate ? dayjs(endDate).endOf('day').toISOString() : '',
                authorization
            ),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    setCenterUserList(res.data.centerUserList);
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!!authObject?.testAccount,
            staleTime: 0,
        }
    );

    useEffect(() => {
        if (endDate || sortType.value || memberId) {
            setLastPostId('');
            setPrevFilterFlag(isChangeFilter);
            setIsChangeFilter(!isChangeFilter);
            setStop(false);
        }
    }, [endDate, sortType.value, memberId]);

    // 상세 모달안 비디오 클릭 이벤트
    const onClickVideoAtDetailModal = (arr: any, videoId: string) => {
        setSelectVideoId(videoId);
        setVideoList(arr);
        setIsVideoModal(true);
    };

    return (
        <Col
            style={{
                marginTop: 64,
                alignItems: 'center',
            }}
        >
            <Col style={{ width: 1192, marginTop: 48 }}>
                <FeedMemberHeader
                    sortOptions={sortOptions}
                    sortType={sortType}
                    isVisibleSort={isVisibleSort}
                    toggleDropDown={toggleDropDown}
                    handleDropDownSelect={handleDropDownSelect}
                    onClickCalendar={onClickCalendar}
                    onClickRefresh={onClickRefresh}
                    startDate={startDate}
                    endDate={endDate}
                    onClickClearFilter={onClickClearFilter}
                />

                <Row style={{ justifyContent: 'space-between', marginTop: 32 }}>
                    {/* 게시물 피드리스트 */}
                    <FeedList onClickDetail={onClickDetail} feedList={feedList} setTarget={setTarget} />
                    {/* 센터 유저리스트 */}
                    <FeedSelectList
                        coachId={coachId}
                        memberId={memberId}
                        centerUserList={centerUserList}
                        onClickCoach={onClickCoach}
                        onClickMember={onClickMember}
                        onClickAllButton={onClickAllButton}
                    />
                </Row>
            </Col>

            {isCalendar && (
                <RangeCalendar
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    setIsCalendar={setIsCalendar}
                    isPrevMonth={true}
                    isNextMonth={true}
                />
            )}

            {/* 피드 상세 모달 */}
            {isDetailModal && (
                <ModalFeedDetail
                    modalObject={modalObject}
                    setModalVisible={setIsDetailModal}
                    onClickVideoAtDetailModal={onClickVideoAtDetailModal}
                />
            )}
            {/* 비디오 플레이어 모달 */}
            {isVideoModal && videoList && selectVideoId && (
                <ModalVideoPlayer
                    setModalVisible={setIsVideoModal}
                    videoList={videoList}
                    selectVideoId={selectVideoId}
                />
            )}
        </Col>
    );
};

export default FeedMemberPage;
