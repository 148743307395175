import { Bottom } from '../global';
import { Col, getItemWithExpiration, setItemWithExpiration } from '../../lib/utils';
import { useEffect, useState } from 'react';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import secureLocalStorage from 'react-secure-storage';
import { useQuery } from '@tanstack/react-query';
import { getSubscriptionPlanAPI } from '../../api/charge';
import { useNavigate } from 'react-router-dom';

import Header from '../../components/Header';
import UpgradeSubscriptionPlan from '../../components/SubscriptionPlan/UpgradeSubscriptionPlan';
import PlanInformation from '../../components/SubscriptionPlan/PlanInformation';
import ModalAlert from '../../components/modal/ModalAlert';

/**
 * 무료체험 기간중 구독플랜 변경하기 버튼 클릭시 오는 페이지
 */
const SubscriptionPlanPage = ({ createAlert, createTwoButtonAlert }: any) => {
    const LocalSuccessUrl: any = process.env.REACT_APP_LOCAL_SUCCESS_URL;
    const LocalFailUrl: any = process.env.REACT_APP_LOCAL_FAIL_URL;
    const ProdSuccessUrl: any = process.env.REACT_APP_PROD_SUCCESS_URL;
    const ProdFailUrl: any = process.env.REACT_APP_PROD_FAIL_URL;

    const navigate = useNavigate();
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));

    const [coachCount, setCoachCount] = useState<number>(0);
    const [buttonType, setButtonType] = useState<string>('small');
    const [recommendPosition, setRecommendPosition] = useState<string>('left');
    const [smallCenterTrainerNumber, setSmallCenterTrainerNumber] = useState<number>(1);
    const [bigCenterTrainerNumber, setBigCenterTrainerNumber] = useState<number>(4);

    const [isConfirmPayModal, setIsConfirmPayModal] = useState<boolean>(false);
    // 코치 숫자 대비 플랜 코치수가 안
    const [isCoachCountAlert, setIsCoachCountAlert] = useState<boolean>(false);

    // 토스 결제창 연동 함수
    const tossPayFunction = async () => {
        setIsConfirmPayModal(false);
        const trainerNumber = buttonType === 'small' ? smallCenterTrainerNumber : bigCenterTrainerNumber;

        setItemWithExpiration('trainerNumber', trainerNumber, 12);

        const clientKey: any =
            process.env.NODE_ENV === 'development'
                ? process.env.REACT_APP_TOSS_TEST_CLIENT_KEY
                : process.env.REACT_APP_TOSS_LIVE_CLIENT_KEY;

        const customerKeyUUID = authObject?.id; // custom number

        await loadTossPayments(clientKey).then((tossPayments) => {
            // ------ 카드 등록창 호출 ------
            tossPayments
                .requestBillingAuth('카드', {
                    customerKey: customerKeyUUID, // 고객 ID로 상점에서 만들어야 합니다. 빌링키와 매핑됩니다. 자세한 파라미터 설명은 결제 정보 파라미터 설명을 참고하세요.
                    successUrl: process.env.NODE_ENV === 'development' ? LocalSuccessUrl : ProdSuccessUrl,
                    failUrl: process.env.NODE_ENV === 'development' ? LocalFailUrl : ProdFailUrl,
                })
                .catch(function (error) {
                    console.log(error.code);
                    secureLocalStorage.removeItem('trainerNumber');
                    if (error.code === 'USER_CANCEL') {
                        // 결제 고객이 결제창을 닫았을 때 에러 처리
                    }
                });
        });
    };

    // 결제 하기 버튼 클릭이벤트
    const onClickPayButton = () => {
        if (buttonType === 'big') {
            if (bigCenterTrainerNumber < 4) {
                createAlert('입력 오류', '4인 이상 대형 센터는 4인이상 결제해야 합니다.', true);
                return;
            }
        }
        tossPayFunction();
        // authObject?.usableStatus === 'trialPaymentPending'
        //     ? tossPayFunction()
        //     : createTwoButtonAlert(
        //           '무료 체험 종료',
        //           '무료체험 기간 중 결제를 진행하시면\n무료체험은 즉시 종료됩니다!',
        //           true,
        //           '취소',
        //           '결제진행',
        //           () => {},
        //           () => {
        //               tossPayFunction();
        //           }
        //       );
    };

    //  구독플랜조회 API : (GET)
    const getSubscriptionPlan = useQuery(
        ['getSubscriptionPlanAPI'],
        async () => await getSubscriptionPlanAPI(authorization, authObject?.adminId),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) {
                        setCoachCount(response.coachCount);
                    }
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!!authObject?.testAccount,
            refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    // 요금 추천을 위한로직
    useEffect(() => {
        if (coachCount < 4) {
            setRecommendPosition('left');
        } else {
            setRecommendPosition('right');
            setButtonType('big');
        }
    }, [coachCount]);

    // 현재 강사수보다 적은 요금제를 선택햇을경우 alert뛰우기 위한
    useEffect(() => {
        if (buttonType === 'small') {
            if (smallCenterTrainerNumber < coachCount) {
                setIsCoachCountAlert(true);
            } else {
                setIsCoachCountAlert(false);
            }
        } else {
            if (bigCenterTrainerNumber < coachCount) {
                setIsCoachCountAlert(true);
            } else {
                setIsCoachCountAlert(false);
            }
        }
    }, [smallCenterTrainerNumber, bigCenterTrainerNumber, buttonType, coachCount]);

    useEffect(() => {
        if (authObject) {
            if (authObject?.usableStatus === 'trial' || authObject?.usableStatus === 'trialPaymentPending') {
                return;
            } else {
                navigate('/myPage', { replace: true });
            }
        }
    }, [authObject]);

    return (
        <>
            <Col style={{ marginTop: 64, width: '100%', alignItems: 'center' }}>
                {/* 구독 플랜 이용 */}
                <UpgradeSubscriptionPlan
                    onClickPayButton={onClickPayButton}
                    createAlert={createAlert}
                    buttonType={buttonType}
                    recommendPosition={recommendPosition}
                    setButtonType={setButtonType}
                    smallCenterTrainerNumber={smallCenterTrainerNumber}
                    setSmallCenterTrainerNumber={setSmallCenterTrainerNumber}
                    bigCenterTrainerNumber={bigCenterTrainerNumber}
                    setBigCenterTrainerNumber={setBigCenterTrainerNumber}
                    authObject={authObject}
                    coachCount={coachCount}
                    isCoachCountAlert={isCoachCountAlert}
                    navigate={navigate}
                />
                {/* 라포 플랜 인포 */}
                <PlanInformation />
            </Col>

            {isConfirmPayModal && (
                <ModalAlert
                    modalVisible={isConfirmPayModal}
                    setModalVisible={setIsConfirmPayModal}
                    title={''}
                    boldContent={'무료체험 종료'}
                    content={'무료체험 기간중 결제를 진행하면 무료체험은 즉시 종료 됩니다'}
                    callBack={tossPayFunction}
                    confirmButtonText={'결제 진행'}
                />
            )}
        </>
    );
};

export default SubscriptionPlanPage;
