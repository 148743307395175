import React from 'react';
import SvgIcon from '../SvgIcon';
import { Body3Regular } from '../../lib/font';
import { colors } from '../../lib/colors';

type Props = {
    onBackButtonClick?: () => void;
};

const NewBackButton = ({ onBackButtonClick }: Props) => {
    return (
        <div
            className="flex flex-row h-[36px] border-[1px] border-primary border-solid rounded-[48px] px-[16px] justify-center items-center bg-white"
            onClick={() => (onBackButtonClick ? onBackButtonClick() : window.history.back())}
        >
            <SvgIcon name="SvgLeftStrokeArrow" stroke={colors.primary} size={14} fill="none" className="mr-[16px]" />
            <Body3Regular style={{ color: colors.primary }}>뒤로가기</Body3Regular>
        </div>
    );
};

export default NewBackButton;
