import React, { Dispatch, SetStateAction, useState } from 'react';
import { Col, Row } from '../../../lib/utils';
import { Body3Light, CaptionRegular, Head4 } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import { SortType } from '../ClassPassPage';
import RoundedDropDown from '../../../components/DropDown/RoundedDropDown';
import ResetButton from '../../../components/Button/ResetButton';
import MembershipTemplate from './MembershipTemplate';
import { I_ALERT, I_CIRCLE_QUESTION, I_EMPTY_V2 } from '../../../types/images';
import { CustomCheckbox } from '../../../components/Grid/Grid';

let firstOptions: any = [
    { value: 'ENTIRE', label: '전체 보기' },
    { value: 'ACTIVE', label: '활성중' },
    { value: 'INACTIVE', label: '비활성중' },
];

let secondOptions: any = [
    { value: 'RECENT', label: '최근 생성 순' },
    { value: 'ACTIVE', label: '최근 사용 순' },
    { value: 'OLD', label: '오래된 생성 순' },
    { value: 'ALPHABET', label: '이름 가나다 순' },
];

type Props = {
    firstSortType: SortType;
    setFirstSortType: Dispatch<SetStateAction<SortType>>;
    secondSortType: SortType;
    setSecondSortType: Dispatch<SetStateAction<SortType>>;
    filterMembershipTemplates: any;
    onClickDetailPage: (item: any) => void;
    isHideCoach: boolean;
    setIsHideCoach: any;
    onClickHideCoach?: () => void;
};

const ClassPassList = ({
    firstSortType,
    setFirstSortType,
    secondSortType,
    setSecondSortType,
    filterMembershipTemplates,
    onClickDetailPage,
    isHideCoach,
    setIsHideCoach,
    onClickHideCoach,
}: Props) => {
    const [isOpenFirst, setIsOpenFirst] = useState<boolean>(false);
    const [isOpenSecond, setIsOpenSecond] = useState<boolean>(false);

    // 필터 초기화 버튼 클릭이벤트
    const onClickFilterReset = () => {
        setFirstSortType({ value: 'ENTIRE', label: '전체 보기' });
        localStorage.setItem('firstSortType', JSON.stringify({ value: 'ENTIRE', label: '전체 보기' }));

        setSecondSortType({ value: 'RECENT', label: '최근 생성 순' });
        localStorage.setItem('secondSortType', JSON.stringify({ value: 'RECENT', label: '최근 생성 순' }));

        setIsHideCoach(false);
        localStorage.setItem('firstSortType', JSON.stringify(false));
    };

    const toggleFirst = () => {
        setIsOpenFirst(!isOpenFirst);
    };
    const handleFirstSelect = (option: any) => {
        setFirstSortType(option);
        setIsOpenFirst(false);
        localStorage.setItem('firstSortType', JSON.stringify(option));
    };

    const toggleSecond = () => {
        setIsOpenSecond(!isOpenSecond);
    };
    const handleSecondSelect = (option: any) => {
        setSecondSortType(option);
        setIsOpenSecond(false);
        localStorage.setItem('secondSortType', JSON.stringify(option));
    };

    return (
        <>
            <Row
                style={{
                    width: 1192,
                    justifyContent: 'space-between',
                    marginTop: 48,
                    alignItems: 'center',
                }}
            >
                <Row>
                    <Row
                        style={{ cursor: 'pointer', height: 24, alignItems: 'center' }}
                        onClick={() => {
                            window.open(
                                'https://mustmove.notion.site/e1b5abd61b91412cbc76a45a21c3f070?pvs=74',
                                '_blank'
                            ); // 새 탭에서 링크 열기
                        }}
                    >
                        <Head4 style={{ color: colors.LAVEL_4 }}>수강권 관리</Head4>{' '}
                        <img
                            src={I_CIRCLE_QUESTION}
                            style={{ width: 16, height: 16, marginLeft: 16, marginRight: 3 }}
                        />
                        <CaptionRegular style={{ color: colors.POINT_1, cursor: 'pointer' }}>
                            수강권 기능 알아보기
                        </CaptionRegular>
                    </Row>
                </Row>

                <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                    <Row className=" cursor-pointer mr-[16px]" onClick={() => onClickHideCoach && onClickHideCoach()}>
                        <CustomCheckbox
                            checked={isHideCoach}
                            className="mr-[8px]"
                            onChange={() => onClickHideCoach && onClickHideCoach()}
                        />
                        <CaptionRegular>강사 생성 숨기기</CaptionRegular>
                    </Row>
                    <RoundedDropDown
                        toggleSortDropDown={toggleFirst}
                        sortType={firstSortType}
                        isOpenSort={isOpenFirst}
                        sortOptions={firstOptions}
                        handleSortSelect={handleFirstSelect}
                        size="small"
                        className="mr-[16px]"
                    />

                    <RoundedDropDown
                        toggleSortDropDown={toggleSecond}
                        sortType={secondSortType}
                        isOpenSort={isOpenSecond}
                        sortOptions={secondOptions}
                        handleSortSelect={handleSecondSelect}
                        size="small"
                        className="mr-[16px]"
                    />

                    <ResetButton text="초기화" callBack={onClickFilterReset} />
                </Row>
            </Row>

            <div
                style={{
                    width: 1192,
                    paddingLeft: 24,
                    marginTop: 40,
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                }}
            >
                {/* 수강권 */}
                {filterMembershipTemplates === null || filterMembershipTemplates.length === 0 ? (
                    <Row
                        style={{
                            width: 1192,
                            paddingLeft: 24,
                            justifyContent: 'center',
                            marginTop: 152,
                        }}
                    >
                        <Col
                            style={{
                                width: 206,
                                height: 80,
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <img src={I_EMPTY_V2} style={{ width: 24, height: 24 }} />
                            <Body3Light style={{ color: colors.LAVEL_2 }}>
                                {'사용할 수 있는 수강권이 없어요\n새로운 수강권을 만들어주세요!'}
                            </Body3Light>
                        </Col>
                    </Row>
                ) : (
                    filterMembershipTemplates.map((item: any, index: number) => {
                        return (
                            <MembershipTemplate
                                key={index + 'membershipTemplate'}
                                index={index}
                                item={item}
                                onClickDetailPage={onClickDetailPage}
                            />
                        );
                    })
                )}
            </div>
        </>
    );
};

export default ClassPassList;
