import { useEffect, useState } from 'react';
import { Col, Row, getCurrentDayWeekNumber } from '../../../lib/utils';
import { Body1Bold, Body2Bold, Body2Regular, Body3Regular } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import { I_LEFT_ANGLE, I_RIGHT_ANGLE } from '../../../types/images';
import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import { getActiveMembersAPI } from '../../../api/dashboard';
import ActiveMembersDailyGraph from './ActiveMembersDailyGraph';
import ActiveMembersWeeklyGraph from './ActiveMembersWeeklyGraph';
import ActiveMembersMonthlyGraph from './ActiveMembersMonthlyGraph';
import {
    dailyDummyData,
    dailyMaxCountDummy,
    monthlyDummyData,
    monthlyMaxCountDummy,
    weeklyDummyData,
    weeklyMaxCountDummy,
} from './DummyData';
import ThreeButton from '../../Button/ThreeButton';
import ResetButton from '../../Button/ResetButton';

const DashBoardActiveMembers = ({ authorization, authObject }: any) => {
    const [currentDate, setCurrentDate] = useState(dayjs());
    const [dailyData, setDailyData] = useState<any>(null);
    const [weeklyData, setWeeklyData] = useState<any>(null);
    const [monthlyData, setMonthlyData] = useState<any>(null);

    const [todayMaxCount, setTodayMaxCount] = useState<number>(0);
    const [weekMaxCount, setWeekMaxCount] = useState<number>(0);
    const [monthMaxCount, setMonthMaxCount] = useState<number>(0);

    // 몇주차 표시
    const [weekNumber, setWeekNumber] = useState<number>(0);

    const [isToday, setIsToday] = useState<boolean>(true);
    const [isWeek, setIsWeek] = useState<boolean>(false);
    const [isMonth, setIsMonth] = useState<boolean>(false);

    // 날짜 세팅
    const settingDate = (type: string) => {
        setCurrentDate(dayjs());
        if (type === 'today') {
            setIsToday(true);
            setIsWeek(false);
            setIsMonth(false);
        }

        if (type === 'thisWeek') {
            setIsToday(false);
            setIsWeek(true);
            setIsMonth(false);
        }

        if (type === 'thisMonth') {
            setIsToday(false);
            setIsWeek(false);
            setIsMonth(true);
        }
    };

    const onClickAddDate = () => {
        if (isToday) {
            const newDate = dayjs(currentDate).add(1, 'week');
            setCurrentDate(newDate);
        }

        if (isWeek) {
            const newDate = dayjs(currentDate).add(1, 'month');
            setCurrentDate(newDate);
        }

        if (isMonth) {
            const newDate = dayjs(currentDate).add(1, 'year');
            setCurrentDate(newDate);
        }
    };

    const onClickSubtractDate = () => {
        if (isToday) {
            const newDate = dayjs(currentDate).subtract(1, 'week');
            setCurrentDate(newDate);
        }

        if (isWeek) {
            const newDate = dayjs(currentDate).subtract(1, 'month');
            setCurrentDate(newDate);
        }

        if (isMonth) {
            const newDate = dayjs(currentDate).subtract(1, 'year');
            setCurrentDate(newDate);
        }
    };

    useEffect(() => {
        if (currentDate) {
            setWeekNumber(getCurrentDayWeekNumber(currentDate));
        }
    }, [currentDate]);

    //  수업진행 상황 그래프 조회 API : (GET)
    const getActiveMembers = useQuery(
        ['getActiveMembersAPI', currentDate],
        async () =>
            await getActiveMembersAPI(authObject?.centerId, dayjs(currentDate).format('YYYY-MM-DD'), authorization),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const daily = res.data?.daily;
                    const weekly = res.data?.weekly;
                    const monthly = res.data?.monthly;

                    const dailyMaxCount = res.data.dailyMaxCount;
                    const weeklyMaxCount = res.data.weeklyMaxCount;
                    const monthlyMaxCount = res.data.monthlyMaxCount;

                    setDailyData(daily);
                    setWeeklyData(weekly);
                    setMonthlyData(monthly);

                    setTodayMaxCount(dailyMaxCount);
                    setWeekMaxCount(weeklyMaxCount);
                    setMonthMaxCount(monthlyMaxCount);
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!!authObject?.testAccount,
            refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    // 당일 더미데이터 세팅
    useEffect(() => {
        if (authObject?.testAccount && dailyDummyData && dailyMaxCountDummy) {
            setDailyData(dailyDummyData);
            setTodayMaxCount(dailyMaxCountDummy);
        }
    }, [dailyDummyData, dailyMaxCountDummy]);

    // 주간 더미데이터 세팅
    useEffect(() => {
        if (authObject?.testAccount && weeklyDummyData && weeklyMaxCountDummy) {
            setWeeklyData(weeklyDummyData);
            setWeekMaxCount(weeklyMaxCountDummy);
        }
    }, [weeklyDummyData, weeklyMaxCountDummy]);

    // 월간 더미데이터 세팅
    useEffect(() => {
        if (authObject?.testAccount && monthlyDummyData && monthlyMaxCountDummy) {
            setMonthlyData(monthlyDummyData);
            setMonthMaxCount(monthlyMaxCountDummy);
        }
    }, [monthlyDummyData, monthlyMaxCountDummy]);

    const CustomToolTip = ({ id, value, color, data }: any) => (
        <Col
            style={{
                width: 162,
                height: 96,
                background: 'rgba(20, 20, 23, 0.46)',
                borderRadius: '16px 16px 16px 16px',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Row style={{ width: 96, alignItems: 'center' }}>
                <Row style={{ width: 16, height: 16, borderRadius: 2, backgroundColor: colors.WHITE_600 }} />
                <Body2Regular style={{ marginLeft: 8, color: colors.WHITE_100 }}>
                    {'전체 :'}
                    <Body2Bold>{data.totalMemberCount}</Body2Bold>
                </Body2Regular>
            </Row>
            <Row style={{ width: 96, alignItems: 'center' }}>
                <Row style={{ width: 16, height: 16, borderRadius: 2, backgroundColor: colors.BLUE_500 }} />
                <Body2Regular style={{ marginLeft: 8, color: colors.WHITE_100 }}>
                    {'활성 :'}
                    <Body2Bold>{data.activeMemberCount}</Body2Bold>
                </Body2Regular>
            </Row>
        </Col>
    );

    const CustomTextLayer = ({ bars }: any) => {
        return (
            <g>
                {bars.map((bar: any, index: number) => (
                    <text
                        key={index}
                        x={bar.x + bar.width / 2}
                        y={bar.y + bar.height / 2}
                        textAnchor="middle"
                        alignmentBaseline="middle"
                        style={{
                            fill: bar.data.id === 'activeMemberCount' ? 'white' : 'black', // 조건에 따라 텍스트 색상 지정
                            fontSize: 16,
                            fontWeight: 700,
                            //lineHeight: 24,
                        }}
                    >
                        {bar.key.includes('activeMemberCount') && bar.data.value > 0 && bar.data.value}
                        {bar.key.includes('interval') && bar.data.value > 0 && bar.data.data.totalMemberCount}
                    </text>
                ))}
            </g>
        );
    };

    return (
        <Col
            style={{
                width: 1192,
                height: 612,
                marginTop: 24,

                borderRadius: 16,
                backgroundColor: colors.WHITE_50,
                border: '1px solid #C3D4F4',
            }}
        >
            <Body1Bold style={{ color: colors.LAVEL_4, marginTop: 48, marginLeft: 70 }}>수강권 활성 회원수</Body1Bold>

            <Row
                style={{
                    marginTop: 24,
                    alignItems: 'center',
                    marginLeft: 70,
                    justifyContent: 'space-between',
                    paddingRight: 70,
                }}
            >
                <Row style={{ alignItems: 'center' }}>
                    <Row
                        style={{
                            width: 232,
                            height: 32,
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginRight: 24,
                        }}
                    >
                        <button style={{ width: 24, height: 24 }} onClick={() => onClickSubtractDate()}>
                            <img src={I_LEFT_ANGLE} style={{ width: 24, height: 24 }} />
                        </button>
                        <Body3Regular style={{ width: 160, textAlign: 'center', color: colors.LAVEL_2 }}>
                            {isToday && currentDate.format('M월 ') + weekNumber + '주차'}
                            {isWeek && currentDate.format('YYYY년 MM월')}
                            {isMonth && currentDate.format('YYYY년')}
                        </Body3Regular>
                        <button style={{ width: 32, height: 32 }} onClick={() => onClickAddDate()}>
                            <img src={I_RIGHT_ANGLE} style={{ width: 24, height: 24 }} />
                        </button>
                    </Row>

                    <ThreeButton
                        isFirst={isToday}
                        isSecond={isWeek}
                        isThird={isMonth}
                        firstText={'일간'}
                        secondText={'주간'}
                        thirdText={'월간'}
                        firstCallBack={() => settingDate('today')}
                        secondCallBack={() => settingDate('thisWeek')}
                        thirdCallBack={() => settingDate('thisMonth')}
                    />
                    {/* 초기화 버튼 */}
                    <ResetButton style={{ marginLeft: 24 }} callBack={() => setCurrentDate(dayjs())} />
                </Row>

                <Row style={{ width: 234, justifyContent: 'space-between' }}>
                    <Row
                        style={{
                            width: 105,
                            height: 24,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            style={{
                                width: 16,
                                height: 16,
                                borderRadius: 2,
                                backgroundColor: colors.WHITE_600,
                            }}
                        />
                        <Body3Regular style={{ color: colors.LAVEL_3 }}>{`전체 회원 수`}</Body3Regular>
                    </Row>

                    <Row
                        style={{
                            width: 105,
                            height: 24,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginLeft: 29,
                        }}
                    >
                        <div
                            style={{
                                width: 16,
                                height: 16,
                                borderRadius: 2,
                                backgroundColor: colors.PRIMARY_BLUE,
                            }}
                        />
                        <Body3Regular style={{ color: colors.LAVEL_3 }}>{`활성 회원 수`}</Body3Regular>
                    </Row>
                </Row>
            </Row>

            <div
                style={{
                    width: 1192,
                    height: 614,
                    marginTop: 25,
                }}
            >
                {isToday && dailyData && (
                    <ActiveMembersDailyGraph
                        todayMaxCount={todayMaxCount}
                        dailyData={dailyData}
                        CustomToolTip={CustomToolTip}
                        CustomTextLayer={CustomTextLayer}
                    />
                )}
                {isWeek && weeklyData && (
                    <ActiveMembersWeeklyGraph
                        weekMaxCount={weekMaxCount}
                        weeklyData={weeklyData}
                        CustomToolTip={CustomToolTip}
                        CustomTextLayer={CustomTextLayer}
                    />
                )}
                {isMonth && monthlyData && (
                    <ActiveMembersMonthlyGraph
                        monthMaxCount={monthMaxCount}
                        monthlyData={monthlyData}
                        CustomToolTip={CustomToolTip}
                        CustomTextLayer={CustomTextLayer}
                    />
                )}
            </div>
        </Col>
    );
};

export default DashBoardActiveMembers;
