import { Row } from '../../lib/utils';
import { Head4 } from '../../lib/font';
import { colors } from '../../lib/colors';

import WhiteRoundButton from '../Button/WhiteRoundButton';

type Props = {
    onClickBackButton: () => void;
};

const DetailHeader = ({ onClickBackButton }: Props) => {
    return (
        <Row
            style={{
                alignItems: 'center',
                width: 1192,
                marginTop: 38,
                marginBottom: 36,
                justifyContent: 'space-between',
            }}
        >
            <Head4 style={{ color: colors.LAVEL_4 }}>강사 프로필</Head4>
            <WhiteRoundButton size="small" text="뒤로가기" callBack={onClickBackButton} />
        </Row>
    );
};

export default DetailHeader;
