import React from 'react';
import { Col, Row } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import { I_COMMENT_ICON, I_LIKE_ICON } from '../../../types/images';
import { Body1Bold, Body1Regular, Body2Bold, Body3Light, Body3Regular, CaptionLight } from '../../../lib/font';
import ProfileImage from '../../ProfileImage';
import dayjs from 'dayjs';

const ModalFeedDetailFooter = ({ comments, reactions }: any) => {
    return (
        <>
            <Col
                style={{
                    width: 1016,
                    border: '1px solid #C3D4F4',
                    borderRadius: 16,
                    marginTop: 48,
                    alignSelf: 'center',
                    paddingTop: 16,
                    paddingLeft: 16,
                    paddingRight: 16,
                }}
            >
                <Row style={{ paddingBottom: 16 }}>
                    <img src={I_LIKE_ICON} style={{ width: 24, height: 24 }} />
                    <Body3Regular style={{ color: colors.LAVEL_3 }}>{`좋아요(${
                        reactions ? reactions.length : 0
                    })`}</Body3Regular>

                    <img src={I_COMMENT_ICON} style={{ width: 24, height: 24, marginLeft: 48 }} />
                    <Body3Regular style={{ color: colors.LAVEL_3 }}>{`댓글(${
                        comments ? comments.length : 0
                    })`}</Body3Regular>
                </Row>

                {comments &&
                    comments.map((item: any, index: number) => {
                        return (
                            <Col
                                style={{
                                    width: 1016,
                                    borderTop: '1px solid #D4DBE8',
                                    alignSelf: 'center',
                                    paddingLeft: 24,
                                    paddingRight: 24,
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                }}
                                key={index + 'detailModalComments'}
                            >
                                <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Row style={{ alignItems: 'center' }}>
                                        <ProfileImage
                                            imageUrl={item?.profileImageUrl}
                                            type="VerySmall"
                                            profileName={item?.profileName}
                                            profileColorType={item?.profileColorType}
                                            right={8}
                                        />
                                        <Body2Bold style={{ color: colors.LAVEL_3, marginLeft: 8 }}>
                                            {`${item?.commenterName} ${item?.role === 'coach' ? ' 강사' : ' 회원'}`}
                                        </Body2Bold>
                                    </Row>
                                    <CaptionLight style={{ color: colors.LAVEL_1 }}>
                                        {dayjs(item?.createdTime).format('YY/MM/DD(ddd)/A HH:mm')}
                                    </CaptionLight>
                                </Row>

                                <Row
                                    style={{
                                        width: '100%',
                                        alignSelf: 'center',
                                        marginTop: 8,

                                        flexWrap: 'wrap',
                                        paddingLeft: 40,
                                    }}
                                >
                                    <Body3Regular style={{ color: colors.LAVEL_2 }}>{item?.content}</Body3Regular>
                                </Row>
                            </Col>
                        );
                    })}
            </Col>

            <div style={{ width: 500, height: 10, marginTop: 100 }} />
        </>
    );
};

export default ModalFeedDetailFooter;
