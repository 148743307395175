import { Col, Row, truncateString } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import { I_BLUE_TRIANGLE, I_EMPTY_PROFILE, I_RED_TRIANGLE } from '../../../types/images';
import {
    Body1Regular,
    Body2Bold,
    Body2Light,
    Body3Bold,
    Body3Regular,
    CaptionRegular,
    Subtitle1Bold,
} from '../../../lib/font';
import Scrollbars from 'react-custom-scrollbars-2';
import dayjs from 'dayjs';
import ScheduleHistoryEmptyList from './ScheduleHistoryEmptyList';
import ProfileImage from '../../ProfileImage';
import TruncateText from '../../../lib/TruncateText';
import { useState } from 'react';
import { HoverReason } from '../HoverReason';

type Props = {
    thisMonthList: any;
};

const ScheduleHistoryThisMonthList = ({ thisMonthList }: Props) => {
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [isHovered, setIsHovered] = useState(false);

    return (
        <Col>
            {thisMonthList.length === 0 ? (
                <ScheduleHistoryEmptyList type={'thisDay'} />
            ) : (
                thisMonthList.map((item: any, index: number) => {
                    console.log(item);
                    return (
                        <Row
                            style={{
                                width: 1100,
                                height: 68,
                                alignItems: 'center',
                                paddingLeft: 12,
                                backgroundColor: index === hoveredIndex ? '#ECF1FC' : 'transparent',
                                transition: 'background-color 0.3s ease',
                            }}
                            key={index}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                        >
                            <Row
                                className="testRow"
                                style={{
                                    width: 70.33 - 0.5,
                                    height: 32,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Body3Regular style={{ color: colors.LAVEL_3 }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%', // 부모 요소의 높이에 맞게 설정
                                        }}
                                    >
                                        <Row
                                            style={{
                                                width: 46,
                                                height: 30,
                                                borderRadius: 4,
                                                backgroundColor:
                                                    item?.adjustmentType === 'coachTodayCancel'
                                                        ? colors.ERROR_50
                                                        : colors.CHANGEYELLOW,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <CaptionRegular
                                                style={{
                                                    color:
                                                        item?.adjustmentType === 'coachTodayCancel'
                                                            ? colors.ERROR
                                                            : colors.CHANGE,
                                                }}
                                            >
                                                {item?.adjustmentType === 'coachTodayCancel' ? '취소' : '변경'}
                                            </CaptionRegular>
                                        </Row>
                                    </div>
                                </Body3Regular>
                            </Row>

                            <div
                                style={{
                                    width: 1,
                                    height: 24,
                                    backgroundColor: colors.WHITE_50,
                                }}
                            />

                            <Row style={{ width: 136.67 - 0.5, justifyContent: 'center' }}>
                                <ProfileImage
                                    imageUrl={item?.coachProfileImageUrl}
                                    type="VerySmall"
                                    profileName={item?.coachProfileName}
                                    profileColorType={item?.coachProfileColorType}
                                />
                                <Body3Regular style={{ color: colors.LAVEL_3, marginLeft: 8 }}>
                                    {item.coachName}
                                </Body3Regular>
                            </Row>
                            <div
                                style={{
                                    width: 1,
                                    height: 24,
                                    backgroundColor: colors.WHITE_50,
                                }}
                            />

                            <Row style={{ width: 136.67 - 0.5, justifyContent: 'center' }}>
                                <ProfileImage
                                    imageUrl={item?.memberProfileImageUrl}
                                    type="VerySmall"
                                    profileName={item?.memberProfileName}
                                    profileColorType={item?.memberProfileColorType}
                                />
                                <Body3Regular style={{ color: colors.LAVEL_3, marginLeft: 8 }}>
                                    {item.memberName}
                                </Body3Regular>
                            </Row>
                            <div
                                style={{
                                    width: 1,
                                    height: 24,
                                    backgroundColor: colors.WHITE_50,
                                }}
                            />
                            <Row
                                style={{
                                    width: 200.66 - 0.5,
                                    height: 32,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <CaptionRegular style={{ color: colors.LAVEL_3 }}>
                                    <p>{dayjs(item.startTime).format('YYYY / MM / DD(ddd)')}</p>
                                    <p>
                                        {dayjs(item.startTime).format(`A hh시 mm분`)} ~{' '}
                                        {dayjs(item.endTime).format(`A hh시 mm분`)}
                                    </p>
                                </CaptionRegular>
                            </Row>
                            <div
                                style={{
                                    width: 1,
                                    height: 24,
                                    backgroundColor: colors.WHITE_50,
                                }}
                            />
                            <Row
                                style={{
                                    width: 200.66 - 0.5,
                                    height: 32,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {item?.adjustmentType === 'coachTodayCancel' ? (
                                    <CaptionRegular>-</CaptionRegular>
                                ) : (
                                    <CaptionRegular style={{ color: colors.LAVEL_3 }}>
                                        <p>{dayjs(item.changedStartTime).format('YYYY / MM / DD(ddd)')}</p>
                                        <p>
                                            {dayjs(item.changedStartTime).format(`A hh시 mm분`)} ~{' '}
                                            {dayjs(item.changedEndTime).format(`A hh시 mm분`)}
                                        </p>
                                    </CaptionRegular>
                                )}
                            </Row>

                            <div
                                style={{
                                    width: 1,
                                    height: 24,
                                    backgroundColor: colors.WHITE_50,
                                }}
                            />

                            <Row
                                style={{
                                    width: 228.67 - 0.5,
                                    height: 68,
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    paddingLeft: 14.33,
                                    position: 'relative',
                                }}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            >
                                <TruncateText text={item.reason} maxLength={40}>
                                    {item.reason}
                                </TruncateText>
                                {isHovered && hoveredIndex === index && (
                                    <HoverReason className="hover-popup">
                                        <Col>
                                            <Body3Bold style={{ marginBottom: 8 }}>
                                                강사 {item.adjustmentType === 'coachTodayCancel' ? '취소' : '변경'} 사유
                                            </Body3Bold>
                                            <Body3Regular style={{ marginBottom: 12 }}>{item.reason}</Body3Regular>
                                            <CaptionRegular>
                                                취소시간: {dayjs(item.createdAt).format('YYYY/MM/DD(ddd) A hh시 mm분')}
                                            </CaptionRegular>
                                        </Col>
                                    </HoverReason>
                                )}
                            </Row>

                            <div
                                style={{
                                    width: 1,
                                    height: 24,
                                    backgroundColor: colors.WHITE_50,
                                }}
                            />

                            <Row style={{ width: 126.33, height: 32, alignItems: 'center', justifyContent: 'center' }}>
                                <CaptionRegular style={{ color: colors.LAVEL_3 }}>
                                    <p>{`총 ${item.cancelCount}회`}</p>
                                </CaptionRegular>
                            </Row>
                        </Row>
                    );
                })
            )}
        </Col>
    );
};

export default ScheduleHistoryThisMonthList;
