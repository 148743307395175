import React from 'react';
import { Col, Row } from '../../../lib/utils';
import { Body3Light, Body3Regular } from '../../../lib/font';
import { StyledSignUpInput2 } from '../SignUpBody2';
import WhiteRoundButton from '../../Button/WhiteRoundButton';
import { colors } from '../../../lib/colors';

const InputPhoneNumber = ({ phoneNumber, setPhoneNumber, handleSmsCodeRequest, isCheckAuthNumber }: any) => {
    return (
        <Col style={{ marginTop: 32 }}>
            <label htmlFor="phoneNumber" style={{ display: 'flex', marginBottom: 8 }}>
                <Body3Regular>휴대폰 번호</Body3Regular>
                &nbsp;
                <Body3Regular style={{ color: colors.ERROR }}>*</Body3Regular>
            </label>
            <Row>
                <StyledSignUpInput2
                    id="phoneNumber"
                    name="phoneNumber"
                    type="phoneNumber"
                    autoComplete="phoneNumber"
                    value={phoneNumber}
                    placeholder="휴대폰 번호를 입력해주세요!"
                    required
                    onChange={(e) => {
                        const regex = /^[0-9\b -]{0,13}$/;
                        if (regex.test(e.target.value)) {
                            setPhoneNumber(e.target.value);
                        }
                    }}
                />
                <WhiteRoundButton
                    text="인증"
                    size="middle"
                    isDisabled={phoneNumber.length < 13 || isCheckAuthNumber}
                    isVisibleLeftImg={false}
                    isVisibleRightImg={false}
                    callBack={handleSmsCodeRequest}
                    type="button"
                    customStyle={{ width: 76, height: 48 }}
                />
            </Row>
            <Body3Light style={{ marginLeft: 16, marginTop: 6, color: colors.WHITE_800 }}>
                본인인증에 필요한 절차입니다
            </Body3Light>
        </Col>
    );
};

export default InputPhoneNumber;
