import React from 'react';
import { Body3Regular, CaptionBold, CaptionLight } from '../../lib/font';
import { colors } from '../../lib/colors';
import { Col, Row } from '../../lib/utils';
import { I_ANDROID_LOGO, I_ANDROID_QR_CODE, I_IOS_LOGO, I_IOS_QR_CODE } from '../../types/images';

import ColorSquareButton from '../Button/ColorSquareButton';

const ModalAddTrainerBody1 = ({ onClickNextStep }: any) => {
    return (
        <>
            <Row style={{ marginTop: 32, marginLeft: 48 }}>
                <Body3Regular style={{ color: colors.WHITE_800 }}>
                    강사님의 휴대폰에 ‘라포'를 다운로드를 하여 ‘트레이너 회원가입’을 진행해주세요!
                    <br />
                    이미 다운로드가 되어있다면 ‘다음'을 눌러주세요
                </Body3Regular>
            </Row>

            <Row style={{ marginTop: 32, marginLeft: 48 }}>
                <Col
                    style={{
                        width: 88,
                        height: 140,
                        marginRight: 64,
                        alignItems: 'center',
                    }}
                >
                    <Row style={{ alignItems: 'center' }}>
                        <img style={{ width: 18, height: 18 }} src={I_IOS_LOGO} />
                        <CaptionBold style={{ color: colors.LAVEL_1, marginLeft: 4 }}>IOS</CaptionBold>
                    </Row>
                    <img style={{ width: 68, height: 68, marginTop: 18 }} src={I_IOS_QR_CODE} />
                    <CaptionLight style={{ marginTop: 17.81, color: colors.BLUE_900 }}>App Store</CaptionLight>
                </Col>

                <Col style={{ width: 88, height: 132, alignItems: 'center' }}>
                    <Row style={{ alignItems: 'center' }}>
                        <img style={{ width: 18, height: 18 }} src={I_ANDROID_LOGO} />
                        <CaptionBold style={{ color: colors.LAVEL_1, marginLeft: 4 }}>Android</CaptionBold>
                    </Row>

                    <img style={{ width: 68, height: 68, marginTop: 18 }} src={I_ANDROID_QR_CODE} />
                    <CaptionLight style={{ marginTop: 17.81, color: colors.BLUE_900 }}>Google Store</CaptionLight>
                </Col>

                <ColorSquareButton
                    text={'다음'}
                    callBack={onClickNextStep}
                    size="middle"
                    customStyle={{ marginLeft: 406, marginTop: 124 }}
                />
            </Row>
        </>
    );
};

export default ModalAddTrainerBody1;
