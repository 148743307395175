import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { Col, Row } from '../../lib/utils';
import { Body1Bold, Body1Regular, Body3Bold, Body3Light, Head4 } from '../../lib/font';
import { colors } from '../../lib/colors';
import { I_POPUP_EXIT, I_PRIMARY_LEFT_TRIANGLE, I_PRIMARY_RIGHT_TRIANGLE, I_SEARCH } from '../../types/images';
import dayjs from 'dayjs';
import styled from 'styled-components';

type Props = {
    isMonth: boolean;
    isYear: boolean;
    isEntire: boolean;
    currentDate: any;
    settingDate: (type: string) => void;
    onClickExit: () => void;
    searchText: string;
    setSearchText: Dispatch<SetStateAction<string>>;
    thisMonthFilterList: any;
    thisYearFilterList: any;
    entireFilterList: any;
    onClickAddDate: () => void;
    onClickSubtractDate: () => void;
};

const HoverImage = styled.img`
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

const ModalClassHistoryHeader = ({
    isMonth,
    isYear,
    isEntire,
    currentDate,
    settingDate,
    onClickExit,
    searchText,
    setSearchText,
    thisMonthFilterList,
    thisYearFilterList,
    entireFilterList,
    onClickAddDate,
    onClickSubtractDate,
}: Props) => {
    return (
        <Row
            style={{
                width: 1400,
                height: 210,
                borderTopLeftRadius: 24,
                borderTopRightRadius: 24,
                boxShadow: '1px 1px 8px 0px rgba(43, 82, 157, 0.18)',
            }}
        >
            <Col>
                <Row style={{ alignItems: 'center', marginLeft: 60, marginTop: 44, marginBottom: 8 }}>
                    <Head4 style={{ color: colors.BLUE_700 }}>수업 히스토리</Head4>
                    <Body3Light style={{ marginLeft: 36, color: colors.WHITE_800 }}>
                        해당 트레이너의 &nbsp;<Body3Bold>수업 기록을 확인</Body3Bold>할 수 있습니다
                    </Body3Light>
                </Row>
                <Head4 style={{ color: colors.WHITE_900, marginLeft: 60, marginTop: 18 }}>
                    총 {isMonth && thisMonthFilterList && thisMonthFilterList.length}
                    {isYear && thisYearFilterList && thisYearFilterList.length}
                    {isEntire && entireFilterList && entireFilterList.length}회
                </Head4>

                <Row style={{ marginLeft: 60, marginTop: 24 }}>
                    <Row
                        style={{
                            width: 154,
                            height: 24,
                        }}
                    >
                        <button style={{ width: 24, height: 24 }} onClick={onClickSubtractDate}>
                            <img src={I_PRIMARY_LEFT_TRIANGLE} style={{ width: 24, height: 24 }} />
                        </button>
                        <Body1Bold
                            style={{
                                width: 100,
                                color: colors.BLUE_700,
                                textAlign: 'center',
                            }}
                        >
                            {isMonth
                                ? dayjs(currentDate).format('YYYY년 MM월')
                                : isYear
                                ? dayjs(currentDate).format('YYYY년')
                                : '전체기간'}
                        </Body1Bold>
                        <button style={{ width: 24, height: 24 }} onClick={onClickAddDate}>
                            <img src={I_PRIMARY_RIGHT_TRIANGLE} style={{ width: 24, height: 24 }} />
                        </button>
                    </Row>

                    <Row
                        style={{
                            width: 440,
                            marginLeft: 73,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <button
                            style={{
                                width: 124,
                                height: 24,
                                color: isMonth ? colors.BLUE_700 : colors.WHITE_700,
                            }}
                            onClick={() => settingDate('month')}
                        >
                            {isMonth ? <Body1Bold>이번달</Body1Bold> : <Body1Regular>이번달</Body1Regular>}
                        </button>

                        <button
                            style={{
                                width: 124,
                                height: 24,
                                color: isYear ? colors.BLUE_700 : colors.WHITE_700,
                            }}
                            onClick={() => settingDate('year')}
                        >
                            {isYear ? <Body1Bold>이번년도</Body1Bold> : <Body1Regular>이번년도</Body1Regular>}
                        </button>
                        <button
                            style={{
                                width: 124,
                                height: 24,
                                color: isEntire ? colors.BLUE_700 : colors.WHITE_700,
                            }}
                            onClick={() => settingDate('entire')}
                        >
                            {isEntire ? <Body1Bold>전체보기</Body1Bold> : <Body1Regular>전체보기</Body1Regular>}
                        </button>
                    </Row>
                </Row>
            </Col>
            <Col style={{ marginLeft: 197 }}>
                <HoverImage
                    src={I_POPUP_EXIT}
                    style={{ width: 52, height: 52, marginTop: 40, marginLeft: 354 }}
                    onClick={onClickExit}
                />

                <Row style={{ width: 406, height: 64, marginTop: 36 }}>
                    <img
                        src={I_SEARCH}
                        style={{ position: 'absolute', width: 17, height: 20, marginTop: 22, marginLeft: 23 }}
                    />

                    <input
                        placeholder="회원이름으로 검색"
                        value={searchText}
                        style={{
                            width: 406,
                            height: 64,
                            borderRadius: 48,
                            backgroundColor: colors.WHITE_500,
                            boxShadow: '4px 4px 8px 1px rgba(33, 62, 123, 0.16) inset',
                            paddingLeft: 50,
                            fontSize: 16,
                            fontWeight: 300,
                            //lineHeight: 24,
                        }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value.trim())}
                    />
                </Row>
            </Col>
        </Row>
    );
};

export default ModalClassHistoryHeader;
