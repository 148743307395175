import React from 'react';
import { Body3Bold, Body3Regular } from '../../../lib/font';
import { I_PRIMARY_CHECK } from '../../../types/images';
import { colors } from '../../../lib/colors';

const CheckPolicy = ({ onClickCheckAge, isCheckAge, onClickPolicy, isCheckPolicy, onClickTerm }: any) => {
    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 76 }}>
                <button
                    type="button"
                    onClick={onClickCheckAge}
                    style={{
                        width: 24,
                        height: 24,
                        borderWidth: 2,
                        borderColor: 'rgba(212, 219, 232, 1)',
                        borderRadius: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: 24,
                    }}
                >
                    {isCheckAge && <img src={I_PRIMARY_CHECK} />}
                </button>

                <Body3Regular style={{ color: colors.LAVEL_4 }}>(필수) 만 14세 이상입니다.</Body3Regular>
                <Body3Regular style={{ color: colors.ERROR }}>*</Body3Regular>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginTop: 23 }}>
                <button
                    type="button"
                    onClick={onClickPolicy}
                    style={{
                        width: 24,
                        height: 24,
                        borderWidth: 2,
                        borderColor: 'rgba(212, 219, 232, 1)',
                        borderRadius: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: 24,
                    }}
                >
                    {isCheckPolicy && <img src={I_PRIMARY_CHECK} />}
                </button>
                <div style={{ cursor: 'pointer' }} onClick={onClickTerm}>
                    <Body3Regular>
                        (필수) <Body3Bold>개인정보 수집 및 이용약관</Body3Bold>에 동의합니다.
                    </Body3Regular>
                </div>
                <Body3Regular style={{ color: colors.ERROR }}>*</Body3Regular>
            </div>
        </>
    );
};

export default CheckPolicy;
