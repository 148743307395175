import React, { Dispatch, SetStateAction, useState } from 'react';
import { Col, Row } from '../../lib/utils';
import { Body3Light, Head4, Subtitle1Bold, Subtitle1Regular } from '../../lib/font';
import { colors } from '../../lib/colors';
import { I_RIGHT_PRIMARY_BTN } from '../../types/images';

type Props = {
    buttonType: string;
    setButtonType: Dispatch<SetStateAction<string>>;
    smallCenterTrainerNumber: any;
    bigCenterTrainerNumber: any;
    isCoachCountAlert: any;
    onChangeTrainerNumber: any;
    authObject: any;
};

const ExpiredPlanReSubscriptionHeader = ({
    buttonType,
    setButtonType,
    smallCenterTrainerNumber,
    bigCenterTrainerNumber,
    isCoachCountAlert,
    onChangeTrainerNumber,
    authObject,
}: Props) => {
    const [isLeftHovered, setIsLeftHovered] = useState(false);
    const [isRightHovered, setIsRightHovered] = useState<boolean>(false);
    return (
        <Col
            style={{
                width: 1192,
                borderRadius: 24,
            }}
        >
            <Subtitle1Bold style={{ color: colors.LAVEL_4, marginLeft: 100, marginTop: 48 }}>
                {'몇 명의 강사님이 이용하실 예정인가요?'}
            </Subtitle1Bold>

            <Row style={{ width: 378, height: 48, alignItems: 'center', marginLeft: 108, marginTop: 16 }}>
                <img src={I_RIGHT_PRIMARY_BTN} style={{ width: 16, height: 16 }} />

                <input
                    value={buttonType === 'small' ? smallCenterTrainerNumber : bigCenterTrainerNumber}
                    type="number"
                    max={buttonType === 'small' ? 3 : 20}
                    min={buttonType === 'small' ? 1 : 4}
                    style={{
                        width: 88,
                        height: 48,
                        borderRadius: 36,
                        backgroundColor: colors.WHITE_50,
                        marginLeft: 12,
                        // textAlign: 'center',
                        paddingLeft: 16,
                        fontSize: 16,
                        fontWeight: 400,
                        color: colors.WHITE_900,

                        border: isCoachCountAlert ? '1px solid #FF3E4A' : '1px solid #a6bfef',
                    }}
                    onChange={onChangeTrainerNumber}
                />
                <Head4 style={{ marginLeft: 12, color: colors.LAVEL_4 }}>{'명'}</Head4>
                <Body3Light
                    style={{ color: colors.LAVEL_2, marginTop: 8, marginLeft: 24 }}
                >{`현재 등록된 강사 : ${authObject?.coachCount}명`}</Body3Light>
            </Row>

            <Row style={{ width: 1064, marginLeft: 64, justifyContent: 'space-between', marginTop: 48 }}>
                {/* 3인이하 소형센터  */}
                <button
                    onClick={() => setButtonType('small')}
                    style={{ width: 532 }}
                    onMouseEnter={() => setIsLeftHovered(true)}
                    onMouseLeave={() => setIsLeftHovered(false)}
                >
                    <Col
                        style={{
                            width: 532,
                            height: 60,
                            alignItems: 'center',
                            justifyContent: 'center',
                            background:
                                buttonType === 'small'
                                    ? 'linear-gradient(90deg, #3D73DD 0%, #6327E2 100%)'
                                    : isLeftHovered
                                    ? colors.WHITE_600
                                    : colors.WHITE_300,
                            border: '1px solid #A6BFEF',
                            borderTopLeftRadius: 36,
                            borderBottomLeftRadius: 36,
                        }}
                    >
                        <Subtitle1Bold style={{ color: buttonType === 'small' ? colors.WHITE_50 : colors.POINT_2 }}>
                            3인 이하 소형 센터
                        </Subtitle1Bold>
                    </Col>
                </button>

                {/* 4인 이상 대형 센터 */}
                <Col style={{ width: 532 }}>
                    <button
                        style={{
                            width: 532,
                            height: 60,
                            alignItems: 'center',
                            justifyContent: 'center',
                            background:
                                buttonType === 'big'
                                    ? 'linear-gradient(90deg, #3D73DD 0%, #6327E2 100%)'
                                    : isRightHovered
                                    ? colors.WHITE_600
                                    : colors.BLUE_50,

                            border: '1px solid #A6BFEF',
                            borderTopRightRadius: 36,
                            borderBottomRightRadius: 36,
                        }}
                        onClick={() => setButtonType('big')}
                        onMouseEnter={() => setIsRightHovered(true)}
                        onMouseLeave={() => setIsRightHovered(false)}
                    >
                        <Subtitle1Bold style={{ color: buttonType === 'big' ? colors.WHITE_50 : colors.POINT_2 }}>
                            4인 이상 대형 센터
                        </Subtitle1Bold>
                    </button>
                </Col>
            </Row>
        </Col>
    );
};

export default ExpiredPlanReSubscriptionHeader;
