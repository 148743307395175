import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

const coachScheduleRecords = [
    {
        _id: '657821571fbfed001986f207',
        scheduleBundleId: null,
        memo: '',
        isDeleted: false,
        isCanceled: false,
        coachId: '6465c1a6090e920012f138eb',
        memberId: '646b0d58755c9f00126dba42',
        alias: 'KK7',
        startTime: dayjs().startOf('day').add(18, 'hour').toISOString(),
        endTime: dayjs().startOf('day').add(19, 'hour').toISOString(),
        requestDeadline: '2023-12-27T10:00:00.000Z',
        createdAt: '2023-12-12T09:01:11.746Z',
        updatedAt: '2023-12-21T09:20:11.778Z',
        __v: 0,
        currentSession: 5,
        totalSession: 30,
        membershipCount: 1,
    },
    {
        _id: '65817b86497eb200195d10ff',
        scheduleBundleId: null,
        memo: '',
        isDeleted: false,
        isCanceled: false,
        coachId: '6465c1a6090e920012f138eb',
        memberId: '64abae3f408d05001309d2cd',
        alias: '3132',
        startTime: dayjs().startOf('day').add(17, 'hour').toISOString(),
        endTime: dayjs().startOf('day').add(18, 'hour').toISOString(),
        requestDeadline: '2023-12-18T10:00:00.000Z',
        createdAt: '2023-12-19T11:16:22.498Z',
        updatedAt: '2023-12-21T10:30:40.224Z',
        __v: 0,
        currentSession: 3,
        totalSession: 30,
        membershipCount: 1,
    },
    {
        _id: '65799eb98bcd1b0012d22007',
        scheduleBundleId: null,
        memo: '',
        isDeleted: true,
        isCanceled: false,
        coachId: '6465c1a6090e920012f138eb',
        memberId: '6577b1bbe537480019d73b70',
        alias: 'Xx',
        startTime: dayjs().startOf('day').add(16, 'hour').toISOString(),
        endTime: dayjs().startOf('day').add(17, 'hour').toISOString(),
        requestDeadline: '2023-12-14T10:00:00.000Z',
        createdAt: '2023-12-13T12:08:25.622Z',
        updatedAt: '2023-12-20T02:27:29.686Z',
        __v: 0,
        deletedAt: '2023-12-20T02:27:29.686Z',
        currentSession: null,
        totalSession: null,
        membershipCount: null,
    },
    {
        _id: '6579892a01d50c0019fac163',
        scheduleBundleId: null,
        memo: '',
        isDeleted: true,
        isCanceled: false,
        coachId: '6465c1a6090e920012f138eb',
        memberId: '6577b1bbe537480019d73b70',
        alias: 'Xx',
        startTime: dayjs().startOf('day').add(15, 'hour').toISOString(),
        endTime: dayjs().startOf('day').add(16, 'hour').toISOString(),
        requestDeadline: '2023-12-14T10:00:00.000Z',
        createdAt: '2023-12-13T10:36:26.792Z',
        updatedAt: '2023-12-20T02:27:29.686Z',
        __v: 0,
        deletedAt: '2023-12-20T02:27:29.686Z',
        currentSession: null,
        totalSession: null,
        membershipCount: null,
    },
    {
        _id: '6579892a01d50c0019fac163',
        scheduleBundleId: null,
        memo: '',
        isDeleted: true,
        isCanceled: false,
        coachId: '6465c1a6090e920012f138eb',
        memberId: '6577b1bbe537480019d73b70',
        alias: 'Xx',
        startTime: dayjs().startOf('day').subtract(1, 'day').add(15, 'hour').toISOString(),
        endTime: dayjs().startOf('day').subtract(1, 'day').add(16, 'hour').toISOString(),
        requestDeadline: '2023-12-14T10:00:00.000Z',
        createdAt: '2023-12-13T10:36:26.792Z',
        updatedAt: '2023-12-20T02:27:29.686Z',
        __v: 0,
        deletedAt: '2023-12-20T02:27:29.686Z',
        currentSession: null,
        totalSession: null,
        membershipCount: null,
    },
    {
        _id: '6579892a01d50c0019fac163',
        scheduleBundleId: null,
        memo: '',
        isDeleted: true,
        isCanceled: false,
        coachId: '6465c1a6090e920012f138eb',
        memberId: '6577b1bbe537480019d73b70',
        alias: 'Xx',
        startTime: dayjs().startOf('day').subtract(1, 'day').add(15, 'hour').toISOString(),
        endTime: dayjs().startOf('day').subtract(1, 'day').add(16, 'hour').toISOString(),
        requestDeadline: '2023-12-14T10:00:00.000Z',
        createdAt: '2023-12-13T10:36:26.792Z',
        updatedAt: '2023-12-20T02:27:29.686Z',
        __v: 0,
        deletedAt: '2023-12-20T02:27:29.686Z',
        currentSession: null,
        totalSession: null,
        membershipCount: null,
    },
    {
        _id: '657988797b163c0012f1515a',
        scheduleBundleId: null,
        memo: '',
        isDeleted: true,
        isCanceled: false,
        coachId: '6465c1a6090e920012f138eb',
        memberId: '6577b1bbe537480019d73b70',
        alias: 'Xx',
        startTime: dayjs().subtract(1, 'month').startOf('day').add(18, 'hour').toISOString(),
        endTime: dayjs().subtract(1, 'month').startOf('day').add(19, 'hour').toISOString(),
        requestDeadline: '2023-12-13T10:00:00.000Z',
        createdAt: '2023-12-13T10:33:29.238Z',
        updatedAt: '2023-12-20T02:27:29.686Z',
        __v: 0,
        deletedAt: '2023-12-20T02:27:29.686Z',
        currentSession: null,
        totalSession: null,
        membershipCount: null,
    },
    {
        _id: '657988ad7b163c0012f152b1',
        scheduleBundleId: null,
        memo: '',
        isDeleted: false,
        isCanceled: false,
        coachId: '6465c1a6090e920012f138eb',
        memberId: '64abae3f408d05001309d2cd',
        alias: '3132',
        startTime: dayjs().subtract(1, 'year').startOf('day').add(18, 'hour').toISOString(),
        endTime: dayjs().subtract(1, 'year').startOf('day').add(19, 'hour').toISOString(),
        requestDeadline: '2023-12-13T10:00:00.000Z',
        createdAt: '2023-12-13T10:34:21.516Z',
        updatedAt: '2023-12-21T10:30:40.224Z',
        __v: 0,
        currentSession: 2,
        totalSession: 30,
        membershipCount: 1,
    },
];

const thisYearSchedules = coachScheduleRecords.filter((schedule: any) =>
    dayjs(schedule.startTime).isBetween(dayjs().startOf('year'), dayjs().endOf('year'), 'day', '[]')
);

const thisMonthSchedules = coachScheduleRecords.filter((schedule: any) =>
    dayjs(schedule.startTime).isBetween(dayjs().startOf('month'), dayjs().endOf('month'), 'day', '[]')
);

const thisMonthScheduleCount = thisMonthSchedules.length;
const thisYearScheduleCount = thisYearSchedules.length;
const allScheduleCount = coachScheduleRecords.length;

const currentYear = dayjs(coachScheduleRecords[0]?.startTime).get('year');
const currentMonth = dayjs(coachScheduleRecords[0]?.startTime).get('month') + 1;
const lastYear = dayjs(coachScheduleRecords[coachScheduleRecords.length - 1]?.startTime).get('year');
const lastMonth = dayjs(coachScheduleRecords[coachScheduleRecords.length - 1]?.startTime).get('month') + 1;

/*
 * 빈 배열 세팅
 */
let totalViewDummyData: any = [];

// 최근 년도
for (let i = 0; i <= currentYear - lastYear; i++) {
    totalViewDummyData.push({ [currentYear - i]: [] });
    if (i === 0) {
        for (let j = 0; j < currentMonth; j++) {
            totalViewDummyData[i][currentYear - i].push({ [currentMonth - j]: [], monthScheduleCount: 0 });
        }
    }
    // 중간 년도
    else {
        for (let j = 0; j <= 12 - lastMonth; j++) {
            totalViewDummyData[i][currentYear - i].push({ [12 - j]: [], monthScheduleCount: 0 });
        }
    }
}

/*
 *  빈 배열에 shedule 껴넣기
 */
coachScheduleRecords.map((schedule: any) => {
    const year = dayjs(schedule.startTime).get('year');
    const month = dayjs(schedule.startTime).get('month') + 1;
    const day = dayjs(schedule.startTime).get('date');
    let yearIndex = 0;
    let monthIndex = 0;
    let dayIndex = 0;
    if (totalViewDummyData.some((obj: any) => obj.hasOwnProperty(year))) {
        yearIndex = totalViewDummyData.findIndex((obj: any) => obj.hasOwnProperty(year));
        if (totalViewDummyData[yearIndex][year].some((obj: any) => obj.hasOwnProperty(month))) {
            monthIndex = totalViewDummyData[yearIndex][year].findIndex((obj: any) => obj.hasOwnProperty(month));
            totalViewDummyData[yearIndex][year][monthIndex].monthScheduleCount++;
            if (totalViewDummyData[yearIndex][year][monthIndex][month].some((obj: any) => obj.hasOwnProperty(day))) {
                dayIndex = totalViewDummyData[yearIndex][year][monthIndex][month].findIndex((obj: any) =>
                    obj.hasOwnProperty(day)
                );
                totalViewDummyData[yearIndex][year][monthIndex][month][dayIndex][day].push(schedule);
                totalViewDummyData[yearIndex][year][monthIndex][month][dayIndex].dayScheduleCount++;
            } else {
                totalViewDummyData[yearIndex][year][monthIndex][month].push({
                    [day]: [schedule],
                    dayScheduleCount: 1,
                });
            }
        }
    }
});

let thisYearDummyData: any = [];
let thisMonthDummyData: any = [];

const yearNumber = dayjs().get('year');
const monthNumber = dayjs().get('month') + 1;
if (totalViewDummyData.some((obj: any) => obj.hasOwnProperty(yearNumber))) {
    const yearIndex = totalViewDummyData.findIndex((obj: any) => obj.hasOwnProperty(yearNumber));
    thisYearDummyData = totalViewDummyData[yearIndex];
    if (totalViewDummyData[yearIndex][yearNumber].some((obj: any) => obj.hasOwnProperty(monthNumber))) {
        const monthIndex = totalViewDummyData[yearIndex][yearNumber].findIndex((obj: any) =>
            obj.hasOwnProperty(monthNumber)
        );
        thisMonthDummyData = totalViewDummyData[yearIndex][yearNumber][monthIndex];
    }
}

export {
    thisMonthScheduleCount,
    thisYearScheduleCount,
    allScheduleCount,
    thisMonthDummyData,
    thisYearDummyData,
    totalViewDummyData,
};
