import React from 'react';
import { Col, Row } from '../../../lib/utils';
import { I_GREY_CIRCLE } from '../../../types/images';
import { Body3Light, Head4 } from '../../../lib/font';
import { colors } from '../../../lib/colors';

const MemberShipEmptyProgress = () => {
    const lengthArr = Array.from({ length: 9 }, (_, index) => 8 - index);
    return (
        <Col style={{}}>
            {lengthArr.map((item, index) => {
                return (
                    <Row
                        key={index}
                        style={{
                            marginTop: index === 0 ? 15 : 0,
                            marginBottom: 13,
                            marginLeft: 62,
                            alignItems: 'center',
                        }}
                    >
                        <Body3Light style={{ color: colors.BLUE_700 }}>{item}</Body3Light>
                        <hr style={{ width: 1080, height: 1, marginLeft: 19, backgroundColor: colors.WHITE_600 }} />
                    </Row>
                );
            })}
        </Col>
    );
};

export default MemberShipEmptyProgress;
