import React from 'react';
import styled from 'styled-components';
import { Col, Row } from '../../lib/utils';
import { colors } from '../../lib/colors';
import { Body2Bold, Body3Bold, OverlineRegular } from '../../lib/font';
import dayjs from 'dayjs';
import { MemberMembershipDataType } from '../../services/useMembershipQueryService';
import { MemberShipApiType } from '../../api/memberShip';
import SvgIcon from '../SvgIcon';

type Props = {
    index: number;
    membership: MemberMembershipDataType | MemberShipApiType;
    onClickDetailPage: (membership: any) => void;
    onClickDelete?: (index: number) => void;
};

const Container = styled.div`
    margin-bottom: 24px;
    margin-top: 3px;
    margin-left: 3px;
    margin-right: 20px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 2px 2px 12px 0px #00000029;
    width: 316px; /* 고정 너비 설정 */
    height: 160px; /* 고정 높이 설정 */
    &:hover {
        transform: scale(1.02);
    }
    flex-shrink: 0; /* Prevent shrinking */
    position: relative; /* Add relative positioning here */
`;

const MembershipCard = ({ index, membership, onClickDetailPage, onClickDelete = undefined }: Props) => {
    // 유효기간
    const validDays = dayjs(membership.expirationDate).diff(dayjs(), 'day');
    // 활성화 여부
    const isActive = membership.status === 'active';
    return (
        <Container onClick={() => onClickDetailPage(membership)} className="flex flex-col">
            {onClickDelete && (
                <Col
                    className="flex absolute right-[12px] top-[10px] cursor-pointer"
                    onClick={() => {
                        onClickDelete && onClickDelete(index);
                    }}
                >
                    <SvgIcon name={'SvgCircleX'} size={24} fill="white" />
                </Col>
            )}
            <Col
                className="flex flex-col w-full"
                style={{
                    // membership.BGColor 이 있을 경우 그대로 사용, 없을 경우 기본값 사용
                    background: `${
                        isActive
                            ? `linear-gradient(135deg, ${membership.BGColor[0]} 0%, ${membership.BGColor[1]} 100%)`
                            : colors.GREY_200
                    }`,

                    alignItems: 'center',
                    // background: `linear-gradient(135deg, ${membership.BGColor[0]} 0%, ${membership.BGColor[1]} 100%)`,
                }}
            >
                <Col className="flex w-full p-[32px] justify-start items-start">
                    <Body2Bold className="text-LAVEL_0">{`${membership?.name}`}</Body2Bold>
                </Col>
            </Col>
            <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                <Row className="flex w-full justify-between px-[24px] py-[16px] bg-WHITE_50">
                    <Col style={{ justifyContent: 'space-between' }}>
                        <OverlineRegular style={{ color: colors.LAVEL_3 }}>{'총 회차'}</OverlineRegular>
                        <Body3Bold style={{ color: colors.LAVEL_4 }}>{`${membership?.totalSession}회`}</Body3Bold>
                    </Col>
                    <Col style={{ justifyContent: 'space-between' }}>
                        <OverlineRegular style={{ color: colors.LAVEL_3 }}>{'유효 기간'}</OverlineRegular>
                        <Body3Bold style={{ color: colors.LAVEL_4 }}>{`${validDays}일`}</Body3Bold>
                    </Col>
                    <Col style={{ justifyContent: 'space-between' }}>
                        <OverlineRegular style={{ color: colors.LAVEL_3 }}>{'시간'}</OverlineRegular>
                        <Body3Bold style={{ color: colors.LAVEL_4 }}>{`${membership?.period}분`}</Body3Bold>
                    </Col>
                </Row>
            </Row>
        </Container>
    );
};

export default MembershipCard;
