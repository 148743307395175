export interface ChartItem {
    value: number;
    color: string;
}

export interface DonutChartProps {
    data: ChartItem[];
    totalValue: number;
}

const DonutChart = ({ data, totalValue }: DonutChartProps) => {
    const width = 200;
    const height = 200;
    const innerRadius = 75; // 빈공간 원의 반지름 (width와 height가 50이므로 반지름은 25)
    const outerRadius = 100; // 바깥 원의 반지름 (width와 height가 200이므로 반지름은 100)
    const strokeWidth = (outerRadius - innerRadius) / 2; // 회색 선의 두께

    // 전체 데이터의 합을 계산
    const total = data.reduce((acc, item) => acc + item.value, 0);

    // 각도를 라디안으로 변환
    const radians = (degree: number) => (degree * Math.PI) / 180;

    // 호(arc)를 그리는 함수
    const describeArc = (x: number, y: number, radius: number, startAngle: number, endAngle: number) => {
        const start = {
            x: x + radius * Math.cos(radians(startAngle - 90)),
            y: y + radius * Math.sin(radians(startAngle - 90)),
        };
        const end = {
            x: x + radius * Math.cos(radians(endAngle - 90)),
            y: y + radius * Math.sin(radians(endAngle - 90)),
        };
        const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

        return ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 1, end.x, end.y, 'L', x, y, 'Z'].join(' ');
    };

    // 데이터를 값에 따라 정렬
    const sortedData = [...data].sort((a, b) => b.value - a.value);

    return (
        <svg style={{ zIndex: 2 }} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
            <g transform={`translate(${width / 2}, ${height / 2})`}>
                {sortedData.map((item, index) => {
                    const angle = (item.value / totalValue) * 360; // 백분율로 변환
                    return (
                        <path
                            key={index}
                            d={describeArc(0, 0, outerRadius, 0, angle)}
                            fill={item.color}
                            style={{ zIndex: data.length - index }}
                        />
                    );
                })}
                <circle cx="0" cy="0" r={innerRadius} fill="white" />
            </g>
        </svg>
    );
};

export default DonutChart;
