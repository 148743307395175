import React from 'react';
import { colors } from '../../../lib/colors';
import { Col, Row } from '../../../lib/utils';
import ProfileImage from '../../ProfileImage';
import { Body3Regular, CaptionRegular } from '../../../lib/font';
import styled from 'styled-components';
import Dot from '../../Dot';
import dayjs from 'dayjs';

const StyledButton = styled.button<{ selected: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 378px;
    align-items: center;
    align-self: center;
    background-color: ${({ selected }) => (selected ? '#E9F1FF' : '#FDFEFF')};
    border-width: 1px 0px 1px 0px;
    border-style: solid;
    border-color: #e9f1ff;
    padding-left: 16px;
    padding-right: 24px;
    z-index: 1;

    &:hover {
        background-color: #f5f9ff;
    }
`;

const CoachButton = ({ coachId, coachItem, onClickCoach, memberList }: any) => {
    const totalMemberCnt = memberList?.length;
    const activeMemberList = memberList.filter((item: any) => item.status === 'active');
    const coachScheduleReportCount = coachItem?.coachScheduleReportCount;
    const coachScheduleReportManagedMemberCount = coachItem?.coachScheduleReportManagedMemberCount;
    const coachScheduleReportLastDateString = coachItem?.coachScheduleReportLastDateString;
    // 몇일전 마지막 작성일
    const lastDate = dayjs().diff(dayjs(coachScheduleReportLastDateString), 'day');

    return (
        <StyledButton onClick={() => onClickCoach(coachItem.coachId)} selected={coachId === coachItem.coachId}>
            <Row className=" items-center py-[12px]">
                <ProfileImage
                    imageUrl={coachItem?.profileImageUrl}
                    type="Small"
                    profileName={coachItem?.profileName}
                    profileColorType={coachItem?.profileColorType}
                    right={16}
                />
                <Col style={{ alignItems: 'flex-start' }}>
                    <Body3Regular
                        style={{ color: colors.LAVEL_3, marginBottom: 4 }}
                    >{`${coachItem?.coachName} 강사`}</Body3Regular>
                    <CaptionRegular style={{ color: colors.LAVEL_2 }}>
                        {`일지 관리 회원 : ${coachScheduleReportManagedMemberCount}명`}
                    </CaptionRegular>
                    <Row className=" items-center">
                        <CaptionRegular style={{ color: colors.LAVEL_2 }}>
                            {`일지 작성 : ${coachScheduleReportCount}개`}
                        </CaptionRegular>
                        <Dot size={2} color={'LAVEL_3'} className=" mx-[8px]" />
                        <CaptionRegular style={{ color: colors.LAVEL_2 }}>{`${
                            lastDate ? lastDate + '일 전' : '없음'
                        }`}</CaptionRegular>
                    </Row>
                </Col>
            </Row>

            <div
                style={{
                    width: 16,
                    height: 16,
                    borderRadius: 12,
                    border: '1px solid #A6BFEF',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {coachId === coachItem.coachId && (
                    <div
                        style={{
                            width: 10,
                            height: 10,
                            borderRadius: 9,
                            backgroundColor: colors.primary,
                        }}
                    />
                )}
            </div>
        </StyledButton>
    );
};

export default CoachButton;
