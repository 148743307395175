import styled, { keyframes } from 'styled-components';
import { Col, Row, truncateString } from '../../lib/utils';
import { colors } from '../../lib/colors';
import { Body1Regular, Body2Regular, Subtitle1Bold, Subtitle1Regular } from '../../lib/font';
import dayjs from 'dayjs';
import { useRecoilState } from 'recoil';
import { sidebarStatusState } from '../../atoms/atom';

// 모달 컴포넌트 스타일드
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// 모달 스타일드 컴포넌트
const HoverScheduleContainer = styled.div`
    position: absolute;
    width: 340px;
    height: 136px;
    border-radius: 8px;
    background-color: rgba(253, 254, 255, 1);
    border: none;
    box-shadow: 0px 0px 8px 2px rgba(24, 62, 134, 0.28);
    animation: ${fadeIn} 1s ease; /* 애니메이션 적용 */
    z-index: 9999;
`;

const HoverEtcContainer = styled.div`
    position: absolute;
    width: 340px;
    height: 192px;
    border-radius: 8px;
    background-color: rgba(253, 254, 255, 1);
    border: none;
    box-shadow: 0px 0px 8px 2px rgba(24, 62, 134, 0.28);
    animation: ${fadeIn} 1s ease; /* 애니메이션 적용 */
    z-index: 9999;
`;

const WeekHoverItem = ({ childItem, cellIndex }: any) => {
    const [sidebarStatus, setSidebarStatus] = useRecoilState(sidebarStatusState);

    const isBeforeCurrentTime = dayjs().isBefore(childItem?.startTime);
    const isGoingSchedule =
        dayjs().format('YYYY-MM-DD HH') === dayjs(childItem?.startTime).format('YYYY-MM-DD HH') ? true : false;
    const type = childItem?.type;

    const marginLeftStyle = sidebarStatus === 'open' ? (cellIndex > 4 ? -360 : 160) : cellIndex > 4 ? -360 : 190;

    return (
        <>
            {type === 'schedule' ? (
                <HoverScheduleContainer
                    style={{
                        marginTop: parseInt(dayjs(childItem.startTime).format('H')) > 20 ? -34 : -10,
                        marginLeft: marginLeftStyle,
                    }}
                >
                    <Row
                        style={{
                            width: 340,
                            height: 52,
                            borderTopRightRadius: 8,
                            borderTopLeftRadius: 8,
                            backgroundColor: colors.primary,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            paddingLeft: 24,
                            paddingRight: 24,
                        }}
                    >
                        <Subtitle1Bold style={{ color: colors.WHITE_50 }}>
                            {dayjs(childItem?.startTime).format('MM월 DD일(ddd)')}
                        </Subtitle1Bold>

                        <Row
                            style={{
                                borderRadius: 34,
                                border: isGoingSchedule
                                    ? '1px solid rgba(255, 217, 119, 1)'
                                    : '1px solid rgba(253, 254, 255, 1)',
                                paddingLeft: 10,
                                paddingRight: 10,
                                backgroundColor: colors.PRIMARY_BLUE,
                            }}
                        >
                            <Body2Regular style={{ color: isGoingSchedule ? colors.secondary : colors.WHITE_50 }}>
                                {`${isGoingSchedule ? '수업 중' : !isBeforeCurrentTime ? '수업 완료' : '수업 예정'}`}
                            </Body2Regular>
                        </Row>
                    </Row>

                    <Row
                        style={{
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            paddingLeft: 24,
                            paddingRight: 24,
                            marginTop: 8,
                        }}
                    >
                        <Subtitle1Bold style={{ color: colors.BLUE_900 }}>
                            {`${childItem?.coachName} 강사`}{' '}
                        </Subtitle1Bold>
                        <Subtitle1Bold style={{ color: colors.BLUE_900 }}>{`${childItem?.alias} 회원`} </Subtitle1Bold>
                    </Row>

                    <Row
                        style={{
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            paddingLeft: 24,
                            paddingRight: 24,
                            marginTop: 8,
                        }}
                    >
                        <Body1Regular style={{ color: colors.WHITE_900 }}>
                            {dayjs(childItem.startTime).format('A h:mm ~ ')}
                            {dayjs(childItem.endTime).format('h:mm')}
                        </Body1Regular>
                        <Body1Regular style={{ color: colors.WHITE_900 }}>
                            {childItem?.currentSession ? childItem?.currentSession + ' / ' : '? / '}
                            {childItem?.totalSession ? childItem?.totalSession : '?'}
                        </Body1Regular>
                    </Row>
                </HoverScheduleContainer>
            ) : (
                <HoverEtcContainer
                    style={{
                        marginTop: parseInt(dayjs(childItem.startTime).format('H')) > 20 ? -110 : -10,
                        marginLeft: marginLeftStyle,
                    }}
                >
                    <Row
                        style={{
                            width: 340,
                            height: 52,
                            borderTopRightRadius: 8,
                            borderTopLeftRadius: 8,
                            backgroundColor: colors.PINK,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            paddingLeft: 24,
                            paddingRight: 24,
                        }}
                    >
                        <Subtitle1Bold style={{ color: colors.WHITE_50 }}>
                            {dayjs(childItem?.startTime).format('MM월 DD일(ddd)')}
                        </Subtitle1Bold>

                        <Row
                            style={{
                                borderRadius: 34,
                                borderWidth: 1,
                                borderColor: colors.WHITE_50,
                                paddingLeft: 10,
                                paddingRight: 10,
                                backgroundColor: colors.PINK,

                                zIndex: 1,
                            }}
                        >
                            <Body2Regular style={{ color: colors.WHITE_50 }}>{'일반 일정'}</Body2Regular>
                        </Row>
                    </Row>

                    <Col
                        style={{
                            paddingLeft: 24,
                            paddingRight: 24,
                            paddingTop: 8,
                            paddingBottom: 16,
                        }}
                    >
                        <Subtitle1Bold
                            style={{ color: colors.BLUE_900 }}
                        >{`${childItem?.coachName} 강사`}</Subtitle1Bold>
                        <Col style={{ width: 292, height: 56, overflowWrap: 'break-word', justifyContent: 'center' }}>
                            <Subtitle1Regular style={{ color: colors.BLUE_900 }}>
                                {truncateString(childItem?.name, 40)}
                            </Subtitle1Regular>
                        </Col>

                        <Body1Regular style={{ color: colors.WHITE_900 }}>
                            {dayjs(childItem?.startTime).format('A h:mm ~ ')}
                            {dayjs(childItem?.endTime).format('h:mm')}
                        </Body1Regular>
                    </Col>
                </HoverEtcContainer>
            )}
        </>
    );
};

export default WeekHoverItem;
