import { Col, Row } from '../../lib/utils';
import { colors } from '../../lib/colors';
import { Body2Bold, Body2Light, Head4 } from '../../lib/font';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
dayjs.locale('ko');

const WeeklyScheduleGridHeader = ({ scheduleList, setIsCalendar, selectedDay, sidebarStatus }: any) => {
    const arrayWithIndex = Array.from({ length: 7 }, (_, index) => index);
    const baseDate = dayjs(selectedDay);
    const startWeekNumberDate = baseDate.startOf('month').startOf('week');
    const endWeekNumberDate = baseDate.startOf('week');
    let weekDiff = endWeekNumberDate.diff(startWeekNumberDate, 'day', true);
    let weekNumber = Math.floor(weekDiff) / 7 + 1; // 0주차는 존재할 수 없으니 1을 더해줌

    return (
        <Row
            style={{
                boxShadow: '0px 4px 24px 0px #00000014',
                borderTopLeftRadius: 24,
                borderTopRightRadius: 24,
                alignItems: 'center',
                height: 160,
            }}
        >
            <Row style={{ alignItems: 'center' }}>
                <button
                    style={{
                        width: 140,
                        height: 160,
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 10,
                        paddingLeft: 15,
                    }}
                    onClick={() => setIsCalendar(true)}
                >
                    <Col
                        style={{
                            width: 100,
                            height: 88,
                            borderRadius: 24,
                            backgroundColor: colors.WHITE_50,
                            boxShadow: '1px 1px 8px 0px #2B529D2E',
                        }}
                    >
                        <Row
                            style={{
                                width: 100,
                                height: 34,
                                boxShadow: '1px 1px 8px 0px #2B529D2E',
                                borderTopLeftRadius: 24,
                                borderTopRightRadius: 24,
                                justifyContent: 'center',
                                paddingTop: 8,
                            }}
                        >
                            <Body2Bold style={{ color: colors.BLUE_700 }}>
                                {dayjs(selectedDay).format('MM월')}
                            </Body2Bold>
                        </Row>
                        <Head4 style={{ color: colors.BLUE_700, textAlign: 'center', marginTop: 6 }}>
                            {weekNumber}주차
                        </Head4>
                    </Col>
                </button>

                <hr
                    style={{
                        width: 2,
                        height: 48,
                        backgroundColor: colors.WHITE_500,
                    }}
                />
            </Row>

            {scheduleList &&
                scheduleList.map((item: any, index: number) => {
                    return (
                        <Row style={{ height: 160, alignItems: 'center' }} key={index}>
                            <Col
                                style={{
                                    width: sidebarStatus === 'fold' ? 210 : 170.2,
                                    height: 160,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Col
                                    style={{
                                        width: 100,
                                        height: 88,
                                        backgroundColor:
                                            dayjs().format('ddd') === item.daysOfWeek &&
                                            dayjs().format('D일') === item.day
                                                ? colors.WHITE_50
                                                : colors.WHITE_200,
                                        borderRadius: 24,
                                        borderWidth:
                                            dayjs().format('ddd') === item.daysOfWeek &&
                                            dayjs().format('D일') === item.day
                                                ? 2
                                                : 0,
                                        borderColor: colors.PRIMARY_BLUE,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {index === 0 || index === 6 ? (
                                        <>
                                            <Head4 style={{ color: colors.ERROR }}>{item.daysOfWeek}</Head4>
                                            <Body2Light style={{ color: colors.WHITE_800 }}>{item.day}</Body2Light>
                                        </>
                                    ) : (
                                        <>
                                            <Head4 style={{ color: colors.BLUE_700 }}>{item.daysOfWeek}</Head4>
                                            <Body2Bold style={{ color: colors.WHITE_900 }}>{item.day}</Body2Bold>
                                        </>
                                    )}
                                </Col>
                            </Col>
                            {index < 6 && (
                                <hr
                                    style={{
                                        width: 3,
                                        height: 48,
                                        backgroundColor: colors.WHITE_500,
                                    }}
                                />
                            )}
                        </Row>
                    );
                })}
        </Row>
    );
};

export default WeeklyScheduleGridHeader;
