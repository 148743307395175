import React from 'react';
import { Col, Row, convertDate } from '../../../lib/utils';
import { Body1Bold, Body1Regular, Body2Bold, Body2Regular, Body3Regular, Subtitle1Bold } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import { I_EMPTY_CONDITION } from '../../../types/images';
import { CONDITION_TYPE } from '../../../lib/varialbe';
import ProfileImage from '../../ProfileImage';

const BasicInfo = ({ scheduleReport, receivers }: any) => {
    const scheduleInfo = scheduleReport?.scheduleInfo;
    const condition = scheduleReport?.condition;
    const currentSession = scheduleInfo?.currentSession ? scheduleInfo?.currentSession : '?';
    const totalSession = scheduleInfo?.totalSession ? scheduleInfo?.totalSession : '?';

    let conditionText = condition
        ? CONDITION_TYPE.find((item) => item.code === condition.todayCondition)?.text
        : '기록 안함';

    let conditionImg = condition
        ? CONDITION_TYPE.find((item) => item.code === condition.todayCondition)?.src
        : I_EMPTY_CONDITION;

    return (
        <>
            <Row>
                <ProfileImage
                    imageUrl={receivers?.profileImageUrl}
                    type="Small"
                    profileName={receivers?.profileName}
                    profileColorType={receivers?.profileColorType}
                />
                <Col style={{ marginLeft: 12 }}>
                    <Body1Bold style={{ color: colors.LAVEL_3 }}>{`${receivers?.receiverName} 회원`}</Body1Bold>
                    <Body3Regular
                        style={{ color: colors.LAVEL_3, marginTop: 8 }}
                    >{`수강권 : ${scheduleInfo?.membershipName}`}</Body3Regular>
                    <Body3Regular
                        style={{ color: colors.LAVEL_3 }}
                    >{`회차 : ${currentSession} / ${totalSession}회차`}</Body3Regular>
                </Col>
            </Row>

            <Row
                style={{ width: 752, height: 1, backgroundColor: colors.WHITE_600, marginTop: 24, marginBottom: 24 }}
            />

            {/* 수업 시간, 회원권 */}
            <Row style={{ alignItems: 'center' }}>
                <Body2Bold style={{ color: colors.LAVEL_3 }}>{'수업 날짜 : '}</Body2Bold>
                <Body2Regular style={{ color: colors.LAVEL_2, marginLeft: 8 }}>{`${convertDate(
                    scheduleInfo?.startTime,
                    'YY/MM/DD(ddd)'
                )}`}</Body2Regular>

                <Body2Bold style={{ color: colors.LAVEL_3, marginLeft: 64 }}>{'수업시간 : '}</Body2Bold>
                <Body2Regular style={{ color: colors.LAVEL_2, marginLeft: 8 }}>{`${convertDate(
                    scheduleInfo?.startTime,
                    'YY/MM/DD(ddd)/AHH:mm'
                )} ~ ${convertDate(scheduleInfo?.endTime, 'HH:mm')}`}</Body2Regular>
            </Row>

            {/* 전체적인 컨디션 */}
            <Row style={{ alignItems: 'center', marginTop: 16 }}>
                <Body2Bold style={{ color: colors.LAVEL_3 }}>{'전체적인 컨디션 :'}</Body2Bold>
                <Body2Regular style={{ color: colors.LAVEL_2, marginLeft: 8, marginRight: 8 }}>
                    {conditionText}
                </Body2Regular>
                <img src={conditionImg} style={{ width: 24, height: 24 }} />
            </Row>
            {/* 수면 시간, 이전 체중, 현재 체중 */}
            <Row style={{ alignItems: 'center', marginTop: 16 }}>
                <Body2Bold style={{ color: colors.LAVEL_3 }}>{'수면 시간 :'}</Body2Bold>
                <Body2Regular style={{ color: colors.LAVEL_2, marginLeft: 8, marginRight: 36 }}>
                    {`${
                        condition?.sleepTime
                            ? condition?.sleepTime === 0
                                ? '기록 안함'
                                : condition?.sleepTime + '시간'
                            : '기록 안함'
                    } `}
                </Body2Regular>
                <Body2Bold style={{ color: colors.LAVEL_3 }}>{'이전 체중 :'}</Body2Bold>

                <Body2Regular style={{ color: colors.LAVEL_2, marginLeft: 8, marginRight: 36 }}>
                    {`${
                        condition?.previousWeight
                            ? condition?.previousWeight === 0
                                ? '기록 안함'
                                : condition?.previousWeight + 'kg'
                            : '기록 안함'
                    } `}
                </Body2Regular>
                <Body2Bold style={{ color: colors.LAVEL_3 }}>{`현재 체중 :`}</Body2Bold>

                <Body2Regular style={{ color: colors.LAVEL_2, marginLeft: 8, marginRight: 36 }}>
                    {`${
                        condition?.weight
                            ? condition?.weight === 0
                                ? '기록 안함'
                                : condition?.weight + 'kg'
                            : '기록 안함'
                    } `}
                </Body2Regular>
            </Row>
        </>
    );
};

export default BasicInfo;
