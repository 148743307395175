import { Dispatch, SetStateAction } from 'react';
import { Body1Regular, Body2Regular, Subtitle1Bold } from '../../lib/font';
import { colors } from '../../lib/colors';
import NormalButton from '../Button/NormalButton';
import styled from 'styled-components';
import { I_ALERT, I_FILL_ALERT } from '../../types/images';
import { Col } from '../../lib/utils';
import { useLocation } from 'react-router-dom';
import ColorSquareButton from '../Button/ColorSquareButton';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2002;
`;

const ModalInner = styled.div`
    background: white;
    width: 540px;
    height: 278px;
    border-radius: 8px;
    box-shadow: 8px 8px 24px 0px #00000052;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 36px;
`;

type Props = {
    modalVisible: boolean;
    setModalVisible: Dispatch<SetStateAction<boolean>>;
    navigate: any;
    content?: string;
};

const ModalFail = ({ modalVisible, setModalVisible, navigate, content }: Props) => {
    const location = useLocation();
    return (
        <ModalOverLay>
            <ModalInner>
                <img
                    src={I_FILL_ALERT}
                    style={{
                        width: 24,
                        height: 24,
                        marginBottom: 8,
                    }}
                />

                <Subtitle1Bold
                    style={{
                        color: colors.LAVEL_4,
                        whiteSpace: 'pre-wrap',
                        textAlign: 'center',
                    }}
                >
                    카드 등록 실패
                </Subtitle1Bold>
                <Body2Regular
                    style={{
                        color: colors.LAVEL_4,
                        whiteSpace: 'pre-wrap',
                        textAlign: 'center',
                        marginTop: 32,
                        marginBottom: 32,
                    }}
                >
                    {content}
                </Body2Regular>

                <ColorSquareButton
                    text="재입력 하기"
                    size="middle"
                    callBack={() => {
                        setModalVisible(false);

                        location.pathname.includes('expired')
                            ? navigate('/expired')
                            : navigate('/myPage/SubscriptionPlanPage');
                    }}
                    isVisibleLeftImg={false}
                    isVisibleRightImg={false}
                />
            </ModalInner>
        </ModalOverLay>
    );
};

export default ModalFail;
