import React, { useState } from 'react';
import { colors } from '../../lib/colors';
import { Body3Light, Body3Regular } from '../../lib/font';
import { Col, Row } from '../../lib/utils';
import styled from 'styled-components';
import WhiteRoundButton from '../Button/WhiteRoundButton';
import { I_LOCK_MASKING, I_UNLOCK_MASKING } from '../../types/images';
import ColorRoundButton from '../Button/ColorRoundButton';
import TextBlueButton from '../TextButton/TextBlueButton';

// StyledInput 컴포넌트 생성
const StyledIdInput = styled.input`
    width: 31.2rem;
    height: 4.8rem;
    font-size: 1.8rem;
    padding-left: 1.6rem;
    border-bottom: 1px solid #a6bfef;
    color: #353535;
    ::placeholder {
        color: #acacac;
    }
`;

// StyledInput 컴포넌트 생성
const StyledPasswordInput = styled.input`
    width: 100%;
    height: 4.8rem;
    font-size: 1.8rem;
    padding-left: 1.6rem;
    border-bottom: 1px solid #a6bfef;
    color: #353535;
    ::placeholder {
        color: #acacac;
    }
`;

const StyledButton = styled.button`
    width: 2.4rem;
    height: 2.4rem;
    background: none;
    border: none;
    position: absolute;
    margin-left: 40rem;
    cursor: pointer;
    opacity: 0.8; /* 기본 투명도 설정 */

    /* 호버 시 투명도 변경 */
    &:hover {
        opacity: 1;
    }

    /* 클릭 시 투명도 변경 */
    &:active {
        opacity: 0.6;
    }
`;

interface I_Props {
    register: any;
    getValues: any;
    errors: any;
    watch: any;
    isCheckDuplicated: boolean;
    checkDuplicatedId: any;
    onClickNextStep: () => void;
    onClickLoginPage: () => void;
}

const SignUpBody1 = ({
    register,
    getValues,
    errors,
    watch,
    isCheckDuplicated,
    checkDuplicatedId,
    onClickNextStep,
    onClickLoginPage,
}: I_Props) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState<boolean>(false);

    const onClickToggleMasking = () => {
        setShowPassword(!showPassword);
    };

    const onClickToggleConfirmMasking = () => {
        setShowPasswordConfirm(!showPasswordConfirm);
    };

    return (
        <Col>
            <Col style={{ width: '44rem', marginTop: 48 }}>
                <label htmlFor="adminId" style={{ display: 'flex', marginBottom: 8 }}>
                    <Body3Regular>아이디</Body3Regular>
                    <Body3Regular style={{ color: colors.ERROR }}>*</Body3Regular>
                </label>
                <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <StyledIdInput
                        type="text"
                        {...register('adminId', {
                            required: true,
                            minLength: { value: 4, message: '영문과 숫자를 조합하여 4글자 이상으로 입력해주세요!' },
                            maxLength: { value: 20, message: '아이디는 20자를 초과할수 없습니다.' },
                        })}
                        placeholder="아이디를 입력해주세요!"
                        autoComplete="off"
                    />
                    <WhiteRoundButton
                        isDisabled={watch('adminId').length < 4 || isCheckDuplicated}
                        callBack={checkDuplicatedId}
                        size="middle"
                        text={'중복 확인'}
                        isVisibleLeftImg={false}
                        isVisibleRightImg={false}
                        type="button"
                    />
                </Row>

                {isCheckDuplicated ? (
                    <Body3Light style={{ marginLeft: 16, marginTop: 8, color: colors.primary }}>
                        {'중복체크를 완료하였습니다.'}
                    </Body3Light>
                ) : watch('adminId').length === 0 ? (
                    <Body3Light style={{ marginLeft: 16, marginTop: 8, color: colors.WHITE_800 }}>
                        아이디는 추후 바꿀수 없으니, 신중하게 작성해주세요!
                    </Body3Light>
                ) : errors.adminId ? (
                    <Body3Light style={{ marginLeft: 16, marginTop: 8, color: colors.LAVEL_1 }}>
                        {errors.adminId.message}
                    </Body3Light>
                ) : (
                    watch('adminId').length !== 0 && (
                        <Body3Light style={{ marginLeft: 16, marginTop: 8, color: colors.WHITE_50 }}>
                            {'dummy'}
                        </Body3Light>
                    )
                )}
            </Col>

            <Col style={{ width: '44rem', marginTop: 48 }}>
                <label htmlFor="password" style={{ display: 'flex', marginBottom: 8 }}>
                    <Body3Regular>비밀번호</Body3Regular>
                    <Body3Regular style={{ color: colors.ERROR }}>*</Body3Regular>
                </label>

                <Row style={{ alignItems: 'center' }}>
                    <StyledPasswordInput
                        {...register('password', {
                            required: '비밀번호를 입력해주세요',
                            pattern: {
                                value: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/,
                                message: '영문, 숫자, 특수문자 포함 8 ~ 20자로 입력해주세요',
                            },
                        })}
                        type={showPassword ? 'text' : 'password'}
                        minLength={8}
                        maxLength={20}
                        placeholder="비밀번호를 입력해주세요!"
                        autoComplete="new-password"
                    />

                    {watch('password').length !== 0 && (
                        <StyledButton type="button" onClick={onClickToggleMasking}>
                            <img src={showPassword ? I_LOCK_MASKING : I_UNLOCK_MASKING} alt="Button Icon" />
                        </StyledButton>
                    )}
                </Row>

                {errors.password ? (
                    <Body3Light style={{ marginLeft: 16, marginTop: 8, color: colors.LAVEL_1 }}>
                        {errors.password.message}
                    </Body3Light>
                ) : (
                    <Body3Light style={{ marginLeft: 16, marginTop: 8, color: colors.WHITE_50 }}>{'dummy'}</Body3Light>
                )}
            </Col>

            <Col style={{ width: '44rem', marginTop: 48 }}>
                <label htmlFor="passwordConfirm" style={{ display: 'flex', marginBottom: 8 }}>
                    <Body3Regular>비밀번호 확인</Body3Regular>
                    <Body3Regular style={{ color: colors.ERROR }}>*</Body3Regular>
                </label>
                <Row style={{ alignItems: 'center' }}>
                    <StyledPasswordInput
                        {...register('passwordConfirm', {
                            required: '* 비밀번호를 입력해주세요',
                            pattern: {
                                value: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/,
                                message: '영문, 숫자, 특수문자 포함 8 ~ 20자로 입력해주세요',
                            },
                            validate: {
                                check: (val: any) => {
                                    if (getValues('password') !== val) {
                                        return '비밀번호가 일치하지 않습니다.';
                                    }
                                },
                            },
                        })}
                        type={showPasswordConfirm ? 'text' : 'password'}
                        minLength={8}
                        maxLength={20}
                        placeholder="비밀번호를 한 번  더 입력해주세요!"
                    />
                    {watch('passwordConfirm').length !== 0 && (
                        <StyledButton type="button" onClick={onClickToggleConfirmMasking}>
                            <img src={showPassword ? I_LOCK_MASKING : I_UNLOCK_MASKING} alt="Button Icon" />
                        </StyledButton>
                    )}
                </Row>

                {errors.passwordConfirm ? (
                    <Body3Light style={{ marginLeft: 16, marginTop: 6, color: colors.red }}>
                        {errors.passwordConfirm.message}
                    </Body3Light>
                ) : (
                    <Body3Light style={{ marginLeft: 16, marginTop: 6, color: colors.WHITE_50 }}>{'ddd'}</Body3Light>
                )}
            </Col>

            <Col style={{ marginBottom: 300 }}>
                <ColorRoundButton
                    size="middle"
                    text="다음"
                    isVisibleLeftImg={false}
                    isVisibleRightImg={false}
                    customStyle={{
                        width: '44rem',
                        height: 48,
                        marginTop: 32,
                        marginBottom: 32,
                    }}
                    callBack={onClickNextStep}
                    type="button"
                />
                <TextBlueButton text="로그인 페이지 돌아가기" size="middle" callBack={onClickLoginPage} />
            </Col>
        </Col>
    );
};

export default SignUpBody1;
