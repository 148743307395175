import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import Modal from '../modal/Modal';
import { Body3Regular, CaptionRegular, Subtitle1Bold } from '../../lib/font';
import { Col, getItemWithExpiration, Row } from '../../lib/utils';
import FormTextInput from '../InputBox /FormTextInput';
import MemoTextInput from '../TextInput/MemoTextInput';
import NewTextInputDropDown from '../DropDown/NewTextInputDropDown';
import { CodeValueType } from '../../types/types';
import NewColorSquareButton from '../Button/NewColorSquareButton';
import useCenterMutationService from '../../services/useCenterMutationService';
import { MemberProfile } from '../../services/useMemberQueryService';
import dayjs from 'dayjs';
import { DATE_REGEXP, GENDER_TYPE, GENDERCODETYPE, PHONE_NUMBER_REGEXP } from '../../lib/varialbe';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    createAlert: any;
    memberId: string;
    memberProfile: MemberProfile;
};

const ModalMemberProfileChange = ({ setIsVisible, createAlert, memberId, memberProfile }: Props) => {
    const queryClient = useQueryClient();

    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const { patchCenterUpdateMemberMutation } = useCenterMutationService({
        onPatchCenterUpdateMemberSuccess(data) {
            setIsVisible(false);
            queryClient.invalidateQueries({ queryKey: ['getCenterMemberList'] });
            createAlert('프로필 수정 완료', '프로필 수정을 완료하였습니다');
        },
    });
    // 회원이름
    const [memberName, setMemberName] = useState(memberProfile?.name);
    // 휴대폰번호
    const [phoneNumber, setPhoneNumber] = useState(memberProfile?.phoneNumber);
    // 생년월일
    const [birthDay, setBirthDay] = useState(
        memberProfile?.birthday ? dayjs(memberProfile?.birthday).format('YYYY-MM-DD') : ''
    );
    // 성별
    const [gender, setGender] = useState<CodeValueType<GENDERCODETYPE>>(
        memberProfile?.gender ? (memberProfile.gender === 'male' ? GENDER_TYPE[0] : GENDER_TYPE[1]) : GENDER_TYPE[2]
    );
    // 주소
    const [address, setAddress] = useState(memberProfile?.address);
    // 메모
    const [memo, setMemo] = useState(memberProfile?.memo);

    // // 생년월일 변경
    // const onChangeBirthDay = (e: ChangeEvent<HTMLInputElement>) => {
    //     const currentBirthDay = e.target.value;
    //     const regex = /^[0-9\b -]{0,10}$/;
    //     if (regex.test(currentBirthDay)) {
    //         setBirthDay(currentBirthDay);
    //     }
    // };
    // 생년월일 '-'추가 정제로직
    useEffect(() => {
        if (birthDay) {
            if (birthDay.length === 8) {
                setBirthDay(birthDay.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'));
            }

            if (birthDay.length === 9) {
                setBirthDay(birthDay.replace(/-/g, ''));
            }
        }
    }, [birthDay]);

    const onClickUpdateButton = () => {
        // 휴대폰번호를 적었을때 '-' 정규식 검사
        if (phoneNumber && !PHONE_NUMBER_REGEXP.test(phoneNumber)) {
            // createAlert('삭제 완료', '해당 트레이너 삭제를 완료하였습니다');
            createAlert('휴대폰번호를 정확히 입력해주세요', '휴대폰번호를 정확히 입력해주세요', true);

            return;
        }
        // 생년월일 '-' 정규식 검사
        if (birthDay && !DATE_REGEXP.test(birthDay)) {
            createAlert('생년월일을 정확히 입력해주세요', '생년월일을 정확히 입력해주세요', true);
            return;
        }
        patchCenterUpdateMemberMutation.mutate({
            centerId: authObject.centerId, //centerId:
            memberId: memberId, //memberId:
            name: memberName, //name:
            gender: gender?.code, //gender:
            birthday: birthDay, //birthday:
            phoneNumber: phoneNumber, //phoneNumber:
            memo: memo, //memo:
            address: address, //address:
            // newCoachId: coachName?.code, //newCoachId:
            accessToken: authorization, //accessToken:
        });
    };

    return (
        <Modal
            whiteViewHeight={856}
            whiteViewWidth={1000}
            setIsVisible={setIsVisible}
            isCloseButton
            onClose={() => {
                setIsVisible(false);
            }}
            isCloseOutside
        >
            <Col className="py-[36px] px-[48px]">
                <Subtitle1Bold className="mb-[48px]">프로필 편집하기</Subtitle1Bold>
                <Col className="pl-[32px]">
                    {/* 회원이름 ,휴대폰번호 */}
                    <Row className="mb-[68px]">
                        <FormTextInput
                            title="회원이름"
                            placeholder="회원이름을 입력해주세요"
                            value={memberName}
                            onChangeText={setMemberName}
                            isRequired
                            width={300}
                            mr={120}
                        />
                        <FormTextInput
                            title="휴대폰번호"
                            placeholder="010-1234-5678"
                            value={phoneNumber}
                            onChangeText={setPhoneNumber}
                            isRequired
                            width={300}
                            subTitle="010을 포함한 11글자를 입력해주세요!"
                            subtitlePosition="right"
                        />
                    </Row>
                    {/* 생년월일 , 성별 */}

                    <Row className="mb-[68px]">
                        <FormTextInput
                            title="생년월일"
                            placeholder="2000-01-01"
                            value={birthDay}
                            onChangeText={setBirthDay}
                            width={300}
                            mr={120}
                        />
                        <Col>
                            <Body3Regular className=" text-LAVEL_4 mr-[4px] mb-[12px]">{'성별'}</Body3Regular>
                            <NewTextInputDropDown
                                datas={GENDER_TYPE}
                                selectData={gender}
                                onClickSort={(item) => {
                                    setGender(item);
                                }}
                                fontType="Body2Regular"
                                width={300}
                            />
                        </Col>
                    </Row>
                    {/* 주소 */}
                    <FormTextInput
                        title="주소"
                        placeholder="주소를 입력해주세요"
                        value={address}
                        onChangeText={setAddress}
                        width={720}
                        mb={68}
                    />
                    {/* 메모 */}
                    <Row className="flex items-center mb-[8px]">
                        <Body3Regular className="text-LAVEL_4 mr-[12px]">메모</Body3Regular>
                        <CaptionRegular className=" text-LAVEL_2">
                            {'관리자님과 담당 강사님만 확인할 수 있는 정보에요! '}
                        </CaptionRegular>
                    </Row>
                    <MemoTextInput
                        value={memo}
                        onChange={(e) => setMemo(e.target.value)}
                        placeholder="메모를 입력해주세요"
                        width={600}
                        height={136}
                    />
                </Col>
            </Col>
            <NewColorSquareButton
                text="편집 완료"
                className=" absolute right-[36px] bottom-[36px] "
                width={148}
                height={48}
                onClick={onClickUpdateButton}
            />
        </Modal>
    );
};

export default ModalMemberProfileChange;
