import { colors } from '../lib/colors';
import { Head3, Head4, Subtitle1Regular } from '../lib/font';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { Row } from '../lib/utils';
import { useRecoilState } from 'recoil';
import { sidebarStatusState } from '../atoms/atom';
import styled from 'styled-components';
dayjs.locale('ko');
const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    transition: all 0.5s ease;
`;

type Props = {
    title: string;
    subTitle?: string | null;
};
const Header = ({ title, subTitle }: Props) => {
    const [sidebarStatus, setSidebarStatus] = useRecoilState(sidebarStatusState);
    return (
        <HeaderContainer
            style={{
                width: sidebarStatus === 'open' ? 1356 : 1632,
                height: 68,
                borderRadius: 48,
                borderWidth: 2,
                borderColor: colors.PRIMARY_BLUE,
                marginTop: 68,
            }}
        >
            <Row
                style={{
                    alignItems: 'center',
                }}
            >
                <Row
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: sidebarStatus === 'open' ? 260 : 312.92,
                        height: 68,
                        borderRadius: 48,
                        backgroundColor: colors.PRIMARY_BLUE,
                    }}
                >
                    <Head3 style={{ color: colors.WHITE_50 }}>{title}</Head3>
                </Row>

                {subTitle && (
                    <Head4
                        style={{
                            paddingLeft: sidebarStatus === 'open' ? 24 : 28,
                            color: colors.PRIMARY_BLUE,
                        }}
                    >
                        {subTitle}
                    </Head4>
                )}
            </Row>
            <Subtitle1Regular style={{ marginRight: sidebarStatus === 'open' ? 46 : 71, color: colors.BLUE_600 }}>
                {dayjs().format('YYYY년 MM월 DD일 (ddd)')}
            </Subtitle1Regular>
        </HeaderContainer>
    );
};

export default Header;
