import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Body3Regular } from '../../lib/font';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
type ButtonProps = {
    isClick: boolean;
};

const ButtonContainer = styled.div`
    width: 266px;
    height: 32px;
    border-radius: 4px;
    background-color: #edf4ff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* padding-left: 12px;
    padding-right: 12px; */
`;

const Button = styled.button<ButtonProps>`
    width: 86px;
    height: 32px;
    border-radius: 2px;
    background-color: ${(props) => (props.isClick ? '#FDFEFF' : '#edf4ff')};
    font-size: 16px;
    font-weight: ${(props) => (props.isClick ? 400 : 300)};
    animation: ${fadeIn} 1s ease; /* 애니메이션 적용 */
    transition: background-color 0.3s ease, color 0.3s ease, font-weight 0.3s ease, border 0.3s ease, color 0.3s ease;
    border: ${(props) => (props.isClick ? '1px solid #3d73dd' : '')};
    color: #3d73dd;
`;

type Props = {
    isFirst: boolean;
    isSecond: boolean;
    isThird: boolean;
    firstText: string;
    secondText: string;
    thirdText: string;
    firstCallBack: () => void;
    secondCallBack: () => void;
    thirdCallBack: () => void;
};

const ThreeButton = ({
    isFirst,
    isSecond,
    isThird,
    firstText,
    secondText,
    thirdText,
    firstCallBack,
    secondCallBack,
    thirdCallBack,
}: Props) => {
    return (
        <ButtonContainer>
            <Button onClick={firstCallBack} isClick={isFirst}>
                <span>{firstText}</span>
            </Button>
            <Button onClick={secondCallBack} isClick={isSecond}>
                <span>{secondText}</span>
            </Button>
            <Button onClick={thirdCallBack} isClick={isThird}>
                <span>{thirdText}</span>
            </Button>
        </ButtonContainer>
    );
};

export default ThreeButton;
