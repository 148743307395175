import React, { useEffect, useState } from 'react';
import ScheduleHistoryListHeader from './ScheduleHistoryListHeader';
import dayjs from 'dayjs';
import ScheduleHistoryThisDayList from './ScheduleHistoryThisDayList';
import { useQuery } from '@tanstack/react-query';
import { getCancelAndChangeListAPI } from '../../../api/schedule';
import ScheduleHistoryThisWeekList from './ScheduleHistoryThisWeekList';
import ScheduleHistoryThisMonthList from './ScheduleHistoryThisMonthList';
import { Col, getCurrentDayWeekNumber } from '../../../lib/utils';
import { scheduleHistoryCancelAndChangeDummyData } from '../../../lib/dummyData';
import { colors } from '../../../lib/colors';
import TopTable from './TopTable';

const ScheduleHistoryList = ({ authorization, authObject }: any) => {
    // 초기값 설정
    const [currentDate, setCurrentDate] = useState(dayjs());

    const [isToday, setIsToday] = useState<boolean>(true);
    const [isWeek, setIsWeek] = useState<boolean>(false);
    const [isMonth, setIsMonth] = useState<boolean>(false);

    // 몇주차 표시
    const [weekNumber, setWeekNumber] = useState<number>(0);

    const [thisDayList, setThisDayList] = useState<any>(null);
    const [thisWeekList, setThisWeekList] = useState<any>(null);
    const [thisMonthList, setThisMonthList] = useState<any>(null);

    const [cancelCount, setCancelCount] = useState<number>(0);
    const [changeCount, setChangeCount] = useState<number>(0);

    const onClickAddDate = () => {
        if (isToday) {
            const newDate = dayjs(currentDate).add(1, 'day');
            setCurrentDate(newDate);
        }

        if (isWeek) {
            const newDate = dayjs(currentDate).add(1, 'week');
            setCurrentDate(newDate);
        }

        if (isMonth) {
            const newDate = dayjs(currentDate).add(1, 'month');
            setCurrentDate(newDate);
        }
    };

    const onClickSubtractDate = () => {
        if (isToday) {
            const newDate = dayjs(currentDate).subtract(1, 'day');
            setCurrentDate(newDate);
        }

        if (isWeek) {
            const newDate = dayjs(currentDate).subtract(1, 'week');
            setCurrentDate(newDate);
        }

        if (isMonth) {
            const newDate = dayjs(currentDate).subtract(1, 'month');
            setCurrentDate(newDate);
        }
    };

    const settingDate = (type: string) => {
        setCurrentDate(dayjs());
        if (type === 'today') {
            setIsToday(true);
            setIsWeek(false);
            setIsMonth(false);
        }

        if (type === 'thisWeek') {
            setIsToday(false);
            setIsWeek(true);
            setIsMonth(false);
        }

        if (type === 'thisMonth') {
            setIsToday(false);
            setIsWeek(false);
            setIsMonth(true);
        }
    };

    useEffect(() => {
        if (currentDate) {
            setWeekNumber(getCurrentDayWeekNumber(currentDate));
        }
    }, [currentDate]);

    //  당일 취소 및 변경 횟수 조회 API : (GET)
    const getCancelAndChangeList = useQuery(
        ['getCancelAndChangeListAPI', currentDate],
        async () =>
            await getCancelAndChangeListAPI(
                authObject.centerId,
                dayjs(currentDate).format('YYYY-MM-DD'),
                authorization
            ),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const thisDay = res.data.thisDay;
                    const thisWeek = res.data.thisWeek;
                    const thisMonth = res.data.thisMonth;
                    setThisDayList(thisDay);
                    setThisWeekList(thisWeek);
                    setThisMonthList(thisMonth);
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!!authObject?.testAccount,
            // refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    useEffect(() => {
        if (authObject?.testAccount && scheduleHistoryCancelAndChangeDummyData) {
            const thisDay = scheduleHistoryCancelAndChangeDummyData.thisDay;
            const thisWeek = scheduleHistoryCancelAndChangeDummyData.thisWeek;
            const thisMonth = scheduleHistoryCancelAndChangeDummyData.thisMonth;

            const cancelList = thisDay.filter((item: any) => item.adjustmentType === 'coachTodayCancel');
            const changeList = thisDay.filter((item: any) => item.adjustmentType === 'coachTodayChange');
            cancelList && setCancelCount(cancelList.length);
            changeList && setChangeCount(changeList.length);

            setThisDayList(thisDay);
            setThisWeekList(thisWeek);
            setThisMonthList(thisMonth);
        }
    }, [scheduleHistoryCancelAndChangeDummyData]);

    // 수업 취소 변경횟수 정제
    useEffect(() => {
        if (isToday && thisDayList) {
            const cancelList = thisDayList.filter((item: any) => item.adjustmentType === 'coachTodayCancel');
            const changeList = thisDayList.filter((item: any) => item.adjustmentType === 'coachTodayChange');
            cancelList && setCancelCount(cancelList.length);
            changeList && setChangeCount(changeList.length);
        }

        if (isWeek && thisWeekList) {
            const cancelList = thisWeekList.filter((item: any) => item.adjustmentType === 'coachTodayCancel');
            const changeList = thisWeekList.filter((item: any) => item.adjustmentType === 'coachTodayChange');
            cancelList && setCancelCount(cancelList.length);
            changeList && setChangeCount(changeList.length);
        }

        if (isMonth && thisMonthList) {
            const cancelList = thisMonthList.filter((item: any) => item.adjustmentType === 'coachTodayCancel');
            const changeList = thisMonthList.filter((item: any) => item.adjustmentType === 'coachTodayChange');
            cancelList && setCancelCount(cancelList.length);
            changeList && setChangeCount(changeList.length);
        }
    }, [isToday, thisDayList, isWeek, thisWeekList, isMonth, thisMonthList]);

    return (
        <Col
            style={{
                width: 1192,
                minHeight: 494,
                marginTop: 24,

                borderRadius: 16,
                backgroundColor: colors.WHITE_50,
                border: '1px solid #C3D4F4',
            }}
        >
            <ScheduleHistoryListHeader
                currentDate={currentDate}
                onClickSubtractDate={onClickSubtractDate}
                onClickAddDate={onClickAddDate}
                isToday={isToday}
                isWeek={isWeek}
                isMonth={isMonth}
                settingDate={settingDate}
                weekNumber={weekNumber}
                setCurrentDate={setCurrentDate}
                cancelCount={cancelCount}
                changeCount={changeCount}
            />

            <Col
                style={{
                    width: 1100,

                    borderRadius: 8,

                    marginTop: 32,
                    alignSelf: 'center',
                }}
            >
                <TopTable />
                {isToday && thisDayList && <ScheduleHistoryThisDayList thisDayList={thisDayList} />}
                {isWeek && thisWeekList && <ScheduleHistoryThisWeekList thisWeekList={thisWeekList} />}
                {isMonth && thisMonthList && <ScheduleHistoryThisMonthList thisMonthList={thisMonthList} />}
            </Col>
        </Col>
    );
};

export default ScheduleHistoryList;
