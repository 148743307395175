import { Col, Row } from '../../lib/utils';
import { Body1Bold, Body2Bold, Body3Bold, Body3Regular, CaptionLight, CaptionRegular, Head4 } from '../../lib/font';
import { colors } from '../../lib/colors';
import styled from 'styled-components';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getCenterCommRecordAPI, getCenterTodayRecordAPI } from '../../api/dashboard';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import ColorRoundButton from '../Button/ColorRoundButton';
import WhiteRoundButton from '../Button/WhiteRoundButton';
import DashBoardDonutChart from './DashBoardDonutChart';
import ModalAddTrainer from '../ModalAddTrainer/ModalAddTrainer';
import { I_RIGHT_GRAY_ANGLE } from '../../types/images';

const DashBoardTodayHistory = ({ authorization, authObject, createAlert }: any) => {
    const navigate = useNavigate();
    const [isAddTrainer, setIsAddTrainer] = useState<boolean>(false);

    const [totalClass, setTotalClass] = useState<number>(0); // 전체수업
    const [completedClass, setCompletedClass] = useState<number>(0); // 완료수업
    const [todayCancelClass, setTodayCancelClass] = useState<number>(0); // 당일취소
    const [todayChangeClass, setTodayChangeClass] = useState<number>(0); // 당일 변경

    const [activeMemberCnt, setActiveMemberCnt] = useState<number>(0); // 수강중인 회원
    const [allMemberCnt, setAllMemberCnt] = useState<number>(0); // 총 회원
    const [newMemberCnt, setNewMemberCnt] = useState<number>(0); // 신규등록 회원

    const [ptFeedBackCnt, setPtFeedBackCnt] = useState<number>(0); // 피티 피드백
    const [counselCnt, setCounselCnt] = useState<number>(0); // 상담 문의
    const [facilityCnt, setFacilityCnt] = useState<number>(0); // 시설 문의
    const [etcCnt, setEtcCnt] = useState<number>(0); // 기타

    const [date, setDate] = useState(dayjs());

    const [chartData, setChartData] = useState<any>(null);

    //  센터의 오늘 기록 조회 API : (GET)
    const getCenterTodayRecord = useQuery(
        ['getCenterTodayRecordAPI'],
        async () =>
            await getCenterTodayRecordAPI(authObject?.centerId, dayjs(date).format('YYYY-MM-DD'), authorization),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const response = res.data;

                    setTotalClass(response.totalScheduleCount); // 전체수업
                    setCompletedClass(response.completeScheduleCount); // 완료수업
                    setTodayCancelClass(response.canceledScheduleCount); // 당일취소
                    setTodayChangeClass(response.changedScheduleCount); // 당일 변경

                    setActiveMemberCnt(response.activeMembershipMemberCount); // 수강중인 회원
                    setAllMemberCnt(response.allMemberCount); // 총 회원
                    setNewMemberCnt(response.newMemberCount); // 신규등록 회원

                    const completeScheduleCnt = response.completeScheduleCount;
                    const todayCancelScheduleCnt = response.canceledScheduleCount;
                    const todayChangeScheduleCnt = response.changedScheduleCount;

                    const chartData = [
                        { value: completeScheduleCnt, color: '#3D73DD' },
                        { value: todayChangeScheduleCnt, color: '#FFD977' },
                        { value: todayCancelScheduleCnt, color: '#FF8777' },
                    ];
                    setChartData(chartData);
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!!authObject?.testAccount,
            refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    //  센터의 소통 상자 소식 조회 API : (GET)
    const getCenterCommRecord = useQuery(
        ['getCenterCommRecord'],
        async () => await getCenterCommRecordAPI(authObject?.adminId, authorization),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const response = res.data;

                    setPtFeedBackCnt(response.PTFEEDBACKCount);
                    setCounselCnt(response.COUNSELCount);
                    setFacilityCnt(response.FACILITYCount);
                    setEtcCnt(response.ETCCount);
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!!authObject?.testAccount,
            refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    // 트레이너 등록하기 버튼 클릭이벤트
    const onClickAddTrainer = () => {
        if (authObject) {
            // 무료체험 상태일 때
            if (authObject?.usableStatus == 'trial' || authObject?.usableStatus == 'trialPaymentPending') {
                setIsAddTrainer(true);
            }
            // 무료체험 상태가 아닐 때
            else {
                // 강사 인원 수가 플랜 제한 인원수 이상이라면 경고 alert, 추가 가능하다면 트레이너 등록 모달
                authObject?.planLimitCount <= authObject?.coachCount
                    ? createAlert(
                          '오류',
                          '현재플랜 에서의 강사 제한을 초과하였습니다\n 마이페이지 > 플랜정보 에서 플랜을 변경하여 주시길바랍니다',
                          true,
                          '확인',
                          () => navigate('/myPage')
                      )
                    : setIsAddTrainer(true);
            }
        }
    };

    return (
        <Col style={{ width: 1192 }}>
            <Row
                style={{
                    width: 1192,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: 32,
                }}
            >
                <Row
                    style={{
                        width: 187,
                        height: 36,
                        borderRadius: 36,
                        backgroundColor: colors.BLUE_50,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Body3Regular style={{ color: colors.LAVEL_2 }}>
                        {dayjs().format('YYYY년 M월 DD일(ddd)')}
                    </Body3Regular>
                </Row>

                <ColorRoundButton text="강사 등록하기" size="small" callBack={onClickAddTrainer} />
            </Row>

            <Row style={{ width: 1192, alignItems: 'center', justifyContent: 'space-between' }}>
                {/* 오늘의 수업현황 */}
                <Row
                    style={{
                        width: 685,
                        height: 304,
                        border: '1px solid #C3D4F4',
                        backgroundColor: colors.WHITE_50,
                        borderRadius: 16,
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingLeft: 58,
                        paddingRight: 76,
                    }}
                >
                    <DashBoardDonutChart
                        chartData={chartData}
                        completedClass={completedClass}
                        totalClass={totalClass}
                    />

                    <Col
                        style={{
                            width: 283,
                            height: 200,

                            justifyContent: 'space-between',
                            paddingBottom: 16,
                        }}
                    >
                        <Body1Bold style={{ color: colors.LAVEL_3 }}>{'오늘의 수업 현황'}</Body1Bold>

                        <Row
                            style={{
                                width: 283,
                                height: 72,
                                justifyContent: 'space-between',
                            }}
                        >
                            <Col style={{ justifyContent: 'space-between' }}>
                                <Row style={{ alignItems: 'center' }}>
                                    <Row
                                        style={{
                                            width: 16,
                                            height: 16,
                                            borderRadius: 2,
                                            backgroundColor: colors.WHITE_600,
                                            marginRight: 8,
                                        }}
                                    />
                                    <Body3Regular style={{ color: colors.LAVEL_3 }}>{'전체수업 :'}</Body3Regular>
                                    <Body3Bold style={{ color: colors.POINT_2 }}>
                                        {authObject?.testAccount ? 100 : totalClass}
                                    </Body3Bold>
                                </Row>
                                <Row style={{ alignItems: 'center' }}>
                                    <Row
                                        style={{
                                            width: 16,
                                            height: 16,
                                            borderRadius: 2,
                                            backgroundColor: colors.BLUE_500,
                                            marginRight: 8,
                                        }}
                                    />
                                    <Body3Regular style={{ color: colors.LAVEL_3 }}>{'완료수업 :'}</Body3Regular>
                                    <Body3Bold style={{ color: colors.POINT_2 }}>
                                        {authObject?.testAccount ? 57 : completedClass}
                                    </Body3Bold>
                                </Row>
                            </Col>

                            <Col style={{ justifyContent: 'space-between' }}>
                                <Row style={{ alignItems: 'center' }}>
                                    <Row
                                        style={{
                                            width: 16,
                                            height: 16,
                                            borderRadius: 2,
                                            backgroundColor: colors.ORANGE,
                                            marginRight: 8,
                                        }}
                                    />
                                    <Body3Regular style={{ color: colors.LAVEL_3 }}>{'당일 취소 :'}</Body3Regular>
                                    <Body3Bold style={{ color: colors.POINT_2 }}>
                                        {authObject?.testAccount ? 10 : todayCancelClass}
                                    </Body3Bold>
                                </Row>
                                <Row style={{ alignItems: 'center' }}>
                                    <Row
                                        style={{
                                            width: 16,
                                            height: 16,
                                            borderRadius: 2,
                                            backgroundColor: colors.YELLOW,
                                            marginRight: 8,
                                        }}
                                    />
                                    <Body3Regular style={{ color: colors.LAVEL_3 }}>{'당일 변경 :'}</Body3Regular>
                                    <Body3Bold style={{ color: colors.POINT_2 }}>
                                        {authObject?.testAccount ? 5 : todayChangeClass}
                                    </Body3Bold>
                                </Row>
                            </Col>
                        </Row>

                        <Row
                            style={{ justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}
                            onClick={() => navigate('/schedule/history')}
                        >
                            <CaptionRegular style={{ color: colors.WHITE_700, marginRight: 4 }}>
                                {'당일 취소 & 변경 자세히보기'}
                            </CaptionRegular>
                            <img src={I_RIGHT_GRAY_ANGLE} style={{ width: 16, height: 16 }} />
                        </Row>
                    </Col>
                </Row>

                <Col
                    style={{
                        width: 230,
                        height: 304,
                        border: '1px solid #C3D4F4',
                        backgroundColor: colors.WHITE_50,
                        borderRadius: 16,
                        alignItems: 'center',
                    }}
                >
                    <Body2Bold style={{ color: colors.LAVEL_3, marginTop: 32 }}>{'오늘의 신규 등록 회원'}</Body2Bold>
                    <Head4 style={{ color: colors.LAVEL_3, marginTop: 28 }}>{`+ ${newMemberCnt}명`}</Head4>
                    <Body3Regular
                        style={{ color: colors.LAVEL_3, marginTop: 26 }}
                    >{`총 회원 : ${allMemberCnt}명`}</Body3Regular>
                    <CaptionLight style={{ color: colors.LAVEL_3, marginTop: 12, marginBottom: 38 }}>
                        {`수강중인 회원 : ${activeMemberCnt}명`}
                    </CaptionLight>

                    <WhiteRoundButton
                        customStyle={{ width: 132, height: 24 }}
                        text="회원 리스트 바로가기"
                        callBack={() => navigate('/memberList')}
                        size="tiny"
                    />
                </Col>
                <Col
                    style={{
                        width: 230,
                        height: 304,
                        border: '1px solid #C3D4F4',
                        backgroundColor: colors.WHITE_50,
                        borderRadius: 16,
                        alignItems: 'center',
                    }}
                >
                    <Body2Bold style={{ color: colors.LAVEL_3, marginTop: 32 }}>{'소통 상자 소식'}</Body2Bold>
                    <Body3Regular style={{ width: 115, color: colors.LAVEL_3, marginTop: 32 }}>
                        {'PT 피드백 : '}
                        <Body3Bold
                            style={{ color: ptFeedBackCnt ? colors.POINT_2 : colors.GREY_100 }}
                        >{`${ptFeedBackCnt}건`}</Body3Bold>
                    </Body3Regular>

                    <Body3Regular style={{ width: 115, color: colors.LAVEL_3, marginTop: 10 }}>
                        {'상담 문의 : '}
                        <Body3Bold
                            style={{ color: counselCnt ? colors.POINT_2 : colors.GREY_100 }}
                        >{`${counselCnt}건`}</Body3Bold>
                    </Body3Regular>
                    <Body3Regular style={{ width: 115, color: colors.LAVEL_3, marginTop: 10 }}>
                        {'시설 문의 : '}
                        <Body3Bold
                            style={{ color: facilityCnt ? colors.POINT_2 : colors.GREY_100 }}
                        >{`${facilityCnt}건`}</Body3Bold>
                    </Body3Regular>

                    <Body3Regular style={{ width: 115, color: colors.LAVEL_3, marginTop: 10, marginBottom: 30 }}>
                        {'기타 : '}
                        <Body3Bold
                            style={{ color: etcCnt ? colors.POINT_2 : colors.GREY_100 }}
                        >{`${etcCnt}건`}</Body3Bold>
                    </Body3Regular>

                    <WhiteRoundButton
                        customStyle={{ width: 116, height: 24 }}
                        text="소통상자 바로가기"
                        callBack={() => navigate('/member/communication')}
                        size="tiny"
                    />
                </Col>
            </Row>
            {/* 모달 : 트레이너 등록하기*/}
            {isAddTrainer && (
                <ModalAddTrainer
                    modalVisible={isAddTrainer}
                    setModalVisible={setIsAddTrainer}
                    authorization={authorization}
                    authObject={authObject}
                    createAlert={createAlert}
                />
            )}
        </Col>
    );
};

export default DashBoardTodayHistory;
