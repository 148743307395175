import React from 'react';

import { Col, Row } from '../../lib/utils';
import { I_PLAN_PRICE } from '../../types/images';

const PlanInformation = () => {
    return (
        <Row style={{ width: 1192, marginTop: 24, marginBottom: 300 }}>
            <img src={I_PLAN_PRICE} style={{ width: 1192, height: 718 }} />
        </Row>
    );
};

export default PlanInformation;
