import { Row } from '../../lib/utils';
import { colors } from '../../lib/colors';
import { CaptionRegular } from '../../lib/font';
import { I_BOTTOM_PRIMARY_TRIANGLE } from '../../types/images';

type Props = {
    isInputMode: boolean;
    textareaRef: any;
    text: string;
    handleChange: (event: any) => void;
};

const ModalDetailCommunicationInputReply = ({ isInputMode, textareaRef, text, handleChange }: Props) => {
    return (
        <>
            {isInputMode && (
                <>
                    <Row style={{ marginLeft: 68, marginTop: 8 }}>
                        <CaptionRegular style={{ color: colors.POINT_1, marginRight: 4 }}>{'답변 작성'}</CaptionRegular>
                        <img style={{ width: 16, height: 16 }} src={I_BOTTOM_PRIMARY_TRIANGLE} />
                    </Row>

                    <Row style={{ marginTop: 8 }}>
                        <textarea
                            ref={textareaRef}
                            style={{
                                width: 588,
                                maxHeight: 272,
                                borderRadius: 8,
                                border: '1px solid #7DA1E8',
                                marginLeft: 60,
                                padding: 16,
                                fontSize: 16,
                                color: colors.WHITE_900,
                            }}
                            value={text}
                            maxLength={1000}
                            onChange={handleChange}
                            placeholder="답변을 작성해주세요"
                        />
                    </Row>
                </>
            )}
        </>
    );
};

export default ModalDetailCommunicationInputReply;
