import axios from 'axios';
import { useEffect } from 'react';

const useAxiosInterceptor = ({ createAlert, clearLocalStorage, navigate }: any) => {
    useEffect(() => {
        // api 요청 전역감지
        axios.interceptors.request.use(
            (config) => {
                return config;
            },
            (error) => {
                if ((error.response && error.response.status === 401) || error.response.status === 403) {
                    createAlert('', '세션이 만료되었습니다');
                    clearLocalStorage();
                    navigate('/signIn');
                }

                return Promise.reject(error);
            }
        );

        // api 응답 전역감지
        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if ((error.response && error.response.status === 401) || error.response.status === 403) {
                    createAlert('', '세션이 만료되었습니다');
                    clearLocalStorage();
                    navigate('/signIn');
                }

                return Promise.reject(error);
            }
        );
    }, []);
};

export default useAxiosInterceptor;
