import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Col, getItemWithExpiration, Row } from '../../lib/utils';
import { Body2Bold, Body3Regular, CaptionLight, Subtitle1Bold } from '../../lib/font';
import NewWhiteRoundButton from '../Button/NewWhiteRoundButton';
import ColTitleAndTextInput from '../ColTitleAndTextInput';
import dayjs from 'dayjs';
import MemoTextInput from '../TextInput/MemoTextInput';
import NewWhiteSquareButton from '../Button/NewWhiteSquareButton';
import NewColorSquareButton from '../Button/NewColorSquareButton';
import { MemberMembershipDataType } from '../../services/useMembershipQueryService';
import Modal from '../modal/Modal';
import CloseButton from '../Button/CloseButton';
import { colors } from '../../lib/colors';
import ModalUpdateMembershipName from './ModalUpdateMembershipName';
import useMembershipService from '../../services/useMembershipService';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
    memberName?: string;
    coachName?: string;
    membership: MemberMembershipDataType;
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    createAlert: any;
};

const ModalMembershipUpdate = ({ memberName, coachName, membership, setIsVisible, createAlert }: Props) => {
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const queryClient = useQueryClient();

    const { updateMemberShipMutation } = useMembershipService({
        onUpdatedMemberShipSuccess() {
            createAlert('수강권 수정 완료', '수강권이 성공적으로 수정되었습니다 !', false);
            queryClient.invalidateQueries({
                queryKey: ['getMembershipForMemberQuery'],
            });

            setIsVisible(false);
        },
    });
    // 수강권 이름 수정 모달
    const [isMembershipNameModalVisible, setIsMembershipNameModalVisible] = useState(false);

    // 회당 가격
    const [pricePerClass, setPricePerClass] = useState<number>(membership.pricePerSchedule);
    // 판매 금액
    const [totalPrice, setTotalPrice] = useState<number>(membership.totalPrice);

    // 시작 날짜
    const [startDate, setStartDate] = useState<string>(membership.activationDate);
    // 만료 날짜 expirationDate
    const [expirationDate, setExpirationDate] = useState<string>(membership.expirationDate);
    // 수강권 기간
    const [membershipPeriod, setMembershipPeriod] = useState<number>(
        dayjs(expirationDate).diff(dayjs(startDate), 'day')
    );
    // 시작 회차 startSession
    const [startSession, setStartSession] = useState<number>(membership.startSessionValue);
    // 총 회차 totalSession
    const [totalSession, setTotalSession] = useState<number>(membership.totalSession);
    // 수업 시간
    const [classTime, setClassTime] = useState<number>(membership.period);
    // 메모
    const [memo, setMemo] = useState<string>(membership.memo);
    // BGColor
    const [BGColor, setBGColor] = useState<string[]>(membership.BGColor);
    // 수강권 이름
    const [membershipName, setMembershipName] = useState<string>(membership.name);

    const onClickUpdateMembership = () => {
        // 수업시간이 10분 단위가 아니라면 경고창
        if (classTime % 10 !== 0) {
            createAlert('올바르지 않은 입력', '수업 시간을 10분단위로 입력하여 주세요', true);
            return;
        }
        // 시작회차가 총회차 보다 크다면 경고창
        else if (startSession > totalSession) {
            createAlert('올바르지 않은 입력', '시작회차를 총회차보다 낮게 설정해주세요!', true);
            return;
        }
        //시작날짜가 만료날짜보다 크다면 경고창
        else if (dayjs(startDate).isAfter(dayjs(expirationDate))) {
            createAlert('올바르지 않은 입력', '시작날짜를 만료날짜보다 더 낮게 설정해주세요!', true);
            return;
        }

        updateMemberShipMutation.mutate({
            membershipId: membership._id,
            name: membershipName,
            period: classTime,
            activationDate: startDate,
            expirationDate: expirationDate,
            startSessionValue: startSession,
            totalSession: totalSession,
            memo: memo,
            pricePerSchedule: pricePerClass,
            totalPrice: totalPrice,
            accessToken: authorization,
        });
    };

    return (
        <Modal whiteViewHeight={700} whiteViewWidth={1080} setIsVisible={setIsVisible} isCloseOutside={false}>
            <CloseButton size={24} className=" absolute right-[25px] top-[25px]" onClick={() => setIsVisible(false)} />
            <Col className="flex py-[36px] px-[48px]">
                <Row className="mb-[24px]">
                    <Subtitle1Bold>수강권 수정하기</Subtitle1Bold>
                </Row>
                <Row className="mb-[32px]">
                    <Col className="mr-[40px]">
                        <Col
                            style={{
                                width: 264,
                                height: 120,
                                background:
                                    BGColor && BGColor?.length > 0
                                        ? `linear-gradient(135deg, ${BGColor[0]} 0%, ${BGColor[1]} 100%)`
                                        : colors.GREY_200,
                                borderRadius: 8,
                                justifyContent: 'center',
                                paddingRight: 24,
                                paddingLeft: 24,
                                marginBottom: 24,
                            }}
                        >
                            <Body2Bold className="text-white">{`${membershipName}`}</Body2Bold>
                        </Col>
                        {/* <NewWhiteRoundButton
                            text="수강권 이름 수정하기"
                            onClick={() => setIsMembershipNameModalVisible(true)}
                            fontType="CaptionRegular"
                            width={136}
                            height={24}
                            mb={52}
                        /> */}
                        <ColTitleAndTextInput
                            title="회당 가격 (원)"
                            value={pricePerClass.toString()}
                            onChange={(value) => {
                                if (value === '') {
                                    value = '0';
                                }
                                setPricePerClass(parseInt(value));
                            }}
                            width={264}
                            height={36}
                            onClear={() => {
                                setPricePerClass(0);
                            }}
                            textInputRightIcon={false}
                            mb={32}
                            isClearIcon={false}
                        />
                        <ColTitleAndTextInput
                            title="판매 금액 (원)"
                            value={totalPrice.toString()}
                            onChange={(value) => {
                                if (value === '') {
                                    value = '0';
                                }
                                setTotalPrice(parseInt(value));
                            }}
                            width={264}
                            height={36}
                            onClear={() => {
                                setTotalPrice(0);
                            }}
                            textInputRightIcon={false}
                            isClearIcon={false}
                        />
                    </Col>
                    <Col className="w-[644px] border-[1px] border-solid border-BLUE_100 rounded-[8px] p-[32px]">
                        <Row className="mb-[48px]">
                            <ColTitleAndTextInput
                                title="담당 강사"
                                value={coachName}
                                onChange={() => {}}
                                width={240}
                                height={36}
                                textInputRightIcon={false}
                                textInputLeftIcon={false}
                                disabled
                                mr={60}
                            />
                            <ColTitleAndTextInput
                                title="회원"
                                value={memberName}
                                onChange={() => {}}
                                width={240}
                                height={36}
                                textInputRightIcon={false}
                                textInputLeftIcon={false}
                                disabled
                            />
                        </Row>
                        <Row className="mb-[48px]">
                            <ColTitleAndTextInput
                                title="시작 날짜"
                                value={startDate}
                                isCalendar
                                onClickDate={(date) => {
                                    setStartDate(dayjs(date).toISOString());
                                    setMembershipPeriod(dayjs(expirationDate).diff(dayjs(date), 'day'));
                                }}
                                width={240}
                                height={36}
                                mr={60}
                            />
                            <ColTitleAndTextInput
                                title="만료 날짜"
                                value={expirationDate}
                                isCalendar
                                onClickDate={(date) => {
                                    setExpirationDate(dayjs(date).toISOString());
                                    setMembershipPeriod(dayjs(date).diff(dayjs(startDate), 'day'));
                                }}
                                width={240}
                                height={36}
                            />
                        </Row>
                        <Row className=" items-end mb-[48px]">
                            <ColTitleAndTextInput
                                title="수강권 기간"
                                value={membershipPeriod.toString()}
                                onChange={(value) => {
                                    if (value === '') {
                                        value = '0';
                                    }
                                    setMembershipPeriod(parseInt(value));
                                    setExpirationDate(dayjs(startDate).add(parseInt(value), 'day').toISOString());
                                }}
                                width={140}
                                height={36}
                                mr={32}
                                isClearIcon={false}
                            />
                            <Col>
                                <CaptionLight className="text-WHITE_900">
                                    시작, 만료날짜를 입력하시면 유효기간이 자동으로 계산 되어요!
                                </CaptionLight>
                                <CaptionLight className="text-WHITE_900">
                                    만료 날짜가 지나도 수업 회차가 남으면 만료되지 않아요!
                                </CaptionLight>
                            </Col>
                        </Row>
                        <Row className="mb-[48px]">
                            <ColTitleAndTextInput
                                title="시작 회차"
                                value={startSession.toString()}
                                onChange={(value) => {
                                    if (value === '') {
                                        value = '0';
                                    }
                                    setStartSession(parseInt(value));
                                }}
                                width={140}
                                height={36}
                                mr={60}
                                textInputRightIcon={false}
                                isClearIcon={false}
                            />
                            <ColTitleAndTextInput
                                title="총 회차"
                                value={totalSession.toString()}
                                onChange={(value) => {
                                    if (value === '') {
                                        value = '0';
                                    }
                                    setTotalSession(parseInt(value));
                                }}
                                width={140}
                                height={36}
                                textInputRightIcon={false}
                                isClearIcon={false}
                                mr={60}
                            />
                            <ColTitleAndTextInput
                                title="수업 시간"
                                value={classTime}
                                onChange={(value) => {
                                    if (value === '') {
                                        value = '0';
                                    }
                                    setClassTime(parseInt(value));
                                }}
                                width={140}
                                height={36}
                                textInputRightIcon={false}
                                isClearIcon={false}
                            />
                        </Row>
                        <Col>
                            <Body3Regular className="mb-[6px]">메모</Body3Regular>
                            <CaptionLight className="mb-[8px]">
                                수강권의 메모는 관리자님과 강사님만 확인할 수 있어요!
                            </CaptionLight>
                            <MemoTextInput
                                value={memo}
                                onChange={(e) => setMemo(e.target.value)}
                                height={136}
                                placeholder="기억해야 할 회원님의 상태를 메모해보세요"
                            />
                        </Col>
                    </Col>
                </Row>
                <Row className="w-full justify-end">
                    <NewWhiteSquareButton
                        text="닫기"
                        fontType="Body2Regular"
                        width={148}
                        height={48}
                        mr={16}
                        onClick={() => setIsVisible(false)}
                    />
                    <NewColorSquareButton
                        text="수정 완료"
                        fontType="Body2Regular"
                        width={148}
                        height={48}
                        onClick={onClickUpdateMembership}
                    />
                </Row>
            </Col>
            {isMembershipNameModalVisible && (
                <ModalUpdateMembershipName
                    onUpdateComplete={(value: string) => {
                        setMembershipName(value);
                        setIsMembershipNameModalVisible(false);
                    }}
                    setIsVisible={setIsMembershipNameModalVisible}
                    value={membershipName}
                />
            )}
        </Modal>
    );
};

export default ModalMembershipUpdate;
