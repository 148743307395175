import { ChangeEvent, useEffect, useState } from 'react';
import { Body1Bold, Body3Light, Body3Regular, CaptionLight } from '../../lib/font';
import { colors } from '../../lib/colors';
import { Col, Row, setItemWithExpiration } from '../../lib/utils';
import { useMutation } from '@tanstack/react-query';
import { modifyRepresentativeInformationAPI } from '../../api/mypage';
import RoundedDropDown from '../DropDown/RoundedDropDown';
import WhiteRoundButton from '../Button/WhiteRoundButton';
import ColorRoundButton from '../Button/ColorRoundButton';

let genderOptions: any = [
    { value: '', label: '선택' },
    { value: 'male', label: '남성' },
    { value: 'female', label: '여성' },
];

const MyPageRightTop = ({
    createAlert,
    authorization,
    authObject,
    setAuthObject,
    isOpenGender,
    setIsOpenGender,
    onClickPhoneChange,
}: any) => {
    // 초기값 세팅 - 이름 , 이메일 , 성별
    const [name, setName] = useState<string>(authObject?.name);
    const [email, setEmail] = useState<string>(authObject?.email ? authObject?.email : '');
    const [selectedGender, setSelectedGender] = useState({ value: '', label: '선택' });

    // 오류메세지 상태 저장
    const [nameMessage, setNameMessage] = useState<string>('');

    // 변경감지 플래그
    const [isChangedName, setIsChangedName] = useState<boolean>(false);
    const [isChangeEmail, setIsChangeEmail] = useState<boolean>(false);
    const [isChangeGender, setIsChangeGender] = useState<boolean>(false);

    // 이름변경
    const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
        const currentName = e.target.value;
        setName(currentName.trim());
    };

    // 이메일 변경
    const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
        const currentEmail = e.target.value;
        setEmail(currentEmail.trim());
    };

    // 성별 드롭다운 함수
    const toggleGenderDropDown = () => {
        setIsOpenGender(!isOpenGender);
    };

    // 성별 선택 함수
    const handleGenderSelect = (option: any) => {
        setSelectedGender(option);
        setIsOpenGender(false);
    };

    const resetFlag = () => {
        setIsChangedName(false);
        setIsChangeEmail(false);
        setIsChangeGender(false);
    };

    // 마이페이지 대표자 정보 수정(PATCH)
    const modifyRepresentativeInformation = useMutation({
        mutationFn: async () =>
            await modifyRepresentativeInformationAPI(
                authObject?.adminId,
                name,
                authObject?.phoneNumber,
                selectedGender.value,
                email,
                authorization
            ),
        onSuccess: (res) => {
            if (res.status === 200) {
                const user = res.data.user;
                const authObjectData = {
                    adminId: user?.adminId,
                    id: user?.id,
                    name: user?.name,
                    email: user?.email,
                    gender: user?.gender,
                    phoneNumber: user?.phoneNumber,
                    profileImageUrl: user?.profileImageUrl,

                    centerAddress: authObject?.centerAddress,
                    centerCategory: authObject?.centerCategory,
                    centerCode: authObject?.centerCode,
                    centerName: authObject?.centerName,
                    centerId: authObject?.centerId,
                    isNewCommunicationBox: authObject?.isNewCommunicationBox,

                    // 구독플랜관련
                    coachCount: authObject?.coachCount,
                    memberCount: authObject?.memberCount,
                    activationDate: authObject?.activationDate,
                    expirationDate: authObject?.expirationDate,
                    expirationDelayDate: authObject?.expirationDelayDate,
                    finalPaymentDate: authObject?.finalPaymentDate,
                    finalPaymentTryDate: authObject?.finalPaymentTryDate,
                    cardIssuingBank: authObject?.cardIssuingBank,
                    cardNumber: authObject?.cardNumber,
                    planAmount: authObject?.planAmount,
                    usableStatus: authObject?.usableStatus,

                    paymentDueDate: authObject?.paymentDueDate,

                    /** 구독플랜 인원 정보 */
                    planLimitCount: authObject?.planLimitCount, // 플랜 제한 이용 인원
                    prevPlanLimitCount: authObject?.prevPlanLimitCount, // 구독 인원 변경시 변경전 플랜카운트
                    changedPlanLimitCount: authObject?.changedPlanLimitCount, // 구독 인원 변경시 변경 플랜카운트

                    confirmUsableStatus: authObject?.confirmUsableStatus,
                    testAccount: authObject?.testAccount,
                };
                setItemWithExpiration('authorization', authorization, 12);
                setItemWithExpiration('authObject', authObjectData, 12);

                setAuthObject(authObjectData);
                resetFlag();

                createAlert('수정 완료', '개인 정보 수정을 완료하였습니다.');
            } else {
                createAlert('오류', '개인 정보 수정중 오류가 발생하였습니다.', true);
            }
        },
    });

    // 저장하기 버튼 클릭이벤트
    const onClickSave = () => {
        const emailRegExp = /^$|^[A-Za-z0-9_]+[A-Za-z0-9]*[@]{1}[A-Za-z0-9]+[A-Za-z0-9]*[.]{1}[A-Za-z]{1,3}$/;
        if (name.length < 2) {
            createAlert('입력 오류', '이름은 2글자 이상 입력해주세요!', true);
            return;
        }

        if (!emailRegExp.test(email)) {
            createAlert('입력 오류', '이메일 형식이 잘못되었습니다', true);
            return;
        }
        modifyRepresentativeInformation.mutate();
    };

    useEffect(() => {
        if (name === authObject?.name) {
            setIsChangedName(false);
        } else {
            setIsChangedName(true);
        }

        if (email === authObject?.email) {
            setIsChangeEmail(false);
        } else {
            setIsChangeEmail(true);
        }

        if (selectedGender.value === authObject?.gender) {
            setIsChangeGender(false);
        } else {
            setIsChangeGender(true);
        }
    }, [name, email, selectedGender]);

    useEffect(() => {
        if (authObject?.gender) {
            authObject?.gender === '' && setSelectedGender({ value: '', label: '선택' });
            authObject?.gender === 'male' && setSelectedGender({ value: 'male', label: '남성' });
            authObject?.gender === 'female' && setSelectedGender({ value: 'female', label: '여성' });
        }
    }, [authObject]);

    return (
        <div>
            <Col
                style={{
                    width: 888,
                    height: 402,
                    borderRadius: 16,
                    backgroundColor: colors.WHITE_50,
                    border: '1px solid #C3D4F4',
                }}
            >
                <Row style={{ alignItems: 'center', marginLeft: 40, marginTop: 32 }}>
                    <Body1Bold style={{ color: colors.LAVEL_4 }}>{'개인 정보'}</Body1Bold>
                    <CaptionLight style={{ color: colors.LAVEL_1, marginLeft: 16 }}>
                        {'관리자님의 계정 관리를 위한 기본 개인 정보를 작성해주세요 !'}
                    </CaptionLight>
                </Row>

                <Row
                    style={{
                        // alignItems: 'center',

                        marginTop: 32,
                        marginLeft: 48,
                    }}
                >
                    {/* 이름 */}
                    <Col style={{}}>
                        <label htmlFor="name" style={{ display: 'flex' }}>
                            <Body3Regular>성함</Body3Regular>
                            <Body3Regular style={{ color: colors.ERROR }}>*</Body3Regular>
                        </label>

                        <input
                            name="name"
                            id="name"
                            value={name}
                            maxLength={5}
                            onChange={onChangeName}
                            readOnly={authObject?.testAccount}
                            style={{
                                width: 180,
                                height: 48,
                                backgroundColor: colors.WHITE_50,
                                borderRadius: 48,
                                marginTop: 8,
                                paddingLeft: 16,
                                fontSize: 18,
                                fontWeight: 400,
                                color: colors.LAVEL_3,
                                border: '1px solid #3d73dd',
                            }}
                        />
                        <Body3Light style={{ color: colors.ERROR, paddingLeft: 10, paddingTop: 6 }}>
                            {nameMessage}
                        </Body3Light>
                    </Col>

                    {/* 이메일 */}
                    <Col style={{ marginLeft: 72 }}>
                        <label htmlFor="email" style={{ display: 'flex' }}>
                            <Body3Regular>이메일</Body3Regular>
                        </label>

                        <input
                            id="email"
                            name="email"
                            value={email}
                            onChange={onChangeEmail}
                            readOnly={authObject?.testAccount}
                            style={{
                                width: 380,
                                height: 48,
                                backgroundColor: colors.WHITE_50,
                                borderRadius: 48,
                                marginTop: 8,
                                paddingLeft: 16,
                                fontSize: 18,
                                fontWeight: 400,
                                color: colors.LAVEL_3,
                                border: '1px solid #A6BFEF',
                            }}
                        />
                    </Col>
                </Row>

                {/* 전화번호 , 성별 */}
                <Row style={{ marginLeft: 48, marginTop: 44 }}>
                    <Col style={{ height: 80, justifyContent: 'space-between' }}>
                        <label htmlFor="email" style={{ display: 'flex', marginBottom: 6, marginLeft: 8 }}>
                            <Body3Regular style={{ color: colors.LAVEL_4 }}>성별</Body3Regular>
                        </label>

                        <RoundedDropDown
                            toggleSortDropDown={toggleGenderDropDown}
                            sortType={selectedGender}
                            isOpenSort={isOpenGender}
                            sortOptions={genderOptions}
                            handleSortSelect={handleGenderSelect}
                            size="middle"
                        />
                    </Col>

                    <Col style={{ height: 118, marginLeft: 72 }}>
                        <label htmlFor="name" style={{ display: 'flex', marginBottom: 6 }}>
                            <Body3Regular style={{ color: colors.LAVEL_4 }}>전화번호</Body3Regular>
                        </label>

                        <Row>
                            <div
                                style={{
                                    width: 248,
                                    height: 48,
                                    backgroundColor: colors.WHITE_500,
                                    borderRadius: 48,
                                    paddingLeft: 30,
                                    fontSize: 18,
                                    color: colors.WHITE_900,
                                    fontWeight: 400,
                                    // //lineHeight: 24,

                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {authObject?.phoneNumber}
                            </div>
                            <WhiteRoundButton
                                isVisibleLeftImg={false}
                                isVisibleRightImg={false}
                                text={'번호 변경'}
                                size="middle"
                                callBack={onClickPhoneChange}
                                customStyle={{ width: 120, height: 48, marginLeft: 12 }}
                            />
                        </Row>
                    </Col>
                </Row>

                <ColorRoundButton
                    size="small"
                    text={'변경하기'}
                    isDisabled={!(isChangedName || isChangeEmail || isChangeGender) || authObject?.testAccount}
                    callBack={onClickSave}
                    customStyle={{ width: 113, height: 36, marginLeft: 743 }}
                />
            </Col>
        </div>
    );
};

export default MyPageRightTop;
