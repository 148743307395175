import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { colors } from '../../../lib/colors';
import { Col, Row } from '../../../lib/utils';
import { I_SPEECH_BUBBLE } from '../../../types/images';
import { Body1Bold, Body2Regular } from '../../../lib/font';
import ScheduleHistoryEmptyGraph from './ScheduleHistoryEmptyGraph';
import dayjs from 'dayjs';

type Props = {
    weeklyFilterData: any;
    weeklyEntireData: any;
    weekMaxCount: number;
};

const WeeklyScheduleHistoryGraph = ({ weeklyFilterData, weeklyEntireData, weekMaxCount }: Props) => {
    const CustomTick = (tick: any) => {
        const { x, y, value } = tick;

        let findList = null;

        findList = weeklyEntireData.find((e: any) => e.xLabel === value);

        return (
            <g transform={`translate(${x},${y})`} style={{ textAnchor: 'middle' }}>
                {findList && findList.isToday && (
                    <rect x={-3} y={17} fill={colors.BLUE_500} width={8} height={8} rx={4} />
                )}

                <text
                    x={0}
                    y={43}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{
                        fontSize: 20,
                        fontWeight: 700,
                        fill:
                            findList && findList.isToday
                                ? colors.LAVEL_4
                                : dayjs(findList.startDate).isAfter(dayjs())
                                ? colors.GREY_100
                                : colors.LAVEL_2,
                        outlineWidth: 0,
                    }}
                >
                    {value}
                </text>
                <text
                    x={0}
                    y={72}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{
                        fontSize: 12,
                        fill:
                            findList && findList.isToday
                                ? colors.LAVEL_4
                                : dayjs(findList.startDate).isAfter(dayjs())
                                ? colors.GREY_100
                                : colors.LAVEL_2,
                        outlineWidth: 0,
                        fontWeight: 400,
                    }}
                >
                    {findList && findList?.xSubLabel}
                </text>
            </g>
        );
    };

    return (
        <Col
            style={{
                width: 1192,
                height: 614,
                marginTop: 25,
            }}
        >
            {weeklyEntireData.length === 0 ? (
                <ScheduleHistoryEmptyGraph />
            ) : (
                <ResponsiveLine
                    data={weeklyFilterData}
                    margin={{ top: 80, right: 100, bottom: 130, left: 100 }}
                    xScale={{ type: 'point' }}
                    yScale={{
                        type: 'linear',
                        min: 0,
                        max: weekMaxCount < 10 ? 10 : weekMaxCount,
                        stacked: false,
                        reverse: false,
                    }}
                    yFormat=" >-.2f"
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 0,
                        tickPadding: 5,
                        tickRotation: 0,
                        renderTick: CustomTick,
                    }} // 바텀 라벨의 스타일
                    axisLeft={{
                        tickSize: 0,
                        tickPadding: 25,
                        tickRotation: 0,
                    }}
                    enableGridX={false}
                    colors={(d) => d.color} // 선의 색상을 데이터의 color 속성으로 설정
                    pointSize={8}
                    pointBorderWidth={2}
                    // pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    useMesh={false}
                />
            )}
        </Col>
    );
};

export default WeeklyScheduleHistoryGraph;
