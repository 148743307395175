import { ResponsiveBar } from '@nivo/bar';
import React from 'react';
import { colors } from '../../../lib/colors';
import ActiveMemberEmptyGraph from './ActiveMemberEmptyGraph';
import dayjs from 'dayjs';

const ActiveMembersWeeklyGraph = ({ weeklyData, weekMaxCount, CustomToolTip, CustomTextLayer }: any) => {
    const CustomTick = (tick: any) => {
        const { x, y, value } = tick;

        let weeklyFilterData = null;

        weeklyFilterData = weeklyData.find((e: any) => e.xLabel === value);

        return (
            <g transform={`translate(${x},${y})`} style={{ textAnchor: 'middle' }}>
                {weeklyFilterData && weeklyFilterData.isToday && (
                    <rect x={-3} y={17} fill={colors.BLUE_500} width={8} height={8} rx={4} />
                )}

                {/* <line stroke="rgb(232,193,160)" strokeWidth={2} y1={0} y2={40} /> */}

                <text
                    x={0}
                    y={43}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{
                        fontSize: 20,
                        fontWeight: 700,
                        fill:
                            weeklyFilterData && weeklyFilterData.isToday
                                ? colors.LAVEL_4
                                : dayjs(weeklyFilterData.startDate).isAfter(dayjs())
                                ? colors.GREY_100
                                : colors.LAVEL_2,
                        outlineWidth: 0,
                    }}
                >
                    {value}
                </text>
                <text
                    x={0}
                    y={72}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{
                        fontSize: 12,
                        fill:
                            weeklyFilterData && weeklyFilterData.isToday
                                ? colors.LAVEL_4
                                : dayjs(weeklyFilterData.startDate).isAfter(dayjs())
                                ? colors.GREY_100
                                : colors.LAVEL_2,
                        outlineWidth: 0,
                        fontWeight: 400,
                    }}
                >
                    {weeklyFilterData && weeklyFilterData.xSubLabel}
                </text>
            </g>
        );
    };
    return (
        <>
            {weeklyData && weeklyData.length === 0 ? (
                <ActiveMemberEmptyGraph />
            ) : (
                <ResponsiveBar
                    data={weeklyData}
                    layers={[
                        'grid',
                        'axes',
                        'bars',
                        'markers',
                        'legends',
                        CustomTextLayer, // 커스텀 텍스트 레이어 추가
                    ]}
                    keys={['activeMemberCount', 'interval']}
                    indexBy="xLabel"
                    margin={{ right: 70, top: 15, bottom: 100, left: 90 }}
                    axisLeft={{
                        tickSize: 0,
                    }}
                    axisBottom={{
                        tickSize: 0,
                        tickPadding: 5,
                        tickRotation: 0,
                        renderTick: CustomTick,
                    }} // 바텀 라벨의 스타일
                    isInteractive={true} // 호버햇을때 말풍선
                    tooltip={CustomToolTip}
                    padding={weeklyData && weeklyData.length > 4 ? 0.7 : 0.65}
                    minValue={0}
                    maxValue={weekMaxCount < 10 ? 10 : weekMaxCount}
                    enableLabel={false}
                    colors={['#3D73DD', '#D4DBE8']} // 커스터하여 사용할 때
                    colorBy="id" // 색상을 keys 요소들에 각각 적용
                    labelSkipWidth={36}
                    labelSkipHeight={12}
                    motionConfig={'gentle'}
                    layout="vertical"
                />
            )}
        </>
    );
};

export default ActiveMembersWeeklyGraph;
