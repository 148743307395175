import dayjs from 'dayjs';

const dailySchedulesDummyData = [
    {
        coachId: '6476bde354b8b500124e87c7',
        coachName: '강사1',
        coachProfileImageUrl: '',
        schedules: [
            {
                type: 'emptySchedule',
                coachId: '6476bde354b8b500124e87c7',
                startTime: '2023-07-25T23:00:00.000Z',
                endTime: '2023-07-26T08:00:00.000Z',
                duration: 540,
            },
            {
                _id: '64bd37798090fe0019364c8f',
                scheduleBundleId: null,
                coachId: '6476bde354b8b500124e87c7',
                memberId: '647af8532b98070012a1a14a',
                alias: '홍학심',
                startTime: '2023-07-26T08:00:00.000Z',
                endTime: '2023-07-26T08:50:00.000Z',
                requestDeadline: '2023-07-25T13:00:00.000Z',
                createdAt: '2023-07-23T14:21:45.960Z',
                updatedAt: '2023-07-23T14:21:45.960Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                currentSession: null,
                totalSession: null,
                type: 'schedule',
                duration: 50,
            },
            {
                type: 'emptySchedule',
                coachId: '6476bde354b8b500124e87c7',
                startTime: '2023-07-26T08:50:00.000Z',
                endTime: '2023-07-26T10:00:00.000Z',
                duration: 70,
            },
            {
                _id: '64c0987dd7d6a10019020538',
                scheduleBundleId: null,
                coachId: '6476bde354b8b500124e87c7',
                memberId: '64ae6f8f6afffb0019161350',
                alias: '손현진',
                startTime: '2023-07-26T10:00:00.000Z',
                endTime: '2023-07-26T10:50:00.000Z',
                requestDeadline: '2023-07-25T13:00:00.000Z',
                createdAt: '2023-07-26T03:52:29.266Z',
                updatedAt: '2023-07-26T03:52:29.266Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                currentSession: 4,
                totalSession: 66,
                type: 'schedule',
                duration: 50,
            },
            {
                type: 'emptySchedule',
                coachId: '6476bde354b8b500124e87c7',
                startTime: '2023-07-26T10:50:00.000Z',
                endTime: '2023-07-26T11:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64c092e5d7d6a100190201c3',
                scheduleBundleId: null,
                coachId: '6476bde354b8b500124e87c7',
                memberId: '64be73fd04a3c4002afcea66',
                alias: '전재훈',
                startTime: '2023-07-26T11:00:00.000Z',
                endTime: '2023-07-26T11:50:00.000Z',
                requestDeadline: '2023-07-25T13:00:00.000Z',
                createdAt: '2023-07-26T03:28:37.306Z',
                updatedAt: '2023-07-26T03:28:37.306Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                currentSession: 2,
                totalSession: 20,
                type: 'schedule',
                duration: 50,
            },
            {
                type: 'emptySchedule',
                coachId: '6476bde354b8b500124e87c7',
                startTime: '2023-07-26T11:50:00.000Z',
                endTime: '2023-07-26T12:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64bdbca68090fe001936592d',
                scheduleBundleId: null,
                coachId: '6476bde354b8b500124e87c7',
                memberId: '6479d0c02b98070012a15c87',
                alias: '장혜연',
                startTime: '2023-07-26T12:00:00.000Z',
                endTime: '2023-07-26T12:50:00.000Z',
                requestDeadline: '2023-07-25T13:00:00.000Z',
                createdAt: '2023-07-23T23:49:58.018Z',
                updatedAt: '2023-07-23T23:49:58.018Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '6476bde354b8b500124e87c7',
                startTime: '2023-07-26T12:50:00.000Z',
                endTime: '2023-07-26T14:00:00.000Z',
                duration: 70,
            },
            {
                type: 'emptySchedule',
                coachId: '6476bde354b8b500124e87c7',
                startTime: '2023-07-26T23:00:00.000Z',
                endTime: '2023-07-27T14:00:00.000Z',
                duration: 900,
            },
        ],
    },
    {
        coachId: '630dc4da110ab8001a47bd3a',
        coachName: '강사2',
        coachProfileImageUrl: '',
        schedules: [
            {
                _id: '64b291d654d0580018e82103',
                scheduleBundleId: null,
                coachId: '630dc4da110ab8001a47bd3a',
                memberId: '64263876f6f7b0002a04e791',
                alias: '김민아',
                startTime: '2023-07-26T00:30:00.000Z',
                endTime: '2023-07-26T01:20:00.000Z',
                requestDeadline: '2023-07-25T03:00:00.000Z',
                createdAt: '2023-07-15T12:32:22.143Z',
                updatedAt: '2023-07-15T12:32:22.143Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '630dc4da110ab8001a47bd3a',
                startTime: '2023-07-26T01:20:00.000Z',
                endTime: '2023-07-26T01:30:00.000Z',
                duration: 10,
            },
            {
                _id: '64bb8f4b04a3c4002afbfa1f',
                scheduleBundleId: null,
                coachId: '630dc4da110ab8001a47bd3a',
                memberId: '6426398b7afb66006c602451',
                alias: '강미홍',
                startTime: '2023-07-26T01:30:00.000Z',
                endTime: '2023-07-26T02:20:00.000Z',
                requestDeadline: '2023-07-25T03:00:00.000Z',
                createdAt: '2023-07-22T08:11:55.582Z',
                updatedAt: '2023-07-22T08:11:55.582Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '630dc4da110ab8001a47bd3a',
                startTime: '2023-07-26T02:20:00.000Z',
                endTime: '2023-07-26T02:30:00.000Z',
                duration: 10,
            },
            {
                type: 'emptySchedule',
                coachId: '630dc4da110ab8001a47bd3a',
                startTime: '2023-07-26T03:20:00.000Z',
                endTime: '2023-07-26T06:00:00.000Z',
                duration: 160,
            },
            {
                type: 'emptySchedule',
                coachId: '630dc4da110ab8001a47bd3a',
                startTime: '2023-07-26T06:50:00.000Z',
                endTime: '2023-07-26T07:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64bb8f8604a3c4002afbfabd',
                scheduleBundleId: null,
                coachId: '630dc4da110ab8001a47bd3a',
                memberId: '64ae5b835a46c6001145a99c',
                alias: '박유리',
                startTime: '2023-07-26T07:00:00.000Z',
                endTime: '2023-07-26T07:50:00.000Z',
                requestDeadline: '2023-07-25T03:00:00.000Z',
                createdAt: '2023-07-22T08:12:54.045Z',
                updatedAt: '2023-07-22T08:12:54.045Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '630dc4da110ab8001a47bd3a',
                startTime: '2023-07-26T07:50:00.000Z',
                endTime: '2023-07-26T09:00:00.000Z',
                duration: 70,
            },
            {
                _id: '64b27c8b54d0580018e81866',
                scheduleBundleId: null,
                coachId: '630dc4da110ab8001a47bd3a',
                memberId: '642d2cbbda464a004bf7e574',
                alias: '조시제',
                startTime: '2023-07-26T09:00:00.000Z',
                endTime: '2023-07-26T09:50:00.000Z',
                requestDeadline: '2023-07-25T03:00:00.000Z',
                createdAt: '2023-07-15T11:01:31.505Z',
                updatedAt: '2023-07-15T11:01:31.505Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '630dc4da110ab8001a47bd3a',
                startTime: '2023-07-26T09:50:00.000Z',
                endTime: '2023-07-26T10:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64bb8fa104a3c4002afbfb14',
                scheduleBundleId: null,
                coachId: '630dc4da110ab8001a47bd3a',
                memberId: '64292ab8f6f7b0002a05d035',
                alias: '정서영',
                startTime: '2023-07-26T10:00:00.000Z',
                endTime: '2023-07-26T10:50:00.000Z',
                requestDeadline: '2023-07-25T03:00:00.000Z',
                createdAt: '2023-07-22T08:13:21.018Z',
                updatedAt: '2023-07-22T08:13:21.018Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '630dc4da110ab8001a47bd3a',
                startTime: '2023-07-26T10:50:00.000Z',
                endTime: '2023-07-26T11:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64bb8fae04a3c4002afbfb47',
                scheduleBundleId: null,
                coachId: '630dc4da110ab8001a47bd3a',
                memberId: '6426bee87afb66006c608b54',
                alias: '김혜문',
                startTime: '2023-07-26T11:00:00.000Z',
                endTime: '2023-07-26T11:50:00.000Z',
                requestDeadline: '2023-07-25T03:00:00.000Z',
                createdAt: '2023-07-22T08:13:34.308Z',
                updatedAt: '2023-07-22T08:13:34.308Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '630dc4da110ab8001a47bd3a',
                startTime: '2023-07-26T11:50:00.000Z',
                endTime: '2023-07-26T12:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64bb8fb9d7d6a10019004789',
                scheduleBundleId: null,
                coachId: '630dc4da110ab8001a47bd3a',
                memberId: '6433e6f1b1f41c002aa95a37',
                alias: '김소연',
                startTime: '2023-07-26T12:00:00.000Z',
                endTime: '2023-07-26T12:50:00.000Z',
                requestDeadline: '2023-07-25T03:00:00.000Z',
                createdAt: '2023-07-22T08:13:45.579Z',
                updatedAt: '2023-07-22T08:13:45.579Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '630dc4da110ab8001a47bd3a',
                startTime: '2023-07-26T12:50:00.000Z',
                endTime: '2023-07-26T13:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64bb8fc804a3c4002afbfbac',
                scheduleBundleId: null,
                coachId: '630dc4da110ab8001a47bd3a',
                memberId: '642906254ee5550077f58cdf',
                alias: '정인길',
                startTime: '2023-07-26T13:00:00.000Z',
                endTime: '2023-07-26T13:50:00.000Z',
                requestDeadline: '2023-07-25T03:00:00.000Z',
                createdAt: '2023-07-22T08:14:00.719Z',
                updatedAt: '2023-07-22T08:14:00.719Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '630dc4da110ab8001a47bd3a',
                startTime: '2023-07-26T13:50:00.000Z',
                endTime: '2023-07-26T14:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64be73b38090fe001936bf8f',
                scheduleBundleId: null,
                coachId: '630dc4da110ab8001a47bd3a',
                memberId: '645a34de72e0ad0056c1c31a',
                alias: '김유리',
                startTime: '2023-07-26T14:00:00.000Z',
                endTime: '2023-07-26T14:50:00.000Z',
                requestDeadline: '2023-07-25T03:00:00.000Z',
                createdAt: '2023-07-24T12:50:59.064Z',
                updatedAt: '2023-07-24T12:50:59.064Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '630dc4da110ab8001a47bd3a',
                startTime: '2023-07-26T14:50:00.000Z',
                endTime: '2023-07-26T15:00:00.000Z',
                duration: 10,
            },
        ],
    },
    {
        coachId: '63bc3704135c53002a2f4451',
        coachName: '강사3',
        coachProfileImageUrl: '',
        schedules: [
            {
                type: 'emptySchedule',
                coachId: '63bc3704135c53002a2f4451',
                startTime: '2023-07-25T15:00:00.000Z',
                endTime: '2023-07-26T00:00:00.000Z',
                duration: 540,
            },
            {
                _id: '64bca56fd7d6a10019008f7c',
                scheduleBundleId: null,
                coachId: '63bc3704135c53002a2f4451',
                memberId: '64ab676dd63356004b46eaeb',
                alias: '정다은',
                startTime: '2023-07-26T00:00:00.000Z',
                endTime: '2023-07-26T00:50:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-23T03:58:39.072Z',
                updatedAt: '2023-07-24T11:57:33.736Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: false,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '63bc3704135c53002a2f4451',
                startTime: '2023-07-26T00:50:00.000Z',
                endTime: '2023-07-26T01:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64b7438ed7d6a10019fec1a7',
                scheduleBundleId: null,
                coachId: '63bc3704135c53002a2f4451',
                memberId: '6498f08eebe0860035627931',
                alias: '박종원',
                startTime: '2023-07-26T01:00:00.000Z',
                endTime: '2023-07-26T01:50:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-19T01:59:42.553Z',
                updatedAt: '2023-07-23T04:09:52.053Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '63bc3704135c53002a2f4451',
                startTime: '2023-07-26T01:50:00.000Z',
                endTime: '2023-07-26T02:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64b9e47d25fa4b0012760a23',
                scheduleBundleId: null,
                coachId: '63bc3704135c53002a2f4451',
                memberId: '64b36ee654d0580018e854f6',
                alias: '한명연',
                startTime: '2023-07-26T02:00:00.000Z',
                endTime: '2023-07-26T02:50:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-21T01:50:53.147Z',
                updatedAt: '2023-07-21T01:50:53.147Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: false,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '63bc3704135c53002a2f4451',
                startTime: '2023-07-26T02:50:00.000Z',
                endTime: '2023-07-26T10:00:00.000Z',
                duration: 430,
            },
            {
                _id: '64b93b9804a3c4002afb547d',
                scheduleBundleId: null,
                coachId: '63bc3704135c53002a2f4451',
                memberId: '64939c3a6fb5ee003502fe69',
                alias: '성현수',
                startTime: '2023-07-26T10:00:00.000Z',
                endTime: '2023-07-26T10:50:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-20T13:50:16.905Z',
                updatedAt: '2023-07-20T13:50:16.905Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: false,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '63bc3704135c53002a2f4451',
                startTime: '2023-07-26T10:50:00.000Z',
                endTime: '2023-07-26T11:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64b920f925fa4b001275e06c',
                scheduleBundleId: null,
                coachId: '63bc3704135c53002a2f4451',
                memberId: '641da5799336e00012831684',
                alias: '이민애',
                startTime: '2023-07-26T11:00:00.000Z',
                endTime: '2023-07-26T11:50:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-20T11:56:41.213Z',
                updatedAt: '2023-07-20T11:56:41.213Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '63bc3704135c53002a2f4451',
                startTime: '2023-07-26T11:50:00.000Z',
                endTime: '2023-07-26T12:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64bb6fb28090fe001935b980',
                scheduleBundleId: null,
                coachId: '63bc3704135c53002a2f4451',
                memberId: '6408852e4be3fa00772add9b',
                alias: '고근정',
                startTime: '2023-07-26T12:00:00.000Z',
                endTime: '2023-07-26T12:50:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-22T05:57:06.871Z',
                updatedAt: '2023-07-22T05:57:06.871Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: false,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '63bc3704135c53002a2f4451',
                startTime: '2023-07-26T12:50:00.000Z',
                endTime: '2023-07-26T15:00:00.000Z',
                duration: 130,
            },
        ],
    },
    {
        coachId: '64254c6a7afb66006c5fe79d',
        coachName: '강사4',
        coachProfileImageUrl: '',
        schedules: [
            {
                type: 'emptySchedule',
                coachId: '64254c6a7afb66006c5fe79d',
                startTime: '2023-07-26T00:00:00.000Z',
                endTime: '2023-07-26T02:00:00.000Z',
                duration: 120,
            },
            {
                _id: '64b91d8a04a3c4002afb450d',
                scheduleBundleId: null,
                coachId: '64254c6a7afb66006c5fe79d',
                memberId: '64352bc579c01500a2039cb7',
                alias: '정병준',
                startTime: '2023-07-26T02:00:00.000Z',
                endTime: '2023-07-26T02:50:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-20T11:42:02.635Z',
                updatedAt: '2023-07-20T11:42:02.635Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '64254c6a7afb66006c5fe79d',
                startTime: '2023-07-26T02:50:00.000Z',
                endTime: '2023-07-26T07:00:00.000Z',
                duration: 250,
            },
            {
                _id: '64bc9da904a3c4002afc3e7a',
                scheduleBundleId: null,
                coachId: '64254c6a7afb66006c5fe79d',
                memberId: '642e79e71215e700298616c1',
                alias: '이동연',
                startTime: '2023-07-26T07:00:00.000Z',
                endTime: '2023-07-26T07:50:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-23T03:25:29.596Z',
                updatedAt: '2023-07-23T03:25:29.596Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: false,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '64254c6a7afb66006c5fe79d',
                startTime: '2023-07-26T07:50:00.000Z',
                endTime: '2023-07-26T08:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64bd0d848090fe00193636b7',
                scheduleBundleId: null,
                coachId: '64254c6a7afb66006c5fe79d',
                memberId: '642fe8e1557b580012bd23f5',
                alias: '최윤정',
                startTime: '2023-07-26T08:00:00.000Z',
                endTime: '2023-07-26T08:50:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-23T11:22:44.337Z',
                updatedAt: '2023-07-23T11:22:44.337Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '64254c6a7afb66006c5fe79d',
                startTime: '2023-07-26T08:50:00.000Z',
                endTime: '2023-07-26T09:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64b91eb525fa4b001275deb9',
                scheduleBundleId: null,
                coachId: '64254c6a7afb66006c5fe79d',
                memberId: '6460ec3aa9d8c600828b1ad6',
                alias: '박한별',
                startTime: '2023-07-26T09:00:00.000Z',
                endTime: '2023-07-26T09:50:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-20T11:47:01.954Z',
                updatedAt: '2023-07-20T11:47:01.954Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '64254c6a7afb66006c5fe79d',
                startTime: '2023-07-26T09:50:00.000Z',
                endTime: '2023-07-26T10:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64b91e7925fa4b001275de8c',
                scheduleBundleId: null,
                coachId: '64254c6a7afb66006c5fe79d',
                memberId: '6471ba470be48200122703c4',
                alias: '김희찬',
                startTime: '2023-07-26T10:00:00.000Z',
                endTime: '2023-07-26T10:50:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-20T11:46:01.234Z',
                updatedAt: '2023-07-20T11:46:01.234Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '64254c6a7afb66006c5fe79d',
                startTime: '2023-07-26T10:50:00.000Z',
                endTime: '2023-07-26T11:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64b91e27d7d6a10019ff928b',
                scheduleBundleId: null,
                coachId: '64254c6a7afb66006c5fe79d',
                memberId: '642e94d0557b580012bc9357',
                alias: '이은지',
                startTime: '2023-07-26T11:00:00.000Z',
                endTime: '2023-07-26T11:50:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-20T11:44:39.321Z',
                updatedAt: '2023-07-20T11:44:39.321Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: false,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '64254c6a7afb66006c5fe79d',
                startTime: '2023-07-26T11:50:00.000Z',
                endTime: '2023-07-26T12:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64b91dfa04a3c4002afb4545',
                scheduleBundleId: null,
                coachId: '64254c6a7afb66006c5fe79d',
                memberId: '64b157e491f76200120d446f',
                alias: '김동민',
                startTime: '2023-07-26T12:00:00.000Z',
                endTime: '2023-07-26T12:50:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-20T11:43:54.703Z',
                updatedAt: '2023-07-20T11:43:54.703Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '64254c6a7afb66006c5fe79d',
                startTime: '2023-07-26T12:50:00.000Z',
                endTime: '2023-07-26T13:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64bbb6368090fe001935d5c4',
                scheduleBundleId: null,
                coachId: '64254c6a7afb66006c5fe79d',
                memberId: '64323243b1f41c002aa895ab',
                alias: '형대우',
                startTime: '2023-07-26T13:00:00.000Z',
                endTime: '2023-07-26T13:50:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-22T10:57:58.828Z',
                updatedAt: '2023-07-22T10:57:58.828Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: false,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '64254c6a7afb66006c5fe79d',
                startTime: '2023-07-26T13:50:00.000Z',
                endTime: '2023-07-26T14:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64b91e198090fe0019351634',
                scheduleBundleId: null,
                coachId: '64254c6a7afb66006c5fe79d',
                memberId: '642e7b2bb1f41c002aa762cd',
                alias: '남성현',
                startTime: '2023-07-26T14:00:00.000Z',
                endTime: '2023-07-26T14:50:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-20T11:44:25.652Z',
                updatedAt: '2023-07-20T11:44:25.652Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '64254c6a7afb66006c5fe79d',
                startTime: '2023-07-26T14:50:00.000Z',
                endTime: '2023-07-26T15:00:00.000Z',
                duration: 10,
            },
        ],
    },
    {
        coachId: '642596251f90e500615765dd',
        coachName: '강사5',
        coachProfileImageUrl: '',
        schedules: [
            {
                type: 'emptySchedule',
                coachId: '642596251f90e500615765dd',
                startTime: '2023-07-25T15:00:00.000Z',
                endTime: '2023-07-26T01:00:00.000Z',
                duration: 600,
            },
            {
                type: 'emptySchedule',
                coachId: '642596251f90e500615765dd',
                startTime: '2023-07-26T01:50:00.000Z',
                endTime: '2023-07-26T02:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64b0c08754d0580018e77d6d',
                scheduleBundleId: null,
                coachId: '642596251f90e500615765dd',
                memberId: '64787eb2975144001275309a',
                alias: '위나라',
                startTime: '2023-07-26T02:00:00.000Z',
                endTime: '2023-07-26T02:50:00.000Z',
                requestDeadline: '2023-07-25T12:00:00.000Z',
                createdAt: '2023-07-14T03:27:03.852Z',
                updatedAt: '2023-07-14T03:27:03.852Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '642596251f90e500615765dd',
                startTime: '2023-07-26T02:50:00.000Z',
                endTime: '2023-07-26T03:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64b3646e91f76200120dd652',
                scheduleBundleId: null,
                coachId: '642596251f90e500615765dd',
                memberId: '64955d4e8c5fb8007755b1dd',
                alias: '한정',
                startTime: '2023-07-26T08:00:00.000Z',
                endTime: '2023-07-26T08:50:00.000Z',
                requestDeadline: '2023-07-25T12:00:00.000Z',
                createdAt: '2023-07-16T03:30:54.459Z',
                updatedAt: '2023-07-16T03:30:54.459Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '642596251f90e500615765dd',
                startTime: '2023-07-26T08:50:00.000Z',
                endTime: '2023-07-26T09:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64c08cacd7d6a1001901fd74',
                scheduleBundleId: null,
                coachId: '642596251f90e500615765dd',
                memberId: '6426b4331f90e5006157bdd0',
                alias: '허준서',
                startTime: '2023-07-26T09:00:00.000Z',
                endTime: '2023-07-26T09:50:00.000Z',
                requestDeadline: '2023-07-25T12:00:00.000Z',
                createdAt: '2023-07-26T03:02:04.179Z',
                updatedAt: '2023-07-26T03:02:04.179Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '642596251f90e500615765dd',
                startTime: '2023-07-26T09:50:00.000Z',
                endTime: '2023-07-26T10:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64b48dd525fa4b001273fd8d',
                scheduleBundleId: null,
                coachId: '642596251f90e500615765dd',
                memberId: '64886a9936e0e700134e9af4',
                alias: '최현욱',
                startTime: '2023-07-26T11:00:00.000Z',
                endTime: '2023-07-26T11:50:00.000Z',
                requestDeadline: '2023-07-25T12:00:00.000Z',
                createdAt: '2023-07-17T00:39:49.834Z',
                updatedAt: '2023-07-17T00:39:49.834Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '642596251f90e500615765dd',
                startTime: '2023-07-26T11:50:00.000Z',
                endTime: '2023-07-26T12:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64c0aa908090fe001937a72d',
                scheduleBundleId: null,
                coachId: '642596251f90e500615765dd',
                memberId: '6426b1fb7afb66006c608275',
                alias: '최재준',
                startTime: '2023-07-26T12:00:00.000Z',
                endTime: '2023-07-26T12:50:00.000Z',
                requestDeadline: '2023-07-25T12:00:00.000Z',
                createdAt: '2023-07-26T05:09:36.813Z',
                updatedAt: '2023-07-26T05:09:36.813Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: false,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '642596251f90e500615765dd',
                startTime: '2023-07-26T12:50:00.000Z',
                endTime: '2023-07-26T13:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64ba1e28d7d6a10019ffd3af',
                scheduleBundleId: null,
                coachId: '642596251f90e500615765dd',
                memberId: '649bdacd633bb30061ca9e13',
                alias: '경은정',
                startTime: '2023-07-26T13:00:00.000Z',
                endTime: '2023-07-26T13:50:00.000Z',
                requestDeadline: '2023-07-25T12:00:00.000Z',
                createdAt: '2023-07-21T05:56:56.242Z',
                updatedAt: '2023-07-21T05:56:56.242Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '642596251f90e500615765dd',
                startTime: '2023-07-26T13:50:00.000Z',
                endTime: '2023-07-26T14:00:00.000Z',
                duration: 10,
            },
        ],
    },
    {
        coachId: '63be82639b70e900193e4bd6',
        coachName: '강사6',
        coachProfileImageUrl: '',
        schedules: [
            {
                type: 'emptySchedule',
                coachId: '63be82639b70e900193e4bd6',
                startTime: '2023-07-26T00:00:00.000Z',
                endTime: '2023-07-26T01:00:00.000Z',
                duration: 60,
            },
            {
                _id: '64bc9b6425fa4b001276de0d',
                scheduleBundleId: null,
                coachId: '63be82639b70e900193e4bd6',
                memberId: '64acbd7e9d3824007791506d',
                alias: '박지성',
                startTime: '2023-07-26T01:00:00.000Z',
                endTime: '2023-07-26T01:30:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-23T03:15:48.741Z',
                updatedAt: '2023-07-23T03:17:51.445Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: false,
                type: 'schedule',
                duration: 30,
                currentSession: null,
                totalSession: null,
            },
            {
                _id: '64bdecfc04a3c4002afc9ce7',
                scheduleBundleId: null,
                coachId: '63be82639b70e900193e4bd6',
                memberId: '644b7b1a7e771f0036913a79',
                alias: '권민서',
                startTime: '2023-07-26T01:30:00.000Z',
                endTime: '2023-07-26T02:00:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-24T03:16:12.491Z',
                updatedAt: '2023-07-24T03:16:47.581Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                currentSession: null,
                totalSession: null,
                type: 'schedule',
                duration: 30,
            },
            {
                type: 'emptySchedule',
                coachId: '63be82639b70e900193e4bd6',
                startTime: '2023-07-26T02:00:00.000Z',
                endTime: '2023-07-26T02:30:00.000Z',
                duration: 30,
            },
            {
                _id: '64bb7689d7d6a10019003d7e',
                scheduleBundleId: null,
                coachId: '63be82639b70e900193e4bd6',
                memberId: '64659cc06203c8010631c793',
                alias: '이래원',
                startTime: '2023-07-26T02:30:00.000Z',
                endTime: '2023-07-26T03:00:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-22T06:26:17.469Z',
                updatedAt: '2023-07-22T06:27:25.736Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: false,
                type: 'schedule',
                duration: 30,
                currentSession: null,
                totalSession: null,
            },
            {
                _id: '64bf17a325fa4b001277adbe',
                scheduleBundleId: null,
                coachId: '63be82639b70e900193e4bd6',
                memberId: '64abb3cbd63356004b471ab7',
                alias: '이상엽',
                startTime: '2023-07-26T03:00:00.000Z',
                endTime: '2023-07-26T03:30:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-25T00:30:27.899Z',
                updatedAt: '2023-07-25T00:31:29.512Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: false,
                type: 'schedule',
                duration: 30,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '63be82639b70e900193e4bd6',
                startTime: '2023-07-26T03:30:00.000Z',
                endTime: '2023-07-26T04:30:00.000Z',
                duration: 60,
            },
            {
                _id: '64bc9ba9d7d6a100190088cc',
                scheduleBundleId: null,
                coachId: '63be82639b70e900193e4bd6',
                memberId: '649fb4201617bf006cfdc110',
                alias: '강현아',
                startTime: '2023-07-26T04:30:00.000Z',
                endTime: '2023-07-26T05:00:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-23T03:16:57.237Z',
                updatedAt: '2023-07-23T03:17:25.305Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                currentSession: null,
                totalSession: null,
                type: 'schedule',
                duration: 30,
            },
            {
                type: 'emptySchedule',
                coachId: '63be82639b70e900193e4bd6',
                startTime: '2023-07-26T05:00:00.000Z',
                endTime: '2023-07-26T05:30:00.000Z',
                duration: 30,
            },
            {
                _id: '64bb37f325fa4b0012767258',
                scheduleBundleId: null,
                coachId: '63be82639b70e900193e4bd6',
                memberId: '64ba19798090fe001935535c',
                alias: '라유민',
                startTime: '2023-07-26T05:30:00.000Z',
                endTime: '2023-07-26T06:00:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-22T01:59:15.545Z',
                updatedAt: '2023-07-22T02:00:24.964Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                currentSession: null,
                totalSession: null,
                type: 'schedule',
                duration: 30,
            },
            {
                _id: '64bb4c8ed7d6a100190028b1',
                scheduleBundleId: null,
                coachId: '63be82639b70e900193e4bd6',
                memberId: '646f0939c3cc0400195247e3',
                alias: '조휘윤',
                startTime: '2023-07-26T06:00:00.000Z',
                endTime: '2023-07-26T06:30:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-22T03:27:10.347Z',
                updatedAt: '2023-07-22T03:28:07.942Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                currentSession: null,
                totalSession: null,
                type: 'schedule',
                duration: 30,
            },
            {
                _id: '64bded0204a3c4002afc9d01',
                scheduleBundleId: null,
                coachId: '63be82639b70e900193e4bd6',
                memberId: '644b7de26579b100616e4af9',
                alias: '배창욱',
                startTime: '2023-07-26T06:30:00.000Z',
                endTime: '2023-07-26T07:00:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-24T03:16:18.471Z',
                updatedAt: '2023-07-24T03:16:31.827Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                currentSession: null,
                totalSession: null,
                type: 'schedule',
                duration: 30,
            },
            {
                _id: '64c065838090fe00193783a2',
                scheduleBundleId: null,
                coachId: '63be82639b70e900193e4bd6',
                memberId: '644b78e95345c3002b8da926',
                alias: '김혜영',
                startTime: '2023-07-26T07:00:00.000Z',
                endTime: '2023-07-26T07:50:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-26T00:14:59.320Z',
                updatedAt: '2023-07-26T00:14:59.320Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                currentSession: 6,
                totalSession: 20,
                type: 'schedule',
                duration: 50,
            },
            {
                type: 'emptySchedule',
                coachId: '63be82639b70e900193e4bd6',
                startTime: '2023-07-26T07:50:00.000Z',
                endTime: '2023-07-26T09:30:00.000Z',
                duration: 100,
            },
            {
                _id: '64bb33c2d7d6a100190015bf',
                scheduleBundleId: null,
                coachId: '63be82639b70e900193e4bd6',
                memberId: '63d6f9fd9b70e9001940cccc',
                alias: '임주환',
                startTime: '2023-07-26T09:30:00.000Z',
                endTime: '2023-07-26T10:00:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-22T01:41:22.256Z',
                updatedAt: '2023-07-22T01:41:30.574Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                currentSession: null,
                totalSession: null,
                type: 'schedule',
                duration: 30,
            },
            {
                _id: '64c0de4f04a3c4002afdd627',
                scheduleBundleId: null,
                coachId: '63be82639b70e900193e4bd6',
                memberId: '6490681fe3ddf4002b1b1c87',
                alias: '김주리',
                startTime: '2023-07-26T10:00:00.000Z',
                endTime: '2023-07-26T10:30:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-26T08:50:23.320Z',
                updatedAt: '2023-07-26T09:17:22.667Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: false,
                type: 'schedule',
                duration: 30,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '63be82639b70e900193e4bd6',
                startTime: '2023-07-26T10:30:00.000Z',
                endTime: '2023-07-26T11:00:00.000Z',
                duration: 30,
            },
            {
                _id: '64bdbefb25fa4b0012772c31',
                scheduleBundleId: null,
                coachId: '63be82639b70e900193e4bd6',
                memberId: '64772eee44544d001920b746',
                alias: '주현주',
                startTime: '2023-07-26T11:00:00.000Z',
                endTime: '2023-07-26T11:30:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-23T23:59:55.090Z',
                updatedAt: '2023-07-24T06:37:30.341Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                currentSession: null,
                totalSession: null,
                type: 'schedule',
                duration: 30,
            },
            {
                type: 'emptySchedule',
                coachId: '63be82639b70e900193e4bd6',
                startTime: '2023-07-26T11:30:00.000Z',
                endTime: '2023-07-26T12:00:00.000Z',
                duration: 30,
            },
            {
                _id: '64bb766f04a3c4002afbf0a5',
                scheduleBundleId: null,
                coachId: '63be82639b70e900193e4bd6',
                memberId: '644bb2195345c3002b8dbf7f',
                alias: '이서영',
                startTime: '2023-07-26T12:00:00.000Z',
                endTime: '2023-07-26T12:30:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-22T06:25:51.414Z',
                updatedAt: '2023-07-22T06:28:00.369Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                currentSession: null,
                totalSession: null,
                type: 'schedule',
                duration: 30,
            },
            {
                _id: '64c06e968090fe00193786cc',
                scheduleBundleId: null,
                coachId: '63be82639b70e900193e4bd6',
                memberId: '6470c1360be482001226d9e4',
                alias: '김현창',
                startTime: '2023-07-26T12:30:00.000Z',
                endTime: '2023-07-26T13:00:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-26T00:53:42.535Z',
                updatedAt: '2023-07-26T00:53:51.232Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: false,
                type: 'schedule',
                duration: 30,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '63be82639b70e900193e4bd6',
                startTime: '2023-07-26T13:00:00.000Z',
                endTime: '2023-07-26T14:00:00.000Z',
                duration: 60,
            },
            {
                type: 'emptySchedule',
                coachId: '63be82639b70e900193e4bd6',
                startTime: '2023-07-27T00:00:00.000Z',
                endTime: '2023-07-27T14:00:00.000Z',
                duration: 840,
            },
        ],
    },
    {
        coachId: '64812363cec7d000770b5d72',
        coachName: '강사7',
        coachProfileImageUrl: '',
        schedules: [
            {
                type: 'emptySchedule',
                coachId: '64812363cec7d000770b5d72',
                startTime: '2023-07-26T00:00:00.000Z',
                endTime: '2023-07-26T00:10:00.000Z',
                duration: 10,
            },
            {
                _id: '64baa3e88090fe001935839c',
                scheduleBundleId: null,
                coachId: '64812363cec7d000770b5d72',
                memberId: '6489ab3eac2a3e00133c1046',
                alias: '송진아',
                startTime: '2023-07-26T00:10:00.000Z',
                endTime: '2023-07-26T01:00:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-21T15:27:36.125Z',
                updatedAt: '2023-07-25T23:51:01.257Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                currentSession: 12,
                totalSession: 45,
                type: 'schedule',
                duration: 50,
            },
            {
                type: 'emptySchedule',
                coachId: '64812363cec7d000770b5d72',
                startTime: '2023-07-26T01:00:00.000Z',
                endTime: '2023-07-26T02:00:00.000Z',
                duration: 60,
            },
            {
                _id: '64bf6e178090fe0019371b4a',
                scheduleBundleId: null,
                coachId: '64812363cec7d000770b5d72',
                memberId: '64814eab9200d6008238704a',
                alias: '김현주',
                startTime: '2023-07-26T02:00:00.000Z',
                endTime: '2023-07-26T02:50:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-25T06:39:19.887Z',
                updatedAt: '2023-07-25T06:39:19.887Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                currentSession: 41,
                totalSession: 51,
                type: 'schedule',
                duration: 50,
            },
            {
                type: 'emptySchedule',
                coachId: '64812363cec7d000770b5d72',
                startTime: '2023-07-26T02:50:00.000Z',
                endTime: '2023-07-26T03:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64b62ca48090fe001933e469',
                scheduleBundleId: null,
                coachId: '64812363cec7d000770b5d72',
                memberId: '6481265bcec7d000770b5e68',
                alias: '이지연',
                startTime: '2023-07-26T03:00:00.000Z',
                endTime: '2023-07-26T03:50:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-18T06:09:40.590Z',
                updatedAt: '2023-07-18T06:09:40.590Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                currentSession: 11,
                totalSession: 20,
                type: 'schedule',
                duration: 50,
            },
            {
                type: 'emptySchedule',
                coachId: '64812363cec7d000770b5d72',
                startTime: '2023-07-26T03:50:00.000Z',
                endTime: '2023-07-26T04:30:00.000Z',
                duration: 40,
            },
            {
                _id: '64b62c017a2f110012ee9f47',
                scheduleBundleId: null,
                coachId: '64812363cec7d000770b5d72',
                memberId: '64813cf4d59539004b94983f',
                alias: '최주희',
                startTime: '2023-07-26T04:30:00.000Z',
                endTime: '2023-07-26T05:20:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-18T06:06:57.172Z',
                updatedAt: '2023-07-25T06:01:21.063Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                currentSession: 37,
                totalSession: 52,
                type: 'schedule',
                duration: 50,
            },
            {
                type: 'emptySchedule',
                coachId: '64812363cec7d000770b5d72',
                startTime: '2023-07-26T05:20:00.000Z',
                endTime: '2023-07-26T05:30:00.000Z',
                duration: 10,
            },
            {
                _id: '64b62cbb7a2f110012eea06f',
                scheduleBundleId: null,
                coachId: '64812363cec7d000770b5d72',
                memberId: '649bab598d6af0004b6564fa',
                alias: '유지연',
                startTime: '2023-07-26T05:30:00.000Z',
                endTime: '2023-07-26T06:20:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-18T06:10:03.119Z',
                updatedAt: '2023-07-18T06:10:03.119Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                currentSession: 6,
                totalSession: 10,
                type: 'schedule',
                duration: 50,
            },
            {
                type: 'emptySchedule',
                coachId: '64812363cec7d000770b5d72',
                startTime: '2023-07-26T06:20:00.000Z',
                endTime: '2023-07-26T09:00:00.000Z',
                duration: 160,
            },
            {
                _id: '64b62c848090fe001933e38e',
                scheduleBundleId: null,
                coachId: '64812363cec7d000770b5d72',
                memberId: '6486ea21e19d730019bf2967',
                alias: '미소',
                startTime: '2023-07-26T09:00:00.000Z',
                endTime: '2023-07-26T09:50:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-18T06:09:08.114Z',
                updatedAt: '2023-07-18T06:09:08.114Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: false,
                type: 'schedule',
                duration: 50,
                currentSession: null,
                totalSession: null,
            },
            {
                type: 'emptySchedule',
                coachId: '64812363cec7d000770b5d72',
                startTime: '2023-07-26T09:50:00.000Z',
                endTime: '2023-07-26T10:30:00.000Z',
                duration: 40,
            },
            {
                _id: '64b64cdb8090fe001933fa40',
                scheduleBundleId: null,
                coachId: '64812363cec7d000770b5d72',
                memberId: '648305929200d60082394085',
                alias: '조은영',
                startTime: '2023-07-26T10:30:00.000Z',
                endTime: '2023-07-26T11:20:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-18T08:27:07.653Z',
                updatedAt: '2023-07-26T10:12:47.615Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                currentSession: 23,
                totalSession: 40,
                type: 'schedule',
                duration: 50,
            },
            {
                type: 'emptySchedule',
                coachId: '64812363cec7d000770b5d72',
                startTime: '2023-07-26T11:20:00.000Z',
                endTime: '2023-07-26T12:00:00.000Z',
                duration: 40,
            },
            {
                _id: '64b62c607a2f110012ee9fde',
                scheduleBundleId: null,
                coachId: '64812363cec7d000770b5d72',
                memberId: '6481298ade345e008db8bee0',
                alias: '임보라',
                startTime: '2023-07-26T12:00:00.000Z',
                endTime: '2023-07-26T12:50:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-18T06:08:32.105Z',
                updatedAt: '2023-07-18T06:08:32.105Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                currentSession: 19,
                totalSession: 20,
                type: 'schedule',
                duration: 50,
            },
            {
                type: 'emptySchedule',
                coachId: '64812363cec7d000770b5d72',
                startTime: '2023-07-26T12:50:00.000Z',
                endTime: '2023-07-26T13:00:00.000Z',
                duration: 10,
            },
            {
                _id: '64b62c777a2f110012eea004',
                scheduleBundleId: null,
                coachId: '64812363cec7d000770b5d72',
                memberId: '64812530d59539004b948b55',
                alias: '김하연',
                startTime: '2023-07-26T13:00:00.000Z',
                endTime: '2023-07-26T13:50:00.000Z',
                requestDeadline: '2023-07-25T14:00:00.000Z',
                createdAt: '2023-07-18T06:08:55.515Z',
                updatedAt: '2023-07-18T06:08:55.515Z',
                __v: 0,
                isCanceled: false,
                isDeleted: false,
                isConnected: true,
                currentSession: 28,
                totalSession: 70,
                type: 'schedule',
                duration: 50,
            },
            {
                type: 'emptySchedule',
                coachId: '64812363cec7d000770b5d72',
                startTime: '2023-07-26T13:50:00.000Z',
                endTime: '2023-07-26T14:00:00.000Z',
                duration: 10,
            },
        ],
    },
];

dailySchedulesDummyData.map((coachScheudles) => {
    coachScheudles.schedules.map((schdule) => {
        if (!!schdule.alias) {
            schdule.alias = schdule.alias.substr(0, 1) + 'OO';
        }

        const startDay = dayjs(schdule.startTime).day();
        const startHour = dayjs(schdule.startTime).get('hour');
        const startMinute = dayjs(schdule.startTime).get('minute');
        schdule.startTime = dayjs()
            .startOf('week')
            .add(startDay, 'day')
            .add(startHour, 'hour')
            .add(startMinute, 'minute')
            .toISOString();

        const endDay = dayjs(schdule.endTime).day();
        const endHour = dayjs(schdule.endTime).get('hour');
        const endMinute = dayjs(schdule.endTime).get('minute');
        schdule.endTime = dayjs()
            .startOf('week')
            .add(endDay, 'day')
            .add(endHour, 'hour')
            .add(endMinute, 'minute')
            .toISOString();
    });
});

const dailySchedulesCountDummyData = 57;

export { dailySchedulesDummyData, dailySchedulesCountDummyData };
