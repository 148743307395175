import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { colors } from '../../../lib/colors';
import { Col } from '../../../lib/utils';
import dayjs from 'dayjs';

import ScheduleHistoryEmptyGraph from './ScheduleHistoryEmptyGraph';

const DailyScheduleHistoryGraph = ({ dailyFilterData, dailyEntireData, todayMaxCount }: any) => {
    const CustomTick = (tick: any) => {
        const { x, y, value } = tick;

        let findList = null;
        findList = dailyEntireData.find((e: any) => e.xLabel === value);

        return (
            <g transform={`translate(${x},${y})`} style={{ textAnchor: 'middle' }}>
                {findList?.isToday && <rect x={-12} y={56} fill={colors.primary} width={24} height={24} rx={12} />}
                <text
                    x={0}
                    y={43}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{
                        fontSize: 28,
                        fontWeight: 700,
                        fill: findList?.isToday
                            ? colors.LAVEL_4
                            : dayjs(findList.startDate).isAfter(dayjs())
                            ? colors.GREY_100
                            : colors.LAVEL_2,
                        outlineWidth: 0,
                    }}
                >
                    {findList && findList?.xSubLabel.replace('일', '')}
                </text>
                <text
                    x={0}
                    y={70}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{
                        fontSize: 12,
                        fill: findList?.isToday
                            ? colors.WHITE_50
                            : dayjs(findList.startDate).isAfter(dayjs())
                            ? colors.GREY_100
                            : colors.LAVEL_2,

                        outlineWidth: 0,
                        fontWeight: 400,
                    }}
                >
                    {value}
                </text>
            </g>
        );
    };

    return (
        <Col
            style={{
                width: 1192,
                height: 614,
                marginTop: 25,
            }}
        >
            {dailyFilterData && dailyEntireData.length === 0 ? (
                <ScheduleHistoryEmptyGraph />
            ) : (
                <ResponsiveLine
                    data={dailyFilterData}
                    margin={{ right: 70, top: 15, bottom: 100, left: 90 }}
                    xScale={{ type: 'point' }}
                    yScale={{
                        type: 'linear',
                        min: 0,
                        max: todayMaxCount < 10 ? 10 : todayMaxCount,
                        stacked: false,
                        reverse: false,
                    }}
                    yFormat=" >-.2f"
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 0,
                        tickPadding: 5,
                        tickRotation: 0,
                        renderTick: CustomTick,
                    }} // 바텀 라벨의 스타일
                    axisLeft={{
                        tickSize: 0,
                        tickPadding: 25,
                        tickRotation: 0,
                    }}
                    isInteractive={true}
                    enableGridX={false}
                    colors={(d) => d.color} // 선의 색상을 데이터의 color 속성으로 설정
                    pointSize={8}
                    pointBorderWidth={2}
                    // pointBorderColor={{ from: 'serieColor' }}  // 점굵게하는거
                    pointLabelYOffset={-12}
                    useMesh={false}
                />
            )}
        </Col>
    );
};

export default DailyScheduleHistoryGraph;
