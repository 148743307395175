import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { getItemWithExpiration } from '../lib/utils';

import { modifyTrainerProfileAPI, ModifyTrainerProfileParamsType } from '../api/employee';
import { EmployeeProfile } from './useEmployeeQueryService';

type Props = {
    onModifyTrainerProfileSuccess?: (data: EmployeeProfile) => void;
};

const useEmployeeMutationService = ({ onModifyTrainerProfileSuccess }: Props) => {
    const [authObject] = useState(getItemWithExpiration('authObject'));
    const [authorization] = useState(getItemWithExpiration('authorization'));

    // 임시회원 생성
    const modifyTrainerProfileAPIMutation = useMutation(
        ['modifyTrainerProfileAPIMutation'],
        async (params: ModifyTrainerProfileParamsType) =>
            modifyTrainerProfileAPI({ ...params, accessToken: authorization }),
        {
            onError: (error) => {
                console.log(error);
            },
            onSuccess: async ({ data, status }) => {
                if (status === 200) {
                    onModifyTrainerProfileSuccess && onModifyTrainerProfileSuccess(data.employeeProfile);
                }
            },
        }
    );

    return { modifyTrainerProfileAPIMutation };
};

export default useEmployeeMutationService;
