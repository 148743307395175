import { Body1Bold, Body3Regular } from '../../lib/font';
import { colors } from '../../lib/colors';
import styled from 'styled-components';
import { I_ERROR, I_GREEN_FILL_ALERT } from '../../types/images';
import { Row } from '../../lib/utils';
import WhiteSquareButton from '../Button/WhiteSquareButton';
import ColorSquareButton from '../Button/ColorSquareButton';
import SvgIcon from '../SvgIcon';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2002;
`;

const ModalInner = styled.div`
    background: white;
    width: 540px;
    border-radius: 8px;
    box-shadow: 8px 8px 16px 0px #0000005c;
    position: relative; /* Ensure positioning context */
    padding-bottom: 24px; /* Add padding for the button */
`;

type Props = {
    title: string | null;
    content: string | null;
    isError?: boolean;
    buttonContent?: string | null;
    callBack?: any;
    isTwoButton: boolean;
    twoButtonContent: string | null;
    twoButtonCallBack: any;
    alertStateReset: any;
};

const ModalAlertOneButton = ({
    title,
    content,
    isError,
    buttonContent = '확인',
    callBack,
    isTwoButton,
    twoButtonContent,
    twoButtonCallBack,
    alertStateReset,
}: Props) => {
    return (
        <ModalOverLay>
            <ModalInner>
                <Row
                    style={{
                        alignItems: 'center',
                        marginLeft: 24,
                        marginTop: 24,
                        marginBottom: 16,
                    }}
                >
                    <SvgIcon name={'SvgError'} size={24} fill={isError ? colors.ERROR : colors.SUCCESS} />
                    <Body1Bold style={{ color: colors.LAVEL_4, marginLeft: 12 }}>{title}</Body1Bold>
                </Row>
                <Row
                    style={{
                        width: 456,
                        marginLeft: 60,
                        marginBottom: 20,
                        whiteSpace: 'pre-wrap',
                    }}
                >
                    <Body3Regular style={{}}>{content}</Body3Regular>
                </Row>
                {isTwoButton === true ? (
                    <div
                        style={{
                            display: 'flex',
                            width: 312,
                            marginTop: 40,
                            marginLeft: 274,
                        }}
                    >
                        <WhiteSquareButton
                            text={twoButtonContent ? twoButtonContent : '취소'}
                            size="small"
                            callBack={() => {
                                twoButtonCallBack && twoButtonCallBack();
                                alertStateReset();
                            }}
                        />
                        <ColorSquareButton
                            text={buttonContent ?? '확인'}
                            size={'small'}
                            customStyle={{ marginLeft: 16 }}
                            callBack={() => {
                                callBack && callBack();
                                alertStateReset();
                            }}
                        />
                    </div>
                ) : (
                    <ColorSquareButton
                        text={buttonContent ?? '확인'}
                        size={'small'}
                        customStyle={{ marginTop: 20, marginLeft: 403 }}
                        callBack={() => {
                            callBack && callBack();
                            alertStateReset();
                        }}
                    />
                )}
            </ModalInner>
        </ModalOverLay>
    );
};

export default ModalAlertOneButton;
