import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NormalButton from '../../components/Button/NormalButton';
import { Col, getItemWithExpiration } from '../../lib/utils';
import secureLocalStorage from 'react-secure-storage';
import { I_FILL_ALERT } from '../../types/images';
import { Body2Regular, Subtitle1Bold } from '../../lib/font';
import { colors } from '../../lib/colors';
import ColorSquareButton from '../../components/Button/ColorSquareButton';

const FailSubscriptionPlanPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));

    useEffect(() => {
        secureLocalStorage.removeItem('trainerNumber');
    }, []);

    useEffect(() => {
        if (authObject) {
            if (authObject?.usableStatus === 'trial' || authObject?.usableStatus === 'trialPaymentPending') {
                return;
            } else {
                navigate('/myPage', { replace: true });
            }
        }
    }, [authObject]);

    return (
        <div>
            <Col style={{ width: '100%', alignItems: 'center', marginTop: 176 }}>
                <img src={I_FILL_ALERT} style={{ width: 24, height: 24 }} />
                <Subtitle1Bold style={{ color: colors.LAVEL_4, marginTop: 8 }}>{'잘못된 정보입니다!'}</Subtitle1Bold>
                <Body2Regular style={{ width: 400, whiteSpace: 'pre-wrap', textAlign: 'center', marginTop: 32 }}>
                    {queryParams.get('message')}
                </Body2Regular>

                <ColorSquareButton
                    text="결제페이지 돌아가기"
                    callBack={() => {
                        navigate('/myPage/SubscriptionPlanPage', { replace: true });
                    }}
                    size="middle"
                    isVisibleLeftImg={false}
                    isVisibleRightImg={false}
                    customStyle={{
                        width: 240,
                        height: 48,
                        marginTop: 32,
                    }}
                />
            </Col>
        </div>
    );
};

export default FailSubscriptionPlanPage;
