// White_Round 버튼
import i_clicked_solid_plus from '../assets/images/button/i_clicked_solid_plus.png';
import i_default_solid_plus from '../assets/images/button/i_default_solid_plus.png';
import i_hover_solid_plus from '../assets/images/button/i_hover_solid_plus.png';
import i_disabled_solid_plus from '../assets/images/button/i_disabled_solid_plus.png';
import i_white_plus from '../assets/images/button/i_white_plus.png';

export const I_CLICKED_SOLID_PLUS = i_clicked_solid_plus;
export const I_DEFAULT_SOLID_PLUS = i_default_solid_plus;
export const I_HOVER_SOLID_PLUS = i_hover_solid_plus;
export const I_DISABLED_SOLID_PLUS = i_disabled_solid_plus;
export const I_WHITE_PLUS = i_white_plus;
