import { useEffect, useRef, useState } from 'react';
import { getItemWithExpiration } from '../../lib/utils';
import Header from '../../components/Header';
import Calendar from '../../components/Calendar/Calendar';
import DailyScheduleTop from '../../components/DailySchedule/DailyScheduleTop';
import DailyScheduleContent from '../../components/DailySchedule/DailyScheduleContent';
import TopLeftRightButton from '../../components/DailySchedule/TopLeftRightButton';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getDailyScheduleAPI } from '../../api/schedule';
import { useMutation } from '@tanstack/react-query';
import { storeUserFlow } from '../../api/adminuserflow';
import { dailySchedulesCountDummyData, dailySchedulesDummyData } from '../../components/DailySchedule/DummyData';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { useRecoilState } from 'recoil';
import { sidebarStatusState } from '../../atoms/atom';
import ModalRegisterSchedule from '../../components/DailySchedule/ModalRegisterSchedule/ModalRegisterSchedule';
dayjs.locale('ko');

const DailySchedulePage = ({ createAlert, createTwoButtonAlert, setIsLoading }: any) => {
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));
    const [sidebarStatus, setSidebarStatus] = useRecoilState(sidebarStatusState);

    const containerRef: any = useRef(null);

    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    /** 초기값 설정 */
    const [currentDate, setCurrentDate] = useState(dayjs()); // 현재시간 계속 구독하면서 그림자 생기게하는용도
    const [dailyScheduleList, setDailyScheduleList] = useState<any>(null);
    const [dailyScheduleCount, setDailyScheduleCount] = useState<number>(0);
    const [gridData, setGridData] = useState<any>(null);

    /** 일반일정 */
    const [selectedSchedule, setSelectedSchedule] = useState<any>(null);
    const [mutateType, setMutateType] = useState<'create' | 'modify' | ''>('');
    const [isRegisterModal, setIsRegisterModal] = useState<boolean>(false);

    /** 페이징 처리 */
    const pageSize = 8;

    /** 당일 */
    const [currentPage, setCurrentPage] = useState<number>(1);
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const [dailyCurrentData, setDailyCurrentData] = useState<any>(null);
    let endPage: number = 0;
    endPage = dailyScheduleList && Math.ceil(dailyScheduleList.length / pageSize);

    /** 캘린더 state */
    const [selectedDay, setSelectedDay] = useState<any>(new Date());
    const [isCalendar, setIsCalendar] = useState<boolean>(false);

    // 달력의 바깥부분 클릭시 달력모달 사라지게
    const onClickOutSide = (event: any) => {
        if (containerRef.current && !event.target.classList.contains('exclude')) {
            if (isCalendar) {
                setIsCalendar(false);
            } else {
                return;
            }
        }
    };

    const handleNextPage = () => {
        setIsHovered(false);
        if (currentPage < Math.ceil(dailyScheduleList.length / pageSize)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        setIsHovered(false);
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // 호버클릭시 : 일반일정 생성모달
    const onClickHover = (scheduleTime: any, coachName: string, coachId: string) => {
        setMutateType('create');

        console.log();

        setSelectedSchedule({
            scheduleStartTime: dayjs(scheduleTime).format('YYYY-MM-DD HH:mm'),
            coachName: coachName,
            coachId: coachId,
        });
        setIsRegisterModal(true);
    };

    // 일반일정 클릭시 : 일반일정 수정/삭제 모달
    const onClickEtcSchedule = (
        startTime: any,
        endTime: any,
        coachName: string,
        coachId: string,
        title: string,
        oneTimeScheduleId: string
    ) => {
        setMutateType('modify');
        setSelectedSchedule({
            scheduleStartTime: dayjs(startTime),
            scheduleEndTime: dayjs(endTime),
            coachName: coachName,
            coachId: coachId,
            title: title,
            oneTimeScheduleId: oneTimeScheduleId,
        });
        setIsRegisterModal(true);
    };

    // 일간 강사 스케줄 조회 API : (GET)
    const getDailySchedule = useQuery(
        ['getDailyScheduleAPI', selectedDay],
        async () =>
            await getDailyScheduleAPI(authObject.centerId, dayjs(selectedDay).format('YYYY-MM-DD'), authorization),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    setDailyScheduleList(res.data.dailySchedules);
                    setDailyScheduleCount(res.data.dailySchedulesCount);
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!!authObject?.testAccount,
            // refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    // 데모버전으로 진입시
    useEffect(() => {
        if (authObject?.testAccount) {
            setDailyScheduleList(dailySchedulesDummyData);
            setDailyScheduleCount(dailySchedulesCountDummyData);
        }
    }, [authObject?.testAccount]);

    // 강사 8명씩 자르기
    useEffect(() => {
        if (dailyScheduleList) {
            let filterData = dailyScheduleList.slice(startIndex, endIndex);
            setDailyCurrentData(filterData);
        }
    }, [dailyScheduleList, currentPage]);

    // 페이징된 데이터 기반 그리드 데이터 정제
    useEffect(() => {
        if (dailyCurrentData) {
            let grid = Array.from({ length: 24 }, (_, rowIndex) => {
                return Array.from({ length: 9 }, (_, colIndex) => ({
                    id: `${rowIndex}-${colIndex}`,
                    content:
                        colIndex === 0
                            ? rowIndex === 0
                                ? `오전 12시`
                                : rowIndex < 12
                                ? `오전 ${rowIndex}시`
                                : rowIndex === 12
                                ? `오후 ${rowIndex}시`
                                : `오후 ${rowIndex - 12}시`
                            : `${rowIndex}-${colIndex}`,
                    coachName: null,
                    coachId: null,
                    coachProfileImageUrl: null,
                }));
            });

            for (let i = 0; i < dailyCurrentData.length; i++) {
                grid[0][i + 1].coachName = dailyCurrentData[i].coachName;
                grid[0][i + 1].coachId = dailyCurrentData[i].coachId;
                grid[0][i + 1].coachProfileImageUrl = dailyCurrentData[i].coachProfileImageUrl;
            }

            setGridData(grid);
        }
    }, [dailyCurrentData]);

    // 접속 데이터 측정 API
    const pushUserFlow = useMutation({
        mutationFn: async () => await storeUserFlow('scheduleDaily', authorization),
    });
    // 접속 데이터 측정
    useEffect(() => {
        // 접속 데이터 측정
        if (pushUserFlow) {
            pushUserFlow.mutate();
        }
        // 5분마다 시간업데이트
        const updateCurrentTime = () => {
            setCurrentDate(dayjs());
        };
        updateCurrentTime();
        const intervalId = setInterval(updateCurrentTime, 60 * 1000);
        return () => clearInterval(intervalId);
    }, []);

    // 로딩 플래그
    useEffect(() => {
        if (getDailySchedule) {
            if (getDailySchedule.isLoading) {
                if (authObject?.testAccount) {
                    setIsLoading(false);
                } else {
                    setIsLoading(true);
                }
            } else {
                setIsLoading(false);
            }
        }
    }, [getDailySchedule]);

    // 화면진입시 사이드바 접기
    useEffect(() => {
        setSidebarStatus('fold');
    }, []);

    return (
        <div onClick={onClickOutSide} ref={containerRef}>
            <Header title="스케줄 관리" subTitle="일간 강사 스케줄" />
            <DailyScheduleTop dailyScheduleCount={dailyScheduleCount} sidebarStatus={sidebarStatus} />
            <DailyScheduleContent
                dailyScheduleList={dailyScheduleList}
                selectedDay={selectedDay}
                currentDate={currentDate}
                dailyCurrentData={dailyCurrentData}
                gridData={gridData}
                setIsCalendar={setIsCalendar}
                currentPage={currentPage}
                endPage={endPage}
                sidebarStatus={sidebarStatus}
                onClickHover={onClickHover}
                onClickEtcSchedule={onClickEtcSchedule}
            />

            <TopLeftRightButton
                sidebarStatus={sidebarStatus}
                dailyScheduleList={dailyScheduleList}
                currentPage={currentPage}
                endPage={endPage}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                handlePrevPage={handlePrevPage}
                handleNextPage={handleNextPage}
                isHovered={isHovered}
            />

            {isCalendar && (
                <Calendar
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    setIsCalendar={setIsCalendar}
                    isPrevMonth={true}
                    isNextMonth={true}
                />
            )}

            {/* 일반일정 등록 모달 */}
            {isRegisterModal && selectedSchedule && (
                <ModalRegisterSchedule
                    selectedDay={selectedDay}
                    selectedSchedule={selectedSchedule}
                    setSelectedSchedule={setSelectedSchedule}
                    setIsRegisterModal={setIsRegisterModal}
                    createAlert={createAlert}
                    mutateType={mutateType}
                    createTwoButtonAlert={createTwoButtonAlert}
                />
            )}
        </div>
    );
};

export default DailySchedulePage;
