import Header from '../../components/Header';
import { Col, Row, clearLocalStorage, getItemWithExpiration, setItemWithExpiration } from '../../lib/utils';
import MyPageLeft from '../../components/MyPage/MyPageLeft';
import MyPageRightTop from '../../components/MyPage/MyPageRightTop';
import MyPageRightBottom from '../../components/MyPage/MyPageRightBottom';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteAccountAPI, getMyPageInfoAPI } from '../../api/mypage';
import ModalPhoneNumberChange from '../../components/modal/ModalPhoneNumberChange';
import ModalPassWordChange from '../../components/modal/ModalPassWordChange';
import ModalWithDrawal from '../../components/modal/ModalWithDrawal';
import ModalAlert from '../../components/modal/ModalAlert';

import { useLocation, useNavigate } from 'react-router-dom';
import { storeUserFlow } from '../../api/adminuserflow';
import ModalUsingPlan from '../../components/modal/ModalUsingPlan';
import ModalCancelSubsCription from '../../components/modal/ModalCancelSubsCription';
import ModalCancelWithDrawal from '../../components/modal/ModalCancelWithDrawal';
import { cancelSubscriptionAPI, cancelSubscriptionWithdrawalAPI } from '../../api/charge';
import dayjs from 'dayjs';
import { colors } from '../../lib/colors';

const MyPage = ({ createAlert, authObject, setAuthObject }: any) => {
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    // const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const location = useLocation();
    let isUsingModalFlag = location?.state?.isSubscriptionModal;

    // 초기값 설정 : 소속 트레이너 수 , 활성 수강생 수
    const [numberTrainers, setNumberTrainers] = useState<number>(0);
    const [numberStudents, setNumberStudents] = useState<number>(0);
    const [numberActive, setNumberActive] = useState<number>(0);
    const [reasonText, setReasonText] = useState<string>('');

    // 성별 샐랙트박스 상태
    const [isOpenGender, setIsOpenGender] = useState<boolean>(false);
    // 업종 셀렉트박스 상태
    const [isOpenCategory, setIsOpenCategory] = useState<boolean>(false);

    /**
     * isPasswordChangeModal : 비밀번호 변경 모달
     * isPhoneNumberChangeModal : 휴대전화 번호 변경 모달
     * isWithDrawalModal : 탈퇴하기 모달
     * isUsingPlanModal : 구독 플랜 모달
     * isRepeatCancelSubsCription : 구독 플랜 > 구독취소 눌럿을시 모달
     * isCancelWithDrawalModal : 구독 플랜 > 구독취소 철회 눌럿을시 모달
     */
    const [isPasswordChangeModal, setIsPasswordChangeModal] = useState<boolean>(false);
    const [isPhoneNumberChangeModal, setIsPhoneNumberChangeModal] = useState<boolean>(false);
    const [isWithDrawalModal, setIsWithDrawalModal] = useState<boolean>(false);
    const [isUsingPlanModal, setIsUsingPlanModal] = useState<boolean>(false);
    const [isRepeatCancelSubsCription, setIsRepeatCancelSubsCription] = useState<boolean>(false);
    const [isCancelWithDrawalModal, setIsCancelWithDrawalModal] = useState<boolean>(false);

    const [isDeleteAlertModal, setIsDeleteAlertModal] = useState<boolean>(false);
    const [alertTitle, setAlertTitle] = useState<string>('');
    const [alertContent, setAlertContent] = useState<string>('');

    // 비밀번호 변경 버튼 클릭이벤트
    const onClickPasswordChange = () => {
        setIsPasswordChangeModal(true);
    };
    // 휴대전화 번호 변경 버튼 클릭이벤트
    const onClickPhoneChange = () => {
        setIsPhoneNumberChangeModal(true);
    };
    // 회원탈퇴 버튼 클릭이벤트
    const onClickWithDrawal = () => {
        setIsWithDrawalModal(true);
    };
    // 현재 나의 플랜 보기 버튼 클릭이벤트
    const onClickConfirmUsingPlan = () => {
        setIsUsingPlanModal(true);
    };
    // 구독플랜모달 > 구독 취소 버튼 눌럿을 경우
    const onClickRepeatCancelSubscription = () => {
        setIsRepeatCancelSubsCription(true);
    };
    // 구독 플랜 > 구독취소 철회 눌럿을시
    const onClickCancelWithDrawal = () => {
        setIsCancelWithDrawalModal(true);
    };

    // 외부클릭시 드롭다운닫기
    const onClickOutSide = () => {
        if (isOpenGender) {
            setIsOpenGender(false);
        }

        if (isOpenCategory) {
            setIsOpenCategory(false);
        }
    };

    // 회원탈퇴 버튼이유 탈퇴하기 버튼 클릭이벤트
    const onClickWithDrawalRepeat = (title: string, content: string) => {
        setAlertTitle(title);
        setAlertContent(content);
        setIsDeleteAlertModal(true);
    };

    // 진짜 회원탈퇴
    const onClickDeleteAccount = () => {
        deleteAccount.mutate(reasonText);
    };

    // 구독 취소 버튼 클릭이벤트
    const onClickCancelSubscription = () => {
        cancelSubscription.mutate();
    };

    // 구독 취소 철회 버튼 클릭이벤트
    const onClickCancelSubscriptionWithdrawal = () => {
        cancelSubscriptionWithdrawal.mutate();
    };

    //  마이페이지 정보조회 API : (GET)
    const getMyPageInformation = useQuery(
        ['getMyPageInfoAPI'],
        async () => await getMyPageInfoAPI(authObject?.centerId, authorization && authorization),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const myPageStatus = res.data;

                    setNumberTrainers(myPageStatus.employeeCount);
                    setNumberStudents(myPageStatus.allMemberCount);
                    setNumberActive(myPageStatus.activeMemberCount);
                    const authObjectData = {
                        adminId: authObject?.adminId,
                        id: authObject?.id,
                        name: authObject?.name,
                        email: authObject?.email,
                        gender: authObject?.gender,
                        phoneNumber: authObject?.phoneNumber,
                        profileImageUrl: authObject?.profileImageUrl,

                        centerAddress: authObject?.centerAddress,
                        centerCategory: authObject?.centerCategory,
                        centerCode: authObject?.centerCode,
                        centerName: authObject?.centerName,
                        centerId: authObject?.centerId,
                        isNewCommunicationBox: authObject?.isNewCommunicationBox,

                        // 구독플랜관련
                        coachCount: authObject?.coachCount,
                        memberCount: authObject?.memberCount,
                        activationDate: authObject?.activationDate,
                        expirationDate: authObject?.expirationDate,
                        expirationDelayDate: authObject?.expirationDelayDate,
                        finalPaymentDate: authObject?.finalPaymentDate,
                        finalPaymentTryDate: authObject?.finalPaymentTryDate,
                        cardIssuingBank: authObject?.cardIssuingBank,
                        cardNumber: authObject?.cardNumber,
                        planAmount: authObject?.planAmount,
                        usableStatus: myPageStatus?.usableStatus,

                        paymentDueDate: myPageStatus?.paymentDueDate,

                        /** 구독플랜 인원 정보 */
                        planLimitCount: myPageStatus?.planLimitCount, // 플랜 제한 이용 인원
                        prevPlanLimitCount: myPageStatus?.prevPlanLimitCount, // 구독 인원 변경시 변경전 플랜카운트
                        changedPlanLimitCount: myPageStatus?.changedPlanLimitCount, // 구독 인원 변경시 변경 플랜카운트

                        confirmUsableStatus: true,
                        testAccount: authObject?.testAccount,
                    };

                    setItemWithExpiration('authObject', authObjectData, 12);
                    setAuthObject(authObjectData);
                } else {
                    createAlert('오류', '마이페이지 정보조회 실패', true);
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization,
            staleTime: 0,
        }
    );

    // 회원 탈퇴 API : (PATCH)
    const deleteAccount = useMutation({
        mutationFn: async (reason: string) => await deleteAccountAPI(authObject?.adminId, reason, authorization),
        onSuccess: (res) => {
            if (res.status === 204) {
                setIsWithDrawalModal(false);
                setIsDeleteAlertModal(false);
                clearLocalStorage();
                navigate('/signIn');
                createAlert('탈퇴 완료', '정상적으로 탈퇴되었습니다.');
            } else {
                createAlert('오류', '탈퇴 진행중 오류가 발생하였습니다', true);
            }
        },
    });

    // 구독 취소 API : (PATCH)
    const cancelSubscription = useMutation({
        mutationFn: async () => await cancelSubscriptionAPI(authorization, authObject?.adminId),
        onSuccess: (res) => {
            if (res.status === 200) {
                const response = res.data;

                const authObjectData = {
                    adminId: authObject?.adminId,
                    id: authObject?.id,
                    name: authObject?.name,
                    email: authObject?.email,
                    gender: authObject?.gender,
                    phoneNumber: authObject?.phoneNumber,
                    profileImageUrl: authObject?.profileImageUrl,

                    centerAddress: authObject?.centerAddress,
                    centerCategory: authObject?.centerCategory,
                    centerCode: authObject?.centerCode,
                    centerName: authObject?.centerName,
                    centerId: authObject?.centerId,
                    isNewCommunicationBox: authObject?.isNewCommunicationBox,

                    // 구독플랜관련
                    coachCount: authObject?.coachCount,
                    memberCount: authObject?.memberCount,

                    activationDate: authObject?.activationDate,
                    expirationDate: authObject?.expirationDate,
                    expirationDelayDate: authObject?.expirationDelayDate,
                    finalPaymentDate: authObject?.finalPaymentDate,
                    finalPaymentTryDate: authObject?.finalPaymentTryDate,
                    cardIssuingBank: authObject?.cardIssuingBank,
                    cardNumber: authObject?.cardNumber,
                    planAmount: authObject?.planAmount,
                    usableStatus: response?.usableStatus,

                    paymentDueDate: response?.paymentDueDate,

                    /** 구독플랜관련 */
                    planLimitCount: authObject?.planLimitCount,
                    prevPlanLimitCount: authObject?.prevPlanLimitCount,
                    changedPlanLimitCount: authObject?.changedPlanLimitCount, // 구독 인원 변경시 변경 플랜카운트

                    confirmUsableStatus: true,
                    testAccount: authObject?.testAccount,
                };

                setItemWithExpiration('authObject', authObjectData, 12);
                setAuthObject(authObjectData);

                setIsUsingPlanModal(false);
                setIsRepeatCancelSubsCription(false);
                createAlert(
                    '구독 취소 요청 완료',
                    `${dayjs(authObject?.expirationDate).format(
                        'YYYY년 MM월 DD일'
                    )}까지 정상적으로 이용가능하며 구독 만료일까지 취소 철회가 가능합니다. 그동안 ‘라포 매니저’를 이용해주셔서 감사합니다`
                );

                queryClient.invalidateQueries({ queryKey: ['getMyPageInfoAPI'] });
            } else {
                createAlert('오류', '탈퇴 진행중 오류가 발생하였습니다', true);
            }
        },
    });

    // 구독 취소 철회 API : (PATCH)
    const cancelSubscriptionWithdrawal = useMutation({
        mutationFn: async () => await cancelSubscriptionWithdrawalAPI(authorization, authObject?.adminId),
        onSuccess: (res) => {
            if (res.status === 200) {
                const response = res.data;

                const authObjectData = {
                    adminId: authObject?.adminId,
                    id: authObject?.id,
                    name: authObject?.name,
                    email: authObject?.email,
                    gender: authObject?.gender,
                    phoneNumber: authObject?.phoneNumber,
                    profileImageUrl: authObject?.profileImageUrl,

                    centerAddress: authObject?.centerAddress,
                    centerCategory: authObject?.centerCategory,
                    centerCode: authObject?.centerCode,
                    centerName: authObject?.centerName,
                    centerId: authObject?.centerId,
                    isNewCommunicationBox: authObject?.isNewCommunicationBox,

                    // 구독플랜관련
                    coachCount: authObject?.coachCount,
                    memberCount: authObject?.memberCount,

                    activationDate: authObject?.activationDate,
                    expirationDate: authObject?.expirationDate,
                    expirationDelayDate: authObject?.expirationDelayDate,
                    finalPaymentDate: authObject?.finalPaymentDate,
                    finalPaymentTryDate: authObject?.finalPaymentTryDate,
                    cardIssuingBank: authObject?.cardIssuingBank,
                    cardNumber: authObject?.cardNumber,
                    planAmount: authObject?.planAmount,
                    usableStatus: response?.usableStatus,

                    paymentDueDate: response?.paymentDueDate,

                    planLimitCount: authObject?.planLimitCount,
                    prevPlanLimitCount: authObject?.prevPlanLimitCount,
                    changedPlanLimitCount: authObject?.changedPlanLimitCount,

                    confirmUsableStatus: true,
                    testAccount: authObject?.testAccount,
                };

                setItemWithExpiration('authObject', authObjectData, 12);
                setAuthObject(authObjectData);
                setIsUsingPlanModal(false);
                setIsCancelWithDrawalModal(false);
                createAlert(
                    '구독 취소 철회 완료',
                    `정상적으로 구독 취소 철회가 완료되었습니다\n이용해주셔서 감사합니다`
                );

                queryClient.invalidateQueries({ queryKey: ['getMyPageInfoAPI'] });
            } else {
                createAlert('오류', '탈퇴 진행중 오류가 발생하였습니다', true);
            }
        },
    });

    const pushUserFlow = useMutation({
        mutationFn: async () => await storeUserFlow('myPage', authorization),
    });

    useEffect(() => {
        if (isUsingModalFlag) {
            setIsUsingPlanModal(true);
            isUsingModalFlag = false;
        }
    }, [isUsingModalFlag]);

    useEffect(() => {
        try {
            if (pushUserFlow) {
                pushUserFlow.mutate();
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    return (
        <Col
            style={{
                width: 1440,
                marginTop: 64,
                marginBottom: 150,
                backgroundColor: colors.WHITE_200,
                alignSelf: 'center',
            }}
            onClick={onClickOutSide}
        >
            <Row style={{ width: 1440, justifyContent: 'center', marginTop: 64 }}>
                <MyPageLeft
                    authorization={authorization}
                    authObject={authObject}
                    numberTrainers={numberTrainers}
                    numberStudents={numberStudents}
                    numberActive={numberActive}
                    onClickPasswordChange={onClickPasswordChange}
                    onClickWithDrawal={onClickWithDrawal}
                    onClickConfirmUsingPlan={onClickConfirmUsingPlan}
                    setAuthObject={setAuthObject}
                />

                <Col style={{ width: 888, marginLeft: 24 }}>
                    <MyPageRightTop
                        createAlert={createAlert}
                        authorization={authorization}
                        authObject={authObject}
                        setAuthObject={setAuthObject}
                        isOpenGender={isOpenGender}
                        setIsOpenGender={setIsOpenGender}
                        onClickPhoneChange={onClickPhoneChange}
                    />
                    <MyPageRightBottom
                        createAlert={createAlert}
                        authorization={authorization}
                        authObject={authObject}
                        setAuthObject={setAuthObject}
                        isOpenCategory={isOpenCategory}
                        setIsOpenCategory={setIsOpenCategory}
                        navigate={navigate}
                    />
                </Col>
            </Row>

            {/* 비밀번호 변경 모달 */}
            {isPasswordChangeModal && (
                <ModalPassWordChange
                    modalVisible={isPasswordChangeModal}
                    setModalVisible={setIsPasswordChangeModal}
                    createAlert={createAlert}
                    authorization={authorization}
                    authObject={authObject}
                />
            )}
            {/* 휴대전화 번호 변경 모달 */}
            {isPhoneNumberChangeModal && (
                <ModalPhoneNumberChange
                    modalVisible={isPhoneNumberChangeModal}
                    setModalVisible={setIsPhoneNumberChangeModal}
                    createAlert={createAlert}
                    authorization={authorization}
                    authObject={authObject}
                    setAuthObject={setAuthObject}
                />
            )}

            {/* 마이페이지 탈퇴하기 버튼 클릭시 모달 */}
            {isWithDrawalModal && (
                <ModalWithDrawal
                    modalVisible={isWithDrawalModal}
                    setModalVisible={setIsWithDrawalModal}
                    onClickWithDrawalRepeat={onClickWithDrawalRepeat}
                    reasonText={reasonText}
                    setReasonText={setReasonText}
                />
            )}

            {/* 회원탈퇴 사유 적은후 탈퇴하기 눌럿을 경우 모달 */}
            {isDeleteAlertModal && (
                <ModalAlert
                    modalVisible={isDeleteAlertModal}
                    setModalVisible={setIsDeleteAlertModal}
                    title={alertTitle}
                    content={alertContent}
                    callBack={onClickDeleteAccount}
                    isError={true}
                />
            )}

            {/* 내플랜 보기 버튼 클릭시 모달 */}
            {isUsingPlanModal && (
                <ModalUsingPlan
                    setModalVisible={setIsUsingPlanModal}
                    authObject={authObject}
                    navigate={navigate}
                    onClickRepeatCancelSubscription={onClickRepeatCancelSubscription}
                    onClickCancelWithDrawal={onClickCancelWithDrawal}
                />
            )}

            {/* 구독플랜 > 구독 취소 버튼 클릭시 모달 */}
            {isRepeatCancelSubsCription && (
                <ModalCancelSubsCription
                    authObject={authObject}
                    setModalVisible={setIsRepeatCancelSubsCription}
                    navigate={navigate}
                    onClickCancelSubscription={onClickCancelSubscription}
                />
            )}

            {/* 구독플랜 > 구독 취소 철회 버튼 클릭시 모달 */}
            {isCancelWithDrawalModal && (
                <ModalCancelWithDrawal
                    authObject={authObject}
                    setModalVisible={setIsCancelWithDrawalModal}
                    navigate={navigate}
                    onClickCancelSubscriptionWithdrawal={onClickCancelSubscriptionWithdrawal}
                />
            )}
        </Col>
    );
};

export default MyPage;
