import axios, { AxiosResponse } from 'axios';

export /**
 * @method get
 * @brief 매니저의 소통상자 조회
 */
const getCommunicationListAPI = async (accessToken: string, adminId: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/commBoxs/admin/${adminId}`,
            headers: { authorization: accessToken },
            data: {},
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method post
 * @brief 소통상자 답글 및 추가질문
 */
const createCommunicationReplyAPI = async (
    communicationBoxId: string,
    memberId: string,
    adminId: string,
    content: string,
    accessToken: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/v1/commBoxs/${communicationBoxId}/reply`,
            headers: { authorization: accessToken },
            data: {
                memberId,
                adminId,
                content,
            },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 500:
                return { status: error.response.status };
        }
    }
};

export /**
 * @method DELETE
 * @brief 소통상자 답글 삭제
 */
const deleteCommunicationReplyAPI = async (communicationBoxId: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_SERVER}/v1/commBoxs/${communicationBoxId}`,
            headers: { authorization: accessToken },
            data: {},
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 408:
                return { status: error.response.status };
            case 500:
                return { status: error.response.status };
        }
    }
};

export /**
 * @method PATCH
 * @brief 소통상자 답글 수정
 */
const editCommunicationReplyAPI = async (
    communicationBoxId: string,
    content: string,
    accessToken: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER}/v1/commBoxs/${communicationBoxId}`,
            headers: { authorization: accessToken },
            data: { content },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 407:
                return { status: error.response.status };
            case 500:
                return { status: error.response.status };
        }
    }
};
