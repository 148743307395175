import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import styled from 'styled-components';
import SvgIcon from '../SvgIcon';
import { colors } from '../../lib/colors';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalInner = styled.div`
    background: white;
    border-radius: 8px;
    box-shadow: 8px 8px 24px 0px #00000052;
`;

type Props = {
    children: React.ReactNode;
    whiteViewWidth?: number;
    whiteViewHeight?: number;
    setIsVisible?: Dispatch<SetStateAction<boolean>>;
    isCloseButton?: boolean;
    isCloseOutside?: boolean;
    onClose?: any;
};
const Modal = ({
    children,
    whiteViewWidth,
    whiteViewHeight,
    setIsVisible,
    isCloseButton = false,
    isCloseOutside = true,
    onClose,
}: Props) => {
    const ref = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node) && isCloseOutside) {
            setIsVisible && setIsVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setIsVisible && setIsVisible(false);
        }
    };
    return (
        <ModalOverLay>
            <ModalInner
                ref={ref}
                style={{
                    width: whiteViewWidth,
                    height: whiteViewHeight,
                    position: 'relative',
                }}
                className=" overflow-auto"
            >
                {isCloseButton && (
                    <SvgIcon
                        className=" absolute right-[27px] top-[27px] w-[28px] h-[28px] cursor-pointer"
                        name={'SvgX'}
                        stroke={colors.GREY_500}
                        onClick={onClose}
                    />
                )}

                {children}
            </ModalInner>
        </ModalOverLay>
    );
};

export default Modal;
