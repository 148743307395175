import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Row } from '../../lib/utils';
import { I_POPUP_EXIT } from '../../types/images';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
`;

const ModalInner = styled.div`
    background: white;
    width: 1000px;
    height: 480px;
    border-radius: 24px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    background: rgba(245, 249, 255, 1);
`;

const HoverImage = styled.img`
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

type Props = {
    setIsPictureModal: Dispatch<SetStateAction<boolean>>;
    pictureList: any;
};
const ModalPictureViewer = ({ setIsPictureModal, pictureList }: Props) => {
    const [viewPicture, setViewPicture] = useState<string>('');

    const onClickChangePicture = (url: string) => {
        setViewPicture(url);
    };

    useEffect(() => {
        if (pictureList) {
            setViewPicture(pictureList[0]);
        }
    }, [pictureList]);

    return (
        <ModalOverLay>
            <ModalInner>
                {viewPicture ? (
                    <img
                        src={viewPicture}
                        style={{ width: 834, height: 416, marginTop: 32, marginLeft: 32, objectFit: 'scale-down' }}
                    />
                ) : (
                    <div style={{ width: 834, height: 416, marginTop: 32, marginLeft: 32 }} />
                )}

                <Col style={{ height: 416, marginTop: 32 }}>
                    <HoverImage
                        src={I_POPUP_EXIT}
                        style={{ width: 52, height: 52, marginLeft: 32 }}
                        onClick={() => setIsPictureModal(false)}
                    />

                    <Col
                        style={{
                            height: 363.5,
                            marginLeft: 24,
                            justifyContent: 'flex-end',
                        }}
                    >
                        {pictureList &&
                            pictureList.map((item: any, index: number) => {
                                return (
                                    <Row
                                        key={'pictureDetail' + index}
                                        style={{
                                            width: 68,
                                            height: 68,
                                            marginTop: 16,
                                            borderRadius: 2,
                                            overflow: 'hidden',
                                            border: viewPicture === item ? '3px solid rgba(61, 115, 221, 1)' : '0',
                                        }}
                                        onClick={() => onClickChangePicture(item)}
                                    >
                                        <img src={item} style={{ width: 68, height: 68 }} />
                                    </Row>
                                );
                            })}
                    </Col>
                </Col>
            </ModalInner>
        </ModalOverLay>
    );
};

export default ModalPictureViewer;
