import React from 'react';
import { Col, Row } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import { I_EMPTY_V2 } from '../../../types/images';
import { Body3Light } from '../../../lib/font';

const FeedEmpty = () => {
    return (
        <Row
            style={{
                width: 786,
                height: 216,
                backgroundColor: colors.WHITE_200,
                borderRadius: 24,
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Col
                style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <img src={I_EMPTY_V2} style={{ width: 24, height: 24, marginBottom: 8 }} />
                <Body3Light style={{ color: colors.LAVEL_2 }}>아직 작성된 수업피드가 없어요!</Body3Light>
            </Col>
        </Row>
    );
};

export default FeedEmpty;
