import React from 'react';
import { Col, Row } from '../../lib/utils';
import { Body1Regular, Body3Regular, CaptionRegular, Subtitle1Bold } from '../../lib/font';
import { colors } from '../../lib/colors';
import { I_BOTTOM_PRIMARY_TRIANGLE, I_DOWN_TRIANGLE, I_EMPTY_PROFILE, I_PRIMARY_TRIANGLE } from '../../types/images';
import dayjs from 'dayjs';

type Props = {
    isEditMode: boolean;
    modalObject: any;
    textareaRef: any;
    editText: string;
    handleEditChange: (event: any) => void;
    onClickPicture: (item: any) => void;
};
const ModalDetailCommunicationBody = ({
    modalObject,
    isEditMode,
    textareaRef,
    editText,
    handleEditChange,
    onClickPicture,
}: Props) => {
    return (
        <>
            {modalObject.contents &&
                modalObject.contents.map((item: any, index: number) => {
                    return (
                        <Col key={'content' + index}>
                            <Row style={{ marginTop: 16 }}>
                                {isEditMode &&
                                modalObject.contents.length !== 1 &&
                                modalObject.contents.length - 1 === index ? (
                                    <textarea
                                        ref={textareaRef}
                                        style={{
                                            width: 588,
                                            // height: 104,
                                            maxHeight: 272,
                                            borderRadius: 8,
                                            border: '1px solid #648FE4',

                                            marginLeft: 60,
                                            padding: 16,
                                            fontSize: 16,
                                            color: colors.WHITE_900,
                                        }}
                                        value={editText}
                                        maxLength={1000}
                                        onChange={handleEditChange}
                                        placeholder="답변을 작성해주세요"
                                    />
                                ) : (
                                    <Col>
                                        {!(item.role === 'member') && (
                                            <Row style={{ marginLeft: 68 }}>
                                                <CaptionRegular style={{ color: colors.POINT_1, marginRight: 4 }}>
                                                    {'답변 완료'}
                                                </CaptionRegular>
                                                <img
                                                    style={{ width: 16, height: 16 }}
                                                    src={I_BOTTOM_PRIMARY_TRIANGLE}
                                                />
                                            </Row>
                                        )}

                                        <Col
                                            style={{
                                                width: 588,
                                                borderRadius: 8,
                                                border:
                                                    item.role === 'member' ? '1px solid #C7C7C7' : '1px solid #648FE4',

                                                marginLeft: 60,
                                                padding: 16,
                                                marginTop: item.role === 'member' ? 0 : 8,
                                                whiteSpace: 'pre-line',
                                            }}
                                        >
                                            <Body3Regular style={{ color: colors.LAVEL_3 }}>
                                                {item.content}
                                            </Body3Regular>
                                            <Row style={{ marginTop: 16 }}>
                                                {item.imageUrls &&
                                                    item.imageUrls.map((imageItem: any, imageIndex: number) => {
                                                        return (
                                                            <img
                                                                key={'image' + imageIndex}
                                                                src={imageItem}
                                                                style={{
                                                                    width: 68,
                                                                    height: 68,
                                                                    marginRight: 8,
                                                                }}
                                                                onClick={() => onClickPicture(item.imageUrls)}
                                                            />
                                                        );
                                                    })}
                                            </Row>
                                        </Col>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    );
                })}
        </>
    );
};

export default ModalDetailCommunicationBody;
