import React, { useState } from 'react';
import { Body3Bold, Body3Light, Body3Regular, CaptionBold, CaptionLight, CaptionRegular } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import { Col, Row, truncateString } from '../../../lib/utils';
import dayjs from 'dayjs';
import { I_GOING_ETC_SCHEDULE } from '../../../types/images';
import styled, { keyframes } from 'styled-components';
// 애니메이션 정의
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

interface StyledColProps {
    backgroundColor: string;
    isVisible: boolean;
}

// 스타일드 컴포넌트 정의
const HoverItem = styled.div<StyledColProps>`
    width: 300px;
    height: 192px;
    background-color: ${(props) => props.backgroundColor};
    display: flex;
    flex-direction: column;
    position: absolute;
    border-radius: 16px;
    box-shadow: 4px 4px 8px 0px #73737375;
    padding-left: 16px;
    padding-right: 24px;
    padding-top: 14px;
    z-index: 99;
    transition: all 1s ease;
    animation: ${(props) => (props.isVisible ? fadeIn : 'none')} 1s ease forwards;
`;

const EtcBlock = ({
    onClickEtcOffSchedule,
    parentItem,
    childItem,
    colPosition,
    rowPosition,
    boxHeight,
    parentIndex,
}: any) => {
    const [showModal, setShowModal] = useState(false);

    const isGoingSchedule = dayjs().isAfter(childItem.startTime) && dayjs().isBefore(childItem.endTime);

    const hoverStyle =
        parentIndex > 5
            ? {
                  right: '110%',
              }
            : {
                  left: '110%',
              };

    return (
        <Col
            onClick={() => {
                onClickEtcOffSchedule(childItem);
            }}
            style={{
                width: '10.4%',
                height: boxHeight,
                position: 'absolute',
                marginTop: colPosition,
                marginLeft: `${rowPosition}%`,
                borderRadius: 4,
                justifyContent: 'center',
                alignItems: 'start',
                paddingLeft: '1.11%',
                border: '2px solid #6E6E6E',
                backgroundColor: '#FAFAFA',
                boxShadow: showModal ? '0px 0px 12px 0px #73737382' : '',
                cursor: 'pointer',
            }}
            onMouseEnter={() => setShowModal(true)}
            onMouseLeave={() => setShowModal(false)}
        >
            <Row style={{ width: '100%', justifyContent: 'flex-end' }}>
                {isGoingSchedule && (
                    <img
                        style={{
                            position: 'absolute',
                            width: 24,
                            height: 18,
                            top: 0,
                        }}
                        src={I_GOING_ETC_SCHEDULE}
                    />
                )}
            </Row>
            <Col>
                <Body3Bold style={{ color: colors.LAVEL_3 }}>{childItem?.name}</Body3Bold>

                <CaptionLight style={{ color: colors.LAVEL_2 }}>{`${childItem?.duration}분`}</CaptionLight>
            </Col>

            {showModal && (
                <HoverItem style={hoverStyle} backgroundColor={colors.WHITE_50} isVisible={showModal}>
                    <Row
                        style={{
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Row style={{ width: 200 }}>
                            <Col
                                style={{
                                    width: 8,
                                    height: 22,
                                    borderRadius: 8,
                                    backgroundColor: colors.GREY_400,
                                    marginRight: 11,
                                }}
                            />
                            <Body3Bold style={{ color: colors.LAVEL_3 }}>{`${parentItem?.coachName} 강사`}</Body3Bold>
                        </Row>
                        <Row
                            style={{
                                width: 64,
                                height: 23,
                                borderRadius: 34,
                                border: '1px solid #737373',
                                color: colors.GREY_400,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <CaptionBold>{'일반일정'}</CaptionBold>
                        </Row>
                    </Row>

                    <Body3Light style={{ color: colors.LAVEL_3, marginTop: 16 }}>
                        {dayjs(childItem?.startTime).format('M월 D일(ddd)')}
                    </Body3Light>
                    <Body3Bold style={{ color: colors.LAVEL_3, marginTop: 8 }}>
                        {dayjs(childItem?.startTime).format('A hh:mm ~ ')}
                        {dayjs(childItem?.endTime).format('A hh:mm ')}
                        {`(${childItem?.duration}분)`}
                    </Body3Bold>

                    <Body3Regular style={{ width: 252, color: colors.LAVEL_3, marginTop: 8 }}>
                        {truncateString(childItem?.name, 30)}
                    </Body3Regular>
                </HoverItem>
            )}
        </Col>
    );
};

export default EtcBlock;
