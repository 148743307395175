import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { colors } from '../../lib/colors';
import {
    Body1Regular,
    Body3Light,
    Body3Regular,
    CaptionLight,
    Head4,
    Subtitle1Bold,
    Subtitle1Regular,
} from '../../lib/font';
import styled from 'styled-components';
import { Col, Row, setItemWithExpiration } from '../../lib/utils';
import { I_POPUP_EXIT } from '../../types/images';
import InputButton from '../Button/InputButton';
import { useMutation } from '@tanstack/react-query';
import { requestIssueSmsCodeAPI, requestVerifySmsCodeAPI } from '../../api/auth';
import NormalButton from '../Button/NormalButton';
import { modifyRepresentativeInformationAPI } from '../../api/mypage';
import WhiteRoundButton from '../Button/WhiteRoundButton';
import WhiteSquareButton from '../Button/WhiteSquareButton';
import ColorSquareButton from '../Button/ColorSquareButton';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalInner = styled.div`
    background: white;
    width: 880px;
    height: 536px;
    border-radius: 8px;
    box-shadow: 8px 8px 24px 0px #00000052;
`;

const HoverImage = styled.img`
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

type Props = {
    modalVisible: boolean;
    setModalVisible: Dispatch<SetStateAction<boolean>>;
    createAlert: any;
    authorization: any;
    authObject: any;
    setAuthObject: any;
};

const ModalPhoneNumberChange = ({
    modalVisible,
    setModalVisible,
    createAlert,
    authorization,
    authObject,
    setAuthObject,
}: Props) => {
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [authNumber, setAuthNumber] = useState<string>('');

    // 휴대폰 인증 확인
    const [isCheckAuthNumber, setIsCheckAuthNumber] = useState<boolean>(false);

    // 타이머관련 변수
    const initialMinutes = 3;
    const initialSeconds = 0;
    const [minutes, setMinutes] = useState(initialMinutes);
    const [seconds, setSeconds] = useState(initialSeconds);
    const [isRunning, setIsRunning] = useState(false);

    // 시간을 "03:00" 형식으로 포맷팅
    const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

    // 타이머 시작
    const startTimer = () => {
        setMinutes(initialMinutes);
        setSeconds(initialSeconds);
        setIsRunning(true);
    };

    // 타이머 초기화
    const resetTimer = () => {
        setIsRunning(false);
        setMinutes(initialMinutes);
        setSeconds(initialSeconds);
    };

    // 인증번호 발급 (POST)
    const requestIssueSmsCode = useMutation({
        mutationFn: async () => await requestIssueSmsCodeAPI(phoneNumber),
        onSuccess: (res) => {
            console.log(res);
            if (res.status === 201) {
                createAlert('전송 완료', '인증번호를 전송하였습니다.');
                startTimer();
            }
            if (res.status === 400) {
                createAlert('입력 오류', '올바른 휴대폰번호를 입력해주세요!', true);
                resetTimer();
            }
            if (res.status === 409) {
                createAlert(
                    '',
                    `동일한 휴대전화 번호로 다수의 계정을 생성할 수 없습니다\n 아이디 찾기는 카카오톡 라포 채널에 문의해주세요!`
                );
                resetTimer();
            }
            if (res.status === 418) {
                createAlert('입력 오류', '인증번호 전송 오류', true);
                resetTimer();
            }
        },
    });

    // 인증번호 검증 (POST)
    const requestVerifySmsCode = useMutation({
        mutationFn: async () => await requestVerifySmsCodeAPI(phoneNumber, authNumber),
        onSuccess: (res) => {
            if (res.status === 200) {
                setIsCheckAuthNumber(true);
                resetTimer();
                createAlert('인증 완료', '인증이 완료되었습니다');
            }
            if (res.status === 400) {
                createAlert('입력 오류', '올바른 휴대폰번호를 입력해주세요', true);
            }
            if (res.status === 404) {
                createAlert('입력 오류', '먼저 인증번호를 발급해주세요', true);
            }
            if (res.status === 409) {
                createAlert('입력 오류', '유효하지 않은 인증번호 입니다', true);
            }
            if (res.status === 418) {
                createAlert('입력 오류', '만료된 인증번호 입니다', true);
                resetTimer();
            }
        },
    });

    // 마이페이지 대표자 정보 수정(PATCH)
    const modifyRepresentativeInformationAtModal = useMutation({
        mutationFn: async () =>
            await modifyRepresentativeInformationAPI(
                authObject.adminId,
                authObject.name,
                phoneNumber,
                authObject.gender,
                authObject.email,
                authorization
            ),
        onSuccess: (res) => {
            if (res.status === 200) {
                const user = res.data.user;
                const authObjectData = {
                    adminId: user?.adminId,
                    id: user?.id,
                    name: user?.name,
                    email: user?.email,
                    gender: user?.gender,
                    phoneNumber: user?.phoneNumber,
                    profileImageUrl: user?.profileImageUrl,

                    centerAddress: authObject?.centerAddress,
                    centerCategory: authObject?.centerCategory,
                    centerCode: authObject?.centerCode,
                    centerName: authObject?.centerName,
                    centerId: authObject?.centerId,
                    isNewCommunicationBox: authObject?.isNewCommunicationBox,
                    /** 구독플랜관련 */
                    coachCount: authObject?.coachCount,
                    memberCount: authObject?.memberCount,
                    activationDate: authObject?.activationDate,
                    expirationDate: authObject?.expirationDate,
                    expirationDelayDate: authObject?.expirationDelayDate,
                    finalPaymentDate: authObject?.finalPaymentDate,
                    finalPaymentTryDate: authObject?.finalPaymentTryDate,
                    cardIssuingBank: authObject?.cardIssuingBank,
                    cardNumber: authObject?.cardNumber,
                    planAmount: authObject?.planAmount,
                    usableStatus: authObject?.usableStatus,
                    paymentDueDate: authObject?.paymentDueDate,

                    /** 구독플랜 인원 정보 */
                    planLimitCount: authObject?.planLimitCount, // 플랜 제한 이용 인원
                    prevPlanLimitCount: authObject?.prevPlanLimitCount, // 구독 인원 변경시 변경전 플랜카운트
                    changedPlanLimitCount: authObject?.changedPlanLimitCount, // 구독 인원 변경시 변경 플랜카운트

                    confirmUsableStatus: false,
                    testAccount: authObject?.testAccount,
                };
                setItemWithExpiration('authorization', authorization, 12);
                setItemWithExpiration('authObject', authObjectData, 12);
                setModalVisible(false);

                createAlert('수정 완료', '휴대폰 번호 수정을 완료하였습니다.');
            } else {
                setModalVisible(false);
                createAlert('입력 오류', '휴대폰 번호 수정중 오류가 발생하였습니다.', true);
            }
        },
    });

    // 인증번호 발급
    const handleSmsCodeRequest = () => {
        requestIssueSmsCode.mutate();
    };

    // 인증번호 검증
    const handleAuthSmsCode = () => {
        requestVerifySmsCode.mutate();
    };

    const onClickSave = () => {
        if (!isCheckAuthNumber) {
            createAlert('입력 오류', '휴대폰 인증을 진행해주세요', true);
            return;
        }

        modifyRepresentativeInformationAtModal.mutate();
    };

    // 휴대전화 번호 '-'추가 정제로직
    useEffect(() => {
        setIsCheckAuthNumber(false);
        if (phoneNumber.length === 10) {
            setPhoneNumber(phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
        }
        if (phoneNumber.length === 13) {
            setPhoneNumber(phoneNumber.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
        }
    }, [phoneNumber]);

    // 휴대폰인증 타이머
    useEffect(() => {
        let timer: any;

        if (isRunning) {
            timer = setInterval(() => {
                if (minutes === 0 && seconds === 0) {
                    clearInterval(timer);
                    setIsRunning(false);
                } else {
                    if (seconds === 0) {
                        setMinutes(minutes - 1);
                        setSeconds(59);
                    } else {
                        setSeconds(seconds - 1);
                    }
                }
            }, 1000);
        } else {
            clearInterval(timer);
        }

        return () => clearInterval(timer);
    }, [isRunning, minutes, seconds]);

    return (
        <ModalOverLay>
            <ModalInner>
                <Row style={{ marginBottom: 16 }}>
                    <Subtitle1Bold style={{ color: colors.LAVEL_4, marginLeft: 48, marginTop: 36 }}>
                        휴대전화 번호 변경
                    </Subtitle1Bold>

                    <HoverImage
                        src={I_POPUP_EXIT}
                        style={{ width: 32, height: 32, marginLeft: 600, marginTop: 16 }}
                        onClick={() => setModalVisible(false)}
                    />
                </Row>

                <Body3Regular style={{ color: colors.LAVEL_3, marginLeft: 48 }}>
                    {'휴대전화 번호를 바꾸셨나요? 새롭게 인증해주세요!'}
                </Body3Regular>

                {/* 휴대폰 번호, 인증 */}

                <Col style={{ marginLeft: 67, marginTop: 48 }}>
                    <label htmlFor="phoneNumber" style={{ display: 'flex' }}>
                        <Body3Regular style={{ color: colors.LAVEL_4 }}>휴대폰 번호</Body3Regular>
                        <Body3Regular style={{ color: colors.ERROR }}>*</Body3Regular>
                    </label>
                    <Row>
                        <input
                            id="phoneNumber"
                            name="phoneNumber"
                            type="phoneNumber"
                            autoComplete="phoneNumber"
                            value={phoneNumber}
                            required
                            onChange={(e) => {
                                const regex = /^[0-9\b -]{0,13}$/;
                                if (regex.test(e.target.value)) {
                                    setPhoneNumber(e.target.value);
                                }
                            }}
                            style={{
                                width: 340,
                                height: 48,
                                borderBottom: '1px solid #A6BFEF',
                                paddingLeft: 16,
                                paddingRight: 16,
                                fontSize: 18,
                                fontWeight: 400,
                            }}
                        />
                        <WhiteRoundButton
                            text="인증"
                            callBack={handleSmsCodeRequest}
                            size="middle"
                            isVisibleLeftImg={false}
                            isVisibleRightImg={false}
                            customStyle={{ width: 76, height: 48, marginLeft: 16 }}
                            isDisabled={phoneNumber.length === 0}
                        />
                    </Row>

                    <CaptionLight style={{ marginLeft: 16, marginTop: 11, color: colors.LAVEL_1 }}>
                        본인 인증에 필요한 절차입니다!
                    </CaptionLight>
                </Col>

                <Col style={{ marginLeft: 67, marginTop: 59 }}>
                    <label htmlFor="name" style={{ display: 'flex' }}>
                        <Body3Regular style={{ color: colors.LAVEL_4 }}>인증 번호</Body3Regular>
                        <Body3Regular style={{ color: colors.ERROR }}>*</Body3Regular>

                        {isRunning && (
                            <Body3Regular style={{ color: colors.ERROR, marginLeft: 212 }}>
                                {formattedTime}
                            </Body3Regular>
                        )}
                    </label>

                    <Row>
                        <input
                            id="authNumber"
                            name="text"
                            type="authNumber"
                            autoComplete="authNumber"
                            maxLength={6}
                            value={authNumber}
                            onChange={(e) => {
                                setAuthNumber(e.target.value);
                            }}
                            style={{
                                width: 340,
                                height: 48,
                                borderBottom: '1px solid #A6BFEF',
                                paddingLeft: 16,
                                paddingRight: 16,
                                fontSize: 18,
                                fontWeight: 400,
                            }}
                        />

                        <WhiteRoundButton
                            text="확인"
                            callBack={handleAuthSmsCode}
                            size="middle"
                            isVisibleLeftImg={false}
                            isVisibleRightImg={false}
                            customStyle={{ width: 76, height: 48, marginLeft: 16 }}
                            isDisabled={isCheckAuthNumber || authNumber.length === 0}
                        />
                    </Row>

                    <CaptionLight style={{ marginLeft: 10, marginTop: 11, color: colors.LAVEL_1 }}>
                        휴대폰 문자로 온 인증 번호를 입력해주세요!
                    </CaptionLight>
                </Col>

                <Row style={{ marginLeft: 700, marginTop: 27 }}>
                    <ColorSquareButton
                        text="변경"
                        size="middle"
                        isVisibleLeftImg={false}
                        isVisibleRightImg={false}
                        callBack={onClickSave}
                        isDisabled={!isCheckAuthNumber}
                    />
                </Row>
            </ModalInner>
        </ModalOverLay>
    );
};

export default ModalPhoneNumberChange;
