import React from 'react';
import { Col, truncateString } from '../../../lib/utils';
import { Body3Regular } from '../../../lib/font';
import { colors } from '../../../lib/colors';

const FeedTextContent = ({ textObject }: any) => {
    return (
        <Col style={{ width: 714, alignSelf: 'center', marginTop: 16, flexWrap: 'wrap' }}>
            <Body3Regular style={{ color: colors.LAVEL_3 }}>{truncateString(textObject[0]?.content, 140)}</Body3Regular>
        </Col>
    );
};

export default FeedTextContent;
