// apiService.js

import { getAuthentificationPhoneNumber, getOneMember } from '../api/member';

export const fetchOneMemberProfile = (
    params: {
        centerId: string;
        memberId: string;
    },
    authorization: string
) => ({
    queryKey: ['getCenterMemberList', params.centerId, params.memberId],
    queryFn: () => getOneMember(params.centerId, params.memberId, authorization),
    onSuccess: (data: any) => {
        // 데이터 정제 로직
        return data?.data?.member ?? [];
    },
});

export const fetchAuthentificationPhoneNumber = (
    params: { centerId: string; phoneNumber: string },
    authorization: string
) => ({
    queryKey: ['getAuthentificationPhoneNumber', params.phoneNumber],
    queryFn: () =>
        getAuthentificationPhoneNumber({
            centerId: params.centerId,
            phoneNumber: params.phoneNumber,
            accessToken: authorization,
        }),
    onSuccess: (data: any) => {
        const result: FetchAuthentificationPhoneNumberResponse = data?.data?.result ?? {};
        // 데이터 정제 로직
        return result;
    },
});

export type FetchAuthentificationPhoneNumberResponse = {
    coachName: string;
    centerName: string;
    weight: WeightRecord;
    height: number;
    birthday: string;
    gender: 'male' | 'female';
};

type WeightRecord = {
    value: number;
    recordDate: string; // ISO 날짜 문자열로 정의
};

export type MemberProfile = {
    inviteCode: string;
    isInfo: boolean;
    isRequest: boolean;
    isDeleted: boolean;
    isCanceled: boolean; // 추가된 필드
    memberType: 'temp' | 'regular' | 'premium'; // 추가된 필드, 예시로 Enum 형태로 정의 가능
    name: string;
    phoneNumber: string;
    gender: 'male' | 'female';
    height: number;
    weight: WeightRecord[];
    address: string; // 추가된 필드
    memo: string;
    centerId: string; // 추가된 필드
    created: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
    birthday: string;
    exerciseVolume: string;
    profileImageUrl: string;
    profileColorType: string;
    profileName: string;
    coachProfileImageUrl: string;
    coachProfileColorType: string;
    coachProfileName: string;
    coachId: string;
    coachName: string;
    id: string;
};
