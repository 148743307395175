import React, { CSSProperties, useEffect, useState } from "react";
import styled from "styled-components";
import {
  Body1Regular,
  Body2Regular,
  Body3Regular,
  CaptionRegular,
} from "../../lib/font";
import { Row } from "../../lib/utils";
import {
  I_CLICKED_SOLID_PLUS,
  I_DEFAULT_SOLID_PLUS,
  I_DISABLED_SOLID_PLUS,
  I_HOVER_SOLID_PLUS,
} from "../../types/buttonImage";

const ButtonWrap = styled.button<{ mr?: number }>`
  margin-right: ${(props) => (props.mr ? props.mr : 0)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fdfeff;
  border: ${(props) =>
    props.disabled ? "1px solid #A5ABB5" : "1px solid #3d73dd"};
  color: ${(props) => (props.disabled ? "#A5ABB5" : "#3D73DD")};

  &:not([disabled]):hover {
    background-color: #f5f9ff;
    border: 1px solid #3869c9;
    color: #3869c9;
  }
  &:not([disabled]):active {
    background-color: #ecf1fc;
    border: 1px solid #2b529d;
    color: #2b529d;
  }
`;

type Props = {
  text: string;
  isVisibleLeftImg?: boolean;
  isVisibleRightImg?: boolean;
  isDisabled?: boolean;
  size: "big" | "middle" | "small" | "tiny";
  callBack?: () => void;
  customStyle?: CSSProperties;
  mr?: number;
};

const WhiteSquareButton = ({
  text,
  isVisibleLeftImg = false,
  isVisibleRightImg = false,
  isDisabled = false,
  size,
  callBack,
  customStyle,
  mr,
}: Props) => {
  const [imageSrc, setImageSrc] = useState<string>(`${I_DEFAULT_SOLID_PLUS}`);
  const handleMouseEnter = () => {
    if (!isDisabled) {
      setImageSrc(`${I_HOVER_SOLID_PLUS}`);
    }
  };

  const handleMouseLeave = () => {
    if (!isDisabled) {
      setImageSrc(`${I_DEFAULT_SOLID_PLUS}`);
    }
  };

  const handleClick = () => {
    if (!isDisabled) {
      setImageSrc(`${I_CLICKED_SOLID_PLUS}`);
      setTimeout(() => {
        setImageSrc(`${I_DEFAULT_SOLID_PLUS}`);
      }, 100);
      callBack && callBack();
    }
  };

  useEffect(() => {
    if (isDisabled) {
      setImageSrc(`${I_DISABLED_SOLID_PLUS}`);
    }
  }, [isDisabled]);

  let BigStyle: CSSProperties = {
    width: 217,
    height: 64,
  };

  let MiddleStyle: CSSProperties = {
    width: 148,
    height: 48,
  };

  let SmallStyle: CSSProperties = {
    width: 113,
    height: 32,
  };
  let TinyStyle: CSSProperties = {
    width: 81,
    height: 24,
  };

  return (
    <ButtonWrap
      mr={mr}
      style={
        customStyle
          ? customStyle
          : size === "big"
          ? BigStyle
          : size === "middle"
          ? MiddleStyle
          : size === "small"
          ? SmallStyle
          : TinyStyle
      }
      disabled={isDisabled}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      {(size === "big" || size === "middle") && isVisibleLeftImg && (
        <Row
          style={{
            width: 24,
            height: 24,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={imageSrc} style={{ width: 18, height: 18 }} />
        </Row>
      )}

      {size === "big" && (
        <Body1Regular style={{ marginLeft: 8, marginRight: 8 }}>
          {text}
        </Body1Regular>
      )}
      {size === "middle" && (
        <Body2Regular style={{ marginLeft: 8, marginRight: 8 }}>
          {text}
        </Body2Regular>
      )}
      {size === "small" && <Body3Regular>{text}</Body3Regular>}
      {size === "tiny" && <CaptionRegular>{text}</CaptionRegular>}

      {(size === "big" || size === "middle") && isVisibleRightImg && (
        <Row
          style={{
            width: 24,
            height: 24,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={imageSrc} style={{ width: 18, height: 18 }} />
        </Row>
      )}
    </ButtonWrap>
  );
};

export default WhiteSquareButton;
