import React from 'react';
import styled from 'styled-components';
import { Row } from '../../../lib/utils';
import { I_WHITE_CHECK } from '../../../types/images';
interface CircleProps {
    background: string;
}

const Circle = styled.div<CircleProps>`
    width: 24px;
    height: 24px;
    border-radius: 12px;
    box-shadow: 2px 2px 4px 0px #2525253d;
    border: 1px solid #ffffff;
    background: ${(props) => props.background};
    transition: transform 0.3s ease;
    cursor: pointer;
    &:hover {
        transform: scale(1.1);
    }
`;

const BgColorSelect = ({ bgColor, setBgColor }: any) => {
    return (
        <Row
            style={{
                width: 264,
                height: 24,
                alignItems: 'center',
                justifyContent: 'space-between',
                marginLeft: 87,
                marginTop: 24,
            }}
        >
            <Circle
                onClick={() => setBgColor(['#E62423', '#EAC84D'])}
                background="linear-gradient(135deg, #E62423 0%, #EAC84D 100%)"
            >
                {bgColor && bgColor[0] === '#E62423' && <img src={I_WHITE_CHECK} style={{ width: 24, height: 24 }} />}
            </Circle>
            <Circle
                onClick={() => setBgColor(['#F19642', '#0C8F58'])}
                background="linear-gradient(135deg, #F19642 0%, #0C8F58 100%)"
            >
                {bgColor && bgColor[0] === '#F19642' && <img src={I_WHITE_CHECK} style={{ width: 24, height: 24 }} />}
            </Circle>
            <Circle
                onClick={() => setBgColor(['#40CE96', '#1B5AD4'])}
                background="linear-gradient(135deg, #40CE96 0%, #1B5AD4 100%)"
            >
                {bgColor && bgColor[0] === '#40CE96' && <img src={I_WHITE_CHECK} style={{ width: 24, height: 24 }} />}
            </Circle>
            <Circle
                onClick={() => setBgColor(['#216BDB', '#953DDB'])}
                background="linear-gradient(135deg, #216BDB 0%, #953DDB 100%)"
            >
                {bgColor && bgColor[0] === '#216BDB' && <img src={I_WHITE_CHECK} style={{ width: 24, height: 24 }} />}
            </Circle>
            <Circle
                onClick={() => setBgColor(['#A74AF0', '#DB4863'])}
                background="linear-gradient(135deg, #A74AF0 0%, #DB4863 100%)"
            >
                {bgColor && bgColor[0] === '#A74AF0' && <img src={I_WHITE_CHECK} style={{ width: 24, height: 24 }} />}
            </Circle>
            <Circle
                onClick={() => setBgColor(['#CD4844', '#3C9BB9'])}
                background="linear-gradient(135deg, #CD4844 0%, #3C9BB9 100%)"
            >
                {bgColor && bgColor[0] === '#CD4844' && <img src={I_WHITE_CHECK} style={{ width: 24, height: 24 }} />}
            </Circle>
            <Circle
                onClick={() => setBgColor(['#2FA0DF', '#EE78C6'])}
                background="linear-gradient(135deg, #2FA0DF 0%, #EE78C6 100%)"
            >
                {bgColor && bgColor[0] === '#2FA0DF' && <img src={I_WHITE_CHECK} style={{ width: 24, height: 24 }} />}
            </Circle>
        </Row>
    );
};

export default BgColorSelect;
