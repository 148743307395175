import React from 'react';
import { Col, Row } from '../../lib/utils';
import { colors } from '../../lib/colors';
import { Body3Light, Body3Regular } from '../../lib/font';
import ProfileImage from '../ProfileImage';
import { I_CIRCLE_EXIT, I_EMPTY_V2, I_GREY_CIRCLE, I_WHITE_CHECK } from '../../types/images';
import styled from 'styled-components';

const StyledRowContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 1192px;
    height: 56px;
    align-items: center;
    padding-left: 24px;
    cursor: pointer;
    background-color: #fdfeff;
    &:hover {
        background-color: #ecf1fc;
    }
    &:active {
        background-color: #f0f6ff;
    }
`;

const TrainerList = ({ filterCoachList, onClickDetailPage }: any) => {
    return (
        <Col
            style={{
                width: 1192,
                minHeight: 240,
                borderRadius: 16,
                border: '1px solid #c3d4f4',
                marginTop: 24,
                backgroundColor: colors.WHITE_50,
                overflow: 'hidden',
            }}
        >
            <Row
                style={{
                    width: 1192,
                    height: 56,
                    borderBottom: '1px solid #c3d4f4',
                    alignItems: 'center',
                    paddingLeft: 24,
                }}
            >
                <Body3Regular style={{ width: 108, textAlign: 'center' }}>{'강사 이름'}</Body3Regular>
                <div
                    style={{
                        width: 1,
                        height: 24,
                        backgroundColor: colors.BLUE_100,
                        marginLeft: 24,
                        marginRight: 24,
                    }}
                />

                <Body3Regular style={{ width: 80, textAlign: 'center' }}>{'강사 코드'}</Body3Regular>
                <div
                    style={{
                        width: 1,
                        height: 24,
                        backgroundColor: colors.BLUE_100,
                        marginLeft: 24,
                        marginRight: 24,
                    }}
                />

                <Body3Regular style={{ width: 88, textAlign: 'center' }}>{'총 회원 수'}</Body3Regular>
                <div
                    style={{
                        width: 1,
                        height: 24,
                        backgroundColor: colors.BLUE_100,
                        marginLeft: 24,
                        marginRight: 24,
                    }}
                />

                <Body3Regular style={{ width: 88, textAlign: 'center' }}>{'활성 회원 수'}</Body3Regular>
                <div
                    style={{
                        width: 1,
                        height: 24,
                        backgroundColor: colors.BLUE_100,
                        marginLeft: 24,
                        marginRight: 24,
                    }}
                />

                <Body3Regular style={{ width: 162, textAlign: 'center' }}>{'연락처'}</Body3Regular>
                <div
                    style={{
                        width: 1,
                        height: 24,
                        backgroundColor: colors.BLUE_100,
                        marginLeft: 24,
                        marginRight: 24,
                    }}
                />

                <Body3Regular style={{ width: 260, textAlign: 'center' }}>{'이메일'}</Body3Regular>
                <div
                    style={{
                        width: 1,
                        height: 24,
                        backgroundColor: colors.BLUE_100,
                        marginLeft: 24,
                        marginRight: 24,
                    }}
                />
                <Body3Regular style={{ width: 72, textAlign: 'center' }}>{'센터 연결'}</Body3Regular>
            </Row>

            <Col>
                {/*
                 * isConnected : false , isDeleted :false -> 수락 대기중 상태
                 * isConnected : true , isDeleted :false -> 정상 활성화 상태
                 * isConnected : true , isDeleted :true -> 비활성화 상태 (센터에서 삭제된 트레이너)
                 * isConnected : false , isDeleted :true -> 강사가 탈퇴한 상태 (센터에서 삭제 + 트레이너라포 탈퇴)
                 **/}
                {filterCoachList.length > 0 ? (
                    filterCoachList.map((item: any, index: number) => {
                        return item?.isDeleted ? (
                            <Row
                                style={{
                                    width: 1192,
                                    height: 56,
                                    alignItems: 'center',
                                    paddingLeft: 24,
                                    backgroundColor: colors.GREY_50,
                                }}
                                key={'coachList' + index}
                            >
                                <Row style={{ width: 108, justifyContent: 'center' }}>
                                    <ProfileImage
                                        imageUrl={item?.profileImageUrl}
                                        type="VerySmall"
                                        profileName={item?.profileName}
                                        profileColorType={item?.profileColorType}
                                    />
                                    <Body3Regular style={{ marginLeft: 12, color: colors.LAVEL_1 }}>
                                        {`${item?.coachName}`}
                                    </Body3Regular>
                                </Row>
                                <div
                                    style={{
                                        width: 1,
                                        height: 24,
                                        backgroundColor: colors.GREY_50,
                                        marginLeft: 24,
                                        marginRight: 24,
                                    }}
                                />

                                <Body3Regular
                                    style={{
                                        width: 80,
                                        textAlign: 'center',
                                        color: colors.ERROR_200,
                                    }}
                                >{`삭제`}</Body3Regular>
                                <div
                                    style={{
                                        width: 1,
                                        height: 24,
                                        backgroundColor: colors.GREY_50,
                                        marginLeft: 24,
                                        marginRight: 24,
                                    }}
                                />

                                <Body3Regular
                                    style={{
                                        width: 88,
                                        textAlign: 'center',
                                        color: colors.LAVEL_1,
                                    }}
                                >{`${item?.coachTotalMemberCount}명`}</Body3Regular>
                                <div
                                    style={{
                                        width: 1,
                                        height: 24,
                                        backgroundColor: colors.GREY_50,
                                        marginLeft: 24,
                                        marginRight: 24,
                                    }}
                                />

                                <Body3Regular
                                    style={{
                                        width: 88,
                                        textAlign: 'center',
                                        color: colors.LAVEL_1,
                                    }}
                                >{`${item?.coachActiveMemberCount}명`}</Body3Regular>
                                <div
                                    style={{
                                        width: 1,
                                        height: 24,
                                        backgroundColor: colors.GREY_50,
                                        marginLeft: 24,
                                        marginRight: 24,
                                    }}
                                />

                                <Body3Regular
                                    style={{
                                        width: 160,
                                        textAlign: 'center',
                                        color: colors.LAVEL_1,
                                    }}
                                >
                                    {item?.coachPhoneNumber ? item?.coachPhoneNumber : '-'}
                                </Body3Regular>
                                <div
                                    style={{
                                        width: 1,
                                        height: 24,
                                        backgroundColor: colors.GREY_50,
                                        marginLeft: 24,
                                        marginRight: 24,
                                    }}
                                />

                                <Body3Regular
                                    style={{
                                        width: 260,
                                        textAlign: 'center',
                                        color: colors.LAVEL_1,
                                    }}
                                >
                                    {item?.coachEmail ? item?.coachEmail : '-'}
                                </Body3Regular>
                                <div
                                    style={{
                                        width: 1,
                                        height: 24,
                                        backgroundColor: colors.GREY_50,
                                        marginLeft: 24,
                                        marginRight: 24,
                                    }}
                                />
                                <Row style={{ width: 72, justifyContent: 'center' }}>
                                    <img src={I_CIRCLE_EXIT} style={{ width: 24, height: 24 }} />
                                </Row>
                            </Row>
                        ) : (
                            <StyledRowContainer onClick={() => onClickDetailPage(item)} key={'coachList' + index}>
                                <Row style={{ width: 108, justifyContent: 'center' }}>
                                    <ProfileImage
                                        imageUrl={item?.profileImageUrl}
                                        type="VerySmall"
                                        profileName={item?.profileName}
                                        profileColorType={item?.profileColorType}
                                    />
                                    <Body3Regular style={{ marginLeft: 12, color: colors.LAVEL_3 }}>
                                        {`${item?.coachName}`}
                                    </Body3Regular>
                                </Row>
                                <div
                                    style={{
                                        width: 1,
                                        height: 24,
                                        backgroundColor: colors.WHITE_50,
                                        marginLeft: 24,
                                        marginRight: 24,
                                    }}
                                />

                                <Body3Regular
                                    style={{ width: 80, textAlign: 'center' }}
                                >{`${item?.coachInviteCode}`}</Body3Regular>
                                <div
                                    style={{
                                        width: 1,
                                        height: 24,
                                        backgroundColor: colors.WHITE_50,
                                        marginLeft: 24,
                                        marginRight: 24,
                                    }}
                                />

                                <Body3Regular
                                    style={{ width: 88, textAlign: 'center' }}
                                >{`${item?.coachTotalMemberCount}명`}</Body3Regular>
                                <div
                                    style={{
                                        width: 1,
                                        height: 24,
                                        backgroundColor: colors.WHITE_50,
                                        marginLeft: 24,
                                        marginRight: 24,
                                    }}
                                />

                                <Body3Regular
                                    style={{ width: 88, textAlign: 'center' }}
                                >{`${item?.coachActiveMemberCount}명`}</Body3Regular>
                                <div
                                    style={{
                                        width: 1,
                                        height: 24,
                                        backgroundColor: colors.WHITE_50,
                                        marginLeft: 24,
                                        marginRight: 24,
                                    }}
                                />

                                <Body3Regular style={{ width: 160, textAlign: 'center' }}>
                                    {item?.coachPhoneNumber ? item?.coachPhoneNumber : '-'}
                                </Body3Regular>
                                <div
                                    style={{
                                        width: 1,
                                        height: 24,
                                        backgroundColor: colors.WHITE_50,
                                        marginLeft: 24,
                                        marginRight: 24,
                                    }}
                                />

                                <Body3Regular style={{ width: 260, textAlign: 'center' }}>
                                    {item?.coachEmail ? item?.coachEmail : '-'}
                                </Body3Regular>
                                <div
                                    style={{
                                        width: 1,
                                        height: 24,
                                        backgroundColor: colors.WHITE_50,
                                        marginLeft: 24,
                                        marginRight: 24,
                                    }}
                                />

                                <Row style={{ width: 72, justifyContent: 'center' }}>
                                    {item?.isConnected === true && item?.isDeleted === false ? (
                                        <Row
                                            style={{
                                                width: 18,
                                                height: 18,
                                                borderRadius: 9,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: colors.BLUE_500,
                                            }}
                                        >
                                            <img src={I_WHITE_CHECK} style={{ width: 16, height: 16 }} />
                                        </Row>
                                    ) : (
                                        <img src={I_CIRCLE_EXIT} style={{ width: 24, height: 24 }} />
                                    )}
                                </Row>
                            </StyledRowContainer>
                        );
                    })
                ) : (
                    <Col
                        style={{
                            width: 192,
                            height: 56,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            alignSelf: 'center',
                            marginTop: 56,
                        }}
                    >
                        <img style={{ width: 24, height: 24 }} src={I_EMPTY_V2} />
                        <Body3Light style={{ color: colors.LAVEL_2 }}>{'아직 등록된 강사님이 없어요!'}</Body3Light>
                    </Col>
                )}
            </Col>
        </Col>
    );
};

export default TrainerList;
