import React from 'react';
import { Col, Row, splitAndTranslate } from '../../../lib/utils';
import { Body1Bold, Body2Bold, Body2Regular, Body3Bold, Body3Regular } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import { PAINPERIOD, PAINSTEP, PAINTYPE } from '../../../lib/varialbe';

const BodyPainList = ({ scheduleReport }: any) => {
    const condition = scheduleReport?.condition;
    const painReports = condition?.painReports;
    return (
        <>
            <Row style={{ alignItems: 'center', marginTop: 32 }}>
                <Body2Bold style={{ color: colors.LAVEL_3 }}>{'신체 통증 :'}</Body2Bold>
                <Body2Regular style={{ color: colors.LAVEL_2, marginLeft: 8, marginRight: 36 }}>{`${
                    condition ? (condition?.painReports?.length === 0 ? '없음' : '있음') : '없음'
                }`}</Body2Regular>
            </Row>

            <Col style={{ width: 830, marginTop: 16, justifyContent: 'space-between' }}>
                {painReports &&
                    painReports.map((item: any, index: number) => {
                        const rainbox = ['#3D74DA', '#1F8A45', '#FFE500', '#FF8C01', '#FF0000'];
                        const painLevelColor = rainbox[item?.painLevel - 1];
                        const painPart = splitAndTranslate(item.painPart).join(' - ');
                        const painLevelToString = PAINSTEP[item?.painLevel - 1];
                        const painType = PAINTYPE.find((pain) => pain.code === item.painType);
                        const painPeriod = PAINPERIOD.find((pain) => pain.code === item.painPeriod);

                        return (
                            <Row key={index + 'painReportsList'} style={{ marginTop: 32 }}>
                                <Row
                                    style={{
                                        width: 24,
                                        height: 24,
                                        borderRadius: 12,
                                        backgroundColor: painLevelColor,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Body3Bold style={{ color: colors.WHITE_50 }}>{index + 1}</Body3Bold>
                                </Row>

                                <Col
                                    style={{
                                        height: 114,
                                        justifyContent: 'space-between',
                                        marginLeft: 8,
                                    }}
                                >
                                    <Body3Bold style={{ color: colors.LAVEL_3 }}>{painPart}</Body3Bold>

                                    <Row style={{ alignItems: 'center', marginTop: 16 }}>
                                        <div
                                            style={{
                                                width: 4,
                                                height: 4,
                                                borderRadius: 2,
                                                backgroundColor: colors.WHITE_900,
                                                marginRight: 4,
                                            }}
                                        />
                                        <Body3Regular style={{ color: colors.LAVEL_2 }}>
                                            통증 정도 : {item?.painLevel}단계({painLevelToString})
                                        </Body3Regular>
                                    </Row>
                                    <Row style={{ alignItems: 'center' }}>
                                        <div
                                            style={{
                                                width: 4,
                                                height: 4,
                                                borderRadius: 2,
                                                backgroundColor: colors.WHITE_900,
                                                marginRight: 4,
                                            }}
                                        />
                                        <Body3Regular style={{ color: colors.LAVEL_2 }}>
                                            통증 유형 : {painType?.value}
                                        </Body3Regular>
                                    </Row>
                                    <Row style={{ alignItems: 'center' }}>
                                        <div
                                            style={{
                                                width: 4,
                                                height: 4,
                                                borderRadius: 2,
                                                backgroundColor: colors.WHITE_900,
                                                marginRight: 4,
                                            }}
                                        />
                                        <Body3Regular style={{ color: colors.LAVEL_2 }}>
                                            통증 기간 : {painPeriod?.value}
                                        </Body3Regular>
                                    </Row>
                                </Col>
                            </Row>
                        );
                    })}
            </Col>
        </>
    );
};

export default BodyPainList;
