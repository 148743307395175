import React from 'react';
import { Col, Row } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import { Body2Regular, Body3Regular, CaptionRegular } from '../../../lib/font';

import EmptyClass from './EmptyClass';
import dayjs from 'dayjs';
import ProfileImage from '../../ProfileImage';

const ClassHistoryThisMonth = ({ thisMonthList, searchText, thisMonthFilterList }: any) => {
    return (
        <Col style={{ width: 1192 }}>
            {thisMonthFilterList && thisMonthFilterList.length === 0 ? (
                <EmptyClass />
            ) : (
                thisMonthList &&
                thisMonthList.map((item: any, index: number) => {
                    let keys = Object.keys(item);

                    let dayObject = item[keys[0]];
                    let dayTime = dayObject[0].startTime;

                    let findFilterList: any = [];

                    if (searchText === '') {
                        findFilterList = dayObject;
                    } else {
                        findFilterList = dayObject.filter((e: any) => e.alias.includes(searchText));
                    }

                    return findFilterList && findFilterList.length === 0 ? (
                        <div key={index}></div>
                    ) : (
                        <Col style={{ marginTop: 16, marginBottom: 16 }} key={index + 'dayList'}>
                            <Row style={{ alignItems: 'center', marginLeft: 8, marginBottom: 16 }}>
                                <Body2Regular style={{ color: colors.LAVEL_4 }}>{`${dayjs(dayTime).format(
                                    'MM월 DD일 (ddd)'
                                )}`}</Body2Regular>
                                <Body2Regular
                                    style={{ marginLeft: 32, color: colors.LAVEL_4 }}
                                >{`총 ${findFilterList.length}회`}</Body2Regular>
                            </Row>
                            <Col
                                style={{
                                    width: 1192,
                                    minHeight: 118,
                                    borderRadius: 16,
                                    backgroundColor: colors.WHITE_50,
                                    border: '1px solid #C3D4F4',
                                    overflow: 'hidden',
                                }}
                            >
                                <Row
                                    style={{
                                        width: 1192,
                                        height: 56,
                                        alignItems: 'center',
                                        borderBottom: '1px solid #c3d4f4',
                                        paddingLeft: 46,
                                    }}
                                >
                                    <Row
                                        style={{
                                            width: 108,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Body3Regular style={{ color: colors.LAVEL_3 }}>{`회원 이름`}</Body3Regular>
                                    </Row>
                                    <div
                                        style={{
                                            width: 1,
                                            height: 24,
                                            backgroundColor: colors.BLUE_100,
                                            marginLeft: 24,
                                            marginRight: 24,
                                        }}
                                    />

                                    <Row
                                        style={{
                                            width: 320,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Body3Regular style={{ color: colors.LAVEL_3 }}>{`수업시간`}</Body3Regular>
                                    </Row>
                                    <div
                                        style={{
                                            width: 1,
                                            height: 24,
                                            backgroundColor: colors.BLUE_100,
                                            marginLeft: 24,
                                            marginRight: 24,
                                        }}
                                    />

                                    <Row
                                        style={{
                                            width: 320,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Body3Regular style={{ color: colors.LAVEL_3 }}>{`수강권`}</Body3Regular>
                                    </Row>
                                    <div
                                        style={{
                                            width: 1,
                                            height: 24,
                                            backgroundColor: colors.BLUE_100,
                                            marginLeft: 24,
                                            marginRight: 24,
                                        }}
                                    />

                                    <Row
                                        style={{
                                            width: 80,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Body3Regular style={{ color: colors.LAVEL_3 }}>{`회차`}</Body3Regular>
                                    </Row>
                                    <div
                                        style={{
                                            width: 1,
                                            height: 24,
                                            backgroundColor: colors.BLUE_100,
                                            marginLeft: 24,
                                            marginRight: 24,
                                        }}
                                    />

                                    <Row
                                        style={{
                                            width: 80,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Body3Regular style={{ color: colors.LAVEL_3 }}>{`상태`}</Body3Regular>
                                    </Row>
                                </Row>

                                {findFilterList.map((childItem: any, childIndex: number) => {
                                    console.log(childItem);
                                    return (
                                        <Row
                                            key={childIndex + 'dayDetailList'}
                                            style={{
                                                width: 1192,
                                                height: 62,
                                                alignItems: 'center',
                                                paddingLeft: 46,
                                            }}
                                        >
                                            <Row
                                                style={{
                                                    width: 108,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <ProfileImage
                                                    imageUrl={childItem?.profileImageUrl}
                                                    type="VerySmall"
                                                    profileName={childItem?.profileName}
                                                    profileColorType={childItem?.profileColorType}
                                                    right={8}
                                                />
                                                <Body3Regular
                                                    style={{ color: colors.LAVEL_3 }}
                                                >{`${childItem.alias}`}</Body3Regular>
                                            </Row>
                                            <div
                                                style={{
                                                    width: 1,
                                                    height: 24,
                                                    backgroundColor: colors.WHITE_50,
                                                    marginLeft: 24,
                                                    marginRight: 24,
                                                }}
                                            />

                                            <Row
                                                style={{
                                                    width: 320,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Body3Regular style={{ color: colors.LAVEL_3 }}>
                                                    {dayjs(childItem.startTime).format('A HH:mm ~ ')}
                                                    {dayjs(childItem.endTime).format('A HH:mm')}
                                                </Body3Regular>
                                            </Row>
                                            <div
                                                style={{
                                                    width: 1,
                                                    height: 24,
                                                    backgroundColor: colors.WHITE_50,
                                                    marginLeft: 24,
                                                    marginRight: 24,
                                                }}
                                            />

                                            <Row
                                                style={{
                                                    width: 320,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Body3Regular style={{ color: colors.LAVEL_3 }}>{`${
                                                    childItem?.membershipName ? childItem?.membershipName : '-'
                                                }`}</Body3Regular>
                                            </Row>
                                            <div
                                                style={{
                                                    width: 1,
                                                    height: 24,
                                                    backgroundColor: colors.WHITE_50,
                                                    marginLeft: 24,
                                                    marginRight: 24,
                                                }}
                                            />

                                            <Row
                                                style={{
                                                    width: 80,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Body3Regular style={{ color: colors.LAVEL_3 }}>
                                                    {childItem.currentSession ? childItem.currentSession : '-'}/
                                                    {childItem.totalSession ? childItem.totalSession : '-'}회
                                                </Body3Regular>
                                            </Row>
                                            <div
                                                style={{
                                                    width: 1,
                                                    height: 24,
                                                    backgroundColor: colors.WHITE_50,
                                                    marginLeft: 24,
                                                    marginRight: 24,
                                                }}
                                            />

                                            <Row
                                                style={{
                                                    width: 80,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Row
                                                    style={{
                                                        width: 46,
                                                        height: 30,
                                                        borderRadius: 4,
                                                        backgroundColor: !!childItem?.attendanceStatus // attendanceStatus 없을 때는 모두 ATTENDANCE 취급
                                                            ? childItem?.attendanceStatus === 'ATTENDANCE'
                                                                ? colors.SUCCESS_50
                                                                : colors.ERROR_50
                                                            : colors.SUCCESS_50,
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <CaptionRegular
                                                        style={{
                                                            color: !!childItem?.attendanceStatus // attendanceStatus 없을 때는 모두 ATTENDANCE 취급
                                                                ? childItem?.attendanceStatus === 'ATTENDANCE'
                                                                    ? colors.SUCCESS
                                                                    : colors.ERROR
                                                                : colors.SUCCESS,
                                                        }}
                                                    >{`${
                                                        !!childItem?.attendanceStatus // attendanceStatus 없을 때는 모두 ATTENDANCE 취급
                                                            ? childItem?.attendanceStatus === 'ATTENDANCE'
                                                                ? '출석'
                                                                : '노쇼'
                                                            : '출석'
                                                    }`}</CaptionRegular>
                                                </Row>
                                            </Row>
                                        </Row>
                                    );
                                })}
                            </Col>
                        </Col>
                    );
                })
            )}
        </Col>
    );
};

export default ClassHistoryThisMonth;
