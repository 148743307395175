import React, { CSSProperties, useEffect, useState } from 'react';
import { Col, Row } from '../../../../lib/utils';
import { Body3Bold, Body3Light, CaptionBold, CaptionLight, CaptionRegular } from '../../../../lib/font';
import dayjs from 'dayjs';
import { colors } from '../../../../lib/colors';
import { I_DOWN_TRIANGLE, I_TODAY_TRIANGLE } from '../../../../types/images';
import styled, { css, keyframes } from 'styled-components';
import WhiteRoundButton from '../../../Button/WhiteRoundButton';
import ColorRoundButton from '../../../Button/ColorRoundButton';

// 애니메이션 정의
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

interface StyledColProps {
    backgroundColor: string;
    isVisible: boolean;
    requestType: string;
    childIndex: number;
}

// 스타일드 컴포넌트 정의
const HoverItem = styled.div<StyledColProps>`
    display: flex;
    flex-direction: column;
    width: 300px;
    height: ${(props) => (props.requestType === 'change' ? '384px' : '224px')};
    background-color: ${(props) => props.backgroundColor};
    position: absolute;
    border-radius: 16px;
    box-shadow: 4px 4px 8px 0px #b24b3d52;
    padding-left: 16px;
    padding-right: 24px;
    padding-top: 14px;
    z-index: 3;
    transition: all 1s ease;
    animation: ${(props) => (props.isVisible ? fadeIn : 'none')} 1s ease forwards;

    /* parentIndex에 따라 다른 방향으로 투명한 영역 추가 */
    &::after {
        content: '';
        width: 50px; /* 투명한 영역 */
        height: 100%;
        position: absolute;
        top: 0;
        ${(props) =>
            props.childIndex > 5
                ? css`
                      right: -50px;
                  `
                : css`
                      left: -50px;
                  `}
    }
`;

const ScheduleBlock = ({
    childItem,
    cellIndex,
    onClickSchedule,
    onClickRequest,
    onClickApproveScheduleRequest,
    onClickRejectScheduleRequest,
}: any) => {
    const [showModal, setShowModal] = useState(false);

    const hoverStyle: CSSProperties = {
        marginTop: childItem.requestType === 'register' ? '-4%' : '-8%',
        marginRight: cellIndex > 5 ? '13%' : '-19%',
    };

    const handleMouseEnter = () => {
        setShowModal(true);
    };

    const handleMouseLeave = () => {
        // 마우스가 빈 공간에 있을 때 onMouseLeave 호출 방지
        setShowModal(false);
    };

    const handleRejectButtonClick = (event: React.MouseEvent) => {
        event.stopPropagation(); // 이벤트 버블링 방지
        onClickRejectScheduleRequest(childItem);
    };

    const handleApproveButtonClick = (event: React.MouseEvent) => {
        event.stopPropagation(); // 이벤트 버블링 방지
        onClickApproveScheduleRequest(childItem);
    };

    return (
        // TODO : 신청의 경우 추후에 position relative 적용 및 호버 위치 재조정
        <Col
            onClick={() => {
                onClickRequest(childItem);
            }}
            style={{
                width: '91.5%',
                height: 92.5,
                borderRadius: 4,
                border: '2px solid #FF8777',
                alignItems: 'flex-end',
                marginBottom: 4,
                backgroundColor: '#FFFCFB',
                cursor: 'pointer',
                boxShadow: showModal ? '0px 0px 12px 0px #FF877782' : '',
            }}
            onMouseEnter={() => setShowModal(true)}
            onMouseLeave={() => setShowModal(false)}
        >
            <Col style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <Col style={{ width: '86.2%', height: 74, justifyContent: 'space-between' }}>
                    <Body3Bold style={{ color: colors.LAVEL_3 }}>{childItem?.coachName} 강사</Body3Bold>
                    <Row style={{ alignItems: 'center' }}>
                        <Body3Bold style={{ color: colors.LAVEL_3 }}>{childItem?.alias}</Body3Bold>
                        {/* <CaptionLight style={{ marginLeft: 12, color: colors.LAVEL_2 }}>
                            {childItem?.currentSession ? childItem?.currentSession : '?'}/
                            {childItem?.totalSession ? childItem?.totalSession : '?'}
                        </CaptionLight> */}
                    </Row>
                    <Row style={{ alignItems: 'center' }}>
                        <CaptionLight style={{ color: colors.LAVEL_2, marginRight: 8 }}>
                            {dayjs(childItem?.startTime).format('Ahh:mm').replace('오전', 'AM').replace('오후', 'PM')}
                        </CaptionLight>
                        <CaptionBold style={{ color: colors.LAVEL_2, marginRight: 8 }}>{' · '}</CaptionBold>
                        <CaptionLight style={{ color: colors.LAVEL_2 }}>{childItem?.duration}분</CaptionLight>
                    </Row>
                </Col>
            </Col>

            {showModal && (
                <HoverItem
                    style={hoverStyle}
                    requestType={childItem?.requestType}
                    backgroundColor={colors.WHITE_50}
                    isVisible={showModal}
                    childIndex={cellIndex}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <Row
                        style={{
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Row style={{ width: 200 }}>
                            <Col
                                style={{
                                    width: 8,
                                    height: 22,
                                    borderRadius: 8,
                                    backgroundColor: colors.ORANGE,
                                    marginRight: 11,
                                }}
                            />
                            <Body3Bold style={{ color: colors.LAVEL_3 }}>{`${childItem?.coachName} 강사`}</Body3Bold>
                        </Row>
                        <Row
                            style={{
                                width: 64,
                                height: 23,
                                borderRadius: 34,
                                border: '1px solid #FF8777',
                                color: colors.ORANGE,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <CaptionBold>{childItem?.requestType === 'change' ? '변경 요청' : '수업 신청'}</CaptionBold>
                        </Row>
                    </Row>

                    {childItem?.requestType === 'register' && (
                        <>
                            <Body3Light style={{ color: colors.LAVEL_3, marginTop: 16 }}>
                                {dayjs(childItem?.startTime).format('M월 D일(ddd)')}
                            </Body3Light>
                            <Body3Bold style={{ color: colors.LAVEL_3, marginTop: 8 }}>
                                {dayjs(childItem?.startTime).format('A h:mm ~ ')}
                                {dayjs(childItem?.endTime).format('A h:mm ')}
                                {`(${childItem?.duration}분)`}
                            </Body3Bold>
                            <Row style={{ alignItems: 'center', marginTop: 8 }}>
                                <Body3Bold style={{ color: colors.LAVEL_3 }}>{childItem?.alias} 회원</Body3Bold>
                                {/* <Body3Light style={{ color: colors.LAVEL_2, marginLeft: 16 }}>
                                    {`${childItem?.currentSession ? childItem?.currentSession : '?'}/${
                                        childItem?.totalSession ? childItem?.totalSession : '?'
                                    }회`}
                                </Body3Light> */}
                            </Row>
                        </>
                    )}

                    {childItem?.requestType === 'change' && (
                        <>
                            <Col style={{ alignItems: 'center' }}>
                                <Col
                                    style={{
                                        width: 268,
                                        borderRadius: 8,
                                        height: 88,
                                        border: '1px solid #D4DBE8',
                                        marginTop: 16,
                                        padding: 16,
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Row style={{ alignItems: 'center' }}>
                                        <Row
                                            style={{
                                                width: 38,
                                                height: 22,
                                                borderRadius: 24,
                                                backgroundColor: colors.GREY_200,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: colors.WHITE_50,
                                            }}
                                        >
                                            <CaptionRegular>{'기존'}</CaptionRegular>
                                        </Row>
                                        <Body3Light style={{ color: colors.LAVEL_3, marginLeft: 8 }}>
                                            {dayjs(childItem?.beforeStartTime).format('M월 D일(ddd)')}
                                        </Body3Light>
                                    </Row>
                                    <Body3Bold style={{ color: colors.LAVEL_3 }}>
                                        {dayjs(childItem?.beforeStartTime).format('A h:mm ~ ')}{' '}
                                        {dayjs(childItem?.beforeEndTime).format('A h:mm')}
                                    </Body3Bold>
                                </Col>

                                <img
                                    src={I_DOWN_TRIANGLE}
                                    style={{
                                        width: 16,
                                        height: 16,
                                        marginTop: 8,
                                        marginBottom: 8,
                                    }}
                                />

                                <Col
                                    style={{
                                        width: 268,
                                        borderRadius: 8,
                                        height: 88,
                                        border: '1px solid #D4DBE8',
                                        padding: 16,
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Row style={{ alignItems: 'center' }}>
                                        <Row
                                            style={{
                                                width: 38,
                                                height: 22,
                                                borderRadius: 24,
                                                backgroundColor: colors.BLUE_500,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: colors.WHITE_50,
                                            }}
                                        >
                                            <CaptionRegular>{'변경'}</CaptionRegular>
                                        </Row>
                                        <Body3Light style={{ color: colors.LAVEL_3, marginLeft: 8 }}>
                                            {dayjs(childItem?.changedStartTime).format('M월 D일(ddd)')}
                                        </Body3Light>
                                    </Row>
                                    <Body3Bold style={{ color: colors.LAVEL_3 }}>
                                        {dayjs(childItem?.changedStartTime).format('A h:mm ~ ')}{' '}
                                        {dayjs(childItem?.changedEndTime).format('A h:mm')}
                                    </Body3Bold>
                                </Col>
                            </Col>

                            <Body3Bold style={{ color: colors.LAVEL_3, marginTop: 16 }}>
                                {childItem?.alias} 회원
                            </Body3Bold>
                        </>
                    )}

                    <CaptionRegular style={{ color: colors.LAVEL_2, marginTop: 8 }}>
                        수강권 : {childItem?.membership ? childItem?.membership?.name : '-'}
                    </CaptionRegular>

                    <Row
                        style={{
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginTop: 16,
                        }}
                    >
                        <WhiteRoundButton
                            text={'거절하기'}
                            size="tiny"
                            customStyle={{ width: 120, height: 24 }}
                            callBack={handleRejectButtonClick}
                        />
                        <ColorRoundButton
                            text={'수락하기'}
                            size="tiny"
                            customStyle={{ width: 120, height: 24 }}
                            callBack={handleApproveButtonClick}
                        />
                    </Row>
                </HoverItem>
            )}
        </Col>
    );
};

export default ScheduleBlock;
