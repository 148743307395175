import React, { useState } from 'react';
import { Col, Row, truncateString } from '../../lib/utils';
import { Body2Regular, Body3Light, Body3Regular, CaptionRegular } from '../../lib/font';
import { colors } from '../../lib/colors';
import { I_EMPTY_V2, I_GREY_CIRCLE } from '../../types/images';
import dayjs from 'dayjs';
import ProfileImage from '../ProfileImage';
import styled from 'styled-components';

const Container = styled.div<{ isActive: boolean }>`
    display: flex;
    flex-direction: row;
    width: 1190px;
    height: 80px;
    padding-top: 16px;

    background-color: ${({ isActive }) => (isActive ? '#F0F6FF' : '#FDFEFF')};
    cursor: pointer;
    &:hover {
        background-color: #ecf1fc;
    }
`;

const CommunicationListBody = ({ currentItems, onClickDetailButton, communicationObject }: any) => {
    return (
        <Col
            style={{
                width: 1192,
                minHeight: 184,
                borderRadius: '0px 0px 15px 15px',
                backgroundColor: colors.WHITE_50,
                border: '1px solid #C3D4F4',
                alignItems: 'center',
            }}
        >
            {currentItems && currentItems.length > 0 ? (
                currentItems.map((item: any, index: number) => {
                    return (
                        <Container
                            isActive={item._id === communicationObject?._id}
                            onClick={() => onClickDetailButton(item)}
                            key={'comm' + index}
                        >
                            <Row
                                style={{
                                    width: 48,
                                    height: 30,
                                    backgroundColor: item.status === 'PENDING' ? colors.ERROR_50 : colors.SUCCESS_50,
                                    borderRadius: 4,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginLeft: 24,
                                    marginRight: 24,
                                }}
                            >
                                <CaptionRegular
                                    style={{ color: item.status === 'PENDING' ? colors.ERROR : colors.SUCCESS }}
                                >
                                    {item.status === 'PENDING' ? '대기' : '완료'}
                                </CaptionRegular>
                            </Row>

                            <div style={{ width: 1, height: 24, backgroundColor: colors.WHITE_50 }} />
                            <Row
                                style={{
                                    width: 108,
                                    height: 24,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginLeft: 24,
                                    marginRight: 24,
                                }}
                            >
                                <Body3Regular style={{ color: colors.LAVEL_3 }}>
                                    {item.category === 'PTFEEDBACK'
                                        ? 'PT 피드백'
                                        : item.category === 'FACILITY'
                                        ? '시설문의'
                                        : item.category === 'COUNSEL'
                                        ? '상담문의'
                                        : '기타'}
                                </Body3Regular>
                            </Row>

                            <div style={{ width: 1, height: 24, backgroundColor: colors.WHITE_50 }} />
                            <Row
                                style={{
                                    width: 112,
                                    height: 24,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginLeft: 24,
                                    marginRight: 24,
                                }}
                            >
                                <ProfileImage
                                    imageUrl={item?.profileImageUrl}
                                    type="VerySmall"
                                    profileName={item?.profileName}
                                    profileColorType={item?.profileColorType}
                                />
                                <Body3Regular style={{ color: colors.LAVEL_3, marginLeft: 12 }}>
                                    {truncateString(item.memberName, 5)}
                                </Body3Regular>
                            </Row>

                            <div style={{ width: 1, height: 24, backgroundColor: colors.WHITE_50 }} />

                            <Row
                                style={{
                                    width: 112,
                                    height: 24,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginLeft: 24,
                                    marginRight: 24,
                                }}
                            >
                                <ProfileImage
                                    imageUrl={item?.profileImageUrl}
                                    type="VerySmall"
                                    profileName={item?.profileName}
                                    profileColorType={item?.profileColorType}
                                />
                                <Body3Regular style={{ color: colors.LAVEL_3, marginLeft: 12 }}>
                                    {item.coachName}
                                </Body3Regular>
                            </Row>
                            <div style={{ width: 1, height: 24, backgroundColor: colors.WHITE_50 }} />

                            <Row
                                style={{
                                    width: 404,
                                    height: 48,
                                    // alignItems: 'center',
                                    justifyContent: 'center',
                                    marginLeft: 24,
                                    marginRight: 24,
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                <Body3Regular
                                    style={{
                                        color: colors.LAVEL_3,
                                        display: 'block',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        lineHeight: '1.2em',
                                        maxHeight: '2.4em',
                                        whiteSpace: 'normal',
                                    }}
                                >
                                    {truncateString(item.contents[0].content, 48)}
                                </Body3Regular>
                            </Row>

                            <div style={{ width: 1, height: 24, backgroundColor: colors.WHITE_50 }} />

                            <Row
                                style={{
                                    width: 120,
                                    height: 24,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginLeft: 24,
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                <Body2Regular style={{ color: colors.LAVEL_3 }}>
                                    {dayjs(item.updatedTime).format('YYYY/MM/DD')}
                                </Body2Regular>
                            </Row>
                        </Container>
                    );
                })
            ) : (
                <Col
                    style={{
                        width: 250,
                        height: 56,
                        marginTop: 56,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <img style={{ width: 24, height: 24 }} src={I_EMPTY_V2} />
                    <Body3Light style={{ marginLeft: 16, color: colors.LAVEL_2 }}>
                        아직 회원에게 온 소통글이 없어요
                    </Body3Light>
                </Col>
            )}
        </Col>
    );
};

export default CommunicationListBody;
