import axios, { AxiosResponse } from 'axios';

export /**
 * @method post
 * @brief 관리자 유저플로우 저장
 */
const storeUserFlow = async (
  event?: string | null,
  accessToken?:string
): Promise<any> => {
  let response = {} as AxiosResponse<any, any>;
  try {
    response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER}/v1/adminUserflows`,
        headers: {authorization: accessToken},
        data: {
            event
        },
    });

    if (response.status === 200) {
        return response.data;
    }
  } catch (error: any) {
    // Error 😨
    switch (error.response.status) {
        case 401:
            return {status: error.response.status};
        case 403:
            return {status: error.response.status};
        case 500:
            alert('서버 내부 오류.');
        return {status: error.response.status};
    }
  }
};