import React from 'react';
import { Body2Bold, CaptionRegular } from '../../../lib/font';
import { colors } from '../../../lib/colors';

const FeedSelectListHeader = ({ onClickAllButton, coachId }: any) => {
    return (
        <button
            onClick={onClickAllButton}
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 282, border: 'none' }}
        >
            <CaptionRegular style={{ color: colors.LAVEL_3 }}>{'전체 선택'}</CaptionRegular>
            <div
                style={{
                    width: 16,
                    height: 16,
                    borderRadius: 12,
                    border: '1px solid #A6BFEF',
                    marginLeft: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {coachId === '' && (
                    <div
                        style={{
                            width: 10,
                            height: 10,
                            borderRadius: 9,
                            backgroundColor: colors.BLUE_500,
                        }}
                    />
                )}
            </div>
        </button>
    );
};

export default FeedSelectListHeader;
