import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { Row } from '../../lib/utils';
import { Body3Light, Body3Regular, Head4, Subtitle1Bold } from '../../lib/font';
import { I_POPUP_EXIT } from '../../types/images';
import { colors } from '../../lib/colors';
import NormalButton from '../Button/NormalButton';
import WhiteSquareButton from '../Button/WhiteSquareButton';
import ColorSquareButton from '../Button/ColorSquareButton';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalInner = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    width: 880px;
    height: 460px;
    border-radius: 8px;
    box-shadow: 8px 8px 24px 0px #00000052;
    padding-left: 48px;
`;

const HoverImage = styled.img`
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

type Props = {
    modalVisible: boolean;
    setModalVisible: Dispatch<SetStateAction<boolean>>;
    onClickWithDrawalRepeat: any;
    reasonText: string;
    setReasonText: Dispatch<SetStateAction<string>>;
};

const ModalWithDrawal = ({
    modalVisible,
    setModalVisible,
    onClickWithDrawalRepeat,
    reasonText,
    setReasonText,
}: Props) => {
    return (
        <ModalOverLay>
            <ModalInner>
                <Row>
                    <Subtitle1Bold style={{ color: colors.LAVEL_4, marginTop: 36 }}>
                        {'정말 탈퇴하시겠습니까?'}
                    </Subtitle1Bold>

                    <HoverImage
                        src={I_POPUP_EXIT}
                        style={{ width: 32, height: 32, marginLeft: 545, marginTop: 16 }}
                        onClick={() => setModalVisible(false)}
                    />
                </Row>
                <Body3Regular style={{ marginTop: 16, color: colors.LAVEL_3 }}>
                    {'정말 ‘라포’를 떠나실건가요? 어떤 이유인지 알려주세요!:('}
                </Body3Regular>

                <textarea
                    placeholder="탈퇴 사유 혹은 라포를 쓰시면서 불편했던 점을 알려주세요"
                    value={reasonText}
                    onChange={(e) => {
                        setReasonText(e.target.value);
                    }}
                    style={{
                        width: 796,
                        height: 236,
                        borderRadius: 8,
                        border: '1px solid #A6BFEF',
                        marginTop: 16,
                        color: colors.BLUE_900,
                        fontSize: 16,

                        padding: 16,
                    }}
                />
                <Row style={{ width: 312, justifyContent: 'space-between', marginLeft: 488, marginTop: 24 }}>
                    <WhiteSquareButton
                        text="취소"
                        size="middle"
                        callBack={() => {
                            setModalVisible(false);
                        }}
                    />
                    <ColorSquareButton
                        text={'탈퇴하기'}
                        size="middle"
                        callBack={() =>
                            onClickWithDrawalRepeat(
                                '',
                                '탈퇴하시면 그동안의 이용내역이 모두 삭제됩니다\n그래도 탈퇴를 진행하시겠습니까?'
                            )
                        }
                    />
                </Row>
            </ModalInner>
        </ModalOverLay>
    );
};

export default ModalWithDrawal;
