import React from 'react';
import { MutatingDots } from 'react-loader-spinner';
import styled from 'styled-components';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
`;

const Loading = () => {
    return (
        <ModalOverLay>
            <MutatingDots
                visible={true}
                height="100"
                width="100"
                color="#3D73DD"
                secondaryColor="#3D73DD"
                radius="12.5"
                ariaLabel="mutating-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </ModalOverLay>
    );
};

export default Loading;
