import axios, { AxiosResponse } from 'axios';

export /**
 * @method get
 * @brief 대시보드 오늘의 수업 조회
 */
const getCenterTodayRecordAPI = async (centerId: string, date: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v2/centers/today-record/${centerId}?date=${date}`,
            headers: { authorization: accessToken },
            data: {},
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method get
 * @brief 대시보드 오늘의 소통상자 데이터 조회
 */
const getCenterCommRecordAPI = async (adminId: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/commBoxs/today-record/admin/${adminId}`,
            headers: { authorization: accessToken },
            data: {},
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method get
 * @brief 대시보드 수업진행 상황 그래프 조회
 */
const getClassProgressAPI = async (centerId: string, date: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/centers/schedule-progress/${centerId}?date=${date}`,
            headers: { authorization: accessToken },
            data: {},
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method get
 * @brief 대시보드 수강권 등록
 */
const getMembershipProgressAPI = async (centerId: string, date: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v2/centers/membership-register/${centerId}?date=${date}`,
            headers: { authorization: accessToken },
            data: {},
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method get
 * @brief 대시보드 센터내 활성 회원 수 그래프 조회
 */
const getActiveMembersAPI = async (centerId: string, date: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/centers/membership-member/${centerId}?date=${date}`,
            headers: { authorization: accessToken },
            data: {},
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};
