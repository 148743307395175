import { ResponsiveBar } from '@nivo/bar';

import MemberListEmptyGraph from './MemberListEmptyGraph';
import { colors } from '../../../../lib/colors';
import dayjs from 'dayjs';

const CustomTextLayer = ({ bars }: any) => {
    return (
        <g>
            {bars.map((bar: any, index: number) => (
                <text
                    key={index}
                    x={bar.x + bar.width / 2}
                    y={bar.y + bar.height / 2}
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    style={{
                        fill: bar.data.id === 'activeMemberCount' ? 'white' : 'black', // 조건에 따라 텍스트 색상 지정
                        fontSize: 16,
                        fontWeight: 700,
                        //lineHeight: 24,
                    }}
                >
                    {bar.key.includes('active') && bar.data.value > 0 && bar.data.value}
                    {bar.key.includes('interval') && bar.data.value > 0 && bar.data.data.totalMemberCount}
                </text>
            ))}
        </g>
    );
};

const MemberListWeeklyGraph = ({ weeklyData, weeklyMaxCount, CustomToolTip }: any) => {
    const CustomTick = (tick: any) => {
        const { x, y, value } = tick;

        let weeklyFilterData = null;

        weeklyFilterData = weeklyData.find((e: any) => e.xLabel === value);

        return (
            <g transform={`translate(${x},${y})`} style={{ textAnchor: 'middle' }}>
                {weeklyFilterData && weeklyFilterData.isToday && (
                    <rect x={-3} y={17} fill={colors.BLUE_500} width={8} height={8} rx={4} />
                )}

                {/* <line stroke="rgb(232,193,160)" strokeWidth={2} y1={0} y2={40} /> */}

                <text
                    x={0}
                    y={43}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{
                        fontSize: 20,
                        fontWeight: 700,
                        fill:
                            weeklyFilterData && weeklyFilterData.isToday
                                ? colors.LAVEL_4
                                : dayjs(weeklyFilterData.startDate).isAfter(dayjs())
                                ? colors.GREY_100
                                : colors.LAVEL_2,
                        outlineWidth: 0,
                    }}
                >
                    {value}
                </text>
                <text
                    x={0}
                    y={72}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{
                        fontSize: 12,
                        fill:
                            weeklyFilterData && weeklyFilterData.isToday
                                ? colors.LAVEL_4
                                : dayjs(weeklyFilterData.startDate).isAfter(dayjs())
                                ? colors.GREY_100
                                : colors.LAVEL_2,
                        outlineWidth: 0,
                        fontWeight: 400,
                    }}
                >
                    {weeklyFilterData && weeklyFilterData.xSubLabel}
                </text>
            </g>
        );
    };

    return weeklyData && weeklyData.length === 0 ? (
        <MemberListEmptyGraph />
    ) : (
        <ResponsiveBar
            data={weeklyData}
            layers={[
                'grid',
                'axes',
                'bars',
                'markers',
                'legends',
                CustomTextLayer, // 커스텀 텍스트 레이어 추가
            ]}
            keys={['activeMemberCount', 'interval']}
            indexBy="xLabel"
            margin={{ right: 70, top: 15, bottom: 100, left: 90 }}
            axisLeft={{
                tickSize: 0,
            }}
            axisBottom={{
                tickSize: 0,
                tickPadding: 5,
                tickRotation: 0,
                renderTick: CustomTick,
            }} // 바텀 라벨의 스타일
            isInteractive={true} // 호버햇을때 말풍선
            tooltip={CustomToolTip}
            padding={weeklyData && weeklyData.length > 4 ? 0.7 : 0.65}
            enableLabel={false}
            minValue={0}
            maxValue={weeklyMaxCount < 10 ? 10 : weeklyMaxCount}
            // borderRadius={6}
            // borderWidth={2}
            // borderColor={'#fff'}
            colors={['#3D73DD', '#D4DBE8']} // 커스터하여 사용할 때
            colorBy="id" // 색상을 keys 요소들에 각각 적용
            labelSkipWidth={36}
            labelSkipHeight={12}
            motionConfig={'gentle'}
            layout="vertical"
        />
    );
};

export default MemberListWeeklyGraph;
