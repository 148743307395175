import React from 'react';
import { Row } from '../../lib/utils';
import NormalButton from '../Button/NormalButton';
import WhiteRoundButton from '../Button/WhiteRoundButton';
import ColorRoundButton from '../Button/ColorRoundButton';
import { I_EDIT, I_TRASH } from '../../types/images';

type Props = {
    modalObject: any;
    isInputMode: boolean;
    isEditMode: boolean;
    text: string;
    editText: string;
    onClickCreateReply: () => void;
    onClickReplyButton: () => void;
    onClickEditReply: () => void;
    onClickEditReplyButton: () => void;
    onClickDeleteReply: () => void;
};
const ModalDetailCommunicationBottom = ({
    modalObject,
    isInputMode,
    isEditMode,
    text,
    editText,
    onClickCreateReply,
    onClickReplyButton,
    onClickEditReply,
    onClickEditReplyButton,
    onClickDeleteReply,
}: Props) => {
    return (
        <>
            {modalObject?.status === 'PENDING' ? (
                <Row
                    style={{
                        width: '100%',
                        justifyContent: 'flex-end',
                        marginTop: 36,
                        paddingRight: 72,
                    }}
                >
                    {isInputMode ? (
                        <ColorRoundButton
                            isDisabled={text.trim().length === 0}
                            size="small"
                            callBack={onClickCreateReply}
                            text={'답변 작성'}
                        />
                    ) : (
                        <WhiteRoundButton
                            text="답변 작성"
                            callBack={onClickReplyButton}
                            size="small"
                            customStyle={{ width: 111, height: 36 }}
                        />
                    )}
                </Row>
            ) : isEditMode ? (
                <Row
                    style={{
                        width: '100%',
                        justifyContent: 'flex-end',
                        marginTop: 36,
                        paddingRight: 72,
                    }}
                >
                    <ColorRoundButton
                        isDisabled={editText.trim().length === 0}
                        size="small"
                        callBack={onClickEditReply}
                        text={'답변 보내기'}
                    />
                </Row>
            ) : (
                <Row
                    style={{
                        width: 588,
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        alignSelf: 'center',
                        marginTop: 12,
                    }}
                >
                    <Row
                        onClick={onClickDeleteReply}
                        style={{
                            width: 32,
                            height: 32,
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                        }}
                    >
                        <img src={I_TRASH} style={{ width: 24, height: 24 }} />
                    </Row>
                    <Row
                        onClick={onClickEditReplyButton}
                        style={{
                            width: 32,
                            height: 32,
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                        }}
                    >
                        <img src={I_EDIT} style={{ width: 24, height: 24 }} />
                    </Row>
                    {/* <NormalButton onClick={onClickEditReplyButton} text={'내용 수정'} style={{ marginRight: 24 }} />
                    <NormalButton onClick={onClickDeleteReply} text={'답변 삭제'} /> */}
                </Row>
            )}
        </>
    );
};

export default ModalDetailCommunicationBottom;
