import React, { CSSProperties, Dispatch, SetStateAction } from 'react';
import { Row } from '../../lib/utils';
import { colors } from '../../lib/colors';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
type ButtonProps = {
    isClick: boolean;
};

const Container = styled.div`
    width: 22.4rem;
    height: 4rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-radius: 4;
    color: #3d73dd;

    /*   
        backgroundColor: colors.WHITE_500, */
`;

const ButtonContainer = styled.button<ButtonProps>`
    width: 50%;
    height: 40px;
    border-radius: 2px;
    background-color: ${(props) => (props.isClick ? '#FDFEFF' : '#EDF4FF')};
    border: ${(props) => (props.isClick ? '1px solid #3D73DD' : '1px solid #C3D4F4')};
    font-size: 1.8rem;
    font-weight: ${(props) => (props.isClick ? 700 : 300)};
    animation: ${fadeIn} 1s ease; /* 애니메이션 적용 */
    transition: background-color 0.3s ease, color 0.3s ease, font-weight 0.3s ease;
    color: #3d73dd;
`;

type Props = {
    leftText: string;
    rightText: string;
    isLeft: boolean;
    isRight: boolean;
    callBackLeft: () => void;
    callBackRight: () => void;
    customStyle?: CSSProperties;
};
const SmallToggleButton = ({
    leftText,
    rightText,
    isLeft,
    isRight,
    callBackLeft,
    callBackRight,
    customStyle,
}: Props) => {
    return (
        <Container style={customStyle}>
            <ButtonContainer onClick={callBackLeft} isClick={isLeft}>
                <span>{leftText}</span>
            </ButtonContainer>

            <ButtonContainer onClick={callBackRight} isClick={isRight}>
                <span>{rightText}</span>
            </ButtonContainer>
        </Container>
    );
};

export default SmallToggleButton;
