import { Body1Bold, Body3Light, Body3Regular, CaptionRegular, Head4, Subtitle1Bold } from '../../lib/font';
import { colors } from '../../lib/colors';
import { Col, Row } from '../../lib/utils';
import { I_POPUP_EXIT } from '../../types/images';
import styled from 'styled-components';

const HoverImage = styled.img`
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

const ModalAddTrainerHeader = ({ step, onClickExit }: any) => {
    return (
        <Col>
            <Row>
                <Subtitle1Bold style={{ color: colors.LAVEL_4, marginLeft: 48, marginTop: 36 }}>
                    {'강사 등록하기'}
                </Subtitle1Bold>

                <HoverImage
                    src={I_POPUP_EXIT}
                    style={{ width: 32, height: 32, marginLeft: 646, marginTop: 16 }}
                    onClick={onClickExit}
                />
            </Row>

            <Body3Regular style={{ color: colors.LAVEL_3, marginLeft: 48, marginTop: 16 }}>
                {'센터에 소속된 트레이너의 코드를 입력하시면 쉽고 빠르게 연동이 가능합니다!'}
            </Body3Regular>
            <Row style={{ marginTop: 16, marginLeft: 48 }}>
                <div
                    style={{
                        width: 24,
                        height: 24,
                        borderRadius: 18,
                        backgroundColor: step === 'first' ? colors.PRIMARY_BLUE : colors.BLUE_100,
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <CaptionRegular style={{ color: colors.WHITE_50 }}>1</CaptionRegular>
                </div>
                <hr
                    style={{
                        width: 32,
                        height: 1,
                        borderColor: colors.BLUE_100,
                        alignSelf: 'center',
                    }}
                />
                <div
                    style={{
                        width: 24,
                        height: 24,
                        borderRadius: 18,
                        backgroundColor: step === 'second' ? colors.PRIMARY_BLUE : colors.BLUE_100,
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <CaptionRegular style={{ color: colors.WHITE_50 }}>2</CaptionRegular>
                </div>
                <hr
                    style={{
                        width: 32,
                        height: 1,
                        borderColor: colors.BLUE_100,
                        alignSelf: 'center',
                    }}
                />
                <div
                    style={{
                        width: 24,
                        height: 24,
                        borderRadius: 18,
                        backgroundColor: step === 'third' ? colors.PRIMARY_BLUE : colors.BLUE_100,
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <CaptionRegular style={{ color: colors.WHITE_50 }}>3</CaptionRegular>
                </div>
            </Row>
        </Col>
    );
};

export default ModalAddTrainerHeader;
