import dayjs from 'dayjs';

const weeklySchedulesDummyData = [
    {
        daysOfWeek: '일',
        day: '23일',
        schedules: [
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [
                {
                    _id: '64ba90c68090fe0019357f24',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '64939c3a6fb5ee003502fe69',
                    alias: '성현수',
                    startTime: '2023-07-23T01:00:00.000Z',
                    endTime: '2023-07-23T01:50:00.000Z',
                    requestDeadline: '2023-07-22T14:00:00.000Z',
                    createdAt: '2023-07-21T14:05:58.401Z',
                    updatedAt: '2023-07-21T14:05:58.401Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
            ],
            [],
            [
                {
                    _id: '64bb1aee8090fe0019358dc3',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '64ab676dd63356004b46eaeb',
                    alias: '정다은',
                    startTime: '2023-07-23T03:00:00.000Z',
                    endTime: '2023-07-23T03:50:00.000Z',
                    requestDeadline: '2023-07-22T14:00:00.000Z',
                    createdAt: '2023-07-21T23:55:26.841Z',
                    updatedAt: '2023-07-22T12:55:26.687Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
            ],
            [],
            [
                {
                    _id: '64b48c9525fa4b001273fcb0',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '6477b33b2a7db2001275cff1',
                    alias: '김가은',
                    startTime: '2023-07-23T05:00:00.000Z',
                    endTime: '2023-07-23T05:50:00.000Z',
                    requestDeadline: '2023-07-22T14:00:00.000Z',
                    createdAt: '2023-07-17T00:34:29.252Z',
                    updatedAt: '2023-07-19T14:25:05.824Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
            ],
            [
                {
                    _id: '64b7f27fd7d6a10019ff29ef',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '642e79e71215e700298616c1',
                    alias: '이동연',
                    startTime: '2023-07-23T06:00:00.000Z',
                    endTime: '2023-07-23T07:50:00.000Z',
                    requestDeadline: '2023-07-22T14:00:00.000Z',
                    createdAt: '2023-07-19T14:26:07.158Z',
                    updatedAt: '2023-07-19T14:26:07.158Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 110,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
            ],
            [
                {
                    _id: '64acf612052e6d008257a9b0',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '649408fd29fee00040f281d7',
                    alias: '오정현',
                    startTime: '2023-07-23T07:00:00.000Z',
                    endTime: '2023-07-23T07:50:00.000Z',
                    requestDeadline: '2023-07-22T14:00:00.000Z',
                    createdAt: '2023-07-11T06:26:26.545Z',
                    updatedAt: '2023-07-11T06:26:26.545Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 3,
                    totalSession: 10,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
            ],
            [
                {
                    _id: '64acf61f052e6d008257a9f8',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '64818afdcec7d000770ba9a1',
                    alias: '신경호',
                    startTime: '2023-07-23T08:00:00.000Z',
                    endTime: '2023-07-23T08:50:00.000Z',
                    requestDeadline: '2023-07-22T14:00:00.000Z',
                    createdAt: '2023-07-11T06:26:39.132Z',
                    updatedAt: '2023-07-11T06:26:39.132Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 20,
                    totalSession: 41,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
            ],
            [
                {
                    _id: '64acf63d9d3824007791820c',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '649bab598d6af0004b6564fa',
                    alias: '유지연',
                    startTime: '2023-07-23T09:00:00.000Z',
                    endTime: '2023-07-23T09:50:00.000Z',
                    requestDeadline: '2023-07-22T14:00:00.000Z',
                    createdAt: '2023-07-11T06:27:09.019Z',
                    updatedAt: '2023-07-19T06:43:29.827Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 5,
                    totalSession: 10,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
                {
                    _id: '64b913338090fe0019351142',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '642e6586b1f41c002aa7560f',
                    alias: '조재운',
                    startTime: '2023-07-23T09:40:00.000Z',
                    endTime: '2023-07-23T10:30:00.000Z',
                    requestDeadline: '2023-07-22T14:00:00.000Z',
                    createdAt: '2023-07-20T10:57:55.750Z',
                    updatedAt: '2023-07-20T10:57:55.750Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
            ],
            [],
            [],
            [],
            [],
            [],
        ],
    },
    {
        daysOfWeek: '월',
        day: '24일',
        schedules: [
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [
                {
                    _id: '64bb1adf04a3c4002afbc114',
                    scheduleBundleId: null,
                    coachId: '6476bde354b8b500124e87c7',
                    memberId: '647b0cb5cd47a200191db34a',
                    alias: '이화진',
                    startTime: '2023-07-24T00:00:00.000Z',
                    endTime: '2023-07-24T00:50:00.000Z',
                    requestDeadline: '2023-07-23T13:00:00.000Z',
                    createdAt: '2023-07-21T23:55:11.745Z',
                    updatedAt: '2023-07-21T23:55:11.745Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 4,
                    totalSession: 10,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사1',
                },
                {
                    _id: '64b8f98f8090fe001934fed4',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '649a70b0584f13002a0972a7',
                    alias: '백영옥',
                    startTime: '2023-07-24T00:00:00.000Z',
                    endTime: '2023-07-24T00:50:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-20T09:08:31.786Z',
                    updatedAt: '2023-07-20T09:08:31.786Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64b62b55d7d6a10019fe510d',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '64829f22d59539004b953d9e',
                    alias: '임효빈',
                    startTime: '2023-07-24T00:00:00.000Z',
                    endTime: '2023-07-24T00:50:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-18T06:04:05.194Z',
                    updatedAt: '2023-07-18T06:04:05.194Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 35,
                    totalSession: 70,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
            ],
            [
                {
                    _id: '64bb1ae325fa4b00127663d9',
                    scheduleBundleId: null,
                    coachId: '6476bde354b8b500124e87c7',
                    memberId: '6477df2544544d001920dd22',
                    alias: '이지연',
                    startTime: '2023-07-24T01:00:00.000Z',
                    endTime: '2023-07-24T01:50:00.000Z',
                    requestDeadline: '2023-07-23T13:00:00.000Z',
                    createdAt: '2023-07-21T23:55:15.790Z',
                    updatedAt: '2023-07-21T23:55:15.790Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사1',
                },
                {
                    _id: '64b91f3104a3c4002afb45d9',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '649f7e5f069939003544425c',
                    alias: '이하경',
                    startTime: '2023-07-24T01:00:00.000Z',
                    endTime: '2023-07-24T01:50:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-20T11:49:05.906Z',
                    updatedAt: '2023-07-20T11:49:05.906Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64bbdf6f04a3c4002afc1710',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '64672e366203c8010632236f',
                    alias: '권나연',
                    startTime: '2023-07-24T01:00:00.000Z',
                    endTime: '2023-07-24T01:50:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-22T13:53:51.715Z',
                    updatedAt: '2023-07-22T13:53:51.715Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64b62b648090fe001933e13f',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '64812853d59539004b948cf0',
                    alias: '오혜수',
                    startTime: '2023-07-24T01:00:00.000Z',
                    endTime: '2023-07-24T01:50:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-18T06:04:20.174Z',
                    updatedAt: '2023-07-18T06:04:20.174Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 37,
                    totalSession: 70,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
                {
                    _id: '64bb7681d7d6a10019003d64',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64659cc06203c8010631c793',
                    alias: '이래원',
                    startTime: '2023-07-24T01:30:00.000Z',
                    endTime: '2023-07-24T02:00:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-22T06:26:09.838Z',
                    updatedAt: '2023-07-22T06:26:46.125Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 30,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사6',
                },
            ],
            [
                {
                    _id: '64b0c07b5a46c600114684c1',
                    scheduleBundleId: null,
                    coachId: '642596251f90e500615765dd',
                    memberId: '64787eb2975144001275309a',
                    alias: '위나라',
                    startTime: '2023-07-24T02:00:00.000Z',
                    endTime: '2023-07-24T02:50:00.000Z',
                    requestDeadline: '2023-07-23T12:00:00.000Z',
                    createdAt: '2023-07-14T03:26:51.675Z',
                    updatedAt: '2023-07-14T03:26:51.675Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사5',
                },
                {
                    _id: '64b91d8125fa4b001275ddf0',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '64352bc579c01500a2039cb7',
                    alias: '정병준',
                    startTime: '2023-07-24T02:00:00.000Z',
                    endTime: '2023-07-24T02:50:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-20T11:41:53.594Z',
                    updatedAt: '2023-07-20T11:41:53.594Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64b3fbe35a46c6001147932e',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '64b36ee654d0580018e854f6',
                    alias: '한명연',
                    startTime: '2023-07-24T02:00:00.000Z',
                    endTime: '2023-07-24T02:50:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-16T14:17:07.467Z',
                    updatedAt: '2023-07-16T14:17:07.467Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64b62b7d25fa4b001274a94f',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '648169329200d600823891ca',
                    alias: '위지우',
                    startTime: '2023-07-24T02:00:00.000Z',
                    endTime: '2023-07-24T02:50:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-18T06:04:45.141Z',
                    updatedAt: '2023-07-18T06:04:45.141Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 10,
                    totalSession: 20,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
                {
                    _id: '64bb768504a3c4002afbf0de',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '644bb2195345c3002b8dbf7f',
                    alias: '이서영',
                    startTime: '2023-07-24T02:30:00.000Z',
                    endTime: '2023-07-24T03:00:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-22T06:26:13.728Z',
                    updatedAt: '2023-07-22T06:27:17.117Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
            ],
            [
                {
                    _id: '64b62b907a2f110012ee9e8e',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '6489734eb4e6370012dabc41',
                    alias: '김태정',
                    startTime: '2023-07-24T03:00:00.000Z',
                    endTime: '2023-07-24T03:50:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-18T06:05:04.854Z',
                    updatedAt: '2023-07-18T06:05:04.854Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 11,
                    totalSession: 46,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
                {
                    _id: '64bdbec68090fe0019365b01',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '644b817b93cf190056b626b5',
                    alias: '한혜정',
                    startTime: '2023-07-24T03:30:00.000Z',
                    endTime: '2023-07-24T04:00:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-23T23:59:02.368Z',
                    updatedAt: '2023-07-23T23:59:39.148Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
            ],
            [
                {
                    _id: '64bc9ba604a3c4002afc3bf4',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '649fb4201617bf006cfdc110',
                    alias: '강현아',
                    startTime: '2023-07-24T04:30:00.000Z',
                    endTime: '2023-07-24T05:00:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-23T03:16:54.533Z',
                    updatedAt: '2023-07-23T03:17:16.460Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
            ],
            [
                {
                    _id: '64bb366fd7d6a100190018dc',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64ba19798090fe001935535c',
                    alias: '라유민',
                    startTime: '2023-07-24T05:30:00.000Z',
                    endTime: '2023-07-24T06:00:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-22T01:52:47.170Z',
                    updatedAt: '2023-07-22T01:53:22.489Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
            ],
            [
                {
                    _id: '64bcdcf725fa4b001276f7fa',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '6490681fe3ddf4002b1b1c87',
                    alias: '김주리',
                    startTime: '2023-07-24T06:00:00.000Z',
                    endTime: '2023-07-24T06:30:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-23T07:55:35.196Z',
                    updatedAt: '2023-07-23T07:55:43.791Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 30,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사6',
                },
                {
                    _id: '64ba737504a3c4002afba72d',
                    scheduleBundleId: null,
                    coachId: '642596251f90e500615765dd',
                    memberId: '6426b4331f90e5006157bdd0',
                    alias: '허준서',
                    startTime: '2023-07-24T06:00:00.000Z',
                    endTime: '2023-07-24T06:50:00.000Z',
                    requestDeadline: '2023-07-23T12:00:00.000Z',
                    createdAt: '2023-07-21T12:00:53.074Z',
                    updatedAt: '2023-07-21T12:00:53.074Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사5',
                },
                {
                    _id: '64bbb60e04a3c4002afc09f4',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '64b157e491f76200120d446f',
                    alias: '김동민',
                    startTime: '2023-07-24T06:00:00.000Z',
                    endTime: '2023-07-24T06:50:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-22T10:57:18.322Z',
                    updatedAt: '2023-07-22T10:57:18.322Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64bcf7b904a3c4002afc5d04',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '6496677570795a0077c5bf0b',
                    alias: '박제니',
                    startTime: '2023-07-24T06:30:00.000Z',
                    endTime: '2023-07-24T07:20:00.000Z',
                    requestDeadline: '2023-07-23T03:00:00.000Z',
                    createdAt: '2023-07-23T09:49:45.121Z',
                    updatedAt: '2023-07-23T09:49:45.121Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64bb661504a3c4002afbe79f',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64a41d0b07309c00b9e9e249',
                    alias: '한지혜',
                    startTime: '2023-07-24T07:00:00.000Z',
                    endTime: '2023-07-24T07:30:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-22T05:16:05.310Z',
                    updatedAt: '2023-07-22T05:16:36.715Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 9,
                    totalSession: 26,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64bdc0688090fe0019365c7b',
                    scheduleBundleId: null,
                    coachId: '642596251f90e500615765dd',
                    memberId: '6427b1d27afb66006c60b9e1',
                    alias: '양우재',
                    startTime: '2023-07-24T07:00:00.000Z',
                    endTime: '2023-07-24T07:50:00.000Z',
                    requestDeadline: '2023-07-23T12:00:00.000Z',
                    createdAt: '2023-07-24T00:06:00.229Z',
                    updatedAt: '2023-07-24T00:06:00.229Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사5',
                },
                {
                    _id: '64bb1ae88090fe0019358db6',
                    scheduleBundleId: null,
                    coachId: '6476bde354b8b500124e87c7',
                    memberId: '64ae5c3454d0580018e69f5f',
                    alias: '박정아',
                    startTime: '2023-07-24T07:00:00.000Z',
                    endTime: '2023-07-24T07:50:00.000Z',
                    requestDeadline: '2023-07-23T13:00:00.000Z',
                    createdAt: '2023-07-21T23:55:20.243Z',
                    updatedAt: '2023-07-21T23:55:20.243Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 3,
                    totalSession: 11,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사1',
                },
                {
                    _id: '64bca84804a3c4002afc45f4',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '64a52d327488f30012f06c2c',
                    alias: '박서영',
                    startTime: '2023-07-24T07:00:00.000Z',
                    endTime: '2023-07-24T07:50:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-23T04:10:48.817Z',
                    updatedAt: '2023-07-23T04:10:48.817Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
            ],
            [
                {
                    _id: '64bb766804a3c4002afbf08b',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '644b7eed5345c3002b8dac91',
                    alias: '임수빈',
                    startTime: '2023-07-24T08:00:00.000Z',
                    endTime: '2023-07-24T08:50:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-22T06:25:44.188Z',
                    updatedAt: '2023-07-22T06:25:44.188Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사6',
                },
            ],
            [
                {
                    _id: '64bcdcbf25fa4b001276f7aa',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '6465a121c2b88900ae7041a9',
                    alias: '박은영',
                    startTime: '2023-07-24T09:00:00.000Z',
                    endTime: '2023-07-24T09:30:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-23T07:54:39.898Z',
                    updatedAt: '2023-07-24T07:36:04.029Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 10,
                    totalSession: 24,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64b91ea78090fe00193516b4',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '6460ec3aa9d8c600828b1ad6',
                    alias: '박한별',
                    startTime: '2023-07-24T09:00:00.000Z',
                    endTime: '2023-07-24T09:50:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-20T11:46:47.059Z',
                    updatedAt: '2023-07-20T11:46:47.059Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64bb33bed7d6a100190015ad',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '63d6f9fd9b70e9001940cccc',
                    alias: '임주환',
                    startTime: '2023-07-24T09:30:00.000Z',
                    endTime: '2023-07-24T10:00:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-22T01:41:18.094Z',
                    updatedAt: '2023-07-22T01:41:37.887Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
            ],
            [
                {
                    _id: '64bb1aec25fa4b00127663f7',
                    scheduleBundleId: null,
                    coachId: '6476bde354b8b500124e87c7',
                    memberId: '6476c476223a68002a58afd1',
                    alias: '박미성',
                    startTime: '2023-07-24T10:00:00.000Z',
                    endTime: '2023-07-24T10:50:00.000Z',
                    requestDeadline: '2023-07-23T13:00:00.000Z',
                    createdAt: '2023-07-21T23:55:24.419Z',
                    updatedAt: '2023-07-21T23:55:24.419Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사1',
                },
                {
                    _id: '64b93b93d7d6a10019ff9fd4',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '64939c3a6fb5ee003502fe69',
                    alias: '성현수',
                    startTime: '2023-07-24T10:00:00.000Z',
                    endTime: '2023-07-24T10:50:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-20T13:50:11.705Z',
                    updatedAt: '2023-07-20T13:50:11.705Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64b62bae7a2f110012ee9f09',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '64825b0dd59539004b951735',
                    alias: '조혜린',
                    startTime: '2023-07-24T10:00:00.000Z',
                    endTime: '2023-07-24T10:50:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-18T06:05:34.537Z',
                    updatedAt: '2023-07-18T06:05:34.537Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 24,
                    totalSession: 40,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
                {
                    _id: '64bb8f9b04a3c4002afbfaef',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '6433e6f1b1f41c002aa95a37',
                    alias: '김소연',
                    startTime: '2023-07-24T10:00:00.000Z',
                    endTime: '2023-07-24T10:50:00.000Z',
                    requestDeadline: '2023-07-23T03:00:00.000Z',
                    createdAt: '2023-07-22T08:13:15.386Z',
                    updatedAt: '2023-07-22T08:13:15.386Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64b920e8d7d6a10019ff944c',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '641da5799336e00012831684',
                    alias: '이민애',
                    startTime: '2023-07-24T11:00:00.000Z',
                    endTime: '2023-07-24T11:50:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-20T11:56:24.859Z',
                    updatedAt: '2023-07-20T11:56:24.859Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64b62bcf25fa4b001274aa9d',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '64815f41cec7d000770b8e78',
                    alias: '김민정',
                    startTime: '2023-07-24T11:00:00.000Z',
                    endTime: '2023-07-24T11:50:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-18T06:06:07.989Z',
                    updatedAt: '2023-07-18T06:06:07.989Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 32,
                    totalSession: 52,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
                {
                    _id: '64bb8fabd7d6a10019004757',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '6426bee87afb66006c608b54',
                    alias: '김혜문',
                    startTime: '2023-07-24T11:00:00.000Z',
                    endTime: '2023-07-24T11:50:00.000Z',
                    requestDeadline: '2023-07-23T03:00:00.000Z',
                    createdAt: '2023-07-22T08:13:31.672Z',
                    updatedAt: '2023-07-22T08:13:31.672Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64bcdcba8090fe00193621cc',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '63be9569375284002aa55f87',
                    alias: '허영진',
                    startTime: '2023-07-24T12:00:00.000Z',
                    endTime: '2023-07-24T12:30:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-23T07:54:34.289Z',
                    updatedAt: '2023-07-23T07:55:28.517Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64be573504a3c4002afcddc0',
                    scheduleBundleId: null,
                    coachId: '642596251f90e500615765dd',
                    memberId: '6426d921951ca00056496c5f',
                    alias: '이혜진',
                    startTime: '2023-07-24T12:00:00.000Z',
                    endTime: '2023-07-24T12:50:00.000Z',
                    requestDeadline: '2023-07-23T12:00:00.000Z',
                    createdAt: '2023-07-24T10:49:25.915Z',
                    updatedAt: '2023-07-24T10:49:25.915Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사5',
                },
                {
                    _id: '64b0ec8c6afffb001916f74f',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '64426af227d69b0019b7fcf9',
                    alias: '황건우',
                    startTime: '2023-07-24T12:00:00.000Z',
                    endTime: '2023-07-24T12:50:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-14T06:34:52.727Z',
                    updatedAt: '2023-07-20T02:15:19.788Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64bc79bed7d6a100190075d6',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '64b9315c8090fe0019351cd6',
                    alias: '동현진',
                    startTime: '2023-07-24T12:00:00.000Z',
                    endTime: '2023-07-24T12:50:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-23T00:52:14.716Z',
                    updatedAt: '2023-07-23T00:52:14.716Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64bb8fbed7d6a100190047a2',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '64292ab8f6f7b0002a05d035',
                    alias: '정서영',
                    startTime: '2023-07-24T12:00:00.000Z',
                    endTime: '2023-07-24T12:50:00.000Z',
                    requestDeadline: '2023-07-23T03:00:00.000Z',
                    createdAt: '2023-07-22T08:13:50.754Z',
                    updatedAt: '2023-07-22T08:13:50.754Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64bbb62c25fa4b001276a990',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '64323243b1f41c002aa895ab',
                    alias: '형대우',
                    startTime: '2023-07-24T13:00:00.000Z',
                    endTime: '2023-07-24T13:50:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-22T10:57:48.948Z',
                    updatedAt: '2023-07-22T10:57:48.948Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64baa404d7d6a100190002b9',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '6489ab3eac2a3e00133c1046',
                    alias: '송진아',
                    startTime: '2023-07-24T13:00:00.000Z',
                    endTime: '2023-07-24T13:50:00.000Z',
                    requestDeadline: '2023-07-23T14:00:00.000Z',
                    createdAt: '2023-07-21T15:28:04.272Z',
                    updatedAt: '2023-07-21T15:28:04.272Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 11,
                    totalSession: 45,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
                {
                    _id: '64bb8fc104a3c4002afbfb93',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '642906254ee5550077f58cdf',
                    alias: '정인길',
                    startTime: '2023-07-24T13:00:00.000Z',
                    endTime: '2023-07-24T13:50:00.000Z',
                    requestDeadline: '2023-07-23T03:00:00.000Z',
                    createdAt: '2023-07-22T08:13:53.534Z',
                    updatedAt: '2023-07-22T08:13:53.534Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [],
        ],
    },
    {
        daysOfWeek: '화',
        day: '25일',
        schedules: [
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [
                {
                    _id: '64b0804f54d0580018e76207',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '649b6d078d6af0004b6544b0',
                    alias: '심미선',
                    startTime: '2023-07-24T22:00:00.000Z',
                    endTime: '2023-07-24T22:50:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-13T22:53:03.624Z',
                    updatedAt: '2023-07-13T22:53:03.624Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
            ],
            [],
            [
                {
                    _id: '64bd10ebd7d6a1001900b254',
                    scheduleBundleId: null,
                    coachId: '6476bde354b8b500124e87c7',
                    memberId: '6476e61111a5690035e8a115',
                    alias: '이주연',
                    startTime: '2023-07-25T00:00:00.000Z',
                    endTime: '2023-07-25T00:50:00.000Z',
                    requestDeadline: '2023-07-24T13:00:00.000Z',
                    createdAt: '2023-07-23T11:37:15.459Z',
                    updatedAt: '2023-07-23T11:37:15.459Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사1',
                },
            ],
            [
                {
                    _id: '64bb4c7dd7d6a100190027d8',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '646f0939c3cc0400195247e3',
                    alias: '조휘윤',
                    startTime: '2023-07-25T01:00:00.000Z',
                    endTime: '2023-07-25T01:30:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-22T03:26:53.002Z',
                    updatedAt: '2023-07-22T03:27:47.313Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
            ],
            [
                {
                    _id: '64bb35d48090fe00193598be',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64b8c1b38090fe001934e1e8',
                    alias: '고건',
                    startTime: '2023-07-25T02:00:00.000Z',
                    endTime: '2023-07-25T02:30:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-22T01:50:12.483Z',
                    updatedAt: '2023-07-24T05:08:24.733Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64b9fa3f04a3c4002afb763a',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '64631cdb2c2eae00e52166c9',
                    alias: '신태준',
                    startTime: '2023-07-25T02:00:00.000Z',
                    endTime: '2023-07-25T02:50:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-21T03:23:43.449Z',
                    updatedAt: '2023-07-21T03:23:43.449Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64b8b29325fa4b001275a572',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '6489993aac2a3e00133c0345',
                    alias: '이정련',
                    startTime: '2023-07-25T02:00:00.000Z',
                    endTime: '2023-07-25T02:50:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-20T04:05:39.701Z',
                    updatedAt: '2023-07-20T04:36:12.642Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 5,
                    totalSession: 48,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
                {
                    _id: '64bdbeca8090fe0019365b0e',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64abb0a3d63356004b4718e1',
                    alias: '송지민',
                    startTime: '2023-07-25T02:30:00.000Z',
                    endTime: '2023-07-25T03:00:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-23T23:59:06.148Z',
                    updatedAt: '2023-07-24T06:36:54.324Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 30,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사6',
                },
                {
                    _id: '64bb8f5004a3c4002afbfa3f',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '642589331f90e500615759d0',
                    alias: '임혜진',
                    startTime: '2023-07-25T02:30:00.000Z',
                    endTime: '2023-07-25T03:20:00.000Z',
                    requestDeadline: '2023-07-24T03:00:00.000Z',
                    createdAt: '2023-07-22T08:12:00.948Z',
                    updatedAt: '2023-07-22T08:12:00.948Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64bd10ef04a3c4002afc67b5',
                    scheduleBundleId: null,
                    coachId: '6476bde354b8b500124e87c7',
                    memberId: '6476c390223a68002a58af71',
                    alias: '오군영',
                    startTime: '2023-07-25T03:00:00.000Z',
                    endTime: '2023-07-25T03:50:00.000Z',
                    requestDeadline: '2023-07-24T13:00:00.000Z',
                    createdAt: '2023-07-23T11:37:19.810Z',
                    updatedAt: '2023-07-23T11:37:19.810Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사1',
                },
                {
                    _id: '64bb8f5825fa4b0012769b6f',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '6426c1a57afb66006c608c1a',
                    alias: '유혜란',
                    startTime: '2023-07-25T03:30:00.000Z',
                    endTime: '2023-07-25T04:20:00.000Z',
                    requestDeadline: '2023-07-24T03:00:00.000Z',
                    createdAt: '2023-07-22T08:12:08.754Z',
                    updatedAt: '2023-07-22T08:12:08.754Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64b7a4318090fe00193483a4',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '6440d266f9990f0035432b22',
                    alias: '윤후남',
                    startTime: '2023-07-25T04:00:00.000Z',
                    endTime: '2023-07-25T04:50:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-19T08:52:01.583Z',
                    updatedAt: '2023-07-19T08:52:01.583Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64bb62f604a3c4002afbe591',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '648a9592f4edc000122e3d81',
                    alias: '이지현',
                    startTime: '2023-07-25T04:00:00.000Z',
                    endTime: '2023-07-25T04:50:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-22T05:02:46.369Z',
                    updatedAt: '2023-07-22T05:02:46.369Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
            ],
            [
                {
                    _id: '64bdecffd7d6a1001900e52b',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '644b7de26579b100616e4af9',
                    alias: '배창욱',
                    startTime: '2023-07-25T05:00:00.000Z',
                    endTime: '2023-07-25T05:30:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-24T03:16:15.819Z',
                    updatedAt: '2023-07-24T03:16:24.636Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64b0bf1991f76200120d072a',
                    scheduleBundleId: null,
                    coachId: '642596251f90e500615765dd',
                    memberId: '6459ee29bbf15000613c0296',
                    alias: '윤나형',
                    startTime: '2023-07-25T05:00:00.000Z',
                    endTime: '2023-07-25T05:50:00.000Z',
                    requestDeadline: '2023-07-24T12:00:00.000Z',
                    createdAt: '2023-07-14T03:20:57.457Z',
                    updatedAt: '2023-07-14T03:20:57.457Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사5',
                },
                {
                    _id: '64b9343f8090fe0019351f46',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '64b9315c8090fe0019351cd6',
                    alias: '동현진',
                    startTime: '2023-07-25T05:00:00.000Z',
                    endTime: '2023-07-25T05:50:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-20T13:18:55.106Z',
                    updatedAt: '2023-07-20T13:18:55.106Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64bb8f5e25fa4b0012769b88',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '64276036f6f7b0002a054bd2',
                    alias: '조연서',
                    startTime: '2023-07-25T05:00:00.000Z',
                    endTime: '2023-07-25T05:50:00.000Z',
                    requestDeadline: '2023-07-24T03:00:00.000Z',
                    createdAt: '2023-07-22T08:12:14.152Z',
                    updatedAt: '2023-07-22T08:12:14.152Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64b0c00891f76200120d07d9',
                    scheduleBundleId: null,
                    coachId: '642596251f90e500615765dd',
                    memberId: '6459ee27afd53c004b590512',
                    alias: '유서영',
                    startTime: '2023-07-25T06:00:00.000Z',
                    endTime: '2023-07-25T06:50:00.000Z',
                    requestDeadline: '2023-07-24T12:00:00.000Z',
                    createdAt: '2023-07-14T03:24:56.049Z',
                    updatedAt: '2023-07-14T03:24:56.049Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사5',
                },
                {
                    _id: '64b7a4458090fe00193483c7',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '6435399d79c01500a203a2af',
                    alias: '임영국',
                    startTime: '2023-07-25T06:00:00.000Z',
                    endTime: '2023-07-25T06:50:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-19T08:52:21.495Z',
                    updatedAt: '2023-07-19T08:52:21.495Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64bb8f77d7d6a100190046bc',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '6426c2e21f90e5006157c624',
                    alias: '김영은',
                    startTime: '2023-07-25T06:00:00.000Z',
                    endTime: '2023-07-25T06:50:00.000Z',
                    requestDeadline: '2023-07-24T03:00:00.000Z',
                    createdAt: '2023-07-22T08:12:39.331Z',
                    updatedAt: '2023-07-22T08:12:39.331Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64bb6619d7d6a1001900342e',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64a41d0b07309c00b9e9e249',
                    alias: '한지혜',
                    startTime: '2023-07-25T07:00:00.000Z',
                    endTime: '2023-07-25T07:30:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-22T05:16:09.252Z',
                    updatedAt: '2023-07-22T05:16:49.988Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 10,
                    totalSession: 26,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64b62c1325fa4b001274aacb',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '64815c50cec7d000770b8be7',
                    alias: '김미리',
                    startTime: '2023-07-25T07:00:00.000Z',
                    endTime: '2023-07-25T07:50:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-18T06:07:15.892Z',
                    updatedAt: '2023-07-18T06:07:15.892Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 11,
                    totalSession: 40,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
                {
                    _id: '64bb8f83d7d6a100190046d5',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '642950604ee5550077f5a181',
                    alias: '이영희',
                    startTime: '2023-07-25T07:00:00.000Z',
                    endTime: '2023-07-25T07:50:00.000Z',
                    requestDeadline: '2023-07-24T03:00:00.000Z',
                    createdAt: '2023-07-22T08:12:51.570Z',
                    updatedAt: '2023-07-22T08:12:51.570Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
                {
                    _id: '64bb33a18090fe0019359633',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '63d9dde6135c53002a328620',
                    alias: '박상호',
                    startTime: '2023-07-25T07:30:00.000Z',
                    endTime: '2023-07-25T08:00:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-22T01:40:49.984Z',
                    updatedAt: '2023-07-25T03:08:33.254Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 30,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사6',
                },
            ],
            [
                {
                    _id: '64b27c825a46c60011472052',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '642d2cbbda464a004bf7e574',
                    alias: '조시제',
                    startTime: '2023-07-25T08:00:00.000Z',
                    endTime: '2023-07-25T08:50:00.000Z',
                    requestDeadline: '2023-07-24T03:00:00.000Z',
                    createdAt: '2023-07-15T11:01:22.634Z',
                    updatedAt: '2023-07-24T12:50:52.991Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
                {
                    _id: '64be70498090fe001936bec9',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '6475fb83223a68002a588085',
                    alias: '이민주',
                    startTime: '2023-07-25T08:30:00.000Z',
                    endTime: '2023-07-25T09:00:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-24T12:36:25.475Z',
                    updatedAt: '2023-07-24T14:32:53.410Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 19,
                    totalSession: 24,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
            ],
            [
                {
                    _id: '64b6619ed7d6a10019fe7b45',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '6486ea21e19d730019bf2967',
                    alias: '미소',
                    startTime: '2023-07-25T09:00:00.000Z',
                    endTime: '2023-07-25T09:50:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-18T09:55:42.701Z',
                    updatedAt: '2023-07-18T09:55:42.701Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사7',
                },
                {
                    _id: '64bb4806d7d6a100190023f4',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64251517eb77ab004021bef0',
                    alias: '김중기',
                    startTime: '2023-07-25T09:30:00.000Z',
                    endTime: '2023-07-25T10:00:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-22T03:07:50.773Z',
                    updatedAt: '2023-07-22T03:08:03.502Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
            ],
            [
                {
                    _id: '64b91e5d04a3c4002afb458c',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '647b033b68ce160012b982a4',
                    alias: '서지완',
                    startTime: '2023-07-25T10:00:00.000Z',
                    endTime: '2023-07-25T10:50:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-20T11:45:33.125Z',
                    updatedAt: '2023-07-20T11:45:33.125Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64b62c2b7a2f110012ee9f8d',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '648986cbac2a3e00133bf57a',
                    alias: '양강순',
                    startTime: '2023-07-25T10:00:00.000Z',
                    endTime: '2023-07-25T10:50:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-18T06:07:39.123Z',
                    updatedAt: '2023-07-18T09:55:19.353Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 7,
                    totalSession: 21,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
                {
                    _id: '64be742825fa4b0012778de3',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '64bbd9f88090fe001935e1ab',
                    alias: '이상아',
                    startTime: '2023-07-25T10:00:00.000Z',
                    endTime: '2023-07-25T10:50:00.000Z',
                    requestDeadline: '2023-07-24T03:00:00.000Z',
                    createdAt: '2023-07-24T12:52:56.578Z',
                    updatedAt: '2023-07-24T12:52:56.578Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
                {
                    _id: '64bb338ed7d6a10019001412',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '6458050e12a69d0040524d04',
                    alias: '박운태',
                    startTime: '2023-07-25T10:30:00.000Z',
                    endTime: '2023-07-25T11:00:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-22T01:40:30.820Z',
                    updatedAt: '2023-07-22T01:42:07.372Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
            ],
            [
                {
                    _id: '64bcdcc525fa4b001276f7cc',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '63be9569375284002aa55f87',
                    alias: '허영진',
                    startTime: '2023-07-25T11:00:00.000Z',
                    endTime: '2023-07-25T11:30:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-23T07:54:45.572Z',
                    updatedAt: '2023-07-23T07:55:16.034Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64bb1af4d7d6a10019000a49',
                    scheduleBundleId: null,
                    coachId: '6476bde354b8b500124e87c7',
                    memberId: '6476e6af54b8b500124e9cf5',
                    alias: '이노아',
                    startTime: '2023-07-25T11:00:00.000Z',
                    endTime: '2023-07-25T11:50:00.000Z',
                    requestDeadline: '2023-07-24T13:00:00.000Z',
                    createdAt: '2023-07-21T23:55:32.056Z',
                    updatedAt: '2023-07-21T23:55:32.056Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사1',
                },
                {
                    _id: '64bd0dda25fa4b00127708de',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '6477b33b2a7db2001275cff1',
                    alias: '김가은',
                    startTime: '2023-07-25T11:00:00.000Z',
                    endTime: '2023-07-25T11:50:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-23T11:24:10.809Z',
                    updatedAt: '2023-07-24T15:53:22.851Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64b69b98d7d6a10019fe9b82',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '64842fe251f75800a313d0d8',
                    alias: '김수정',
                    startTime: '2023-07-25T11:00:00.000Z',
                    endTime: '2023-07-25T11:50:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-18T14:03:04.202Z',
                    updatedAt: '2023-07-18T14:03:04.202Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64b62c3e7a2f110012ee9fa0',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '649bb5e58d6af0004b656c8a',
                    alias: '손혜빈',
                    startTime: '2023-07-25T11:00:00.000Z',
                    endTime: '2023-07-25T11:50:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-18T06:07:58.464Z',
                    updatedAt: '2023-07-18T06:07:58.464Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 7,
                    totalSession: 20,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
                {
                    _id: '64b27c4a5a46c60011471fc6',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '64257c11647bac004b61ed71',
                    alias: '황월계',
                    startTime: '2023-07-25T11:00:00.000Z',
                    endTime: '2023-07-25T11:50:00.000Z',
                    requestDeadline: '2023-07-24T03:00:00.000Z',
                    createdAt: '2023-07-15T11:00:26.327Z',
                    updatedAt: '2023-07-15T11:00:26.327Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
                {
                    _id: '64bf406304a3c4002afd1ba9',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '644b7eed5345c3002b8dac91',
                    alias: '임수빈',
                    startTime: '2023-07-25T11:30:00.000Z',
                    endTime: '2023-07-25T12:20:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-25T03:24:19.127Z',
                    updatedAt: '2023-07-25T03:24:19.127Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사6',
                },
            ],
            [
                {
                    _id: '64b6835604a3c4002afa3daa',
                    scheduleBundleId: null,
                    coachId: '642596251f90e500615765dd',
                    memberId: '6427c0ddf6f7b0002a055b97',
                    alias: '엄지현',
                    startTime: '2023-07-25T12:00:00.000Z',
                    endTime: '2023-07-25T12:50:00.000Z',
                    requestDeadline: '2023-07-24T12:00:00.000Z',
                    createdAt: '2023-07-18T12:19:34.713Z',
                    updatedAt: '2023-07-18T12:19:34.713Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사5',
                },
                {
                    _id: '64ba70e925fa4b00127645e0',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '6479d17820036a002a3d94ce',
                    alias: '정유성',
                    startTime: '2023-07-25T12:00:00.000Z',
                    endTime: '2023-07-25T12:50:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-21T11:50:01.283Z',
                    updatedAt: '2023-07-21T11:50:01.283Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64b62c5825fa4b001274ab08',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '6481298ade345e008db8bee0',
                    alias: '임보라',
                    startTime: '2023-07-25T12:00:00.000Z',
                    endTime: '2023-07-25T12:50:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-18T06:08:24.767Z',
                    updatedAt: '2023-07-18T06:08:24.767Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 18,
                    totalSession: 20,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
                {
                    _id: '64bb8fbc04a3c4002afbfb7a',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '642901bd4ee5550077f58996',
                    alias: '안형진',
                    startTime: '2023-07-25T12:00:00.000Z',
                    endTime: '2023-07-25T12:50:00.000Z',
                    requestDeadline: '2023-07-24T03:00:00.000Z',
                    createdAt: '2023-07-22T08:13:48.102Z',
                    updatedAt: '2023-07-22T08:13:48.102Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64b48dc125fa4b001273fd70',
                    scheduleBundleId: null,
                    coachId: '642596251f90e500615765dd',
                    memberId: '64267ba11f90e5006157a9e7',
                    alias: '김유리',
                    startTime: '2023-07-25T13:00:00.000Z',
                    endTime: '2023-07-25T13:50:00.000Z',
                    requestDeadline: '2023-07-24T12:00:00.000Z',
                    createdAt: '2023-07-17T00:39:29.644Z',
                    updatedAt: '2023-07-17T00:39:29.644Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사5',
                },
                {
                    _id: '64b62c707a2f110012ee9ff1',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '64aa5dd6d63356004b469885',
                    alias: '배은희',
                    startTime: '2023-07-25T13:00:00.000Z',
                    endTime: '2023-07-25T13:50:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-18T06:08:48.809Z',
                    updatedAt: '2023-07-18T06:08:48.809Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 3,
                    totalSession: 30,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
            ],
            [
                {
                    _id: '64bbb6468090fe001935d5ce',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '64760d2a09b60d0019381b85',
                    alias: '김성민',
                    startTime: '2023-07-25T14:00:00.000Z',
                    endTime: '2023-07-25T14:50:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-22T10:58:14.656Z',
                    updatedAt: '2023-07-22T10:58:14.656Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64b7d2f0d7d6a10019ff1df6',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '64851b2cac000800b948e136',
                    alias: '이슬비',
                    startTime: '2023-07-25T14:00:00.000Z',
                    endTime: '2023-07-25T14:50:00.000Z',
                    requestDeadline: '2023-07-24T14:00:00.000Z',
                    createdAt: '2023-07-19T12:11:28.842Z',
                    updatedAt: '2023-07-19T12:11:28.842Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 5,
                    totalSession: 10,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
            ],
        ],
    },
    {
        daysOfWeek: '수',
        day: '26일',
        schedules: [
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [
                {
                    _id: '64bca56fd7d6a10019008f7c',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '64ab676dd63356004b46eaeb',
                    alias: '정다은',
                    startTime: '2023-07-26T00:00:00.000Z',
                    endTime: '2023-07-26T00:50:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-23T03:58:39.072Z',
                    updatedAt: '2023-07-24T11:57:33.736Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64baa3e88090fe001935839c',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '6489ab3eac2a3e00133c1046',
                    alias: '송진아',
                    startTime: '2023-07-26T00:10:00.000Z',
                    endTime: '2023-07-26T01:00:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-21T15:27:36.125Z',
                    updatedAt: '2023-07-25T23:51:01.257Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 12,
                    totalSession: 45,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
                {
                    _id: '64b291d654d0580018e82103',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '64263876f6f7b0002a04e791',
                    alias: '김민아',
                    startTime: '2023-07-26T00:30:00.000Z',
                    endTime: '2023-07-26T01:20:00.000Z',
                    requestDeadline: '2023-07-25T03:00:00.000Z',
                    createdAt: '2023-07-15T12:32:22.143Z',
                    updatedAt: '2023-07-15T12:32:22.143Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64bc9b6425fa4b001276de0d',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64acbd7e9d3824007791506d',
                    alias: '박지성',
                    startTime: '2023-07-26T01:00:00.000Z',
                    endTime: '2023-07-26T01:30:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-23T03:15:48.741Z',
                    updatedAt: '2023-07-23T03:17:51.445Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 30,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사6',
                },
                {
                    _id: '64b7438ed7d6a10019fec1a7',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '6498f08eebe0860035627931',
                    alias: '박종원',
                    startTime: '2023-07-26T01:00:00.000Z',
                    endTime: '2023-07-26T01:50:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-19T01:59:42.553Z',
                    updatedAt: '2023-07-23T04:09:52.053Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64bdecfc04a3c4002afc9ce7',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '644b7b1a7e771f0036913a79',
                    alias: '권민서',
                    startTime: '2023-07-26T01:30:00.000Z',
                    endTime: '2023-07-26T02:00:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-24T03:16:12.491Z',
                    updatedAt: '2023-07-24T03:16:47.581Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64bb8f4b04a3c4002afbfa1f',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '6426398b7afb66006c602451',
                    alias: '강미홍',
                    startTime: '2023-07-26T01:30:00.000Z',
                    endTime: '2023-07-26T02:20:00.000Z',
                    requestDeadline: '2023-07-25T03:00:00.000Z',
                    createdAt: '2023-07-22T08:11:55.582Z',
                    updatedAt: '2023-07-22T08:11:55.582Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64b0c08754d0580018e77d6d',
                    scheduleBundleId: null,
                    coachId: '642596251f90e500615765dd',
                    memberId: '64787eb2975144001275309a',
                    alias: '위나라',
                    startTime: '2023-07-26T02:00:00.000Z',
                    endTime: '2023-07-26T02:50:00.000Z',
                    requestDeadline: '2023-07-25T12:00:00.000Z',
                    createdAt: '2023-07-14T03:27:03.852Z',
                    updatedAt: '2023-07-14T03:27:03.852Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사5',
                },
                {
                    _id: '64b91d8a04a3c4002afb450d',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '64352bc579c01500a2039cb7',
                    alias: '정병준',
                    startTime: '2023-07-26T02:00:00.000Z',
                    endTime: '2023-07-26T02:50:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-20T11:42:02.635Z',
                    updatedAt: '2023-07-20T11:42:02.635Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64b9e47d25fa4b0012760a23',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '64b36ee654d0580018e854f6',
                    alias: '한명연',
                    startTime: '2023-07-26T02:00:00.000Z',
                    endTime: '2023-07-26T02:50:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-21T01:50:53.147Z',
                    updatedAt: '2023-07-21T01:50:53.147Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64bf6e178090fe0019371b4a',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '64814eab9200d6008238704a',
                    alias: '김현주',
                    startTime: '2023-07-26T02:00:00.000Z',
                    endTime: '2023-07-26T02:50:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-25T06:39:19.887Z',
                    updatedAt: '2023-07-25T06:39:19.887Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 41,
                    totalSession: 51,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
                {
                    _id: '64bb7689d7d6a10019003d7e',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64659cc06203c8010631c793',
                    alias: '이래원',
                    startTime: '2023-07-26T02:30:00.000Z',
                    endTime: '2023-07-26T03:00:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-22T06:26:17.469Z',
                    updatedAt: '2023-07-22T06:27:25.736Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 30,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사6',
                },
            ],
            [
                {
                    _id: '64bf17a325fa4b001277adbe',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64abb3cbd63356004b471ab7',
                    alias: '이상엽',
                    startTime: '2023-07-26T03:00:00.000Z',
                    endTime: '2023-07-26T03:30:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-25T00:30:27.899Z',
                    updatedAt: '2023-07-25T00:31:29.512Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 30,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사6',
                },
                {
                    _id: '64b62ca48090fe001933e469',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '6481265bcec7d000770b5e68',
                    alias: '이지연',
                    startTime: '2023-07-26T03:00:00.000Z',
                    endTime: '2023-07-26T03:50:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-18T06:09:40.590Z',
                    updatedAt: '2023-07-18T06:09:40.590Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 11,
                    totalSession: 20,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
            ],
            [
                {
                    _id: '64bc9ba9d7d6a100190088cc',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '649fb4201617bf006cfdc110',
                    alias: '강현아',
                    startTime: '2023-07-26T04:30:00.000Z',
                    endTime: '2023-07-26T05:00:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-23T03:16:57.237Z',
                    updatedAt: '2023-07-23T03:17:25.305Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64b62c017a2f110012ee9f47',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '64813cf4d59539004b94983f',
                    alias: '최주희',
                    startTime: '2023-07-26T04:30:00.000Z',
                    endTime: '2023-07-26T05:20:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-18T06:06:57.172Z',
                    updatedAt: '2023-07-25T06:01:21.063Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 37,
                    totalSession: 52,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
            ],
            [
                {
                    _id: '64bb37f325fa4b0012767258',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64ba19798090fe001935535c',
                    alias: '라유민',
                    startTime: '2023-07-26T05:30:00.000Z',
                    endTime: '2023-07-26T06:00:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-22T01:59:15.545Z',
                    updatedAt: '2023-07-22T02:00:24.964Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64b62cbb7a2f110012eea06f',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '649bab598d6af0004b6564fa',
                    alias: '유지연',
                    startTime: '2023-07-26T05:30:00.000Z',
                    endTime: '2023-07-26T06:20:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-18T06:10:03.119Z',
                    updatedAt: '2023-07-18T06:10:03.119Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 6,
                    totalSession: 10,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
            ],
            [
                {
                    _id: '64bb4c8ed7d6a100190028b1',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '646f0939c3cc0400195247e3',
                    alias: '조휘윤',
                    startTime: '2023-07-26T06:00:00.000Z',
                    endTime: '2023-07-26T06:30:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-22T03:27:10.347Z',
                    updatedAt: '2023-07-22T03:28:07.942Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64bded0204a3c4002afc9d01',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '644b7de26579b100616e4af9',
                    alias: '배창욱',
                    startTime: '2023-07-26T06:30:00.000Z',
                    endTime: '2023-07-26T07:00:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-24T03:16:18.471Z',
                    updatedAt: '2023-07-24T03:16:31.827Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
            ],
            [
                {
                    _id: '64bc9da904a3c4002afc3e7a',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '642e79e71215e700298616c1',
                    alias: '이동연',
                    startTime: '2023-07-26T07:00:00.000Z',
                    endTime: '2023-07-26T07:50:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-23T03:25:29.596Z',
                    updatedAt: '2023-07-23T03:25:29.596Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64c065838090fe00193783a2',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '644b78e95345c3002b8da926',
                    alias: '김혜영',
                    startTime: '2023-07-26T07:00:00.000Z',
                    endTime: '2023-07-26T07:50:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-26T00:14:59.320Z',
                    updatedAt: '2023-07-26T00:14:59.320Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 6,
                    totalSession: 20,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사6',
                },
                {
                    _id: '64bb8f8604a3c4002afbfabd',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '64ae5b835a46c6001145a99c',
                    alias: '박유리',
                    startTime: '2023-07-26T07:00:00.000Z',
                    endTime: '2023-07-26T07:50:00.000Z',
                    requestDeadline: '2023-07-25T03:00:00.000Z',
                    createdAt: '2023-07-22T08:12:54.045Z',
                    updatedAt: '2023-07-22T08:12:54.045Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64b3646e91f76200120dd652',
                    scheduleBundleId: null,
                    coachId: '642596251f90e500615765dd',
                    memberId: '64955d4e8c5fb8007755b1dd',
                    alias: '한정',
                    startTime: '2023-07-26T08:00:00.000Z',
                    endTime: '2023-07-26T08:50:00.000Z',
                    requestDeadline: '2023-07-25T12:00:00.000Z',
                    createdAt: '2023-07-16T03:30:54.459Z',
                    updatedAt: '2023-07-16T03:30:54.459Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사5',
                },
                {
                    _id: '64bd37798090fe0019364c8f',
                    scheduleBundleId: null,
                    coachId: '6476bde354b8b500124e87c7',
                    memberId: '647af8532b98070012a1a14a',
                    alias: '홍학심',
                    startTime: '2023-07-26T08:00:00.000Z',
                    endTime: '2023-07-26T08:50:00.000Z',
                    requestDeadline: '2023-07-25T13:00:00.000Z',
                    createdAt: '2023-07-23T14:21:45.960Z',
                    updatedAt: '2023-07-23T14:21:45.960Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사1',
                },
                {
                    _id: '64bd0d848090fe00193636b7',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '642fe8e1557b580012bd23f5',
                    alias: '최윤정',
                    startTime: '2023-07-26T08:00:00.000Z',
                    endTime: '2023-07-26T08:50:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-23T11:22:44.337Z',
                    updatedAt: '2023-07-23T11:22:44.337Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
            ],
            [
                {
                    _id: '64c08cacd7d6a1001901fd74',
                    scheduleBundleId: null,
                    coachId: '642596251f90e500615765dd',
                    memberId: '6426b4331f90e5006157bdd0',
                    alias: '허준서',
                    startTime: '2023-07-26T09:00:00.000Z',
                    endTime: '2023-07-26T09:50:00.000Z',
                    requestDeadline: '2023-07-25T12:00:00.000Z',
                    createdAt: '2023-07-26T03:02:04.179Z',
                    updatedAt: '2023-07-26T03:02:04.179Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사5',
                },
                {
                    _id: '64b91eb525fa4b001275deb9',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '6460ec3aa9d8c600828b1ad6',
                    alias: '박한별',
                    startTime: '2023-07-26T09:00:00.000Z',
                    endTime: '2023-07-26T09:50:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-20T11:47:01.954Z',
                    updatedAt: '2023-07-20T11:47:01.954Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64b62c848090fe001933e38e',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '6486ea21e19d730019bf2967',
                    alias: '미소',
                    startTime: '2023-07-26T09:00:00.000Z',
                    endTime: '2023-07-26T09:50:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-18T06:09:08.114Z',
                    updatedAt: '2023-07-18T06:09:08.114Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사7',
                },
                {
                    _id: '64b27c8b54d0580018e81866',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '642d2cbbda464a004bf7e574',
                    alias: '조시제',
                    startTime: '2023-07-26T09:00:00.000Z',
                    endTime: '2023-07-26T09:50:00.000Z',
                    requestDeadline: '2023-07-25T03:00:00.000Z',
                    createdAt: '2023-07-15T11:01:31.505Z',
                    updatedAt: '2023-07-15T11:01:31.505Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
                {
                    _id: '64bb33c2d7d6a100190015bf',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '63d6f9fd9b70e9001940cccc',
                    alias: '임주환',
                    startTime: '2023-07-26T09:30:00.000Z',
                    endTime: '2023-07-26T10:00:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-22T01:41:22.256Z',
                    updatedAt: '2023-07-22T01:41:30.574Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
            ],
            [
                {
                    _id: '64c0de4f04a3c4002afdd627',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '6490681fe3ddf4002b1b1c87',
                    alias: '김주리',
                    startTime: '2023-07-26T10:00:00.000Z',
                    endTime: '2023-07-26T10:30:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-26T08:50:23.320Z',
                    updatedAt: '2023-07-26T09:17:22.667Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 30,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사6',
                },
                {
                    _id: '64c0987dd7d6a10019020538',
                    scheduleBundleId: null,
                    coachId: '6476bde354b8b500124e87c7',
                    memberId: '64ae6f8f6afffb0019161350',
                    alias: '손현진',
                    startTime: '2023-07-26T10:00:00.000Z',
                    endTime: '2023-07-26T10:50:00.000Z',
                    requestDeadline: '2023-07-25T13:00:00.000Z',
                    createdAt: '2023-07-26T03:52:29.266Z',
                    updatedAt: '2023-07-26T03:52:29.266Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 4,
                    totalSession: 66,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사1',
                },
                {
                    _id: '64b91e7925fa4b001275de8c',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '6471ba470be48200122703c4',
                    alias: '김희찬',
                    startTime: '2023-07-26T10:00:00.000Z',
                    endTime: '2023-07-26T10:50:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-20T11:46:01.234Z',
                    updatedAt: '2023-07-20T11:46:01.234Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64b93b9804a3c4002afb547d',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '64939c3a6fb5ee003502fe69',
                    alias: '성현수',
                    startTime: '2023-07-26T10:00:00.000Z',
                    endTime: '2023-07-26T10:50:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-20T13:50:16.905Z',
                    updatedAt: '2023-07-20T13:50:16.905Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64bb8fa104a3c4002afbfb14',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '64292ab8f6f7b0002a05d035',
                    alias: '정서영',
                    startTime: '2023-07-26T10:00:00.000Z',
                    endTime: '2023-07-26T10:50:00.000Z',
                    requestDeadline: '2023-07-25T03:00:00.000Z',
                    createdAt: '2023-07-22T08:13:21.018Z',
                    updatedAt: '2023-07-22T08:13:21.018Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
                {
                    _id: '64b64cdb8090fe001933fa40',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '648305929200d60082394085',
                    alias: '조은영',
                    startTime: '2023-07-26T10:30:00.000Z',
                    endTime: '2023-07-26T11:20:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-18T08:27:07.653Z',
                    updatedAt: '2023-07-26T10:12:47.615Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 23,
                    totalSession: 40,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
            ],
            [
                {
                    _id: '64bdbefb25fa4b0012772c31',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64772eee44544d001920b746',
                    alias: '주현주',
                    startTime: '2023-07-26T11:00:00.000Z',
                    endTime: '2023-07-26T11:30:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-23T23:59:55.090Z',
                    updatedAt: '2023-07-24T06:37:30.341Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64b48dd525fa4b001273fd8d',
                    scheduleBundleId: null,
                    coachId: '642596251f90e500615765dd',
                    memberId: '64886a9936e0e700134e9af4',
                    alias: '최현욱',
                    startTime: '2023-07-26T11:00:00.000Z',
                    endTime: '2023-07-26T11:50:00.000Z',
                    requestDeadline: '2023-07-25T12:00:00.000Z',
                    createdAt: '2023-07-17T00:39:49.834Z',
                    updatedAt: '2023-07-17T00:39:49.834Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사5',
                },
                {
                    _id: '64c092e5d7d6a100190201c3',
                    scheduleBundleId: null,
                    coachId: '6476bde354b8b500124e87c7',
                    memberId: '64be73fd04a3c4002afcea66',
                    alias: '전재훈',
                    startTime: '2023-07-26T11:00:00.000Z',
                    endTime: '2023-07-26T11:50:00.000Z',
                    requestDeadline: '2023-07-25T13:00:00.000Z',
                    createdAt: '2023-07-26T03:28:37.306Z',
                    updatedAt: '2023-07-26T03:28:37.306Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 2,
                    totalSession: 20,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사1',
                },
                {
                    _id: '64b91e27d7d6a10019ff928b',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '642e94d0557b580012bc9357',
                    alias: '이은지',
                    startTime: '2023-07-26T11:00:00.000Z',
                    endTime: '2023-07-26T11:50:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-20T11:44:39.321Z',
                    updatedAt: '2023-07-20T11:44:39.321Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64b920f925fa4b001275e06c',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '641da5799336e00012831684',
                    alias: '이민애',
                    startTime: '2023-07-26T11:00:00.000Z',
                    endTime: '2023-07-26T11:50:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-20T11:56:41.213Z',
                    updatedAt: '2023-07-20T11:56:41.213Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64bb8fae04a3c4002afbfb47',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '6426bee87afb66006c608b54',
                    alias: '김혜문',
                    startTime: '2023-07-26T11:00:00.000Z',
                    endTime: '2023-07-26T11:50:00.000Z',
                    requestDeadline: '2023-07-25T03:00:00.000Z',
                    createdAt: '2023-07-22T08:13:34.308Z',
                    updatedAt: '2023-07-22T08:13:34.308Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64bb766f04a3c4002afbf0a5',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '644bb2195345c3002b8dbf7f',
                    alias: '이서영',
                    startTime: '2023-07-26T12:00:00.000Z',
                    endTime: '2023-07-26T12:30:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-22T06:25:51.414Z',
                    updatedAt: '2023-07-22T06:28:00.369Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64c0aa908090fe001937a72d',
                    scheduleBundleId: null,
                    coachId: '642596251f90e500615765dd',
                    memberId: '6426b1fb7afb66006c608275',
                    alias: '최재준',
                    startTime: '2023-07-26T12:00:00.000Z',
                    endTime: '2023-07-26T12:50:00.000Z',
                    requestDeadline: '2023-07-25T12:00:00.000Z',
                    createdAt: '2023-07-26T05:09:36.813Z',
                    updatedAt: '2023-07-26T05:09:36.813Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사5',
                },
                {
                    _id: '64bdbca68090fe001936592d',
                    scheduleBundleId: null,
                    coachId: '6476bde354b8b500124e87c7',
                    memberId: '6479d0c02b98070012a15c87',
                    alias: '장혜연',
                    startTime: '2023-07-26T12:00:00.000Z',
                    endTime: '2023-07-26T12:50:00.000Z',
                    requestDeadline: '2023-07-25T13:00:00.000Z',
                    createdAt: '2023-07-23T23:49:58.018Z',
                    updatedAt: '2023-07-23T23:49:58.018Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사1',
                },
                {
                    _id: '64b91dfa04a3c4002afb4545',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '64b157e491f76200120d446f',
                    alias: '김동민',
                    startTime: '2023-07-26T12:00:00.000Z',
                    endTime: '2023-07-26T12:50:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-20T11:43:54.703Z',
                    updatedAt: '2023-07-20T11:43:54.703Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64bb6fb28090fe001935b980',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '6408852e4be3fa00772add9b',
                    alias: '고근정',
                    startTime: '2023-07-26T12:00:00.000Z',
                    endTime: '2023-07-26T12:50:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-22T05:57:06.871Z',
                    updatedAt: '2023-07-22T05:57:06.871Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64b62c607a2f110012ee9fde',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '6481298ade345e008db8bee0',
                    alias: '임보라',
                    startTime: '2023-07-26T12:00:00.000Z',
                    endTime: '2023-07-26T12:50:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-18T06:08:32.105Z',
                    updatedAt: '2023-07-18T06:08:32.105Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 19,
                    totalSession: 20,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
                {
                    _id: '64bb8fb9d7d6a10019004789',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '6433e6f1b1f41c002aa95a37',
                    alias: '김소연',
                    startTime: '2023-07-26T12:00:00.000Z',
                    endTime: '2023-07-26T12:50:00.000Z',
                    requestDeadline: '2023-07-25T03:00:00.000Z',
                    createdAt: '2023-07-22T08:13:45.579Z',
                    updatedAt: '2023-07-22T08:13:45.579Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
                {
                    _id: '64c06e968090fe00193786cc',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '6470c1360be482001226d9e4',
                    alias: '김현창',
                    startTime: '2023-07-26T12:30:00.000Z',
                    endTime: '2023-07-26T13:00:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-26T00:53:42.535Z',
                    updatedAt: '2023-07-26T00:53:51.232Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 30,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사6',
                },
            ],
            [
                {
                    _id: '64ba1e28d7d6a10019ffd3af',
                    scheduleBundleId: null,
                    coachId: '642596251f90e500615765dd',
                    memberId: '649bdacd633bb30061ca9e13',
                    alias: '경은정',
                    startTime: '2023-07-26T13:00:00.000Z',
                    endTime: '2023-07-26T13:50:00.000Z',
                    requestDeadline: '2023-07-25T12:00:00.000Z',
                    createdAt: '2023-07-21T05:56:56.242Z',
                    updatedAt: '2023-07-21T05:56:56.242Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사5',
                },
                {
                    _id: '64bbb6368090fe001935d5c4',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '64323243b1f41c002aa895ab',
                    alias: '형대우',
                    startTime: '2023-07-26T13:00:00.000Z',
                    endTime: '2023-07-26T13:50:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-22T10:57:58.828Z',
                    updatedAt: '2023-07-22T10:57:58.828Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64b62c777a2f110012eea004',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '64812530d59539004b948b55',
                    alias: '김하연',
                    startTime: '2023-07-26T13:00:00.000Z',
                    endTime: '2023-07-26T13:50:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-18T06:08:55.515Z',
                    updatedAt: '2023-07-18T06:08:55.515Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 28,
                    totalSession: 70,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
                {
                    _id: '64bb8fc804a3c4002afbfbac',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '642906254ee5550077f58cdf',
                    alias: '정인길',
                    startTime: '2023-07-26T13:00:00.000Z',
                    endTime: '2023-07-26T13:50:00.000Z',
                    requestDeadline: '2023-07-25T03:00:00.000Z',
                    createdAt: '2023-07-22T08:14:00.719Z',
                    updatedAt: '2023-07-22T08:14:00.719Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64b91e198090fe0019351634',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '642e7b2bb1f41c002aa762cd',
                    alias: '남성현',
                    startTime: '2023-07-26T14:00:00.000Z',
                    endTime: '2023-07-26T14:50:00.000Z',
                    requestDeadline: '2023-07-25T14:00:00.000Z',
                    createdAt: '2023-07-20T11:44:25.652Z',
                    updatedAt: '2023-07-20T11:44:25.652Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64be73b38090fe001936bf8f',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '645a34de72e0ad0056c1c31a',
                    alias: '김유리',
                    startTime: '2023-07-26T14:00:00.000Z',
                    endTime: '2023-07-26T14:50:00.000Z',
                    requestDeadline: '2023-07-25T03:00:00.000Z',
                    createdAt: '2023-07-24T12:50:59.064Z',
                    updatedAt: '2023-07-24T12:50:59.064Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
        ],
    },
    {
        daysOfWeek: '목',
        day: '27일',
        schedules: [
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [
                {
                    _id: '64bb1b14d7d6a10019000a7b',
                    scheduleBundleId: null,
                    coachId: '6476bde354b8b500124e87c7',
                    memberId: '6476e61111a5690035e8a115',
                    alias: '이주연',
                    startTime: '2023-07-27T00:00:00.000Z',
                    endTime: '2023-07-27T00:50:00.000Z',
                    requestDeadline: '2023-07-26T13:00:00.000Z',
                    createdAt: '2023-07-21T23:56:04.137Z',
                    updatedAt: '2023-07-21T23:56:04.137Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사1',
                },
            ],
            [
                {
                    _id: '64bb380c8090fe0019359d03',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '644b81b57e771f0036913d89',
                    alias: '염경민',
                    startTime: '2023-07-27T01:00:00.000Z',
                    endTime: '2023-07-27T01:30:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-22T01:59:40.841Z',
                    updatedAt: '2023-07-22T02:00:41.720Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 4,
                    totalSession: 10,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64b62ce77a2f110012eea0b5',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '648986cbac2a3e00133bf57a',
                    alias: '양강순',
                    startTime: '2023-07-27T01:00:00.000Z',
                    endTime: '2023-07-27T01:50:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-18T06:10:47.551Z',
                    updatedAt: '2023-07-18T06:10:47.551Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 8,
                    totalSession: 21,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
            ],
            [
                {
                    _id: '64bb35d825fa4b0012766f0f',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64b8c1b38090fe001934e1e8',
                    alias: '고건',
                    startTime: '2023-07-27T02:00:00.000Z',
                    endTime: '2023-07-27T02:30:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-22T01:50:16.709Z',
                    updatedAt: '2023-07-22T01:50:36.789Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64bb1b06d7d6a10019000a62',
                    scheduleBundleId: null,
                    coachId: '6476bde354b8b500124e87c7',
                    memberId: '64b2354d6afffb00191759a7',
                    alias: '오용식',
                    startTime: '2023-07-27T02:00:00.000Z',
                    endTime: '2023-07-27T02:50:00.000Z',
                    requestDeadline: '2023-07-26T13:00:00.000Z',
                    createdAt: '2023-07-21T23:55:50.940Z',
                    updatedAt: '2023-07-21T23:55:50.940Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 1,
                    totalSession: 30,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사1',
                },
                {
                    _id: '64b9fa4d04a3c4002afb765c',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '64631cdb2c2eae00e52166c9',
                    alias: '신태준',
                    startTime: '2023-07-27T02:00:00.000Z',
                    endTime: '2023-07-27T02:50:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-21T03:23:57.806Z',
                    updatedAt: '2023-07-21T03:23:57.806Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64b62d0e25fa4b001274ac5e',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '6489734eb4e6370012dabc41',
                    alias: '김태정',
                    startTime: '2023-07-27T02:00:00.000Z',
                    endTime: '2023-07-27T02:50:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-18T06:11:26.044Z',
                    updatedAt: '2023-07-18T06:11:26.044Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 12,
                    totalSession: 46,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
                {
                    _id: '64bb38028090fe0019359ce7',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64832a6e51f75800a31384d7',
                    alias: '권현민',
                    startTime: '2023-07-27T02:30:00.000Z',
                    endTime: '2023-07-27T03:00:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-22T01:59:30.451Z',
                    updatedAt: '2023-07-22T01:59:57.904Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 12,
                    totalSession: 24,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64bb8f5325fa4b0012769b56',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '6426c1a57afb66006c608c1a',
                    alias: '유혜란',
                    startTime: '2023-07-27T02:30:00.000Z',
                    endTime: '2023-07-27T03:20:00.000Z',
                    requestDeadline: '2023-07-26T03:00:00.000Z',
                    createdAt: '2023-07-22T08:12:03.785Z',
                    updatedAt: '2023-07-22T08:12:03.785Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64bdbecdd7d6a1001900d2f3',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64abb0a3d63356004b4718e1',
                    alias: '송지민',
                    startTime: '2023-07-27T03:00:00.000Z',
                    endTime: '2023-07-27T03:30:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-23T23:59:09.187Z',
                    updatedAt: '2023-07-23T23:59:28.827Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 30,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사6',
                },
                {
                    _id: '64c1c3cf04a3c4002afe0d37',
                    scheduleBundleId: null,
                    coachId: '6476bde354b8b500124e87c7',
                    memberId: '64ae6f8f6afffb0019161350',
                    alias: '손현진',
                    startTime: '2023-07-27T03:00:00.000Z',
                    endTime: '2023-07-27T03:50:00.000Z',
                    requestDeadline: '2023-07-26T13:00:00.000Z',
                    createdAt: '2023-07-27T01:09:35.948Z',
                    updatedAt: '2023-07-27T01:16:43.771Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 5,
                    totalSession: 66,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사1',
                },
                {
                    _id: '64b64ff725fa4b001274c973',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '6489406fee860f001225f6b1',
                    alias: '김규연',
                    startTime: '2023-07-27T03:00:00.000Z',
                    endTime: '2023-07-27T03:50:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-18T08:40:23.833Z',
                    updatedAt: '2023-07-18T08:40:23.833Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 9,
                    totalSession: 34,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
                {
                    _id: '64bdef9325fa4b0012774348',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '63c6bd75375284002aa655db',
                    alias: '송호철',
                    startTime: '2023-07-27T03:30:00.000Z',
                    endTime: '2023-07-27T04:00:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-24T03:27:15.212Z',
                    updatedAt: '2023-07-24T06:36:26.458Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 30,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사6',
                },
                {
                    _id: '64bcf7bc25fa4b001276ff5c',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '6496677570795a0077c5bf0b',
                    alias: '박제니',
                    startTime: '2023-07-27T03:30:00.000Z',
                    endTime: '2023-07-27T04:20:00.000Z',
                    requestDeadline: '2023-07-26T03:00:00.000Z',
                    createdAt: '2023-07-23T09:49:48.833Z',
                    updatedAt: '2023-07-23T09:49:48.833Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64bf17b6d7d6a1001901500d',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '644b817b93cf190056b626b5',
                    alias: '한혜정',
                    startTime: '2023-07-27T04:00:00.000Z',
                    endTime: '2023-07-27T04:30:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-25T00:30:46.781Z',
                    updatedAt: '2023-07-25T00:32:16.250Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64b7a43b8090fe00193483b6',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '6440d266f9990f0035432b22',
                    alias: '윤후남',
                    startTime: '2023-07-27T04:00:00.000Z',
                    endTime: '2023-07-27T04:50:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-19T08:52:11.138Z',
                    updatedAt: '2023-07-19T08:52:11.138Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
            ],
            [
                {
                    _id: '64bc9db025fa4b001276e13e',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '642e64891215e70029860adc',
                    alias: '오상훈',
                    startTime: '2023-07-27T05:00:00.000Z',
                    endTime: '2023-07-27T05:50:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-23T03:25:36.304Z',
                    updatedAt: '2023-07-23T03:25:36.304Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64b62d1725fa4b001274ac75',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '64812a01de345e008db8bf51',
                    alias: '천현영',
                    startTime: '2023-07-27T05:00:00.000Z',
                    endTime: '2023-07-27T05:50:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-18T06:11:35.353Z',
                    updatedAt: '2023-07-18T06:11:35.353Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 27,
                    totalSession: 39,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
                {
                    _id: '64bb8f6004a3c4002afbfa8b',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '64276036f6f7b0002a054bd2',
                    alias: '조연서',
                    startTime: '2023-07-27T05:00:00.000Z',
                    endTime: '2023-07-27T05:50:00.000Z',
                    requestDeadline: '2023-07-26T03:00:00.000Z',
                    createdAt: '2023-07-22T08:12:16.619Z',
                    updatedAt: '2023-07-22T08:12:16.619Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64b7a45625fa4b0012755356',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '6435399d79c01500a203a2af',
                    alias: '임영국',
                    startTime: '2023-07-27T06:00:00.000Z',
                    endTime: '2023-07-27T06:50:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-19T08:52:38.531Z',
                    updatedAt: '2023-07-19T08:52:38.531Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64b62d2025fa4b001274ac88',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '64813cf4d59539004b94983f',
                    alias: '최주희',
                    startTime: '2023-07-27T06:00:00.000Z',
                    endTime: '2023-07-27T06:50:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-18T06:11:44.197Z',
                    updatedAt: '2023-07-18T06:11:44.197Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 38,
                    totalSession: 52,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
            ],
            [
                {
                    _id: '64b0c0155a46c60011468417',
                    scheduleBundleId: null,
                    coachId: '642596251f90e500615765dd',
                    memberId: '6459ee27afd53c004b590512',
                    alias: '유서영',
                    startTime: '2023-07-27T07:00:00.000Z',
                    endTime: '2023-07-27T07:50:00.000Z',
                    requestDeadline: '2023-07-26T12:00:00.000Z',
                    createdAt: '2023-07-14T03:25:09.205Z',
                    updatedAt: '2023-07-26T10:52:13.873Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사5',
                },
                {
                    _id: '64bb1afe25fa4b001276646a',
                    scheduleBundleId: null,
                    coachId: '6476bde354b8b500124e87c7',
                    memberId: '6476f40be0d37a004b81358d',
                    alias: '이기연',
                    startTime: '2023-07-27T07:00:00.000Z',
                    endTime: '2023-07-27T07:50:00.000Z',
                    requestDeadline: '2023-07-26T13:00:00.000Z',
                    createdAt: '2023-07-21T23:55:42.066Z',
                    updatedAt: '2023-07-21T23:55:42.066Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사1',
                },
                {
                    _id: '64b62c1c25fa4b001274aae3',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '64815c50cec7d000770b8be7',
                    alias: '김미리',
                    startTime: '2023-07-27T07:00:00.000Z',
                    endTime: '2023-07-27T07:50:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-18T06:07:24.436Z',
                    updatedAt: '2023-07-18T06:07:24.436Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 12,
                    totalSession: 40,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
            ],
            [
                {
                    _id: '64bb766b25fa4b0012768f23',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '644b7eed5345c3002b8dac91',
                    alias: '임수빈',
                    startTime: '2023-07-27T08:30:00.000Z',
                    endTime: '2023-07-27T09:20:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-22T06:25:47.894Z',
                    updatedAt: '2023-07-22T06:25:47.894Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사6',
                },
            ],
            [
                {
                    _id: '64b921ae04a3c4002afb4906',
                    scheduleBundleId: null,
                    coachId: '642596251f90e500615765dd',
                    memberId: '64b906b18090fe0019350b17',
                    alias: '고문설',
                    startTime: '2023-07-27T09:00:00.000Z',
                    endTime: '2023-07-27T09:50:00.000Z',
                    requestDeadline: '2023-07-26T12:00:00.000Z',
                    createdAt: '2023-07-20T11:59:42.912Z',
                    updatedAt: '2023-07-20T11:59:42.912Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사5',
                },
                {
                    _id: '64bb480b04a3c4002afbd852',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64251517eb77ab004021bef0',
                    alias: '김중기',
                    startTime: '2023-07-27T09:30:00.000Z',
                    endTime: '2023-07-27T10:00:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-22T03:07:55.139Z',
                    updatedAt: '2023-07-22T03:08:13.672Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64b62d317a2f110012eea0e1',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '649bc3a8747f100056d15238',
                    alias: '김민조',
                    startTime: '2023-07-27T09:30:00.000Z',
                    endTime: '2023-07-27T10:20:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-18T06:12:01.331Z',
                    updatedAt: '2023-07-27T08:10:49.695Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 21,
                    totalSession: 40,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
            ],
            [
                {
                    _id: '64ba641ad7d6a10019ffef0b',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '6439346c83f109004bcebf45',
                    alias: '정가현',
                    startTime: '2023-07-27T10:00:00.000Z',
                    endTime: '2023-07-27T10:50:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-21T10:55:22.036Z',
                    updatedAt: '2023-07-21T10:55:22.036Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64bb8fa3d7d6a1001900473e',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '64256b50eb77ab004021e9f5',
                    alias: '이재진',
                    startTime: '2023-07-27T10:00:00.000Z',
                    endTime: '2023-07-27T10:50:00.000Z',
                    requestDeadline: '2023-07-26T03:00:00.000Z',
                    createdAt: '2023-07-22T08:13:23.562Z',
                    updatedAt: '2023-07-22T08:13:23.562Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
                {
                    _id: '64bb339e8090fe0019359619',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '6458050e12a69d0040524d04',
                    alias: '박운태',
                    startTime: '2023-07-27T10:30:00.000Z',
                    endTime: '2023-07-27T11:00:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-22T01:40:46.163Z',
                    updatedAt: '2023-07-22T01:42:15.623Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
            ],
            [
                {
                    _id: '64be704625fa4b0012778c56',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '6475fb83223a68002a588085',
                    alias: '이민주',
                    startTime: '2023-07-27T11:00:00.000Z',
                    endTime: '2023-07-27T11:30:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-24T12:36:22.062Z',
                    updatedAt: '2023-07-24T12:36:35.057Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 20,
                    totalSession: 24,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64bd10f425fa4b0012770a4a',
                    scheduleBundleId: null,
                    coachId: '6476bde354b8b500124e87c7',
                    memberId: '6485a69480b84800aedef8ee',
                    alias: '이종혁',
                    startTime: '2023-07-27T11:00:00.000Z',
                    endTime: '2023-07-27T11:50:00.000Z',
                    requestDeadline: '2023-07-26T13:00:00.000Z',
                    createdAt: '2023-07-23T11:37:24.331Z',
                    updatedAt: '2023-07-24T02:12:21.736Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사1',
                },
                {
                    _id: '64b69b9cd7d6a10019fe9b8c',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '64842fe251f75800a313d0d8',
                    alias: '김수정',
                    startTime: '2023-07-27T11:00:00.000Z',
                    endTime: '2023-07-27T11:50:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-18T14:03:08.581Z',
                    updatedAt: '2023-07-18T14:03:08.581Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64b62d447a2f110012eea119',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '64815f41cec7d000770b8e78',
                    alias: '김민정',
                    startTime: '2023-07-27T11:00:00.000Z',
                    endTime: '2023-07-27T11:50:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-18T06:12:20.038Z',
                    updatedAt: '2023-07-18T06:12:20.038Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 33,
                    totalSession: 52,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
                {
                    _id: '64b27c6554d0580018e81849',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '64257c11647bac004b61ed71',
                    alias: '황월계',
                    startTime: '2023-07-27T11:00:00.000Z',
                    endTime: '2023-07-27T11:50:00.000Z',
                    requestDeadline: '2023-07-26T03:00:00.000Z',
                    createdAt: '2023-07-15T11:00:53.578Z',
                    updatedAt: '2023-07-15T11:00:53.578Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64bb33a5d7d6a1001900148a',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '63d9dde6135c53002a328620',
                    alias: '박상호',
                    startTime: '2023-07-27T12:00:00.000Z',
                    endTime: '2023-07-27T12:30:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-22T01:40:53.601Z',
                    updatedAt: '2023-07-22T01:42:24.289Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 30,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사6',
                },
                {
                    _id: '64b3649554d0580018e85068',
                    scheduleBundleId: null,
                    coachId: '642596251f90e500615765dd',
                    memberId: '6426d921951ca00056496c5f',
                    alias: '이혜진',
                    startTime: '2023-07-27T12:00:00.000Z',
                    endTime: '2023-07-27T12:50:00.000Z',
                    requestDeadline: '2023-07-26T12:00:00.000Z',
                    createdAt: '2023-07-16T03:31:33.566Z',
                    updatedAt: '2023-07-16T03:31:33.566Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사5',
                },
                {
                    _id: '64ba70fb04a3c4002afba575',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '6479d17820036a002a3d94ce',
                    alias: '정유성',
                    startTime: '2023-07-27T12:00:00.000Z',
                    endTime: '2023-07-27T12:50:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-21T11:50:19.535Z',
                    updatedAt: '2023-07-21T11:50:19.535Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64b9f95204a3c4002afb7601',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '6481274bd59539004b948c3d',
                    alias: '윤바쁨',
                    startTime: '2023-07-27T12:00:00.000Z',
                    endTime: '2023-07-27T12:50:00.000Z',
                    requestDeadline: '2023-07-26T14:00:00.000Z',
                    createdAt: '2023-07-21T03:19:46.236Z',
                    updatedAt: '2023-07-22T13:05:42.348Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 6,
                    totalSession: 12,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
                {
                    _id: '64390799574b7700355fe47e',
                    scheduleBundleId: '5f8c0128-032a-4f05-86a4-0fc088428283',
                    startTime: '2023-07-27T12:00:00.000Z',
                    endTime: '2023-07-27T13:00:00.000Z',
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '64257cc0f6f7b0002a04bd2a',
                    alias: '김재영',
                    requestDeadline: '2023-07-26T03:00:00.000Z',
                    __v: 0,
                    createdAt: '2023-04-14T07:58:17.503Z',
                    updatedAt: '2023-04-14T07:58:17.503Z',
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 60,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64be78948090fe001936c44e',
                    scheduleBundleId: null,
                    coachId: '642596251f90e500615765dd',
                    memberId: '64be0b5904a3c4002afcb80d',
                    alias: '이승희',
                    startTime: '2023-07-27T13:00:00.000Z',
                    endTime: '2023-07-27T13:50:00.000Z',
                    requestDeadline: '2023-07-26T12:00:00.000Z',
                    createdAt: '2023-07-24T13:11:48.140Z',
                    updatedAt: '2023-07-24T13:11:48.140Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사5',
                },
                {
                    _id: '64bb8fccd7d6a100190047d5',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '6425a21e647bac004b620165',
                    alias: '이승희',
                    startTime: '2023-07-27T13:00:00.000Z',
                    endTime: '2023-07-27T13:50:00.000Z',
                    requestDeadline: '2023-07-26T03:00:00.000Z',
                    createdAt: '2023-07-22T08:14:04.481Z',
                    updatedAt: '2023-07-22T08:14:04.481Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [],
        ],
    },
    {
        daysOfWeek: '금',
        day: '28일',
        schedules: [
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [
                {
                    _id: '64b0805f5a46c6001146688c',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '649b6d078d6af0004b6544b0',
                    alias: '심미선',
                    startTime: '2023-07-27T22:00:00.000Z',
                    endTime: '2023-07-27T22:50:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-13T22:53:19.731Z',
                    updatedAt: '2023-07-13T22:53:19.731Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
            ],
            [
                {
                    _id: '64b3993654d0580018e862c8',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '64a36d902d210500a311ec57',
                    alias: '정동범',
                    startTime: '2023-07-27T23:00:00.000Z',
                    endTime: '2023-07-27T23:50:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-16T07:16:06.447Z',
                    updatedAt: '2023-07-16T07:16:06.447Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
            ],
            [
                {
                    _id: '64bc9b5a8090fe001936093f',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64785e7040a710002a72836d',
                    alias: '황성숙',
                    startTime: '2023-07-28T00:00:00.000Z',
                    endTime: '2023-07-28T00:30:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-23T03:15:38.840Z',
                    updatedAt: '2023-07-23T03:16:34.358Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 12,
                    totalSession: 24,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64bb1b1704a3c4002afbc192',
                    scheduleBundleId: null,
                    coachId: '6476bde354b8b500124e87c7',
                    memberId: '647b0cb5cd47a200191db34a',
                    alias: '이화진',
                    startTime: '2023-07-28T00:00:00.000Z',
                    endTime: '2023-07-28T00:50:00.000Z',
                    requestDeadline: '2023-07-27T13:00:00.000Z',
                    createdAt: '2023-07-21T23:56:07.608Z',
                    updatedAt: '2023-07-21T23:56:07.608Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 5,
                    totalSession: 10,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사1',
                },
                {
                    _id: '64c07c1404a3c4002afda441',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '6498f08eebe0860035627931',
                    alias: '박종원',
                    startTime: '2023-07-28T00:00:00.000Z',
                    endTime: '2023-07-28T00:50:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-26T01:51:16.197Z',
                    updatedAt: '2023-07-26T01:51:16.197Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64c0783a25fa4b0012784909',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '64263876f6f7b0002a04e791',
                    alias: '김민아',
                    startTime: '2023-07-28T00:30:00.000Z',
                    endTime: '2023-07-28T01:20:00.000Z',
                    requestDeadline: '2023-07-27T03:00:00.000Z',
                    createdAt: '2023-07-26T01:34:50.432Z',
                    updatedAt: '2023-07-26T01:34:50.432Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64bb381025fa4b00127672ca',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '644b81b57e771f0036913d89',
                    alias: '염경민',
                    startTime: '2023-07-28T01:00:00.000Z',
                    endTime: '2023-07-28T01:30:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-22T01:59:44.734Z',
                    updatedAt: '2023-07-22T02:00:56.718Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 5,
                    totalSession: 10,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64bd0d8bd7d6a1001900b0c5',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '642fe8e1557b580012bd23f5',
                    alias: '최윤정',
                    startTime: '2023-07-28T01:00:00.000Z',
                    endTime: '2023-07-28T01:50:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-23T11:22:51.170Z',
                    updatedAt: '2023-07-27T10:56:47.394Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64ba642825fa4b00127641d7',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '6439346c83f109004bcebf45',
                    alias: '정가현',
                    startTime: '2023-07-28T01:00:00.000Z',
                    endTime: '2023-07-28T01:50:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-21T10:55:36.164Z',
                    updatedAt: '2023-07-27T09:27:43.155Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64b62d6ed7d6a10019fe5620',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '64812853d59539004b948cf0',
                    alias: '오혜수',
                    startTime: '2023-07-28T01:00:00.000Z',
                    endTime: '2023-07-28T01:50:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-18T06:13:02.538Z',
                    updatedAt: '2023-07-18T06:13:02.538Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 38,
                    totalSession: 70,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
                {
                    _id: '64bc9b688090fe0019360971',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64acbd7e9d3824007791506d',
                    alias: '박지성',
                    startTime: '2023-07-28T01:30:00.000Z',
                    endTime: '2023-07-28T02:00:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-23T03:15:52.085Z',
                    updatedAt: '2023-07-23T03:18:00.686Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 30,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사6',
                },
                {
                    _id: '64bb8f4ed7d6a100190046a8',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '6426c1a57afb66006c608c1a',
                    alias: '유혜란',
                    startTime: '2023-07-28T01:30:00.000Z',
                    endTime: '2023-07-28T02:20:00.000Z',
                    requestDeadline: '2023-07-27T03:00:00.000Z',
                    createdAt: '2023-07-22T08:11:58.323Z',
                    updatedAt: '2023-07-22T08:11:58.323Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64bb1b0a04a3c4002afbc179',
                    scheduleBundleId: null,
                    coachId: '6476bde354b8b500124e87c7',
                    memberId: '6477df2544544d001920dd22',
                    alias: '이지연',
                    startTime: '2023-07-28T02:00:00.000Z',
                    endTime: '2023-07-28T02:50:00.000Z',
                    requestDeadline: '2023-07-27T13:00:00.000Z',
                    createdAt: '2023-07-21T23:55:54.052Z',
                    updatedAt: '2023-07-21T23:55:54.052Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사1',
                },
                {
                    _id: '64b91d8fd7d6a10019ff923e',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '64352bc579c01500a2039cb7',
                    alias: '정병준',
                    startTime: '2023-07-28T02:00:00.000Z',
                    endTime: '2023-07-28T02:50:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-20T11:42:07.750Z',
                    updatedAt: '2023-07-20T11:42:07.750Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64b9e48804a3c4002afb6d7a',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '64b36ee654d0580018e854f6',
                    alias: '한명연',
                    startTime: '2023-07-28T02:00:00.000Z',
                    endTime: '2023-07-28T02:50:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-21T01:51:04.178Z',
                    updatedAt: '2023-07-21T01:51:04.178Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64b62d7b8090fe001933e5dc',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '648169329200d600823891ca',
                    alias: '위지우',
                    startTime: '2023-07-28T02:00:00.000Z',
                    endTime: '2023-07-28T02:50:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-18T06:13:15.534Z',
                    updatedAt: '2023-07-18T06:13:15.534Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 11,
                    totalSession: 20,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
                {
                    _id: '64bb8f5604a3c4002afbfa59',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '6426398b7afb66006c602451',
                    alias: '강미홍',
                    startTime: '2023-07-28T02:30:00.000Z',
                    endTime: '2023-07-28T03:20:00.000Z',
                    requestDeadline: '2023-07-27T03:00:00.000Z',
                    createdAt: '2023-07-22T08:12:06.038Z',
                    updatedAt: '2023-07-22T08:12:06.038Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64bf17ba04a3c4002afd096d',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64abb3cbd63356004b471ab7',
                    alias: '이상엽',
                    startTime: '2023-07-28T03:00:00.000Z',
                    endTime: '2023-07-28T03:30:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-25T00:30:50.945Z',
                    updatedAt: '2023-07-25T00:32:07.363Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 30,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사6',
                },
                {
                    _id: '64b62d86d7d6a10019fe5641',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '6489993aac2a3e00133c0345',
                    alias: '이정련',
                    startTime: '2023-07-28T03:00:00.000Z',
                    endTime: '2023-07-28T03:50:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-18T06:13:26.537Z',
                    updatedAt: '2023-07-18T06:13:26.537Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 6,
                    totalSession: 48,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
            ],
            [
                {
                    _id: '64bb4c818090fe001935a966',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '646f0939c3cc0400195247e3',
                    alias: '조휘윤',
                    startTime: '2023-07-28T04:00:00.000Z',
                    endTime: '2023-07-28T04:30:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-22T03:26:57.526Z',
                    updatedAt: '2023-07-22T03:27:28.904Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64bb8f5b04a3c4002afbfa72',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '642589331f90e500615759d0',
                    alias: '임혜진',
                    startTime: '2023-07-28T04:00:00.000Z',
                    endTime: '2023-07-28T04:50:00.000Z',
                    requestDeadline: '2023-07-27T03:00:00.000Z',
                    createdAt: '2023-07-22T08:12:11.708Z',
                    updatedAt: '2023-07-22T08:12:11.708Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
                {
                    _id: '64bc9bac04a3c4002afc3c12',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '649fb4201617bf006cfdc110',
                    alias: '강현아',
                    startTime: '2023-07-28T04:30:00.000Z',
                    endTime: '2023-07-28T05:00:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-23T03:17:00.107Z',
                    updatedAt: '2023-07-23T03:18:09.040Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
            ],
            [
                {
                    _id: '64bf17b204a3c4002afd0951',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '644b817b93cf190056b626b5',
                    alias: '한혜정',
                    startTime: '2023-07-28T05:00:00.000Z',
                    endTime: '2023-07-28T05:30:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-25T00:30:42.486Z',
                    updatedAt: '2023-07-25T00:31:38.799Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64bd0dd58090fe001936373e',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '648804bae19d730019bf9259',
                    alias: '유제현',
                    startTime: '2023-07-28T05:00:00.000Z',
                    endTime: '2023-07-28T05:50:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-23T11:24:05.328Z',
                    updatedAt: '2023-07-23T11:24:05.328Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64bb8f6325fa4b0012769ba1',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '6426cd4af6f7b0002a05374b',
                    alias: '최승혁',
                    startTime: '2023-07-28T05:00:00.000Z',
                    endTime: '2023-07-28T05:50:00.000Z',
                    requestDeadline: '2023-07-27T03:00:00.000Z',
                    createdAt: '2023-07-22T08:12:19.305Z',
                    updatedAt: '2023-07-22T08:12:19.305Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64c20179cd982f003582a84c',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '6490681fe3ddf4002b1b1c87',
                    alias: '김주리',
                    startTime: '2023-07-28T06:00:00.000Z',
                    endTime: '2023-07-28T06:30:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-27T05:32:41.098Z',
                    updatedAt: '2023-07-27T11:49:28.519Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 30,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사6',
                },
                {
                    _id: '64b0c01c54d0580018e77c96',
                    scheduleBundleId: null,
                    coachId: '642596251f90e500615765dd',
                    memberId: '6459ee29bbf15000613c0296',
                    alias: '윤나형',
                    startTime: '2023-07-28T06:00:00.000Z',
                    endTime: '2023-07-28T06:50:00.000Z',
                    requestDeadline: '2023-07-27T12:00:00.000Z',
                    createdAt: '2023-07-14T03:25:16.888Z',
                    updatedAt: '2023-07-26T09:46:56.688Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사5',
                },
                {
                    _id: '64bb1b018090fe0019358de5',
                    scheduleBundleId: null,
                    coachId: '6476bde354b8b500124e87c7',
                    memberId: '64ae5c3454d0580018e69f5f',
                    alias: '박정아',
                    startTime: '2023-07-28T06:00:00.000Z',
                    endTime: '2023-07-28T06:50:00.000Z',
                    requestDeadline: '2023-07-27T13:00:00.000Z',
                    createdAt: '2023-07-21T23:55:45.400Z',
                    updatedAt: '2023-07-26T08:59:37.953Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 4,
                    totalSession: 11,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사1',
                },
            ],
            [
                {
                    _id: '64b0bf0a91f76200120d0681',
                    scheduleBundleId: null,
                    coachId: '642596251f90e500615765dd',
                    memberId: '644e147aa3aed70041f0b912',
                    alias: '김용철',
                    startTime: '2023-07-28T07:00:00.000Z',
                    endTime: '2023-07-28T07:50:00.000Z',
                    requestDeadline: '2023-07-27T12:00:00.000Z',
                    createdAt: '2023-07-14T03:20:42.862Z',
                    updatedAt: '2023-07-14T03:20:42.862Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사5',
                },
                {
                    _id: '64bc9da525fa4b001276e116',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '642e79e71215e700298616c1',
                    alias: '이동연',
                    startTime: '2023-07-28T07:00:00.000Z',
                    endTime: '2023-07-28T07:50:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-23T03:25:25.573Z',
                    updatedAt: '2023-07-23T03:25:25.573Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64b63b0925fa4b001274b3c1',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '6489406fee860f001225f6b1',
                    alias: '김규연',
                    startTime: '2023-07-28T07:00:00.000Z',
                    endTime: '2023-07-28T07:50:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-18T07:11:05.298Z',
                    updatedAt: '2023-07-18T07:11:05.298Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 10,
                    totalSession: 34,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
                {
                    _id: '64bb8f88d7d6a100190046ee',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '64ae5b835a46c6001145a99c',
                    alias: '박유리',
                    startTime: '2023-07-28T07:00:00.000Z',
                    endTime: '2023-07-28T07:50:00.000Z',
                    requestDeadline: '2023-07-27T03:00:00.000Z',
                    createdAt: '2023-07-22T08:12:56.636Z',
                    updatedAt: '2023-07-22T08:12:56.636Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64bb380625fa4b00127672b0',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64832a6e51f75800a31384d7',
                    alias: '권현민',
                    startTime: '2023-07-28T08:00:00.000Z',
                    endTime: '2023-07-28T08:30:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-22T01:59:34.335Z',
                    updatedAt: '2023-07-22T03:08:55.963Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 13,
                    totalSession: 24,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64b3647891f76200120dd676',
                    scheduleBundleId: null,
                    coachId: '642596251f90e500615765dd',
                    memberId: '64955d4e8c5fb8007755b1dd',
                    alias: '한정',
                    startTime: '2023-07-28T08:00:00.000Z',
                    endTime: '2023-07-28T08:50:00.000Z',
                    requestDeadline: '2023-07-27T12:00:00.000Z',
                    createdAt: '2023-07-16T03:31:04.301Z',
                    updatedAt: '2023-07-16T03:31:04.301Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사5',
                },
                {
                    _id: '64be9e7dd7d6a10019013ff6',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '642e95dd1215e70029862aa7',
                    alias: '이아현',
                    startTime: '2023-07-28T08:00:00.000Z',
                    endTime: '2023-07-28T08:50:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-24T15:53:33.506Z',
                    updatedAt: '2023-07-24T15:53:33.506Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64b0ab645a46c600114676ea',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '646b2357e35dfd011cbb6474',
                    alias: '유경진',
                    startTime: '2023-07-28T08:00:00.000Z',
                    endTime: '2023-07-28T08:50:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-14T01:56:52.062Z',
                    updatedAt: '2023-07-27T14:43:57.581Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64bb8f90d7d6a10019004707',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '642950604ee5550077f5a181',
                    alias: '이영희',
                    startTime: '2023-07-28T08:00:00.000Z',
                    endTime: '2023-07-28T08:50:00.000Z',
                    requestDeadline: '2023-07-27T03:00:00.000Z',
                    createdAt: '2023-07-22T08:13:04.982Z',
                    updatedAt: '2023-07-22T08:13:04.982Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64bcdcc88090fe0019362200',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '6465a121c2b88900ae7041a9',
                    alias: '박은영',
                    startTime: '2023-07-28T09:00:00.000Z',
                    endTime: '2023-07-28T09:30:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-23T07:54:48.942Z',
                    updatedAt: '2023-07-23T07:56:01.082Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 11,
                    totalSession: 24,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
            ],
            [
                {
                    _id: '64bba5ca25fa4b001276a1b9',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '644bb2195345c3002b8dbf7f',
                    alias: '이서영',
                    startTime: '2023-07-28T10:00:00.000Z',
                    endTime: '2023-07-28T10:30:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-22T09:47:54.046Z',
                    updatedAt: '2023-07-22T09:48:11.825Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 1,
                    totalSession: 12,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64c3564204a3c4002afe93d7',
                    scheduleBundleId: null,
                    coachId: '642596251f90e500615765dd',
                    memberId: '64886a9936e0e700134e9af4',
                    alias: '최현욱',
                    startTime: '2023-07-28T10:00:00.000Z',
                    endTime: '2023-07-28T10:50:00.000Z',
                    requestDeadline: '2023-07-27T12:00:00.000Z',
                    createdAt: '2023-07-28T05:46:42.960Z',
                    updatedAt: '2023-07-28T05:46:42.960Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사5',
                },
                {
                    _id: '64b91e6625fa4b001275de7b',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '647b033b68ce160012b982a4',
                    alias: '서지완',
                    startTime: '2023-07-28T10:00:00.000Z',
                    endTime: '2023-07-28T10:50:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-20T11:45:42.633Z',
                    updatedAt: '2023-07-20T11:45:42.633Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64bb8fa704a3c4002afbfb2e',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '642901bd4ee5550077f58996',
                    alias: '안형진',
                    startTime: '2023-07-28T10:00:00.000Z',
                    endTime: '2023-07-28T10:50:00.000Z',
                    requestDeadline: '2023-07-27T03:00:00.000Z',
                    createdAt: '2023-07-22T08:13:27.281Z',
                    updatedAt: '2023-07-22T08:13:27.281Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64c367c8cd982f00358320d7',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64a41d0b07309c00b9e9e249',
                    alias: '한지혜',
                    startTime: '2023-07-28T11:00:00.000Z',
                    endTime: '2023-07-28T11:30:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-28T07:01:28.282Z',
                    updatedAt: '2023-07-28T07:01:28.282Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 11,
                    totalSession: 26,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64b921b1d7d6a10019ff951d',
                    scheduleBundleId: null,
                    coachId: '642596251f90e500615765dd',
                    memberId: '64b906b18090fe0019350b17',
                    alias: '고문설',
                    startTime: '2023-07-28T11:00:00.000Z',
                    endTime: '2023-07-28T11:50:00.000Z',
                    requestDeadline: '2023-07-27T12:00:00.000Z',
                    createdAt: '2023-07-20T11:59:45.573Z',
                    updatedAt: '2023-07-20T11:59:45.573Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사5',
                },
                {
                    _id: '64b91e4304a3c4002afb457e',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '64426af227d69b0019b7fcf9',
                    alias: '황건우',
                    startTime: '2023-07-28T11:00:00.000Z',
                    endTime: '2023-07-28T11:50:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-20T11:45:07.999Z',
                    updatedAt: '2023-07-20T11:45:07.999Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64bf2de68090fe001936eb43',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '642904477afb66006c612481',
                    alias: '박순애',
                    startTime: '2023-07-28T11:00:00.000Z',
                    endTime: '2023-07-28T11:50:00.000Z',
                    requestDeadline: '2023-07-27T03:00:00.000Z',
                    createdAt: '2023-07-25T02:05:26.773Z',
                    updatedAt: '2023-07-25T02:05:26.773Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64b364986afffb001917ac6d',
                    scheduleBundleId: null,
                    coachId: '642596251f90e500615765dd',
                    memberId: '6427b1d27afb66006c60b9e1',
                    alias: '양우재',
                    startTime: '2023-07-28T12:00:00.000Z',
                    endTime: '2023-07-28T12:50:00.000Z',
                    requestDeadline: '2023-07-27T12:00:00.000Z',
                    createdAt: '2023-07-16T03:31:36.523Z',
                    updatedAt: '2023-07-16T03:31:36.523Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사5',
                },
                {
                    _id: '64b91e0425fa4b001275de46',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '64b157e491f76200120d446f',
                    alias: '김동민',
                    startTime: '2023-07-28T12:00:00.000Z',
                    endTime: '2023-07-28T12:50:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-20T11:44:04.197Z',
                    updatedAt: '2023-07-20T11:44:04.197Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64bb6fbf04a3c4002afbec80',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '6408852e4be3fa00772add9b',
                    alias: '고근정',
                    startTime: '2023-07-28T12:00:00.000Z',
                    endTime: '2023-07-28T12:50:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-22T05:57:19.552Z',
                    updatedAt: '2023-07-22T05:57:19.552Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64b62d4e7a2f110012eea147',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '64aa5dd6d63356004b469885',
                    alias: '배은희',
                    startTime: '2023-07-28T12:00:00.000Z',
                    endTime: '2023-07-28T12:50:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-18T06:12:30.830Z',
                    updatedAt: '2023-07-18T06:12:30.830Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 4,
                    totalSession: 30,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
                {
                    _id: '64be73b625fa4b0012778d13',
                    scheduleBundleId: null,
                    coachId: '630dc4da110ab8001a47bd3a',
                    memberId: '645a34de72e0ad0056c1c31a',
                    alias: '김유리',
                    startTime: '2023-07-28T12:00:00.000Z',
                    endTime: '2023-07-28T12:50:00.000Z',
                    requestDeadline: '2023-07-27T03:00:00.000Z',
                    createdAt: '2023-07-24T12:51:02.112Z',
                    updatedAt: '2023-07-26T13:08:11.013Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사2',
                },
            ],
            [
                {
                    _id: '64b3e4c85a46c600114789fe',
                    scheduleBundleId: null,
                    coachId: '642596251f90e500615765dd',
                    memberId: '6427b1d27afb66006c60b9e1',
                    alias: '양우재',
                    startTime: '2023-07-28T13:00:00.000Z',
                    endTime: '2023-07-28T13:50:00.000Z',
                    requestDeadline: '2023-07-27T12:00:00.000Z',
                    createdAt: '2023-07-16T12:38:32.049Z',
                    updatedAt: '2023-07-16T12:38:32.049Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사5',
                },
                {
                    _id: '64bbc0d504a3c4002afc0e4e',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '642e6586b1f41c002aa7560f',
                    alias: '조재운',
                    startTime: '2023-07-28T13:00:00.000Z',
                    endTime: '2023-07-28T13:50:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-22T11:43:17.616Z',
                    updatedAt: '2023-07-22T11:43:17.616Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64b62d55d7d6a10019fe55e4',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '64812530d59539004b948b55',
                    alias: '김하연',
                    startTime: '2023-07-28T13:00:00.000Z',
                    endTime: '2023-07-28T13:50:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-18T06:12:37.428Z',
                    updatedAt: '2023-07-18T06:12:37.428Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 29,
                    totalSession: 70,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
            ],
            [
                {
                    _id: '64bbb63d25fa4b001276a9ab',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '64323243b1f41c002aa895ab',
                    alias: '형대우',
                    startTime: '2023-07-28T14:00:00.000Z',
                    endTime: '2023-07-28T14:50:00.000Z',
                    requestDeadline: '2023-07-27T14:00:00.000Z',
                    createdAt: '2023-07-22T10:58:05.842Z',
                    updatedAt: '2023-07-25T05:16:02.244Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
            ],
        ],
    },
    {
        daysOfWeek: '토',
        day: '29일',
        schedules: [
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [
                {
                    _id: '64bdbc9fd7d6a1001900d1af',
                    scheduleBundleId: null,
                    coachId: '6476bde354b8b500124e87c7',
                    memberId: '6479d0c02b98070012a15c87',
                    alias: '장혜연',
                    startTime: '2023-07-29T00:00:00.000Z',
                    endTime: '2023-07-29T00:50:00.000Z',
                    requestDeadline: '2023-07-28T13:00:00.000Z',
                    createdAt: '2023-07-23T23:49:51.733Z',
                    updatedAt: '2023-07-23T23:49:51.733Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사1',
                },
                {
                    _id: '64c09e1e04a3c4002afdb828',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '64842fe251f75800a313d0d8',
                    alias: '김수정',
                    startTime: '2023-07-29T00:00:00.000Z',
                    endTime: '2023-07-29T00:50:00.000Z',
                    requestDeadline: '2023-07-28T14:00:00.000Z',
                    createdAt: '2023-07-26T04:16:30.207Z',
                    updatedAt: '2023-07-26T04:16:30.207Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64c25c3025fa4b001278fd1e',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '644b7eed5345c3002b8dac91',
                    alias: '임수빈',
                    startTime: '2023-07-29T00:00:00.000Z',
                    endTime: '2023-07-29T00:50:00.000Z',
                    requestDeadline: '2023-07-28T14:00:00.000Z',
                    createdAt: '2023-07-27T11:59:44.866Z',
                    updatedAt: '2023-07-27T11:59:44.866Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사6',
                },
            ],
            [
                {
                    _id: '64bc9b6b8090fe0019360982',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64b8ec488090fe001934f79c',
                    alias: '권성윤',
                    startTime: '2023-07-29T01:00:00.000Z',
                    endTime: '2023-07-29T01:30:00.000Z',
                    requestDeadline: '2023-07-28T14:00:00.000Z',
                    createdAt: '2023-07-23T03:15:55.132Z',
                    updatedAt: '2023-07-23T03:16:02.906Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64bb1b108090fe0019358dff',
                    scheduleBundleId: null,
                    coachId: '6476bde354b8b500124e87c7',
                    memberId: '6476f40be0d37a004b81358d',
                    alias: '이기연',
                    startTime: '2023-07-29T01:00:00.000Z',
                    endTime: '2023-07-29T01:50:00.000Z',
                    requestDeadline: '2023-07-28T13:00:00.000Z',
                    createdAt: '2023-07-21T23:56:00.973Z',
                    updatedAt: '2023-07-21T23:56:00.973Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사1',
                },
            ],
            [
                {
                    _id: '64bb361a25fa4b0012766fda',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64b8c1b38090fe001934e1e8',
                    alias: '고건',
                    startTime: '2023-07-29T02:00:00.000Z',
                    endTime: '2023-07-29T02:30:00.000Z',
                    requestDeadline: '2023-07-28T14:00:00.000Z',
                    createdAt: '2023-07-22T01:51:22.496Z',
                    updatedAt: '2023-07-22T01:51:42.361Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64bb1b0d25fa4b0012766483',
                    scheduleBundleId: null,
                    coachId: '6476bde354b8b500124e87c7',
                    memberId: '6476c476223a68002a58afd1',
                    alias: '박미성',
                    startTime: '2023-07-29T02:00:00.000Z',
                    endTime: '2023-07-29T02:50:00.000Z',
                    requestDeadline: '2023-07-28T13:00:00.000Z',
                    createdAt: '2023-07-21T23:55:57.551Z',
                    updatedAt: '2023-07-21T23:55:57.551Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사1',
                },
                {
                    _id: '64bcdd2504a3c4002afc552d',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64911dd9de28eb0035050066',
                    alias: '유혜현',
                    startTime: '2023-07-29T02:30:00.000Z',
                    endTime: '2023-07-29T03:00:00.000Z',
                    requestDeadline: '2023-07-28T14:00:00.000Z',
                    createdAt: '2023-07-23T07:56:21.571Z',
                    updatedAt: '2023-07-23T07:56:27.915Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 6,
                    totalSession: 12,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
            ],
            [
                {
                    _id: '64bd379e04a3c4002afc78fa',
                    scheduleBundleId: null,
                    coachId: '6476bde354b8b500124e87c7',
                    memberId: '647af8532b98070012a1a14a',
                    alias: '홍학심',
                    startTime: '2023-07-29T03:00:00.000Z',
                    endTime: '2023-07-29T03:50:00.000Z',
                    requestDeadline: '2023-07-28T13:00:00.000Z',
                    createdAt: '2023-07-23T14:22:22.645Z',
                    updatedAt: '2023-07-23T14:22:22.645Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사1',
                },
                {
                    _id: '64c3ae99d7d6a100190309c4',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '64c2790c25fa4b0012790936',
                    alias: '주성애',
                    startTime: '2023-07-29T03:00:00.000Z',
                    endTime: '2023-07-29T03:50:00.000Z',
                    requestDeadline: '2023-07-28T14:00:00.000Z',
                    createdAt: '2023-07-28T12:03:37.518Z',
                    updatedAt: '2023-07-28T12:03:37.518Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64c0658f25fa4b001278428a',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64880414e19d730019bf919d',
                    alias: '배수운',
                    startTime: '2023-07-29T03:30:00.000Z',
                    endTime: '2023-07-29T04:00:00.000Z',
                    requestDeadline: '2023-07-28T14:00:00.000Z',
                    createdAt: '2023-07-26T00:15:11.659Z',
                    updatedAt: '2023-07-26T10:04:58.516Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 7,
                    totalSession: 24,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
            ],
            [
                {
                    _id: '64b62dd3d7d6a10019fe569a',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '64aa5dd6d63356004b469885',
                    alias: '배은희',
                    startTime: '2023-07-29T04:00:00.000Z',
                    endTime: '2023-07-29T04:50:00.000Z',
                    requestDeadline: '2023-07-28T14:00:00.000Z',
                    createdAt: '2023-07-18T06:14:43.504Z',
                    updatedAt: '2023-07-29T02:38:51.863Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 5,
                    totalSession: 30,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
                {
                    _id: '64be705ed7d6a10019012e7c',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '6475fb83223a68002a588085',
                    alias: '이민주',
                    startTime: '2023-07-29T04:30:00.000Z',
                    endTime: '2023-07-29T05:00:00.000Z',
                    requestDeadline: '2023-07-28T14:00:00.000Z',
                    createdAt: '2023-07-24T12:36:46.592Z',
                    updatedAt: '2023-07-25T00:31:46.436Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 21,
                    totalSession: 24,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
            ],
            [
                {
                    _id: '64bb37f78090fe0019359cd1',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64ba19798090fe001935535c',
                    alias: '라유민',
                    startTime: '2023-07-29T05:00:00.000Z',
                    endTime: '2023-07-29T05:30:00.000Z',
                    requestDeadline: '2023-07-28T14:00:00.000Z',
                    createdAt: '2023-07-22T01:59:19.636Z',
                    updatedAt: '2023-07-27T05:42:26.116Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
                {
                    _id: '64c0a0ef25fa4b001278649c',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '648a9592f4edc000122e3d81',
                    alias: '이지현',
                    startTime: '2023-07-29T05:00:00.000Z',
                    endTime: '2023-07-29T05:50:00.000Z',
                    requestDeadline: '2023-07-28T14:00:00.000Z',
                    createdAt: '2023-07-26T04:28:31.786Z',
                    updatedAt: '2023-07-26T04:28:31.786Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64c07ece8090fe0019378d38',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '644b7b1a7e771f0036913a79',
                    alias: '권민서',
                    startTime: '2023-07-29T05:30:00.000Z',
                    endTime: '2023-07-29T06:00:00.000Z',
                    requestDeadline: '2023-07-28T14:00:00.000Z',
                    createdAt: '2023-07-26T02:02:54.456Z',
                    updatedAt: '2023-07-26T02:03:01.803Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: null,
                    totalSession: null,
                    type: 'schedule',
                    duration: 30,
                    coachName: '강사6',
                },
            ],
            [
                {
                    _id: '64be9e8a04a3c4002afcf9ce',
                    scheduleBundleId: null,
                    coachId: '64254c6a7afb66006c5fe79d',
                    memberId: '6477b33b2a7db2001275cff1',
                    alias: '김가은',
                    startTime: '2023-07-29T06:00:00.000Z',
                    endTime: '2023-07-29T06:50:00.000Z',
                    requestDeadline: '2023-07-28T14:00:00.000Z',
                    createdAt: '2023-07-24T15:53:46.008Z',
                    updatedAt: '2023-07-24T15:53:46.008Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사4',
                },
                {
                    _id: '64b0ab6954d0580018e77139',
                    scheduleBundleId: null,
                    coachId: '63bc3704135c53002a2f4451',
                    memberId: '646b2357e35dfd011cbb6474',
                    alias: '유경진',
                    startTime: '2023-07-29T06:00:00.000Z',
                    endTime: '2023-07-29T06:50:00.000Z',
                    requestDeadline: '2023-07-28T14:00:00.000Z',
                    createdAt: '2023-07-14T01:56:57.914Z',
                    updatedAt: '2023-07-28T10:37:11.468Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    type: 'schedule',
                    duration: 50,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사3',
                },
                {
                    _id: '64b62db37a2f110012eea19d',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '649bab598d6af0004b6564fa',
                    alias: '유지연',
                    startTime: '2023-07-29T06:00:00.000Z',
                    endTime: '2023-07-29T06:50:00.000Z',
                    requestDeadline: '2023-07-28T14:00:00.000Z',
                    createdAt: '2023-07-18T06:14:11.150Z',
                    updatedAt: '2023-07-18T06:14:11.150Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 7,
                    totalSession: 10,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
            ],
            [
                {
                    _id: '64bb767325fa4b0012768f3e',
                    scheduleBundleId: null,
                    coachId: '63be82639b70e900193e4bd6',
                    memberId: '64659cc06203c8010631c793',
                    alias: '이래원',
                    startTime: '2023-07-29T07:00:00.000Z',
                    endTime: '2023-07-29T07:30:00.000Z',
                    requestDeadline: '2023-07-28T14:00:00.000Z',
                    createdAt: '2023-07-22T06:25:55.231Z',
                    updatedAt: '2023-07-22T06:27:38.122Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: false,
                    type: 'schedule',
                    duration: 30,
                    currentSession: null,
                    totalSession: null,
                    coachName: '강사6',
                },
                {
                    _id: '64b62dbdd7d6a10019fe566c',
                    scheduleBundleId: null,
                    coachId: '64812363cec7d000770b5d72',
                    memberId: '649408fd29fee00040f281d7',
                    alias: '오정현',
                    startTime: '2023-07-29T07:00:00.000Z',
                    endTime: '2023-07-29T07:50:00.000Z',
                    requestDeadline: '2023-07-28T14:00:00.000Z',
                    createdAt: '2023-07-18T06:14:21.269Z',
                    updatedAt: '2023-07-18T06:14:21.269Z',
                    __v: 0,
                    isCanceled: false,
                    isDeleted: false,
                    isConnected: true,
                    currentSession: 4,
                    totalSession: 10,
                    type: 'schedule',
                    duration: 50,
                    coachName: '강사7',
                },
            ],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
        ],
    },
];

weeklySchedulesDummyData.map((dailySchedules) => {
    dailySchedules.schedules.map((schedules) => {
        schedules.map((schedule) => {
            if (!!schedule.alias) {
                schedule.alias = schedule.alias.substr(0, 1) + 'OO';
            }
            const startDay = dayjs(schedule.startTime).day();
            const startHour = dayjs(schedule.startTime).get('hour');
            const startMinute = dayjs(schedule.startTime).get('minute');
            schedule.startTime = dayjs()
                .startOf('week')
                .add(startDay, 'day')
                .add(startHour, 'hour')
                .add(startMinute, 'minute')
                .toISOString();

            const endDay = dayjs(schedule.endTime).day();
            const endHour = dayjs(schedule.endTime).get('hour');
            const endMinute = dayjs(schedule.endTime).get('minute');
            schedule.endTime = dayjs()
                .startOf('week')
                .add(endDay, 'day')
                .add(endHour, 'hour')
                .add(endMinute, 'minute')
                .toISOString();
        });
    });
});

const weeklySchedulesCountDummyData = 274;

export { weeklySchedulesDummyData, weeklySchedulesCountDummyData };
