import React from 'react';

type Props = {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    width?: number;
    height?: number;
    mb?: number;
    mr?: number;
};

const BottomBorderTextInput = ({ value, onChange, width, height, mb, mr }: Props) => {
    return (
        <input
            className="border-b-[1px] border-solid border-primary text-[1.8rem] font-normal text-LAVEL_4"
            value={value}
            onChange={onChange}
            style={{ width: width, height: height, marginBottom: mb, marginRight: mr }}
        />
    );
};

export default BottomBorderTextInput;
