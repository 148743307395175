import React, { useEffect, useState } from 'react';
import { Col, isSameDate, Row } from '../../../lib/utils';
import { Body2Regular, Body3Bold, Body3Regular, CaptionLight } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import InputSquareDropDown from '../../DropDown/InputSquareDropDown';
import dayjs from 'dayjs';
import WhiteSquareButton from '../../Button/WhiteSquareButton';
import styled from 'styled-components';
import WhiteRoundButton from '../../Button/WhiteRoundButton';
import ColorRoundButton from '../../Button/ColorRoundButton';
import _ from 'lodash';
import RepeatInputSquareDropDown from '../../DropDown/repeatInputSquareDropDown';

const HoverAbleRow = styled.div<{ selected: string }>`
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: ${({ selected }) => (selected === 'true' ? '#3D73DD' : '#FDFEFF')};
    color: ${({ selected }) => (selected === 'true' ? '#FDFEFF' : '#353535')};

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        background-color: #c4d4f4;
        color: #fdfeff;
    }
`;

const CreateRepeatScheduleLeft = ({
    coachOption,
    coach,
    handleCoachSelect,
    selectedDay,
    setIsCalendar,
    timeList,
    startTime,
    handleStartTimeSelect,
    endTime,
    handleEndTimeSelect,
    repeatNumber,
    onChangeRepeatCount,
    daysArr,
    setDaysArr,
    onClickRepeatDay,
    isEveryDaySameTime,
    setIsEveryDaySameTime,
    roundedTime,
    currentTimeAdd50,
    onClickMaxRepeatCount,
    maxRepeatNumber,
}: any) => {
    const [isExistSelectDay, setIsExistSelectDay] = useState<boolean>(false);
    const [currentDayIndex, setCurrentDayIndex] = useState<number>(0); // 마지막에 클릭된 요일의 인덱스
    const [fillteredDaysArr, setFillteredDaysArr] = useState<any>([]);

    // 시작 시간 설정
    const handleDaysArrStartTime = (options: any, repeatIndex?: number) => {
        let cloneDayjsArr = _.cloneDeep(daysArr);
        let currentIndex = currentDayIndex;
        if (!!repeatIndex) {
            currentIndex = repeatIndex;
        }

        if (isEveryDaySameTime) {
            for (let cloneDayjs of cloneDayjsArr) {
                cloneDayjs.startTime = options;

                let add50StartTime = dayjs(options.value).add(50, 'minute');

                if (add50StartTime.isAfter(dayjs(options.value).endOf('day'))) {
                    cloneDayjs.endTime = {
                        value: dayjs().endOf('day').add(1, 'millisecond'),
                        label: dayjs().endOf('day').add(1, 'millisecond').format('A hh:mm'),
                    };
                } else {
                    cloneDayjs.endTime = {
                        value: add50StartTime,
                        label: dayjs(add50StartTime).format('A hh:mm'),
                    };
                }
            }
        } else {
            cloneDayjsArr[currentIndex].startTime = options;

            let add50StartTime = dayjs(options.value).add(50, 'minute');

            if (add50StartTime.isAfter(dayjs(options.value).endOf('day'))) {
                cloneDayjsArr[currentIndex].endTime = {
                    value: dayjs().endOf('day').add(1, 'millisecond'),
                    label: dayjs().endOf('day').add(1, 'millisecond').format('A hh:mm'),
                };
            } else {
                cloneDayjsArr[currentIndex].endTime = {
                    value: add50StartTime,
                    label: dayjs(add50StartTime).format('A hh:mm'),
                };
            }
        }

        setDaysArr(cloneDayjsArr);
    };

    // 종료시간 설정
    const handleDaysArrEndTime = (options: any, repeatIndex?: number) => {
        let cloneDayjsArr = _.cloneDeep(daysArr);
        let currentIndex = currentDayIndex;
        if (!!repeatIndex) {
            currentIndex = repeatIndex;
        }
        cloneDayjsArr[currentIndex].endTime = options;

        setDaysArr(cloneDayjsArr);
    };

    // 시간추가
    const onClickAddTime = (addNumber: number) => {
        let cloneDayjsArr = _.cloneDeep(daysArr);
        let currentIndex = currentDayIndex;

        let endTimeValue = dayjs(cloneDayjsArr[currentIndex].endTime.value).add(addNumber, 'minute');

        if (isEveryDaySameTime) {
            for (let cloneDayjs of cloneDayjsArr) {
                cloneDayjs.endTime = {
                    value: endTimeValue,
                    label: dayjs(endTimeValue).format('A hh:mm'),
                };
            }
        } else {
            cloneDayjsArr[currentIndex].endTime = {
                value: endTimeValue,
                label: dayjs(endTimeValue).format('A hh:mm'),
            };
        }

        setDaysArr(cloneDayjsArr);
    };

    // 시간빼기
    const onClickSubTime = (addNumber: number) => {
        let cloneDayjsArr = _.cloneDeep(daysArr);
        let currentIndex = currentDayIndex;

        let endTimeValue = dayjs(cloneDayjsArr[currentIndex].endTime.value).subtract(addNumber, 'minute');

        if (isEveryDaySameTime) {
            for (let cloneDayjs of cloneDayjsArr) {
                cloneDayjs.endTime = {
                    value: endTimeValue,
                    label: dayjs(endTimeValue).format('A hh:mm'),
                };
            }
        } else {
            cloneDayjsArr[currentIndex].endTime = {
                value: endTimeValue,
                label: dayjs(endTimeValue).format('A hh:mm'),
            };
        }

        setDaysArr(cloneDayjsArr);
    };

    // 시간추가
    const onClickRepeatAddTime = (addNumber: number, repeatIndex: number) => {
        let cloneDayjsArr = _.cloneDeep(daysArr);
        let currentIndex = currentDayIndex;
        if (!!repeatIndex) {
            currentIndex = repeatIndex;
        }

        let endTimeValue = dayjs(cloneDayjsArr[currentIndex].endTime.value).add(addNumber, 'minute');

        cloneDayjsArr[currentIndex].endTime = {
            value: endTimeValue,
            label: dayjs(endTimeValue).format('A hh:mm'),
        };

        setDaysArr(cloneDayjsArr);
    };

    // 시간빼기
    const onClickRepeatSubTime = (addNumber: number, repeatIndex: number) => {
        let cloneDayjsArr = _.cloneDeep(daysArr);
        let currentIndex = currentDayIndex;
        if (!!repeatIndex) {
            currentIndex = repeatIndex;
        }

        let endTimeValue = dayjs(cloneDayjsArr[currentIndex].endTime.value).subtract(addNumber, 'minute');

        cloneDayjsArr[currentIndex].endTime = {
            value: endTimeValue,
            label: dayjs(endTimeValue).format('A hh:mm'),
        };

        setDaysArr(cloneDayjsArr);
    };

    // // 시간빼기
    // const onClickRepeatSubTime = (subNumber: number) => {
    //     if (dayjs(startTime.value).isBefore(dayjs(endTime.value).subtract(subNumber, 'minute'))) {
    //         setEndTime({
    //             value: dayjs(endTime.value).subtract(subNumber, 'minute'),
    //             label: dayjs(dayjs(endTime.value).subtract(subNumber, 'minute')).format('A hh:mm'),
    //         });
    //     } else {
    //         createAlert('입력 오류', '감소 가능한 시간을 초과하였습니다', '확인', () => {});
    //     }
    // };

    // 반복요일 배열중 하나라도 선택되어야 아래에 시간이 표사된다
    useEffect(() => {
        if (daysArr) {
            let findIndexDaysArr = daysArr.findIndex((item: any) => item?.isSelected === true);

            setCurrentDayIndex(findIndexDaysArr);

            if (findIndexDaysArr === -1) {
                setIsExistSelectDay(false);
            } else {
                setIsExistSelectDay(true);
            }
            setFillteredDaysArr(daysArr.filter((item: any) => item?.isSelected === true));
            console.log('da', daysArr);
        }
    }, [daysArr]);

    // useEffect(() => {
    //     if (fillteredDaysArr) {
    //         console.log('fdA', fillteredDaysArr);
    //     }
    // }, [fillteredDaysArr]);

    // 마지막 클릭된걸 지우는 경우 이전에 선택되엇던 것들중 가장 앞에잇는것이 마지막클릭된걸로 간주
    // 마지막 클릭된걸 지우는 경우에 이전에 선택되엇던게 없으면 return;

    // useEffect(() => {
    //     if (currentDayIndex === -1) {
    //         let cloneDayjsArr: any = _.cloneDeep(daysArr);
    //         let findSelectedIndex = cloneDayjsArr.findIndex((item: any) => item.isSelected === true);

    //         if (findSelectedIndex === -1) {
    //             return;
    //         } else {
    //             setDaysArr(cloneDayjsArr);
    //         }
    //     }

    //     console.log('currentDayIndex :', currentDayIndex);
    // }, [currentDayIndex]);

    return (
        <Col style={{ width: 470 }}>
            <Col style={{ width: 382, height: 88, justifyContent: 'space-between' }}>
                <Body3Regular style={{ color: colors.LAVEL_4 }}> {'담당 강사'}</Body3Regular>

                {coachOption && (
                    <InputSquareDropDown
                        selectedGender={coach}
                        genderOptions={coachOption}
                        handleGenderSelect={handleCoachSelect}
                        customStyle={{ width: 382 }}
                        zIndex={100}
                    />
                )}
            </Col>

            <Col style={{ width: 382, height: 88, justifyContent: 'space-between', marginTop: 64 }}>
                <Body3Regular style={{ color: colors.LAVEL_4 }}> {'첫 등록일'}</Body3Regular>

                <InputSquareDropDown
                    selectedGender={coach}
                    genderOptions={coachOption}
                    handleGenderSelect={handleCoachSelect}
                    customStyle={{ width: 382 }}
                    isJustClick={true}
                    justClickString={dayjs(selectedDay).format('YYYY년 M월 D일(ddd)')}
                    justClickCallBack={() => setIsCalendar(true)}
                    zIndex={90}
                />
            </Col>

            <Col style={{ width: 382, height: 88, marginTop: 64 }}>
                <Row style={{ alignItems: 'center' }}>
                    <Body3Regular style={{ color: colors.LAVEL_4 }}>{'반복 횟수'}</Body3Regular>
                    <CaptionLight style={{ color: colors.LAVEL_2, marginLeft: 8 }}>
                        {'회원님과 회원님의 수강권을 선택해주세요! '}
                    </CaptionLight>
                </Row>

                <Row style={{ alignItems: 'center', justifyContent: 'space-between', marginTop: 16 }}>
                    <input
                        value={repeatNumber}
                        onChange={(e) => onChangeRepeatCount(e.target.value.toString())}
                        type="number"
                        min="1"
                        max={maxRepeatNumber.toString()}
                        disabled={maxRepeatNumber === 0}
                        placeholder="회원을 먼저 선택해주세요!"
                        style={{
                            width: 250,
                            height: 48,
                            borderBottom: '1px solid #a6bfef',
                            paddingLeft: 16,
                            fontSize: 18,
                        }}
                    />
                    <WhiteSquareButton
                        customStyle={{ width: 108, height: 48 }}
                        text="최대 횟수"
                        size="middle"
                        callBack={onClickMaxRepeatCount}
                        isDisabled={maxRepeatNumber === 0}
                    />
                </Row>
            </Col>

            <Col style={{ width: 382, height: 88, justifyContent: 'space-between', marginTop: 64 }}>
                <Body3Regular style={{ color: colors.LAVEL_4 }}>{'반복요일'}</Body3Regular>

                <Row
                    style={{
                        width: 382,
                        height: 48,
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingLeft: 19,
                        paddingRight: 19,
                        border: '1px solid #C3D4F4',
                        borderRadius: 8,
                    }}
                >
                    {daysArr &&
                        daysArr.map((item: any, index: number) => {
                            return (
                                <HoverAbleRow
                                    selected={item?.isSelected ? 'true' : 'false'}
                                    onClick={() => onClickRepeatDay(item)}
                                    key={'dayjsArr' + index}
                                >
                                    <Body3Regular>{item?.value}</Body3Regular>
                                </HoverAbleRow>
                            );
                        })}
                </Row>
            </Col>

            {isExistSelectDay && (
                <Col style={{ width: 534, backgroundColor: colors.WHITE_50, marginTop: 64 }}>
                    <Row style={{ alignItems: 'center' }}>
                        <Row
                            onClick={() => {
                                setIsEveryDaySameTime(true);
                            }}
                            style={{ alignItems: 'center', cursor: 'pointer' }}
                        >
                            <Row
                                style={{
                                    width: 16,
                                    height: 16,
                                    borderRadius: '50%',
                                    border: '1px solid #3d73dd',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {isEveryDaySameTime && (
                                    <Row
                                        style={{
                                            width: 10,
                                            height: 10,
                                            borderRadius: '50%',
                                            backgroundColor: colors.BLUE_500,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    />
                                )}
                            </Row>

                            <Body3Regular style={{ marginLeft: 8, color: colors.LAVEL_4 }}>
                                {'모든 요일에 동일한 시간'}
                            </Body3Regular>
                        </Row>

                        <Row
                            onClick={() => {
                                setIsEveryDaySameTime(false);
                            }}
                            style={{ alignItems: 'center', cursor: 'pointer', marginLeft: 48 }}
                        >
                            <Row
                                style={{
                                    width: 16,
                                    height: 16,
                                    borderRadius: '50%',
                                    border: '1px solid #3d73dd',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {!isEveryDaySameTime && (
                                    <Row
                                        style={{
                                            width: 10,
                                            height: 10,
                                            borderRadius: '50%',
                                            backgroundColor: colors.BLUE_500,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    />
                                )}
                            </Row>

                            <Body3Regular style={{ marginLeft: 8, color: colors.LAVEL_4 }}>
                                {'요일별 시간 설정'}
                            </Body3Regular>
                        </Row>
                    </Row>

                    {isEveryDaySameTime ? (
                        <Col
                            style={{
                                width: 534,
                                height: 226,
                                borderRadius: 16,
                                border: '1px solid #c3d4f4',
                                marginTop: 32,
                                padding: 32,
                            }}
                        >
                            <Body3Regular style={{ color: colors.LAVEL_4 }}>{'모든 요일 반복'}</Body3Regular>

                            <Row
                                style={{
                                    width: 470,
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginTop: 16,
                                }}
                            >
                                {timeList && (
                                    <InputSquareDropDown
                                        selectedGender={
                                            currentDayIndex === -1 ? startTime : daysArr[currentDayIndex].startTime
                                        }
                                        genderOptions={timeList}
                                        handleGenderSelect={
                                            currentDayIndex === -1 ? handleStartTimeSelect : handleDaysArrStartTime
                                        }
                                        isDefaultScroll={true}
                                    />
                                )}

                                <Body2Regular style={{ color: colors.BLUE_500 }}>~</Body2Regular>
                                {timeList && (
                                    <InputSquareDropDown
                                        selectedGender={
                                            currentDayIndex === -1 ? endTime : daysArr[currentDayIndex].endTime
                                        }
                                        genderOptions={timeList}
                                        handleGenderSelect={
                                            currentDayIndex === -1 ? handleEndTimeSelect : handleDaysArrEndTime
                                        }
                                        isDefaultScroll={true}
                                    />
                                )}
                            </Row>
                            <CaptionLight style={{ color: colors.LAVEL_1, marginLeft: 8, marginTop: 16 }}>
                                *일정에 소요되는 시간만큼 추가해주세요!
                            </CaptionLight>

                            <Row
                                style={{
                                    width: 470,
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginTop: 16,
                                }}
                            >
                                <WhiteRoundButton
                                    customStyle={{ width: 60, height: 24 }}
                                    text="-30분"
                                    callBack={() => {
                                        onClickSubTime(30);
                                    }}
                                    size="tiny"
                                />
                                <WhiteRoundButton
                                    customStyle={{ width: 60, height: 24 }}
                                    text="-20분"
                                    callBack={() => {
                                        onClickSubTime(20);
                                    }}
                                    size="tiny"
                                />
                                <WhiteRoundButton
                                    customStyle={{ width: 60, height: 24 }}
                                    text="-10분"
                                    callBack={() => {
                                        onClickSubTime(10);
                                    }}
                                    size="tiny"
                                />

                                <ColorRoundButton
                                    customStyle={{ width: 60, height: 24 }}
                                    text="+10분"
                                    callBack={() => {
                                        onClickAddTime(10);
                                    }}
                                    size="tiny"
                                />
                                <ColorRoundButton
                                    customStyle={{ width: 60, height: 24 }}
                                    text="+20분"
                                    callBack={() => {
                                        onClickAddTime(20);
                                    }}
                                    size="tiny"
                                />
                                <ColorRoundButton
                                    customStyle={{ width: 60, height: 24 }}
                                    text="+30분"
                                    callBack={() => {
                                        onClickAddTime(30);
                                    }}
                                    size="tiny"
                                />
                            </Row>
                        </Col>
                    ) : (
                        <Col
                            style={{
                                width: 534,
                                borderRadius: 16,
                                border: '1px solid #c3d4f4',
                                marginTop: 32,
                                padding: 32,
                            }}
                        >
                            <Body3Regular style={{ color: colors.LAVEL_4 }}>{'요일별 반복'}</Body3Regular>

                            {fillteredDaysArr.map((item: any, index: number) => {
                                return (
                                    <>
                                        <Row
                                            style={{
                                                display: 'flex',
                                                width: '40px',
                                                height: '40px',
                                                padding: '8px 16px',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: '10px',
                                                borderRadius: '50%',
                                                background: '#3D73DD',
                                                color: 'white',
                                                textAlign: 'center',
                                                marginTop: 24,
                                            }}
                                        >
                                            <Body3Bold>{item?.value}</Body3Bold>
                                        </Row>
                                        <Row
                                            style={{
                                                width: 470,
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                marginTop: 16,
                                            }}
                                        >
                                            {timeList && (
                                                <RepeatInputSquareDropDown
                                                    selectedGender={
                                                        currentDayIndex === -1
                                                            ? startTime
                                                            : daysArr[parseInt(item.week)].startTime
                                                    }
                                                    genderOptions={timeList}
                                                    handleGenderSelect={
                                                        currentDayIndex === -1
                                                            ? handleStartTimeSelect
                                                            : handleDaysArrStartTime
                                                    }
                                                    repeatIndex={parseInt(item.week)}
                                                    zIndex={(fillteredDaysArr.length - index) * 10}
                                                />
                                            )}

                                            <Body2Regular style={{ color: colors.BLUE_500 }}>~</Body2Regular>
                                            {timeList && (
                                                <RepeatInputSquareDropDown
                                                    selectedGender={
                                                        currentDayIndex === -1
                                                            ? endTime
                                                            : daysArr[parseInt(item.week)].endTime
                                                    }
                                                    genderOptions={timeList}
                                                    handleGenderSelect={
                                                        currentDayIndex === -1
                                                            ? handleEndTimeSelect
                                                            : handleDaysArrEndTime
                                                    }
                                                    repeatIndex={parseInt(item.week)}
                                                    zIndex={(fillteredDaysArr.length - index) * 10}
                                                />
                                            )}
                                        </Row>
                                        <CaptionLight style={{ color: colors.LAVEL_1, marginLeft: 8, marginTop: 16 }}>
                                            *일정에 소요되는 시간만큼 추가해주세요!
                                        </CaptionLight>

                                        <Row
                                            style={{
                                                width: 470,
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                marginTop: 16,
                                            }}
                                        >
                                            <WhiteRoundButton
                                                customStyle={{ width: 60, height: 24 }}
                                                text="-30분"
                                                callBack={() => {
                                                    onClickRepeatSubTime(30, item.week);
                                                }}
                                                size="tiny"
                                            />
                                            <WhiteRoundButton
                                                customStyle={{ width: 60, height: 24 }}
                                                text="-20분"
                                                callBack={() => {
                                                    onClickRepeatSubTime(20, item.week);
                                                }}
                                                size="tiny"
                                            />
                                            <WhiteRoundButton
                                                customStyle={{ width: 60, height: 24 }}
                                                text="-10분"
                                                callBack={() => {
                                                    onClickRepeatSubTime(10, item.week);
                                                }}
                                                size="tiny"
                                            />

                                            <ColorRoundButton
                                                customStyle={{ width: 60, height: 24 }}
                                                text="+10분"
                                                callBack={() => {
                                                    onClickRepeatAddTime(10, item.week);
                                                }}
                                                size="tiny"
                                            />
                                            <ColorRoundButton
                                                customStyle={{ width: 60, height: 24 }}
                                                text="+20분"
                                                callBack={() => {
                                                    onClickRepeatAddTime(20, item.week);
                                                }}
                                                size="tiny"
                                            />
                                            <ColorRoundButton
                                                customStyle={{ width: 60, height: 24 }}
                                                text="+30분"
                                                callBack={() => {
                                                    onClickRepeatAddTime(30, item.week);
                                                }}
                                                size="tiny"
                                            />
                                        </Row>
                                    </>
                                );
                            })}
                        </Col>
                    )}
                </Col>
            )}
        </Col>
    );
};

export default CreateRepeatScheduleLeft;
