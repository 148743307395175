import { Dispatch, SetStateAction, useRef, useState } from 'react';
import styled from 'styled-components';
import ModalAddTrainerBody1 from './ModalAddTrainerBody1';
import ModalAddTrainerHeader from './ModalAddTrainerHeader';
import ModalAddTrainerBody2 from './ModalAddTrainerBody2';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { connectingTrainerAPI, getAddTrainerInfoAPI } from '../../api/employee';
import ModalAddTrainerBody3 from './ModalAddTrainerBody3';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
`;

const ModalInner = styled.div`
    background: white;
    width: 880px;
    height: 460px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
`;

type Props = {
    modalVisible: boolean;
    setModalVisible: Dispatch<SetStateAction<boolean>>;
    authorization: any;
    authObject: any;
    createAlert: any;
};

const ModalAddTrainer = ({ modalVisible, setModalVisible, authorization, authObject, createAlert }: Props) => {
    const [step, setStep] = useState<string>('first');

    const [digits, setDigits] = useState(['', '', '', '']);
    const inputRefs: any = [useRef(null), useRef(null), useRef(null), useRef(null)];
    const queryClient = useQueryClient();

    // 트레이너 조회 플래그
    const [getTrainerInfoFlag, setGetTrainerInfoFlag] = useState<boolean>(false);
    // 트레이너 인포
    const [coachId, setCoachId] = useState<string>('');
    const [coachInviteCode, setCoachInviteCode] = useState<string>('');
    const [coachName, setCoachName] = useState<string>('');
    const [coachProfileImageUrl, setCoachProfileImageUrl] = useState<string>('');

    const handleChange = (index: any, value: any) => {
        let sanitizedValue;

        if (index === 0) {
            sanitizedValue = value.replace(/[^A-Za-z]/g, '').toUpperCase();
        } else {
            sanitizedValue = value.replace(/[^0-9]/g, '');
        }

        const newDigits = [...digits];
        newDigits[index] = sanitizedValue;
        setDigits(newDigits);

        if (index < inputRefs.length - 1 && sanitizedValue !== '') {
            inputRefs[index + 1].current.focus();
        }
    };

    const onClickExit = () => {
        setModalVisible(false);
    };

    const onClickNextStep = () => {
        if (step === 'first') {
            setStep('second');
        }

        if (step === 'second') {
            if (digits[0] !== '' && digits[1] !== '' && digits[2] !== '' && digits[3] !== '') {
                setGetTrainerInfoFlag(true);
            } else {
                createAlert('필수 입력', '트레이너 코드를 입력해주세요', true);
            }
        }

        if (step === 'third') {
            connectingTrainer.mutate();
        }
    };

    const onClickPrevStep = () => {
        if (step === 'second') {
            setStep('first');
            setCoachId('');
            setCoachInviteCode('');
            setCoachName('');
            setCoachProfileImageUrl('');
            setDigits(['', '', '', '']);
        } else {
            setStep('second');
            setCoachId('');
            setCoachInviteCode('');
            setCoachName('');
            setCoachProfileImageUrl('');
            setDigits(['', '', '', '']);
        }
    };

    //  트레이너 조회 API : (GET)
    const getAddTrainerInfo = useQuery(
        ['getAddTrainerInfoAPI'],
        async () =>
            await getAddTrainerInfoAPI(
                authObject?.centerId,
                digits[0] + digits[1] + digits[2] + digits[3],
                authorization
            ),
        {
            onSuccess: (res) => {
                setGetTrainerInfoFlag(false);

                if (res.status === 200) {
                    const coach = res.data;

                    console.log(coach);
                    setCoachId(coach.coachId);
                    setCoachInviteCode(coach.coachInviteCode);
                    setCoachName(coach.coachName);
                    setCoachProfileImageUrl(coach.coachProfileImageUrl);
                    setStep('third');
                }
                if (res.status === 404) {
                    createAlert('오류', '트레이너를 찾을 수 없습니다!\n 코드를 다시 한번 확인해주세요', true);
                }
                if (res.status === 406) {
                    createAlert(
                        '오류',
                        '현재 요금제에서는 더이상 추가가 불가능합니다.\n 문제가 있을 시 카카오톡 Rappo 채널로 문의 부탁드려요',
                        true
                    );
                }
                if (res.status === 409) {
                    createAlert('오류', '이미 등록된 트레이너 입니다', true);
                }
            },
            onError: (err) => {
                setGetTrainerInfoFlag(false);
                createAlert('', '알수 없는 오류입니다');
                console.log(err);
            },
            enabled: getTrainerInfoFlag,
            staleTime: 0,
        }
    );

    // 트레이너 연결 (PATCH)
    const connectingTrainer = useMutation({
        mutationFn: async () => await connectingTrainerAPI(authObject?.centerId, coachId, authorization),
        onSuccess: (res) => {
            console.log(res);
            if (res.status === 200) {
                setModalVisible(false);
                createAlert(
                    '등록 완료',
                    `트레이너 등록이 완료되었습니다\n트레이너의 상세 정보는 '트레이너 정보'에서 상세 입력할 수 있습니다`
                );

                queryClient.invalidateQueries({ queryKey: ['getTrainerListAPI'] });
            }
            if (res.status === 404) {
                createAlert('오류', '트레이너를 찾을 수 없습니다!\n 코드를 다시 한번 확인해주세요', true);
            }
            if (res.status === 406) {
                createAlert(
                    '오류',
                    '현재 요금제에서는 더이상 추가가 불가능합니다.\n 문제가 있을 시 카카오톡 Rappo 채널로 문의 부탁드려요',
                    true
                );
            }
            if (res.status === 409) {
                createAlert('오류', '이미 등록된 트레이너 입니다', true);
            }
        },
    });

    return (
        <ModalOverLay>
            <ModalInner>
                <ModalAddTrainerHeader step={step} onClickExit={onClickExit} />
                {step === 'first' && <ModalAddTrainerBody1 onClickNextStep={onClickNextStep} />}
                {step === 'second' && (
                    <ModalAddTrainerBody2
                        onClickNextStep={onClickNextStep}
                        onClickPrevStep={onClickPrevStep}
                        digits={digits}
                        handleChange={handleChange}
                        inputRefs={inputRefs}
                    />
                )}
                {step === 'third' && (
                    <ModalAddTrainerBody3
                        coachInviteCode={coachInviteCode}
                        coachName={coachName}
                        coachProfileImageUrl={coachProfileImageUrl}
                        onClickNextStep={onClickNextStep}
                        onClickPrevStep={onClickPrevStep}
                    />
                )}
            </ModalInner>
        </ModalOverLay>
    );
};

export default ModalAddTrainer;
