import axios, { AxiosResponse } from 'axios';

// export /**
//  * @method get
//  * @brief 일간 스케쥴 조회 API
//  */
// const getDailyScheduleAPI = async (centerId: string, date: string, accessToken: string): Promise<any> => {
//     let response = {} as AxiosResponse<any, any>;
//     try {
//         response = await axios({
//             method: 'get',
//             url: `${process.env.REACT_APP_SERVER}/v1/centers/daily-schedules/${centerId}?date=${date}`,
//             headers: { authorization: accessToken },
//         });

//         if (response.status === 200) {
//             return response;
//         }
//     } catch (error: any) {
//         // Error 😨
//         switch (error.response.status) {
//             case 400:
//                 return { status: error.response.status };
//             case 401:
//                 return { status: error.response.status };
//             case 403:
//                 return { status: error.response.status };
//             case 404:
//                 return { status: error.response.status };
//             case 500:
//                 alert('서버 내부 오류.');
//                 return { status: error.response.status };
//         }
//     }
// };

export /**
 * @method get
 * @brief 일간 스케쥴 조회 v3 API
 */
const getDailyScheduleAPI = async (centerId: string, date: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v4/centers/daily-schedules/${centerId}?date=${date}`,

            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method get
 * @brief 주간 스케쥴 조회 API
 */
const getWeeklyScheduleAPI = async (centerId: string, date: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v4/centers/weekly-schedules/${centerId}?date=${date}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method get
 * @brief 스케줄변경/취소탭 ->  당일 취소및 변경된 수업 횟수 조회 API
 */
const getCancelAndChangeCountAPI = async (centerId: string, date: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/centers/today-adjustment-count/${centerId}?date=${date}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method get
 * @brief 스케줄변경/취소탭 ->  당일 취소및 변경 리스트 조회 API
 */
const getCancelAndChangeListAPI = async (centerId: string, date: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/centers/today-adjustment-list/${centerId}?date=${date}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method get
 * @brief 스케줄변경/취소탭 -> 회원의 수업취소 리스트 조회 API
 */
const getMemberCancelScheduleListAPI = async (centerId: string, date: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/centers/member-cancel-list/${centerId}?date=${date}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method post
 * @brief 일간 강사스케줄 / 일반일정 등록
 */
const createGeneralScheduleAPI = async (
    centerId: string,
    coachId: string,
    name: string,
    startTime: string,
    endTime: string,
    accessToken: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/v1/onetimeschedules/center/${centerId}`,
            headers: { authorization: accessToken },
            data: {
                coachId,
                name,
                startTime,
                endTime,
            },
        });

        if (response.status === 201) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 410:
                return { status: error.response.status };
            case 420:
                return { status: error.response.status };
            case 430:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method patch
 * @brief 일간 강사스케줄 / 일반일정 수정
 */
const modifyGeneralScheduleAPI = async (
    centerId: string,
    onetimeScheudleId: string,
    coachId: string,
    name: string,
    startTime: string,
    endTime: string,
    accessToken: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER}/v1/onetimeschedules/center/${centerId}/onetimeScheudle/${onetimeScheudleId}`,
            headers: { authorization: accessToken },
            data: {
                coachId,
                name,
                startTime,
                endTime,
            },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 410:
                return { status: error.response.status };
            case 420:
                return { status: error.response.status };
            case 430:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

// 스케줄 생성 관련

export /**
 * @method get
 * @brief 강사 리스트 드롭다운 에서 coachName, coachId 조회 api
 */
const getCoachListAPI = async (centerId: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/centers/coach-name-list/${centerId}`,

            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method get
 * @brief 센터 스케줄 생성을 위한 강사의 회원 리스트 조회
 */
const getMemberListForCenterAPI = async (centerId: string, coachId: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v2/rapports/center/${centerId}/coach/${coachId}/member-list-for-create-schedule`,

            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method post
 * @brief 1:1 일정추가
 */
const createOneOnOneScheduleAPI = async (
    centerId: string,
    coachId: string,
    memberId: string,
    startTime: string,
    endTime: string,
    membershipId: string,
    accessToken: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/v1/schedules/center/${centerId}`,
            headers: { authorization: accessToken },
            data: {
                coachId,
                memberId,
                startTime,
                endTime,
                membershipId,
            },
        });

        if (response.status === 201) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 410:
                return { status: error.response.status };
            case 420:
                return { status: error.response.status };
            case 430:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method post
 * @brief 1:2 일정추가
 */
const createOneOnTwoScheduleAPI = async (
    centerId: string,
    coachId: string,
    scheduleTimes: any,
    accessToken: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/v1/schedules/center/${centerId}/duplicable`,
            headers: { authorization: accessToken },
            data: {
                coachId,
                scheduleTimes,
            },
        });

        if (response.status === 201) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 410:
                return { status: error.response.status };
            case 420:
                return { status: error.response.status };
            case 430:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method post
 * @brief 반복 일정 추가
 */
const createRepeatScheduleAPI = async (
    centerId: string,
    baseStartDate: string,
    coachId: string,
    memberId: string,
    membershipId: string,
    scheduleTimes: any,
    accessToken: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/v1/schedules/center/${centerId}/repeat`,
            headers: { authorization: accessToken },
            data: {
                baseStartDate,
                coachId,
                memberId,
                membershipId,
                scheduleTimes,
            },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 410:
                return { status: error.response.status };
            case 420:
                return { status: error.response.status };
            case 430:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method post
 * @brief 반복 일정 삭제
 */
const deleteRepeatScheduleAPI = async (
    centerId: string,
    scheduleId: string,
    scheduleBundleId: string,
    accessToken: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_SERVER}/v1/schedules/center/${centerId}/repeat?scheduleId=${scheduleId}&scheduleBundleId=${scheduleBundleId}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 204) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method post
 * @brief OFF 일정 추가
 */
const createOffAPI = async (
    centerId: string,
    coachId: string,
    blockType: string,
    startTime: string,
    endTime: string,
    accessToken: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/v1/blocks/center/${centerId}`,
            headers: { authorization: accessToken },
            data: {
                coachId,
                blockType,
                startTime,
                endTime,
            },
        });

        if (response.status === 201) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 410:
                return { status: error.response.status };
            case 420:
                return { status: error.response.status };
            case 430:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method patch
 * @brief 일간/주간 강사스케줄 / 수업일정 수정
 */
const modifyOneOnOneScheduleAPI = async (
    centerId: string,
    scheduleId: string,
    coachId: string,
    changedStartTime: string,
    changedEndTime: string,
    accessToken: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER}/v1/schedules/center/${centerId}/schedule/${scheduleId}`,
            headers: { authorization: accessToken },
            data: {
                coachId,
                changedStartTime,
                changedEndTime,
            },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 410:
                return { status: error.response.status };
            case 420:
                return { status: error.response.status };
            case 430:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method patch
 * @brief 일간/주간 강사스케줄 / 수업일정 수정
 */
const updateScheduleSessionAPI = async (
    coachId: string,
    memberId: string,
    scheduleId: string,
    currentSession: number,
    accessToken: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER}/v2/memberships/coach/${coachId}/member/${memberId}/currentsession`,
            headers: { authorization: accessToken },
            data: {
                scheduleId,
                currentSession,
            },
        });

        if (response.status === 200 || response.status === 210) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 412:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method patch
 * @brief 일간/주간 강사스케줄 / 수업일정 회원권 수정
 */
const modifyOneOnOneScheduleMembershipAPI = async (
    centerId: string,
    scheduleId: string,
    coachId: string,
    newMembershipId: string,
    accessToken: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER}/v1/schedules/center/${centerId}/schedule/${scheduleId}/membership`,
            headers: { authorization: accessToken },
            data: {
                coachId,
                newMembershipId,
            },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 410:
                return { status: error.response.status };
            case 420:
                return { status: error.response.status };
            case 430:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method patch
 * @brief 일간 강사스케줄 / 일반일정 수정
 */
const modifyScheduleAttendance = async (
    scheduleId: string,
    attendanceStatus: string,
    accessToken: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER}/v1/schedules/${scheduleId}/attendancestatus`,
            headers: { authorization: accessToken },
            data: {
                attendanceStatus,
            },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 410:
                return { status: error.response.status };
            case 420:
                return { status: error.response.status };
            case 430:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method delete
 * @brief 일정삭제
 */
const deleteScheduleAPI = async (
    centerId: string,
    scheduleId: string,
    coachId: string,
    accessToken: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_SERVER}/v1/schedules/center/${centerId}/schedule/${scheduleId}`,
            headers: { authorization: accessToken },
            data: {
                coachId,
            },
        });

        if (response.status === 204) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };

            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method delete
 * @brief 일간 강사스케줄 / 일반일정 삭제
 */
const deleteGeneralScheduleAPI = async (
    centerId: string,
    onetimeScheudleId: string,
    accessToken: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_SERVER}/v1/onetimeschedules/center/${centerId}/onetimeScheudle/${onetimeScheudleId}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 204) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };

            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method delete
 * @brief OFF 일정 삭제
 */
const deleteOffScheduleAPI = async (centerId: string, blockId: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_SERVER}/v1/blocks/center/${centerId}/block/${blockId}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 204) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };

            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method patch
 * @brief 수업 신청 수락
 */
const approveScheduleRequestAPI = async (centerId: string, requestId: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER}/v1/requests/center/${centerId}/request/${requestId}/approve`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 410:
                return { status: error.response.status };
            case 420:
                return { status: error.response.status };
            case 430:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method patch
 * @brief 수업 신청 거절
 */
const rejectScheduleRequestAPI = async (centerId: string, requestId: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER}/v1/requests/center/${centerId}/request/${requestId}/reject`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 410:
                return { status: error.response.status };
            case 420:
                return { status: error.response.status };
            case 430:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method post
 * @brief 1:1 일정추가
 */
const createOneMoreScheduleAPI = async (
    centerId: string,
    coachId: string,
    memberId: string,
    startTime: string,
    endTime: string,
    membershipId: string,
    accessToken: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/v1/schedules/center/${centerId}/one-more-schedule`,
            headers: { authorization: accessToken },
            data: {
                coachId,
                memberId,
                startTime,
                endTime,
                membershipId,
            },
        });

        if (response.status === 201) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 410:
                return { status: error.response.status };
            case 420:
                return { status: error.response.status };
            case 430:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};
