import React from 'react';
import TextSelector, { FontsType } from '../TextSelector';
import { colors } from '../../lib/colors';

type Props = {
    text: string;
    fontType?: FontsType;
    width?: number;
    height?: number;
    onClick?: () => void;
    className?: string;
    mb?: number;
    mt?: number;
    color?: keyof typeof colors;
    textColor?: keyof typeof colors;
    disabled?: boolean;
};

const NewWhiteRoundButton = ({
    text,
    fontType = 'Body2Regular',
    width,
    height,
    onClick,
    className,
    mb,
    mt,
    color = 'primary',
    textColor,
    disabled,
}: Props) => {
    return (
        <div
            onClick={onClick}
            className={`flex flex-col justify-center items-center  bg-white rounded-[48px] ${className} border-[1px] border-solid border-${color} cursor-pointer 
            ${disabled ? 'border-WHITE_700 bg-WHITE_400' : ''}`}
            style={{
                width: width ? `${width}px` : 'auto',
                height: height ? `${height}px` : 'auto',
                marginBottom: mb ? `${mb}px` : '0',
                marginTop: mt ? `${mt}px` : '0',
            }}
        >
            <TextSelector fontType={fontType} text={text} color={textColor ? textColor : color} />
        </div>
    );
};

export default NewWhiteRoundButton;
