import React from 'react';
import { Col, Row } from '../../../lib/utils';
import { Body3Light, Body3Regular } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import { StyledSignUpInput2 } from '../SignUpBody2';
import WhiteRoundButton from '../../Button/WhiteRoundButton';

const InputSmsCode = ({
    authNumber,
    setAuthNumber,
    isRunning,
    formattedTime,
    isCheckAuthNumber,
    handleAuthSmsCode,
}: any) => {
    return (
        <Col style={{ marginTop: 32 }}>
            <label htmlFor="name" style={{ display: 'flex', marginBottom: 8 }}>
                <Body3Regular>인증 번호</Body3Regular>
                &nbsp;
                <Body3Regular style={{ color: colors.ERROR }}>*</Body3Regular>
                {isRunning && (
                    <Body3Regular style={{ color: colors.ERROR, marginLeft: 212 }}>{formattedTime}</Body3Regular>
                )}
            </label>

            <Row>
                <StyledSignUpInput2
                    id="authNumber"
                    name="text"
                    type="authNumber"
                    autoComplete="authNumber"
                    placeholder="인증번호를 입력해주세요!"
                    maxLength={6}
                    value={authNumber}
                    onChange={(e) => {
                        setAuthNumber(e.target.value);
                    }}
                />

                <WhiteRoundButton
                    text="확인"
                    size="middle"
                    isDisabled={isCheckAuthNumber || authNumber.length === 0}
                    isVisibleLeftImg={false}
                    isVisibleRightImg={false}
                    callBack={handleAuthSmsCode}
                    type="button"
                    customStyle={{ width: 76, height: 48 }}
                />
            </Row>

            <Body3Light
                style={{ marginLeft: 16, marginTop: 6, color: isCheckAuthNumber ? colors.primary : colors.WHITE_900 }}
            >
                {isCheckAuthNumber ? '인증이 완료되었어요!' : '휴대폰 문자로 온 인증 번호를 입력해주세요!'}
            </Body3Light>
        </Col>
    );
};

export default InputSmsCode;
