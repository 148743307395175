import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { colors } from '../../../lib/colors';
import { Col } from '../../../lib/utils';
import dayjs from 'dayjs';

import ScheduleHistoryEmptyGraph from './ScheduleHistoryEmptyGraph';

const MonthlyScheduleHistoryGraph = ({ monthlyFilterData, monthlyEntireData, monthMaxCount }: any) => {
    const CustomTick = (tick: any) => {
        const { x, y, value } = tick;

        let findList = null;
        findList = monthlyEntireData.find((e: any) => e.xLabel === value);

        return (
            <g transform={`translate(${x},${y})`} style={{ textAnchor: 'middle' }}>
                {findList && findList.isToday && (
                    <rect x={-4} y={20} fill={colors.BLUE_500} width={8} height={8} rx={4} />
                )}
                <text
                    x={0}
                    y={55}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{
                        fontSize: 24,
                        fontWeight: 700,
                        fill:
                            findList && findList.isToday
                                ? colors.LAVEL_4
                                : dayjs(findList.startDate).isAfter(dayjs())
                                ? colors.GREY_100
                                : colors.LAVEL_2,
                        outlineWidth: 0,
                    }}
                >
                    {value}
                </text>
            </g>
        );
    };
    return (
        <Col
            style={{
                width: 1192,
                height: 614,
                marginTop: 25,
            }}
        >
            {monthlyEntireData.length === 0 ? (
                <ScheduleHistoryEmptyGraph />
            ) : (
                <ResponsiveLine
                    data={monthlyFilterData}
                    margin={{ right: 70, top: 15, bottom: 100, left: 90 }}
                    xScale={{ type: 'point' }}
                    yScale={{
                        type: 'linear',
                        min: 0,
                        max: monthMaxCount < 10 ? 10 : monthMaxCount,
                        stacked: false,
                        reverse: false,
                    }}
                    yFormat=" >-.2f"
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 0,
                        tickPadding: 5,
                        tickRotation: 0,
                        renderTick: CustomTick,
                    }} // 바텀 라벨의 스타일
                    axisLeft={{
                        tickSize: 0,
                        tickPadding: 25,
                        tickRotation: 0,
                    }}
                    enableGridX={false}
                    colors={(d) => d.color} // 선의 색상을 데이터의 color 속성으로 설정
                    pointSize={8}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    useMesh={false}
                />
            )}
        </Col>
    );
};

export default MonthlyScheduleHistoryGraph;
