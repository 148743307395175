import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { Col, Row } from '../../lib/utils';
import { Body1Regular, Body3Light, Head4, Subtitle1Regular } from '../../lib/font';
import { HoverImage } from '../../lib/styled';
import { I_EMPTY_PROFILE, I_POPUP_EXIT } from '../../types/images';
import { colors } from '../../lib/colors';
import NormalButton from '../Button/NormalButton';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
`;

const ModalInner = styled.div`
    background: #f5f9ff;
    width: 1000px;
    height: 480px;
    border-radius: 24px;
    box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.12);
`;

type Props = {
    modalVisible: boolean;
    setModalVisible: Dispatch<SetStateAction<boolean>>;
    coachName: string;
    coachCode: string;
    onClickReRegisterTrainer: () => void;
};

const ModalReRegister = ({ modalVisible, setModalVisible, coachName, coachCode, onClickReRegisterTrainer }: Props) => {
    return (
        <ModalOverLay>
            <ModalInner>
                <Row style={{ alignItems: 'center', marginTop: 36, marginLeft: 60 }}>
                    <Head4 style={{ color: colors.BLUE_700 }}>트레이너 재등록하기</Head4>
                    <Body3Light style={{ marginLeft: 36, color: colors.WHITE_800 }}>
                        이전에 삭제된 트레이너를 다시 재등록 하시겠습니까?
                    </Body3Light>
                    <HoverImage
                        onClick={() => setModalVisible(false)}
                        src={I_POPUP_EXIT}
                        style={{ width: 52, height: 52, marginLeft: 335 }}
                    />
                </Row>

                <Row
                    style={{
                        width: 880,
                        height: 130,
                        borderRadius: 8,
                        backgroundColor: colors.WHITE_300,
                        boxShadow: '4px 4px 8px 1px #213E7B29 inset',
                        marginTop: 72,
                        marginLeft: 60,
                        alignItems: 'center',
                    }}
                >
                    <img src={I_EMPTY_PROFILE} style={{ width: 80, height: 80, marginLeft: 48 }} />
                    <Col style={{ marginLeft: 36 }}>
                        <Subtitle1Regular style={{ marginLeft: 12, color: colors.BLUE_900 }}>
                            강사 이름
                        </Subtitle1Regular>
                        <Row
                            style={{
                                width: 220,
                                height: 48,
                                borderRadius: 48,
                                backgroundColor: colors.WHITE_500,
                                marginTop: 6,
                                alignItems: 'center',
                                paddingLeft: 22,
                            }}
                        >
                            <Body1Regular style={{ color: colors.WHITE_900 }}>{coachName}</Body1Regular>
                        </Row>
                    </Col>
                    <Col style={{ marginLeft: 18 }}>
                        <Subtitle1Regular style={{ marginLeft: 12, color: colors.BLUE_900 }}>
                            강사 코드
                        </Subtitle1Regular>
                        <Row
                            style={{
                                width: 220,
                                height: 48,
                                borderRadius: 48,
                                backgroundColor: colors.WHITE_500,
                                marginTop: 6,
                                alignItems: 'center',
                                paddingLeft: 22,
                            }}
                        >
                            <Body1Regular style={{ color: colors.WHITE_900 }}>{coachCode}</Body1Regular>
                        </Row>
                    </Col>
                </Row>

                <NormalButton
                    text="재등록 확인"
                    style={{ marginTop: 54, marginLeft: 770 }}
                    onClick={onClickReRegisterTrainer}
                />
            </ModalInner>
        </ModalOverLay>
    );
};

export default ModalReRegister;
