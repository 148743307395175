import React from 'react';
import { Col, Row } from '../../../lib/utils';
import { Body1Regular, Body2Bold, Body2Regular, Body3Regular, Subtitle1Bold } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import { MENSTRUATIONAMOUNT, PMSPAINSTEP, PMSPAINTYPE, PMSTYPE } from '../../../lib/varialbe';

export enum PMSPainType {
    LOWER_ABDOMEN_PAIN = 'LOWER_ABDOMEN_PAIN',
    ABDOMINAL_DISTENSION = 'ABDOMINAL_DISTENSION',
    HEADACHE = 'HEADACHE',
    CHEST_PAIN = 'CHEST_PAIN',
    MUSCLE_PAIN = 'MUSCLE_PAIN',
    PELVIC_PAIN = 'PELVIC_PAIN',
    NONE = 'NONE',
}

export enum PMSType {
    EMOTIONAL_FLUCTUATIONS = 'EMOTIONAL_FLUCTUATIONS',
    APPETITE_CHANGES = 'APPETITE_CHANGES',
    CONSTIPATION = 'CONSTIPATION',
    DIARRHEA = 'DIARRHEA',
    COLD_SWEET = 'COLD_SWEET',
    FEVER_AND_CHILLS = 'FEVER_AND_CHILLS',
    FATIGUE = 'FATIGUE',
    NAUSEA_AND_VOMITING = 'NAUSEA_AND_VOMITING',
    NONE = 'NONE',
}

const PmsInfo = ({ scheduleReport }: any) => {
    const condition = scheduleReport?.condition;
    const PMSReports = condition?.PMSReports;

    const menstruationAmount = PMSReports?.menstruationAmount ?? '';
    const menstruationAmountText = MENSTRUATIONAMOUNT.find(
        (menstruation) => menstruation.code === menstruationAmount
    )?.value;

    //생리량 ,통증 유형, 불편함 정도, PMS
    const PMSPainType = PMSReports?.PMSPainType ?? ([] as PMSPainType[]);
    const PMSPainText =
        PMSPainType[0] === 'NONE'
            ? '없음'
            : (PMSPainType as PMSPainType[])
                  .map((pmsPainType: PMSPainType) => {
                      return PMSPAINTYPE.find((pms) => pms.code === pmsPainType)?.value;
                  })
                  .join('/ ') ?? '';
    const PMSLevel = PMSReports?.PMSLevel ?? 0;
    const PMSStepText = PMSPAINSTEP[PMSLevel - 1];
    const PMSType = PMSReports?.PMSType ?? ([] as PMSType[]);
    const PMSText =
        PMSType[0] === 'NONE'
            ? '없음'
            : (PMSType as PMSType[])
                  .map((pmsType: PMSType) => {
                      return PMSTYPE.find((pms) => pms.code === pmsType)?.value;
                  })
                  .join('/ ') ?? '';
    return (
        <Col style={{ marginTop: 32 }}>
            <Row style={{ alignItems: 'center' }}>
                <Body2Bold style={{ color: colors.LAVEL_3 }}>{'월경 유무 :'}</Body2Bold>
                <Body2Regular style={{ color: colors.LAVEL_2, marginLeft: 8 }}>{menstruationAmountText}</Body2Regular>
            </Row>

            <Row style={{ alignItems: 'center', marginTop: 16 }}>
                <div
                    style={{
                        width: 4,
                        height: 4,
                        borderRadius: 2,
                        backgroundColor: colors.WHITE_900,
                        marginRight: 4,
                        marginLeft: 4,
                    }}
                />
                <Body3Regular style={{ color: colors.LAVEL_2 }}>{`통증 유형 : ${PMSPainText}`}</Body3Regular>
            </Row>
            <Row style={{ alignItems: 'center', marginTop: 8 }}>
                <div
                    style={{
                        width: 4,
                        height: 4,
                        borderRadius: 2,
                        backgroundColor: colors.WHITE_900,
                        marginRight: 4,
                        marginLeft: 4,
                    }}
                />
                <Body3Regular
                    style={{ color: colors.LAVEL_2 }}
                >{`통증 정도 : ${PMSLevel}단계 (${PMSStepText})`}</Body3Regular>
            </Row>
            <Row style={{ alignItems: 'center', marginTop: 8 }}>
                <div
                    style={{
                        width: 4,
                        height: 4,
                        borderRadius: 2,
                        backgroundColor: colors.WHITE_900,
                        marginRight: 4,
                        marginLeft: 4,
                    }}
                />
                <Body3Regular style={{ color: colors.LAVEL_2 }}>{`PMS : ${PMSText}`}</Body3Regular>
            </Row>
        </Col>
    );
};

export default PmsInfo;
