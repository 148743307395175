import React, { useEffect, useState } from 'react';
import { Col, getItemWithExpiration } from '../../../lib/utils';
import ClassPassDetailHeader from './ClassPassDetailHeader';
import ClassPassDetailMembership from './ClassPassDetailMembership';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateMembershipTemplatesAPI } from '../../../api/memberShip';
import _ from 'lodash';
import NewClassPassHistoryComponent from './ClassPassHistory/NewClassPassHistoryComponent';
import { CodeValueType } from '../../../types/types';
import useSingleQuery from '../../../hook/useSingleQuery';
import {
    fetchGetOneMembershipTemplate,
    MembershipTemplateDataType,
} from '../../../services/useMembershipTemplateQueryService';
import { MemberMembershipDataType } from '../../../services/useMembershipQueryService';
import dayjs from 'dayjs';
import ModalMembershipChange from './ClassPassHistory/ModalMembershipChange';
import ModalRegisterPass from '../ModalRegisterPass/ModalRegisterPass';
import { fetchCoachNameList } from '../../../services/useCenterQueryService';
import useMultipleQueries from '../../../hook/useMultipleQueries';

type Props = {
    authObject: any;
    detailObject: any;
    onClickModifyPass: () => void;
    createAlert: any;
    onClickListPage: () => void;
};
const ClassPassDetail = ({ authObject, detailObject, onClickModifyPass, createAlert, onClickListPage }: Props) => {
    const queryClient = useQueryClient();
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [historyList, setHistoryList] = useState<MemberMembershipDataType[]>([]);
    // 선택된 수강권
    const [selectedHistoryList, setSelectedHistoryList] = useState<MemberMembershipDataType[]>([]);
    // 수강권 이전하기 모달
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    // 새로운 수강권 등록 모달
    const [isRegisterPassModal, setIsRegisterPassModal] = useState<boolean>(false);

    const [isAvailable, setIsAvailable] = useState<boolean>(detailObject?.isAvailable);

    const [firstSortType, setFirstSortType] = useState<CodeValueType>({ code: 'ALL', value: '전체 보기' });
    const [secondSortType, setSecondSortType] = useState<CodeValueType>({ code: 'RECENT', value: '최근 발급순' });
    const [thirdSortType, setThirdSortType] = useState<CodeValueType>({ code: 'all', value: '모든 강사' });
    const [coachNameList, setCoachNameList] = useState<Array<CodeValueType>>([{ code: 'all', value: '모든 강사' }]);

    const { refinedResults, isLoading } = useMultipleQueries([
        {
            fetchFn: fetchGetOneMembershipTemplate,
            params: { membershipTemplateId: detailObject?._id },
            enabled: !!detailObject?._id,
        },
        {
            fetchFn: fetchCoachNameList,
            params: { centerId: authObject?.centerId },
            enabled: !!authObject?.centerId,
        },
    ]);

    const membershipTemplateData = refinedResults?.[0]?.data as MembershipTemplateDataType;

    //coachList
    const coachList: CodeValueType<string>[] = refinedResults?.[1]?.data as CodeValueType<string>[];

    // 수강권 정보 수정
    const updateMembershipTemplates = useMutation({
        mutationFn: async (isAvailable: boolean) =>
            await updateMembershipTemplatesAPI(
                authorization,
                detailObject?._id,
                detailObject?.name,
                detailObject?.period,
                detailObject?.pricePerSchedule,
                detailObject?.totalPrice,
                detailObject?.totalSession,
                detailObject?.BGColor,
                detailObject?.memo,
                detailObject?.validDays,
                !isAvailable
            ),
        onSuccess: (res) => {
            if (res.status === 200) {
                queryClient.invalidateQueries({ queryKey: ['getDetailMembershipAPIQuery'] });
                queryClient.invalidateQueries({ queryKey: ['getMembershipTemplatesListAPI'] });
            }
        },
    });

    // 수강권 ON/OFF 클릭이벤트
    const onClickUpdatePermission = () => {
        updateMembershipTemplates.mutate(isAvailable);
        setIsAvailable(!isAvailable);
    };

    useEffect(() => {
        if (!!coachList) {
            coachList[0] = { code: 'all', value: '모든 강사' };
            setCoachNameList(coachList);
        }
    }, []);

    // 필터링
    useEffect(() => {
        // membershipTemplateData 에 memberships 가 있을때만 실행
        if (membershipTemplateData?.memberships && membershipTemplateData?.memberships.length > 0) {
            let historyList = [...membershipTemplateData.memberships];

            // firstSortType 필터링
            if (firstSortType.code === 'ACTIVE') {
                historyList = historyList.filter((membership) => membership.status === 'active');
            } else if (firstSortType.code === 'EXPIRED') {
                historyList = historyList.filter((membership) => membership.status === 'expired');
            }

            // secondSortType 정렬
            if (secondSortType.code === 'RECENT') {
                historyList.sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.expirationDate)));
            } else if (secondSortType.code === 'OLD') {
                historyList.sort((a, b) => dayjs(a.expirationDate).diff(dayjs(b.createdAt)));
            }
            //MANY : 총회차 많은순 FEW 총회차 적은순
            else if (secondSortType.code === 'MANY') {
                historyList.sort((a, b) => b.totalSession - a.totalSession);
            } else if (secondSortType.code === 'FEW') {
                historyList.sort((a, b) => a.totalSession - b.totalSession);
            }

            if (thirdSortType.code !== 'all') {
                historyList = historyList.filter((membership) => membership.coachId === thirdSortType.code);
            }

            // 필터링 및 정렬된 historyist를 설정
            setHistoryList(historyList);
        }
    }, [firstSortType, secondSortType, thirdSortType, membershipTemplateData]);
    return (
        <Col>
            <ClassPassDetailHeader onClickModifyPass={onClickModifyPass} onClickBack={onClickListPage} />
            <ClassPassDetailMembership
                detailObject={detailObject}
                isAvailable={isAvailable}
                onClickUpdatePermission={onClickUpdatePermission}
                isNoMembershipHistory={historyList.length === 0}
                createAlert={createAlert}
                onClickListPage={onClickListPage}
            />

            <NewClassPassHistoryComponent
                firstSortType={firstSortType}
                onFirstOptionChange={(item) => setFirstSortType(item)}
                secondSortType={secondSortType}
                onSecondOptionChange={(item) => setSecondSortType(item)}
                thirdSortType={thirdSortType}
                onThirdOptionChange={(item) => setThirdSortType(item)}
                coachNameList={coachNameList}
                historyList={historyList}
                onClickChangeMembership={(data) => {
                    setSelectedHistoryList(data);
                    setIsModalVisible(true);
                }}
                onClickReset={() => {
                    setSelectedHistoryList([]);
                    setFirstSortType({ code: 'ALL', value: '전체 보기' });
                    setSecondSortType({ code: 'RECENT', value: '최근 순' });
                    setThirdSortType({ code: 'all', value: '모든 강사' });
                }}
            />
            {isModalVisible && (
                <ModalMembershipChange
                    setIsVisible={setIsModalVisible}
                    onClickCreateNewMembership={() => setIsRegisterPassModal(true)}
                    beforeMembershipTemplate={detailObject}
                    membershipIds={selectedHistoryList.map((item) => item._id)}
                    onPatchRelocateMembershipSuccess={() => {
                        createAlert('수강권 이전에 성공했습니다 !', '이전된 수강권에서 정보를 확인해주세요', false);
                        setSelectedHistoryList([]);
                        queryClient.invalidateQueries({ queryKey: ['getDetailMembershipAPIQuery'] });
                    }}
                />
            )}
            {isRegisterPassModal && (
                <ModalRegisterPass
                    modalVisible={isRegisterPassModal}
                    setModalVisible={setIsRegisterPassModal}
                    createAlert={createAlert}
                />
            )}
        </Col>
    );
};

export default ClassPassDetail;
