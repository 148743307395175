import { useEffect, useRef, useState } from 'react';
import { Col, Row } from '../../../lib/utils';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { getDailyScheduleAPI } from '../../../api/schedule';
import { dailySchedulesCountDummyData, dailySchedulesDummyData } from '../../DailySchedule/DummyData';
import CoachList from './CoachList';
import ScheduleTableLayout from './ScheduleTableLayout';
import ScheduleTableInnerBox from './ScheduleTableInnerBox';
import ModalRegisterSchedule from '../../DailySchedule/ModalRegisterSchedule/ModalRegisterSchedule';
import { I_FLOATING } from '../../../types/images';
import GridShadow from '../GridShadow';

const DailySchedule = ({
    setIsLoading,
    grid,
    currentDate,
    authorization,
    authObject,
    createAlert,
    createTwoButtonAlert,
    selectedDay,
    setSelectedDay,
    selectedSchedule,
    setSelectedSchedule,
    onClickFloatingButton,
    onClickSchedule,
    onClickRequest,
    onClickEtcOffSchedule,
    onClickApproveScheduleRequest,
    onClickRejectScheduleRequest,
    scrollY,
    dailyCurrentData,
    gridData,
    onClickHover,
    currentPage,
    endPage,
    handleNextPage,
    handlePrevPage,
}: any) => {
    return (
        <Col style={{ marginTop: 88 }}>
            <CoachList
                dailyCurrentData={dailyCurrentData}
                scrollY={scrollY}
                currentPage={currentPage}
                endPage={endPage}
                handleNextPage={handleNextPage}
                handlePrevPage={handlePrevPage}
            />
            <ScheduleTableLayout
                dailyCurrentData={dailyCurrentData}
                gridData={gridData}
                scrollY={scrollY}
                onClickHover={onClickHover}
                selectedDay={selectedDay}
            />
            <ScheduleTableInnerBox
                onClickSchedule={onClickSchedule}
                onClickRequest={onClickRequest}
                onClickEtcOffSchedule={onClickEtcOffSchedule}
                onClickRejectScheduleRequest={onClickRejectScheduleRequest}
                onClickApproveScheduleRequest={onClickApproveScheduleRequest}
                dailyCurrentData={dailyCurrentData}
                scrollY={scrollY}
            />
            {/* 음영처리 */}
            <GridShadow currentDate={currentDate} selectedDay={selectedDay} />
        </Col>
    );
};

export default DailySchedule;
