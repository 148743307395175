import axios, { AxiosResponse } from 'axios';
import { createDataObject } from '../lib/utils';

export /**
 * @method get
 * @brief 트레이너 등록창에서 조회 API
 */
const getAddTrainerInfoAPI = async (centerId: string, inviteCode: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/centers/check-coach/${centerId}/${inviteCode}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 409:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method patch
 * @brief 트레이너 연결 API
 */
const connectingTrainerAPI = async (centerId: string, coachId: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER}/v1/centers/connect-coach/${centerId}`,
            headers: { authorization: accessToken },
            data: {
                coachId,
            },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 409:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method get
 * @brief 트레이너 관리 / 트레이너 리스트 조회 API
 */
const getTrainerListAPI = async (
    centerId: string,
    sort: 'registrationasc' | 'seniorityasc' | 'membercountdesc' | 'alphabetasc',
    accessToken: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/centers/coach-list/${centerId}?sort=${sort}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method get
 * @brief 트레이너 관리 / 트레이너 정보 조회 API
 */
const getEmployeeInfoAtManageAPI = async (employeeId: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v2/employees/profile/${employeeId}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method get
 * @brief 트레이너 관리 / 담당회원 그래프 조회 API
 */
const getTrainerMemberCountAPI = async (employeeId: string, date: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/employees/member-count/${employeeId}?date=${date}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method get
 * @brief 트레이너 관리 / 식단 관리회원 그래프 조회 API
 */
const getTrainerMemberMealCountAPI = async (employeeId: string, date: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/employees/diet-member-count/${employeeId}?date=${date}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method get
 * @brief 트레이너 관리 / 회원권 등록 리스트 조회 API
 */
const getTrainerMembershipListAPI = async (employeeId: string, date: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/employees/membership-registration-list/${employeeId}?date=${date}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method patch
 * @brief 트레이너 프로필 수정 API
 */
const modifyTrainerProfileAPI = async ({
    accessToken,
    employeeId,
    ...other
}: ModifyTrainerProfileParamsType & { accessToken: string }): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    const data = await createDataObject({ ...other });
    try {
        response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER}/v2/employees/profile/${employeeId}`,
            headers: { authorization: accessToken },
            data: { ...data },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method get
 * @brief 트레이너 관리 / 모달 담당회원 리스트 조회 API
 */
const getTrainerMemberListChargeAPI = async (employeeId: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/employees/member-list/${employeeId}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method patch
 * @brief 트레이너 삭제 API
 */
const deleteTrainerAPI = async (centerId: string, employeeId: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER}/v1/centers/disconnect-coach/${centerId}`,
            headers: { authorization: accessToken },
            data: {
                employeeId,
            },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 409:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method patch
 * @brief 깅사의 수강권 권한 변경
 */
const updateTrainerPermissionAPI = async ({
    centerId,
    coachId,
    isMembershipPermissionAll,
    accessToken,
}: UpdateTrainerPermissionParamsType & { accessToken: string }): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER}/v1/centers/${centerId}/membership-permission`,
            headers: { authorization: accessToken },
            data: {
                coachId,
                isMembershipPermissionAll,
            },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 409:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method get
 * @brief 강사의 담당회원의 수업기록 조회
 */
const getSpecificMemberClassHistoryAPI = async (
    accessToken: string,
    employeeId: string,
    date: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v2/employees/schedule-history/${employeeId}?date=${date}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

//updateTrainerPermissionAPI param
// PatchDisconnectMemberParamsType

export type UpdateTrainerPermissionParamsType = {
    centerId: string;
    coachId: string;
    isMembershipPermissionAll: boolean;
};

export type ModifyTrainerProfileParamsType = {
    employeeId: string;
    birthday: string;
    gender: string;
    email: string;
    phoneNumber: string;
    startDate: string;
    jobPosition: string;
};
