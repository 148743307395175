import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import {
    dailyDummyData,
    dailyMaxCountDummy,
    monthlyDummyData,
    monthlyMaxCountDummy,
    weeklyDummyData,
    weeklyMaxCountDummy,
} from './DummyData';
import { Col, getCurrentDayWeekNumber, Row } from '../../../../lib/utils';
import MemberListGraphHeader from './MemberListGraphHeader';
import { colors } from '../../../../lib/colors';
import MemberListDailyGraph from './MemberListDailyGraph';
import MemberListWeeklyGraph from './MemberListWeeklyGraph';
import MemberListMonthlyGraph from './MemberListMonthlyGraph';
import { useQuery } from '@tanstack/react-query';
import { getTrainerMemberCountAPI } from '../../../../api/employee';
import { Body2Bold, Body2Regular } from '../../../../lib/font';

type Props = {
    authorization: any;
    employeeId: string;
    authObject: any;
};
const MemberListGraph = ({ authorization, employeeId, authObject }: Props) => {
    // 초기값 설정
    const [currentDate, setCurrentDate] = useState(dayjs());
    const [dailyData, setDailyData] = useState<any>(null);
    const [weeklyData, setWeeklyData] = useState<any>(null);
    const [monthlyData, setMonthlyData] = useState<any>(null);

    const [dailyMaxCount, setDailyMaxCount] = useState<number>(0);
    const [weeklyMaxCount, setWeeklyMaxCount] = useState<number>(0);
    const [monthlyMaxCount, setMonthlyMaxCount] = useState<number>(0);

    // 몇주차 표시
    const [weekNumber, setWeekNumber] = useState<number>(0);
    const [isToday, setIsToday] = useState<boolean>(true);
    const [isWeek, setIsWeek] = useState<boolean>(false);
    const [isMonth, setIsMonth] = useState<boolean>(false);

    const settingDate = (type: string) => {
        setCurrentDate(dayjs());
        if (type === 'today') {
            setIsToday(true);
            setIsWeek(false);
            setIsMonth(false);
        }

        if (type === 'thisWeek') {
            setIsToday(false);
            setIsWeek(true);
            setIsMonth(false);
        }
        if (type === 'thisMonth') {
            setIsToday(false);
            setIsWeek(false);
            setIsMonth(true);
        }
    };

    const onClickAddDate = () => {
        if (isToday) {
            const newDate = dayjs(currentDate).add(1, 'week');
            setCurrentDate(newDate);
        }

        if (isWeek) {
            const newDate = dayjs(currentDate).add(1, 'month');
            setCurrentDate(newDate);
        }

        if (isMonth) {
            const newDate = dayjs(currentDate).add(1, 'year');
            setCurrentDate(newDate);
        }
    };

    const onClickSubtractDate = () => {
        if (isToday) {
            const newDate = dayjs(currentDate).subtract(1, 'week');
            setCurrentDate(newDate);
        }

        if (isWeek) {
            const newDate = dayjs(currentDate).subtract(1, 'month');
            setCurrentDate(newDate);
        }

        if (isMonth) {
            const newDate = dayjs(currentDate).subtract(1, 'year');
            setCurrentDate(newDate);
        }
    };

    const CustomToolTip = ({ id, value, color, data }: any) => (
        <Col
            style={{
                width: 162,
                height: 96,
                background: 'rgba(20, 20, 23, 0.46)',
                borderRadius: '16px 16px 16px 16px',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Row style={{ width: 96, alignItems: 'center' }}>
                <Row style={{ width: 16, height: 16, borderRadius: 2, backgroundColor: colors.WHITE_600 }} />
                <Body2Regular style={{ marginLeft: 8, color: colors.WHITE_100 }}>
                    {'전체 :'}
                    <Body2Bold>{data.totalMemberCount}</Body2Bold>
                </Body2Regular>
            </Row>
            <Row style={{ width: 96, alignItems: 'center' }}>
                <Row style={{ width: 32, height: 16, borderRadius: 2, backgroundColor: colors.BLUE_500 }} />
                <Body2Regular style={{ marginLeft: 8, color: colors.WHITE_100 }}>
                    {'수강중 :'}
                    <Body2Bold>{data.activeMemberCount}</Body2Bold>
                </Body2Regular>
            </Row>
        </Col>
    );

    useEffect(() => {
        if (currentDate) {
            setWeekNumber(getCurrentDayWeekNumber(currentDate));
        }
    }, [currentDate]);

    //  트레이너 담당회원 조회 API : (GET)
    const getTrainerMemberCount = useQuery(
        ['getTrainerMemberCountAPI', employeeId, currentDate],
        async () => await getTrainerMemberCountAPI(employeeId, dayjs(currentDate).format('YYYY-MM-DD'), authorization),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const daily = res.data.daily;
                    const weekly = res.data.weekly;
                    const monthly = res.data.monthly;

                    const dailyMaxCount = res.data.dailyMaxCount;
                    const weeklyMaxCount = res.data.weeklyMaxCount;
                    const monthlyMaxCount = res.data.monthlyMaxCount;

                    setDailyMaxCount(dailyMaxCount);
                    setWeeklyMaxCount(weeklyMaxCount);
                    setMonthlyMaxCount(monthlyMaxCount);

                    setDailyData(daily);
                    setWeeklyData(weekly);
                    setMonthlyData(monthly);
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!employeeId && !!!authObject?.testAccount,
            // refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    // 더미데이터 일간 세팅
    useEffect(() => {
        if (authObject?.testAccount && dailyDummyData && dailyMaxCountDummy) {
            setDailyData(dailyDummyData);
            setDailyMaxCount(dailyMaxCountDummy);
        }
    }, [dailyDummyData, dailyMaxCountDummy]);

    // 더미데이터 주간 세팅
    useEffect(() => {
        if (authObject?.testAccount && weeklyDummyData && weeklyMaxCountDummy) {
            setWeeklyData(weeklyDummyData);
            setWeeklyMaxCount(weeklyMaxCountDummy);
        }
    }, [weeklyDummyData, weeklyMaxCountDummy]);

    // 더미데이터 월간 세팅
    useEffect(() => {
        if (authObject?.testAccount && monthlyDummyData && monthlyMaxCountDummy) {
            setMonthlyData(monthlyDummyData);
            setMonthlyMaxCount(monthlyMaxCountDummy);
        }
    }, [monthlyDummyData, monthlyMaxCountDummy]);

    return (
        <Col
            style={{
                width: 1192,
                height: 612,
                marginTop: 24,
                borderRadius: 16,
                backgroundColor: colors.WHITE_50,
                border: '1px solid #C3D4F4',
            }}
        >
            <MemberListGraphHeader
                isToday={isToday}
                isWeek={isWeek}
                isMonth={isMonth}
                onClickSubtractDate={onClickSubtractDate}
                onClickAddDate={onClickAddDate}
                settingDate={settingDate}
                currentDate={currentDate}
                weekNumber={weekNumber}
                setCurrentDate={setCurrentDate}
            />

            <div
                style={{
                    width: 1192,
                    height: 614,
                    marginTop: 25,
                }}
            >
                {isToday && dailyData && (
                    <MemberListDailyGraph
                        dailyData={dailyData}
                        dailyMaxCount={dailyMaxCount}
                        CustomToolTip={CustomToolTip}
                    />
                )}
                {isWeek && weeklyData && (
                    <MemberListWeeklyGraph
                        weeklyData={weeklyData}
                        weeklyMaxCount={weeklyMaxCount}
                        CustomToolTip={CustomToolTip}
                    />
                )}
                {isMonth && monthlyData && (
                    <MemberListMonthlyGraph
                        monthlyData={monthlyData}
                        monthlyMaxCount={monthlyMaxCount}
                        CustomToolTip={CustomToolTip}
                    />
                )}
            </div>
        </Col>
    );
};

export default MemberListGraph;
