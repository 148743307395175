import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { Col, Row } from '../../lib/utils';
import { Body2Bold, Body2Regular, Body3Light, Subtitle1Bold } from '../../lib/font';
import { I_POPUP_EXIT } from '../../types/images';
import { colors } from '../../lib/colors';

import ColorSquareButton from '../Button/ColorSquareButton';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalInner = styled.div`
    background: #f5f9ff;
    width: 880px;
    height: 450px;
    border-radius: 8px;
    box-shadow: 8px 8px 24px 0px #00000052;
    display: flex;
    flex-direction: column;
`;

const HoverImage = styled.img`
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

type Props = {
    setModalVisible: Dispatch<SetStateAction<boolean>>;
    navigate: any;
    onClickCancelSubscriptionWithdrawal: () => void;
    authObject: any;
};

const ModalCancelWithDrawal = ({
    setModalVisible,
    navigate,
    onClickCancelSubscriptionWithdrawal,
    authObject,
}: Props) => {
    return (
        <ModalOverLay>
            <ModalInner>
                <Col>
                    <Row>
                        <Subtitle1Bold style={{ color: colors.LAVEL_4, marginLeft: 48, marginTop: 36 }}>
                            {'구독 취소 철회'}
                        </Subtitle1Bold>
                        <HoverImage
                            src={I_POPUP_EXIT}
                            style={{ width: 32, height: 32, marginTop: 16, marginLeft: 640 }}
                            onClick={() => setModalVisible(false)}
                        />
                    </Row>

                    <Row style={{ alignItems: 'center', marginLeft: 56, marginTop: 32, marginBottom: 24 }}>
                        <Body2Bold style={{ color: colors.LAVEL_4 }}>사용 플랜 :</Body2Bold>
                        <Body2Bold style={{ color: colors.LAVEL_4, marginLeft: 16 }}>
                            Rappo Manager Plan ({authObject?.planLimitCount}인)
                        </Body2Bold>
                    </Row>

                    <Body2Bold style={{ color: colors.LAVEL_3, marginLeft: 56 }}>
                        지금 구독 취소 철회를 진행 하시면...
                    </Body2Bold>

                    <Row style={{ alignItems: 'center', marginLeft: 56, marginTop: 24 }}>
                        <div style={{ width: 3, height: 3, borderRadius: 1.5, backgroundColor: colors.LAVEL_3 }} />
                        <Body3Light style={{ color: colors.LAVEL_3, marginLeft: 6 }}>
                            {'기존 결제일에 등록된 카드 (결제수단)으로 자동 결제가 진행됩니다.'}
                        </Body3Light>
                    </Row>
                    <Row style={{ alignItems: 'center', marginLeft: 56 }}>
                        <div style={{ width: 3, height: 3, borderRadius: 1.5, backgroundColor: colors.LAVEL_3 }} />
                        <Body3Light style={{ color: colors.LAVEL_3, marginLeft: 6 }}>
                            철회 이전의 플랜을 그대로 진행하게 되며, 플랜 변경을 원하신다면
                        </Body3Light>
                    </Row>

                    <Body3Light style={{ color: colors.LAVEL_3, marginLeft: 65 }}>
                        {'[마이페이지 > 구독플랜 > 구독플랜 변경하기] 과정을 따라주세요.'}
                    </Body3Light>
                    <Row style={{ alignItems: 'center', marginLeft: 56, marginBottom: 22 }}>
                        <div style={{ width: 3, height: 3, borderRadius: 1.5, backgroundColor: colors.LAVEL_3 }} />
                        <Body3Light style={{ color: colors.LAVEL_3, marginLeft: 6 }}>
                            {'서비스 사용 중 환불은 불가능하며, 마지막 결제일 기준으로 30일간 이용 가능합니다.'}
                        </Body3Light>
                    </Row>

                    <Body2Regular style={{ color: colors.POINT_1, marginLeft: 56 }}>
                        라포 매니저를 다시 이용하시겠습니가?
                    </Body2Regular>

                    <ColorSquareButton
                        text="구독 해지 철회"
                        size="middle"
                        callBack={onClickCancelSubscriptionWithdrawal}
                        customStyle={{
                            marginLeft: 700,
                            marginTop: 20,
                        }}
                    />
                </Col>
            </ModalInner>
        </ModalOverLay>
    );
};

export default ModalCancelWithDrawal;
