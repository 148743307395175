import React from 'react';
import { Head4 } from '../../lib/font';
import { Row } from '../../lib/utils';
import NewRoundedDropDown from '../DropDown/NewRoundedDropDown';

let sortOptions: any = [
    { code: 'registrationasc', value: '등록순' },
    { code: 'seniorityasc', value: '연차순' },
    { code: 'membercountdesc', value: '회원 많은 순' },
    { code: 'alphabetasc', value: '가나다 순' },
];

type Props = {
    coachSortType: any;
    setCoachSortType: any;
};

const TrainerManageHeader = ({ coachSortType, setCoachSortType }: Props) => {
    return (
        <Row style={{ width: 1192, paddingLeft: 8, justifyContent: 'space-between', alignItems: 'center' }}>
            <Head4 style={{ width: 216 }}>{`강사 리스트`}</Head4>

            <NewRoundedDropDown
                datas={sortOptions}
                selectData={coachSortType}
                onClickSort={(item) => setCoachSortType(item)}
            />
        </Row>
    );
};

export default TrainerManageHeader;
