import React from 'react';
import { Row } from '../../lib/utils';
import { I_LEFT_ANGLE, I_LEFT_TWO_ANGLE, I_RIGHT_ANGLE, I_RIGHT_TWO_ANGLE } from '../../types/images';
import { CaptionLight, CaptionRegular } from '../../lib/font';
import { colors } from '../../lib/colors';

const CommunicationPaging = ({
    filterList,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    onClickPrevPage,
    onClickNextPage,
    onClickStartPage,
    onClickLastPage,
    totalNumberCommunication,
}: any) => {
    const totalPages = filterList && Math.ceil(filterList.length / itemsPerPage);
    const pagesToShow = totalPages && Math.min(5, totalPages); // 최대 5개의 페이지만 표시

    const renderPages = () => {
        const pages = [];
        const startPage = Math.floor((currentPage - 1) / 5) * 5 + 1; // 페이지 숫자 범위 시작

        for (let i = startPage; i < startPage + pagesToShow; i++) {
            if (i > totalPages) break; // 페이지 수를 초과하면 중단

            pages.push(
                <button
                    style={{
                        width: 32,
                        height: 32,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 8,
                        backgroundColor: currentPage === i ? colors.BLUE_50 : colors.WHITE_200,
                    }}
                    key={i}
                    onClick={() => setCurrentPage(i)}
                    disabled={currentPage === i}
                >
                    <CaptionRegular
                        style={{
                            color: currentPage === i ? colors.POINT_1 : colors.LAVEL_2,
                        }}
                    >
                        {i}
                    </CaptionRegular>
                </button>
            );
        }

        if (pages.length === 5) {
            pages.push(
                <CaptionRegular
                    key={'...'}
                    style={{
                        width: 32,
                        height: 32,

                        color: colors.WHITE_700,
                    }}
                >
                    {'...'}
                </CaptionRegular>
            );
        }

        return pages;
    };

    return (
        <Row
            style={{
                width: 1192,
                justifyContent: 'space-between',
                marginTop: 24,
                alignItems: 'center',
                paddingLeft: 8,
                paddingRight: 8,
            }}
        >
            <CaptionLight
                style={{ color: colors.LAVEL_1 }}
            >{`회원 소통 전체 : ${totalNumberCommunication}건`}</CaptionLight>
            <Row>
                <button
                    style={{
                        width: 32,
                        height: 32,
                        marginRight: 8,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    onClick={onClickStartPage}
                    disabled={currentPage === 1}
                >
                    <img style={{ width: 24, height: 24 }} src={I_LEFT_TWO_ANGLE} />
                </button>
                <button
                    style={{
                        width: 32,
                        height: 32,
                        marginRight: 16,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    onClick={onClickPrevPage}
                    disabled={currentPage === 1}
                >
                    <img style={{ width: 24, height: 24 }} src={I_LEFT_ANGLE} />
                </button>

                {!filterList || totalPages === 0 ? (
                    <Row
                        style={{
                            width: 32,
                            height: 32,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <CaptionRegular
                            style={{
                                color: colors.WHITE_700,
                            }}
                        >
                            {1}
                        </CaptionRegular>
                    </Row>
                ) : (
                    renderPages()
                )}

                <button
                    style={{
                        width: 32,
                        height: 32,
                        marginLeft: 16,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    onClick={onClickNextPage}
                    disabled={currentPage === totalPages || totalPages === 0 || !filterList}
                >
                    <img style={{ width: 24, height: 24 }} src={I_RIGHT_ANGLE} />
                </button>
                <button
                    style={{
                        width: 32,
                        height: 32,
                        marginLeft: 8,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    onClick={onClickLastPage}
                    disabled={currentPage === totalPages || totalPages === 0 || !filterList}
                >
                    <img style={{ width: 24, height: 24 }} src={I_RIGHT_TWO_ANGLE} />
                </button>
            </Row>

            <CaptionLight style={{ color: colors.LAVEL_1 }}>{`페이지 당 : 10건`}</CaptionLight>
        </Row>
    );
};

export default CommunicationPaging;
