import React from 'react';
import { Col, Row } from '../../../lib/utils';
import { Body2Regular, Body3Regular, CaptionLight } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import InputSquareDropDown from '../../DropDown/InputSquareDropDown';
import dayjs from 'dayjs';

const CreateOneMoreScheduleLeft = ({
    coachOption,
    coach,
    handleCoachSelect,
    selectedDay,
    setIsCalendar,
    timeList,
    startTime,
    handleStartTimeSelect,
    endTime,
    handleEndTimeSelect,
    onClickAddTime,
    onClickSubTime,
}: any) => {
    return (
        <Col style={{ width: 470 }}>
            <Col style={{ width: 382, height: 88, justifyContent: 'space-between' }}>
                <Body3Regular style={{ color: colors.LAVEL_4 }}> {'담당 강사'}</Body3Regular>

                {coachOption && (
                    <InputSquareDropDown
                        selectedGender={coach}
                        genderOptions={coachOption}
                        handleGenderSelect={handleCoachSelect}
                        customStyle={{ width: 382 }}
                        zIndex={100}
                        disabled={true}
                    />
                )}
            </Col>

            <Col style={{ width: 382, height: 88, justifyContent: 'space-between', marginTop: 64 }}>
                <Body3Regular style={{ color: colors.LAVEL_4 }}> {'날짜'}</Body3Regular>

                <InputSquareDropDown
                    selectedGender={coach}
                    genderOptions={coachOption}
                    handleGenderSelect={handleCoachSelect}
                    customStyle={{ width: 382 }}
                    disabled={true}
                    isJustClick={true}
                    justClickString={dayjs(selectedDay).format('YYYY년 M월 D일(ddd)')}
                    //justClickCallBack={() => setIsCalendar(true)}
                    zIndex={90}
                />
            </Col>

            <Col style={{ width: 470, height: 162, marginTop: 64 }}>
                <Body3Regular style={{ color: colors.LAVEL_4 }}> {'시간'}</Body3Regular>

                <Row style={{ width: 470, alignItems: 'center', justifyContent: 'space-between', marginTop: 16 }}>
                    {timeList && (
                        <InputSquareDropDown
                            selectedGender={startTime}
                            genderOptions={timeList}
                            handleGenderSelect={handleStartTimeSelect}
                            isDefaultScroll={true}
                            disabled={true}
                        />
                    )}

                    <Body2Regular style={{ color: colors.BLUE_500 }}>~</Body2Regular>
                    {timeList && (
                        <InputSquareDropDown
                            selectedGender={endTime}
                            genderOptions={timeList}
                            handleGenderSelect={handleEndTimeSelect}
                            isDefaultScroll={true}
                            disabled={true}
                        />
                    )}
                </Row>
            </Col>
        </Col>
    );
};

export default CreateOneMoreScheduleLeft;
