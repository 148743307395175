import { Subtitle1Bold } from "../../lib/font";
import styled, { CSSProperties } from "styled-components";
import { I_BACK } from "../../types/images";
import { colors } from "../../lib/colors";
import SvgIcon from "../SvgIcon";

const NormalButtonWrap = styled.button`
  width: 180px;
  height: 60px;
  border-radius: 48px;
  box-shadow: 1px 1px 8px 0px #2b529d2e;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(237, 244, 255, 1);
  color: ${(props) =>
    props.disabled ? "rgba(165, 171, 181, 1)" : "rgba(26, 48, 93, 1)"};
  &:not([disabled]):hover {
    background-color: #e9f1ff;
    border-width: 2;
    border-color: #7da1e8;
    color: #3869c9;
  }
  &:not([disabled]):active {
    transform: scale(0.98);
    background-color: #648fe4;
    color: #fdfeff;
    box-shadow: none;
  }
`;

type Props = {
  onClick?: any;
  disabled?: boolean;
  style?: CSSProperties;
};

const BackButton = ({ onClick, disabled, style }: Props) => {
  return (
    <NormalButtonWrap
      type="button"
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      <SvgIcon
        name={"SvgLeftStrokeArrow"}
        stroke={colors.primary}
        size={14}
        fill="none"
        className="mr-[16px]"
      />
      <Subtitle1Bold>뒤로가기</Subtitle1Bold>
    </NormalButtonWrap>
  );
};

export default BackButton;
