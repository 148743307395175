import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import ScheduleHistoryMemberCancelHeader from './ScheduleHistoryMemberCancelHeader';
import { useQuery } from '@tanstack/react-query';
import { getMemberCancelScheduleListAPI } from '../../../api/schedule';
import ScheduleHistoryMemberCancelDaily from './ScheduleHistoryMemberCancelDaily';
import { Col, getCurrentDayWeekNumber } from '../../../lib/utils';
import ScheduleHistoryMemberCancelWeekly from './ScheduleHistoryMemberCancelWeekly';
import ScheduleHistoryMemberCancelMonthly from './ScheduleHistoryMemberCancelMonthly';
import { scheduleHistoryMemberCancelDummyData } from '../../../lib/dummyData';
import { colors } from '../../../lib/colors';
import TopTableOfMemberCancelHistory from './TopTableOfMemberCancelHistory';

const ScheduleHistoryMemberCancel = ({ authorization, authObject }: any) => {
    // 초기값 설정
    const [currentDate, setCurrentDate] = useState(dayjs());
    const [isToday, setIsToday] = useState<boolean>(true);
    const [isWeek, setIsWeek] = useState<boolean>(false);
    const [isMonth, setIsMonth] = useState<boolean>(false);

    // 몇주차 표시
    const [weekNumber, setWeekNumber] = useState<number>(0);

    // 당일, 이번주, 이번달 수업리스트
    const [thisDayList, setThisDayList] = useState<any>(null);
    const [thisWeekList, setThisWeekList] = useState<any>(null);
    const [thisMonthList, setThisMonthList] = useState<any>(null);

    const [cancelCount, setCancelCount] = useState<number>(0);

    const onClickAddDate = () => {
        if (isToday) {
            const newDate = dayjs(currentDate).add(1, 'day');
            setCurrentDate(newDate);
        }

        if (isWeek) {
            const newDate = dayjs(currentDate).add(1, 'week');
            setCurrentDate(newDate);
        }

        if (isMonth) {
            const newDate = dayjs(currentDate).add(1, 'month');
            setCurrentDate(newDate);
        }
    };

    const onClickSubtractDate = () => {
        if (isToday) {
            const newDate = dayjs(currentDate).subtract(1, 'day');
            setCurrentDate(newDate);
        }

        if (isWeek) {
            const newDate = dayjs(currentDate).subtract(1, 'week');
            setCurrentDate(newDate);
        }

        if (isMonth) {
            const newDate = dayjs(currentDate).subtract(1, 'month');
            setCurrentDate(newDate);
        }
    };

    const settingDate = (type: string) => {
        setCurrentDate(dayjs());
        if (type === 'today') {
            setIsToday(true);
            setIsWeek(false);
            setIsMonth(false);
        }

        if (type === 'thisWeek') {
            setIsToday(false);
            setIsWeek(true);
            setIsMonth(false);
        }

        if (type === 'thisMonth') {
            setIsToday(false);
            setIsWeek(false);
            setIsMonth(true);
        }
    };

    useEffect(() => {
        if (currentDate) {
            setWeekNumber(getCurrentDayWeekNumber(currentDate));
        }
    }, [currentDate]);

    //  회원의 수업 취소 리스트 조회 : (GET)
    const getMemberCancelScheduleList = useQuery(
        ['getMemberCancelScheduleListAPI', currentDate],
        async () =>
            await getMemberCancelScheduleListAPI(
                authObject.centerId,
                dayjs(currentDate).format('YYYY-MM-DD'),
                authorization
            ),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const thisDay = res.data.thisDay;
                    const thisWeek = res.data.thisWeek;
                    const thisMonth = res.data.thisMonth;
                    setThisDayList(thisDay);
                    setThisWeekList(thisWeek);
                    setThisMonthList(thisMonth);

                    // thisDay && thisDay.length > 0 && setCancelCount(thisDay.length);
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!!authObject?.testAccount,
            // refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    // 더미데이터 세팅
    useEffect(() => {
        if (authObject?.testAccount && scheduleHistoryMemberCancelDummyData) {
            const thisDay = scheduleHistoryMemberCancelDummyData.thisDay;
            const thisWeek = scheduleHistoryMemberCancelDummyData.thisWeek;
            const thisMonth = scheduleHistoryMemberCancelDummyData.thisMonth;
            setThisDayList(thisDay);
            setThisWeekList(thisWeek);
            setThisMonthList(thisMonth);

            thisDay && thisDay.length > 0 && setCancelCount(thisDay.length);
        }
    }, [scheduleHistoryMemberCancelDummyData]);

    // 취소 횟수 정제
    useEffect(() => {
        if (isToday && thisDayList) {
            setCancelCount(thisDayList.length);
        }

        if (isWeek && thisWeekList) {
            setCancelCount(thisWeekList.length);
        }

        if (isMonth && thisMonthList) {
            setCancelCount(thisMonthList.length);
        }
    }, [isToday, thisDayList, isWeek, thisWeekList, isMonth, thisMonthList]);

    return (
        <Col
            style={{
                width: 1192,
                minHeight: 494,
                marginTop: 24,
                marginBottom: 100,

                borderRadius: 16,
                backgroundColor: colors.WHITE_50,
                border: '1px solid #C3D4F4',
            }}
        >
            <ScheduleHistoryMemberCancelHeader
                currentDate={currentDate}
                onClickSubtractDate={onClickSubtractDate}
                onClickAddDate={onClickAddDate}
                isToday={isToday}
                isWeek={isWeek}
                isMonth={isMonth}
                settingDate={settingDate}
                weekNumber={weekNumber}
                setCurrentDate={setCurrentDate}
                cancelCount={cancelCount}
            />
            <Col
                style={{
                    width: 1100,

                    borderRadius: 8,

                    marginTop: 32,
                    alignSelf: 'center',
                }}
            >
                <TopTableOfMemberCancelHistory />
                {thisDayList && isToday && <ScheduleHistoryMemberCancelDaily thisDayList={thisDayList} />}
                {thisWeekList && isWeek && <ScheduleHistoryMemberCancelWeekly thisWeekList={thisWeekList} />}
                {thisMonthList && isMonth && <ScheduleHistoryMemberCancelMonthly thisMonthList={thisMonthList} />}
            </Col>
        </Col>
    );
};

export default ScheduleHistoryMemberCancel;
