import { Col, Row } from '../../lib/utils';
import { colors } from '../../lib/colors';
import { I_EMPTY_PROFILE } from '../../types/images';
import { Body1Regular, Body2Bold, Body2Regular, Body3Regular, Subtitle1Regular } from '../../lib/font';
import NormalButton from '../Button/NormalButton';
import WhiteSquareButton from '../Button/WhiteSquareButton';
import ColorSquareButton from '../Button/ColorSquareButton';
type Props = {
    coachInviteCode: string;
    coachName: string;
    coachProfileImageUrl: string;
    onClickNextStep: () => void;
    onClickPrevStep: () => void;
};

const ModalAddTrainerBody3 = ({
    coachInviteCode,
    coachName,
    coachProfileImageUrl,
    onClickNextStep,
    onClickPrevStep,
}: Props) => {
    return (
        <Col>
            <Body3Regular style={{ color: colors.LAVEL_3, marginLeft: 48, marginTop: 32 }}>
                {'우리 센터에 소속된 강사님이 맞으시다면 등록을 완료해주세요!'}
            </Body3Regular>
            <Row
                style={{
                    width: 410,
                    height: 120,
                    borderRadius: 16,
                    border: '1px solid #7DA1E8',
                    marginLeft: 48,
                    marginTop: 32,

                    padding: 24,
                    alignItems: 'center',
                }}
            >
                <img style={{ width: 72, height: 72 }} src={I_EMPTY_PROFILE} />

                <Col style={{ height: 66, justifyContent: 'space-between', marginLeft: 16 }}>
                    <Body2Bold style={{ color: colors.LAVEL_4 }}>{`${coachName} 강사님`}</Body2Bold>
                    <Body2Regular style={{ color: colors.LAVEL_3 }}>{`강사코드 : ${coachInviteCode}`}</Body2Regular>
                </Col>
            </Row>

            <Row style={{ width: 312, justifyContent: 'space-between', marginTop: 28, marginLeft: 536 }}>
                <WhiteSquareButton text="이전" callBack={onClickPrevStep} size="middle" />
                <ColorSquareButton text="완료" callBack={onClickNextStep} size="middle"></ColorSquareButton>
            </Row>
        </Col>
    );
};

export default ModalAddTrainerBody3;
