import { useState } from 'react';
import SvgIcon from '../SvgIcon';
import { colors } from '../../lib/colors';

type InputProps = {
    value: string | number;
    onChange: (value: string) => void;
    placeholder?: string;
    disabled?: boolean;
    error?: boolean;
    clearable?: boolean;
    onClear?: () => void;
    width?: number;
    height?: number;
    textInputHeight?: number;
    maxLength?: number;
    isLeftIcon?: boolean;
    isRightIcon?: boolean;
    isClearIcon?: boolean;
    unit?: string;
    isMoney?: boolean;
    isEnterChange?: boolean;
};

const RadiusTextInput = ({
    value = '',
    onChange,
    placeholder = '값을 입력해주세요',
    disabled = false,
    error = false,
    clearable = false,
    onClear,
    width = 12,
    height = 36,
    textInputHeight = 36,
    maxLength,
    isLeftIcon,
    isRightIcon,
    isClearIcon = true,
    unit,
    isEnterChange = false,
}: InputProps) => {
    const [focus, setFocus] = useState(false);
    const [inputValue, setInputValue] = useState(value);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isEnterChange) {
            let newValue = e.target.value;

            // If there is a unit, remove it from the input value
            if (unit && newValue.endsWith(unit)) {
                newValue = newValue.slice(0, -unit.length);
            }

            setInputValue(newValue);
        } else {
            let newValue = e.target.value;

            onChange(e.target.value);
            setInputValue(newValue);
        }
    };
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && isEnterChange) {
            onChange(inputValue as string);
        }
    };

    return (
        <div className={` relative h-[${textInputHeight}px] flex-row`} style={{ width: width, height: height }}>
            <input
                type="text"
                value={disabled ? value : isEnterChange ? inputValue ?? '' : value}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                disabled={disabled}
                maxLength={maxLength}
                aria-disabled={disabled}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                className={`w-full h-full px-[16px] py-[6px] rounded-full border-[1px] focus:outline-none text-[16px] placeholder-GREY_200
          ${disabled ? 'bg-WHITE_500 text-LAVEL_1 cursor-not-allowed' : 'bg-white'}
          ${error ? 'border-red-500' : focus ? 'border-primary' : 'border-WHITE_600'}
          ${!disabled && !error ? 'hover:border-primary-dark' : ''}
          ${isLeftIcon ? 'pl-[44px]' : 'pl-[16px]'}
        `}
            />
            {value && isClearIcon && !disabled && (
                <div
                    className="absolute right-[16px] top-1/2 transform -translate-y-1/2 text-primary cursor-pointer"
                    onClick={() => {
                        setInputValue('');
                        onClear && onClear();
                    }}
                >
                    <SvgIcon name="SvgCircleX" size={18} fill={colors.WHITE_600} />
                </div>
            )}
            {isRightIcon && !value && !disabled && (
                <SvgIcon
                    name="SvgSearch"
                    size={12}
                    stroke={colors.GREY_200}
                    fill={colors.trans}
                    className="absolute right-[16px] top-1/2 transform -translate-y-1/2 w-[24px] h-[24px] text-primary cursor-pointer"
                />
            )}
            {isLeftIcon && !disabled && (
                <SvgIcon
                    name="SvgSearch"
                    size={12}
                    stroke={colors.GREY_200}
                    fill={colors.trans}
                    className="absolute left-[16px] top-1/2 transform -translate-y-1/2 w-[24px] h-[24px] text-primary cursor-pointer"
                />
            )}
        </div>
    );
};

export default RadiusTextInput;
