import React from 'react';
import { Col, Row } from '../../../lib/utils';
import {
    Body1Bold,
    Body1Regular,
    Body2Bold,
    Body2Light,
    Body2Regular,
    Body3Bold,
    Body3Light,
    Body3Regular,
    Head4,
} from '../../../lib/font';
import { colors } from '../../../lib/colors';
import { I_LEFT_ANGLE, I_PRIMARY_LEFT_TRIANGLE, I_PRIMARY_RIGHT_TRIANGLE, I_RIGHT_ANGLE } from '../../../types/images';
import dayjs from 'dayjs';
import ResetButton from '../../Button/ResetButton';
import ThreeButton from '../../Button/ThreeButton';

type Props = {
    currentDate: any;
    onClickSubtractDate: () => void;
    onClickAddDate: () => void;
    isToday: boolean;
    isWeek: boolean;
    isMonth: boolean;
    settingDate: (type: string) => void;
    weekNumber: number;
    setCurrentDate: any;
    cancelCount: number;
    changeCount: number;
};

const ScheduleHistoryListHeader = ({
    currentDate,
    onClickSubtractDate,
    onClickAddDate,
    isToday,
    isWeek,
    isMonth,
    settingDate,
    weekNumber,
    setCurrentDate,
    cancelCount,
    changeCount,
}: Props) => {
    return (
        <Col>
            <Body1Bold style={{ marginLeft: 70, marginTop: 48 }}>당일 취소 및 변경 - 강사</Body1Bold>
            <Row
                style={{
                    marginTop: 24,
                    alignItems: 'center',
                    marginLeft: 70,
                    justifyContent: 'space-between',
                    paddingRight: 70,
                }}
            >
                <Row>
                    <Row
                        style={{
                            width: 232,
                            height: 32,
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginRight: 24,
                        }}
                    >
                        <button style={{ width: 24, height: 24 }} onClick={() => onClickSubtractDate()}>
                            <img src={I_LEFT_ANGLE} style={{ width: 24, height: 24 }} />
                        </button>
                        <Body3Regular style={{ width: 160, textAlign: 'center', color: colors.LAVEL_2 }}>
                            {isToday && currentDate.format('MM월 DD일 (ddd)')}
                            {isWeek && currentDate.format('MM월 ') + weekNumber + '주차'}
                            {isMonth && currentDate.format('YYYY년 MM월')}
                        </Body3Regular>
                        <button style={{ width: 32, height: 32 }} onClick={() => onClickAddDate()}>
                            <img src={I_RIGHT_ANGLE} style={{ width: 24, height: 24 }} />
                        </button>
                    </Row>
                    <ThreeButton
                        isFirst={isToday}
                        isSecond={isWeek}
                        isThird={isMonth}
                        firstText={'일간'}
                        secondText={'주간'}
                        thirdText={'월간'}
                        firstCallBack={() => settingDate('today')}
                        secondCallBack={() => settingDate('thisWeek')}
                        thirdCallBack={() => settingDate('thisMonth')}
                    />
                    {/* 초기화 버튼 */}
                    <ResetButton style={{ marginLeft: 24 }} callBack={() => setCurrentDate(dayjs())} text="오늘" />
                </Row>

                <Row style={{ justifyContent: 'space-between' }}>
                    <Row
                        style={{
                            height: 24,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Body3Regular style={{ color: colors.LAVEL_3 }}>{`취소(${cancelCount})`}</Body3Regular>
                    </Row>

                    <Row
                        style={{
                            height: 24,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginLeft: 16,
                        }}
                    >
                        <Body3Regular style={{ color: colors.LAVEL_3 }}>{`변경(${changeCount})`}</Body3Regular>
                    </Row>
                </Row>
            </Row>
        </Col>
    );
};

export default ScheduleHistoryListHeader;
