import React from 'react';
import { Col, Row } from '../../lib/utils';
import DonutChart from './DonutChart';
import { Body2Bold, Head4, OverlineLight } from '../../lib/font';
import { colors } from '../../lib/colors';

const DashBoardDonutChart = ({ chartData, completedClass, totalClass }: any) => {
    const width = 200;
    const height = 200;
    const outerRadius = 93; // 바깥 원의 반지름
    const innerRadius = 84; // 안쪽 원의 반지름
    return (
        <Row
            style={{
                width: 200,
                height: 200,
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
            }}
        >
            {chartData && <DonutChart data={chartData} totalValue={totalClass} />}

            <Col style={{ width: 58, height: 58, position: 'absolute', zIndex: 2 }}>
                <Row style={{ alignItems: 'flex-end', justifyContent: 'center' }}>
                    <Head4>{completedClass / totalClass ? Math.ceil((completedClass / totalClass) * 100) : 0}</Head4>
                    <Body2Bold>%</Body2Bold>
                </Row>
                <OverlineLight style={{ color: colors.LAVEL_3 }}>{'스케줄 진행도'}</OverlineLight>
            </Col>

            <svg width={width} height={height} style={{ zIndex: 0.5, position: 'absolute' }}>
                <circle cx={width / 2} cy={height / 2} r={outerRadius} fill="lightgray" />
                <circle cx={width / 2} cy={height / 2} r={innerRadius} fill="white" />
            </svg>
        </Row>
    );
};

export default DashBoardDonutChart;
