import React from 'react';
import TextSelector, { FontsType } from '../TextSelector';

type Props = {
    text: string;
    fontType?: FontsType;
    width?: number;
    height?: number;
    onClick?: () => void;
    className?: string;
    mb?: number;
    mr?: number;
};

const NewWhiteSquareButton = ({
    text,
    fontType = 'Body2Regular',
    width,
    height,
    onClick,
    className,
    mb,
    mr,
}: Props) => {
    return (
        <div
            onClick={onClick}
            className={`flex flex-col justify-center items-center  bg-white rounded-[2px] ${className} border-[1px] border-solid border-primary cursor-pointer`}
            style={{
                width: width ? `${width}px` : 'auto',
                height: height ? `${height}px` : 'auto',
                marginBottom: mb ? `${mb}px` : '0',
                marginRight: mr ? `${mr}px` : '0',
            }}
        >
            <TextSelector fontType={fontType} text={text} color={'primary'} />
        </div>
    );
};

export default NewWhiteSquareButton;
