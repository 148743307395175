import React from 'react';
import { Col } from '../lib/utils';
import { colors } from '../lib/colors';

type Props = {
    className?: string;
    size?: number;
    color?: keyof typeof colors;
};

const Dot = ({ className, size = 4, color = 'black' }: Props) => {
    return (
        <Col
            style={{
                width: size,
                height: size,
                borderRadius: 50,
                backgroundColor: colors[color],
            }}
            className={`${className}`}
        />
    );
};

export default Dot;
