import styled from 'styled-components';
import { Body1Regular, Body3Regular } from '../../lib/font';
import { CSSProperties } from 'react';

const ButtonWrap = styled.button`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #353535;
    &:not([disabled]):hover {
        color: #7da1e8;
    }
    &:not([disabled]):active {
        color: #3d73dd;
    }
`;

type Props = {
    size: 'large' | 'middle';
    text: string;
    callBack?: any;
    customStyle?: CSSProperties;
};
const TextChangeButton = ({ size, text, callBack, customStyle }: Props) => {
    const LargeStyle = {
        width: 146,
        height: 46,
    };

    const MiddleStyle = {
        width: 113,
        height: 32,
    };

    return (
        <ButtonWrap onClick={callBack} style={customStyle ? customStyle : size === 'large' ? LargeStyle : MiddleStyle}>
            {size === 'middle' && <Body3Regular>{text}</Body3Regular>}
            {size === 'large' && <Body1Regular>{text}</Body1Regular>}
        </ButtonWrap>
    );
};

export default TextChangeButton;
