import { Row } from '../lib/utils';
import { colors } from '../lib/colors';
import { Body1Bold, CaptionRegular } from '../lib/font';
import dayjs from 'dayjs';

// 결제상태
// needAction : 최초 접속
// trial : 무료 체험중
// trialPaymentPending : 무료 체험 유예
// plan : 유료 구독
// planWillChanged : 유료 구독 변경예정
// planWillCanceled : 유료 구독 취소예정
// planAborted : 유료 구독 유예
// needPlanToFree : 무료 체험 만료
// needPlanToCharge : 유료 구독 만료

const UsableState = ({ authObject }: any) => {
    return (
        // 무료플랜 이용중
        authObject?.usableStatus === 'trial' ? (
            <Row
                style={{
                    width: 113,
                    height: 24,
                    borderRadius: 18,

                    border: '1px solid #FF3E4A',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div style={{ width: 8, height: 8, borderRadius: 4, backgroundColor: colors.red, marginRight: 4 }} />
                <CaptionRegular style={{ color: colors.LAVEL_4 }}>
                    무료 플랜 D-
                    {authObject.expirationDate && dayjs(authObject.expirationDate).diff(dayjs(), 'day')}
                </CaptionRegular>
            </Row>
        ) : authObject?.usableStatus === 'plan' ? (
            // 유료 플랜 이용중
            <Row
                style={{
                    width: 123,
                    height: 24,
                    borderRadius: 24,

                    border: '1px solid #00A793',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div style={{ width: 8, height: 8, borderRadius: 4, backgroundColor: colors.GREEN, marginRight: 4 }} />
                <CaptionRegular style={{ color: colors.LAVEL_4 }}>
                    {authObject?.planLimitCount}인 플랜 이용중
                </CaptionRegular>
            </Row>
        ) : authObject?.usableStatus === 'planWillChanged' ? (
            // 유료 플랜 이용중
            <Row
                style={{
                    width: 123,
                    height: 24,
                    borderRadius: 24,
                    border: '1px solid #00A793',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div style={{ width: 8, height: 8, borderRadius: 4, backgroundColor: colors.GREEN, marginRight: 4 }} />
                <CaptionRegular style={{ color: colors.LAVEL_4 }}>
                    {authObject?.changedPlanLimitCount}인 플랜 이용중
                </CaptionRegular>
            </Row>
        ) : authObject?.usableStatus === 'planWillCanceled' ? (
            // 구독취소 후 만료까지 카운트다운
            <Row
                style={{
                    width: 113,
                    height: 24,
                    borderRadius: 24,

                    border: '1px solid #FF8777',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div style={{ width: 8, height: 8, borderRadius: 4, backgroundColor: colors.red, marginRight: 4 }} />
                <CaptionRegular style={{ color: colors.LAVEL_4 }}>
                    구독 만료 D-
                    {dayjs(authObject?.expirationDate).diff(dayjs(), 'day')}
                </CaptionRegular>
            </Row>
        ) : authObject?.usableStatus === 'planAborted' ||
          authObject?.usableStatus === 'needPlanToCharge' ||
          authObject?.usableStatus === 'needPlanToCharge' ||
          authObject?.usableStatus === 'trialPaymentPending' ? (
            // 구독 만료, 결제 유예중 상태
            <Row
                style={{
                    width: 83,
                    height: 24,
                    borderRadius: 24,

                    border: '1px solid #FF3E4A',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div style={{ width: 8, height: 8, borderRadius: 4, backgroundColor: colors.red, marginRight: 4 }} />
                <CaptionRegular style={{ color: colors.LAVEL_4 }}>구독 만료</CaptionRegular>
            </Row>
        ) : null
    );
};

export default UsableState;
