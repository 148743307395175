import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Body3Regular } from '../../lib/font';
import { colors } from '../../lib/colors';
import { Row } from '../../lib/utils';

const DropdownContainer = styled.div`
    position: relative;
    display: inline-block;
`;

const ButtonWrap = styled.button<{ isOpen: boolean }>`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 146px;
    height: 46px;
    align-items: center;
    justify-content: center;
    color: ${({ isOpen }) => (isOpen ? '#3d73dd' : '#353535')};
    &:not([disabled]):hover {
        color: #7da1e8;
    }
    &:not([disabled]):active {
        color: #3d73dd;
    }
`;

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideUp = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

const DropdownContent = styled.div<{ isOpen: boolean }>`
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    position: absolute;
    background: #f8fbffe0;
    padding-top: 8px;
    padding-bottom: 4px;
    width: 240px;
    box-shadow: 0px 6px 12px 0px #0000003d;
    border-radius: 8px;
    animation: ${({ isOpen }) => (isOpen ? slideDown : slideUp)} 0.3s ease forwards;
    z-index: 1;
`;

const DropdownItem = styled.a`
    width: 240px;
    height: 48px;
    padding: 8px;
    margin-bottom: 4px;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    background: #f5f9ff3d;
    z-index: 1;
    &:hover {
        background: #ecf1fc;
    }
`;

const IdentifyRow = styled.div`
    width: 6px;
    height: 32px;
    border-radius: 4px;
    background: #ecf1fc;
    margin-right: 16px;
    &:hover {
        background: #c3d4f4;
    }
`;

type Props = {
    text: string;
    isDropDown: boolean;
    callBack?: () => void;
    isActive?: boolean;
    list?: any;
};

const TextButtonDropDown = ({ text, isDropDown, callBack, isActive, list }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => {
        if (isDropDown) {
            setIsOpen(!isOpen);
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <DropdownContainer ref={dropdownRef}>
            <ButtonWrap
                isOpen={isOpen}
                onClick={() => {
                    isDropDown ? toggleDropdown() : callBack && callBack();
                }}
            >
                <Body3Regular style={{ color: isActive ? colors.BLUE_500 : '' }}>{text}</Body3Regular>
            </ButtonWrap>

            <DropdownContent isOpen={isOpen}>
                {list &&
                    list.map((item: any, index: number) => {
                        return (
                            <DropdownItem
                                key={text + index}
                                onClick={() => {
                                    item.callBack();
                                    toggleDropdown();
                                }}
                            >
                                <IdentifyRow />
                                <Body3Regular style={{ color: colors.LAVEL_3 }}>{item.name}</Body3Regular>
                            </DropdownItem>
                        );
                    })}
            </DropdownContent>
        </DropdownContainer>
    );
};

export default TextButtonDropDown;
