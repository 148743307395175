import { Col, Row } from '../../lib/utils';
import { colors } from '../../lib/colors';
import { Body1Regular, Body2Bold, Head4 } from '../../lib/font';
import { I_EMPTY_PROFILE } from '../../types/images';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { Dispatch, SetStateAction } from 'react';
import ProfileImage from '../ProfileImage';
dayjs.locale('ko');

type Props = {
    selectedDay: Date;
    dailyCurrentData: any;
    setIsCalendar: Dispatch<SetStateAction<boolean>>;
    sidebarStatus: 'open' | 'fold';
};

const DailyScheduleGridHeader = ({ selectedDay, dailyCurrentData, setIsCalendar, sidebarStatus }: Props) => {
    return (
        <Row
            style={{
                boxShadow: '0px 4px 24px 0px #00000014',
                alignItems: 'center',
                height: 160,
            }}
        >
            <Row style={{ alignItems: 'center' }}>
                <button
                    style={{
                        width: 140,
                        height: 160,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 10,
                    }}
                    onClick={() => setIsCalendar(true)}
                >
                    <Col
                        style={{
                            width: 100,
                            height: 88,
                            borderRadius: 24,
                            backgroundColor: colors.WHITE_50,
                            boxShadow: '1px 1px 8px 0px #2B529D2E',
                        }}
                    >
                        <Row
                            style={{
                                width: 100,
                                height: 34,
                                boxShadow: '1px 1px 8px 0px #2B529D2E',
                                borderTopLeftRadius: 24,
                                borderTopRightRadius: 24,
                                justifyContent: 'center',
                                paddingTop: 8,
                            }}
                        >
                            <Body2Bold style={{ color: colors.BLUE_700 }}>
                                {dayjs(selectedDay).format('MM월')}
                            </Body2Bold>
                        </Row>
                        <Head4 style={{ color: colors.BLUE_700, textAlign: 'center', marginTop: 6 }}>
                            {dayjs(selectedDay).format('DD일')}
                        </Head4>
                    </Col>
                </button>
                <hr
                    style={{
                        width: 2,
                        height: 48,
                        backgroundColor: colors.WHITE_500,
                    }}
                />
            </Row>
            <Row style={{ width: sidebarStatus === 'open' ? 1216 : 1488 }}>
                {dailyCurrentData &&
                    dailyCurrentData.map((item: any, index: number) => {
                        return (
                            <Row
                                key={index + 'headerCoaches'}
                                style={{
                                    width: sidebarStatus === 'open' ? 152 : 186,
                                    height: 160,
                                    alignItems: 'center',
                                    paddingLeft: sidebarStatus === 'open' ? 31 : 48,
                                }}
                            >
                                <Col
                                    key={index}
                                    style={{
                                        width: 88,
                                        height: 92,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <ProfileImage
                                        imageUrl={item?.coachProfileImageUrl}
                                        type="Big"
                                        profileName={item?.coachProfileName}
                                        profileColorType={item?.coachProfileColorType}
                                    />

                                    {item.coachName && (
                                        <Body1Regular style={{ color: colors.BLUE_700, marginTop: 8 }}>
                                            {item.coachName}
                                        </Body1Regular>
                                    )}
                                </Col>

                                {index < 7 && (
                                    <hr
                                        style={{
                                            width: 2,
                                            height: 48,
                                            backgroundColor: colors.WHITE_500,
                                            marginLeft: sidebarStatus === 'open' ? 32 : 48,
                                        }}
                                    />
                                )}
                            </Row>
                        );
                    })}
            </Row>
        </Row>
    );
};

export default DailyScheduleGridHeader;
