import React, { useState } from 'react';
import { Col, Row } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import { Body3Bold, Body3Light, CaptionBold, CaptionLight, CaptionRegular } from '../../../lib/font';
import dayjs from 'dayjs';
import { I_TODAY_TRIANGLE } from '../../../types/images';
import styled, { keyframes } from 'styled-components';

// 애니메이션 정의
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

interface StyledColProps {
    backgroundColor: string;
    isVisible: boolean;
}

// 스타일드 컴포넌트 정의
const HoverItem = styled.div<StyledColProps>`
    width: 300px;
    height: 192px;
    z-index: 99;

    background-color: ${(props) => props.backgroundColor};
    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    box-shadow: 4px 4px 8px 0px #0d41a675;
    padding-left: 16px;
    padding-right: 24px;
    padding-top: 14px;
    transition: all 1s ease;
    animation: ${(props) => (props.isVisible ? fadeIn : 'none')} 1s ease forwards;
`;

const ScheduleBlock = ({
    onClickSchedule,
    parentItem,
    parentIndex,
    childItem,
    colPosition,
    rowPosition,
    boxHeight,
}: any) => {
    const [showModal, setShowModal] = useState(false);

    const isGoingSchedule = dayjs().isAfter(childItem.startTime) && dayjs().isBefore(childItem.endTime);

    const hoverStyle =
        parentIndex > 5
            ? {
                  right: '110%',
              }
            : {
                  left: '110%',
              };

    return (
        <Col
            onClick={() => {
                onClickSchedule(childItem);
            }}
            style={{
                width: '10.4%',
                height: boxHeight,
                position: 'absolute',
                marginTop: colPosition,
                marginLeft: `${rowPosition}%`,
                borderRadius: 4,
                justifyContent: 'center',
                alignItems: 'start',
                paddingLeft: '1.11%',
                border: isGoingSchedule ? '2px solid #3D73DD' : '2px solid #648FE4',
                backgroundColor: colors.WHITE_50,
                cursor: 'pointer',
                boxShadow: showModal ? '0px 0px 12px 0px #3D73DD52' : '',
            }}
            onMouseEnter={() => setShowModal(true)}
            onMouseLeave={() => setShowModal(false)}
        >
            <Row style={{ width: '100%', justifyContent: 'flex-end' }}>
                {isGoingSchedule && (
                    <img
                        style={{
                            position: 'absolute',
                            width: 24,
                            height: 18,
                            top: 0,
                        }}
                        src={I_TODAY_TRIANGLE}
                    />
                )}
            </Row>

            {childItem?.duration > 15 ? (
                <Body3Bold style={{ color: isGoingSchedule ? colors.LAVEL_4 : colors.LAVEL_3 }}>
                    {childItem?.alias}
                </Body3Bold>
            ) : (
                <CaptionBold style={{ color: isGoingSchedule ? colors.LAVEL_4 : colors.LAVEL_3 }}>
                    {childItem?.alias}
                </CaptionBold>
            )}

            {childItem?.duration > 20 && (
                <Row style={{ alignItems: 'center' }}>
                    <CaptionLight style={{ color: colors.LAVEL_2 }}>
                        {childItem?.currentSession ? childItem?.currentSession : '?'}
                    </CaptionLight>

                    <CaptionLight style={{ color: colors.LAVEL_2 }}>
                        /{childItem?.totalSession ? childItem?.totalSession : '?'}회
                    </CaptionLight>

                    <Row
                        style={{
                            width: 2,
                            height: 2,
                            borderRadius: 1,
                            backgroundColor: colors.LAVEL_2,
                            marginLeft: 8,
                            marginRight: 8,
                        }}
                    />

                    <CaptionLight>{`${childItem?.duration}분`}</CaptionLight>
                </Row>
            )}

            {/* 우측 하단에 노쇼를 의미하는 빨간 점 추가 */}
            {childItem?.attendanceStatus === 'NOSHOW' && (
                <div
                    style={{
                        position: 'absolute',
                        width: '4%',
                        aspectRatio: 1,
                        backgroundColor: colors.ERROR,
                        borderRadius: '50%',
                        bottom: 8,
                        right: 8,
                    }}
                />
            )}

            {showModal && (
                <HoverItem style={hoverStyle} backgroundColor={colors.WHITE_50} isVisible={showModal}>
                    <Row
                        style={{
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Row style={{ width: 200 }}>
                            <Col
                                style={{
                                    width: 8,
                                    height: 22,
                                    borderRadius: 8,
                                    backgroundColor: colors.BLUE_500,
                                    marginRight: 11,
                                }}
                            />
                            <Body3Bold style={{ color: colors.LAVEL_3 }}>{`${parentItem?.coachName} 강사`}</Body3Bold>
                        </Row>
                        {childItem?.attendanceStatus === 'NOSHOW' ? (
                            <Row
                                style={{
                                    width: 64,
                                    height: 23,
                                    borderRadius: 34,
                                    border: '1px solid #FF3E4A',
                                    color: colors.red,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <CaptionBold>노쇼</CaptionBold>
                            </Row>
                        ) : (
                            <Row
                                style={{
                                    width: 64,
                                    height: 23,
                                    borderRadius: 34,
                                    border: '1px solid #3D73DD',
                                    color: colors.BLUE_500,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <CaptionBold>
                                    {isGoingSchedule
                                        ? '수업중'
                                        : dayjs().isAfter(childItem?.endTime)
                                        ? '수업 완료'
                                        : '수업 예정'}
                                </CaptionBold>
                            </Row>
                        )}
                    </Row>

                    <Body3Light style={{ color: colors.LAVEL_3, marginTop: 16 }}>
                        {dayjs(childItem?.startTime).format('M월 D일(ddd)')}
                    </Body3Light>
                    <Body3Bold style={{ color: colors.LAVEL_3, marginTop: 8 }}>
                        {dayjs(childItem?.startTime).format('A hh:mm ~ ')}
                        {dayjs(childItem?.endTime).format('A hh:mm ')}
                        {`(${childItem?.duration}분)`}
                    </Body3Bold>
                    <Row style={{ alignItems: 'center', marginTop: 8 }}>
                        <Body3Bold style={{ color: colors.LAVEL_3 }}>{childItem?.alias} 회원</Body3Bold>
                        <Body3Light style={{ color: colors.LAVEL_2, marginLeft: 16 }}>{`${
                            childItem?.currentSession ? childItem?.currentSession : '?'
                        }/${childItem?.totalSession ? childItem?.totalSession : '?'}회`}</Body3Light>
                    </Row>
                    <CaptionRegular style={{ color: colors.LAVEL_2, marginTop: 8 }}>
                        수강권 : {childItem?.membership ? childItem?.membership?.name.substr(0, 40) : '-'}
                    </CaptionRegular>
                </HoverItem>
            )}
        </Col>
    );
};

export default ScheduleBlock;
