import React, { useEffect, useState } from 'react';
import { Col, Row } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import { Body3Bold, Body3Regular, CaptionRegular } from '../../../lib/font';
import dayjs from 'dayjs';
import ProfileImage from '../../ProfileImage';
import TruncateText from '../../../lib/TruncateText';
import ScheduleHistoryMemberCancleEmptyLIst from './ScheduleHistoryMemberCancleEmptyLIst';
import { HoverReason } from '../HoverReason';

const ScheduleHistoryMemberCancelDaily = ({ thisDayList }: any) => {
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [isHovered, setIsHovered] = useState(false);

    return (
        <Col>
            {thisDayList.length === 0 ? (
                <ScheduleHistoryMemberCancleEmptyLIst type={'thisDay'} />
            ) : (
                thisDayList.map((item: any, index: number) => {
                    return (
                        <Row
                            style={{
                                width: 1100,
                                height: 68,
                                alignItems: 'center',
                                paddingLeft: 12,
                                backgroundColor: index === hoveredIndex ? '#ECF1FC' : 'transparent',
                                transition: 'background-color 0.3s ease',
                            }}
                            key={index}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                        >
                            <Row style={{ width: 124, justifyContent: 'center' }}>
                                <ProfileImage
                                    imageUrl={item?.coachProfileImageUrl}
                                    type="VerySmall"
                                    profileName={item?.coachProfileName}
                                    profileColorType={item?.coachProfileColorType}
                                />
                                <Body3Regular style={{ color: colors.LAVEL_3, marginLeft: 8 }}>
                                    {item.coachName}
                                </Body3Regular>
                            </Row>
                            <div
                                style={{
                                    width: 1,
                                    height: 24,
                                    marginLeft: 16,
                                    marginRight: 16,
                                }}
                            />

                            <Row style={{ width: 124, justifyContent: 'center' }}>
                                <ProfileImage
                                    imageUrl={item?.memberProfileImageUrl}
                                    type="VerySmall"
                                    profileName={item?.memberProfileName}
                                    profileColorType={item?.memberProfileColorType}
                                />
                                <Body3Regular style={{ color: colors.LAVEL_3, marginLeft: 8 }}>
                                    {item.memberName}
                                </Body3Regular>
                            </Row>
                            <div
                                style={{
                                    width: 1,
                                    height: 24,
                                    marginLeft: 16,
                                    marginRight: 16,
                                }}
                            />
                            <Row style={{ width: 190, height: 32, alignItems: 'center', justifyContent: 'center' }}>
                                <CaptionRegular style={{ color: colors.LAVEL_3 }}>
                                    <p>{dayjs(item.startTime).format('YYYY / MM / DD(ddd)')}</p>
                                    <p>
                                        {dayjs(item.startTime).format(`A hh시 mm분`)} ~{' '}
                                        {dayjs(item.endTime).format(`A hh시 mm분`)}
                                    </p>
                                </CaptionRegular>
                            </Row>
                            <div
                                style={{
                                    width: 1,
                                    height: 24,
                                    marginLeft: 20,
                                    marginRight: 20,
                                }}
                            />

                            <Row style={{ width: 120, height: 32, alignItems: 'center', justifyContent: 'center' }}>
                                <CaptionRegular style={{ color: colors.LAVEL_3 }}>
                                    <p>{dayjs(item.createdAt).format('YYYY / MM / DD(ddd)')}</p>
                                    <p>{dayjs(item.createdAt).format(`A hh시 mm분`)}</p>
                                </CaptionRegular>
                            </Row>
                            <div
                                style={{
                                    width: 1,
                                    height: 24,
                                    marginLeft: 20,
                                    marginRight: 20,
                                }}
                            />

                            <Row
                                style={{
                                    width: 340,
                                    height: 68,
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    position: 'relative',
                                }}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            >
                                <TruncateText text={item.reason} maxLength={40}>
                                    {item.reason}
                                </TruncateText>
                                {/* <TruncateText text="Short text." maxLength={20} /> */}
                                {/* <Body3Regular style={{ color: colors.LAVEL_3 }}>{item.reason}</Body3Regular> */}
                                {isHovered && hoveredIndex === index && (
                                    <HoverReason className="hover-popup">
                                        <Col>
                                            <Body3Bold style={{ marginBottom: 8 }}>
                                                {/* 강사 {item.type === 'coachTodayCancel' ? '취소' : '변경'} 사유 */}
                                                회원 취소 사유
                                            </Body3Bold>
                                            <Body3Regular style={{ marginBottom: 12 }}>{item.reason}</Body3Regular>
                                            <CaptionRegular>
                                                취소시간: {dayjs(item.createdAt).format('YYYY/MM/DD(ddd) A hh시 mm분')}
                                            </CaptionRegular>
                                        </Col>
                                    </HoverReason>
                                )}
                            </Row>

                            <div
                                style={{
                                    width: 1,
                                    height: 24,
                                    marginLeft: 20,
                                    marginRight: 20,
                                }}
                            />

                            <Row style={{ width: 100, height: 32, alignItems: 'center', justifyContent: 'center' }}>
                                <Body3Regular style={{ color: colors.LAVEL_3 }}>{item.cancelCount}</Body3Regular>
                            </Row>
                        </Row>
                    );
                })
            )}
        </Col>
    );
};

export default ScheduleHistoryMemberCancelDaily;
