import { useMutation, useQuery } from '@tanstack/react-query';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { getSpecificMemberClassHistoryAPI } from '../../../api/employee';
import dayjs from 'dayjs';
import { storeUserFlow } from '../../../api/adminuserflow';
import { Col } from '../../../lib/utils';
import ClassHistoryHeader from './ClassHistoryHeader';
import ClassHistoryThisMonth from './ClassHistoryThisMonth';
import ClassHistoryThisYear from './ClassHistoryThisYear';
import _ from 'lodash';
import ClassHistoryEntire from './ClassHistoryEntire';

type Props = {
    authorization?: any;
    employeeId: string;
    authObject: any;
};

const ClassHistory = ({ authorization, employeeId, authObject }: Props) => {
    console.log('wefwefwef');

    // 초기값 설정
    const [currentDate, setCurrentDate] = useState(dayjs());
    const [searchText, setSearchText] = useState<string>('');
    const [isMonth, setIsMonth] = useState<boolean>(true);
    const [isYear, setIsYear] = useState<boolean>(false);
    const [isEntire, setIsEntire] = useState<boolean>(false);

    const [thisMonthList, setThisMonthList] = useState<any>(null);
    const [thisMonthFilterList, setThisMonthFilterList] = useState<any>([]);

    const [thisYearList, setThisYearList] = useState<any>(null);
    const [thisYearFilterList, setThisYearFilterList] = useState<any>([]);

    const [entireList, setEntireList] = useState<any>(null);
    const [entireFilterList, setEntireFilterList] = useState<any>([]);

    const [yearToggleState, setYearToggleState] = useState<boolean[]>([]);
    const [entireToggleState, setEntireToggleState] = useState<boolean[]>([]);

    const settingDate = (type: string) => {
        if (type === 'month') {
            setIsMonth(true);
            setIsYear(false);
            setIsEntire(false);
            setCurrentDate(dayjs());
        }

        if (type === 'year') {
            setIsMonth(false);
            setIsYear(true);
            setIsEntire(false);
            setCurrentDate(dayjs());
        }

        if (type === 'entire') {
            setIsMonth(false);
            setIsYear(false);
            setIsEntire(true);
            setCurrentDate(dayjs());
        }
    };
    const onChangeSearchText = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const onClickResetButton = () => {
        settingDate('month');
        setSearchText('');
    };

    const onClickAddDate = () => {
        if (isMonth) {
            const newDate = dayjs(currentDate).add(1, 'month');
            setCurrentDate(newDate);
        }

        if (isYear) {
            const newDate = dayjs(currentDate).add(1, 'year');
            setCurrentDate(newDate);
        }
    };

    const onClickSubtractDate = () => {
        if (isMonth) {
            const newDate = dayjs(currentDate).subtract(1, 'month');
            setCurrentDate(newDate);
        }

        if (isYear) {
            const newDate = dayjs(currentDate).subtract(1, 'year');
            setCurrentDate(newDate);
        }
    };

    // 이번연도 버튼 토글 함수
    const onClickYearToggleBtn = (index: number) => {
        try {
            let cloneYearToggleState = _.cloneDeep(yearToggleState);
            cloneYearToggleState[index] = !cloneYearToggleState[index];
            setYearToggleState(cloneYearToggleState);
        } catch (error) {
            console.log(error);
        }
    };

    // 전체 버튼 토글 함수
    const onClickEntireToggleBtn = (first: number, second: number) => {
        try {
            let cloneEntireToggleState: any = _.cloneDeep(entireToggleState);
            cloneEntireToggleState[first][second] = !cloneEntireToggleState[first][second];

            setEntireToggleState(cloneEntireToggleState);
        } catch (error) {
            console.log(error);
        }
    };

    //  수업기록 조회 API : (GET)
    const getSpecificMemberClassHistory = useQuery(
        ['getSpecificMemberClassHistoryAPI', employeeId, currentDate],
        async () =>
            await getSpecificMemberClassHistoryAPI(authorization, employeeId, dayjs(currentDate).format('YYYY-MM-DD')),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const thisMonth: any = res.data.thisMonth;
                    const thisYear = res.data.thisYear;
                    const entire = res.data.totalView;

                    if (thisMonth) {
                        let thisMonthKey = Object.keys(thisMonth)[0];
                        let thisMonthList = thisMonth[thisMonthKey];
                        setThisMonthList(thisMonthList ? thisMonthList : []);
                    }

                    if (thisYear) {
                        let thisYearKey = Object.keys(thisYear)[0];
                        let thisYearList = thisYear[thisYearKey];
                        setThisYearList(thisYearList ? thisYearList : []);
                    }

                    if (entire) {
                        setEntireList(entire);
                    }
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!employeeId && !!!authObject?.testAccount,
            // refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );
    // 접속 데이터 측정 API
    const pushUserFlow = useMutation({
        mutationFn: async () => await storeUserFlow('memberScheduleHistory', authorization),
    });
    // 접속 데이터 측정 API
    useEffect(() => {
        try {
            if (pushUserFlow) {
                pushUserFlow.mutate();
            }
        } catch (error) {
            console.log(error);
        }
    }, []);
    useEffect(() => {
        if (thisMonthList) {
            let thisMonthLengthCheckArr: any = [];
            for (let i = 0; i < thisMonthList.length; i++) {
                let keys = Object.keys(thisMonthList[i]);
                let dayObject = thisMonthList[i][keys[0]];

                let findFilterList: any = [];
                if (searchText === '') {
                    findFilterList = dayObject;
                } else {
                    findFilterList = dayObject.filter((e: any) => e.alias.includes(searchText));
                }

                if (findFilterList) {
                    for (let j = 0; j < findFilterList.length; j++) {
                        thisMonthLengthCheckArr.push(findFilterList[j]);
                    }
                }
            }
            setThisMonthFilterList(thisMonthLengthCheckArr);
        }

        if (thisYearList) {
            let thisYearLengthCheckArr: any = [];
            let yearToggleStateArr = [];

            for (let i = 0; i < thisYearList.length; i++) {
                let keys = Object.keys(thisYearList[i]);

                let monthArr = thisYearList[i][keys[0]];

                for (let j = 0; j < monthArr.length; j++) {
                    yearToggleStateArr[i] = false;
                    let dayKeys = Object.keys(monthArr[j]);
                    let dayObject = monthArr[j][dayKeys[0]];

                    let findFilterList = [];

                    if (searchText === '') {
                        findFilterList = dayObject;
                    } else {
                        findFilterList = dayObject.filter((e: any) => e.alias.includes(searchText));
                    }

                    if (findFilterList) {
                        for (let k = 0; k < findFilterList.length; k++) {
                            thisYearLengthCheckArr.push(findFilterList[k]);
                        }
                    }
                }
            }

            setYearToggleState(yearToggleStateArr);
            setThisYearFilterList(thisYearLengthCheckArr);
        }

        if (entireList) {
            let entireLengthCheckArr: any = [];

            let rows = 10; // 원하는 행의 수
            let cols = 12; // 원하는 열의 수
            let entireToggleState: any = Array.from({ length: rows }, () => Array(cols).fill(false));

            for (let i = 0; i < entireList.length; i++) {
                let keys = Object.keys(entireList[i]);
                let yearArr = entireList[i][keys[0]];

                for (let j = 0; j < yearArr.length; j++) {
                    let monthKeys = Object.keys(yearArr[j]);
                    let monthArr = yearArr[j][monthKeys[0]];

                    for (let k = 0; k < monthArr.length; k++) {
                        let dayKeys = Object.keys(monthArr[k]);
                        let dayObject = monthArr[k][dayKeys[0]];

                        entireToggleState[i][j] = false;

                        let findFilterList = [];

                        if (searchText === '') {
                            findFilterList = dayObject;
                        } else {
                            findFilterList = dayObject.filter((e: any) => e.alias.includes(searchText));
                        }

                        if (findFilterList) {
                            for (let l = 0; l < findFilterList.length; l++) {
                                entireLengthCheckArr.push(findFilterList[l]);
                            }
                        }
                    }
                }
            }

            setEntireToggleState(entireToggleState);
            setEntireFilterList(entireLengthCheckArr);
        }
    }, [searchText, thisMonthList, thisYearList, entireList]);

    return (
        <Col style={{ marginBottom: 200 }}>
            <ClassHistoryHeader
                isMonth={isMonth}
                isYear={isYear}
                isEntire={isEntire}
                currentDate={currentDate}
                searchText={searchText}
                onClickSubtractDate={onClickSubtractDate}
                onClickAddDate={onClickAddDate}
                settingDate={settingDate}
                onClickResetButton={onClickResetButton}
                onChangeSearchText={onChangeSearchText}
                thisMonthFilterList={thisMonthFilterList}
                thisYearFilterList={thisYearFilterList}
                entireFilterList={entireFilterList}
            />

            {isMonth && (
                <ClassHistoryThisMonth
                    thisMonthList={thisMonthList}
                    thisMonthFilterList={thisMonthFilterList}
                    searchText={searchText}
                />
            )}

            {isYear && (
                <ClassHistoryThisYear
                    thisYearList={thisYearList}
                    thisYearFilterList={thisYearFilterList}
                    searchText={searchText}
                    yearToggleState={yearToggleState}
                    onClickYearToggleBtn={onClickYearToggleBtn}
                />
            )}

            {isEntire && (
                <ClassHistoryEntire
                    entireList={entireList}
                    entireFilterList={entireFilterList}
                    searchText={searchText}
                    entireToggleState={entireToggleState}
                    onClickEntireToggleBtn={onClickEntireToggleBtn}
                />
            )}
        </Col>
    );
};

export default ClassHistory;
