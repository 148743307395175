import React from 'react';
import { Col } from '../../../lib/utils';
import MemberMealListGraph from '../MemberMealList/MemberMealListGraph';
import MemberList from './MemberList/MemberList';
import MemberListGraph from './MemberListGraph/MemberListGraph';

const ManageMember = ({ authorization, employeeId, authObject, coachId }: any) => {
    return (
        <Col style={{ width: 1192, marginBottom: 160 }}>
            {/* 회원 리스트 */}
            <MemberList coachId={coachId} />

            <MemberListGraph authorization={authorization} employeeId={employeeId} authObject={authObject} />
            {/* 회원권 등록리스트 그래프*/}
            {/* <MembershipList authorization={authorization} employeeId={employeeId} authObject={authObject} /> */}

            {/* 식단 관리 회원 그래프 */}
            <MemberMealListGraph authorization={authorization} employeeId={employeeId} authObject={authObject} />
        </Col>
    );
};

export default ManageMember;
