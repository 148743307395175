import React from 'react';
import { Col, Row } from '../../../lib/utils';
import { Body2Regular, Body3Regular, CaptionLight } from '../../../lib/font';
import InputSquareDropDown from '../../DropDown/InputSquareDropDown';
import dayjs from 'dayjs';
import { colors } from '../../../lib/colors';
import ThreeButton from '../../Button/ThreeButton';
import WhiteRoundButton from '../../Button/WhiteRoundButton';
import ColorRoundButton from '../../Button/ColorRoundButton';
import NormalToggleButton from '../../ToggleButton/NormalToggleButton';

const CreateOffScheduleRight = ({
    coachOption,
    coach,
    handleCoachSelect,
    selectedDay,
    setIsCalendar,
    timeList,
    startTime,
    handleStartTimeSelect,
    endTime,
    handleEndTimeSelect,
    onClickAddTime,
    onClickSubTime,

    blockType,
    setBlockType,
    isAllDayBlock,
    onClickToggleAllDayBlock,
}: any) => {
    return (
        <Col style={{ marginLeft: 125 }}>
            <Col style={{ width: 382, height: 152, justifyContent: 'space-between', marginBottom: 64 }}>
                <Body3Regular style={{ color: colors.LAVEL_4 }}> {'날짜'}</Body3Regular>

                <ThreeButton
                    isFirst={blockType === 'oneDay'}
                    isSecond={blockType === 'everyWeek'}
                    isThird={blockType === 'everyDay'}
                    firstText={'오늘만'}
                    secondText={'매주 반복'}
                    thirdText={'매일 반복'}
                    firstCallBack={() => setBlockType('oneDay')}
                    secondCallBack={() => setBlockType('everyWeek')}
                    thirdCallBack={() => setBlockType('everyDay')}
                />

                <InputSquareDropDown
                    selectedGender={coach}
                    genderOptions={coachOption}
                    handleGenderSelect={handleCoachSelect}
                    customStyle={{ width: 382 }}
                    isJustClick={true}
                    justClickString={dayjs(selectedDay).format('YYYY년 M월 D일(ddd)')}
                    justClickCallBack={() => setIsCalendar(true)}
                />
            </Col>

            <Col style={{ width: 470, height: 246 }}>
                <Body3Regular style={{ color: colors.LAVEL_4 }}> {'시간'}</Body3Regular>
                <Row
                    style={{
                        width: 280,
                        height: 52,
                        marginTop: 24,
                        marginBottom: 24,
                        alignItems: 'center',
                        paddingLeft: 20,
                        paddingRight: 20,
                        justifyContent: 'space-between',
                        border: '1px solid #C3D4F4',
                        borderRadius: 4,
                    }}
                >
                    <Body3Regular style={{ color: colors.LAVEL_3 }}>{'하루종일 막기'}</Body3Regular>

                    <NormalToggleButton isActive={isAllDayBlock} callBack={onClickToggleAllDayBlock} />
                </Row>

                <Row style={{ width: 470, alignItems: 'center', justifyContent: 'space-between' }}>
                    {timeList && (
                        <InputSquareDropDown
                            selectedGender={startTime}
                            genderOptions={timeList}
                            handleGenderSelect={handleStartTimeSelect}
                            isDefaultScroll={true}
                        />
                    )}

                    <Body2Regular style={{ color: colors.BLUE_500 }}>~</Body2Regular>
                    {timeList && (
                        <InputSquareDropDown
                            selectedGender={endTime}
                            genderOptions={timeList}
                            handleGenderSelect={handleEndTimeSelect}
                            isDefaultScroll={true}
                        />
                    )}
                </Row>
                <CaptionLight style={{ color: colors.LAVEL_1, marginLeft: 8, marginTop: 16 }}>
                    *일정에 소요되는 시간만큼 추가해주세요!
                </CaptionLight>

                <Row
                    style={{
                        width: 470,
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: 16,
                    }}
                >
                    <WhiteRoundButton
                        customStyle={{ width: 60, height: 24 }}
                        text="-30분"
                        callBack={() => {
                            onClickSubTime(30);
                        }}
                        size="tiny"
                    />
                    <WhiteRoundButton
                        customStyle={{ width: 60, height: 24 }}
                        text="-20분"
                        callBack={() => {
                            onClickSubTime(20);
                        }}
                        size="tiny"
                    />
                    <WhiteRoundButton
                        customStyle={{ width: 60, height: 24 }}
                        text="-10분"
                        callBack={() => {
                            onClickSubTime(10);
                        }}
                        size="tiny"
                    />

                    <ColorRoundButton
                        customStyle={{ width: 60, height: 24 }}
                        text="+10분"
                        callBack={() => {
                            onClickAddTime(10);
                        }}
                        size="tiny"
                    />
                    <ColorRoundButton
                        customStyle={{ width: 60, height: 24 }}
                        text="+20분"
                        callBack={() => {
                            onClickAddTime(20);
                        }}
                        size="tiny"
                    />
                    <ColorRoundButton
                        customStyle={{ width: 60, height: 24 }}
                        text="+30분"
                        callBack={() => {
                            onClickAddTime(30);
                        }}
                        size="tiny"
                    />
                </Row>
            </Col>
        </Col>
    );
};

export default CreateOffScheduleRight;
