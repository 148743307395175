import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Body3Regular } from '../../lib/font';
import { colors } from '../../lib/colors';
import { I_SEARCH } from '../../types/images';
import { Row } from '../../lib/utils';

// Styled Components
const DropdownContainer = styled.div`
    position: relative;
    display: inline-block;
    width: 382px;
`;

const DropdownButton = styled.input`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-between; */
    background-color: #fdfeff;
    color: #acacac;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    height: 48px;
    font-weight: 400;
    font-size: 18px;
    cursor: pointer;
    z-index: 2;
    border-bottom: 1px solid #a6bfef;

    &:hover {
        background-color: #f8fbff;
    }
`;

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideUp = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

const DropdownContent = styled.div<{ isOpen: boolean }>`
    position: absolute;
    background: #f0f6ff;
    width: 100%;
    max-height: 338px;
    overflow: auto;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 4px;
    top: 55px;
    animation: ${({ isOpen }) => (isOpen ? slideDown : slideUp)} 0.3s ease forwards;
    z-index: 1;
    cursor: pointer;
`;

const DropdownItem = styled.div`
    width: 98.9%;
    height: 48px;
    text-decoration: none;
    display: flex;
    align-items: center;
    background-color: #fdfeff;
    margin-top: 4px;
    padding-left: 14px;
    z-index: 1;
    &:hover {
        background-color: #f8fbff;
    }
`;

type Props = {
    dropDownOptions: any;
    handleSelectOption: any;
    searchText: string;
    onChangeText: any;
    placeholder: string;
    isDisabled?: boolean;
};

const InputSearchDropdown = ({
    dropDownOptions,
    handleSelectOption,
    onChangeText,
    searchText,
    placeholder,
    isDisabled,
}: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isHovered, setIsHovered] = useState(false);

    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isOpen) {
            // 모달이 열릴 때 body의 스크롤을 막음
            document.body.style.overflow = 'hidden';
        } else {
            // 모달이 닫힐 때 body의 스크롤을 복원
            document.body.style.overflow = 'auto';
        }

        // 컴포넌트가 언마운트될 때 스크롤을 복원
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    return (
        <DropdownContainer ref={dropdownRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <DropdownButton
                disabled={isDisabled}
                placeholder={placeholder}
                value={searchText}
                onChange={onChangeText}
                onClick={toggleDropdown}
            />

            {isOpen && (
                <DropdownContent
                    style={{
                        paddingTop: dropDownOptions.length === 0 ? 0 : 4,
                        paddingBottom: dropDownOptions.length === 0 ? 0 : 8,

                        border: dropDownOptions.length === 0 ? 'none' : '1px solid #a6bfef',
                    }}
                    isOpen={isOpen}
                >
                    {dropDownOptions &&
                        dropDownOptions.map((option: any, index: number) => (
                            <DropdownItem
                                key={option.value}
                                onClick={() => {
                                    handleSelectOption(option);
                                    toggleDropdown();
                                }}
                            >
                                <Body3Regular style={{ color: colors.LAVEL_4 }}>{option.label}</Body3Regular>
                            </DropdownItem>
                        ))}
                </DropdownContent>
            )}
        </DropdownContainer>
    );
};

export default InputSearchDropdown;
