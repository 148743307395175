import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
import RegisterScheduleHeader from './ReigsterScheduleHeader';
import ManagerName from './ManagerName';
import ScheduleTitle from './ScheduleTitle';
import ScheduleDate from './ScheduleDate';
import ScheduleTime from './ScheduleTime';
import AddScheduleTime from './AddScheduleTime';
import MutateButton from './MutateButton';
import { createGeneralScheduleAPI, deleteGeneralScheduleAPI, modifyGeneralScheduleAPI } from '../../../api/schedule';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { concatDateAndTimeDayjs, getItemWithExpiration, isSameDate } from '../../../lib/utils';

const animation = keyframes`
  0% {
    transform: translateY(500px);
    opacity: 0;
  }

  100%{
    transform: translateX(0);
    opacity: 1;
  }
`;

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    animation: ${animation} 0.5s ease-out;
`;

const ModalInner = styled.div`
    background: white;
    width: 700px;
    height: 780px;
    right: 0;
    border-radius: 24px;
    box-shadow: 4px 4px 12px 0px #0000001f;
    background: #f5f9ff;
    display: flex;
    flex-direction: column;
`;

type RefineDate = {
    refineStartTime: string;
    refineEndTime: string;
};

type Props = {
    selectedDay: any;
    selectedSchedule: any;
    setSelectedSchedule: any;
    setIsRegisterModal: Dispatch<SetStateAction<boolean>>;
    createAlert: any;
    mutateType: string;
    createTwoButtonAlert: any;
};
const ModalRegisterSchedule = ({
    selectedDay,
    selectedSchedule,
    setSelectedSchedule,
    setIsRegisterModal,
    createAlert,
    mutateType,
    createTwoButtonAlert,
}: Props) => {
    const containerRef: any = useRef(null);
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));
    const queryClient = useQueryClient();

    const [timeList, setTimeList] = useState<any>(null);
    const [text, setText] = useState(selectedSchedule?.title ? selectedSchedule?.title : '');
    const [startTime, setStartTime] = useState<any>(
        selectedSchedule.scheduleStartTime ? selectedSchedule.scheduleStartTime : dayjs()
    );
    const [endTime, setEndTime] = useState<any>(
        selectedSchedule.scheduleEndTime
            ? selectedSchedule.scheduleEndTime
            : dayjs(selectedSchedule.scheduleStartTime).add(50, 'minute')
    );
    const [isStartTime, setIsStartTime] = useState<boolean>(false);
    const [isEndTime, setIsEndTime] = useState<boolean>(false);
    const [isDisabledModify, setIsDisabledModify] = useState<boolean>(false);

    const onClickExit = () => {
        setIsRegisterModal(false);
        setSelectedSchedule(null);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };

    const clearState = () => {
        setText('');
        setSelectedSchedule(null);
        setStartTime(null);
        setEndTime(null);
    };

    // 시간추가
    const onClickAddTime = (addNumber: number) => {
        if (dayjs(startTime).format('YYYY-MM-DD') === dayjs(endTime).add(addNumber, 'minute').format('YYYY-MM-DD')) {
            setEndTime(dayjs(endTime).add(addNumber, 'minute'));
        } else {
            createAlert('입력 오류', '추가 가능한 시간을 초과하였습니다', '확인', () => {});
        }
    };

    // 시간 선택 드롭다운 사라지게
    const onClickOutSide = (event: any) => {
        if (containerRef.current && !event.target.classList.contains('exclude')) {
            if (isStartTime || isEndTime) {
                setIsStartTime(false);
                setIsEndTime(false);
            } else {
                return;
            }
        }
    };

    // 일정등록 버튼
    const createEtcSchedule = () => {
        const concatStartTime = dayjs(concatDateAndTimeDayjs(selectedDay, startTime));
        const concatEndTime = dayjs(concatDateAndTimeDayjs(selectedDay, endTime));
        const refineStartTime = dayjs(concatStartTime).toISOString();
        const refineEndTime =
            dayjs(concatEndTime).format('HH:mm') === '00:00'
                ? dayjs(concatEndTime).add(1, 'day').toISOString()
                : dayjs(concatEndTime).toISOString();

        if (isSameDate(dayjs(refineStartTime), dayjs(refineEndTime))) {
            createAlert('입력 오류', '시작 시간과 완료 시간이 같습니다!\n다시 한번 확인해주세요!', '확인', () => {});
            return;
        }

        if (dayjs(refineStartTime).isAfter(dayjs(refineEndTime))) {
            createAlert(
                '입력 오류',
                '시작 시간이 완료 시간보다 늦을 수  없습니다!\n다시 한번 확인해주세요!',
                '확인',
                () => {}
            );
            return;
        }

        if (text.trim().length < 1) {
            createAlert('입력 오류', '일정 제목을 입력해주세요!', '확인', () => {});
            return;
        }
        const refineDate: RefineDate = {
            refineStartTime: refineStartTime,
            refineEndTime: refineEndTime,
        };

        createGeneralSchedule.mutate(refineDate);
    };

    // 일정삭제 버튼
    const deleteEtcSchedule = () => {
        createTwoButtonAlert(
            '일반 일정 삭제',
            `해당 일반일정을 삭제하시겠습니까?`,
            '취소',
            '삭제',
            () => {},
            () => {
                deleteGeneralSchedule.mutate();
            }
        );
    };

    // 일정수정 버튼
    const modifyEtcSchedule = () => {
        const concatStartTime = dayjs(concatDateAndTimeDayjs(selectedDay, startTime));
        const concatEndTime = dayjs(concatDateAndTimeDayjs(selectedDay, endTime));
        const refineStartTime = dayjs(concatStartTime).toISOString();
        const refineEndTime =
            dayjs(concatEndTime).format('HH:mm') === '00:00'
                ? dayjs(concatEndTime).add(1, 'day').toISOString()
                : dayjs(concatEndTime).toISOString();

        if (isSameDate(dayjs(refineStartTime), dayjs(refineEndTime))) {
            createAlert('', '시작 시간과 완료 시간이 같습니다!\n다시 한번 확인해주세요!', '확인', () => {});
            return;
        }

        if (dayjs(refineStartTime).isAfter(dayjs(refineEndTime))) {
            createAlert('', '시작 시간이 완료 시간보다 늦을 수  없습니다!\n다시 한번 확인해주세요!', '확인', () => {});
            return;
        }

        if (text.trim().length < 1) {
            createAlert('', '일정 제목을 입력해주세요!', '확인', () => {});
            return;
        }
        const refineDate: RefineDate = {
            refineStartTime: refineStartTime,
            refineEndTime: refineEndTime,
        };

        modifyGeneralSchedule.mutate(refineDate);
    };

    // 일반일정 생성 API : (POST)
    const createGeneralSchedule = useMutation({
        mutationFn: async (refineDate: RefineDate) =>
            await createGeneralScheduleAPI(
                authObject?.centerId,
                selectedSchedule?.coachId,
                text,
                refineDate?.refineStartTime,
                refineDate?.refineEndTime,
                authorization
            ),
        onSuccess: (res) => {
            if (res.status === 201) {
                setIsRegisterModal(false);
                clearState();
                queryClient.invalidateQueries({ queryKey: ['getDailyScheduleAPI'] });
                queryClient.invalidateQueries({ queryKey: ['getWeeklyScheduleAPI'] });
            }
            if (res.status === 400) {
                createAlert('', '해당 시간에는 스케줄을 생성 할 수 없습니다', '확인', () => {});
            }

            if (res.status === 410 || res.status === 420 || res.status === 430) {
                createAlert(
                    '',
                    '해당 시간에 이미 스케줄이 등록되어있습니다!\n다시 한번 확인해주세요!',
                    '확인',
                    () => {}
                );
            }
        },
        onError: () => {
            setIsRegisterModal(false);
            clearState();
            createAlert('', '오류가 발생하였습니다!\n다시 한번 등록해주세요!', '확인', () => {});
            queryClient.invalidateQueries({ queryKey: ['getDailyScheduleAPI'] });
            queryClient.invalidateQueries({ queryKey: ['getWeeklyScheduleAPI'] });
        },
    });

    // 일반일정 삭제 API : (DELETE)
    const deleteGeneralSchedule = useMutation({
        mutationFn: async () =>
            await deleteGeneralScheduleAPI(authObject?.centerId, selectedSchedule?.oneTimeScheduleId, authorization),
        onSuccess: (res) => {
            setIsRegisterModal(false);
            clearState();
            queryClient.invalidateQueries({ queryKey: ['getDailyScheduleAPI'] });
            queryClient.invalidateQueries({ queryKey: ['getWeeklyScheduleAPI'] });
        },
        onError: () => {
            setIsRegisterModal(false);
            clearState();
            createAlert('', '오류가 발생하였습니다!\n다시 한번 등록해주세요!', '확인', () => {});
            queryClient.invalidateQueries({ queryKey: ['getDailyScheduleAPI'] });
            queryClient.invalidateQueries({ queryKey: ['getWeeklyScheduleAPI'] });
        },
    });

    // 일반일정 수정 API : (PATCH)
    const modifyGeneralSchedule = useMutation({
        mutationFn: async (refineDate: RefineDate) =>
            await modifyGeneralScheduleAPI(
                authObject?.centerId,
                selectedSchedule?.oneTimeScheduleId,
                selectedSchedule?.coachId,
                text,
                refineDate?.refineStartTime,
                refineDate?.refineEndTime,
                authorization
            ),
        onSuccess: (res) => {
            if (res.status === 200) {
                setIsRegisterModal(false);
                clearState();
                queryClient.invalidateQueries({ queryKey: ['getDailyScheduleAPI'] });
                queryClient.invalidateQueries({ queryKey: ['getWeeklyScheduleAPI'] });
            }
            if (res.status === 400) {
                createAlert('', '해당 시간에는 스케줄을 생성 할 수 없습니다', '확인', () => {});
            }

            if (res.status === 410 || res.status === 420 || res.status === 430) {
                createAlert(
                    '',
                    '해당 시간에 이미 스케줄이 등록되어있습니다!\n다시 한번 확인해주세요!',
                    '확인',
                    () => {}
                );
            }
        },
        onError: () => {
            setIsRegisterModal(false);
            clearState();
            createAlert('', '오류가 발생하였습니다!\n다시 한번 등록해주세요!', '확인', () => {});
            queryClient.invalidateQueries({ queryKey: ['getDailyScheduleAPI'] });
            queryClient.invalidateQueries({ queryKey: ['getWeeklyScheduleAPI'] });
        },
    });

    // 시간 10분단위로 생성
    useEffect(() => {
        // 시간생성
        const generateTimeSlots = () => {
            const timeSlots = [];
            const start = dayjs().startOf('day').hour(0).minute(0);
            for (let i = 0; i < 144; i++) {
                // 24 hours * 6 (10분 간격)
                const time = start.add(i * 10, 'minute');
                const formattedTime = time.format('A hh:mm'); // 오전/오후 표시
                timeSlots.push({ name: formattedTime, value: time });
            }
            return timeSlots;
        };

        const timeSlots = generateTimeSlots();
        setTimeList(timeSlots);
    }, []);

    // 시작 시간이 변할때 endTime정제
    useEffect(() => {
        if (startTime && !isSameDate(dayjs(startTime), dayjs(selectedSchedule?.scheduleStartTime))) {
            const refineStartTime = concatDateAndTimeDayjs(selectedDay, startTime);
            const add50Min: Dayjs = dayjs(refineStartTime).add(50, 'minute');

            if (
                add50Min.isAfter(dayjs(selectedDay).endOf('day')) ||
                isSameDate(add50Min, dayjs(selectedDay).endOf('day'))
            ) {
                setEndTime(dayjs().endOf('day').add(1, 'millisecond'));
            } else {
                setEndTime(add50Min);
            }
        }
    }, [startTime]);

    // 수정으로 진입시 변경감지 후 변경일때만 수정버튼 활성화
    useEffect(() => {
        if (selectedSchedule && mutateType === 'modify') {
            if (
                selectedSchedule.title !== text ||
                selectedSchedule.scheduleStartTime !== startTime ||
                selectedSchedule.scheduleEndTime !== endTime
            ) {
                setIsDisabledModify(false);
            } else {
                setIsDisabledModify(true);
            }
        }
    }, [selectedSchedule, text, startTime, endTime]);

    return (
        <ModalOverLay onClick={onClickOutSide} ref={containerRef}>
            <ModalInner>
                {/* 헤더 */}
                <RegisterScheduleHeader onClickExit={onClickExit} />
                {/* 일정 제목  */}
                <ScheduleTitle text={text} handleChange={handleChange} />
                {/* 담당자 */}
                <ManagerName selectedSchedule={selectedSchedule} />
                {/* 날짜 */}
                <ScheduleDate startTime={startTime} selectedDay={selectedDay} />
                {/* 시간 */}
                <ScheduleTime
                    startTime={startTime}
                    setStartTime={setStartTime}
                    endTime={endTime}
                    setEndTime={setEndTime}
                    timeList={timeList}
                    isStartTime={isStartTime}
                    setIsStartTime={setIsStartTime}
                    isEndTime={isEndTime}
                    setIsEndTime={setIsEndTime}
                />
                {/* 시간추가 버튼 */}
                <AddScheduleTime onClickAddTime={onClickAddTime} />
                {/* 수정 삭제 등록  */}
                <MutateButton
                    mutateType={mutateType}
                    isDisabledModify={isDisabledModify}
                    createEtcSchedule={createEtcSchedule}
                    deleteEtcSchedule={deleteEtcSchedule}
                    modifyEtcSchedule={modifyEtcSchedule}
                />
            </ModalInner>
        </ModalOverLay>
    );
};

export default ModalRegisterSchedule;
