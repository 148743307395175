import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { Col } from '../../lib/utils';
import NormalButton from '../../components/Button/NormalButton';

const FailPurchaseAtExpired = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate();

    useEffect(() => {
        secureLocalStorage.removeItem('trainerNumber');
    }, []);

    return (
        <div>
            <Col style={{ marginLeft: 650, marginTop: 450, width: 900, alignItems: 'center' }}>
                <p style={{ fontSize: 25 }}>{queryParams.get('message')}</p>
                <NormalButton
                    style={{ marginTop: 40 }}
                    text="다시 결제하기"
                    onClick={() => {
                        navigate('/expired', { replace: true });
                    }}
                />
            </Col>
        </div>
    );
};

export default FailPurchaseAtExpired;
