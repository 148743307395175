import React, { useState } from 'react';
import { Body2Regular, Body3Regular, CaptionRegular } from '../../lib/font';
import { Col, Row } from '../../lib/utils';
import Dot from '../Dot';
import SvgIcon from '../SvgIcon';
import { colors } from '../../lib/colors';

type FormTextInputProps = {
    title: string;
    placeholder?: string;
    value: string;
    onChangeText: (text: string) => void;
    isRequired?: boolean;
    subTitle?: string;
    keyboardType?: 'default' | 'numeric' | 'email-address' | 'phone-pad';
    width?: number | string;
    subtitlePosition?: 'right' | 'bottom';
    mb?: number;
    mr?: number;
    isClearIcon?: boolean;
    disabled?: boolean;
    maxLength?: number;
};

const FormTextInput = ({
    title,
    placeholder,
    value,
    onChangeText,
    isRequired = false,
    subTitle = undefined,
    keyboardType = 'default',
    width: _width = '100%',
    subtitlePosition = 'bottom',
    mb,
    mr,
    isClearIcon = true,
    disabled = false,
    maxLength,
}: FormTextInputProps) => {
    // input focus
    const [focus, setFocus] = useState(false);
    return (
        <Col
            style={{
                width: _width,
                marginBottom: mb,
                marginRight: mr,
            }}
        >
            <Row className={`flex items-center  mb-[8px]`}>
                <Body3Regular className=" text-LAVEL_4 mr-[4px]">{title}</Body3Regular>
                {isRequired && <Dot size={4} color="ERROR" className=" self-start mr-[12px]" />}
                {subtitlePosition === 'right' && subTitle && (
                    <CaptionRegular className=" text-LAVEL_2">{subTitle}</CaptionRegular>
                )}
            </Row>
            {subtitlePosition === 'bottom' && subTitle && (
                <CaptionRegular className=" text-LAVEL_2 mb-[12px]">{subTitle}</CaptionRegular>
            )}
            <Row
                className={` items-center border-solid border-b border-BLUE_200 py-[8px] px-[8px] ${
                    focus && 'border-BLUE_500'
                } ${disabled && 'bg-[#f2f2f2] border-[#acacac]'} rounded-sm `}
            >
                <input
                    type={keyboardType === 'numeric' ? 'number' : 'text'}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => onChangeText(e.target.value)}
                    className="w-full  text-[18px] leading-[26px] text-LAVEL_3"
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    maxLength={maxLength}
                    disabled={disabled}
                />
                {value && isClearIcon && !disabled && (
                    <SvgIcon
                        name="SvgCircleX"
                        size={18}
                        fill={colors.WHITE_600}
                        onClick={() => onChangeText('')}
                        className=" cursor-pointer"
                    />
                )}
            </Row>
        </Col>
    );
};

export default FormTextInput;
