import React from 'react';
import { Col, Row } from '../../../lib/utils';
import { Body2Bold, CaptionBold, CaptionLight, OverlineRegular } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import styled from 'styled-components';

// &:hover {
//     transform: scale(1.1);
// }

const Container = styled.div`
    width: 264px;
    height: 196px;
    margin-bottom: 24px;
    margin-right: 23px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 2px 2px 12px 0px #00000029;
    &:hover {
        transform: scale(1.02);
    }
`;

const MembershipTemplate = ({ index, item, onClickDetailPage }: any) => {
    const isCoach = item.issuer.issuerRole === 'coach';
    return (
        <Container onClick={() => onClickDetailPage(item)}>
            <Col
                style={{
                    width: 264,
                    height: 136,
                    background: `linear-gradient(135deg, ${item.BGColor[0]} 0%, ${item.BGColor[1]} 100%)`,
                    alignItems: 'flex-start',
                    paddingTop: 18,
                    paddingLeft: 24,
                    paddingBottom: 14,
                    paddingRight: 16,
                }}
                className="flex relative"
            >
                <Col
                    style={{
                        justifyContent: 'center',
                        whiteSpace: 'pre-wrap',
                        color: colors.LAVEL_0,
                        height: 56,
                    }}
                >
                    <Body2Bold>{item?.name.length > 27 ? `${item?.name.substring(0, 27)}...` : item?.name}</Body2Bold>
                </Col>
                <Row className="flex w-[230px] items-center absolute bottom-[14px] justify-between ">
                    <Col>
                        <CaptionBold
                            style={{ color: colors.LAVEL_0 }}
                        >{`판매금액 : ${(item?.totalPrice).toLocaleString()}원`}</CaptionBold>

                        <OverlineRegular style={{ marginTop: 4, color: colors.LAVEL_0 }}>
                            {`회당 가격 : ${(item?.pricePerSchedule).toLocaleString()}원`}
                        </OverlineRegular>
                    </Col>
                    {isCoach && (
                        <Col className="flex border-[1px] border-solid border-white  rounded-full px-[8.5px] py-[6px]">
                            <CaptionLight className="text-LAVEL_0">강사생성</CaptionLight>
                        </Col>
                    )}
                </Row>
            </Col>
            <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                <Row
                    style={{
                        width: 196,
                        height: 60,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Col style={{ width: 44, height: 34, justifyContent: 'space-between' }}>
                        <OverlineRegular style={{ color: colors.LAVEL_3 }}>{'총 회차'}</OverlineRegular>
                        <CaptionBold style={{ color: colors.LAVEL_4 }}>{`${item?.totalSession}회`}</CaptionBold>
                    </Col>
                    <Col style={{ width: 44, height: 34, justifyContent: 'space-between' }}>
                        <OverlineRegular style={{ color: colors.LAVEL_3 }}>{'기간'}</OverlineRegular>
                        <CaptionBold style={{ color: colors.LAVEL_4 }}>{`${item?.validDays}일`}</CaptionBold>
                    </Col>
                    <Col style={{ width: 44, height: 34, justifyContent: 'space-between' }}>
                        <OverlineRegular style={{ color: colors.LAVEL_3 }}>{'시간'}</OverlineRegular>
                        <CaptionBold style={{ color: colors.LAVEL_4 }}>{`${item?.period}분`}</CaptionBold>
                    </Col>
                </Row>
            </Row>
        </Container>
    );
};

export default MembershipTemplate;
