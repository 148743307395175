import React, { useState } from 'react';
import './TruncateText.css';
import { Body3Bold, Body3Regular, CaptionRegular } from './font';
import dayjs from 'dayjs';

interface TruncateTextProps {
    text: string;
    type?: string;
    time?: string;
    maxLength: number;
    children?: React.ReactNode; // children 추가
}

const TruncateText: React.FC<TruncateTextProps> = ({ text, maxLength }) => {
    const textWithoutLineBreak = text.replace(/\n/g, ' ');
    const firstLine = textWithoutLineBreak.substring(0, 16);
    const secondLine = textWithoutLineBreak.substring(16, 32);
    const isTruncated = textWithoutLineBreak.length > maxLength;
    const displayText = isTruncated ? `${firstLine}\n${secondLine}...` : `${firstLine}\n${secondLine}`;
    return (
        <div className="truncate-text">
            <CaptionRegular style={{ whiteSpace: 'pre-line' }}>{displayText}</CaptionRegular>
        </div>
    );
};

export default TruncateText;
