import React from 'react';
import { Col, Row, truncateString } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import ProfileImage from '../../ProfileImage';
import { Body3Bold, Body3Light, Body3Regular, CaptionRegular } from '../../../lib/font';
import { I_BOOKMARK, I_RIGHT_PRIMARY_BTN } from '../../../types/images';
import dayjs from 'dayjs';

const FeedReply = ({ initialPost, item }: any) => {
    const textObject = initialPost?.contents?.filter((item: any) => item.contentType === 'TEXT');

    return (
        <Col
            style={{
                width: 714,
                backgroundColor: colors.WHITE_200,
                alignSelf: 'center',
                borderRadius: 24,
                marginTop: 16,
                paddingBottom: 16,
                border: '1px solid #D4DBE8',
            }}
        >
            <img src={I_BOOKMARK} style={{ width: 32, height: 40, position: 'absolute', marginLeft: 650 }} />
            <Row
                style={{
                    width: 714,
                    justifyContent: 'space-between',
                    paddingLeft: 36,
                    paddingRight: 36,
                    paddingTop: 24,
                    alignItems: 'flex-end',
                }}
            >
                <Row style={{ height: 48 }}>
                    <ProfileImage
                        imageUrl={initialPost?.profileImageUrl}
                        type="Middle"
                        profileName={initialPost?.profileName}
                        profileColorType={initialPost?.profileColorType}
                        right={16}
                    />

                    <Col style={{ height: 48, justifyContent: 'center' }}>
                        <Row style={{ alignItems: 'center' }}>
                            <Body3Bold style={{ color: colors.LAVEL_3 }}>{`${initialPost?.writerName} 강사`}</Body3Bold>
                            <img
                                src={I_RIGHT_PRIMARY_BTN}
                                style={{ width: 16, height: 16, marginLeft: 8, marginRight: 8 }}
                            />
                            <Body3Bold style={{ color: colors.POINT_1 }}>{'공지글'}</Body3Bold>
                        </Row>
                        <CaptionRegular style={{ height: 20 }}></CaptionRegular>

                        {/* <Body3Regular>{`회원 총 ${item?.totalMemberCount}명`}</Body3Regular> */}
                    </Col>
                </Row>
                <Body3Light>{dayjs(initialPost?.createdTime).format('YY/MM/DD(ddd)/A HH:mm')}</Body3Light>
            </Row>

            <Col
                style={{
                    width: 714,
                    paddingLeft: 36,
                    paddingRight: 36,
                    alignSelf: 'center',
                    marginTop: 16,
                    flexWrap: 'wrap',
                }}
            >
                <Body3Regular style={{ color: colors.LAVEL_2 }}>
                    {textObject && truncateString(textObject[0].content, 140)}
                </Body3Regular>
            </Col>
        </Col>
    );
};

export default FeedReply;
