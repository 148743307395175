import Header from '../components/Header';
import DashBoardTodayHistory from '../components/DashBoard/DashBoardTodayHistory';
import DashBoardClassProgress from '../components/DashBoard/DashBoardClassProgress/DashBoardClassProgress';
import DashBoardMemberShipProgress from '../components/DashBoard/DashBoardMemberShipProgress/DashBoardMemberShipProgress';
import DashBoardActiveMembers from '../components/DashBoard/DashBoardActiveMembers/DashBoardActiveMembers';
import { useState, useEffect } from 'react';
import { Col, getItemWithExpiration } from '../lib/utils';
import styled from 'styled-components';
import { I_LEFT_BRACKET, I_LEFT_BRACKET_HOVER, I_RIGHT_BRACKET, I_RIGHT_BRACKET_HOVER } from '../types/images';
import { useMutation } from '@tanstack/react-query';
import { storeUserFlow } from '../api/adminuserflow';

export const LeftButton = styled.button`
    width: 21px;
    height: 58px;
    background-image: url(${I_LEFT_BRACKET});
    &:hover {
        background-image: url(${I_LEFT_BRACKET_HOVER});
    }
`;

export const RightButton = styled.button`
    width: 21px;
    height: 58px;
    background-image: url(${I_RIGHT_BRACKET});
    &:hover {
        background-image: url(${I_RIGHT_BRACKET_HOVER});
    }
`;

const DashboardPage = (createAlert: any) => {
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));

    // 접속 데이터 측정 API
    const pushUserFlow = useMutation({
        mutationFn: async () => await storeUserFlow('dash', authorization),
    });

    useEffect(() => {
        try {
            if (pushUserFlow) {
                pushUserFlow.mutate();
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    return (
        <Col style={{ width: '100%', marginTop: 120, alignItems: 'center', paddingBottom: 300 }}>
            {/* 오늘의 수업 현황 / 신규등록 회원 / 소통상자 */}
            <DashBoardTodayHistory authorization={authorization} authObject={authObject} createAlert={createAlert} />

            {/* 강사별 수업 진행 현황 */}
            <DashBoardClassProgress authorization={authorization} authObject={authObject} />

            {/* 수강권 등록 */}
            <DashBoardMemberShipProgress authorization={authorization} authObject={authObject} />

            {/* 센터 내 회원권 활성 회원 수 */}
            <DashBoardActiveMembers authorization={authorization} authObject={authObject} />
        </Col>
    );
};

export default DashboardPage;
