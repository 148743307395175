import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Col, Row, truncateString } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import { Body1Regular, Body2Regular, Subtitle1Bold, Subtitle1Regular } from '../../../lib/font';
import dayjs from 'dayjs';
import { useRecoilState } from 'recoil';
import { sidebarStatusState } from '../../../atoms/atom';

// 모달 컴포넌트 스타일드
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const HoverRequestContainer = styled.div`
    position: absolute;
    width: 340px;
    height: 136px;
    border-radius: 8px;
    background-color: rgba(253, 254, 255, 1);
    border: none;
    box-shadow: 0px 0px 8px 2px rgba(24, 62, 134, 0.28);
    animation: ${fadeIn} 1s ease; /* 애니메이션 적용 */
    z-index: 9999;
`;

const HoverRequest = ({ childItem, parentItem, parentIndex }: any) => {
    const [sidebarStatus, setSidebarStatus] = useRecoilState(sidebarStatusState);
    const marginLeftStyle = sidebarStatus === 'open' ? 465 : 490;

    return (
        <HoverRequestContainer
            style={{
                marginTop: parseInt(dayjs(childItem.startTime).format('H')) > 20 ? -43 : 0,
                marginLeft: parentIndex > 4 ? -marginLeftStyle : marginLeftStyle,
            }}
        >
            <Row
                style={{
                    width: 340,
                    height: 52,
                    borderTopRightRadius: 8,
                    borderTopLeftRadius: 8,
                    backgroundColor: colors.CHANGE,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingLeft: 24,
                    paddingRight: 24,
                }}
            >
                <Subtitle1Bold style={{ color: colors.WHITE_50 }}>
                    {dayjs(childItem?.startTime).format('MM월 DD일(ddd)')}
                </Subtitle1Bold>

                <Row
                    style={{
                        borderRadius: 34,
                        border: '1px solid rgba(253, 254, 255, 1)',
                        paddingLeft: 10,
                        paddingRight: 10,
                    }}
                >
                    <Body2Regular style={{ color: colors.WHITE_50 }}>{`신청대기`}</Body2Regular>
                </Row>
            </Row>

            <Row
                style={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingLeft: 24,
                    paddingRight: 24,
                    marginTop: 8,
                }}
            >
                <Subtitle1Bold style={{ color: colors.BLUE_900 }}>{`${parentItem?.coachName} 강사`} </Subtitle1Bold>
                <Subtitle1Bold style={{ color: colors.BLUE_900 }}>{`${childItem?.alias} 회원`} </Subtitle1Bold>
            </Row>

            <Row
                style={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingLeft: 24,
                    paddingRight: 24,
                    marginTop: 8,
                }}
            >
                <Body1Regular style={{ color: colors.WHITE_900 }}>
                    {dayjs(childItem.startTime).format('A h:mm ~ ')}
                    {dayjs(childItem.endTime).format('h:mm')}
                </Body1Regular>
            </Row>
        </HoverRequestContainer>
    );
};

export default HoverRequest;
