import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { I_PLUS_REGISTER } from '../../../types/images';

const HoverComponent = styled.div`
    width: 100%;
    height: 20px;
`;
const HoverModal = styled.div`
    width: 90.3%;
    height: 60px;
    position: absolute;
    margin-left: 4.8%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 2px solid #ecf1fc; */
    border-radius: 4px;
    background: #ecf1fc;
    z-index: 9;
`;

const Container = styled.div`
    position: relative;
    width: 11.56%;
    height: 120px;
    border-width: 1px;

    box-shadow: 0 0 0 0.1px black;
`;

const EmptyHoverBox = ({ rowIndex, cellIndex, gridData, dailyCurrentData, onClickHover, selectedDay }: any) => {
    const [isFirstHovered, setIsFirstHovered] = useState<boolean>(false);
    const [isSecondHovered, setIsSecondHovered] = useState<boolean>(false);
    const [isThirdHovered, setIsThirdHovered] = useState<boolean>(false);
    const [isFourthHovered, setIsFourthHovered] = useState<boolean>(false);
    const [isFifthHovered, setIsFifthHovered] = useState<boolean>(false);
    const [isSixthHovered, setIsSixthHovered] = useState<boolean>(false);
    const [hour, setHour] = useState<any>('0');
    const [addHour, setAddHour] = useState<any>('0');

    useEffect(() => {
        let convertHour = rowIndex === 0 ? '00' : rowIndex < 10 ? '0' + rowIndex : rowIndex;
        let convertAddHour = rowIndex === 0 ? '1' : rowIndex + 1 < 10 ? '0' + (rowIndex + 1) : rowIndex + 1;
        setHour(convertHour);
        setAddHour(convertAddHour);
    }, []);
    return (
        <Container>
            {/* 0분 */}
            <HoverComponent
                onMouseEnter={() => {
                    const hoverStartTime = dayjs(selectedDay).format(`YYYY-MM-DD ${hour}:00`);
                    const hoverEndTime = dayjs(selectedDay).format(`YYYY-MM-DD ${hour}:30`);

                    const schedules = dailyCurrentData[cellIndex - 1]?.schedules;
                    let arr: any = [];
                    if (schedules) {
                        for (let i = 0; i < schedules.length; i++) {
                            const rangeStart = dayjs(schedules[i].startTime).format('YYYY-MM-DD HH:mm');
                            const rangeEnd = dayjs(schedules[i].endTime).format('YYYY-MM-DD HH:mm');

                            if (
                                dayjs(hoverEndTime).isAfter(rangeStart) &&
                                (dayjs(hoverEndTime).isSame(rangeEnd) || dayjs(hoverEndTime).isBefore(rangeEnd))
                            ) {
                                arr.push(true);
                            } else {
                                if (dayjs(rangeEnd).isBefore(hoverEndTime) && dayjs(rangeEnd).isAfter(hoverStartTime)) {
                                    arr.push(true);
                                } else {
                                    arr.push(false);
                                }
                            }
                        }
                        let findIndex = arr.findIndex((item: any) => item === true);
                        if (findIndex === -1) {
                            setIsFirstHovered(true);
                        } else {
                            setIsFirstHovered(false);
                        }
                    }
                }}
                onMouseLeave={() => {
                    setIsFirstHovered(false);
                }}
            >
                {gridData[0][cellIndex].coachName && isFirstHovered && (
                    <HoverModal
                        style={{}}
                        onClick={() => {
                            let dateToString = dayjs(selectedDay).format(`YYYY-MM-DD ${rowIndex}:00:00`);
                            onClickHover(
                                dayjs(dateToString),
                                dailyCurrentData[cellIndex - 1].coachName,
                                dailyCurrentData[cellIndex - 1].coachId
                            );
                        }}
                    >
                        <img style={{ width: 32, height: 32 }} src={I_PLUS_REGISTER} />
                    </HoverModal>
                )}
            </HoverComponent>
            {/* 10분 */}
            <HoverComponent
                onMouseEnter={() => {
                    const hoverStartTime = dayjs(selectedDay).format(`YYYY-MM-DD ${hour}:10`);
                    const hoverEndTime = dayjs(selectedDay).format(`YYYY-MM-DD ${hour}:40`);

                    const schedules = dailyCurrentData[cellIndex - 1]?.schedules;
                    let arr: any = [];
                    if (schedules) {
                        for (let i = 0; i < schedules.length; i++) {
                            const rangeStart = dayjs(schedules[i].startTime).format('YYYY-MM-DD HH:mm');
                            const rangeEnd = dayjs(schedules[i].endTime).format('YYYY-MM-DD HH:mm');

                            if (
                                dayjs(hoverEndTime).isAfter(rangeStart) &&
                                (dayjs(hoverEndTime).isSame(rangeEnd) || dayjs(hoverEndTime).isBefore(rangeEnd))
                            ) {
                                arr.push(true);
                            } else {
                                if (dayjs(rangeEnd).isBefore(hoverEndTime) && dayjs(rangeEnd).isAfter(hoverStartTime)) {
                                    arr.push(true);
                                } else {
                                    arr.push(false);
                                }
                            }
                        }
                        let findIndex = arr.findIndex((item: any) => item === true);
                        if (findIndex === -1) {
                            setIsSecondHovered(true);
                        } else {
                            setIsSecondHovered(false);
                        }
                    }
                }}
                onMouseLeave={() => {
                    setIsSecondHovered(false);
                }}
            >
                {gridData[0][cellIndex].coachName && isSecondHovered && (
                    <HoverModal
                        style={{
                            marginTop: 20,
                        }}
                        onClick={() => {
                            let dateToString = dayjs(selectedDay).format(`YYYY-MM-DD ${rowIndex}:10:00`);
                            onClickHover(
                                dayjs(dateToString),
                                dailyCurrentData[cellIndex - 1].coachName,
                                dailyCurrentData[cellIndex - 1].coachId
                            );
                        }}
                    >
                        <img style={{ width: 32, height: 32 }} src={I_PLUS_REGISTER} />
                    </HoverModal>
                )}
            </HoverComponent>
            {/* 20분 */}
            <HoverComponent
                onMouseEnter={() => {
                    const hoverStartTime = dayjs(selectedDay).format(`YYYY-MM-DD ${hour}:20`);
                    const hoverEndTime = dayjs(selectedDay).format(`YYYY-MM-DD ${hour}:50`);

                    const schedules = dailyCurrentData[cellIndex - 1]?.schedules;
                    let arr: any = [];
                    if (schedules) {
                        for (let i = 0; i < schedules.length; i++) {
                            const rangeStart = dayjs(schedules[i].startTime).format('YYYY-MM-DD HH:mm');
                            const rangeEnd = dayjs(schedules[i].endTime).format('YYYY-MM-DD HH:mm');

                            if (
                                dayjs(hoverEndTime).isAfter(rangeStart) &&
                                (dayjs(hoverEndTime).isSame(rangeEnd) || dayjs(hoverEndTime).isBefore(rangeEnd))
                            ) {
                                arr.push(true);
                            } else {
                                if (dayjs(rangeEnd).isBefore(hoverEndTime) && dayjs(rangeEnd).isAfter(hoverStartTime)) {
                                    arr.push(true);
                                } else {
                                    arr.push(false);
                                }
                            }
                        }
                        let findIndex = arr.findIndex((item: any) => item === true);
                        if (findIndex === -1) {
                            setIsThirdHovered(true);
                        } else {
                            setIsThirdHovered(false);
                        }
                    }
                }}
                onMouseLeave={() => {
                    setIsThirdHovered(false);
                }}
            >
                {gridData[0][cellIndex].coachName && isThirdHovered && (
                    <HoverModal
                        style={{
                            marginTop: 40,
                        }}
                        onClick={() => {
                            let dateToString = dayjs(selectedDay).format(`YYYY-MM-DD ${rowIndex}:20:00`);
                            onClickHover(
                                dayjs(dateToString),
                                dailyCurrentData[cellIndex - 1].coachName,
                                dailyCurrentData[cellIndex - 1].coachId
                            );
                        }}
                    >
                        <img style={{ width: 32, height: 32 }} src={I_PLUS_REGISTER} />
                    </HoverModal>
                )}
            </HoverComponent>
            {/* 30분 */}
            <HoverComponent
                onMouseEnter={() => {
                    const hoverStartTime = dayjs(selectedDay).format(`YYYY-MM-DD ${hour}:30`);
                    const hoverEndTime = dayjs(selectedDay).format(`YYYY-MM-DD ${parseInt(hour) + 1}:00`);

                    const schedules = dailyCurrentData[cellIndex - 1]?.schedules;
                    let arr: any = [];
                    if (schedules) {
                        for (let i = 0; i < schedules.length; i++) {
                            const rangeStart = dayjs(schedules[i].startTime).format('YYYY-MM-DD HH:mm');
                            const rangeEnd = dayjs(schedules[i].endTime).format('YYYY-MM-DD HH:mm');

                            if (
                                dayjs(hoverEndTime).isAfter(rangeStart) &&
                                (dayjs(hoverEndTime).isSame(rangeEnd) || dayjs(hoverEndTime).isBefore(rangeEnd))
                            ) {
                                arr.push(true);
                            } else {
                                if (dayjs(rangeEnd).isBefore(hoverEndTime) && dayjs(rangeEnd).isAfter(hoverStartTime)) {
                                    arr.push(true);
                                } else {
                                    arr.push(false);
                                }
                            }
                        }
                        let findIndex = arr.findIndex((item: any) => item === true);
                        if (findIndex === -1) {
                            setIsFourthHovered(true);
                        } else {
                            setIsFourthHovered(false);
                        }
                    }
                }}
                onMouseLeave={() => {
                    setIsFourthHovered(false);
                }}
            >
                {gridData[0][cellIndex].coachName && isFourthHovered && (
                    <HoverModal
                        style={{
                            marginTop: 60,
                        }}
                        onClick={() => {
                            let dateToString = dayjs(selectedDay).format(`YYYY-MM-DD ${rowIndex}:30:00`);
                            onClickHover(
                                dayjs(dateToString),
                                dailyCurrentData[cellIndex - 1].coachName,
                                dailyCurrentData[cellIndex - 1].coachId
                            );
                        }}
                    >
                        <img style={{ width: 32, height: 32 }} src={I_PLUS_REGISTER} />
                    </HoverModal>
                )}
            </HoverComponent>
            {/* 40분 */}
            <HoverComponent
                onMouseEnter={() => {
                    const hoverStartTime = dayjs(selectedDay).format(`YYYY-MM-DD ${hour}:40`);
                    const hoverEndTime = dayjs(selectedDay).format(`YYYY-MM-DD ${parseInt(hour) + 1}:10`);

                    const schedules = dailyCurrentData[cellIndex - 1]?.schedules;
                    let arr: any = [];
                    if (schedules) {
                        for (let i = 0; i < schedules.length; i++) {
                            const rangeStart = dayjs(schedules[i].startTime).format('YYYY-MM-DD HH:mm');
                            const rangeEnd = dayjs(schedules[i].endTime).format('YYYY-MM-DD HH:mm');

                            if (
                                dayjs(hoverEndTime).isAfter(rangeStart) &&
                                (dayjs(hoverEndTime).isSame(rangeEnd) || dayjs(hoverEndTime).isBefore(rangeEnd))
                            ) {
                                arr.push(true);
                            } else {
                                if (dayjs(rangeEnd).isBefore(hoverEndTime) && dayjs(rangeEnd).isAfter(hoverStartTime)) {
                                    arr.push(true);
                                } else {
                                    arr.push(false);
                                }
                            }
                        }
                        let findIndex = arr.findIndex((item: any) => item === true);
                        if (findIndex === -1) {
                            setIsFifthHovered(true);
                        } else {
                            setIsFifthHovered(false);
                        }
                    }
                }}
                onMouseLeave={() => {
                    setIsFifthHovered(false);
                }}
            >
                {gridData[0][cellIndex].coachName && isFifthHovered && (
                    <HoverModal
                        style={{
                            marginTop: 80,
                        }}
                        onClick={() => {
                            let dateToString = dayjs(selectedDay).format(`YYYY-MM-DD ${rowIndex}:40:00`);
                            onClickHover(
                                dayjs(dateToString),
                                dailyCurrentData[cellIndex - 1].coachName,
                                dailyCurrentData[cellIndex - 1].coachId
                            );
                        }}
                    >
                        <img style={{ width: 32, height: 32 }} src={I_PLUS_REGISTER} />
                    </HoverModal>
                )}
            </HoverComponent>
            {/* 50분 */}
            <HoverComponent
                onMouseEnter={() => {
                    const hoverStartTime = dayjs(selectedDay).format(`YYYY-MM-DD ${hour}:50`);
                    const hoverEndTime = dayjs(selectedDay).format(`YYYY-MM-DD ${parseInt(hour) + 1}:20`); // 내가 마우스 올린 끝시간

                    const schedules = dailyCurrentData[cellIndex - 1]?.schedules;

                    let arr: any = [];
                    if (schedules) {
                        for (let i = 0; i < schedules.length; i++) {
                            const rangeStart = dayjs(schedules[i].startTime).format('YYYY-MM-DD HH:mm');
                            const rangeEnd = dayjs(schedules[i].endTime).format('YYYY-MM-DD HH:mm');

                            if (
                                dayjs(hoverEndTime).isAfter(rangeStart) &&
                                (dayjs(hoverEndTime).isSame(rangeEnd) || dayjs(hoverEndTime).isBefore(rangeEnd))
                            ) {
                                arr.push(true);
                            } else {
                                if (dayjs(rangeEnd).isBefore(hoverEndTime) && dayjs(rangeEnd).isAfter(hoverStartTime)) {
                                    arr.push(true);
                                } else {
                                    arr.push(false);
                                }
                            }
                        }
                        let findIndex = arr.findIndex((item: any) => item === true);
                        if (findIndex === -1) {
                            setIsSixthHovered(true);
                        } else {
                            setIsSixthHovered(false);
                        }
                    }
                }}
                onMouseLeave={() => {
                    setIsSixthHovered(false);
                }}
            >
                {gridData[0][cellIndex].coachName && isSixthHovered && (
                    <HoverModal
                        style={{
                            marginTop: 100,
                        }}
                        onClick={() => {
                            let dateToString = dayjs(selectedDay).format(`YYYY-MM-DD ${rowIndex}:50:00`);
                            onClickHover(
                                dayjs(dateToString),
                                dailyCurrentData[cellIndex - 1].coachName,
                                dailyCurrentData[cellIndex - 1].coachId
                            );
                        }}
                    >
                        <img style={{ width: 32, height: 32 }} src={I_PLUS_REGISTER} />
                    </HoverModal>
                )}
            </HoverComponent>
        </Container>
    );
};

export default EmptyHoverBox;
