import React, { useState } from 'react';
import { Body2Bold, Body3Bold, Body3Light, Body3Regular, CaptionBold, CaptionRegular } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import { Col, Row } from '../../../lib/utils';
import dayjs from 'dayjs';
import { I_TODAY_TRIANGLE } from '../../../types/images';
import styled, { keyframes } from 'styled-components';
import _ from 'lodash';

// 애니메이션 정의
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

interface StyledColProps {
    backgroundColor: string;
    isVisible: boolean;
}

// 스타일드 컴포넌트 정의
const HoverItem = styled.div<StyledColProps>`
    width: 300px;
    height: 258px;
    z-index: 99;

    background-color: ${(props) => props.backgroundColor};
    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    box-shadow: 4px 4px 8px 0px #0d41a675;
    padding-left: 16px;
    padding-right: 24px;
    padding-top: 14px;
    transition: all 1s ease;
    animation: ${(props) => (props.isVisible ? fadeIn : 'none')} 1s ease forwards;
`;

const GroupScheduleBox = ({
    onClickSchedule,
    boxHeight,
    rowPosition,
    colPosition,
    parentItem,
    parentIndex,
    childArr,
}: any) => {
    const [showModal, setShowModal] = useState(false);
    const isGoingSchedule = dayjs().isAfter(childArr[0]?.startTime) && dayjs().isBefore(childArr[0]?.endTime);
    const [showDetailModalArr, setShowDetailModalArr] = useState(Array.from({ length: childArr.length }, () => false));

    const hoverStyle =
        parentIndex > 5
            ? {
                  right: '110%',
              }
            : {
                  left: '110%',
              };

    return (
        <Row
            style={{
                position: 'absolute',
                width: '10.4%',
                height: boxHeight,
                marginTop: colPosition,
                marginLeft: `${rowPosition}%`,
                cursor: 'pointer',
                justifyContent: 'space-between',
                backgroundColor: colors.WHITE_50,
            }}
            onMouseEnter={() => setShowModal(true)}
            onMouseLeave={() => setShowModal(false)}
        >
            {childArr &&
                childArr.map((item: any, index: number) => {
                    const alias = item?.alias;

                    return (
                        <Col
                            key={'innerScheduleBoxArr' + index}
                            style={{
                                width: '48%',
                                height: boxHeight,
                                borderRadius: 4,
                                backgroundColor: colors.WHITE_50,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                border: isGoingSchedule ? '2px solid #3D73DD' : '2px solid #648FE4',
                                cursor: 'pointer',
                                boxShadow: showDetailModalArr[index] ? '0px 0px 12px 0px #3D73DD52' : '',
                                position: 'relative',
                            }}
                            onMouseEnter={() => {
                                const arr = Array.from({ length: childArr.length }, () => false);
                                arr[index] = true;
                                setShowDetailModalArr(arr);
                            }}
                            onMouseLeave={() => {
                                const arr = Array.from({ length: childArr.length }, () => false);
                                setShowDetailModalArr(arr);
                            }}
                            onClick={() => {
                                onClickSchedule(childArr[index]);
                            }}
                        >
                            <Row style={{ width: '100%', justifyContent: 'flex-end' }}>
                                {isGoingSchedule && (
                                    <img
                                        style={{
                                            position: 'absolute',
                                            width: 24,
                                            height: 18,
                                            top: 0,
                                        }}
                                        src={I_TODAY_TRIANGLE}
                                    />
                                )}
                            </Row>
                            {!alias ? null : item?.duration <= 30 ? (
                                <CaptionBold
                                    style={{
                                        textAlign: 'left',
                                        color: isGoingSchedule ? colors.LAVEL_4 : colors.LAVEL_3,
                                        marginLeft: 8,
                                    }}
                                >
                                    {alias}
                                </CaptionBold>
                            ) : item?.duration > 30 && alias.length <= 3 ? (
                                <Body3Bold
                                    style={{
                                        textAlign: 'left',
                                        color: isGoingSchedule ? colors.LAVEL_4 : colors.LAVEL_3,
                                        marginLeft: 8,
                                    }}
                                >
                                    {alias}
                                </Body3Bold>
                            ) : item?.duration > 30 && alias.length > 3 ? (
                                <Col>
                                    <Body3Bold
                                        style={{
                                            textAlign: 'left',
                                            color: isGoingSchedule ? colors.LAVEL_4 : colors.LAVEL_3,
                                            marginLeft: 8,
                                        }}
                                    >
                                        {alias.slice(0, 2)}
                                    </Body3Bold>
                                    <Body3Bold
                                        style={{
                                            textAlign: 'left',
                                            color: isGoingSchedule ? colors.LAVEL_4 : colors.LAVEL_3,
                                            marginLeft: 8,
                                        }}
                                    >
                                        {alias.slice(2, 5)}
                                    </Body3Bold>
                                </Col>
                            ) : null}

                            {/* <Body3Regular style={{ textAlign: 'center', color: colors.WHITE_900 }}>
                                {item?.duration} {' min'}
                            </Body3Regular> */}

                            {/* 우측 하단에 노쇼를 의미하는 빨간 점 추가 */}
                            {item.attendanceStatus === 'NOSHOW' && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        width: '8%',
                                        aspectRatio: 1,
                                        backgroundColor: colors.ERROR,
                                        borderRadius: '50%',
                                        bottom: 8,
                                        right: 8,
                                    }}
                                />
                            )}
                        </Col>
                    );
                })}

            {showModal && (
                <HoverItem style={hoverStyle} backgroundColor={colors.WHITE_50} isVisible={showModal}>
                    <Row
                        style={{
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Row style={{ width: 200 }}>
                            <Col
                                style={{
                                    width: 8,
                                    height: 22,
                                    borderRadius: 8,
                                    backgroundColor: colors.BLUE_500,
                                    marginRight: 11,
                                }}
                            />
                            <Body3Bold style={{ color: colors.LAVEL_3 }}>{`${parentItem?.coachName} 강사`}</Body3Bold>
                        </Row>
                        {childArr[0]?.attendanceStatus === 'NOSHOW' ? (
                            <Row
                                style={{
                                    width: 64,
                                    height: 23,
                                    borderRadius: 34,
                                    border: '1px solid #FF3E4A',
                                    color: colors.red,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <CaptionBold>노쇼</CaptionBold>
                            </Row>
                        ) : (
                            <Row
                                style={{
                                    width: 64,
                                    height: 23,
                                    borderRadius: 34,
                                    border: '1px solid #3D73DD',
                                    color: colors.BLUE_500,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <CaptionBold>
                                    {isGoingSchedule
                                        ? '수업중'
                                        : dayjs().isAfter(childArr[0]?.endTime)
                                        ? '수업 완료'
                                        : '수업 예정'}
                                </CaptionBold>
                            </Row>
                        )}
                    </Row>

                    <Body3Light style={{ color: colors.LAVEL_3, marginTop: 16 }}>
                        {dayjs(childArr[0]?.startTime).format('M월 D일(ddd)')}
                    </Body3Light>

                    <Body3Bold style={{ color: colors.LAVEL_3, marginTop: 8 }}>
                        {dayjs(childArr[0]?.startTime).format('A hh:mm ~ ')}
                        {dayjs(childArr[0]?.endTime).format('A hh:mm ')}
                        {`(${childArr[0]?.duration}분)`}
                    </Body3Bold>

                    <Row style={{ alignItems: 'center', marginTop: 8 }}>
                        <Body3Bold style={{ color: colors.LAVEL_3 }}>{childArr[0]?.alias} 회원</Body3Bold>
                        <Body3Light style={{ color: colors.LAVEL_2, marginLeft: 16 }}>{`${
                            childArr[0]?.currentSession ? childArr[0]?.currentSession : '?'
                        }/${childArr[0]?.totalSession ? childArr[0]?.totalSession : '?'}회`}</Body3Light>
                    </Row>
                    <CaptionRegular style={{ color: colors.LAVEL_2, marginTop: 8 }}>
                        수강권 : {childArr[0]?.membership ? childArr[0]?.membership?.name : '-'}
                    </CaptionRegular>

                    <Row style={{ alignItems: 'center', marginTop: 8 }}>
                        <Body3Bold style={{ color: colors.LAVEL_3 }}>{childArr[1]?.alias} 회원</Body3Bold>
                        <Body3Light style={{ color: colors.LAVEL_2, marginLeft: 16 }}>{`${
                            childArr[1]?.currentSession ? childArr[1]?.currentSession : '?'
                        }/${childArr[1]?.totalSession ? childArr[1]?.totalSession : '?'}회`}</Body3Light>
                    </Row>
                    <CaptionRegular style={{ color: colors.LAVEL_2, marginTop: 8 }}>
                        수강권 : {childArr[1]?.membership ? childArr[1]?.membership?.name.substr(0, 40) : '-'}
                    </CaptionRegular>
                </HoverItem>
            )}
        </Row>
    );
};

export default GroupScheduleBox;
