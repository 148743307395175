import axios, { AxiosResponse } from 'axios';

export /**
 * @method get
 * @brief 라포매니저 전체 게시물 가져오기
 */
const getAllFeedListAPI = async (
    adminId: string,
    lastPostId: string,
    limit: number,
    startTime: string,
    endTime: string,
    postType: string,
    coachId: string,
    role: string,
    accessToken: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/posts/admin/${adminId}?lastPostId=${lastPostId}&limit=${limit}&startTime=${startTime}&endTime=${endTime}&postType=${postType}&userIdToFilter=${coachId}&writerRole=${role}`,
            headers: { authorization: accessToken },
            data: {},
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method get
 * @brief 센터 소속 강사와 회원 가져오기
 */
const getAllCoachMemberListAPI = async (
    adminId: string,
    startTime: string,
    endTime: string,
    accessToken: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v2/admins/${adminId}/center-userList?startTime=${startTime}&endTime=${endTime}`,
            headers: { authorization: accessToken },
            data: {},
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method get
 * @brief 특정 회원 게시물 가져오기
 */
const getSpecificMemberFeedListAPI = async (
    memberId: string,
    lastPostId: string,
    limit: number,
    startTime: string,
    endTime: string,
    postType: string,
    coachId: string,
    role: string,
    accessToken: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/posts/admin/coach/${coachId}/member/${memberId}?lastPostId=${lastPostId}&limit=${limit}&startTime=${startTime}&endTime=${endTime}&postType=${postType}&writerRole=${role}`,
            headers: { authorization: accessToken },
            data: {},
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};
