import { atom } from 'recoil';

export interface Auth {
    // 개인
    adminId?: string | null;
    name?: string | null;
    phoneNumber?: string | null;
    accessToken?: string | null;
    gender?: string | null;
    email?: string | null;
    profileImageUrl?: string | null;
    // 센터
    centerName?: string | null;
    centerCategory?: string | null;
    centerAddress?: string | null;
    centerCode?: string | null;
    centerId?: string | null;
    // 토큰
    refreshToken?: string | null;
    isLoggedIn?: boolean | null;
    expires?: any;
}

export const authState = atom<Auth>({
    key: 'auth',
    default: {
        adminId: null,
        name: null,
        phoneNumber: null,
        gender: null,
        email: null,
        profileImageUrl: null,
        centerName: null,
        centerCategory: null,
        centerAddress: null,
        centerCode: null,
        centerId: null,
        accessToken: null,
        refreshToken: null,
        isLoggedIn: false,
        expires: false,
    },
});

export interface Loading {
    // 개인
    isLoading: true | false;
}

export const LoadingState = atom<Loading>({
    key: 'loading',
    default: {
        isLoading: false,
    },
});

export const sidebarStatusState = atom<'fold' | 'open'>({
    key: 'sidebarStatus',
    default: 'open',
});

export const screenWidthState = atom<1920 | 1440 | 1024 | 768>({
    key: 'screenWidth',
    default: 1440,
});

export const screenHeightState = atom<1080 | 1024 | 768 | 1024>({
    key: 'screenHeight',
    default: 1024,
});
