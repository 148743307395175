import React, { Dispatch, SetStateAction } from 'react';
import Modal from '../../components/modal/Modal';
import { Col, Row } from '../../lib/utils';
import SvgIcon from '../../components/SvgIcon';
import { Body2Regular, Body3Regular, Subtitle1Bold } from '../../lib/font';
import { colors } from '../../lib/colors';
import NewWhiteSquareButton from '../../components/Button/NewWhiteSquareButton';
import NewColorSquareButton from '../../components/Button/NewColorSquareButton';

type Props = {
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    onClickDelete: () => void;
    isDisableDelete?: boolean;
};

const ModalCoachDelete = ({ setIsVisible, onClickDelete, isDisableDelete }: Props) => {
    const renderCoachDelete = () => {
        return (
            <Col className="flex justify-center items-center w-full h-full p-[36px]">
                <SvgIcon name={'SvgError'} fill="red" style={{ marginBottom: 8 }} />
                <Subtitle1Bold style={{ color: colors.LAVEL_4, marginBottom: 12 }}>강사 삭제</Subtitle1Bold>
                <Body2Regular style={{ marginBottom: 16 }}>해당 회원의 프로필 정보가 삭제됩니다!</Body2Regular>
                <Col className="p-[16px]  bg-WHITE_500 rounded-[8px] mb-[16px] w-[468px]">
                    <Body3Regular>{'1. 삭제가 완료되면, 강사님은 라포 App에서 센터와의 연결이 끊'}</Body3Regular>
                    <Body3Regular className="ml-[16px] mb-[6px]">{'기게 됩니다!'}</Body3Regular>
                    <Body3Regular>{'2. 끊긴 강사님과 다시 연결하면 지난 기록을 이어서 작성하실 수'}</Body3Regular>
                    <Body3Regular className="ml-[16px] mb-[6px]">{'있어요!'}</Body3Regular>

                    <Body3Regular>{'3. 강사의 정보를 삭제해도 [강사관리]에서 지난 기록을 확인하실'}</Body3Regular>
                    <Body3Regular className="ml-[16px]">{'수 있어요!'}</Body3Regular>
                </Col>
                <Row>
                    <NewWhiteSquareButton
                        text="취소"
                        onClick={() => setIsVisible(false)}
                        width={156}
                        height={48}
                        mr={16}
                    />
                    <NewColorSquareButton text="삭제" onClick={onClickDelete} width={156} height={48} />
                </Row>
            </Col>
        );
    };

    const renderDisableDelete = () => {
        return (
            <Col className="flex justify-center items-center w-full h-full p-[36px]">
                <SvgIcon name={'SvgError'} fill="red" style={{ marginBottom: 8 }} />
                <Subtitle1Bold style={{ color: colors.LAVEL_4, marginBottom: 12 }}>
                    강사님을 삭제할 수 없습니다!
                </Subtitle1Bold>
                <Body2Regular style={{ marginBottom: 16 }}>
                    수강중인 회원이 존재하는 강사님은 삭제하실 수 없어요!
                </Body2Regular>
                <Col className="p-[16px]  bg-WHITE_500 rounded-[8px] mb-[16px] w-[468px]">
                    <Body3Regular>{'1. 강사님을 삭제하고 싶으시면 먼저, 해당 강사님의 수강중인 담'}</Body3Regular>
                    <Body3Regular className="ml-[16px] mb-[6px]">
                        {'당 회원님들을 다른강사님으로 변경해주세요!'}
                    </Body3Regular>
                    <Body3Regular>{'2. 매칭되어있는 회원이 없는데도 삭제가 불가능하시면 저희에게 '}</Body3Regular>
                    <Body3Regular className="ml-[16px] mb-[6px]">{'1:1 문의 부탁드려요!'}</Body3Regular>
                </Col>
                <Row>
                    <NewWhiteSquareButton
                        text="1:1 문의 바로가기"
                        onClick={() => window.open('http://pf.kakao.com/_ICxhcb/chat', '_blank')}
                        width={156}
                        height={48}
                        mr={16}
                    />
                    <NewColorSquareButton text="확인" onClick={() => setIsVisible(false)} width={156} height={48} />
                </Row>
            </Col>
        );
    };

    return <Modal setIsVisible={setIsVisible}>{isDisableDelete ? renderCoachDelete() : renderDisableDelete()}</Modal>;
};

export default ModalCoachDelete;
