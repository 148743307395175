import { getEmployeeInfoAtManageAPI } from '../api/employee';

export const fetchEmployeeProfileQuery = (
    params: {
        employeeId: string;
    },
    authorization: string
) => ({
    queryKey: ['getEmployeeInfoAtManageAPIQuery', params.employeeId],
    queryFn: () => getEmployeeInfoAtManageAPI(params.employeeId, authorization),
    onSuccess: (data: any) => {
        // 데이터 정제 로직
        // const result: EmployeeProfile = data?.employeeProfile;
        return data.data.employeeProfile;
    },
});

export interface EmployeeProfileResponse {
    success: boolean;
    employeeProfile: EmployeeProfile;
}

export interface EmployeeProfile {
    coachId: string;
    employeeId: string;
    name: string;
    inviteCode: string;
    phoneNumber: string;
    profileImageUrl: string;
    birthday: string;
    gender: string;
    email: string;
    startDate: string;
    jobPosition: string;
    totalMemberCount: number;
    activeMemberCount: number;
    isShareOnetimeSchedule: boolean;
    isMembershipPermissionAll: boolean;
    profileColorType: string;
    profileName: string;
}
