import React from 'react';
import { Col, Row } from '../../../lib/utils';
import { Body3Regular, CaptionRegular } from '../../../lib/font';
import { colors } from '../../../lib/colors';

import UpdateOneOnOneSchedule from './UpdateOneOnOneSchedule';

const UpdateOneScheduleRight = ({
    setIsOneOnOneClass,
    isOneOnOneClass,
    selectedMember,
    filteredMemberList,
    handleMemberSelect,
    searchText,
    onChangeText,
    coach,
    isDisabledInput,
    onClickDeleteSelectedMember,
    onClickClassPassPage,
    oneOnOneMemberShipIndex,
    setOneOnOneMemberShipIndex,
    firstMemberShipIndex,
    setFirstMemberShipIndex,
    secondMemberShipIndex,
    setSecondMemberShipIndex,
}: any) => {
    return (
        <Col style={{ width: 470, marginLeft: 138 }}>
            <Row style={{ alignItems: 'center', marginBottom: 16 }}>
                <Body3Regular style={{ color: colors.LAVEL_4 }}>회원</Body3Regular>
            </Row>

            <UpdateOneOnOneSchedule
                selectedMember={selectedMember}
                onClickDeleteSelectedMember={onClickDeleteSelectedMember}
                onClickClassPassPage={onClickClassPassPage}
                filteredMemberList={filteredMemberList}
                handleMemberSelect={handleMemberSelect}
                searchText={searchText}
                onChangeText={onChangeText}
                isDisabledInput={isDisabledInput}
                coach={coach}
                oneOnOneMemberShipIndex={oneOnOneMemberShipIndex}
                setOneOnOneMemberShipIndex={setOneOnOneMemberShipIndex}
            />
        </Col>
    );
};

export default UpdateOneScheduleRight;
