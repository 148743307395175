import React, { useEffect, useState, useCallback, useMemo, memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MemberProfileComponents from '../components/MemberProfile/MemberProfileComponents';
import MemberMemberShipComponents from '../components/MemberProfile/MemberMemberShipComponents';
import ModalRegisterPass from './ClassPass/ModalRegisterPass/ModalRegisterPass';
import dayjs from 'dayjs';
import { CodeValueType } from '../types/types';
import MemberClassHistoryComponents from '../components/MemberProfile/MemberClassHistoryComponents';
import { Col, getItemWithExpiration, Row } from '../lib/utils';
import useMultipleQueries from '../hook/useMultipleQueries';
import { fetchCoachNameList } from '../services/useCenterQueryService';
import { fetchOneMemberProfile, MemberProfile } from '../services/useMemberQueryService';
import { isEmpty } from 'lodash';
import { fetchAllMembershipForMember, MemberMembershipDataType } from '../services/useMembershipQueryService';
import useSingleQuery from '../hook/useSingleQuery';
import { useQueryClient } from '@tanstack/react-query';
import ModalMemberProfileComponentCoachChange from '../components/MemberProfile/ModalMemberProfileComponentCoachChange';
import useCenterMutationService from '../services/useCenterMutationService';
import ModalMemberProfileChange from '../components/MemberProfile/ModalMemberProfileChange';
import Modal from '../components/modal/Modal';
import SvgIcon from '../components/SvgIcon';
import { Body2Regular, Body3Regular, Subtitle1Bold } from '../lib/font';
import WhiteSquareButton from '../components/Button/WhiteSquareButton';
import { colors } from '../lib/colors';
import ColorSquareButton from '../components/Button/ColorSquareButton';
import useMemberMutationService from '../services/useMemberMutationService';

type Props = {
    createAlert: any;
};

const MemberProfilePage = React.memo(({ createAlert }: Props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const { memberId } = location.state || {};
    const { deleteTempMemberMutation } = useMemberMutationService({
        onDeleteTempMemberSuccess() {
            createAlert('임시 회원 삭제가 완료되었습니다.', '임시 회원 삭제가 완료되었습니다.', false);
            //뒤로가기
            navigate('/memberList');
        },
    });

    const [isRegisterPassModal, setIsRegisterPassModal] = useState(false);
    // 담당 강사 변경 모달 여부
    const [isChangeCoachModal, setIsChangeCoachModal] = useState(false);
    // 프로필 편집하기 모달 여부
    const [isEditProfileModal, setIsEditProfileModal] = useState(false);
    // 회원 삭제 모달
    const [isVisibleDeleteMember, setIsVisibleDeleteMember] = useState(false);

    const { refinedResults, isLoading, refresh } = useMultipleQueries([
        {
            fetchFn: fetchCoachNameList,
            params: { centerId: authObject?.centerId, isAllConcat: true },
            enabled: !!authObject?.centerId,
        },
        {
            fetchFn: fetchOneMemberProfile,
            params: { centerId: authObject?.centerId, memberId: memberId },
            enabled: !!authObject?.centerId && !!memberId,
        },
    ]);

    const { patchChangeCoachForMemberMutation, patchDisconnectMemberMutation } = useCenterMutationService({
        onPatchCenterUpdateMemberSuccess: () => {
            refresh(1);
            createAlert('회원 정보가 변경되었습니다.', '회원 프로필 정보가 변경되었습니다', false);
        },
        onPatchDisconnectMemberSuccess: () => {
            createAlert('회원 정보가 삭제되었습니다.', '회원 정보가 삭제되었습니다', true);
            //뒤로가기
            window.history.back();
        },
        onPatchChangeCoachForMemberSuccess: (data) => {
            const newCoachName = data?.newCoachName;
            setIsChangeCoachModal(false);
            refresh(1);
            createAlert(
                '담당 강사 변경이 완료되었어요!',
                `${memberProfile.name}회원님의 담당 강사님을 ${newCoachName} 강사님으로 변경되었어요! `,
                '확인',
                () => {}
            );
        },
    });

    const dropDownCoachList = useMemo(
        () => (refinedResults?.[0]?.data as CodeValueType<string>[]) ?? [],
        [refinedResults]
    );

    const memberProfile = useMemo(() => (refinedResults?.[1]?.data as MemberProfile) ?? undefined, [refinedResults]);

    const { refinedResult: asyncResult, isLoading: _resultLoading } = useSingleQuery({
        fetchFn: fetchAllMembershipForMember,
        params: {
            coachId: memberProfile?.coachId,
            memberId: memberId,
            accessToken: authObject?.accessToken,
        },
        enabled: !isEmpty(memberProfile) && !!memberProfile?.coachId,
    });

    const asyncMembershipListData = useMemo(
        () => (asyncResult?.data as MemberMembershipDataType[]) ?? [],
        [asyncResult]
    );

    const [name, setName] = useState<string>(memberProfile ? memberProfile.name : '');
    const [selectCoach, setSelectCoach] = useState<CodeValueType | undefined>(
        dropDownCoachList && dropDownCoachList.find((item: CodeValueType) => item.code === memberProfile?.coachId)
    );

    // const [birthDay, setBirthDay] = useState<string>(
    //     memberProfile ? dayjs(memberProfile.birthday).format('YYYY-MM-DD') : ''
    // );
    // const [selectGender, setSelectGender] = useState<CodeValueType | undefined>({
    //     code: memberProfile?.gender || '',
    //     value: memberProfile?.gender === 'male' ? '남성' : '여성',
    // });
    // const [phoneNumber, setPhoneNumber] = useState<string | undefined>(memberProfile?.phoneNumber);
    // const [memo, setMemo] = useState<string>(memberProfile ? memberProfile.memo : '');

    // const [isChange, setIsChange] = useState<boolean>(false);

    // useEffect(() => {
    //     if (
    //         memberProfile &&
    //         (name !== memberProfile.name ||
    //             selectCoach?.code !== memberProfile.coachId ||
    //             !dayjs(birthDay).isSame(memberProfile.birthday, 'day') ||
    //             selectGender?.code !== memberProfile.gender ||
    //             (phoneNumber ?? '') !== (memberProfile?.phoneNumber ?? '') ||
    //             memo !== memberProfile?.memo)
    //     ) {
    //         setIsChange(true);
    //     } else {
    //         setIsChange(false);
    //     }
    // }, [name, selectCoach, birthDay, selectGender, phoneNumber, memo, memberProfile]);

    const handleRegisterPassModal = useCallback(() => {
        setIsRegisterPassModal(true);
    }, []);

    return isLoading && _resultLoading ? null : (
        <div className="flex flex-col  justify-center ">
            <MemberProfileComponents
                memberProfile={memberProfile}
                memberId={memberId}
                createAlert={createAlert}
                onClickChangeCoach={() => setIsChangeCoachModal(true)}
                // 회원 삭제
                onClickDeleteMember={() => {
                    setIsVisibleDeleteMember(true);
                }}
                // 수업 피드 바로가기
                onClickMoveToFeed={() => {
                    navigate('/feed/member', {
                        state: {
                            coachId: memberProfile?.coachId,
                            memberId: memberId,
                        },
                    });
                }}
                // 프로필 편집
                onClickEditProfile={() => {
                    setIsEditProfileModal(true);
                }}
            />
            <MemberMemberShipComponents
                memberId={memberId}
                coachId={memberProfile?.coachId}
                coachName={selectCoach?.value}
                memberName={name}
                onClickCreateNewMembership={handleRegisterPassModal}
                allMembershipListData={asyncMembershipListData}
                createAlert={createAlert}
            />
            <MemberClassHistoryComponents coachId={memberProfile?.coachId} memberId={memberId} />
            {isRegisterPassModal && (
                <ModalRegisterPass
                    modalVisible={isRegisterPassModal}
                    setModalVisible={setIsRegisterPassModal}
                    createAlert={createAlert}
                    onSuccessCreate={() => {
                        queryClient.invalidateQueries({
                            queryKey: ['getMembershipTemplatesListAPIQuery'],
                        });
                    }}
                />
            )}
            {isChangeCoachModal && (
                <ModalMemberProfileComponentCoachChange
                    setIsVisible={setIsChangeCoachModal}
                    dropDownCoachList={dropDownCoachList}
                    selectCoach={selectCoach}
                    onClickChangeCoach={(newCoachId: string) => {
                        patchChangeCoachForMemberMutation.mutate({
                            centerId: authObject?.centerId,
                            memberId: memberId,
                            coachId: selectCoach?.code,
                            newCoachId: newCoachId,
                        });
                    }}
                />
            )}
            {isEditProfileModal && (
                <ModalMemberProfileChange
                    setIsVisible={setIsEditProfileModal}
                    createAlert={createAlert}
                    memberId={memberId}
                    memberProfile={memberProfile}
                />
            )}
            {isVisibleDeleteMember && (
                <Modal whiteViewHeight={346} whiteViewWidth={540} setIsVisible={setIsVisibleDeleteMember}>
                    <Col className="flex justify-center items-center w-full h-full px-[36px]">
                        <SvgIcon name={'SvgError'} fill="red" style={{ marginBottom: 8 }} />
                        <Subtitle1Bold style={{ color: colors.LAVEL_4, marginBottom: 12 }}>회원 삭제</Subtitle1Bold>
                        <Body2Regular style={{ marginBottom: 16 }}>해당 회원의 프로필 정보가 삭제됩니다!</Body2Regular>
                        <Col className="p-[16px]  bg-WHITE_500 rounded-[8px] mb-[16px] w-full">
                            <Body3Regular>{'1. 삭제후, 회원 검색 및 복구가 불가능합니다!'}</Body3Regular>
                            <Body3Regular>{'2. 삭제된 회원의 수강권은 자동으로 만료처리 됩니다!'}</Body3Regular>
                        </Col>
                        <Row>
                            <WhiteSquareButton
                                text={'취소'}
                                size="middle"
                                mr={16}
                                callBack={() => {
                                    setIsVisibleDeleteMember(false);
                                }}
                            />
                            <ColorSquareButton
                                text={'삭제'}
                                size="middle"
                                callBack={() => {
                                    setIsVisibleDeleteMember(false);

                                    // 임시회원일 경우 바로 삭제
                                    if (memberProfile?.memberType === 'temp') {
                                        deleteTempMemberMutation.mutate({
                                            centerId: authObject.centerId,
                                            memberId: memberId,
                                            accessToken: authorization,
                                        });
                                    } else {
                                        patchDisconnectMemberMutation.mutate({
                                            centerId: authObject.centerId, //centerId:
                                            memberId: memberId, //memberId:
                                            coachId: memberProfile?.coachId, //coachId:
                                            accessToken: authorization, //accessToken:
                                        });
                                    }
                                }}
                            />
                        </Row>
                    </Col>
                </Modal>
            )}
        </div>
    );
});

export default memo(MemberProfilePage);
