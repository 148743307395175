import { Col, Row } from '../../lib/utils';
import { Body1Bold, Body1Regular } from '../../lib/font';
import { colors } from '../../lib/colors';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ko';
import EmptyScheduleBox from './Hover/EmptyScheduleBox';
dayjs.locale('ko');

type Props = {
    gridData: any;
    selectedDay: any;
    sidebarStatus: 'fold' | 'open';
    dailyCurrentData: any;
    onClickHover: (schedule: any, coachName: string, coachId: string) => void;
};
const GridLayout = ({ gridData, selectedDay, sidebarStatus, dailyCurrentData, onClickHover }: Props) => {
    return (
        <Col style={{ height: 2765 }}>
            {gridData.map((row: any, rowIndex: any) => {
                return (
                    <Row key={rowIndex}>
                        {/* 로우 */}
                        {row.map((cell: any, cellIndex: any) => {
                            return cellIndex === 0 ? (
                                <Row
                                    key={cell.id}
                                    style={{
                                        width: 140,
                                        height: 120,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRightWidth: 2,
                                        borderRightColor: colors.BLUE_200,
                                    }}
                                >
                                    {dayjs(selectedDay).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') ? (
                                        parseInt(dayjs(selectedDay).format('H')) === rowIndex ? (
                                            <Row
                                                style={{
                                                    width: 86,
                                                    height: 32,
                                                    borderWidth: 1,
                                                    borderColor: colors.PRIMARY_BLUE,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderRadius: 24,
                                                    backgroundColor: colors.WHITE_50,
                                                }}
                                            >
                                                <Body1Bold style={{ color: colors.BLUE_900 }}>{cell.content}</Body1Bold>
                                            </Row>
                                        ) : parseInt(dayjs(selectedDay).format('H')) + 1 === rowIndex ? (
                                            <Body1Regular style={{ color: colors.BLUE_400 }}>
                                                {cell.content}
                                            </Body1Regular>
                                        ) : (
                                            <Body1Regular style={{ color: colors.WHITE_900 }}>
                                                {cell.content}
                                            </Body1Regular>
                                        )
                                    ) : (
                                        <Body1Regular style={{ color: colors.WHITE_900 }}>{cell.content}</Body1Regular>
                                    )}
                                </Row>
                            ) : (
                                <EmptyScheduleBox
                                    key={cell.id}
                                    rowIndex={rowIndex}
                                    cellIndex={cellIndex}
                                    gridData={gridData}
                                    dailyCurrentData={dailyCurrentData}
                                    sidebarStatus={sidebarStatus}
                                    onClickHover={onClickHover}
                                    selectedDay={selectedDay}
                                />
                            );
                        })}
                    </Row>
                );
            })}
        </Col>
    );
};

export default GridLayout;
