import React from 'react';
import { Col, Row } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import { Body2Bold, Body2Regular, Body3Bold, Body3Regular, CaptionRegular } from '../../../lib/font';

import NormalToggleButton from '../../../components/ToggleButton/NormalToggleButton';
import dayjs from 'dayjs';
import NewWhiteRoundButton from '../../../components/Button/NewWhiteRoundButton';
import useMembershipTemplateMutationService from '../../../services/useMembershipTemplateMutationService';
import { useQueryClient } from '@tanstack/react-query';

const ClassPassDetailMembership = ({
    detailObject,
    isAvailable,
    onClickUpdatePermission,
    isNoMembershipHistory,
    createAlert,
    onClickListPage,
}: any) => {
    const queryClient = useQueryClient();

    const { deleteMembershipTemplateMutation } = useMembershipTemplateMutationService({
        onDeleteMembershipTemplateSuccess() {
            createAlert(
                '수강권 삭제 완료',
                '수강권이 삭제되었어요!\n(수강권 발급기록이 없는 수강권만 삭제 가능해요!)',
                true
            );
            // 뒤로가기
            onClickListPage && onClickListPage();

            queryClient.invalidateQueries({ queryKey: ['getMembershipTemplatesListAPI'] });
        },
    });
    return (
        <Row
            style={{
                width: 1192,
                height: 456,
                borderRadius: 16,
                border: '1px solid #c3d4f4',
                marginTop: 16,
                padding: 64,
                backgroundColor: colors.WHITE_50,
                marginBottom: 64,
            }}
        >
            <Col>
                <Row
                    style={{
                        width: 310,
                        height: 92,
                        borderRadius: 16,
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        paddingLeft: 24,
                        paddingRight: 24,
                        background: `linear-gradient(135deg, ${detailObject.BGColor[0]} 0%, ${detailObject.BGColor[1]} 100%)`,
                    }}
                >
                    <Body2Bold style={{ color: colors.LAVEL_0 }}>
                        {detailObject?.name.length > 30
                            ? `${detailObject?.name.substring(0, 30)}...`
                            : detailObject?.name}
                    </Body2Bold>
                </Row>

                <Row
                    style={{
                        width: 310,
                        height: 64,
                        borderRadius: 16,
                        backgroundColor: isAvailable ? colors.SUCCESS_50 : colors.GREY_50,
                        marginTop: 16,
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingLeft: 24,
                        paddingRight: 24,
                        border: isAvailable ? '1px solid #5FDDBF' : '1px solid #C7C7C7',
                    }}
                >
                    {isAvailable ? (
                        <Body2Regular style={{ color: colors.LAVEL_3 }}>{'수강권 활성화 ON'}</Body2Regular>
                    ) : (
                        <Body2Regular style={{ color: colors.LAVEL_3 }}>{'수강권 활성화 OFF'}</Body2Regular>
                    )}
                    <NormalToggleButton isActive={isAvailable} callBack={onClickUpdatePermission} />
                </Row>

                <CaptionRegular style={{ color: colors.LAVEL_1, marginTop: 12, marginLeft: 8 }}>
                    {'*버튼을 끄면 이용권 발급이 중단됩니다'}
                </CaptionRegular>
            </Col>
            <Col style={{ width: 706, height: 328, marginLeft: 40 }}>
                <Row>
                    <Row style={{ alignItems: 'center', width: 220 }}>
                        <Body3Bold style={{ color: colors.LAVEL_3 }}>{`수강권 생성 : `}</Body3Bold>
                        <Body3Regular style={{ marginLeft: 8, color: colors.LAVEL_3 }}>{`${
                            detailObject.issuer.issuerRole === 'admin' ? '관리자' : detailObject?.issuer?.name
                        }`}</Body3Regular>
                    </Row>

                    <Row style={{ alignItems: 'center', marginLeft: 64, marginRight: 100 }}>
                        <Body3Bold style={{ color: colors.LAVEL_3 }}>{`등록일시 : `}</Body3Bold>
                        <Body3Regular style={{ marginLeft: 8, color: colors.LAVEL_3 }}>{`${dayjs(
                            detailObject?.createdAt
                        ).format('YYYY년 MM월 DD일')}`}</Body3Regular>
                    </Row>
                    {isNoMembershipHistory && (
                        <NewWhiteRoundButton
                            text={'수강권 삭제하기'}
                            color={'GREY_300'}
                            fontType="CaptionRegular"
                            width={120}
                            height={24}
                            onClick={() =>
                                deleteMembershipTemplateMutation.mutate({
                                    membershipTemplateId: detailObject?._id,
                                })
                            }
                        />
                    )}
                </Row>

                <Row style={{ marginTop: 24 }}>
                    <Row style={{ alignItems: 'center', width: 220 }}>
                        <Body3Bold style={{ color: colors.LAVEL_3 }}>{`판매 금액 : `}</Body3Bold>
                        <Body3Regular
                            style={{ marginLeft: 8, color: colors.LAVEL_3 }}
                        >{`${detailObject?.totalPrice.toLocaleString()}원`}</Body3Regular>
                    </Row>

                    <Row style={{ alignItems: 'center', marginLeft: 64 }}>
                        <Body3Bold style={{ color: colors.LAVEL_3 }}>{`회당 가격 : `}</Body3Bold>
                        <Body3Regular
                            style={{ marginLeft: 8, color: colors.LAVEL_3 }}
                        >{`${detailObject?.pricePerSchedule.toLocaleString()}원`}</Body3Regular>
                    </Row>
                </Row>

                <Row style={{ marginTop: 24 }}>
                    <Row style={{ alignItems: 'center' }}>
                        <Body3Bold style={{ color: colors.LAVEL_3 }}>{`사용 기간 : `}</Body3Bold>
                        <Body3Regular
                            style={{ marginLeft: 8, color: colors.LAVEL_3 }}
                        >{`${detailObject?.validDays}일`}</Body3Regular>
                    </Row>

                    <Row style={{ alignItems: 'center', marginLeft: 72 }}>
                        <Body3Bold style={{ color: colors.LAVEL_3 }}>{`총 회차 : `}</Body3Bold>
                        <Body3Regular
                            style={{ marginLeft: 8, color: colors.LAVEL_3 }}
                        >{`${detailObject?.totalSession}회`}</Body3Regular>
                    </Row>

                    <Row style={{ alignItems: 'center', marginLeft: 72 }}>
                        <Body3Bold style={{ color: colors.LAVEL_3 }}>{`수업 시간 : `}</Body3Bold>
                        <Body3Regular
                            style={{ marginLeft: 8, color: colors.LAVEL_3 }}
                        >{`${detailObject?.period}분`}</Body3Regular>
                    </Row>
                </Row>

                <Row style={{ marginTop: 24 }}>
                    <Body3Bold style={{ color: colors.LAVEL_3 }}>{`메모 : `}</Body3Bold>
                    <Col
                        style={{
                            width: 650,
                            height: 184,
                            border: '1px solid #c3d4f4',
                            borderRadius: 4,
                            marginLeft: 16,
                            padding: 8,
                        }}
                    >
                        <Body3Regular style={{ color: colors.LAVEL_3 }}>{detailObject?.memo}</Body3Regular>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default ClassPassDetailMembership;
