import React from 'react';
import { Col } from '../../../lib/utils';
import { Body3Light, Body3Regular } from '../../../lib/font';

import { colors } from '../../../lib/colors';
import { StyledSignUpInput } from '../SignUpBody2';

const InputName = ({ register, errors }: any) => {
    return (
        <Col>
            <label htmlFor="name" style={{ display: 'flex', marginBottom: 8 }}>
                <Body3Regular>성함</Body3Regular>
                &nbsp;
                <Body3Regular style={{ color: colors.ERROR }}>*</Body3Regular>
            </label>
            <StyledSignUpInput
                type="text"
                {...register('name', {
                    minLength: { value: 2, message: '* 이름은 2자 이상이어야 합니다' },
                })}
                maxLength={15}
            />
            <Body3Light style={{ marginLeft: 16, marginTop: 6, color: errors.name ? colors.red : colors.WHITE_50 }}>
                {errors.name ? errors.name.message : 'dd'}
            </Body3Light>
        </Col>
    );
};

export default InputName;
