import React, { ChangeEvent, useEffect, useState } from 'react';
import { Bottom } from '../global';
import { Col, getItemWithExpiration, setItemWithExpiration } from '../../lib/utils';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { changeSubscriptionPlanAPI, getSubscriptionPlanAPI } from '../../api/charge';
import PlanInformation from '../../components/SubscriptionPlan/PlanInformation';
import Header from '../../components/Header';
import PlanChange from '../../components/SubscriptionPlanChange/PlanChange';
import PlanChangePay from '../../components/SubscriptionPlanChange/PlanChangePay';

const SubscriptionPlanChangePage = ({ createAlert, onClickTerm, scrollToTop, createTwoButtonAlert }: any) => {
    const navigate = useNavigate();
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));

    /**
     * coachCount : 현재 등록된 강사
     * buttonType : small -> 3인 이하 소형 센터, big -> 4인 이상 대형 센터
     * smallCenterTrainerNumber : 3인이하 소형센터 강사수 state
     * bigCenterTrainerNumber : 4인 이상 대형센터 강사수 state
     * isCoachCountAlert : 코치 숫자 대비 플랜 코치수가 안맞을때 경고플래그
     * pageType :  prev -> 구독플랜변경 처음페이지 ,  next -> 구독플랜변경 결제페이지
     */
    const [coachCount, setCoachCount] = useState<number>(0);
    const [buttonType, setButtonType] = useState<'small' | 'big'>('small');
    const [smallCenterTrainerNumber, setSmallCenterTrainerNumber] = useState<number>(1);
    const [bigCenterTrainerNumber, setBigCenterTrainerNumber] = useState<number>(4);
    const [isCoachCountAlert, setIsCoachCountAlert] = useState<boolean>(false);
    const [pageType, setPageType] = useState<'prev' | 'next'>('prev');
    const [email, setEmail] = useState<string>(authObject?.email ? authObject?.email : '');
    const [isCheckPolicy, setIsCheckPolicy] = useState<boolean>(false);

    const [currentTrainerNumber, setCurrentTrainerNumber] = useState<number>(1);
    const [currentPriceNumber, setCurrentPriceNumber] = useState<number>(0);
    const [currentPrice, setCurrentPrice] = useState<string>('');

    // 결제 하기 버튼 클릭이벤트
    const onClickPayButton = () => {
        if (buttonType === 'small') {
            if (smallCenterTrainerNumber === authObject?.planLimitCount) {
                createAlert('오류', '현재 구독종인 요금제와 변경할 요금제가 같습니다.', true);
                return;
            } else {
                setPageType('next');
            }
        }

        if (buttonType === 'big') {
            if (bigCenterTrainerNumber === authObject?.planLimitCount) {
                createAlert('오류', '현재 구독종인 요금제와 변경할 요금제가 같습니다.', true);
                return;
            } else {
                setPageType('next');
            }
        }
    };

    // 이메일 변경
    const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
        const currentEmail = e.target.value;
        setEmail(currentEmail.trim());
    };

    // 개인정보 수집 및 이용약관 동의 버튼
    const onClickPolicy = () => {
        setIsCheckPolicy(!isCheckPolicy);
    };

    //  구독플랜조회 API : (GET)
    const getSubscriptionPlan = useQuery(
        ['getSubscriptionPlanAPI'],
        async () => await getSubscriptionPlanAPI(authorization, authObject?.adminId),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) {
                        setCoachCount(response.coachCount);
                    }
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!!authObject?.testAccount,
            refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    // 구독 플랜 변경 API : (PATCH)
    const changeSubscriptionPlan = useMutation({
        mutationFn: async () =>
            await changeSubscriptionPlanAPI(
                authorization,
                authObject?.adminId,
                currentTrainerNumber,
                currentPriceNumber,
                email
            ),
        onSuccess: (res) => {
            if (res.status === 200) {
                const response = res.data;

                const authObjectData = {
                    adminId: authObject?.adminId,
                    id: authObject?.id,
                    name: authObject?.name,
                    email: authObject?.email,
                    gender: authObject?.gender,
                    phoneNumber: authObject?.phoneNumber,
                    profileImageUrl: authObject?.profileImageUrl,

                    centerAddress: authObject?.centerAddress,
                    centerCategory: authObject?.centerCategory,
                    centerCode: authObject?.centerCode,
                    centerName: authObject?.centerName,
                    centerId: authObject?.centerId,
                    isNewCommunicationBox: authObject?.isNewCommunicationBox,

                    // 구독플랜관련
                    coachCount: authObject?.coachCount,
                    memberCount: authObject?.memberCount,

                    activationDate: authObject?.activationDate,
                    expirationDate: authObject?.expirationDate,
                    expirationDelayDate: authObject?.expirationDelayDate,
                    finalPaymentDate: authObject?.finalPaymentDate,
                    finalPaymentTryDate: authObject?.finalPaymentTryDate,
                    cardIssuingBank: authObject?.cardIssuingBank,
                    cardNumber: authObject?.cardNumber,
                    planAmount: response?.planAmount,
                    usableStatus: response?.usableStatus,

                    paymentDueDate: authObject?.paymentDueDate,

                    /** 구독플랜관련 */
                    planLimitCount: response?.planLimitCount,
                    prevPlanLimitCount: response?.prevPlanLimitCount,
                    changedPlanLimitCount: response?.changedPlanLimitCount, // 구독 인원 변경시 변경 플랜카운트

                    confirmUsableStatus: true,
                    testAccount: authObject?.testAccount,
                };

                setItemWithExpiration('authObject', authObjectData, 12);

                if (response.prevPlanLimitCount < response.changedPlanLimitCount) {
                    createAlert(
                        '구독 변경 확정',
                        '플랜 상향 변경 시 즉시 적용되며  변경 된 플랜은 다음 결제 예정일부터\n 자동 청구 됩니다'
                    );
                } else {
                    createAlert(
                        '구독 변경 확정',
                        '이전 플랜 이용 만료까지 기존 플랜이 적용되며 변경된 플랜은 다음 결제 예정\n일부터 적용됩니다'
                    );
                }

                navigate('/myPage', { replace: true });
            } else {
                createAlert('오류', '구독 플랜 변경중 오류가 발생하였습니다', true);
                navigate('/myPage', { replace: true });
            }
        },
    });

    // 플랜 변경 버튼 클릭이벤트
    const onClickPlanChange = () => {
        const emailRegExp = /^$|^[A-Za-z0-9_]+[A-Za-z0-9]*[@]{1}[A-Za-z0-9]+[A-Za-z0-9]*[.]{1}[A-Za-z]{1,3}$/;
        if (!isCheckPolicy) {
            createAlert('필수', '개인정보 수집 및 이용약관에 동의해주세요', true);
            return;
        }

        if (!(email.trim() === '')) {
            if (!emailRegExp.test(email)) {
                createAlert('입력오류', '이메일 형식이 잘못되었습니다', true);
                return;
            }
        }

        changeSubscriptionPlan.mutate();
    };

    // 현재 강사수보다 적은 요금제를 선택햇을경우 alert뛰우기 위한
    useEffect(() => {
        if (buttonType === 'small') {
            if (smallCenterTrainerNumber < coachCount) {
                setIsCoachCountAlert(true);
            } else {
                setIsCoachCountAlert(false);
            }
        } else {
            if (bigCenterTrainerNumber < coachCount) {
                setIsCoachCountAlert(true);
            } else {
                setIsCoachCountAlert(false);
            }
        }
    }, [smallCenterTrainerNumber, bigCenterTrainerNumber, buttonType, coachCount]);

    // 플랜의 따른 가격 세팅
    useEffect(() => {
        if (buttonType === 'small') {
            setCurrentTrainerNumber(smallCenterTrainerNumber);
            setCurrentPriceNumber(smallCenterTrainerNumber * 32900);
            setCurrentPrice((smallCenterTrainerNumber * 32900).toLocaleString());
        } else {
            setCurrentTrainerNumber(bigCenterTrainerNumber);
            setCurrentPriceNumber(bigCenterTrainerNumber * 39900);
            setCurrentPrice((bigCenterTrainerNumber * 39900).toLocaleString());
        }
    }, [buttonType, smallCenterTrainerNumber, bigCenterTrainerNumber]);

    useEffect(() => {
        scrollToTop();
    }, [pageType]);

    // 플랜변경은 -> 플랜구독중, 플랜결제유예중만 들어올수 있게한다.
    useEffect(() => {
        if (authObject) {
            if (
                authObject?.usableStatus === 'plan' ||
                authObject?.usableStatus === 'planAborted' ||
                authObject?.usableStatus === 'planWillChanged'
            ) {
                return;
            } else {
                navigate('/myPage', { replace: true });
            }
        }
    }, [authObject]);

    return (
        <>
            <Col style={{ marginTop: 64, width: '100%', alignItems: 'center', marginBottom: 300 }}>
                {pageType === 'prev' && (
                    <>
                        <PlanChange
                            onClickPayButton={onClickPayButton}
                            createAlert={createAlert}
                            buttonType={buttonType}
                            setButtonType={setButtonType}
                            smallCenterTrainerNumber={smallCenterTrainerNumber}
                            setSmallCenterTrainerNumber={setSmallCenterTrainerNumber}
                            bigCenterTrainerNumber={bigCenterTrainerNumber}
                            setBigCenterTrainerNumber={setBigCenterTrainerNumber}
                            authObject={authObject}
                            coachCount={coachCount}
                            isCoachCountAlert={isCoachCountAlert}
                            navigate={navigate}
                        />
                        {/* 라포 플랜 인포 */}
                        <PlanInformation />
                    </>
                )}

                {pageType === 'next' && (
                    <PlanChangePay
                        email={email}
                        onChangeEmail={onChangeEmail}
                        authObject={authObject}
                        onClickTerm={onClickTerm}
                        onClickPolicy={onClickPolicy}
                        isCheckPolicy={isCheckPolicy}
                        buttonType={buttonType}
                        smallCenterTrainerNumber={smallCenterTrainerNumber}
                        bigCenterTrainerNumber={bigCenterTrainerNumber}
                        currentPrice={currentPrice}
                        onClickPlanChange={onClickPlanChange}
                    />
                )}
            </Col>
        </>
    );
};

export default SubscriptionPlanChangePage;
