import React from 'react';
import { Row } from '../../lib/utils';
import { Body1Regular, Body3Bold, Body3Light, Body3Regular, CaptionLight, Head4 } from '../../lib/font';
import { colors } from '../../lib/colors';
import SmallButton from '../Button/SmallButton';
import dayjs from 'dayjs';
import SortTypeDropDown from '../DropDown/SortTypeDropDown';
import WhiteRoundButton from '../Button/WhiteRoundButton';
import RoundedDropDown from '../DropDown/RoundedDropDown';
import ResetButton from '../Button/ResetButton';

type Props = {
    sortOptions: any;
    sortType: any;
    isVisibleSort: boolean;
    toggleDropDown: () => void;
    handleDropDownSelect: (option: any) => void;
    onClickCalendar: () => void;
    onClickRefresh: () => void;
    startDate: any;
    endDate: any;
    onClickClearFilter: () => void;
};

const FeedMemberHeader = ({
    sortOptions,
    sortType,
    isVisibleSort,
    toggleDropDown,
    handleDropDownSelect,
    onClickCalendar,
    onClickRefresh,
    startDate,
    endDate,
    onClickClearFilter,
}: Props) => {
    return (
        <>
            <Row style={{ width: 397, justifyContent: 'space-between', alignItems: 'center', marginLeft: 8 }}>
                <Head4 style={{ color: colors.LAVEL_4 }}>{'수업 피드'}</Head4>
                <CaptionLight style={{ color: colors.LAVEL_2 }}>
                    {'강사님들과 회원님들이 남긴 모든 피드를 확인해보세요!'}
                </CaptionLight>
            </Row>
            <Row style={{ alignItems: 'center', marginTop: 32, justifyContent: 'space-between' }}>
                <Row style={{ width: 786, alignItems: 'center', justifyContent: 'space-between' }}>
                    <Row style={{ width: 356, alignItems: 'center' }}>
                        {startDate && endDate ? (
                            <Body3Regular style={{ marginRight: 16 }}>
                                {dayjs(startDate).format('YYYY년 M월 D일 ~ ')}
                                {dayjs(endDate).format('YYYY년 M월 D일 ')}
                            </Body3Regular>
                        ) : (
                            <Body3Regular>{'전체 기간'}</Body3Regular>
                        )}

                        <WhiteRoundButton
                            customStyle={{ marginLeft: 16 }}
                            callBack={onClickCalendar}
                            text={'기간 선택'}
                            size="tiny"
                        />
                    </Row>

                    <Row>
                        <ResetButton text={'새로 고침'} callBack={onClickRefresh} />

                        <RoundedDropDown
                            toggleSortDropDown={toggleDropDown}
                            sortType={sortType}
                            isOpenSort={isVisibleSort}
                            sortOptions={sortOptions}
                            handleSortSelect={handleDropDownSelect}
                            size="small"
                        />
                    </Row>
                </Row>

                <Row style={{ width: 380, justifyContent: 'flex-end' }}>
                    <ResetButton text={'초기화'} callBack={onClickClearFilter} />
                </Row>
            </Row>
        </>
    );
};

export default FeedMemberHeader;
