import React from 'react';

import { ResponsiveBar } from '@nivo/bar';
import { colors } from '../../../../lib/colors';
import dayjs from 'dayjs';
import MemberListEmptyGraph from './MemberListEmptyGraph';

const CustomTextLayer = ({ bars }: any) => {
    return (
        <g>
            {bars.map((bar: any, index: number) => (
                <text
                    key={index}
                    x={bar.x + bar.width / 2}
                    y={bar.y + bar.height / 2}
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    style={{
                        fill: bar.data.id === 'activeMemberCount' ? 'white' : 'black', // 조건에 따라 텍스트 색상 지정
                        fontSize: 16,
                        fontWeight: 700,
                        //lineHeight: 24,
                    }}
                >
                    {bar.key.includes('active') && bar.data.value > 0 && bar.data.value}
                    {bar.key.includes('interval') && bar.data.value > 0 && bar.data.data.totalMemberCount}
                </text>
            ))}
        </g>
    );
};

const MemberListMonthlyGraph = ({ monthlyData, monthlyMaxCount, CustomToolTip }: any) => {
    const CustomTick = (tick: any) => {
        const { x, y, value } = tick;

        let monthlyFilterData = null;

        monthlyFilterData = monthlyData.find((e: any) => e.xLabel === value);

        return (
            <g transform={`translate(${x},${y})`} style={{ textAnchor: 'middle' }}>
                {monthlyFilterData && monthlyFilterData.isToday && (
                    <rect x={-4} y={20} fill={colors.BLUE_500} width={8} height={8} rx={4} />
                )}
                <text
                    x={0}
                    y={55}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{
                        fontSize: 24,
                        fontWeight: 700,
                        fill:
                            monthlyFilterData && monthlyFilterData.isToday
                                ? colors.LAVEL_4
                                : dayjs(monthlyFilterData.startDate).isAfter(dayjs())
                                ? colors.GREY_100
                                : colors.LAVEL_2,
                        outlineWidth: 0,
                    }}
                >
                    {value}
                </text>
            </g>
        );
    };

    return (
        <>
            {monthlyData && monthlyData.length === 0 ? (
                <MemberListEmptyGraph />
            ) : (
                <ResponsiveBar
                    data={monthlyData}
                    layers={[
                        'grid',
                        'axes',
                        'bars',
                        'markers',
                        'legends',
                        CustomTextLayer, // 커스텀 텍스트 레이어 추가
                    ]}
                    keys={['activeMemberCount', 'interval']}
                    indexBy="xLabel"
                    margin={{ right: 70, top: 15, bottom: 100, left: 90 }}
                    axisLeft={{
                        tickSize: 0,
                    }}
                    axisBottom={{
                        tickSize: 0,
                        tickPadding: 5,
                        tickRotation: 0,
                        renderTick: CustomTick,
                    }} // 바텀 라벨의 스타일
                    isInteractive={true} // 호버햇을때 말풍선
                    tooltip={CustomToolTip}
                    padding={0.45}
                    enableLabel={false}
                    minValue={0}
                    maxValue={monthlyMaxCount < 10 ? 10 : monthlyMaxCount}
                    colors={['#3D73DD', '#D4DBE8']} // 커스터하여 사용할 때
                    colorBy="id" // 색상을 keys 요소들에 각각 적용
                    labelSkipWidth={36}
                    labelSkipHeight={12}
                    motionConfig={'gentle'}
                    layout="vertical"
                />
            )}
        </>
    );
};

export default MemberListMonthlyGraph;
