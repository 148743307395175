// apiService.js

import { Issuer } from '../api/center';
import { getAllMembershipForMember, getMembershipForMember } from '../api/memberShip';

export const fetchAllMembershipForMember = (
    params: {
        coachId: string;
        memberId: string;
        accessToken: string;
    },
    authorization: string
) => ({
    queryKey: ['getAllMembershipForMemberQuery', params.coachId, params.memberId],
    queryFn: () => getAllMembershipForMember(params.coachId, params.memberId, authorization),
    onSuccess: (data: any) => {
        // 데이터 정제 로직
        return data?.data?.membersMemberships ?? [];
    },
});
export const fetchMembershipForMember = (
    params: {
        membershipId: string;
    },
    authorization: string
) => ({
    queryKey: ['getMembershipForMemberQuery', params.membershipId],
    queryFn: () => getMembershipForMember(params.membershipId, authorization),
    onSuccess: (data: any) => {
        // 데이터 정제 로직
        return data?.data?.membership ?? [];
    },
});

/**
 * 회원권
 * */
export type MemberMembershipDataType = {
    _id: string;
    period: number;
    isDeleted: boolean;
    isCanceled: boolean;
    coachId: string;
    memberId: string;
    name: string;
    activationDate: string;
    expirationDate: string;
    startSessionValue: number;
    totalSession: number;
    createdAt: string;
    updatedAt: string;
    __v: number;
    BGColor: string[];
    issuer: Issuer;
    membershipTemplateId: string;
    memo: string;
    pricePerSchedule: number;
    status: 'expired' | 'active';
    totalPrice: number;
    currentSession: number;
    schedules: MemberScheduleDataType[];
    remainSession: number;
};

/** 회원권의 스케줄 */
export type MemberScheduleDataType = {
    __v: number;
    _id: string;
    alias: string;
    attendanceStatus: 'ATTENDANCE' | 'NOSHOW';
    coachId: string;
    conditionId: string;
    createdAt: string;
    currentSession: number;
    endTime: string;
    isCanceled: boolean;
    isDeleted: boolean;
    memberId: string;
    membershipId: string;
    membership: MemberMembershipDataType;
    memo: string;
    postId: string;
    requestDeadline: string;
    scheduleBundleId: string | null;
    startTime: string;
    totalSession: number;
    updatedAt: string;
};
