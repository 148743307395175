import { ResponsiveBar } from '@nivo/bar';
import { I_EMPTY_PROFILE } from '../../../types/images';
import ClassProgressEmpty from './ClassProgressEmpty';
import { LeftButton, RightButton } from '../../../scenes/DashBoardPage';

type Props = {
    monthCurrentData: any;
    monthMaxCount: number;
    handlePrevPage: () => void;
    handleNextPage: () => void;
    currentMonthPage: any;
    endMonthPage: number;
    CustomToolTip: any;
    CustomTextLayer: any;
};

const ClassProgressMonthGraph = ({
    monthCurrentData,
    monthMaxCount,
    handlePrevPage,
    handleNextPage,
    currentMonthPage,
    endMonthPage,
    CustomToolTip,
    CustomTextLayer,
}: Props) => {
    const CustomTick = (tick: any) => {
        const { x, y, value } = tick;
        const filterData = monthCurrentData;
        const imageSrc = filterData.find((monthCurrentData: any) => monthCurrentData.coachId === value)?.coachProfile;
        const coachName = filterData.find((monthCurrentData: any) => monthCurrentData.coachId === value)?.coachName;

        // 이미지의 너비를 고려하여 중앙 위치 계산
        const imageWidth = 35;
        const imageX = x - imageWidth / 2;
        const imageY = y + 13;

        return (
            <g transform={`translate(${imageX},${imageY})`} style={{ textAnchor: 'middle' }}>
                <foreignObject width={imageWidth} height={imageWidth}>
                    <div
                        style={{
                            width: imageWidth,
                            height: imageWidth,
                            borderRadius: imageWidth / 2,
                            overflow: 'hidden',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            src={imageSrc ? imageSrc : I_EMPTY_PROFILE}
                            alt={`Profile of ${coachName}`}
                            style={{ width: '100%', height: '100%', borderRadius: imageWidth / 2, overflow: 'hidden' }}
                            onError={(e: any) => {
                                e.currentTarget.src = I_EMPTY_PROFILE;
                            }}
                        />
                    </div>
                </foreignObject>

                <text x={17} y={37} dy={16} style={{ fontSize: 12 }}>
                    {coachName}
                </text>
            </g>
        );
    };

    return monthCurrentData && monthCurrentData.length === 0 ? (
        <ClassProgressEmpty />
    ) : (
        <>
            {currentMonthPage !== 1 && (
                <LeftButton
                    style={{ position: 'absolute', marginTop: 160, marginLeft: 20, zIndex: 1 }}
                    onClick={handlePrevPage}
                />
            )}
            {currentMonthPage !== endMonthPage && endMonthPage !== 0 && (
                <RightButton
                    style={{ position: 'absolute', marginTop: 160, marginLeft: 1305, zIndex: 1 }}
                    onClick={handleNextPage}
                />
            )}
            <ResponsiveBar
                data={monthCurrentData}
                layers={[
                    'grid',
                    'axes',
                    'bars',
                    'markers',
                    'legends',
                    CustomTextLayer, // 커스텀 텍스트 레이어 추가
                ]}
                keys={['completeScheduleCount']}
                indexBy="coachId"
                margin={{ right: 70, top: 15, bottom: 100, left: 90 }}
                axisLeft={{
                    tickSize: 0,
                }}
                tooltip={CustomToolTip}
                enableLabel={false}
                axisBottom={{
                    tickSize: 0,
                    tickPadding: 5,
                    tickRotation: 0,
                    renderTick: CustomTick,
                }} // 바텀 라벨의 스타일
                isInteractive={true} // 호버햇을때 말풍선
                minValue={0}
                maxValue={monthMaxCount < 10 ? 10 : monthMaxCount}
                padding={1 - monthCurrentData.length * (monthCurrentData.length > 3 ? 0.05 : 0.07)}
                borderColor={'#fff'}
                colors={['#3D73DD', '#D4DBE8']} // 커스터하여 사용할 때
                colorBy="id" // 색상을 keys 요소들에 각각 적용
                labelSkipWidth={36}
                labelSkipHeight={12}
                layout="vertical"
                animate={false}
            />
        </>
    );
};

export default ClassProgressMonthGraph;
