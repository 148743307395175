import React from 'react';
import { Col, Row } from '../../../lib/utils';
import { Body1Bold, Body2Regular } from '../../../lib/font';
import { colors } from '../../../lib/colors';

const Memo = ({ condition }: any) => {
    return (
        <Col style={{ marginTop: 48 }}>
            <Body1Bold style={{ color: colors.BLUE_900 }}>{'회원 메모'}</Body1Bold>
            <Row
                style={{
                    width: 740,
                    height: 84,
                    borderRadius: 8,
                    borderWidth: 1,
                    borderColor: colors.WHITE_600,
                    backgroundColor: colors.WHITE_50,
                    marginTop: 16,
                    paddingLeft: 16,
                    paddingTop: 16,
                }}
            >
                <Body2Regular style={{ color: colors.WHITE_900 }}>{condition?.memo}</Body2Regular>
            </Row>
        </Col>
    );
};

export default Memo;
