import axios, { AxiosResponse } from 'axios';

export /**
 * @method get
 * @brief 구독 플랜 조회API
 */
const getSubscriptionPlanAPI = async (accessToken: string, adminId: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/admins/subscription-info/${adminId}`,
            headers: { authorization: accessToken },
            data: {},
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method post
 * @brief 무료체험 생성API
 */
const createFreeTrialAPI = async (accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/v1/trials/`,
            headers: { authorization: accessToken },
            data: {},
        });

        if (response.status === 201) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 500:
                return { status: error.response.status };
        }
    }
};

export /**
 * @method post
 * @brief 카드 등록API
 */
const requestRegistrationCardAPI = async (
    accessToken: string,
    authKey: string,
    customerKey: string,
    adminId: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/v1/admins/issue-billingkey/${adminId}`,
            headers: { authorization: accessToken },
            data: {
                authKey,
                customerKey,
            },
        });

        return response;
    } catch (error: any) {
        return error;
    }
};

export /**
 * @method post
 * @brief 결제 요청API
 */
const requestPaymentAPI = async (
    accessToken: string,
    adminId: string,
    count: string,
    price: number,
    email: string,
    activationDate: string,
    expirationDate: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/v1/admins/pay-subscription/${adminId}`,
            headers: { authorization: accessToken },
            data: {
                count,
                price,
                email,
                activationDate,
                expirationDate,
            },
        });

        return response;
    } catch (error: any) {
        return error;
    }
};

export /**
 * @method patch
 * @brief 구독 취소API
 */
const cancelSubscriptionAPI = async (accessToken: string, adminId: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER}/v1/admins/cancel-subscription/${adminId}`,
            headers: { authorization: accessToken },
            data: {},
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method patch
 * @brief 구독 취소 철회API
 */
const cancelSubscriptionWithdrawalAPI = async (accessToken: string, adminId: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER}/v1/admins/withdraw-subscription-cancellation/${adminId}`,
            headers: { authorization: accessToken },
            data: {},
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method patch
 * @brief 구독 변경 API
 */
const changeSubscriptionPlanAPI = async (
    accessToken: string,
    adminId: string,
    planLimitCount: number,
    planAmount: number,
    email: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER}/v1/admins/change-subscription/${adminId}`,
            headers: { authorization: accessToken },
            data: {
                planLimitCount,
                planAmount,
                email,
            },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};
