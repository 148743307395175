import React from 'react';

import dayjs from 'dayjs';
import { Col, Row } from '../../../../lib/utils';
import { Body1Bold, Body3Regular } from '../../../../lib/font';
import { colors } from '../../../../lib/colors';
import { I_LEFT_ANGLE, I_RIGHT_ANGLE } from '../../../../types/images';
import ThreeButton from '../../../Button/ThreeButton';
import ResetButton from '../../../Button/ResetButton';
type Props = {
    isToday: boolean;
    isWeek: boolean;
    isMonth: boolean;
    onClickSubtractDate: () => void;
    onClickAddDate: () => void;
    settingDate: (type: string) => void;
    currentDate: any;
    weekNumber: number;
    setCurrentDate: any;
};
const MemberListGraphHeader = ({
    isToday,
    isWeek,
    isMonth,
    onClickSubtractDate,
    onClickAddDate,
    settingDate,
    currentDate,
    weekNumber,
    setCurrentDate,
}: Props) => {
    return (
        <Col>
            <Body1Bold style={{ color: colors.LAVEL_4, marginTop: 48, marginLeft: 70 }}>
                수업 진행 중 - 회원 수
            </Body1Bold>

            <Row
                style={{
                    marginTop: 24,
                    alignItems: 'center',
                    marginLeft: 70,
                    justifyContent: 'space-between',
                    paddingRight: 70,
                }}
            >
                <Row style={{ alignItems: 'center' }}>
                    {/* 날짜 버튼 */}
                    <Row
                        style={{
                            width: 232,
                            height: 32,
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginRight: 24,
                        }}
                    >
                        <button style={{ width: 24, height: 24 }} onClick={() => onClickSubtractDate()}>
                            <img src={I_LEFT_ANGLE} style={{ width: 24, height: 24 }} />
                        </button>
                        <Body3Regular style={{ width: 160, textAlign: 'center', color: colors.LAVEL_2 }}>
                            {isToday
                                ? currentDate.format('YYYY년 MM월 DD일 (ddd)')
                                : isWeek
                                ? currentDate.format('MM월 ') + weekNumber + '주차'
                                : currentDate.format('YYYY년 MM월')}
                        </Body3Regular>
                        <button style={{ width: 32, height: 32 }} onClick={() => onClickAddDate()}>
                            <img src={I_RIGHT_ANGLE} style={{ width: 24, height: 24 }} />
                        </button>
                    </Row>

                    {/* 일간 / 주간 / 월간 버튼 */}
                    <ThreeButton
                        isFirst={isToday}
                        isSecond={isWeek}
                        isThird={isMonth}
                        firstText={'일간'}
                        secondText={'주간'}
                        thirdText={'월간'}
                        firstCallBack={() => settingDate('today')}
                        secondCallBack={() => settingDate('thisWeek')}
                        thirdCallBack={() => settingDate('thisMonth')}
                    />

                    {/* 초기화 버튼 */}
                    <ResetButton style={{ marginLeft: 24 }} callBack={() => setCurrentDate(dayjs())} />
                </Row>

                <Row
                    style={{
                        height: 24,
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Row style={{ alignItems: 'center' }}>
                        <div
                            style={{
                                width: 16,
                                height: 16,
                                borderRadius: 2,
                                backgroundColor: colors.WHITE_600,
                            }}
                        />
                        <Body3Regular style={{ color: colors.LAVEL_3, marginLeft: 8 }}>{`전체`}</Body3Regular>
                    </Row>

                    <Row style={{ alignItems: 'center', marginLeft: 24 }}>
                        <div
                            style={{
                                width: 16,
                                height: 16,
                                borderRadius: 2,
                                backgroundColor: colors.PRIMARY_BLUE,
                            }}
                        />
                        <Body3Regular style={{ color: colors.LAVEL_3, marginLeft: 8 }}>{`수강권 사용`}</Body3Regular>
                    </Row>
                </Row>
            </Row>
        </Col>
    );
};

export default MemberListGraphHeader;
