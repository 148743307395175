import React from 'react';
import { Col, Row } from '../../../lib/utils';
import EmptyClass from './EmptyClass';
import { Body2Regular, Body3Regular, CaptionRegular } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import dayjs from 'dayjs';
import { I_BOTTOM_PRIMARY, I_TOP_PRIMARY } from '../../../types/images';
import styled from 'styled-components';
import ProfileImage from '../../ProfileImage';

const HoverRow = styled.div`
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    &:hover {
        background: #edf4ff;
        border: 1px solid #e9f1ff;
    }
`;

const ClassHistoryThisYear = ({
    thisYearList,
    searchText,
    thisYearFilterList,
    yearToggleState,
    onClickYearToggleBtn,
}: any) => {
    return (
        <Col style={{ width: 1192 }}>
            {thisYearFilterList && thisYearFilterList.length === 0 ? (
                <EmptyClass />
            ) : (
                thisYearList &&
                thisYearList.map((yearItem: any, yearIndex: number) => {
                    let keys = Object.keys(yearItem);
                    let monthArr = yearItem[keys[0]];
                    let sumCount = 0;

                    let findFilterList = [];

                    if (monthArr.length !== 0) {
                        for (let i = 0; i < monthArr.length; i++) {
                            const item = monthArr[i];
                            let dayKeys = Object.keys(item);
                            let dayObject = item[dayKeys[0]];

                            if (searchText === '') {
                                findFilterList = dayObject;
                            } else {
                                findFilterList = dayObject.filter((e: any) => e.alias.includes(searchText));
                            }

                            sumCount += findFilterList.length;
                        }
                    }

                    return (
                        <Col key={'thisYearArr' + yearIndex}>
                            {monthArr.length !== 0 && (
                                <Row
                                    style={{
                                        width: 275,
                                        height: 48,
                                        alignItems: 'center',
                                        paddingLeft: 8,
                                        paddingRight: 8,
                                    }}
                                >
                                    <Body2Regular style={{ width: 35, color: colors.LAVEL_4 }}>{`${
                                        Object.keys(yearItem)[0]
                                    }월`}</Body2Regular>

                                    <Body2Regular
                                        style={{ width: 88, marginLeft: 32, color: colors.LAVEL_4 }}
                                    >{`총 ${sumCount}회`}</Body2Regular>
                                    <HoverRow onClick={() => onClickYearToggleBtn(yearIndex)}>
                                        <img
                                            src={yearToggleState[yearIndex] ? I_BOTTOM_PRIMARY : I_TOP_PRIMARY}
                                            style={{ width: 32, height: 32 }}
                                        />
                                    </HoverRow>
                                </Row>
                            )}

                            {monthArr.map((monthItem: any, monthIndex: number) => {
                                let dayKeys = Object.keys(monthItem);

                                let dayObject = monthItem[dayKeys[0]];
                                let dayTime = dayObject[0].startTime;

                                let findFilterList = [];

                                if (searchText === '') {
                                    findFilterList = dayObject;
                                } else {
                                    findFilterList = dayObject.filter((e: any) => e.alias.includes(searchText));
                                }

                                return findFilterList && findFilterList.length === 0 ? (
                                    <div key={monthIndex}></div>
                                ) : (
                                    yearToggleState[yearIndex] && (
                                        <Col
                                            style={{ marginTop: 16, marginBottom: 16 }}
                                            key={monthIndex + 'ThisYearYear'}
                                        >
                                            <Row style={{ alignItems: 'center', marginLeft: 16, marginBottom: 16 }}>
                                                <Body3Regular style={{ color: colors.LAVEL_4 }}>{`${dayjs(
                                                    dayTime
                                                ).format('MM월 DD일 (ddd)')}`}</Body3Regular>
                                                <Body3Regular
                                                    style={{ marginLeft: 32, color: colors.LAVEL_4 }}
                                                >{`총 ${findFilterList.length}회`}</Body3Regular>
                                            </Row>
                                            <Col
                                                style={{
                                                    width: 1192,
                                                    minHeight: 118,
                                                    borderRadius: 16,
                                                    backgroundColor: colors.WHITE_50,
                                                    border: '1px solid #C3D4F4',
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                <Row
                                                    style={{
                                                        width: 1192,
                                                        height: 56,
                                                        alignItems: 'center',
                                                        borderBottom: '1px solid #c3d4f4',
                                                        paddingLeft: 46,
                                                    }}
                                                >
                                                    <Row
                                                        style={{
                                                            width: 108,
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Body3Regular
                                                            style={{ color: colors.LAVEL_3 }}
                                                        >{`회원 이름`}</Body3Regular>
                                                    </Row>
                                                    <div
                                                        style={{
                                                            width: 1,
                                                            height: 24,
                                                            backgroundColor: colors.BLUE_100,
                                                            marginLeft: 24,
                                                            marginRight: 24,
                                                        }}
                                                    />

                                                    <Row
                                                        style={{
                                                            width: 320,
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Body3Regular
                                                            style={{ color: colors.LAVEL_3 }}
                                                        >{`수업시간`}</Body3Regular>
                                                    </Row>
                                                    <div
                                                        style={{
                                                            width: 1,
                                                            height: 24,
                                                            backgroundColor: colors.BLUE_100,
                                                            marginLeft: 24,
                                                            marginRight: 24,
                                                        }}
                                                    />

                                                    <Row
                                                        style={{
                                                            width: 320,
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Body3Regular
                                                            style={{ color: colors.LAVEL_3 }}
                                                        >{`수강권`}</Body3Regular>
                                                    </Row>
                                                    <div
                                                        style={{
                                                            width: 1,
                                                            height: 24,
                                                            backgroundColor: colors.BLUE_100,
                                                            marginLeft: 24,
                                                            marginRight: 24,
                                                        }}
                                                    />

                                                    <Row
                                                        style={{
                                                            width: 80,
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Body3Regular
                                                            style={{ color: colors.LAVEL_3 }}
                                                        >{`회차`}</Body3Regular>
                                                    </Row>
                                                    <div
                                                        style={{
                                                            width: 1,
                                                            height: 24,
                                                            backgroundColor: colors.BLUE_100,
                                                            marginLeft: 24,
                                                            marginRight: 24,
                                                        }}
                                                    />

                                                    <Row
                                                        style={{
                                                            width: 80,
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Body3Regular
                                                            style={{ color: colors.LAVEL_3 }}
                                                        >{`상태`}</Body3Regular>
                                                    </Row>
                                                </Row>

                                                {findFilterList.map((childItem: any, childIndex: number) => {
                                                    return (
                                                        <Row
                                                            key={childIndex + 'dayDetailList'}
                                                            style={{
                                                                width: 1192,
                                                                height: 62,
                                                                alignItems: 'center',
                                                                paddingLeft: 46,
                                                            }}
                                                        >
                                                            <Row
                                                                style={{
                                                                    width: 108,
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <ProfileImage
                                                                    imageUrl={childItem?.profileImageUrl}
                                                                    type="VerySmall"
                                                                    profileName={childItem?.profileName}
                                                                    profileColorType={childItem?.profileColorType}
                                                                    right={8}
                                                                />
                                                                <Body3Regular
                                                                    style={{ color: colors.LAVEL_3 }}
                                                                >{`${childItem.alias}`}</Body3Regular>
                                                            </Row>
                                                            <div
                                                                style={{
                                                                    width: 1,
                                                                    height: 24,
                                                                    backgroundColor: colors.WHITE_50,
                                                                    marginLeft: 24,
                                                                    marginRight: 24,
                                                                }}
                                                            />

                                                            <Row
                                                                style={{
                                                                    width: 320,
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <Body3Regular style={{ color: colors.LAVEL_3 }}>
                                                                    {dayjs(childItem.startTime).format('A HH:mm ~ ')}
                                                                    {dayjs(childItem.endTime).format('A HH:mm')}
                                                                </Body3Regular>
                                                            </Row>
                                                            <div
                                                                style={{
                                                                    width: 1,
                                                                    height: 24,
                                                                    backgroundColor: colors.WHITE_50,
                                                                    marginLeft: 24,
                                                                    marginRight: 24,
                                                                }}
                                                            />

                                                            <Row
                                                                style={{
                                                                    width: 320,
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <Body3Regular style={{ color: colors.LAVEL_3 }}>{`${
                                                                    childItem?.membershipName
                                                                        ? childItem?.membershipName
                                                                        : '-'
                                                                }`}</Body3Regular>
                                                            </Row>
                                                            <div
                                                                style={{
                                                                    width: 1,
                                                                    height: 24,
                                                                    backgroundColor: colors.WHITE_50,
                                                                    marginLeft: 24,
                                                                    marginRight: 24,
                                                                }}
                                                            />

                                                            <Row
                                                                style={{
                                                                    width: 80,
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <Body3Regular style={{ color: colors.LAVEL_3 }}>
                                                                    {childItem.currentSession
                                                                        ? childItem.currentSession
                                                                        : '-'}
                                                                    /
                                                                    {childItem.totalSession
                                                                        ? childItem.totalSession
                                                                        : '-'}
                                                                    회
                                                                </Body3Regular>
                                                            </Row>
                                                            <div
                                                                style={{
                                                                    width: 1,
                                                                    height: 24,
                                                                    backgroundColor: colors.WHITE_50,
                                                                    marginLeft: 24,
                                                                    marginRight: 24,
                                                                }}
                                                            />

                                                            <Row
                                                                style={{
                                                                    width: 80,
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <Row
                                                                    style={{
                                                                        width: 46,
                                                                        height: 30,
                                                                        borderRadius: 4,
                                                                        backgroundColor: !!childItem?.attendanceStatus // attendanceStatus 없을 때는 모두 ATTENDANCE 취급
                                                                            ? childItem?.attendanceStatus ===
                                                                              'ATTENDANCE'
                                                                                ? colors.SUCCESS_50
                                                                                : colors.ERROR_50
                                                                            : colors.SUCCESS_50,
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                    }}
                                                                >
                                                                    <CaptionRegular
                                                                        style={{
                                                                            color: !!childItem?.attendanceStatus // attendanceStatus 없을 때는 모두 ATTENDANCE 취급
                                                                                ? childItem?.attendanceStatus ===
                                                                                  'ATTENDANCE'
                                                                                    ? colors.SUCCESS
                                                                                    : colors.ERROR
                                                                                : colors.SUCCESS,
                                                                        }}
                                                                    >{`${
                                                                        !!childItem?.attendanceStatus // attendanceStatus 없을 때는 모두 ATTENDANCE 취급
                                                                            ? childItem?.attendanceStatus ===
                                                                              'ATTENDANCE'
                                                                                ? '출석'
                                                                                : '노쇼'
                                                                            : '출석'
                                                                    }`}</CaptionRegular>
                                                                </Row>
                                                            </Row>
                                                        </Row>
                                                    );
                                                })}
                                            </Col>
                                        </Col>
                                    )
                                );
                            })}
                        </Col>
                    );
                })
            )}
        </Col>
    );
};

export default ClassHistoryThisYear;
