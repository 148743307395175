import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { getCancelAndChangeCountAPI } from '../../../api/schedule';
import { useQuery } from '@tanstack/react-query';
import WeeklyScheduleHistoryGraph from './WeeklyScheduleHistoryGraph';
import DailyScheduleHistoryGraph from './DailyScheduleHistoryGraph';
import MonthlyScheduleHistoryGraph from './MonthlyScheduleHistoryGraph';
import ScheduleHistoryGraphHeader from './ScheduleHistoryGraphHeader';
import { Col, getCurrentDayWeekNumber, getCurrentWeekNumberOfMonth } from '../../../lib/utils';
import {
    dailyDummyData,
    dailyMaxCountDummy,
    monthlyDummyData,
    monthlyMaxCountDummy,
    weeklyDummyData,
    weeklyMaxCountDummy,
} from './DummyData';
import { colors } from '../../../lib/colors';

const ScheduleHistoryGraph = ({ authorization, authObject }: any) => {
    // 초기값 설정
    const [currentDate, setCurrentDate] = useState(dayjs());

    // 일간
    const [dailyEntireData, setDailyEntireData] = useState<any>();
    const [dailyFilterData, setDailyFilterData] = useState<any>();
    const [dayCancelCount, setDayCancelCount] = useState<any>(null);
    const [dayChangeCount, setDayChangeCount] = useState<any>(null);
    // 주간
    const [weeklyEntireData, setWeeklyEntireData] = useState<any>();
    const [weeklyFilterData, setWeeklyFilterData] = useState<any>();
    const [weekCancelCount, setWeekCancelCount] = useState<any>(null);
    const [weekChangeCount, setWeekChangeCount] = useState<any>(null);
    const [monthWeekCount, setMonthWeekCount] = useState<number>(0);

    // 월간
    const [monthlyEntireData, setMonthlyEntireData] = useState<any>();
    const [monthlyFilterData, setMonthlyFilterData] = useState<any>();
    const [monthCancelCount, setMonthCancelCount] = useState<any>(null);
    const [monthChangeCount, setMonthChangeCount] = useState<any>(null);

    const [todayMaxCount, setTodayMaxCount] = useState<number>(0);
    const [weekMaxCount, setWeekMaxCount] = useState<number>(0);
    const [monthMaxCount, setMonthMaxCount] = useState<number>(0);

    // 몇주차 표시
    const [weekNumber, setWeekNumber] = useState<number>(0);

    const [isToday, setIsToday] = useState<boolean>(true);
    const [isWeek, setIsWeek] = useState<boolean>(false);
    const [isMonth, setIsMonth] = useState<boolean>(false);

    const onClickAddDate = () => {
        if (isToday) {
            const newDate = dayjs(currentDate).add(1, 'week');
            setCurrentDate(newDate);
        }

        if (isWeek) {
            const newDate = dayjs(currentDate).add(1, 'month');
            setCurrentDate(newDate);
        }

        if (isMonth) {
            const newDate = dayjs(currentDate).add(1, 'year');
            setCurrentDate(newDate);
        }
    };

    const onClickSubtractDate = () => {
        if (isToday) {
            const newDate = dayjs(currentDate).subtract(1, 'week');
            setCurrentDate(newDate);
        }

        if (isWeek) {
            const newDate = dayjs(currentDate).subtract(1, 'month');
            setCurrentDate(newDate);
        }

        if (isMonth) {
            const newDate = dayjs(currentDate).subtract(1, 'year');
            setCurrentDate(newDate);
        }
    };

    const settingDate = (type: string) => {
        setCurrentDate(dayjs());
        if (type === 'today') {
            setIsToday(true);
            setIsWeek(false);
            setIsMonth(false);
        }

        if (type === 'thisWeek') {
            setIsToday(false);
            setIsWeek(true);
            setIsMonth(false);
        }

        if (type === 'thisMonth') {
            setIsToday(false);
            setIsWeek(false);
            setIsMonth(true);
        }
    };

    useEffect(() => {
        if (currentDate) {
            // 해당주차 찾기
            setWeekNumber(getCurrentDayWeekNumber(currentDate));

            // 해당월이 몇주차로 이루어져 있는지
            setMonthWeekCount(getCurrentWeekNumberOfMonth(currentDate));
        }
    }, [currentDate]);

    //  수업진행 상황 그래프 조회 API : (GET)
    const getCancelAndChangeCount = useQuery(
        ['getCancelAndChangeCountAPI', currentDate],
        async () =>
            await getCancelAndChangeCountAPI(
                authObject.centerId,
                dayjs(currentDate).format('YYYY-MM-DD'),
                authorization
            ),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const dailyData = res.data.daily;
                    const weeklyData = res.data.weekly;
                    const monthlyData = res.data.monthly;

                    const dailyMaxCount = res.data.dailyMaxCount;
                    const weeklyMaxCount = res.data.weeklyMaxCount;
                    const monthlyMaxCount = res.data.monthlyMaxCount;

                    if (dailyData) {
                        let findTodayObject = dailyData.find((item: any) => item.isToday === true);
                        if (findTodayObject) {
                            setDayCancelCount(findTodayObject?.todayCancelCount);
                            setDayChangeCount(findTodayObject?.todayChangeCount);
                        } else {
                            setDayCancelCount(null);
                            setDayChangeCount(null);
                        }
                    }

                    if (weeklyData) {
                        let findThisWeekObject = weeklyData.find((item: any) => item.isToday === true);

                        if (findThisWeekObject && dayjs(currentDate).format('YYYY-MM') === dayjs().format('YYYY-MM')) {
                            setWeekCancelCount(findThisWeekObject?.todayCancelCount);
                            setWeekChangeCount(findThisWeekObject?.todayChangeCount);
                        } else {
                            setWeekCancelCount(null);
                            setWeekChangeCount(null);
                        }
                    }

                    if (monthlyData) {
                        let findThisMonthObject = monthlyData.find((item: any) => item.isToday === true);

                        if (findThisMonthObject) {
                            setMonthCancelCount(findThisMonthObject?.todayCancelCount);
                            setMonthChangeCount(findThisMonthObject?.todayChangeCount);
                        } else {
                            setMonthCancelCount(null);
                            setMonthChangeCount(null);
                        }
                    }

                    setDailyEntireData(dailyData);
                    setWeeklyEntireData(weeklyData);
                    setMonthlyEntireData(monthlyData);

                    setTodayMaxCount(dailyMaxCount);
                    setWeekMaxCount(weeklyMaxCount);
                    setMonthMaxCount(monthlyMaxCount);

                    let dailyDataArr: any = [
                        {
                            id: 'cancel',
                            color: 'hsla(356, 100%, 62%, 1)',
                            data: [],
                        },
                        {
                            id: 'change',
                            color: 'hsla(220, 70%, 55%, 1)',
                            data: [],
                        },
                    ];

                    let weeklyDataArr: any = [
                        {
                            id: 'cancel',
                            color: 'hsla(356, 100%, 62%, 1)',
                            data: [],
                        },
                        {
                            id: 'change',
                            color: 'hsla(220, 70%, 55%, 1)',
                            data: [],
                        },
                    ];

                    let monthlyDataArr: any = [
                        {
                            id: 'cancel',
                            color: 'hsl(356, 100%, 62%)',
                            data: [],
                        },
                        {
                            id: 'change',
                            color: 'hsla(220, 70%, 55%, 1)',
                            data: [],
                        },
                    ];

                    // 당일
                    for (let i = 0; i < dailyData.length; i++) {
                        dailyDataArr[0].data.push({
                            x: dailyData[i]?.xLabel,
                            y: dailyData[i]?.todayCancelCount,
                        });
                        dailyDataArr[1].data.push({
                            x: dailyData[i]?.xLabel,
                            y: dailyData[i]?.todayChangeCount,
                        });
                    }
                    // 이번 주
                    for (let i = 0; i < weeklyData.length; i++) {
                        weeklyDataArr[0].data.push({ x: weeklyData[i]?.xLabel, y: weeklyData[i]?.todayCancelCount });
                        weeklyDataArr[1].data.push({ x: weeklyData[i]?.xLabel, y: weeklyData[i]?.todayChangeCount });
                    }

                    // 이번 달
                    for (let i = 0; i < monthlyData.length; i++) {
                        monthlyDataArr[0].data.push({ x: monthlyData[i]?.xLabel, y: monthlyData[i]?.todayCancelCount });
                        monthlyDataArr[1].data.push({ x: monthlyData[i]?.xLabel, y: monthlyData[i]?.todayChangeCount });
                    }
                    setDailyFilterData(dailyDataArr);
                    setWeeklyFilterData(weeklyDataArr);
                    setMonthlyFilterData(monthlyDataArr);
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!!authObject?.testAccount,
            // refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    // 데모버전 일간 더미데이터 세팅
    useEffect(() => {
        if (authObject?.testAccount && dailyDummyData && dailyMaxCountDummy) {
            const dailyData = dailyDummyData;
            const dailyMaxCount = dailyMaxCountDummy;

            let findTodayObject = dailyData.find((item: any) => item.isToday === true);
            if (findTodayObject) {
                setDayCancelCount(findTodayObject?.todayCancelCount);
                setDayChangeCount(findTodayObject?.todayChangeCount);
            } else {
                setDayCancelCount(null);
                setDayChangeCount(null);
            }

            setDailyEntireData(dailyData);
            setTodayMaxCount(dailyMaxCount);

            let dailyDataArr: any = [
                {
                    id: 'cancel',
                    color: 'hsla(356, 100%, 62%, 1)',
                    data: [],
                },
                {
                    id: 'change',
                    color: 'hsla(219, 70%, 79%, 1)',
                    data: [],
                },
            ];

            // 당일
            for (let i = 0; i < dailyData.length; i++) {
                dailyDataArr[0].data.push({
                    x: dailyData[i]?.xLabel,
                    y: dailyData[i]?.todayCancelCount,
                });
                dailyDataArr[1].data.push({
                    x: dailyData[i]?.xLabel,
                    y: dailyData[i]?.todayChangeCount,
                });
            }

            setDailyFilterData(dailyDataArr);
        }
    }, [dailyDummyData, dailyMaxCountDummy]);

    // 데모버전 주간 더미데이터 세팅
    useEffect(() => {
        if (authObject?.testAccount && weeklyDummyData && weeklyMaxCountDummy) {
            const weeklyData = weeklyDummyData;
            const weeklyMaxCount = weeklyMaxCountDummy;

            let findThisWeekObject = weeklyData.find((item: any) => item.isToday === true);
            if (findThisWeekObject) {
                setWeekCancelCount(findThisWeekObject?.todayCancelCount);
                setWeekChangeCount(findThisWeekObject?.todayChangeCount);
            } else {
                setWeekCancelCount(null);
                setWeekChangeCount(null);
            }

            setWeeklyEntireData(weeklyData);
            setWeekMaxCount(weeklyMaxCount);

            let weeklyDataArr: any = [
                {
                    id: 'cancel',
                    color: 'hsla(356, 100%, 62%, 1)',
                    data: [],
                },
                {
                    id: 'change',
                    color: 'hsla(219, 70%, 79%, 1)',
                    data: [],
                },
            ];

            // 이번 주
            for (let i = 0; i < weeklyData.length; i++) {
                weeklyDataArr[0].data.push({ x: weeklyData[i]?.xLabel, y: weeklyData[i]?.todayCancelCount });
                weeklyDataArr[1].data.push({ x: weeklyData[i]?.xLabel, y: weeklyData[i]?.todayChangeCount });
            }
            setWeeklyFilterData(weeklyDataArr);
        }
    }, [weeklyDummyData, weeklyMaxCountDummy]);

    // 데모버전 월간 더미데이터 세팅
    useEffect(() => {
        if (authObject?.testAccount && monthlyDummyData && monthlyMaxCountDummy) {
            const monthlyData = monthlyDummyData;
            const monthlyMaxCount = monthlyMaxCountDummy;

            let findThisMonthObject = monthlyData.find((item: any) => item.isToday === true);

            if (findThisMonthObject) {
                setMonthCancelCount(findThisMonthObject?.todayCancelCount);
                setMonthChangeCount(findThisMonthObject?.todayChangeCount);
            } else {
                setMonthCancelCount(null);
                setMonthChangeCount(null);
            }

            setMonthlyEntireData(monthlyData);
            setMonthMaxCount(monthlyMaxCount);
            let monthlyDataArr: any = [
                {
                    id: 'cancel',
                    color: 'hsl(356, 100%, 62%)',
                    data: [],
                },
                {
                    id: 'change',
                    color: 'hsl(219, 70%, 79%)',
                    data: [],
                },
            ];

            // 이번 달
            for (let i = 0; i < monthlyData.length; i++) {
                monthlyDataArr[0].data.push({ x: monthlyData[i]?.xLabel, y: monthlyData[i]?.todayCancelCount });
                monthlyDataArr[1].data.push({ x: monthlyData[i]?.xLabel, y: monthlyData[i]?.todayChangeCount });
            }
            setMonthlyFilterData(monthlyDataArr);
        }
    }, [monthlyDummyData, monthlyMaxCountDummy]);

    return (
        <Col
            style={{
                width: 1192,
                height: 612,
                marginTop: 24,

                borderRadius: 16,
                backgroundColor: colors.WHITE_50,
                border: '1px solid #C3D4F4',
            }}
        >
            <ScheduleHistoryGraphHeader
                currentDate={currentDate}
                weekNumber={weekNumber}
                onClickSubtractDate={onClickSubtractDate}
                onClickAddDate={onClickAddDate}
                isToday={isToday}
                isWeek={isWeek}
                isMonth={isMonth}
                settingDate={settingDate}
                setCurrentDate={setCurrentDate}
                authObject={authObject}
                dayCancelCount={dayCancelCount}
                dayChangeCount={dayChangeCount}
                weekCancelCount={weekCancelCount}
                weekChangeCount={weekChangeCount}
                monthCancelCount={monthCancelCount}
                monthChangeCount={monthChangeCount}
            />

            {isToday && dailyFilterData && dailyEntireData && (
                <DailyScheduleHistoryGraph
                    dailyFilterData={dailyFilterData}
                    dailyEntireData={dailyEntireData}
                    todayMaxCount={todayMaxCount}
                />
            )}
            {isWeek && weeklyFilterData && weeklyEntireData && (
                <WeeklyScheduleHistoryGraph
                    weeklyFilterData={weeklyFilterData}
                    weeklyEntireData={weeklyEntireData}
                    weekMaxCount={weekMaxCount}
                />
            )}
            {isMonth && monthlyFilterData && monthlyEntireData && (
                <MonthlyScheduleHistoryGraph
                    monthlyFilterData={monthlyFilterData}
                    monthlyEntireData={monthlyEntireData}
                    monthMaxCount={monthMaxCount}
                />
            )}
        </Col>
    );
};

export default ScheduleHistoryGraph;
