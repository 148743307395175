import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Modal from '../modal/Modal';
import { Col, getItemWithExpiration, Row } from '../../lib/utils';
import { Body2Bold, Body3Regular, CaptionLight, CaptionRegular, Subtitle1Bold } from '../../lib/font';
import useMembershipService from '../../services/useMembershipService';
import CloseButton from '../Button/CloseButton';
import NewColorRoundButton from '../Button/NewColorRoundButton';
import RadiusTextInput from '../TextInput/RadiusTextInput';
import MembershipTemplate from '../../scenes/ClassPass/ClassPassList/MembershipTemplate';
import dayjs from 'dayjs';
import ColTitleAndTextInput from '../ColTitleAndTextInput';
import MemoTextInput from '../TextInput/MemoTextInput';
import NewWhiteSquareButton from '../Button/NewWhiteSquareButton';
import NewColorSquareButton from '../Button/NewColorSquareButton';
import ModalUpdateMembershipName from './ModalUpdateMembershipName';
import { useQueryClient } from '@tanstack/react-query';
import { MembershipTemplateDataType } from '../../services/useMembershipTemplateQueryService';
import { MemberShipApiType } from '../../api/memberShip';

type Props = {
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    // 새로운 수강권 만들기 버튼 클릭 시
    onClickCreateNewMembership: () => void;

    memberName?: string;
    coachName?: string;
    coachId?: string;
    memberId?: string;
    type?: string;
    createAlert?: any;

    // 수강권 등록 완료 시
    onMembershipCreateSuccess?: (membership: MemberShipApiType) => void;
};

const ModalMembershipCreate = ({
    setIsVisible,
    onClickCreateNewMembership,
    memberName,
    coachName,
    coachId,
    memberId,
    type = 'memberProfile',
    createAlert,
    onMembershipCreateSuccess = undefined,
}: Props) => {
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));
    const queryClient = useQueryClient();

    const { allMembershipTemplatesListData, createMemberShipMutation } = useMembershipService({
        fetchAllMembershipTemplatesList: true,
        onCreateMemberShipSuccess() {
            setIsVisible(false);
            createAlert('수강권 등록 완료', '새로운 수강권이 성공적으로 등록되어 회원님께 발급되었습니다!', false);
            if (type === 'memberProfile') {
                queryClient.invalidateQueries({
                    queryKey: ['getAllMembershipForMemberQuery'],
                });
            } else if (type === 'schedule') {
                // 수강권 데이터 다시 불러와야함
                queryClient.invalidateQueries({
                    queryKey: ['getMemberListForCenterAPI'],
                });
            }
        },
    });

    const [filterText, setFilterText] = useState<string>('');

    // filterText 가 있을 때만 필터링
    const filteredData = allMembershipTemplatesListData.filter((item: any) => item.name.includes(filterText));

    // 수강권 이름 수정 모달
    const [isMembershipNameModalVisible, setIsMembershipNameModalVisible] = useState(false);

    // 1,2 단계
    const [step, setStep] = useState<number>(1);
    // 수강권 템플릿 Id
    const [selectMemberShipTemplateId, setSelectMemberShipTemplateId] = useState<string>('');

    // 회당 가격
    const [pricePerClass, setPricePerClass] = useState<number>(0);
    // 판매 금액
    const [totalPrice, setTotalPrice] = useState<number>(0);

    // 시작 날짜
    const [startDate, setStartDate] = useState<string>('');
    // 만료 날짜 expirationDate
    const [expirationDate, setExpirationDate] = useState<string>('');
    // 수강권 기간
    const [membershipPeriod, setMembershipPeriod] = useState<number>(0);
    // 시작 회차 startSession
    const [startSession, setStartSession] = useState<number>(1);
    // 총 회차 totalSession
    const [totalSession, setTotalSession] = useState<number>(0);
    // 수업 시간
    const [classTime, setClassTime] = useState<number>(50);
    // 메모
    const [memo, setMemo] = useState<string>('');
    // BGColor
    const [BGColor, setBGColor] = useState<string[]>([]);
    // 수강권 이름
    const [membershipName, setMembershipName] = useState<string>('');

    // 수강권 선택
    const onClickMembershipTemplate = (item: MembershipTemplateDataType) => {
        setStep(2);
        setPricePerClass(item.pricePerSchedule);
        setTotalPrice(item.totalPrice);
        setStartDate(dayjs().toISOString());
        setExpirationDate(dayjs().add(item.validDays, 'day').toISOString());
        setMembershipPeriod(item.validDays);
        setTotalSession(item.totalSession);
        setBGColor(item.BGColor);
        setMembershipName(item.name);
        setSelectMemberShipTemplateId(item._id);
        setClassTime(item.period);
    };

    // 등록완료 버튼 클릭 시
    const onClickCreateMembership = () => {
        if (classTime === null || classTime.toString().trim().length < 1) {
            createAlert('필수 입력', '수업 시간을 입력하여 주세요', true);
            return;
        }
        if (classTime % 10 > 0 || classTime.toString().trim().length < 1) {
            createAlert('올바르지 않은 입력', '수업 시간을 10분단위로 입력하여 주세요', true);
            return;
        }
        if (onMembershipCreateSuccess) {
            const membership: MemberShipApiType = {
                coachId: coachId,
                membershipTemplateId: selectMemberShipTemplateId,
                name: membershipName,
                period: classTime,
                activationDate: startDate,
                expirationDate: expirationDate,
                startSessionValue: startSession,
                totalSession: totalSession,
                memo: memo,
                pricePerSchedule: pricePerClass,
                status: 'active',
                totalPrice: totalPrice,
                BGColor: BGColor,
                issuer: { issuerId: authObject.adminId, issuerRole: 'admin' },
            };

            onMembershipCreateSuccess && onMembershipCreateSuccess(membership);
        } else {
            createMemberShipMutation.mutate({
                coachId: coachId,
                memberId: memberId,
                membershipTemplateId: selectMemberShipTemplateId,
                name: membershipName,
                period: classTime,
                activationDate: startDate,
                expirationDate: expirationDate,
                startSessionValue: startSession,
                totalSession: totalSession,
                memo: memo,
                pricePerSchedule: pricePerClass,
                status: 'active', // [ ] active 의 기준?
                totalPrice: totalPrice,
                issuer: { issuerId: authObject.adminId, issuerRole: 'admin' },
                BGColor: BGColor,
                accessToken: authorization,
            });
        }
    };

    // 수강권 기간 계산, 만료날짜 - 시작날짜
    useEffect(() => {
        if (startDate && expirationDate) {
            const start = dayjs(startDate);
            const end = dayjs(expirationDate);
            const diff = end.diff(start, 'days');
            setMembershipPeriod(diff);
        }
    }, [startDate, expirationDate]);

    return (
        <Modal whiteViewHeight={700} whiteViewWidth={1080} setIsVisible={setIsVisible} isCloseOutside={false}>
            <CloseButton size={24} className=" absolute right-[25px] top-[25px]" onClick={() => setIsVisible(false)} />
            <Col className="flex py-[36px] px-[48px]">
                <Row className="mb-[24px]">
                    <Subtitle1Bold>수강권 등록하기</Subtitle1Bold>
                </Row>
                {step === 1 ? (
                    <>
                        <Row className="flex justify-between mb-[40px]">
                            <Row className="flex flex-row  items-center">
                                <Col className=" justify-center items-center bg-primary w-[24px] h-[24px] rounded-full">
                                    <CaptionRegular className="text-white">1</CaptionRegular>
                                </Col>
                                <Col className="w-[36px] h-[1px] bg-BLUE_200" />

                                <Col className=" justify-center items-center bg-BLUE_200 w-[24px] h-[24px] rounded-full">
                                    <CaptionRegular className="text-white">2</CaptionRegular>
                                </Col>
                            </Row>
                            <Row>
                                <NewColorRoundButton
                                    bgColor="primary"
                                    text="새로운 수강권 만들기"
                                    onClick={onClickCreateNewMembership}
                                    textColor="white"
                                    fontType="Body3Regular"
                                    width={188}
                                    height={38}
                                    className="mr-[16px]"
                                />
                                <RadiusTextInput
                                    textInputHeight={36}
                                    value={filterText}
                                    placeholder="수강권 검색하기"
                                    width={310}
                                    onChange={(value) => setFilterText(value)}
                                    onClear={() => setFilterText('')}
                                    isRightIcon={false}
                                    isLeftIcon
                                    isEnterChange
                                />
                            </Row>
                        </Row>
                        <div className="grid grid-cols-3 gap-6 w-full px-[56px]">
                            {filteredData.map((item: { _id: any }, index: any) => (
                                <MembershipTemplate
                                    key={item._id}
                                    index={index}
                                    item={item}
                                    onClickDetailPage={onClickMembershipTemplate}
                                />
                            ))}
                        </div>
                    </>
                ) : (
                    <>
                        <Row className="flex flex-row  items-center mb-[40px]">
                            <Col className=" justify-center items-center bg-BLUE_200 w-[24px] h-[24px] rounded-full">
                                <CaptionRegular className="text-white">1</CaptionRegular>
                            </Col>
                            <Col className="w-[36px] h-[1px] bg-BLUE_200" />

                            <Col className=" justify-center items-center bg-primary w-[24px] h-[24px] rounded-full">
                                <CaptionRegular className="text-white">2</CaptionRegular>
                            </Col>
                        </Row>
                        <Row className="mb-[32px]">
                            <Col className="mr-[40px]">
                                <Col
                                    style={{
                                        width: 264,
                                        height: 120,
                                        background: `linear-gradient(135deg, ${BGColor[0]} 0%, ${BGColor[1]} 100%)`,
                                        borderRadius: 8,
                                        justifyContent: 'center',
                                        paddingRight: 24,
                                        paddingLeft: 24,
                                        marginBottom: 24,
                                    }}
                                >
                                    <Body2Bold className="text-white">{`${membershipName}`}</Body2Bold>
                                </Col>
                                {/* <NewWhiteRoundButton
                                    text="수강권 이름 수정하기"
                                    onClick={() => setIsMembershipNameModalVisible(true)}
                                    fontType="CaptionRegular"
                                    width={136}
                                    height={24}
                                    mb={52}
                                /> */}
                                <ColTitleAndTextInput
                                    title="회당 가격 (원)"
                                    value={pricePerClass}
                                    onChange={(value) => {
                                        if (value === '') {
                                            value = '0';
                                        }
                                        setPricePerClass(parseInt(value));
                                    }}
                                    width={264}
                                    height={36}
                                    onClear={() => {
                                        setPricePerClass(0);
                                    }}
                                    textInputRightIcon={false}
                                    mb={32}
                                    isClearIcon={false}
                                />
                                <ColTitleAndTextInput
                                    title="판매 금액 (원)"
                                    value={totalPrice}
                                    onChange={(value) => {
                                        if (value === '') {
                                            value = '0';
                                        }
                                        setTotalPrice(parseInt(value));
                                    }}
                                    width={264}
                                    height={36}
                                    onClear={() => {
                                        setTotalPrice(0);
                                    }}
                                    textInputRightIcon={false}
                                    isClearIcon={false}
                                />
                            </Col>
                            <Col className="w-[644px] border-[1px] border-solid border-BLUE_100 rounded-[8px] p-[32px]">
                                <Row className="mb-[48px]">
                                    {coachName && (
                                        <ColTitleAndTextInput
                                            title="담당 강사"
                                            value={coachName}
                                            onChange={() => {}}
                                            width={240}
                                            height={36}
                                            textInputRightIcon={false}
                                            textInputLeftIcon={false}
                                            disabled
                                            mr={60}
                                        />
                                    )}
                                    {memberName && (
                                        <ColTitleAndTextInput
                                            title="회원"
                                            value={memberName}
                                            onChange={() => {}}
                                            width={240}
                                            height={36}
                                            textInputRightIcon={false}
                                            textInputLeftIcon={false}
                                            disabled
                                        />
                                    )}
                                </Row>
                                <Row className="mb-[48px]">
                                    <ColTitleAndTextInput
                                        title="시작 날짜"
                                        value={startDate}
                                        isCalendar
                                        onClickDate={(date) => {
                                            setStartDate(dayjs(date).toISOString());
                                            setMembershipPeriod(dayjs(expirationDate).diff(dayjs(date), 'day'));
                                        }}
                                        width={240}
                                        height={36}
                                        mr={60}
                                    />
                                    <ColTitleAndTextInput
                                        title="만료 날짜"
                                        value={expirationDate}
                                        isCalendar
                                        onClickDate={(date) => {
                                            setExpirationDate(dayjs(date).toISOString());
                                            setMembershipPeriod(dayjs(date).diff(dayjs(startDate), 'day'));
                                        }}
                                        width={240}
                                        height={36}
                                    />
                                </Row>
                                <Row className=" items-end mb-[48px]">
                                    <ColTitleAndTextInput
                                        title="수강권 기간"
                                        value={membershipPeriod.toString()}
                                        onChange={(value) => {
                                            if (value === '') {
                                                value = '0';
                                            }
                                            setMembershipPeriod(parseInt(value));
                                            setExpirationDate(
                                                dayjs(startDate).add(parseInt(value), 'day').toISOString()
                                            );
                                        }}
                                        width={140}
                                        height={36}
                                        mr={32}
                                        isClearIcon={false}
                                    />
                                    <Col>
                                        <CaptionLight className="text-WHITE_900">
                                            시작, 만료날짜를 입력하시면 유효기간이 자동으로 계산 되어요!
                                        </CaptionLight>
                                        <CaptionLight className="text-WHITE_900">
                                            만료 날짜가 지나도 수업 회차가 남으면 만료되지 않아요!
                                        </CaptionLight>
                                    </Col>
                                </Row>
                                <Row className="mb-[48px]">
                                    <ColTitleAndTextInput
                                        title="시작 회차"
                                        value={startSession.toString()}
                                        onChange={(value) => {
                                            if (value === '') {
                                                value = '0';
                                            }
                                            setStartSession(parseInt(value));
                                        }}
                                        width={140}
                                        height={36}
                                        mr={60}
                                        textInputRightIcon={false}
                                        isClearIcon={false}
                                    />
                                    <ColTitleAndTextInput
                                        title="총 회차"
                                        value={totalSession.toString()}
                                        onChange={(value) => {
                                            if (value === '') {
                                                value = '0';
                                            }
                                            setTotalSession(parseInt(value));
                                        }}
                                        width={140}
                                        height={36}
                                        textInputRightIcon={false}
                                        isClearIcon={false}
                                        mr={60}
                                    />
                                    <ColTitleAndTextInput
                                        title="수업 시간"
                                        value={classTime}
                                        onChange={(value) => {
                                            if (value === '') {
                                                value = '0';
                                            }
                                            setClassTime(parseInt(value));
                                        }}
                                        width={140}
                                        height={36}
                                        textInputRightIcon={false}
                                        isClearIcon={false}
                                    />
                                </Row>
                                <Col>
                                    <Body3Regular className="mb-[6px]">메모</Body3Regular>
                                    <CaptionLight className="mb-[8px]">
                                        수강권의 메모는 관리자님과 강사님만 확인할 수 있어요!
                                    </CaptionLight>
                                    <MemoTextInput
                                        value={memo}
                                        onChange={(e) => setMemo(e.target.value)}
                                        height={136}
                                        placeholder="기억해야 할 회원님의 상태를 메모해보세요"
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <Row className="w-full justify-end">
                            <NewWhiteSquareButton
                                text="이전"
                                fontType="Body2Regular"
                                width={148}
                                height={48}
                                mr={16}
                                onClick={() => setStep(1)}
                            />
                            <NewColorSquareButton
                                text="등록 완료"
                                fontType="Body2Regular"
                                width={148}
                                height={48}
                                onClick={onClickCreateMembership}
                            />
                        </Row>
                    </>
                )}
            </Col>
            {isMembershipNameModalVisible && (
                <ModalUpdateMembershipName
                    onUpdateComplete={(value: string) => {
                        setMembershipName(value);
                        setIsMembershipNameModalVisible(false);
                    }}
                    setIsVisible={setIsMembershipNameModalVisible}
                    value={membershipName}
                />
            )}
        </Modal>
    );
};

export default ModalMembershipCreate;
