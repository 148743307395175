import React from 'react';
import { Col, Row } from '../../lib/utils';
import { colors } from '../../lib/colors';
import { Body2Bold, Body2Regular, CaptionRegular } from '../../lib/font';
import styled from 'styled-components';
import { I_POPUP_EXIT } from '../../types/images';
import dayjs from 'dayjs';
import ProfileImage from '../ProfileImage';

const HoverImage = styled.img`
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

const ModalDetailCommunicationHeader = ({ modalObject, setIsDetailModal, setCommunicationObject }: any) => {
    return (
        <Col
            style={{
                width: 708,
            }}
        >
            <Row style={{ alignItems: 'center', marginLeft: 60, marginTop: 17 }}>
                <Body2Bold style={{ color: colors.LAVEL_4 }}>
                    {modalObject?.category === 'PTFEEDBACK'
                        ? 'PT 피드백'
                        : modalObject?.category === 'FACILITY'
                        ? '시설문의'
                        : modalObject?.category === 'COUNSEL'
                        ? '상담문의'
                        : '기타'}
                </Body2Bold>

                <Row
                    style={{
                        width: 46,
                        height: 30,
                        borderRadius: 4,
                        backgroundColor: modalObject?.status === 'PENDING' ? colors.ERROR_50 : colors.SUCCESS_50,
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 17,
                    }}
                >
                    {modalObject?.status === 'PENDING' ? (
                        <CaptionRegular style={{ color: colors.ERROR }}>{'대기'}</CaptionRegular>
                    ) : (
                        <CaptionRegular style={{ color: colors.SUCCESS }}>{'완료'}</CaptionRegular>
                    )}
                </Row>
            </Row>

            <Row
                style={{
                    width: 587,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginLeft: 61,
                    marginTop: 16,
                    paddingLeft: 8,
                    paddingRight: 8,
                }}
            >
                <CaptionRegular
                    style={{ color: colors.LAVEL_2 }}
                >{`담당 강사 : ${modalObject?.coachName} 강사`}</CaptionRegular>

                <CaptionRegular style={{ color: colors.LAVEL_2 }}>{`소통 생성 : ${dayjs(modalObject.updatedTime).format(
                    'YYYY년 M월 D일 (ddd) A H시 mm분'
                )}`}</CaptionRegular>
            </Row>

            <Row style={{ alignItems: 'center', marginLeft: 68, marginTop: 16 }}>
                <ProfileImage
                    imageUrl={modalObject?.profileImageUrl}
                    type="Small"
                    profileName={modalObject?.profileName}
                    profileColorType={modalObject?.profileColorType}
                    right={16}
                />
                <Body2Regular style={{ color: colors.LAVEL_4 }}>{modalObject?.memberName} 회원</Body2Regular>
            </Row>
        </Col>
    );
};

export default ModalDetailCommunicationHeader;
