import React from 'react';
import { colors } from '../../lib/colors';

const CloseButton = ({
    color = 'GREY_500',
    size = 18,
    onClick,
    className,
}: {
    color?: keyof typeof colors;
    size?: number;
    onClick?: () => void;
    className?: string;
}) => {
    return (
        <div onClick={onClick} className={`cursor-pointer ${className}`}>
            <svg
                className={` text-gray-500`}
                width={size}
                height={size}
                fill="none"
                stroke={colors[color]}
                stroke-width="1"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
        </div>
    );
};

export default CloseButton;
