// 공통
import i_rappo_logo from '../assets/images/common/i_rappo_logo.png';
import i_white_logo from '../assets/images/login/i_white_logo.png';
import i_sign_background from '../assets/images/login/i_sign_background.png';
import i_back from '../assets/images/common/i_back.png';
import i_drop_down from '../assets/images/common/i_drop_down.png';
import i_drop_up from '../assets/images/common/i_drop_up.png';
import i_primary_check from '../assets/images/common/i_primary_check.png';
import i_mustmove_logo from '../assets/images/common/i_mustmove_logo.png';
import i_popup_exit from '../assets/images/common/i_popup_exit.png';
import i_alert from '../assets/images/common/i_alert.png';
import i_search from '../assets/images/common/i_search.png';
import i_scroll_top from '../assets/images/common/i_scroll_top.png';
import i_free_trial from '../assets/images/common/i_free_trial.png';
import i_signIn_rappo_logo from '../assets/images/login/i_signIn_rappo_logo.png';
import i_primary_speech_bubble from '../assets/images/common/i_primary_speech_bubble.png';
import i_yellow_speech_bubble from '../assets/images/common/i_yellow_speech_bubble.png';
import i_red_right_arrow from '../assets/images/common/i_red_right_arrow.png';
import i_rappo_plan from '../assets/images/common/i_rappo_plan.png';
import i_left_bracket from '../assets/images/common/i_left_bracket.png';
import i_right_bracket from '../assets/images/common/i_right_bracket.png';
import i_left_bracket_hover from '../assets/images/common/i_left_bracket_hover.png';
import i_right_bracket_hover from '../assets/images/common/i_right_bracket_hover.png';
import i_spinner from '../assets/images/common/i_spinner.gif';
import i_green_alert from '../assets/images/common/i_green_alert.png';
import i_recommend from '../assets/images/common/i_recommend.png';
import i_mobile_background from '../assets/images/common/i_mobile_background.png';
import i_mobile_logo from '../assets/images/common/i_mobile_logo.png';
import i_left_gray_triangle from '../assets/images/common/i_left_gray_triangle.png';
import i_right_gray_triangle from '../assets/images/common/i_right_gray_triangle.png';
import i_left_primary_two_triangle from '../assets/images/common/i_left_primary_two_triangle.png';
import i_left_gray_two_triangle from '../assets/images/common/i_left_gray_two_triangle.png';
import i_right_primary_two_triangle from '../assets/images/common/i_right_primary_two_triangle.png';
import i_right_gray_two_triangle from '../assets/images/common/i_right_gray_two_triangle.png';

// 사이드바
import i_main_page from '../assets/images/sidebar/i_main_page.png';
import i_sidebar_logo from '../assets/images/sidebar/i_sidebar_logo.png';
import i_sidebar_logo_text from '../assets/images/sidebar/i_sidebar_logo_text.png';
import i_sidebar_fold from '../assets/images/sidebar/i_sidebar_fold.png';
import i_sidebar_open from '../assets/images/sidebar/i_sidebar_open.png';
import i_primary_plus from '../assets/images/sidebar/i_primary_plus.png';
import i_empty_profile from '../assets/images/sidebar/i_empty_profile.png';
import i_ios_qr_code from '../assets/images/sidebar/ios_qr_code.png';
import i_android_qr_code from '../assets/images/sidebar/android_qr_code.png';
import i_ios_logo from '../assets/images/sidebar/i_apple_logo.png';
import i_android_logo from '../assets/images/sidebar/i_android_logo.png';
import i_clicked_main from '../assets/images/sidebar/i_clicked_main.png';
import i_clicked_schedule from '../assets/images/sidebar/i_clicked_schedule.png';
import i_clicked_trainerManage from '../assets/images/sidebar/i_clicked_trainerManage.png';
import i_clicked_communication from '../assets/images/sidebar/i_clicked_communication.png';
import i_clicked_feed from '../assets/images/sidebar/i_clicked_feed.png';
import i_unClicked_main from '../assets/images/sidebar/i_unClicked_main.png';
import i_unClicked_schedule from '../assets/images/sidebar/i_unClicked_schedule.png';
import i_unClicked_trainerManage from '../assets/images/sidebar/i_unClicked_trainerManage.png';
import i_unClicked_communication from '../assets/images/sidebar/i_unClicked_communication.png';
import i_unClicked_feed from '../assets/images/sidebar/i_unClicked_feed.png';

// 대시보드
import i_primary_left_triangle from '../assets/images/dashboard/i_primary_left_triangle.png';
import i_primary_right_triangle from '../assets/images/dashboard/i_primary_right_triangle.png';
import i_grey_circle from '../assets/images/dashboard/i_grey_circle.png';

// 마이페이지
import i_hover_img from '../assets/images/mypage/i_hover_img.png';
import i_accent from '../assets/images/mypage/i_accent.png';

// 달력 (칼렌더)
import i_right_primary_btn from '../assets/images/calendar/i_right_primary_btn.png';
import i_left_primary_btn from '../assets/images/calendar/i_left_primary_btn.png';

// 스케줄관리
import i_red_triangle from '../assets/images/schedule/i_red_triangle.png';
import i_blue_triangle from '../assets/images/schedule/i_blue_triangle.png';
import i_pink_triangle from '../assets/images/schedule/i_pink_triangle.png';
import i_primary_triangle from '../assets/images/schedule/i_primary_triangle.png';
import i_grey_triangle from '../assets/images/schedule/i_grey_triangle.png';
import i_speech_bubble from '../assets/images/schedule/i_speech_bubble.png';
import i_left_page_button from '../assets/images/schedule/i_left_page_button.png';
import i_right_page_button from '../assets/images/schedule/i_right_page_button.png';
import i_left_page_button_hover from '../assets/images/schedule/i_left_page_button_hover.png';
import i_right_page_button_hover from '../assets/images/schedule/i_right_page_button_hover.png';
import i_today_triangle from '../assets/images/schedule/i_today_triangle.png';
import i_going_etc_schedule from '../assets/images/schedule/i_going_etc_schedule.png';
import i_blue_clock from '../assets/images/schedule/i_blue_clock.png';
import i_blue_profile from '../assets/images/schedule/i_blue_profile.png';
import i_blue_schedule from '../assets/images/schedule/i_blue_schedule.png';
import i_left_angle_bracket_default from '../assets/images/schedule/i_left_angle_bracket_default.png';
import i_left_angle_bracket_hover from '../assets/images/schedule/i_left_angle_bracket_hover.png';
import i_left_angle_bracket_click from '../assets/images/schedule/i_left_angle_bracket_click.png';
import i_right_angle_bracket_default from '../assets/images/schedule/i_right_angle_bracket_default.png';
import i_right_angle_bracket_hover from '../assets/images/schedule/i_right_angle_bracket_hover.png';
import i_right_angle_bracket_click from '../assets/images/schedule/i_right_angle_bracket_click.png';

// 피드
import i_like_icon from '../assets/images/feed/i_like_icon.png';
import i_comment_icon from '../assets/images/feed/i_comment_icon.png';
import i_album from '../assets/images/feed/i_album.png';
import i_empty from '../assets/images/feed/i_empty.png';
import i_video_play from '../assets/images/feed/i_video_play.png';
import i_very_good from '../assets/images/feed/i_very_good.png';
import i_good from '../assets/images/feed/i_good.png';
import i_soso from '../assets/images/feed/i_soso.png';
import i_depressed from '../assets/images/feed/i_depressed.png';
import i_terrible from '../assets/images/feed/i_terrible.png';
import i_empty_condition from '../assets/images/feed/i_empty_condition.png';
import i_bookMark from '../assets/images/feed/i_bookMark.png';
import i_downLoad from '../assets/images/feed/i_downLoad.png';
import i_clip from '../assets/images/feed/i_clip.png';

//// V2
import i_1440_logo from '../assets/images/v2/i_1440_logo.png';
import i_close from '../assets/images/v2/i_close.png';
import i_error from '../assets/images/v2/i_error.png';
import i_unlock_masking from '../assets/images/v2/i_unlock_masking.png';
import i_lock_masking from '../assets/images/v2/i_lock_masking.png';
import i_green_fill_alert from '../assets/images/v2/i_green_fill_alert.png';
import i_dropDown_default from '../assets/images/v2/i_dropDown_default.png';
import i_dropDown_hover from '../assets/images/v2/i_dropDown_hover.png';
import i_reset from '../assets/images/v2/i_reset.png';
import i_left_angle from '../assets/images/v2/i_left_angle.png';
import i_right_angle from '../assets/images/v2/i_right_angle.png';
import i_plus from '../assets/images/v2/i_plus.png';
import i_plus_register from '../assets/images/v2/i_plus_register.png';
import i_down_triangle from '../assets/images/v2/i_down_triangle.png';
import i_left_black_angle from '../assets/images/v2/i_left_black_angle.png';
import i_plan_logo from '../assets/images/v2/i_plan_logo.png';
import i_plan_price from '../assets/images/v2/i_plan_price.png';
import i_fill_alert from '../assets/images/v2/i_fill_alert.png';
import i_bottom_primary_triangle from '../assets/images/v2/i_bottom_primary_triangle.png';
import i_edit from '../assets/images/v2/i_edit.png';
import i_trash from '../assets/images/v2/i_trash.png';
import i_left_two_angle from '../assets/images/v2/i_left_two_angle.png';
import i_right_two_angle from '../assets/images/v2/i_right_two_angle.png';
import i_floating from '../assets/images/v2/i_floating.png';
import i_floating_hover from '../assets/images/v2/i_floating_hover.png';
import i_floating_onclick from '../assets/images/v2/i_floating_onclick.png';
import i_white_check from '../assets/images/v2/i_white_check.png';
import i_circle_exit from '../assets/images/v2/i_circle_exit.png';
import i_connect from '../assets/images/v2/i_connect.png';
import i_disConnect from '../assets/images/v2/i_disConnect.png';
import i_pending_connect from '../assets/images/v2/i_peding_connect.png';
import i_top from '../assets/images/v2/i_top.png';
import i_bottom from '../assets/images/v2/i_bottom.png';
import i_top_primary from '../assets/images/v2/i_top_primary.png';
import i_bottom_primary from '../assets/images/v2/i_bottom_primary.png';
import i_gradient_schedule from '../assets/images/v2/i_grident_schedule.png';
import i_grey_trash from '../assets/images/v2/i_grey_trash.png';
import i_gnb_logo_default from '../assets/images/sidebar/i_gnb_logo_default.png';
import i_gnb_logo_hover from '../assets/images/sidebar/i_gnb_logo_hover.png';
import i_gnb_logo_click from '../assets/images/sidebar/i_gnb_logo_click.png';
import i_update_arrow from '../assets/images/v2/i_update_arrow.png';
import i_circle_question from '../assets/images/v2/i_circle_question.png';
import i_new_update_popup_1_1 from '../assets/images/v2/i_new_update_popup_1_1.png';
import i_new_update_popup_1_2 from '../assets/images/v2/i_new_update_popup_1_2.png';
import i_new_update_popup_2_1 from '../assets/images/v2/i_new_update_popup_2_1.png';
import i_new_update_popup_2_2 from '../assets/images/v2/i_new_update_popup_2_2.png';
import i_new_update_popup_3_1 from '../assets/images/v2/i_new_update_popup_3_1.png';
import i_new_update_popup_3_2 from '../assets/images/v2/i_new_update_popup_3_2.png';
import i_right_gray_angle from '../assets/images/v2/i_right_gray_angle.png';
import i_schedule_circleX from '../assets/images/v2/i_schedule_circleX.png';
import i_empty_v2 from '../assets/images/v2/i_empty_v2.png';

// 공통
export const I_RAPPO_LOGO = i_rappo_logo;
export const I_WHITE_LOGO = i_white_logo;
export const I_SIGN_BACKGROUND = i_sign_background;
export const I_BACK = i_back;
export const I_DROP_DOWN = i_drop_down;
export const I_DROP_UP = i_drop_up;
export const I_PRIMARY_CHECK = i_primary_check;
export const I_MUSTMOVE_LOGO = i_mustmove_logo;
export const I_POPUP_EXIT = i_popup_exit;
export const I_ALERT = i_alert;
export const I_SEARCH = i_search;
export const I_SCROLL_TOP = i_scroll_top;
export const I_FREE_TRIAL = i_free_trial;
export const I_SIGNIN_RAPPO_LOGO = i_signIn_rappo_logo;
export const I_PRIMARY_SPEECH_BUBBLE = i_primary_speech_bubble;
export const I_YELLOW_SPEECH_BUBBLE = i_yellow_speech_bubble;
export const I_RED_RIGHT_ARROW = i_red_right_arrow;
export const I_RAPPO_PLAN = i_rappo_plan;
export const I_LEFT_BRACKET = i_left_bracket;
export const I_RIGHT_BRACKET = i_right_bracket;
export const I_LEFT_BRACKET_HOVER = i_left_bracket_hover;
export const I_RIGHT_BRACKET_HOVER = i_right_bracket_hover;
export const I_SPINNER = i_spinner;
export const I_GREEN_ALERT = i_green_alert;
export const I_RECOMMEND = i_recommend;
export const I_MOBILE_BACKGROUND = i_mobile_background;
export const I_MOBILE_LOGO = i_mobile_logo;
export const I_LEFT_GRAY_TRIANGLE = i_left_gray_triangle;
export const I_RIGHT_GRAY_TRIANGLE = i_right_gray_triangle;
export const I_LEFT_PRIMARY_TWO_TRIANGLE = i_left_primary_two_triangle;
export const I_LEFT_GRAY_TWO_TRIANGLE = i_left_gray_two_triangle;
export const I_RIGHT_PRIMARY_TWO_TRIANGLE = i_right_primary_two_triangle;
export const I_RIGHT_GRAY_TWO_TRIANGLE = i_right_gray_two_triangle;

// 사이드바
export const I_MAIN_PAGE = i_main_page;
export const I_SIDEBAR_LOGO = i_sidebar_logo;
export const I_SIDEBAR_LOGO_TEXT = i_sidebar_logo_text;
export const I_SIDEBAR_FOLD = i_sidebar_fold;
export const I_SIDEBAR_OPEN = i_sidebar_open;
export const I_PRIMARY_PLUS = i_primary_plus;
export const I_EMPTY_PROFILE = i_empty_profile;
export const I_IOS_QR_CODE = i_ios_qr_code;
export const I_ANDROID_QR_CODE = i_android_qr_code;
export const I_IOS_LOGO = i_ios_logo;
export const I_ANDROID_LOGO = i_android_logo;
export const I_CLICKED_MAIN = i_clicked_main;
export const I_CLICKED_SCHEDULE = i_clicked_schedule;
export const I_CLICKED_TRAINERMANAGE = i_clicked_trainerManage;
export const I_CLICKED_COMMUNICATION = i_clicked_communication;
export const I_CLICKED_FEED = i_clicked_feed;
export const I_UNCLICKED_MAIN = i_unClicked_main;
export const I_UNCLICKED_SCHEDULE = i_unClicked_schedule;
export const I_UNCLICKED_TRAINERMANAGE = i_unClicked_trainerManage;
export const I_UNCLICKED_COMMUNICATION = i_unClicked_communication;
export const I_UNCLICKED_FEED = i_unClicked_feed;

// 마이페이지
export const I_HOVER_IMG = i_hover_img;
export const I_ACCENT = i_accent;

// 대시보드
export const I_PRIMARY_LEFT_TRIANGLE = i_primary_left_triangle;
export const I_PRIMARY_RIGHT_TRIANGLE = i_primary_right_triangle;
export const I_GREY_CIRCLE = i_grey_circle;

// 달력 (칼렌더)
export const I_RIGHT_PRIMARY_BTN = i_right_primary_btn;
export const I_LEFT_PRIMARY_BTN = i_left_primary_btn;

// 스케줄관리
export const I_RED_TRIANGLE = i_red_triangle;
export const I_BLUE_TRIANGLE = i_blue_triangle;
export const I_PINK_TRIANGLE = i_pink_triangle;
export const I_PRIMARY_TRIANGLE = i_primary_triangle;
export const I_GREY_TRIANGLE = i_grey_triangle;
export const I_SPEECH_BUBBLE = i_speech_bubble;
export const I_LEFT_PAGE_BUTTON = i_left_page_button;
export const I_LEFT_PAGE_BUTTON_HOVER = i_left_page_button_hover;
export const I_RIGHT_PAGE_BUTTON = i_right_page_button;
export const I_RIGHT_PAGE_BUTTON_HOVER = i_right_page_button_hover;
export const I_TODAY_TRIANGLE = i_today_triangle;
export const I_GOING_ETC_SCHEDULE = i_going_etc_schedule;
export const I_BLUE_CLOCK = i_blue_clock;
export const I_BLUE_PROFILE = i_blue_profile;
export const I_BLUE_SCHEDULE = i_blue_schedule;

// 피드
export const I_LIKE_ICON = i_like_icon;
export const I_COMMENT_ICON = i_comment_icon;
export const I_ALBUM = i_album;
export const I_EMPTY = i_empty;
export const I_VIDEO_PLAY = i_video_play;
export const I_VERY_GOOD = i_very_good;
export const I_GOOD = i_good;
export const I_SOSO = i_soso;
export const I_DEPRESSED = i_depressed;
export const I_TERRIBLE = i_terrible;
export const I_EMPTY_CONDITION = i_empty_condition;
export const I_BOOKMARK = i_bookMark;
export const I_DOWNLOAD = i_downLoad;
export const I_CLIP = i_clip;

//// V2
export const I_1440_LOGO = i_1440_logo;
export const I_CLOSE = i_close;
export const I_ERROR = i_error;
export const I_UNLOCK_MASKING = i_unlock_masking;
export const I_LOCK_MASKING = i_lock_masking;
export const I_GREEN_FILL_ALERT = i_green_fill_alert;
export const I_DROPDOWN_DEFAULT = i_dropDown_default;
export const I_DROPDOWN_HOVER = i_dropDown_hover;
export const I_RESET = i_reset;
export const I_LEFT_ANGLE = i_left_angle;
export const I_RIGHT_ANGLE = i_right_angle;
export const I_PLUS = i_plus;
export const I_PLUS_REGISTER = i_plus_register;
export const I_DOWN_TRIANGLE = i_down_triangle;
export const I_LEFT_BLACK_ANGLE = i_left_black_angle;
export const I_PLAN_LOGO = i_plan_logo;
export const I_PLAN_PRICE = i_plan_price;
export const I_FILL_ALERT = i_fill_alert;
export const I_BOTTOM_PRIMARY_TRIANGLE = i_bottom_primary_triangle;
export const I_EDIT = i_edit;
export const I_TRASH = i_trash;
export const I_LEFT_TWO_ANGLE = i_left_two_angle;
export const I_RIGHT_TWO_ANGLE = i_right_two_angle;
export const I_FLOATING = i_floating;
export const I_FLOATING_HOVER = i_floating_hover;
export const I_FLOATING_ONCLICK = i_floating_onclick;
export const I_WHITE_CHECK = i_white_check;
export const I_CIRCLE_EXIT = i_circle_exit;
export const I_CONNECT = i_connect;
export const I_DISCONNECT = i_disConnect;
export const I_PENDING_CONNECT = i_pending_connect;
export const I_TOP = i_top;
export const I_BOTTOM = i_bottom;
export const I_TOP_PRIMARY = i_top_primary;
export const I_BOTTOM_PRIMARY = i_bottom_primary;
export const I_GRADIENT_SCHEDULE = i_gradient_schedule;
export const I_GREY_TRASH = i_grey_trash;
export const I_GNB_LOGO_DEFAULT = i_gnb_logo_default;
export const I_GNB_LOGO_HOVER = i_gnb_logo_hover;
export const I_GNB_LOGO_CLICK = i_gnb_logo_click;

export const I_LEFT_ANGLE_BRACKET_DEFAULT = i_left_angle_bracket_default;
export const I_LEFT_ANGLE_BRACKET_HOVER = i_left_angle_bracket_hover;
export const I_LEFT_ANGLE_BRACKET_CLICK = i_left_angle_bracket_click;
export const I_RIGHT_ANGLE_BRACKET_DEFAULT = i_right_angle_bracket_default;
export const I_RIGHT_ANGLE_BRACKET_HOVER = i_right_angle_bracket_hover;
export const I_RIGHT_ANGLE_BRACKET_CLICK = i_right_angle_bracket_click;

export const I_UPDATE_ARROW = i_update_arrow;
export const I_CIRCLE_QUESTION = i_circle_question;

export const I_NEW_UPDATE_POPUP_1_1 = i_new_update_popup_1_1;
export const I_NEW_UPDATE_POPUP_1_2 = i_new_update_popup_1_2;
export const I_NEW_UPDATE_POPUP_2_1 = i_new_update_popup_2_1;
export const I_NEW_UPDATE_POPUP_2_2 = i_new_update_popup_2_2;
export const I_NEW_UPDATE_POPUP_3_1 = i_new_update_popup_3_1;
export const I_NEW_UPDATE_POPUP_3_2 = i_new_update_popup_3_2;

export const I_RIGHT_GRAY_ANGLE = i_right_gray_angle;
export const I_SCHEDULE_CIRCLEX = i_schedule_circleX;
export const I_EMPTY_V2 = i_empty_v2;
