import React, { useEffect, useRef, useState } from 'react';
import { CodeValueType } from '../../types/types';
import TextSelector, { FontsType } from '../TextSelector';
import SvgIcon from '../SvgIcon';
import { colors } from '../../lib/colors';
import { Body3Regular } from '../../lib/font';
import { Col } from '../../lib/utils';

interface I_Props<T extends string> {
    selectData?: CodeValueType<T>;
    datas: CodeValueType<T>[];
    onClickSort: (item: CodeValueType<T>) => void;
    mr?: number;
    width?: number;
    height?: number;
    fontType?: FontsType;
    mb?: number;
    placeholder?: string;
}

const NewTextInputDropDown = <T extends string>({
    selectData,
    datas,
    onClickSort,
    mr = 0,
    width = 180,
    height = 36,
    fontType = 'CaptionRegular',
    mb = 0,
    placeholder,
}: I_Props<T>) => {
    const [isOpenSort, setIsOpenSort] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpenSort(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div
            className={`relative inline-block cursor-pointer mr-${mr}`}
            ref={dropdownRef}
            style={{
                marginBottom: mb ?? 0,
            }}
        >
            {/* 바깥 버튼 */}
            <button
                className={`flex items-center bg-white text-LAVEL_3 cursor-pointer border-b border-solid border-BLUE_500`}
                style={{ width, height }}
                onClick={() => setIsOpenSort(!isOpenSort)}
            >
                <div className="flex items-center pl-4" style={{ width: width - 32, height }}>
                    <TextSelector
                        text={selectData?.value ? selectData?.value : placeholder ?? ''}
                        fontType={fontType}
                        color={selectData?.value ? 'LAVEL_3' : 'GREY_200'}
                    />
                </div>
                <div className="flex items-center pr-4">
                    <SvgIcon
                        name="SvgDownStrokeArrow"
                        size={14}
                        fill={colors.trans}
                        stroke={colors.BLUE_300}
                        strokeWidth={1.2}
                        style={{
                            transform: isOpenSort ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.2s ease-in-out',
                        }}
                    />
                </div>
            </button>
            <div
                className={`absolute left-0 bg-LAVEL_0 shadow-md rounded-b-lg overflow-hidden transition-transform ${
                    isOpenSort ? 'block animate-slide-down' : 'hidden animate-slide-up'
                }`}
                style={{
                    width,
                    top: height + 4,
                    maxHeight: '200px', // Set the maximum height of the dropdown
                    overflowY: 'auto', // Enable vertical scrolling
                }}
            >
                {datas.map((data: any) => (
                    <Col
                        key={data.value}
                        className="flex items-start justify-center bg-WHITE_50 px-[16px] py-[12px] cursor-pointer hover:bg-WHITE_100 my-[4px]"
                        style={{ width, height }}
                        onClick={() => {
                            setIsOpenSort(false);
                            onClickSort(data);
                        }}
                    >
                        <Body3Regular className=" text-LAVEL_3">{data.value}</Body3Regular>
                    </Col>
                ))}
            </div>
        </div>
    );
};

export default NewTextInputDropDown;
