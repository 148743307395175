import React from 'react';
import { Row } from '../../../lib/utils';
import NewBackButton from '../../../components/Button/NewBackButton';
import NewColorRoundButton from '../../../components/Button/NewColorRoundButton';

type Props = {
    onClickModifyPass: () => void;
    onClickBack: () => void;
};
const ClassPassDetailHeader = ({ onClickModifyPass, onClickBack }: Props) => {
    return (
        <Row
            style={{
                width: 1192,
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 32,
                paddingLeft: 8,
                paddingRight: 8,
            }}
        >
            <NewBackButton onBackButtonClick={onClickBack} />

            <NewColorRoundButton
                text="수강권 수정하기"
                onClick={onClickModifyPass}
                bgColor="primary"
                textColor="white"
                width={160}
                height={36}
            />
        </Row>
    );
};

export default ClassPassDetailHeader;
