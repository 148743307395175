import React, { useEffect, useState } from 'react';
import { Col, Row } from '../../lib/utils';
import { Body3Regular } from '../../lib/font';

type Props = {
    isActive: boolean;
    callBack: () => void;
    disableColor?: string;
    isText?: boolean;
};

const NormalToggleButton = ({ isActive, callBack, disableColor, isText = false }: Props) => {
    const [isToggled, setIsToggled] = useState(isActive);

    useEffect(() => {
        setIsToggled(isActive);
    }, [isActive]);

    const offClass = 'text-GREY_200';

    return (
        <Row className="flex justify-center items-center">
            {isText && <Body3Regular className={`mr-[12px] ${!isActive && offClass}`}>ON</Body3Regular>}
            <Row
                className={`w-[64px] h-[32px] rounded-full flex items-center p-0.5 cursor-pointer transition-colors duration-300 shadow-inner ${
                    isToggled ? 'bg-[#00A793]' : disableColor ? '' : 'bg-[#C7C7C7]'
                }`}
                style={{ backgroundColor: !isToggled && disableColor ? disableColor : '' }}
                onClick={callBack}
            >
                <Col
                    className={`w-[23px] h-[23px] bg-white rounded-full transition-transform duration-300 ${
                        isToggled ? 'transform translate-x-[4px]' : 'transform translate-x-[34px]'
                    }`}
                />
            </Row>
            {isText && <Body3Regular className={`ml-[12px] ${isActive && offClass} `}>OFF</Body3Regular>}
        </Row>
    );
};

export default NormalToggleButton;
