import React, { Dispatch, SetStateAction, useState } from 'react';
import Modal from '../modal/Modal';
import { Col, Row } from '../../lib/utils';
import { Subtitle1Bold } from '../../lib/font';
import BottomBorderTextInput from '../TextInput/BottomBorderTextInput';
import NewWhiteSquareButton from '../Button/NewWhiteSquareButton';
import NewColorSquareButton from '../Button/NewColorSquareButton';

type Props = {
    onUpdateComplete: (value: string) => void;
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    value: string;
};

const ModalUpdateMembershipName = ({ onUpdateComplete, setIsVisible, value: _value }: Props) => {
    const [value, setValue] = useState(_value);
    return (
        <Modal whiteViewWidth={596} whiteViewHeight={284}>
            <Col className="flex justify-center items-center px-[40px] py-[36px]">
                <Subtitle1Bold className="mb-[40px]">수강권 이름 수정</Subtitle1Bold>
                <BottomBorderTextInput
                    value={value}
                    onChange={(e) => {
                        // onChange(e.target.value);
                        setValue(e.target.value);
                    }}
                    width={516}
                    height={48}
                    mb={40}
                />
                <Row>
                    <NewWhiteSquareButton
                        text={'취소'}
                        onClick={() => setIsVisible(false)}
                        width={148}
                        height={48}
                        mr={16}
                    />
                    <NewColorSquareButton
                        text={'수정 완료'}
                        onClick={() => onUpdateComplete(value)}
                        width={148}
                        height={48}
                    />
                </Row>
            </Col>
        </Modal>
    );
};

export default ModalUpdateMembershipName;
