import { colors } from '../../lib/colors';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
dayjs.locale('ko');

const GridShadow = ({ currentDate, selectedDay, sidebarStatus }: any) => {
    return dayjs(selectedDay).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') ? (
        <div>
            <hr
                style={{
                    width: sidebarStatus === 'open' ? 1220 : 1494,

                    top:
                        parseInt(dayjs(currentDate).format('H')) * 120 +
                        parseInt(dayjs(currentDate).format('m')) * 2 -
                        0.6,
                    left: 140,
                    border: '2px solid #2B529D',
                    position: 'absolute',
                }}
            />

            <div
                style={{
                    width: 8,
                    height: 8,
                    borderRadius: 4,
                    backgroundColor: colors.BLUE_700,
                    position: 'absolute',
                    top:
                        parseInt(dayjs(currentDate).format('H')) * 120 +
                        parseInt(dayjs(currentDate).format('m')) * 2 -
                        3,
                    left: 136,
                }}
            />
        </div>
    ) : null;
};

export default GridShadow;
