import React, { ChangeEvent, useEffect } from 'react';
import UpgradeSubscriptionPlanTop from './UpgradeSubscriptionPlanTop';
import UpgradeSubscriptionPlanBottom from './UpgradeSubscriptionPlanBottom';
import { Col, Row } from '../../lib/utils';
import { Body3Regular } from '../../lib/font';
import { colors } from '../../lib/colors';
import { I_LEFT_BLACK_ANGLE, I_PLAN_LOGO, I_RECOMMEND } from '../../types/images';

const UpgradeSubscriptionPlan = ({
    onClickPayButton,
    createAlert,
    buttonType,
    setButtonType,
    recommendPosition,
    smallCenterTrainerNumber,
    setSmallCenterTrainerNumber,
    bigCenterTrainerNumber,
    setBigCenterTrainerNumber,
    authObject,
    coachCount,
    isCoachCountAlert,
    navigate,
}: any) => {
    const onChangeTrainerNumber = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = parseInt(e.target.value);
        if (buttonType === 'small') {
            if (inputValue >= 1 && inputValue <= 3) {
                setSmallCenterTrainerNumber(inputValue);
            } else {
                setSmallCenterTrainerNumber(3);
            }
        } else {
            setBigCenterTrainerNumber(inputValue);
        }
    };

    useEffect(() => {
        // if (bigCenterTrainerNumber < 4) {
        //     setBigCenterTrainerNumber(4);
        // }

        if (bigCenterTrainerNumber > 20) {
            setBigCenterTrainerNumber(20);
            createAlert('', '20명을 초과하는 요금제는 고객센터로 문의바랍니다.');
        }
    }, [bigCenterTrainerNumber]);

    return (
        <div style={{ width: 1192 }}>
            <Row onClick={() => navigate('/myPage', { replace: true })} style={{ marginTop: 32, cursor: 'pointer' }}>
                <img src={I_LEFT_BLACK_ANGLE} style={{ width: 24, height: 24, marginRight: 4 }} />
                <Body3Regular>뒤로가기</Body3Regular>
            </Row>

            <img src={I_PLAN_LOGO} style={{ width: 1192, height: 236, marginTop: 12 }} />
            <Col
                style={{
                    width: 1192,
                    height: 776,
                    borderRadius: 16,
                    backgroundColor: colors.WHITE_50,

                    border: '1px solid #C3D4F4',
                    marginTop: 24,
                }}
            >
                <UpgradeSubscriptionPlanTop
                    authObject={authObject}
                    buttonType={buttonType}
                    setButtonType={setButtonType}
                    smallCenterTrainerNumber={smallCenterTrainerNumber}
                    bigCenterTrainerNumber={bigCenterTrainerNumber}
                    isCoachCountAlert={isCoachCountAlert}
                    onChangeTrainerNumber={onChangeTrainerNumber}
                />
                <UpgradeSubscriptionPlanBottom
                    buttonType={buttonType}
                    setButtonType={setButtonType}
                    smallCenterTrainerNumber={smallCenterTrainerNumber}
                    bigCenterTrainerNumber={bigCenterTrainerNumber}
                    onChangeTrainerNumber={onChangeTrainerNumber}
                    onClickPayButton={onClickPayButton}
                    authObject={authObject}
                    coachCount={coachCount}
                    isCoachCountAlert={isCoachCountAlert}
                />

                {/* <img
                    src={I_RECOMMEND}
                    style={{
                        width: 100,
                        height: 60,
                        position: 'absolute',
                        marginTop: 200,
                        marginLeft: recommendPosition === 'left' ? 10 : 1240,
                    }}
                /> */}
            </Col>
        </div>
    );
};
export default UpgradeSubscriptionPlan;
