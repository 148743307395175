import { ResponsiveBar } from '@nivo/bar';
import React from 'react';
import { colors } from '../../../lib/colors';
import ActiveMemberEmptyGraph from './ActiveMemberEmptyGraph';
import dayjs from 'dayjs';

const ActiveMembersDailyGraph = ({ dailyData, todayMaxCount, CustomToolTip, CustomTextLayer }: any) => {
    const CustomTodayTick = (tick: any) => {
        const { x, y, value } = tick;
        let dailyFilterData = null;

        dailyFilterData = dailyData.find((e: any) => e.xLabel === value);

        return (
            <g transform={`translate(${x},${y})`} style={{ textAnchor: 'middle' }}>
                {dailyFilterData && dailyFilterData.isToday && (
                    <rect x={-12} y={56} fill={colors.primary} width={24} height={24} rx={12} />
                )}
                <text
                    x={0}
                    y={43}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{
                        fontSize: 28,
                        fontWeight: 700,
                        fill:
                            dailyFilterData && dailyFilterData.isToday
                                ? colors.LAVEL_4
                                : dayjs(dailyFilterData.startDate).isAfter(dayjs())
                                ? colors.GREY_100
                                : colors.LAVEL_2,
                        outlineWidth: 0,
                    }}
                >
                    {dailyFilterData && dailyFilterData.xSubLabel.replace('일', '')}
                </text>
                <text
                    x={0}
                    y={70}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{
                        fontSize: 12,
                        fill: dailyFilterData.isToday
                            ? colors.WHITE_50
                            : dayjs(dailyFilterData.startDate).isAfter(dayjs())
                            ? colors.GREY_100
                            : colors.LAVEL_2,
                        outlineWidth: 0,
                        fontWeight: 400,
                    }}
                >
                    {value}
                </text>
            </g>
        );
    };
    return (
        <>
            {dailyData && dailyData.length === 0 ? (
                <ActiveMemberEmptyGraph />
            ) : (
                <ResponsiveBar
                    data={dailyData} // data
                    layers={[
                        'grid',
                        'axes',
                        'bars',
                        'markers',
                        'legends',
                        CustomTextLayer, // 커스텀 텍스트 레이어 추가
                    ]}
                    keys={['activeMemberCount', 'interval']}
                    indexBy="xLabel"
                    margin={{ right: 70, top: 15, bottom: 100, left: 90 }}
                    axisLeft={{
                        tickSize: 0,
                    }}
                    axisBottom={{
                        tickSize: 0,
                        tickPadding: 5,
                        tickRotation: 0,
                        renderTick: CustomTodayTick,
                    }} // 바텀 라벨의 스타일
                    isInteractive={true} // 호버햇을때 말풍선
                    tooltip={CustomToolTip}
                    padding={0.6}
                    minValue={0}
                    maxValue={todayMaxCount < 10 ? 10 : todayMaxCount}
                    enableLabel={false}
                    colors={['#3D73DD', '#D4DBE8']} // 커스터하여 사용할 때
                    colorBy="id" // 색상을 keys 요소들에 각각 적용
                    labelSkipWidth={36}
                    labelSkipHeight={12}
                    motionConfig={'gentle'}
                    layout="vertical"
                />
            )}
        </>
    );
};

export default ActiveMembersDailyGraph;
