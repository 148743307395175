import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Modal from '../../components/modal/Modal';
import { Col, Row } from '../../lib/utils';
import { Body3Regular, Subtitle1Bold } from '../../lib/font';
import FormTextInput from '../../components/InputBox /FormTextInput';
import { EmployeeProfile } from '../../services/useEmployeeQueryService';
import { CodeValueType } from '../../types/types';
import { DATE_REGEXP, EMAIL_REGEXP, GENDER_TYPE, GENDERCODETYPE, PHONE_NUMBER_REGEXP } from '../../lib/varialbe';
import NewTextInputDropDown from '../../components/DropDown/NewTextInputDropDown';
import NewColorSquareButton from '../../components/Button/NewColorSquareButton';
import useEmployeeMutationService from '../../services/useEmployeeMutationService';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    trainerProfile: EmployeeProfile;
    employeeId: string;
    createAlert: any;
};

const ModalTrainerProfileChange = ({ setIsVisible, trainerProfile, employeeId, createAlert }: Props) => {
    const queryClient = useQueryClient();

    const { modifyTrainerProfileAPIMutation } = useEmployeeMutationService({
        // 트레이너 프로필 수정 성공시
        //getEmployeeInfoAtManageAPIQuery
        onModifyTrainerProfileSuccess(data) {
            setIsVisible(false);
            queryClient.invalidateQueries({ queryKey: ['getEmployeeInfoAtManageAPIQuery'] });
            createAlert('프로필 수정 완료', '프로필 수정을 완료하였습니다');
        },
    });
    // 강사이름
    const [trainerName, setTrainerName] = useState(trainerProfile?.name);
    // 휴대폰번호
    const [phoneNumber, setPhoneNumber] = useState(trainerProfile?.phoneNumber);
    // 생년월일
    const [birthDay, setBirthDay] = useState(trainerProfile?.birthday);
    // 성별
    const [gender, setGender] = useState<CodeValueType<GENDERCODETYPE>>(
        trainerProfile?.gender ? (trainerProfile.gender === 'male' ? GENDER_TYPE[0] : GENDER_TYPE[1]) : GENDER_TYPE[2]
    );
    // 입사일
    const [startDate, setStartDate] = useState(trainerProfile?.startDate);
    // 직급
    const [jobPosition, setJobPosition] = useState(trainerProfile?.jobPosition);
    // 이메일
    const [email, setEmail] = useState(trainerProfile?.email);

    // 휴대전화 번호 '-'추가 정제로직
    useEffect(() => {
        if (phoneNumber) {
            if (phoneNumber.length === 10) {
                setPhoneNumber(phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
            }
            if (phoneNumber.length === 13) {
                setPhoneNumber(phoneNumber.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
            }
        }
    }, [phoneNumber]);

    // // 생년월일 '-'추가 정제로직
    useEffect(() => {
        if (birthDay) {
            if (birthDay.length === 8) {
                setBirthDay(birthDay.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'));
            }

            if (birthDay.length === 9) {
                setBirthDay(birthDay.replace(/-/g, ''));
            }
        }
    }, [birthDay]);

    // 입사일 '-'추가 정제로직
    useEffect(() => {
        if (startDate) {
            if (startDate.length === 8) {
                setStartDate(startDate.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'));
            }
            if (startDate.length === 9) {
                setStartDate(startDate.replace(/-/g, ''));
            }
        }
    }, [startDate]);

    // 편집 완료
    const onModifyTrainerProfile = () => {
        // 휴대폰번호를 적었을때 '-' 정규식 검사
        if (phoneNumber && !PHONE_NUMBER_REGEXP.test(phoneNumber)) {
            // createAlert('삭제 완료', '해당 트레이너 삭제를 완료하였습니다');
            createAlert('휴대폰번호를 정확히 입력해주세요', '휴대폰번호를 정확히 입력해주세요', true);

            return;
        }
        // 생년월일 '-' 정규식 검사
        if (birthDay && !DATE_REGEXP.test(birthDay)) {
            createAlert('생년월일을 정확히 입력해주세요', '생년월일을 정확히 입력해주세요', true);
            return;
        }
        // 입사일 '-' 정규식 검사
        if (startDate && !DATE_REGEXP.test(startDate)) {
            createAlert('입사일을 정확히 입력해주세요', '입사일을 정확히 입력해주세요', true);
            return;
        }
        // 이메일을 적었을때 정규식 검사
        if (email && !EMAIL_REGEXP.test(email)) {
            createAlert('이메일을 정확히 입력해주세요', '이메일을 정확히 입력해주세요', true);
            return;
        }

        modifyTrainerProfileAPIMutation.mutate({
            employeeId: employeeId,
            birthday: birthDay,
            gender: gender.code,
            email: email,
            phoneNumber: phoneNumber,
            startDate: startDate,
            jobPosition: jobPosition,
        });
    };

    return (
        <Modal
            setIsVisible={setIsVisible}
            whiteViewWidth={1080}
            whiteViewHeight={700}
            isCloseButton
            onClose={() => setIsVisible(false)}
            isCloseOutside={false}
        >
            <Col className="py-[36px] px-[48px]">
                <Subtitle1Bold className="mb-[48px]">프로필 편집하기</Subtitle1Bold>
                <Col className="pl-[32px]">
                    {/* 회원이름 ,휴대폰번호 */}
                    <Row className="mb-[48px]">
                        <FormTextInput
                            title="강사 이름"
                            placeholder="강사이름을 입력해주세요"
                            value={trainerName}
                            onChangeText={setTrainerName}
                            isRequired
                            width={300}
                            mr={120}
                            disabled
                        />
                        <FormTextInput
                            title="휴대폰번호"
                            placeholder="010-1234-5678"
                            value={phoneNumber}
                            onChangeText={setPhoneNumber}
                            width={300}
                            subTitle={"'-' 포함한 13글자를 입력해주세요!"}
                            subtitlePosition="right"
                            maxLength={13}
                        />
                    </Row>
                    {/* 생년월일 , 성별 */}
                    <Row className="mb-[48px]">
                        <FormTextInput
                            title="생년월일"
                            placeholder="1997-02-19"
                            value={birthDay}
                            onChangeText={setBirthDay}
                            width={300}
                            mr={120}
                            subTitle="'-' 포함한 숫자 8글자로 써주세요!"
                            subtitlePosition="right"
                            maxLength={10}
                        />
                        <Col>
                            <Body3Regular className=" text-LAVEL_4 mr-[4px] mb-[12px]">{'성별'}</Body3Regular>
                            <NewTextInputDropDown
                                datas={GENDER_TYPE}
                                selectData={gender}
                                onClickSort={(item) => {
                                    setGender(item);
                                }}
                                fontType="Body2Regular"
                                width={300}
                            />
                        </Col>
                    </Row>
                    {/* 입사일 , 직급 */}
                    <Row className="mb-[48px]">
                        <FormTextInput
                            title="입사일"
                            placeholder="2022-01-01"
                            value={startDate}
                            onChangeText={setStartDate}
                            width={300}
                            mr={120}
                            subTitle="'-' 포함한 숫자 8글자로 써주세요!"
                            subtitlePosition="right"
                            maxLength={10}
                        />
                        <FormTextInput
                            title="직급"
                            placeholder="직급을 작성해주세요!"
                            value={jobPosition}
                            onChangeText={setJobPosition}
                            width={300}
                            mr={120}
                        />
                    </Row>
                    {/* 주소 */}
                    <FormTextInput
                        title="주소"
                        placeholder="주소를 입력해주세요"
                        value={email}
                        onChangeText={setEmail}
                        width={720}
                        mb={68}
                    />
                </Col>
            </Col>
            <NewColorSquareButton
                text="편집 완료"
                className=" absolute right-[36px] bottom-[36px] "
                width={148}
                height={48}
                onClick={onModifyTrainerProfile}
            />
        </Modal>
    );
};

export default ModalTrainerProfileChange;
