import { useEffect, useState } from 'react';
import { Col, Row, getCurrentDayWeekNumber } from '../../../lib/utils';
import { Body1Bold, Body2Bold, Body2Regular, Body3Regular } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import { I_LEFT_ANGLE, I_RIGHT_ANGLE } from '../../../types/images';
import { useQuery } from '@tanstack/react-query';
import { getClassProgressAPI } from '../../../api/dashboard';
import dayjs from 'dayjs';
import ClassProgressTodayGraph from './ClassProgressTodayGraph';
import ClassProgressWeekGraph from './ClassProgressWeekGraph';
import ClassProgressMonthGraph from './ClassProgressMonthGraph';
import { dashBoardClassProgressDummyData } from '../../../lib/dummyData';
import ThreeButton from '../../Button/ThreeButton';
import ResetButton from '../../Button/ResetButton';

const DashBoardClassProgress = ({ authorization, authObject }: any) => {
    // 초기값 설정
    const [currentDate, setCurrentDate] = useState(dayjs());
    const [todayData, setTodayData] = useState<any>(null);
    const [thisWeekData, setThisWeekData] = useState<any>(null);
    const [thisMonthData, setThisMonthData] = useState<any>(null);

    // 그래프 그리기위한 최대값구해서 최대로표시
    const [todayMaxCount, setTodayMaxCount] = useState<number>(0);
    const [weekMaxCount, setWeekMaxCount] = useState<number>(0);
    const [monthMaxCount, setMonthMaxCount] = useState<number>(0);

    const [todayTotalCount, setTodayTotalCount] = useState<number>(0);
    const [todayCompleteCnt, setTodayCompleteCnt] = useState<number>(0);
    const [weekTotalCount, setWeekTotalCount] = useState<number>(0);
    const [weekCompleteCnt, setWeekCompleteCnt] = useState<number>(0);
    const [monthTotalCount, setMonthTotalCount] = useState<number>(0);
    const [monthCompleteCnt, setMonthCompleteCnt] = useState<number>(0);

    // 몇주차 표시
    const [weekNumber, setWeekNumber] = useState<number>(0);

    const [isToday, setIsToday] = useState<boolean>(true);
    const [isWeek, setIsWeek] = useState<boolean>(false);
    const [isMonth, setIsMonth] = useState<boolean>(false);

    /** 페이징 처리 */
    const pageSize = 10;
    /** 당일 */
    const [currentTodayPage, setCurrentTodayPage] = useState<number>(1);
    const startTodayIndex = (currentTodayPage - 1) * pageSize;
    const endTodayIndex = startTodayIndex + pageSize;
    let todayCurrentData = todayData && todayData.slice(startTodayIndex, endTodayIndex);
    let endTodayPage: number = 0;
    endTodayPage = todayData && Math.ceil(todayData.length / pageSize);

    /** 이번 주 */
    const [currentWeekPage, setCurrentWeekPage] = useState<number>(1);
    const startWeekIndex = (currentWeekPage - 1) * pageSize;
    const endWeekIndex = startWeekIndex + pageSize;
    let weekCurrentData = thisWeekData && thisWeekData.slice(startWeekIndex, endWeekIndex);
    let endWeekPage: number = thisWeekData && Math.ceil(thisWeekData.length / pageSize);

    /** 이번 달 */
    const [currentMonthPage, setCurrentMonthPage] = useState<number>(1);
    const startMonthIndex = (currentMonthPage - 1) * pageSize;
    const endMonthIndex = startMonthIndex + pageSize;
    let monthCurrentData = thisMonthData && thisMonthData.slice(startMonthIndex, endMonthIndex);
    let endMonthPage: number = thisMonthData && Math.ceil(thisMonthData.length / pageSize);

    const handleNextPage = () => {
        if (isToday) {
            if (currentTodayPage < Math.ceil(todayData.length / pageSize)) {
                setCurrentTodayPage(currentTodayPage + 1);
            }
        }

        if (isWeek) {
            if (currentWeekPage < Math.ceil(thisWeekData.length / pageSize)) {
                setCurrentWeekPage(currentWeekPage + 1);
            }
        }

        if (isMonth) {
            if (currentMonthPage < Math.ceil(thisMonthData.length / pageSize)) {
                setCurrentMonthPage(currentMonthPage + 1);
            }
        }
    };

    const handlePrevPage = () => {
        if (isToday) {
            if (currentTodayPage > 1) {
                setCurrentTodayPage(currentTodayPage - 1);
            }
        }

        if (isWeek) {
            if (currentWeekPage > 1) {
                setCurrentWeekPage(currentWeekPage - 1);
            }
        }

        if (isMonth) {
            if (currentMonthPage > 1) {
                setCurrentMonthPage(currentMonthPage - 1);
            }
        }
    };

    const handleResetPage = () => {
        setCurrentTodayPage(1);
        setCurrentWeekPage(1);
        setCurrentMonthPage(1);
    };

    const settingDate = (type: string) => {
        handleResetPage();
        setCurrentDate(dayjs());
        if (type === 'today') {
            setIsToday(true);
            setIsWeek(false);
            setIsMonth(false);
        }

        if (type === 'thisWeek') {
            setIsToday(false);
            setIsWeek(true);
            setIsMonth(false);
        }

        if (type === 'thisMonth') {
            setIsToday(false);
            setIsWeek(false);
            setIsMonth(true);
        }
    };

    const onClickAddDate = () => {
        if (isToday) {
            const newDate = dayjs(currentDate).add(1, 'day');
            setCurrentDate(newDate);
            handleResetPage();
        }

        if (isWeek) {
            const newDate = dayjs(currentDate).add(1, 'week');
            setCurrentDate(newDate);
            handleResetPage();
        }

        if (isMonth) {
            const newDate = dayjs(currentDate).add(1, 'month');
            setCurrentDate(newDate);
            handleResetPage();
        }
    };

    const onClickSubtractDate = () => {
        if (isToday) {
            const newDate = dayjs(currentDate).subtract(1, 'day');
            setCurrentDate(newDate);
            handleResetPage();
        }

        if (isWeek) {
            const newDate = dayjs(currentDate).subtract(1, 'week');
            setCurrentDate(newDate);
            handleResetPage();
        }

        if (isMonth) {
            const newDate = dayjs(currentDate).subtract(1, 'month');
            setCurrentDate(newDate);
            handleResetPage();
        }
    };

    //  수업진행 상황 그래프 조회 API : (GET)
    const getClassProgress = useQuery(
        ['getClassProgressAPI', currentDate],
        async () =>
            await getClassProgressAPI(authObject?.centerId, dayjs(currentDate).format('YYYY-MM-DD'), authorization),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const thisDay = res.data.thisDay;
                    const thisWeek = res.data.thisWeek;
                    const thisMonth = res.data.thisMonth;

                    const thisDayTotalCount = res.data.thisDayTotalCount;
                    const thisWeekTotalCount = res.data.thisWeekTotalCount;
                    const thisMonthTotalCount = res.data.thisMonthTotalCount;

                    const thisDayMaxCount = res.data.thisDayMaxCount;
                    const thisWeekMaxCount = res.data.thisWeekMaxCount;
                    const thisMonthMaxCount = res.data.thisMonthMaxCount;

                    setTodayData(thisDay);
                    setThisWeekData(thisWeek);
                    setThisMonthData(thisMonth);

                    setTodayMaxCount(thisDayMaxCount);
                    setWeekMaxCount(thisWeekMaxCount);
                    setMonthMaxCount(thisMonthMaxCount);
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!!authObject?.testAccount,
            refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    useEffect(() => {
        if (currentDate) {
            setWeekNumber(getCurrentDayWeekNumber(currentDate));
        }
    }, [currentDate]);

    // 데모버전 더미데이터 세팅
    useEffect(() => {
        if (authObject?.testAccount && dashBoardClassProgressDummyData) {
            const thisDay = dashBoardClassProgressDummyData.thisDay;
            const thisWeek = dashBoardClassProgressDummyData.thisWeek;
            const thisMonth = dashBoardClassProgressDummyData.thisMonth;

            const thisDayTotalCount = dashBoardClassProgressDummyData.thisDayTotalCount;
            const thisWeekTotalCount = dashBoardClassProgressDummyData.thisWeekTotalCount;
            const thisMonthTotalCount = dashBoardClassProgressDummyData.thisMonthTotalCount;

            const thisDayMaxCount = dashBoardClassProgressDummyData.thisDayMaxCount;
            const thisWeekMaxCount = dashBoardClassProgressDummyData.thisWeekMaxCount;
            const thisMonthMaxCount = dashBoardClassProgressDummyData.thisMonthMaxCount;

            setTodayData(thisDay);
            setThisWeekData(thisWeek);
            setThisMonthData(thisMonth);

            setTodayMaxCount(thisDayMaxCount);
            setWeekMaxCount(thisWeekMaxCount);
            setMonthMaxCount(thisMonthMaxCount);

            setTodayTotalCount(thisDayTotalCount);
            setWeekTotalCount(thisWeekTotalCount);
            setMonthTotalCount(thisMonthTotalCount);
        }
    }, [dashBoardClassProgressDummyData]);

    // 전체수업 / 완료수업 합산
    useEffect(() => {
        if (todayData && todayData.length > 0) {
            let completeArr = [];
            let totalArr = [];
            for (let i = 0; i < todayData.length; i++) {
                completeArr.push(todayData[i].completeScheduleCount);
                totalArr.push(todayData[i].totalScheduleCount);
            }

            const completeSum = completeArr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            const totalSum = totalArr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

            setTodayTotalCount(totalSum);
            setTodayCompleteCnt(completeSum);
        }

        if (thisWeekData && thisWeekData.length > 0) {
            let completeArr = [];
            let totalArr = [];
            for (let i = 0; i < thisWeekData.length; i++) {
                completeArr.push(thisWeekData[i].completeScheduleCount);
                totalArr.push(thisWeekData[i].totalScheduleCount);
            }

            const completeSum = completeArr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            const totalSum = totalArr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

            setWeekTotalCount(totalSum);
            setWeekCompleteCnt(completeSum);
        }

        if (thisMonthData && thisMonthData.length > 0) {
            let completeArr = [];
            let totalArr = [];
            for (let i = 0; i < thisMonthData.length; i++) {
                completeArr.push(thisMonthData[i].completeScheduleCount);
                totalArr.push(thisMonthData[i].totalScheduleCount);
            }
            const completeSum = completeArr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            const totalSum = totalArr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

            setMonthTotalCount(totalSum);
            setMonthCompleteCnt(completeSum);
        }
    }, [todayData, thisWeekData, thisMonthData]);

    // 그래프에서 마우스 호버시에 나오는 툴팁 커스텀 디자인
    const CustomToolTip = ({ id, value, color, data }: any) => (
        <Col
            style={{
                width: 162,
                height: 96,
                background: 'rgba(20, 20, 23, 0.46)',
                borderRadius: '16px 16px 16px 16px',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Row style={{ width: 96, alignItems: 'center' }}>
                <Row style={{ width: 16, height: 16, borderRadius: 2, backgroundColor: colors.WHITE_600 }} />
                <Body2Regular style={{ marginLeft: 8, color: colors.WHITE_100 }}>
                    {'전체 :'}
                    <Body2Bold>{data.totalScheduleCount}</Body2Bold>
                </Body2Regular>
            </Row>
            <Row style={{ width: 96, alignItems: 'center' }}>
                <Row style={{ width: 16, height: 16, borderRadius: 2, backgroundColor: colors.BLUE_500 }} />
                <Body2Regular style={{ marginLeft: 8, color: colors.WHITE_100 }}>
                    {'완료 :'}
                    <Body2Bold>{data.completeScheduleCount}</Body2Bold>
                </Body2Regular>
            </Row>
        </Col>
    );

    // 그래프에서 그려지는 막대그래프에 나오는 숫자 커스텀 디자인
    const CustomTextLayer = ({ bars }: any) => {
        return (
            <g>
                {bars.map((bar: any, index: number) => (
                    <text
                        key={index}
                        x={bar.x + bar.width / 2}
                        y={bar.y + bar.height / 2}
                        textAnchor="middle"
                        alignmentBaseline="middle"
                        style={{
                            fill: bar.data.id === 'completeScheduleCount' ? 'white' : 'black', // 조건에 따라 텍스트 색상 지정
                            fontSize: 16,
                            fontWeight: 700,
                            //lineHeight: 24,
                        }}
                    >
                        {bar.key.includes('complete') && bar.data.value > 0 && bar.data.value}
                        {bar.key.includes('interval') && bar.data.value > 0 && bar.data.data.totalScheduleCount}
                    </text>
                ))}
            </g>
        );
    };

    return (
        <Col
            style={{
                width: 1192,
                height: 612,
                marginTop: 24,
                borderRadius: 16,
                backgroundColor: colors.WHITE_50,
                border: '1px solid #C3D4F4',
            }}
        >
            <Body1Bold style={{ color: colors.LAVEL_4, marginTop: 48, marginLeft: 70 }}>
                강사별 수업 진행 현황
            </Body1Bold>

            <Row
                style={{
                    marginTop: 24,
                    alignItems: 'center',
                    marginLeft: 70,
                    justifyContent: 'space-between',
                    paddingRight: 70,
                }}
            >
                <Row style={{ alignItems: 'center' }}>
                    {/* 날짜 버튼 */}
                    <Row
                        style={{
                            width: 232,
                            height: 32,
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginRight: 24,
                        }}
                    >
                        <button style={{ width: 24, height: 24 }} onClick={() => onClickSubtractDate()}>
                            <img src={I_LEFT_ANGLE} style={{ width: 24, height: 24 }} />
                        </button>
                        <Body3Regular style={{ width: 160, textAlign: 'center', color: colors.LAVEL_2 }}>
                            {isToday
                                ? currentDate.format('YYYY년 MM월 DD일 (ddd)')
                                : isWeek
                                ? currentDate.format('MM월 ') + weekNumber + '주차'
                                : currentDate.format('YYYY년 MM월')}
                        </Body3Regular>
                        <button style={{ width: 32, height: 32 }} onClick={() => onClickAddDate()}>
                            <img src={I_RIGHT_ANGLE} style={{ width: 24, height: 24 }} />
                        </button>
                    </Row>

                    {/* 일간 / 주간 / 월간 버튼 */}
                    <ThreeButton
                        isFirst={isToday}
                        isSecond={isWeek}
                        isThird={isMonth}
                        firstText={'일간'}
                        secondText={'주간'}
                        thirdText={'월간'}
                        firstCallBack={() => settingDate('today')}
                        secondCallBack={() => settingDate('thisWeek')}
                        thirdCallBack={() => settingDate('thisMonth')}
                    />

                    {/* 초기화 버튼 */}
                    <ResetButton style={{ marginLeft: 24 }} callBack={() => setCurrentDate(dayjs())} />
                </Row>

                <Row style={{ justifyContent: 'space-between' }}>
                    <Row
                        style={{
                            height: 24,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            style={{
                                width: 16,
                                height: 16,
                                borderRadius: 2,
                                backgroundColor: colors.WHITE_600,
                                marginRight: 8,
                            }}
                        />
                        <Body3Regular style={{ color: colors.LAVEL_3 }}>{`전체 수업(${
                            isToday ? todayTotalCount : isWeek ? weekTotalCount : monthTotalCount
                        })`}</Body3Regular>
                    </Row>

                    <Row
                        style={{
                            height: 24,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginLeft: 29,
                        }}
                    >
                        <div
                            style={{
                                width: 16,
                                height: 16,
                                borderRadius: 2,
                                backgroundColor: colors.PRIMARY_BLUE,
                                marginRight: 8,
                            }}
                        />
                        <Body3Regular style={{ color: colors.LAVEL_3 }}>{`완료 수업(${
                            isToday ? todayCompleteCnt : isWeek ? weekCompleteCnt : monthCompleteCnt
                        })`}</Body3Regular>
                    </Row>
                </Row>
            </Row>

            <div
                style={{
                    width: 1192,
                    height: 614,
                    marginTop: 25,
                }}
            >
                {isToday && todayCurrentData && (
                    <ClassProgressTodayGraph
                        todayCurrentData={todayCurrentData}
                        todayMaxCount={todayMaxCount}
                        todayTotalCount={todayTotalCount}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                        currentTodayPage={currentTodayPage}
                        endTodayPage={endTodayPage}
                        CustomToolTip={CustomToolTip}
                        CustomTextLayer={CustomTextLayer}
                    />
                )}
                {isWeek && weekCurrentData && (
                    <ClassProgressWeekGraph
                        weekCurrentData={weekCurrentData}
                        weekMaxCount={weekMaxCount}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                        currentWeekPage={currentWeekPage}
                        endWeekPage={endWeekPage}
                        CustomToolTip={CustomToolTip}
                        CustomTextLayer={CustomTextLayer}
                    />
                )}
                {isMonth && monthCurrentData && (
                    <ClassProgressMonthGraph
                        monthCurrentData={monthCurrentData}
                        monthMaxCount={monthMaxCount}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                        currentMonthPage={currentMonthPage}
                        endMonthPage={endMonthPage}
                        CustomToolTip={CustomToolTip}
                        CustomTextLayer={CustomTextLayer}
                    />
                )}
            </div>
        </Col>
    );
};

export default DashBoardClassProgress;
