import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getCommunicationListAPI } from '../api/commBox';
import { Col, getItemWithExpiration, setItemWithExpiration } from '../lib/utils';
import CommunicationTop from '../components/Communication/CommunicationTop';
import ModalDetailCommunication from '../components/ModalDetailCommunication/ModalDetailCommunication';
import CommunicationPaging from '../components/Communication/CommunicationPaging';
import ModalPictureViewer from '../components/Communication/ModalPictureViewer';
import CommunicationListBody from '../components/Communication/CommunicationListBody';
import CommunicationListHeader from '../components/Communication/CommunicationListHeader';

export interface SortType {
    value: string;
    label: string;
}

let coachSortOptions: any = [];

let contactSortOptions: any = [
    { value: 'ENTIRE', label: '문의 유형 전체' },
    { value: 'PTFEEDBACK', label: 'PT 피드백' },
    { value: 'FACILITY', label: '시설 문의' },
    { value: 'COUNSEL', label: '상담 문의' },
    { value: 'ETC', label: '기타' },
];

let answerSortOptions: any = [
    { value: 'ENTIRE', label: '답변 진행 전체' },
    { value: 'PENDING', label: '답변대기' },
    { value: 'DONE', label: '답변완료' },
];

const CommunicationPage = ({ createTwoButtonAlert, createAlert, authObject, setAuthObject }: any) => {
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));

    const [coachSortType, setCoachSortType] = useState<SortType>({ value: 'ENTIRE', label: '강사 전체' });
    const [contactSortType, setContactSortType] = useState<SortType>({ value: 'ENTIRE', label: '문의 유형 전체' });
    const [answerSortType, setAnswerSortType] = useState<SortType>({ value: 'ENTIRE', label: '답변 진행 전체' });

    const [filterList, setFilterList] = useState<any>(null);
    const [entireList, setEntireList] = useState<any>(null);
    const [totalNumberCommunication, setTotalNumberCommunication] = useState<number>(0);

    const [isOpenCoach, setIsOpenCoach] = useState<boolean>(false);
    const [isOpenContact, setIsOpenContact] = useState<boolean>(false);
    const [isOpenAnswer, setIsOpenAnswer] = useState<boolean>(false);

    // 페이징
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = filterList && filterList.slice(startIndex, endIndex);
    const totalPages = filterList && Math.ceil(filterList.length / itemsPerPage);

    // 자세히 보기 모달
    const [isDetailModal, setIsDetailModal] = useState<boolean>(false);
    const [communicationObject, setCommunicationObject] = useState<any>(null);

    // 사진뷰어 모달
    const [isPictureModal, setIsPictureModal] = useState<boolean>(false);
    const [pictureList, setPictureList] = useState<any>(null);

    // 외부클릭시 드롭다운닫기
    const onClickOutSide = () => {
        if (isOpenCoach) {
            setIsOpenCoach(false);
        }
        if (isOpenContact) {
            setIsOpenContact(false);
        }
        if (isOpenAnswer) {
            setIsOpenAnswer(false);
        }
    };

    // 다음 페이지
    const onClickNextPage = () => {
        setCurrentPage(currentPage + 1);
        if (currentPage % 5 === 0) {
            setCurrentPage(currentPage + 1);
        }
    };

    // 이전 페이지
    const onClickPrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    // 맨 처음 페이지로
    const onClickStartPage = () => {
        setCurrentPage(1);
    };
    // 맨 마지막 페이지로
    const onClickLastPage = () => {
        setCurrentPage(totalPages);
    };

    // 자세히보기 버튼 클릭이벤트
    const onClickDetailButton = (item: any) => {
        setCommunicationObject(item);
        setIsDetailModal(true);
    };

    // 필터 초기화 버튼 클릭이벤트
    const onClickFilterReset = () => {
        setCoachSortType({ value: 'ENTIRE', label: '강사 전체' });
        setContactSortType({ value: 'ENTIRE', label: '문의 유형 전체' });
        setAnswerSortType({ value: 'ENTIRE', label: '답변 진행 전체' });
        setCurrentPage(1);
    };

    // 새로고침 버튼 클릭이벤트
    const onClickRefresh = () => {
        getCommunicationList.refetch();
    };

    const onClickPicture = (item: any) => {
        setPictureList(item);
        setIsPictureModal(true);
    };

    const onClickRefetch = () => {
        getCommunicationList.refetch();
    };

    // 회원 소통 상자 리스트 조회 API : (GET)
    const getCommunicationList = useQuery(
        ['getCommunicationListAPI', authorization],
        async () => await getCommunicationListAPI(authorization, authObject?.adminId),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const communicationBox = res.data.communicationBox;
                    const coachList = res.data.coachList;
                    const isNewCommunicationBox = res.data.isNewCommunicationBox;

                    console.log('실행');

                    setEntireList(communicationBox);
                    communicationBox && setTotalNumberCommunication(communicationBox.length);

                    let createCoachSortOptions = [{ value: 'ENTIRE', label: '강사 전체' }];
                    if (coachList.length > 0) {
                        for (let i = 0; i < coachList.length; i++) {
                            createCoachSortOptions.push({ value: coachList[i].coachId, label: coachList[i].coachName });
                        }
                    }

                    coachSortOptions = createCoachSortOptions;

                    const authObjectData = {
                        adminId: authObject?.adminId,
                        id: authObject?.id,
                        name: authObject?.name,
                        email: authObject?.email,
                        gender: authObject?.gender,
                        phoneNumber: authObject?.phoneNumber,
                        profileImageUrl: authObject?.profileImageUrl,
                        /** 센터 */
                        centerAddress: authObject?.centerAddress,
                        centerCategory: authObject?.centerCategory,
                        centerCode: authObject?.centerCode,
                        centerName: authObject?.centerName,
                        centerId: authObject?.centerId,
                        isNewCommunicationBox: isNewCommunicationBox,

                        /** 구독플랜관련 */
                        coachCount: authObject?.coachCount, // 소속 트레이너수
                        memberCount: authObject?.memberCount, // 활성 수강생수
                        activationDate: authObject?.activationDate, // 플랜 활성일
                        expirationDate: authObject?.expirationDate, // 플랜 만료일
                        expirationDelayDate: authObject?.expirationDelayDate, // 플랜 만료후 유예기간
                        finalPaymentDate: authObject?.finalPaymentDate, // 마지막 결제일
                        finalPaymentTryDate: authObject?.finalPaymentTryDate, // 마지막 결제 시도일

                        cardIssuingBank: authObject?.cardIssuingBank, // 결제카드 은행
                        cardNumber: authObject?.cardNumber, // 결제카드 번호
                        planAmount: authObject?.planAmount, // 결제 금액
                        usableStatus: authObject?.usableStatus, // 구독상태
                        paymentDueDate: authObject?.paymentDueDate,

                        /** 구독플랜 인원 정보 */
                        planLimitCount: authObject?.planLimitCount, // 플랜 제한 이용 인원
                        prevPlanLimitCount: authObject?.prevPlanLimitCount, // 구독 인원 변경시 변경전 플랜카운트
                        changedPlanLimitCount: authObject?.changedPlanLimitCount, // 구독 인원 변경시 변경 플랜카운트

                        confirmUsableStatus: authObject?.confirmUsableStatus, // 구독상태의 따른 팝업 클릭시 12시간이내에 다시안뜨게 하는 변수
                        testAccount: authObject?.testAccount, // 데모버전 계정인지아닌지
                    };
                    setAuthObject(authObjectData);
                    setItemWithExpiration('authObject', authObjectData, 12);
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!!authObject?.testAccount,
            staleTime: 0,
        }
    );

    useEffect(() => {
        if (entireList) {
            if (
                coachSortType.value === 'ENTIRE' &&
                contactSortType.value === 'ENTIRE' &&
                answerSortType.value === 'ENTIRE'
            ) {
                setFilterList(entireList);
                return;
            } else {
                const filteredList = entireList.filter((item: any) => {
                    const coachFilter =
                        coachSortType.value === 'ENTIRE' ? entireList : item.coachId === coachSortType.value;
                    const contactFilter =
                        contactSortType.value === 'ENTIRE' ? entireList : item.category === contactSortType.value;
                    const answerFilter =
                        answerSortType.value === 'ENTIRE' ? entireList : item.status === answerSortType.value;

                    return coachFilter && contactFilter && answerFilter;
                });

                let pendingList = filteredList.filter((item: any) => item.status === 'PENDING');

                let doneList = filteredList.filter((item: any) => item.status === 'DONE');

                pendingList.sort((a: any, b: any) => a.updatedTime - b.updatedTime);
                doneList.sort((a: any, b: any) => a.updatedTime - b.updatedTime);

                let finalFilterList = [].concat(pendingList, doneList);
                setFilterList(finalFilterList);
            }
        }
    }, [coachSortType, contactSortType, answerSortType, entireList]);

    useEffect(() => {
        if (contactSortType.value && answerSortType.value && coachSortType.value) {
            setCurrentPage(1);
        }
    }, [contactSortType.value, answerSortType.value, coachSortType.value]);
    return (
        <Col onClick={onClickOutSide} style={{ width: '100%', alignItems: 'center', marginTop: 64, marginBottom: 300 }}>
            <CommunicationTop
                // 코치 필터
                coachSortOptions={coachSortOptions}
                coachSortType={coachSortType}
                setCoachSortType={setCoachSortType}
                isOpenCoach={isOpenCoach}
                setIsOpenCoach={setIsOpenCoach}
                // 문의 유형 필터
                contactSortOptions={contactSortOptions}
                contactSortType={contactSortType}
                setContactSortType={setContactSortType}
                isOpenContact={isOpenContact}
                setIsOpenContact={setIsOpenContact}
                // 답변 진행 상태 필터
                answerSortOptions={answerSortOptions}
                answerSortType={answerSortType}
                setAnswerSortType={setAnswerSortType}
                isOpenAnswer={isOpenAnswer}
                setIsOpenAnswer={setIsOpenAnswer}
                onClickFilterReset={onClickFilterReset}
                onClickRefresh={onClickRefresh}
                totalNumberCommunication={totalNumberCommunication}
            />

            <CommunicationPaging
                filterList={filterList}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                onClickPrevPage={onClickPrevPage}
                onClickNextPage={onClickNextPage}
                onClickStartPage={onClickStartPage}
                onClickLastPage={onClickLastPage}
                totalNumberCommunication={totalNumberCommunication}
            />

            <Col style={{ width: 1192, marginTop: 15 }}>
                <CommunicationListHeader />
                <CommunicationListBody
                    currentItems={currentItems}
                    onClickDetailButton={onClickDetailButton}
                    communicationObject={communicationObject}
                />
            </Col>

            {isDetailModal && (
                <ModalDetailCommunication
                    setIsDetailModal={setIsDetailModal}
                    communicationObject={communicationObject}
                    setCommunicationObject={setCommunicationObject}
                    onClickRefetch={onClickRefetch}
                    createTwoButtonAlert={createTwoButtonAlert}
                    createAlert={createAlert}
                    onClickPicture={onClickPicture}
                />
            )}

            {isPictureModal && <ModalPictureViewer setIsPictureModal={setIsPictureModal} pictureList={pictureList} />}
        </Col>
    );
};

export default CommunicationPage;
