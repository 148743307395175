import {
    getCenterMemberListAPI,
    GetCenterMemberListRemainingPeriod,
    GetCenterMemberListRemainingSessions,
    GetCenterMemberListStatus,
    getCoachNameListAPI,
    getMemberScheduleHistory,
} from '../api/center';

export const fetchGetCenterMemberList = (
    params: {
        centerId: string;
        memberStatus?: GetCenterMemberListStatus;
        remainingPeriod?: GetCenterMemberListRemainingPeriod;
        remainingSessions?: GetCenterMemberListRemainingSessions;
        coach?: string;
    },
    authorization: string
) => ({
    queryKey: [
        'getCenterMemberListQuery',
        params?.memberStatus,
        params?.remainingPeriod,
        params?.remainingSessions,
        params?.coach,
    ],
    queryFn: () =>
        getCenterMemberListAPI(
            params.centerId,
            params.memberStatus ?? 'all',
            params.remainingPeriod ?? 'all',
            params.remainingSessions ?? 'all',
            params.coach ?? 'all',
            authorization
        ),
    onSuccess: (data: any) => {
        // 데이터 정제 로직
        return data?.members ?? [];
    },
});

export const fetchGetMemberScheduleHistory = (
    params: {
        centerId?: string;
        coachId?: string;
        memberId?: string;
        date?: string;
    },
    authorization: string
) => ({
    queryKey: ['getMemberScheduleHistoryQuery', params?.centerId, params?.coachId, params?.memberId, params?.date],
    queryFn: async () =>
        await getMemberScheduleHistory(params.centerId, params.coachId, params.memberId, params.date, authorization),
    // onSuccess: (data: any) => {
    //     // 데이터 정제 로직
    //     return data?.data ?? undefined;
    // },
    staleTime: 0,
});

export const fetchCoachNameList = (params: { centerId: string; isAllConcat?: boolean }, authorization: string) => ({
    queryKey: ['getCoachListForCenterQuery', params.centerId],
    queryFn: () => getCoachNameListAPI(params.centerId, authorization),
    onSuccess: (data: any) => {
        // 데이터 정제 로직
        if (params.isAllConcat) {
            return dropDownCoachList(data?.data?.coachNameList) ?? [];
        } else {
            return dropDownCoachListWithAll(data?.data?.coachNameList) ?? [];
        }
    },
});

export // all, 전체 포함 시켜서 리턴
const dropDownCoachListWithAll = (coachListData: GetCoachName[]) =>
    [{ code: 'all', value: '전체' }].concat(
        coachListData.map((coach: GetCoachName) => ({
            code: coach.coachId,
            value: coach.name,
        }))
    );
export // all, 전체 포함 시켜서 리턴
const dropDownCoachList = (coachListData: GetCoachName[]) =>
    coachListData.map((coach: GetCoachName) => ({
        code: coach.coachId,
        value: coach.name,
    }));

export type GetCoachName = {
    coachId: string;
    name: string;
};

export type DaySchedule = {
    [day: string]: {
        schedules: MemberClassHistorySchedule[];
        dayScheduleCount: number;
    }[];
};

export type MonthSchedule = {
    [month: string]: DaySchedule[];
};

export type YearSchedule = {
    [year: string]: MonthSchedule;
};

export type MonthlySchedule = {
    success: boolean;
    thisMonthScheduleCount: number;
    thisYearScheduleCount: number;
    allScheduleCount: number;
    thisMonth: MemberClassHistorySchedule[];
    thisYear: YearSchedule;
    totalView: YearSchedule[];
};

export type MemberClassHistorySchedule = {
    scheduleBundleId: string | null;
    memo: string;
    conditionId: string;
    postId: string;
    membershipId: string;
    attendanceStatus: 'NOSHOW' | 'ATTENDANCE' | 'ABSENT';
    isDeleted: boolean;
    isCanceled: boolean;
    coachId: string;
    memberId: string;
    alias: string;
    startTime: string;
    endTime: string;
    requestDeadline: string;
    issuer: {
        issuerId: string;
        issuerRole: 'center' | 'coach' | 'admin';
    };
    createdAt: string;
    updatedAt: string;
    __v: number;
    id: string;
    currentSession: number;
    totalSession: number;
    membershipCount: number;
    membershipName: string;
};
