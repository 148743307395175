import React from 'react';
import { Col } from '../../../lib/utils';
import { Body3Regular } from '../../../lib/font';
import { StyledSignUpInput } from '../SignUpBody2';

const InputCenterInfo = ({ register }: any) => {
    return (
        <Col>
            <Col style={{ marginTop: 64 }}>
                <label htmlFor="centerName" style={{ display: 'flex', marginBottom: 8 }}>
                    <Body3Regular>센터 이름</Body3Regular>
                </label>

                <StyledSignUpInput placeholder="ex) 머스투므브 강남점" type="text" {...register('centerName')} />
            </Col>

            <Col style={{ marginTop: 64 }}>
                <label htmlFor="centerAddress" style={{ display: 'flex', marginBottom: 8 }}>
                    <Body3Regular>센터주소</Body3Regular>
                </label>
                <StyledSignUpInput
                    placeholder="ex) 서울특별시 강남구 선릉로"
                    type="text"
                    {...register('centerAddress')}
                />
            </Col>
        </Col>
    );
};

export default InputCenterInfo;
