import { useState } from 'react';
import { Col, Row } from '../../lib/utils';
import { Body2Bold, Body3Bold, Body3Light, CaptionLight, Head4 } from '../../lib/font';
import { colors } from '../../lib/colors';
import SortTypeDropDown from '../DropDown/SortTypeDropDown';
import SmallButton from '../Button/SmallButton';
import RoundedDropDown from '../DropDown/RoundedDropDown';
import ResetButton from '../Button/ResetButton';

const CommunicationTop = ({
    // 코치필터
    coachSortType,
    coachSortOptions,
    setCoachSortType,
    isOpenCoach,
    setIsOpenCoach,
    // 유형 필터
    contactSortOptions,
    contactSortType,
    setContactSortType,
    isOpenContact,
    setIsOpenContact,
    // 답변 상태 필터
    answerSortType,
    answerSortOptions,
    setAnswerSortType,
    isOpenAnswer,
    setIsOpenAnswer,
    onClickFilterReset,
    onClickRefresh,
    totalNumberCommunication,
}: any) => {
    // 코치 필터
    const toggleCoachDropDown = () => {
        setIsOpenCoach(!isOpenCoach);
    };
    const handleCoachSelect = (option: any) => {
        setCoachSortType(option);
        setIsOpenCoach(false);
    };

    // 문의 필터
    const toggleContactDropDown = () => {
        setIsOpenContact(!isOpenContact);
    };
    const handleContactSelect = (option: any) => {
        setContactSortType(option);
        setIsOpenContact(false);
    };

    // 답변 필터
    const toggleAnswerDropDown = () => {
        setIsOpenAnswer(!isOpenAnswer);
    };
    const handleAnswerSelect = (option: any) => {
        setAnswerSortType(option);
        setIsOpenAnswer(false);
    };

    return (
        <Col>
            <Row
                style={{
                    width: 1144,
                    marginTop: 48,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Row style={{ alignItems: 'center' }}>
                    <Head4 style={{ color: colors.LAVEL_4 }}>소통 상자</Head4>
                    <CaptionLight style={{ color: colors.LAVEL_2, marginLeft: 12 }}>
                        {'회원님께서 App을 통해 문의를 주시면 카카오톡 알림을 드려요!'}
                    </CaptionLight>
                </Row>
                <Row style={{ width: 524, justifyContent: 'space-between', alignItems: 'center' }}>
                    {/* 강사 필터 : Coach */}
                    <RoundedDropDown
                        toggleSortDropDown={toggleCoachDropDown}
                        sortType={coachSortType}
                        isOpenSort={isOpenCoach}
                        sortOptions={coachSortOptions}
                        handleSortSelect={handleCoachSelect}
                        size="small"
                    />
                    {/* 강사 필터 : Contact */}
                    <RoundedDropDown
                        toggleSortDropDown={toggleContactDropDown}
                        sortType={contactSortType}
                        isOpenSort={isOpenContact}
                        sortOptions={contactSortOptions}
                        handleSortSelect={handleContactSelect}
                        size="small"
                    />

                    {/* 답변 필터 : Answer */}
                    <RoundedDropDown
                        toggleSortDropDown={toggleAnswerDropDown}
                        sortType={answerSortType}
                        isOpenSort={isOpenAnswer}
                        sortOptions={answerSortOptions}
                        handleSortSelect={handleAnswerSelect}
                        size="small"
                    />

                    <ResetButton text="초기화" callBack={onClickFilterReset} />
                    {/* <SmallButton
                        onClick={onClickRefresh}
                        text={'새로고침'}
                        style={{ marginLeft: 28, width: 128, height: 36 }}
                    /> */}
                </Row>
            </Row>
        </Col>
    );
};

export default CommunicationTop;
