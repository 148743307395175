import axios, { AxiosResponse } from 'axios';
import { Issuer } from './center';
import { createDataObject } from '../lib/utils';

export /**
 * @method get
 * @brief 수강권 상품 조회 api
 */

const getMembershipTemplatesListAPI = async (accessToken: string, centerId: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/membershipTemplates/?centerId=${centerId}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method get
 * @brief 수강권 상품 1개 detail 조회 api
 */
const getDetailMembershipAPI = async (membershipTemplateId: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/membershipTemplates/${membershipTemplateId}`,
            headers: { authorization: accessToken },
            data: {
                membershipTemplateId,
            },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method post
 * @brief 수강권 생성 api
 */
const createMembershipTemplatesAPI = async (
    accessToken: string,
    name: string,
    centerId: string,
    period: string,
    pricePerSchedule: number,
    totalPrice: number,
    totalSession: string,
    BGColor: any,
    memo: string,
    validDays: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/v1/membershipTemplates`,
            headers: { authorization: accessToken },
            data: {
                name,
                centerId,
                period,
                pricePerSchedule,
                totalPrice,
                totalSession,
                BGColor,
                memo,
                validDays,
            },
        });

        return response;
    } catch (error: any) {
        return error;
    }
};

export /**
 * @method patch
 * @brief 수강권 상품 수정
 */
const updateMembershipTemplatesAPI = async (
    accessToken: string,
    membershipTemplateId: string,
    name: string,
    period: string,
    pricePerSchedule: number,
    totalPrice: number,
    totalSession: string,
    BGColor: any,
    memo: string,
    validDays: string,
    isAvailable: boolean
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER}/v1/membershipTemplates/${membershipTemplateId}`,
            headers: { authorization: accessToken },
            data: {
                name,
                period,
                pricePerSchedule,
                totalPrice,
                totalSession,
                BGColor,
                memo,
                validDays,
                isAvailable,
            },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        return error;
    }
};

export /**
 * @method get
 * @brief 회원의 회원권 모두 가져오기 V2
 */
const getAllMembershipForMember = async (coachId: string, memberId: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v2/memberships/coach/${coachId}/member/${memberId}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};
export /**
 * @method get
 * @brief 회원의 회원권 모두 가져오기 V2
 */
const getMembershipForMember = async (membershipId: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v2/memberships/${membershipId}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export /**
 *
 * @brief 회원권 생성
 */
const createMemberShip = async ({
    coachId,
    memberId,
    membershipTemplateId,
    name,
    period,
    activationDate,
    expirationDate,
    startSessionValue,
    totalSession,
    memo,
    pricePerSchedule,
    status,
    totalPrice,
    issuer,
    BGColor,
    accessToken,
}: MemberShipApiType & { accessToken: string }): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/v3/memberships`,
            headers: {
                authorization: accessToken,
            },
            data: {
                coachId,
                memberId,
                membershipTemplateId,
                name,
                period,
                activationDate,
                expirationDate,
                startSessionValue,
                totalSession,
                memo,
                pricePerSchedule,
                status,
                totalPrice,
                issuer,
                BGColor,
            },
        });

        if (response.status === 201) {
            return response;
        }

        if (response.status === 209) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 450:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return null;
        }
    }
};
export /**
 *
 * @brief 회원권 수정
 */
const updateMemberShip = async ({
    membershipId,
    name,
    period,
    activationDate,
    expirationDate,
    startSessionValue,
    totalSession,
    memo,
    status,
    pricePerSchedule,
    totalPrice,
    accessToken,
}: UpdateMemberShipApiType): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    const data = await createDataObject({
        name,
        period,
        activationDate,
        expirationDate,
        startSessionValue,
        totalSession,
        memo,
        status,
        pricePerSchedule,
        totalPrice,
    });
    console.log('🚀 ~ data:', data);
    try {
        response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER}/v3/memberships/${membershipId}`,
            headers: {
                authorization: accessToken,
            },
            data: data,
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 450:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return null;
        }
    }
};
export /**
 *
 * @brief 회원권 수정
 */
const deleteMemberShip = async (membershipId: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;

    try {
        response = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_SERVER}/v2/memberships/${membershipId}`,
            headers: {
                authorization: accessToken,
            },
        });

        if (response.status === 204) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 450:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return null;
        }
    }
};

export /**
 *
 * @brief 수강권 상품 이전
 */
const patchRelocateMembership = async ({
    newMembershipTemplateId,
    membershipIds,
    accessToken,
}: PatchRelocateMembershipApiType): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;

    try {
        response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER}/v1/membershipTemplates/relocate/memberships`,
            headers: {
                authorization: accessToken,
            },
            data: {
                newMembershipTemplateId,
                membershipIds,
            },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 450:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return null;
        }
    }
};
export /**
 *
 * @brief 수강권 상품 삭제
 */
const deleteMembershipTemplate = async ({
    membershipTemplateId,
    accessToken,
}: {
    membershipTemplateId: string;
    accessToken: string;
}): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;

    try {
        response = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_SERVER}/v1/membershipTemplates/${membershipTemplateId}`,
            headers: {
                authorization: accessToken,
            },
        });

        if (response.status === 204) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 450:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return null;
        }
    }
};
export type MemberShipApiType = {
    coachId?: string;
    memberId?: string;
    membershipTemplateId: string;
    name: string;
    period: number;
    activationDate: string;
    expirationDate: string;
    startSessionValue: number;
    totalSession: number;
    memo: string;
    pricePerSchedule: number;
    status: 'active' | 'expired';
    totalPrice: number;
    BGColor: string[];
    issuer: {
        issuerId?: string;
        issuerRole?: 'admin' | 'coach';
    };
};

export type UpdateMemberShipApiType = {
    membershipId: string;
    name?: string;
    period?: number;
    activationDate?: string;
    expirationDate?: string;
    startSessionValue?: number;
    totalSession?: number;
    memo?: string;
    status?: 'active' | 'expired';
    pricePerSchedule?: number;
    totalPrice?: number;
    accessToken: string;
};
export type PatchRelocateMembershipApiType = {
    newMembershipTemplateId: string;
    membershipIds: string[];
    accessToken: string;
};
