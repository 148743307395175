import { Dispatch, SetStateAction } from 'react';
import { Body3Regular } from '../../lib/font';
import { Col, Row } from '../../lib/utils';
import styled from 'styled-components';
import InputSquareDropDown from '../DropDown/InputSquareDropDown';
import ColorRoundButton from '../Button/ColorRoundButton';
import TextBlueButton from '../TextButton/TextBlueButton';
import InputName from './SignUpInputComponent/InputName';
import InputPhoneNumber from './SignUpInputComponent/InputPhoneNumber';
import InputSmsCode from './SignUpInputComponent/InputSmsCode';
import InputEmail from './SignUpInputComponent/InputEmail';
import InputCenterInfo from './SignUpInputComponent/InputCenterInfo';
import CheckPolicy from './SignUpInputComponent/CheckPolicy';

export const StyledSignUpInput = styled.input`
    width: 44rem;
    height: 4.8rem;
    font-size: 1.8rem;
    padding-left: 1.6rem;
    border-bottom: 1px solid #a6bfef;
    color: #353535;
    ::placeholder {
        color: #acacac;
    }
`;
export const StyledSignUpInput2 = styled.input`
    width: 34.8rem;
    height: 4.8rem;
    font-size: 1.8rem;
    font-weight: 400;
    padding-left: 1.6rem;
    border-bottom: 1px solid #a6bfef;
    color: #353535;
    margin-right: 1.6rem;
    ::placeholder {
        color: #acacac;
    }
`;

interface I_Props {
    register: any;
    errors: any;
    phoneNumber: string;
    setPhoneNumber: Dispatch<SetStateAction<string>>;
    authNumber: string;
    setAuthNumber: Dispatch<SetStateAction<string>>;
    handleSmsCodeRequest: () => void;
    handleAuthSmsCode: () => void;
    isRunning: boolean;
    formattedTime: any;
    isCheckAuthNumber: boolean;
    selectedGender: any;
    genderOptions: any;
    handleGenderSelect: any;
    onClickLoginPage: () => void;
    selectedCategory: any;
    categoryOptions: any;
    handleCategorySelect: any;

    onClickCheckAge: () => void;
    onClickPolicy: () => void;
    isCheckAge: boolean;
    isCheckPolicy: boolean;
    onClickTerm: () => void;
}

const SignUpBody2 = ({
    register,
    errors,
    phoneNumber,
    setPhoneNumber,
    authNumber,
    setAuthNumber,
    handleSmsCodeRequest,
    handleAuthSmsCode,
    isRunning,
    formattedTime,
    isCheckAuthNumber,
    selectedGender,
    genderOptions,
    handleGenderSelect,
    onClickLoginPage,
    selectedCategory,
    categoryOptions,
    handleCategorySelect,

    onClickCheckAge,
    onClickPolicy,
    isCheckAge,
    isCheckPolicy,
    onClickTerm,
}: I_Props) => {
    return (
        <Col style={{ width: '440px', marginTop: 48 }}>
            {/* 이름 */}
            <InputName register={register} errors={errors} />
            {/* 휴대폰번호 */}
            <InputPhoneNumber
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                handleSmsCodeRequest={handleSmsCodeRequest}
                isCheckAuthNumber={isCheckAuthNumber}
            />
            {/* 휴대폰번호 인증 */}
            <InputSmsCode
                authNumber={authNumber}
                setAuthNumber={setAuthNumber}
                isRunning={isRunning}
                formattedTime={formattedTime}
                isCheckAuthNumber={isCheckAuthNumber}
                handleAuthSmsCode={handleAuthSmsCode}
            />
            {/* 이메일 */}
            <InputEmail register={register} />
            {/* 성별 업종 */}
            <Row style={{ marginTop: 64, justifyContent: 'space-between' }}>
                <Col>
                    <Body3Regular>{'성별'}</Body3Regular>
                    <InputSquareDropDown
                        selectedGender={selectedGender}
                        genderOptions={genderOptions}
                        handleGenderSelect={handleGenderSelect}
                    />
                </Col>
                <Col>
                    <Body3Regular>{'업종'}</Body3Regular>
                    <InputSquareDropDown
                        selectedGender={selectedCategory}
                        genderOptions={categoryOptions}
                        handleGenderSelect={handleCategorySelect}
                    />
                </Col>
            </Row>
            {/* 센터이름, 센터주소 */}
            <InputCenterInfo register={register} />
            {/* 정책 체크박스 */}
            <CheckPolicy
                onClickCheckAge={onClickCheckAge}
                isCheckAge={isCheckAge}
                onClickPolicy={onClickPolicy}
                isCheckPolicy={isCheckPolicy}
                onClickTerm={onClickTerm}
            />

            <Col style={{ width: '44rem', marginBottom: 300, marginTop: 64 }}>
                <ColorRoundButton
                    size="middle"
                    text="회원가입"
                    isVisibleLeftImg={false}
                    isVisibleRightImg={false}
                    customStyle={{
                        width: '44rem',
                        height: 48,
                        marginTop: 32,
                        marginBottom: 32,
                    }}
                    type={'submit'}
                />
                <TextBlueButton text="로그인 페이지 돌아가기" size="middle" callBack={onClickLoginPage} />
            </Col>
        </Col>
    );
};

export default SignUpBody2;
