import { I_GNB_LOGO_DEFAULT, I_GNB_LOGO_HOVER, I_GNB_LOGO_CLICK } from '../../types/images';
import styled from 'styled-components';

const LogoButtonWrap = styled.button`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 82px;
    height: 48px;

    border: none;
    background: none;
    padding: 0;
    cursor: pointer;

    img {
        width: 82px;
        height: 48px;
        transition: all 0.2s ease-in-out;
    }

    &:hover img {
        content: url(${I_GNB_LOGO_HOVER});
    }

    &:active img {
        content: url(${I_GNB_LOGO_CLICK});
    }
`;

type Props = {
    callBack: () => void;
};

const LogoButton = ({ callBack }: Props) => {
    return (
        <LogoButtonWrap onClick={callBack}>
            <img style={{ width: 82, height: 48 }} src={I_GNB_LOGO_DEFAULT} />
        </LogoButtonWrap>
    );
};

export default LogoButton;
