import React, { useState } from 'react';
import { getItemWithExpiration, Row } from '../../lib/utils';
import { Head4 } from '../../lib/font';
import { colors } from '../../lib/colors';
import NewRoundedDropDown from '../DropDown/NewRoundedDropDown';
import {
    GetCenterMemberListRemainingPeriod,
    GetCenterMemberListRemainingSessions,
    GetCenterMemberListStatus,
} from '../../api/center';
import { CodeValueType } from '../../types/types';
import ResetButton from '../Button/ResetButton';
import useMultipleQueries from '../../hook/useMultipleQueries';
import { fetchCoachNameList } from '../../services/useCenterQueryService';

const memberStatusDatas: CodeValueType<GetCenterMemberListStatus>[] = [
    { code: 'all', value: '전체 회원' },
    { code: 'active', value: '활성' },
    { code: 'expired', value: '만료' },
    { code: 'none', value: '대기 (수강권 없음)' },
];
const remainingPeriodDatas: CodeValueType<GetCenterMemberListRemainingPeriod>[] = [
    { code: 'all', value: '전체' },
    { code: 'lessThanOneWeek', value: ' 7일 미만' },
    { code: 'lessThanTwoWeeks', value: ' 14일 미만' },
    { code: 'lessThanOneMonth', value: ' 30일 미만' },
    { code: 'lessThanHundredDays', value: ' 100일 미만' },
    { code: 'lessThanOneYear', value: ' 1년 미만' },
    { code: 'oneYearOrMore', value: ' 1년 이상' },
];
const remainingSessionsDatas: CodeValueType<GetCenterMemberListRemainingSessions>[] = [
    { code: 'all', value: '전체' },
    { code: 'lessThanThreeTimes', value: ' 3회 미만' },
    { code: 'lessThanFiveTimes', value: ' 5회 미만' },
    { code: 'lessThanTenTimes', value: ' 10회 미만' },
    { code: 'lessThanTwentyTimes', value: ' 20회 미만' },
    { code: 'twentyTimesOrMore', value: ' 20회 이상' },
];
type Props = {
    coach?: CodeValueType;
    remainingPeriod?: CodeValueType<GetCenterMemberListRemainingPeriod>;
    remainingSessions?: CodeValueType<GetCenterMemberListRemainingSessions>;
    memberStatus?: CodeValueType<GetCenterMemberListStatus>;
    // 회원상태 변경시
    onMemberStatusChange?: (value: CodeValueType<GetCenterMemberListStatus>) => void;
    // 잔여기간 변경시
    onRemainingPeriodChange?: (value: CodeValueType<GetCenterMemberListRemainingPeriod>) => void;
    // 잔여횟수 변경시
    onRemainingSessionsChange?: (value: CodeValueType<GetCenterMemberListRemainingSessions>) => void;
    // 강사 변경시
    onCoachChange?: (value: CodeValueType) => void;
    // 초기화 버튼 눌렀을시
    onClickInit?: () => void;
    // 강사 드롭다운 회원리스트화면, 강사프로필 나눠야함
    type?: 'memberList' | 'coachProfile';
};

const MemberListHeader = ({
    coach,
    remainingPeriod,
    remainingSessions,
    memberStatus,
    onMemberStatusChange,
    onRemainingPeriodChange,
    onRemainingSessionsChange,
    onCoachChange,
    onClickInit,
    type = 'memberList',
}: Props) => {
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));
    const { refinedResults, isLoading } = useMultipleQueries([
        {
            fetchFn: fetchCoachNameList,
            params: { centerId: authObject?.centerId },
            enabled: !!authObject?.centerId,
        },
    ]);

    //coachList
    const coachList: CodeValueType<string>[] = refinedResults?.[0]?.data as CodeValueType<string>[];
    return (
        <>
            <Row
                style={{
                    width: type === 'memberList' ? 'calc(100% - 128px)' : 1192,
                    alignItems: 'center',
                    overflow: 'visible', // 추가된 부분
                    justifyContent: 'space-between',
                    marginTop: 48,
                    marginBottom: 24,
                }}
            >
                <Head4 style={{ color: colors.LAVEL_4 }}>회원 리스트</Head4>
                <Row>
                    {/* 회원 상태 */}
                    <NewRoundedDropDown
                        selectData={memberStatus}
                        datas={memberStatusDatas}
                        onClickSort={(item) => {
                            onMemberStatusChange && onMemberStatusChange(item);
                        }}
                        mr={16}
                    />

                    {/* 잔여 기간 */}
                    <NewRoundedDropDown
                        selectData={remainingPeriod}
                        datas={remainingPeriodDatas}
                        onClickSort={(item) => {
                            onRemainingPeriodChange && onRemainingPeriodChange(item);
                        }}
                        mr={16}
                    />
                    {/* 잔여 횟수*/}
                    <NewRoundedDropDown
                        selectData={remainingSessions}
                        datas={remainingSessionsDatas}
                        onClickSort={(item) => {
                            onRemainingSessionsChange && onRemainingSessionsChange(item);
                        }}
                        mr={16}
                    />
                    {/* 강사 리스트 */}
                    {!isLoading && type === 'memberList' && (
                        <NewRoundedDropDown
                            selectData={coach}
                            datas={coachList}
                            onClickSort={(item) => {
                                onCoachChange && onCoachChange(item);
                            }}
                            mr={16}
                        />
                    )}

                    <ResetButton
                        style={{
                            height: 32,
                        }}
                        callBack={onClickInit}
                    />
                </Row>
            </Row>
        </>
    );
};

export default MemberListHeader;
