import React, { CSSProperties } from 'react';
import { Row } from '../../lib/utils';
import { Body1Regular, Body2Regular, Body3Regular, CaptionRegular } from '../../lib/font';
import { I_WHITE_PLUS } from '../../types/buttonImage';
import { colors } from '../../lib/colors';

type Props = {
    text: string;
    isVisibleLeftImg?: boolean;
    isVisibleRightImg?: boolean;
    isDisabled?: boolean;
    size?: 'big' | 'middle' | 'small' | 'tiny';
    customStyle?: CSSProperties;
    callBack?: any;
    type?: 'submit' | 'button';
    isGradient?: boolean;
    color?: string;
};

const ColorRoundButton = ({
    text,
    isVisibleLeftImg = false,
    isVisibleRightImg = false,
    isDisabled = false,
    size,
    customStyle,
    callBack,
    type,
    isGradient = false,
    color = colors.primary,
}: Props) => {
    const BigStyle: CSSProperties = {
        width: 217,
        height: 64,
    };

    const MiddleStyle: CSSProperties = {
        width: 185,
        height: 48,
    };

    const SmallStyle: CSSProperties = {
        width: 140,
        height: 36,
    };

    const TinyStyle: CSSProperties = {
        width: 77,
        height: 24,
    };

    const buttonStyle: CSSProperties = {
        borderRadius: '48px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: isDisabled ? '#A5ABB5' : color,

        color: isDisabled ? '#EDF4FF' : '#FDFEFF',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        ...(customStyle
            ? customStyle
            : size === 'big'
            ? BigStyle
            : size === 'middle'
            ? MiddleStyle
            : size === 'small'
            ? SmallStyle
            : TinyStyle),
    };

    const gradientButtonStyle: CSSProperties = {
        borderRadius: '48px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: isDisabled ? '#A5ABB5' : 'linear-gradient(90deg, #3D73DD 0%, #6327E2 100%)',

        color: isDisabled ? '#EDF4FF' : '#FDFEFF',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        ...(customStyle
            ? customStyle
            : size === 'big'
            ? BigStyle
            : size === 'middle'
            ? MiddleStyle
            : size === 'small'
            ? SmallStyle
            : TinyStyle),
    };

    // 호버시
    const handleMouseOver = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!isDisabled) {
            if (isGradient) {
                e.currentTarget.style.background = '#3869c9';
            } else {
                e.currentTarget.style.backgroundColor = '#3869c9';
            }
        }
    };

    // 디폴트 컬러
    const handleMouseOut = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!isDisabled) {
            if (isGradient) {
                e.currentTarget.style.background = 'linear-gradient(90deg, #3D73DD 0%, #6327E2 100%)';
            } else {
                e.currentTarget.style.backgroundColor = '#3d73dd';
            }
        }
    };

    // 액티브
    const handleMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!isDisabled) {
            if (isGradient) {
                e.currentTarget.style.background = '#3d73dd';
            } else {
                e.currentTarget.style.backgroundColor = '#3d73dd';
            }
        }
    };

    // 디폴트 컬러
    const handleMouseUp = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!isDisabled) {
            if (isGradient) {
                e.currentTarget.style.background = 'linear-gradient(90deg, #3D73DD 0%, #6327E2 100%)';
            } else {
                e.currentTarget.style.backgroundColor = '#3d73dd';
            }
        }
    };

    return (
        <button
            onClick={callBack}
            style={isGradient ? gradientButtonStyle : buttonStyle}
            disabled={isDisabled}
            type={type}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
        >
            {(size === 'big' || size === 'middle') && (
                <Row
                    style={{
                        width: 24,
                        height: 24,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {isVisibleLeftImg && <img src={I_WHITE_PLUS} style={{ width: 18, height: 18 }} />}
                </Row>
            )}

            {size === 'big' && <Body1Regular style={{ marginLeft: 8, marginRight: 8 }}>{text}</Body1Regular>}
            {size === 'middle' && <Body2Regular style={{ marginLeft: 8, marginRight: 8 }}>{text}</Body2Regular>}
            {size === 'small' && <Body3Regular>{text}</Body3Regular>}
            {size === 'tiny' && <CaptionRegular>{text}</CaptionRegular>}

            {(size === 'big' || size === 'middle') && (
                <Row
                    style={{
                        width: 24,
                        height: 24,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {isVisibleRightImg && <img src={I_WHITE_PLUS} style={{ width: 18, height: 18 }} />}
                </Row>
            )}
        </button>
    );
};

export default ColorRoundButton;
