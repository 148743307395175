import React from 'react';
import { Col } from '../../../lib/utils';
import { Body3Regular } from '../../../lib/font';
import InputSquareDropDown from '../../DropDown/InputSquareDropDown';
import { colors } from '../../../lib/colors';

const CreateOffScheduleLeft = ({ coachOption, coach, handleCoachSelect }: any) => {
    return (
        <Col style={{ width: 382, height: 88, justifyContent: 'space-between' }}>
            <Body3Regular style={{ color: colors.LAVEL_4 }}> {'담당 강사'}</Body3Regular>

            {coachOption && (
                <InputSquareDropDown
                    selectedGender={coach}
                    genderOptions={coachOption}
                    handleGenderSelect={handleCoachSelect}
                    customStyle={{ width: 382 }}
                    zIndex={100}
                />
            )}
        </Col>
    );
};

export default CreateOffScheduleLeft;
