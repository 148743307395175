import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { Col, Row } from '../../lib/utils';
import {
    Body1Bold,
    Body2Bold,
    Body2Regular,
    Body3Bold,
    Body3Light,
    Body3Regular,
    Head4,
    Subtitle1Bold,
} from '../../lib/font';
import { I_ALERT, I_POPUP_EXIT } from '../../types/images';
import { colors } from '../../lib/colors';
import NormalButton from '../Button/NormalButton';
import WhiteSquareButton from '../Button/WhiteSquareButton';
import ColorSquareButton from '../Button/ColorSquareButton';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalInner = styled.div`
    background: #f5f9ff;
    width: 880px;
    height: 460px;
    border-radius: 8px;
    box-shadow: 8px 8px 24px 0px #00000052;
    display: flex;
    flex-direction: column;
`;

const HoverImage = styled.img`
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

type Props = {
    setModalVisible: Dispatch<SetStateAction<boolean>>;
    navigate: any;
    onClickCancelSubscription: () => void;
    authObject: any;
};

const ModalCancelSubsCription = ({ setModalVisible, navigate, onClickCancelSubscription, authObject }: Props) => {
    console.log(authObject);
    return (
        <ModalOverLay>
            <ModalInner>
                <Row>
                    <Subtitle1Bold style={{ marginTop: 36, marginLeft: 48, color: colors.LAVEL_4 }}>
                        {'구독 취소'}
                    </Subtitle1Bold>
                    <HoverImage
                        src={I_POPUP_EXIT}
                        style={{ width: 32, height: 32, marginTop: 16, marginLeft: 690 }}
                        onClick={() => setModalVisible(false)}
                    />
                </Row>

                <Row style={{ alignItems: 'center', marginLeft: 56, marginTop: 32 }}>
                    <Body2Bold style={{ color: colors.LAVEL_4 }}>사용 플랜 :</Body2Bold>
                    <Body2Bold style={{ color: colors.LAVEL_4, marginLeft: 16 }}>
                        Rappo Manager Plan ({authObject?.planLimitCount}인)
                    </Body2Bold>
                </Row>

                <Body2Bold style={{ color: colors.LAVEL_3, marginLeft: 56, marginTop: 24 }}>
                    지금 구독 취소를 하시게 되면...
                </Body2Bold>

                <Row style={{ alignItems: 'center', marginLeft: 56, marginTop: 24 }}>
                    <div style={{ width: 3, height: 3, borderRadius: 1.5, backgroundColor: colors.LAVEL_3 }} />
                    <Body3Light style={{ color: colors.LAVEL_3, marginLeft: 6 }}>
                        서비스 사용 중 환불은 불가능하며, 마지막 결제일 기준으로 30일간 이용 가능합니다.
                    </Body3Light>
                </Row>
                <Row style={{ alignItems: 'center', marginLeft: 56 }}>
                    <div style={{ width: 3, height: 3, borderRadius: 1.5, backgroundColor: colors.LAVEL_3 }} />
                    <Body3Light style={{ color: colors.LAVEL_3, marginLeft: 6 }}>
                        구매 만료 기간 후, 더이상 결제가 진행되지 않으며 '라포 매니저'를 이용하실 수 없습니다.
                    </Body3Light>
                </Row>
                <Row style={{ alignItems: 'center', marginLeft: 56 }}>
                    <div style={{ width: 3, height: 3, borderRadius: 1.5, backgroundColor: colors.LAVEL_3 }} />
                    <Body3Light style={{ color: colors.LAVEL_3, marginLeft: 6 }}>
                        '라포 매니저'에 등록된 강사들의 Rappo App - Manager Plan 또한 해지 되며, 연결된 회원수에 따라
                    </Body3Light>
                </Row>

                <Body3Light style={{ color: colors.LAVEL_3, marginLeft: 65 }}>
                    사용이 제한되거나 불편을 겪을 수 있습니다.
                </Body3Light>
                <Row style={{ alignItems: 'center', marginLeft: 56, marginBottom: 22 }}>
                    <div style={{ width: 3, height: 3, borderRadius: 1.5, backgroundColor: colors.LAVEL_3 }} />
                    <Body3Light style={{ color: colors.LAVEL_3, marginLeft: 6 }}>
                        자세한 사항은 이용약관/개인정보 정책을 확인하여 주세요.
                    </Body3Light>
                </Row>

                <Body2Regular style={{ color: colors.ERROR, marginLeft: 56 }}>
                    더이상 라포 매니저를 이용하시지 않으시겠습니까?
                </Body2Regular>

                <Row
                    style={{
                        width: 312,
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: 20,
                        marginLeft: 536,
                    }}
                >
                    <WhiteSquareButton
                        text="취소"
                        callBack={() => {
                            setModalVisible(false);
                        }}
                        size="middle"
                    />
                    <ColorSquareButton text="구독 해지" callBack={onClickCancelSubscription} size="middle" />
                </Row>
            </ModalInner>
        </ModalOverLay>
    );
};

export default ModalCancelSubsCription;
