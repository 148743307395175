import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import './input.css';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';

const MINUTE = 1000 * 60;

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1 * MINUTE,
            cacheTime: 5 * MINUTE,
            refetchOnWindowFocus: false,
            enabled: false,
            retry: false,
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <RecoilRoot>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </QueryClientProvider>
        </RecoilRoot>
    </React.StrictMode>
);
